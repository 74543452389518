import '@ant-design/compatible/assets/index.css';
import {
	CloudUploadOutlined,
	DeleteFilled,
	EditOutlined,
	InfoCircleOutlined,
	PictureOutlined,
	StarFilled,
	StopOutlined,
	ToTopOutlined,
	UploadOutlined
} from '@ant-design/icons';
import {Form} from '@ant-design/compatible';
import moment from 'moment';
import axios from "axios";
import _ from 'lodash';
import React, {Component} from "react";
import cookie from "react-cookies";
import {connect} from "react-redux";
import {
	Button,
	Col,
	DatePicker,
	Descriptions,
	Divider,
	Empty,
	Input,
	message,
	Modal,
	Row,
	Select,
	Table,
	Tag,
	Upload
} from 'antd';
import {changePage} from '../action/search.action';
import {deleteSlide, descUpdateAlert, slideUpdateAlert, startTiling, startUpload} from '../action/slides.action';
import '../asset/style/add_case.css';
import '../asset/style/custom_antd_table_style.css';
import SlideSuggestions from "../component/case/add.slide.modal";
import {AuthHeader} from "../helper/auth.token";
import {historyMP} from "../helper/history";
import {globalUrlPrefix, openInNewTabType, slideViewType} from '../utils/const';
import {
	decodeStringWithNewLine,
	getAssignCaseButtonComponent,
	getAssignCaseComponent,
	getDbEntryComponent,
	getDeleteComponent,
	getErrorComponent,
	getExportComponent,
	getLabelComponent,
	getLoadingComponent,
	getMorphleIDComponent,
	getObjectiveComponent,
	getPreviewComponent,
	getScreenshotCarousel,
	getStarredComponent,
	getTagsComponent,
	getTilingComponent,
	getUploadComponent
} from "../component/dashboard/slidelist_utils";
import {makeMenuVisible} from "../action/context.menu.action";
import EditableFormTable from './editable_table';
import {getFileExtension, linkViewer, linkViewerNewTab} from "../utils/utils";
import {
	getChronologicalSorting,
	getLexicographicalSorting,
	getNumericalSorting,
	getReverseChronologicalSorting,
	getReverseLexicographicalSorting
} from "../component/case/case_utils";
// import { data } from 'jquery';
import querystring from "query-string";

const { TextArea } = Input;
const { Option } = Select;
const { Column } = Table;

const EditableContext = React.createContext();

// const EditableFormRow = ({ form, index, ...props }) => (
//     <EditableContext.Provider value={form}>
//         <tr {...props} />
//     </EditableContext.Provider>
// );

class EditableCell extends Component {
    state = {
        editing: false,
    };

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    };

    save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    };

    renderCell = form => {
        this.form = form;
        const { children, dataIndex, record, title } = this.props;
        const { editing } = this.state;
        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    initialValue: record[dataIndex],
                })(<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
            </Form.Item>
        ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={this.toggleEdit}
                >
                    {children}
                </div>
            );
    };

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
                ) : (
                        children
                    )}
            </td>
        );
    }
}

const ButtonGroup = Button.Group;

class AllScansCliaCaseView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetchingCase: true,
            isFetchingFiles: true,
            isFetchingFilesSource: true,
            isFetchingSlides: true,
            isFetchingPendingScans: true,
            isFetchingComments: true,
            hasCaseThrownError: true,
            hasSlidesThrownError: true,
            hasCommentsThrownError: true,
            hasFilesThrownError: true,
            hasFilesSourceThrownError: true,
            hasPendingScansThrownError: true,
            selectedRowKeys: [],
            caseScans: {},
            caseScansArray: [],
            previewVisible: false,
            showFile: false,
            previewFile: '',
            caseFileList: [],
            caseFileListSource: [],
            currCase: [],
            pendingScans: [],
            comments: [],
            addSlideModalVisible: false,
            currentHoverRowID: -1,
            screenshotVisible: false,
            screenshots: [],
            sortingMethod: '-date_time',
            inputType: '',
            preglassData: {"diagnosis":"","slide_id":"","quality":"","diagnostic-certainity":"","notes":""},
            postglassData: {
                "diagnosis":"","slide_id":"","quality":"","diagnostic-certainity":"","notes":"","concordance":""
            },
            postWashoutData: {
                "diagnosis":""
            },
            caseValidationData: [],
            caseNotes: "",
            caseNotesData: [],
            slidesPostGlassEligibility: {},
            slidesPostGlassReason: {},
            user_id : cookie.load('user_id'),
            infoEditable: false,
            patient_name :'',
            case_id : '',
            dob: new moment().format('YYYY-MM-DD'),
            receiving_date: new moment().format('YYYY-MM-DD'),
            case_type: '',
            part_designation: '',
            age_and_sex: '',
            gender: 'M',
            status_tags: [],
            current_status_tag: "",
        }

        this.is_cloud = cookie.loadAll().deployment_mode === 'cloud';
        this.isMobile = cookie.loadAll().isMobile === 'true';
        this.iOS = cookie.loadAll().iOS === "true";
    }

    componentDidMount = () => {
        this.getStatusTags();
        this.getCase();
    }

    getStatusTags = () => {
        let url = '/api/get_status_tags/'
        axios.get(url).then(res => {
            this.setState({
                status_tags: res.data['tags'],
            })
        })
    }

    toggleInfoEdit = () => {
        let editable = this.state.infoEditable;
        this.setState({
            infoEditable: !editable,
        })
    }

    getCase = () => {
        this.setState({
            hasCaseThrownError: true,
            hasFilesThrownError: true,
            isFetchingCase: true,
            isFetchingFiles: true,
        });
        let url = `/api/case/` + this.props.case + `/`;
        axios
            .get(url, { headers: { Authorization: AuthHeader() }, 'Cache-Control': 'no-cache' })
            .then(response => {
                this.setState({
                    currCase: response.data,
                    isFetchingCase: false,
                    hasCaseThrownError: false,
                    patient_name :response.data.patient_name,
                    case_id : response.data['case_id'],
                    receiving_date: response.data['receiving_date'],
                    gender: response.data.age_and_sex.split('/')[1][1],
                    age_and_sex: response.data['age_and_sex'],
                    part_designation: response.data['part_designation'],
                    case_type: response.data['case_type'],
                    current_status_tag: response.data['validation_status']
                });

                let caseFiles = _.filter(this.state.currCase.files, (file) => {
                    return file
                });

                let caseFileList = _.map(caseFiles, (caseFile) => {
                    return {
                        uid: caseFile.id,
                        name: caseFile.path.split('/').pop(),
                        status: 'done',
                        url: caseFile.path,
                        label: caseFile.label,
                        storage_path: caseFile.storage_path
                    };
                });

                this.getCaseSlides(response.data);

                this.get_comments();

                this.setState({
                    caseFileList: caseFileList,
                    isFetchingFiles: false,
                    hasFilesThrownError: false,
                })

            }).then(() => {
                this.getCaseValidationData();
                this.getCaseNotes();
                this.getSlidesPostGlassEligibility();
            })
            .catch(err => {
                message.error("Cases could not be retrieved. Contact Admin.");
                this.setState({
                    currCase: [],
                    caseFileList: [],
                    isFetchingCase: false,
                    isFetchingFiles: false,
                    isFetchingSlides: false,
                    isFetchingPendingScans: false,
                    isFetchingFilesSource: false,
                    hasSlidesThrownError: true,
                    hasPendingScansThrownError: true,
                    hasCaseThrownError: true,
                    hasFilesThrownError: true,
                    hasFilesSourceThrownError: true,
                });
                console.log(err);
            });
    }

    handleChangeUploadTRF = (info) => {
        if(info.file.status === 'done'){
            let url = `/api/case/` + this.props.case + `/`;
        axios
                .get(url, { headers: { Authorization: AuthHeader() }, 'Cache-Control': 'no-cache' })
                .then(response => {
                    this.setState({
                        currCase: response.data,
                        isFetchingCase: false,
                        hasCaseThrownError: false,
                    });
                })
            }
        }

    openTRF = (url) => {
            window.open(url, '_blank')
        }

    getCaseSlides = (currCase) => {
        this.setState({
            hasSlidesThrownError: true,
            isFetchingSlides: true,
            isFetchingPendingScans: true,
            hasPendingScansThrownError: true,
        });
        let url = `/api/nonpagslides/?case_id=` + currCase.id;
        axios
            .get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
            .then(response => {
                let allSlidesUpdated = {};
                for (let i = 0; i < response.data.length; i++) {
                    allSlidesUpdated[i] = response.data[i];
                }

                this.setState({
                    caseScans: allSlidesUpdated,
                    caseScansArray: response.data,
                    selectedRowKeys: [],
                    previewVisible: false,
                    showFile: false,
                    previewFile: '',
                    isFetchingSlides: false,
                    hasSlidesThrownError: false,
                })
            })
            .catch(err => {
                message.error("Slides could not be retrieved. Contact Admin.");
                console.log(err);
                this.setState({
                    caseScans: {},
                    caseScansArray: [],
                    selectedRowKeys: [],
                    previewVisible: false,
                    showFile: false,
                    previewFile: '',
                    isFetchingSlides: false,
                    isFetchingPendingScans: false,
                })
            });
    }

    get_comments = () => {
        let url = '/api/microscopy_observations/?case_id=' + this.state.currCase.id;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                let data = response.data;
                for (let i = 0; i < data.length; i++) {
                    data[i].key = data[i].id;
                    let modifiedValue = decodeStringWithNewLine(data[i].observation);
                    data[i].observation_original = data[i].observation;
                    data[i].observation = modifiedValue;
                }
                this.setState({
                    comments: data,
                    isFetchingComments: false,
                    hasCommentsThrownError: false,
                });
            })
            .catch(err => {
                console.log("Failed request", err);
                this.setState({
                    comments: [],
                    isFetchingComments: false,
                });
            });
    }

    handleUpload = (morphle_id, id, allIds, uploadDebug, uploadDebugOnly) => {
        this.props.dispatch(startUpload(morphle_id, id, allIds, uploadDebug, uploadDebugOnly));
    }

    handleDelete = (slide, button) => {
        if (button) {
            let newCaseScans = {};
            let newCaseScansArray = [];
            for (let i = 0; i < this.state.caseScansArray.length; i++) {
                if (slide.id != this.state.caseScansArray[i].id) {
                    newCaseScans[i] = this.state.caseScans[i];
                    newCaseScansArray.push(this.state.caseScansArray[i]);
                }
            }
            this.setState({
                caseScans: newCaseScans,
                caseScansArray: newCaseScansArray
            });
        }
        this.props.dispatch(deleteSlide(slide, this.props.urlState));
    }

    starSelectedRows = () => {
        this.state.selectedRowKeys.map((value, index) => {
            this.handleStarButton(this.state.caseScans[value], true);
        });
    }

    uploadSelectedRows = () => {
        this.state.selectedRowKeys.map((value, index) => {
            let slide = this.state.caseScans[value];
            this.props.dispatch(startUpload(slide.morphle_id, slide.id, this.props.allIds, false, false));
        });
    }

    tileSelectedRows = () => {
        this.state.selectedRowKeys.map((value, index) => {
            this.props.dispatch(startTiling(this.state.caseScans[value].morphle_id, this.props.allIds));
        });
    }

    unstarSelectedRows = () => {
        this.state.selectedRowKeys.map((value, index) => {
            this.handleStarButton(this.state.caseScans[value], false);
        });
    }

    deleteSelectedRows = (e) => {
        this.state.selectedRowKeys.map((value, index) => {
            if (this.state.caseScans[value] == undefined) return;
            this.handleDelete(this.state.caseScans[value], false);
        });
        let newCaseScans = {};
        let newCaseScansArray = [];
        for (let i = 0; i < this.state.caseScansArray.length; i++) {
            if (!this.state.selectedRowKeys.includes(i)) {
                newCaseScans[i] = this.state.caseScans[i];
                newCaseScansArray.push(this.state.caseScansArray[i]);
            }
        }
        this.setState({
            caseScans: newCaseScans,
            caseScansArray: newCaseScansArray
        });
        message.loading("Scheduling slides for Deletion");
    }

    handleStarButton = (slide, input) => {
        let newCaseScans = this.state.caseScans;
        let newCaseScansArray = this.state.caseScansArray;
        let index = newCaseScansArray.findIndex((item) => slide.id === item.id);
        newCaseScans[index].starred = input;
        newCaseScansArray[index].starred = input;
        this.setState({
            caseScans: newCaseScans,
            caseScansArray: newCaseScansArray
        });
        let val = {
            morphle_id: slide.morphle_id,
            path: slide.path,
            date: slide.date,
            specimen_type: slide.specimen_type,
            name: slide.name,
            starred: input,
            displayOrder: slide.displayOrder
        };
        this.props.dispatch(slideUpdateAlert(slide.id, val));
    };

    handleNameChange = (slide, newName) => {
        let newCaseScans = this.state.caseScans;
        let newCaseScansArray = this.state.caseScansArray;
        let index = newCaseScansArray.findIndex((item) => slide.id === item.id);
        newCaseScans[index].name = newName;
        newCaseScansArray[index].name = newName;
        this.setState({
            caseScans: newCaseScans,
            caseScansArray: newCaseScansArray
        });
        let val = {
            morphle_id: slide.morphle_id,
            path: slide.path,
            date: slide.date,
            specimen_type: slide.specimen_type,
            name: newName,
            starred: slide.starred,
            displayOrder: slide.displayOrder
        };
        this.props.dispatch(slideUpdateAlert(slide.id, val));
    };

    openDeleteModal = () => {

        var deletedIDs = "";

        this.state.selectedRowKeys.map((value, index) => {
            if (this.state.caseScans[value] == undefined) return;
            if (index != this.state.selectedRowKeys.length - 1) deletedIDs += (this.state.caseScans[value] || {}).name + ", ";
        });

        deletedIDs += this.state.caseScans[this.state.selectedRowKeys[this.state.selectedRowKeys.length - 1]].name;

        const deleteRowsHandle = this.deleteSelectedRows;

        Modal.confirm({
            title: 'Following scans will be deleted. Confirm?',
            icon: <InfoCircleOutlined></InfoCircleOutlined>,
            content: (<Row>{deletedIDs}</Row>),
            onOk() { deleteRowsHandle() },
            onCancel() { }
        });
    }

    removeFromCase = () => {
        let slides = []
        this.state.selectedRowKeys.map((value, index) => {
            if (this.state.caseScans[value] == undefined) return;
            slides.push(this.state.caseScans[value].id)
        });
        let url = `/api/remove_slides_from_case/?slides=${slides}`
        axios.get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
            .then(response => {
                if (response.status == 200) {
                    let caseScans = this.state.caseScansArray;
                    slides.map((slideValue, slideIndex) => {
                        caseScans.map((caseValue, caseIndex) => {
                            if (slideValue == caseValue.id) {
                                caseScans.splice(caseIndex, 1)
                            }
                        })
                    })
                    this.setState({
                        caseScansArray: caseScans,
                        selectedRowKeys: [],
                    })
                    message.success("Successfully removes slides");
                } else {
                    message.error("Error removing slides. Please contact Admin");
                }
            })
            .catch(err => {
                message.error("Error removing slides. Please contact Admin")
                console.log("Error removing slide. Please contact Admin");
            })
    }

    getRowActionsIfAtleastOneRowSelected = () => {
        if (this.state.caseScansArray.length > 0) {
            return this.state.selectedRowKeys.length > 0 ?
                <ButtonGroup key={1}>
                    <Button onClick={() => { this.setState({ selectedRowKeys: Object.keys(this.state.caseScans).map((value) => parseInt(value)) }) }}>
                        Select All Scans
                    </Button>
                    <Button key={0} onClick={() => { this.setState({ selectedRowKeys: [] }) }}>
                        Cancel Selection
                        </Button>
                    {cookie.loadAll().superuser === "true" ?
                        <Button key={1} onClick={this.tileSelectedRows}>
                            Start Tiling
                        </Button> : undefined}
                    {cookie.loadAll().deployment_mode === 'offline' ?
                        <Button key={-1} onClick={this.uploadSelectedRows}>
                            Upload
                            <CloudUploadOutlined style={{ fontSize: "13px" }} />
                        </Button> : undefined}
                    <Button key={2} onClick={this.starSelectedRows}>
                        Star
                            <StarFilled style={{ color: "#f5667b", fontSize: "13px" }} />
                    </Button>
                    <Button key={3} onClick={this.unstarSelectedRows}>
                        Unstar
                            <StarFilled style={{ fontSize: "13px" }} />
                    </Button>
                    {cookie.loadAll().is_staff === "true" ?
                        <Button key={4} onClick={this.openDeleteModal}>
                            Delete
                                <DeleteFilled style={{ fontSize: "13px" }} />
                        </Button> : undefined}
                    <Button key={3} onClick={this.removeFromCase}>
                        Remove From Case
                        <StopOutlined />
                    </Button>
                </ButtonGroup> :
                <>
                <Col span={16}>
                    <Button onClick={() => { this.setState({ selectedRowKeys: Object.keys(this.state.caseScans).map((value) => parseInt(value)) }) }}>
                        Select All Scans
                    </Button>
                </Col>
                <Col span={2}>
                    <Select
                        value={this.state.sortingMethod}
                        placeholder="Sort List"
                        onChange={this.handleSortingMethodChange}
                        style={{width:230}}
                    >
                        <Option value="name">
                            {" "}
                            A-Z
                        </Option>
                        <Option value="-name">
                            {" "}
                            Z-A
                        </Option>
                        <Option value="date_time">
                            Oldest First
                        </Option>
                        <Option value="-date_time">
                            Recent First
                        </Option>
                        <Option value="number">
                            By Slide Number
                        </Option>
                    </Select>
                </Col>
              </>
        } else {
            return null;
        }

    }

    getRowActions = () => {
        return <Row>
            <Col>
                <span key={0} style={{ marginRight: "10px" }}>
                    {this.state.selectedRowKeys.length > 0 ? "What do you want to do with the selected scans ? " : ""}
                </span>
                {this.getRowActionsIfAtleastOneRowSelected()}
            </Col>
        </Row>
    }

    updatePage = (pageNumber) => {
        historyMP.push('/' + globalUrlPrefix + '/dashboard?page=' + pageNumber);
        this.props.dispatch(changePage(pageNumber, this.props.urlState));
    }

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        let fileExtension = getFileExtension(file.name);
        if (fileExtension == 'jpg' || fileExtension == 'jpeg' || fileExtension == 'png') {
            this.setState({
                showFile: true,
            });
        } else {
            this.setState({
                showFile: false,
            });
        }
        this.setState({
            previewFile: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handleScreenshotPreview = (slide) => {
        this.setState({ 
            screenshotVisible: true, 
            screenshots: slide.screenshots 
        });
    }

    handleScreenshotCancel = () => {
        this.setState({
            screenshotVisible: false
        })
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    handleChangeCaseFile = ({ fileList, file }) => {
        let flag = 0;
        let caseFileList = _.map(fileList, (caseFile) => {
            if (caseFile.response) {
                if (caseFile.response.result) {
                    return {
                        uid: caseFile.response.result.id,
                        name: caseFile.response.result.path.split('/').pop(),
                        status: 'done',
                        url: caseFile.response.result.path,
                        label: caseFile.response.result.label,
                        storage_path: caseFile.response.result.storage_path
                    };
                } else {
                    flag = 1;
                }
            } else {
                return caseFile;
            }
        });
        this.setState({
            caseFileList: caseFileList
        });
        if (flag != 0) {
            message.error("Failed to Upload File. Contact Admin");
        }

    }

    beforeUpload(file, fileList) {
        if (file.type === "application/pdf" || file.type.substring(0, 5) === "image") {
            return true;
        } else {
            message.error('Invalid file type selected!!');
            return false;
        }
    }

    removeFileFromCase = (file) => {
        let url = `/api/remove_case_file/?case_id=${this.state.currCase.case_id}&file=${file.uid}`;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                let slide_file_list = this.state.slideFileList;
                _.remove(slide_file_list, (val) => {
                    return file.uid === val.uid
                });
                this.setState({
                    slideFileList: slide_file_list
                });
                message.success("Removed Slide Image from report " + file.name);
            })
            .catch(err => {
                message.error("Failed to Save Case. Contact Admin");
                console.log(err);
            });

    }

    handleSortingMethodChange = (value) => {
        this.setState({
            sortingMethod: value,
        })
    }

    getTableSource = (allSlides, getIntegersfromString, sortingMethod) => {
        let allSlidesList = []
        let sortedAllSlides = []

        switch (sortingMethod) {
            case 'name':
                sortedAllSlides = getLexicographicalSorting(allSlides)
                break;
            case '-name':
                sortedAllSlides = getReverseLexicographicalSorting(allSlides)
                break;
            case 'number':
                sortedAllSlides = getNumericalSorting(allSlides, getIntegersfromString)
                break;
            case 'date_time':
                sortedAllSlides = getChronologicalSorting(allSlides)
                break;
            case '-date_time':
                sortedAllSlides = getReverseChronologicalSorting(allSlides)
                break;
            default:
                break;
        }

        sortedAllSlides.map((key) => {
            let slide = allSlides[key];
            if (slide.slide_details === undefined || slide.slide_details === "") {
                slide.slide_details = "-";
            }
            if (!slide.isDeleted) {
                allSlidesList.push({
                    key: parseInt(key),
                    id: slide.id,
                    slide_details: slide.slide_details,
                    unread: slide.unread,
                    type: openInNewTabType.SLIDE,
                    slideCard: this.getSlideCard(slide),
                })
            }
        });
        return allSlidesList;
    }

    getSlideCard = (slide) => {
        return <div>
            <Row>
                <Col span={3} style={{ marginTop: 35 }}>
                    <Row>
                        <Col span={8}>
                            {getStarredComponent(slide, this.handleStarButton)}
                        </Col>
                        <Col span={8}>
                            {getUploadComponent(slide, this.props.allIds, this.handleUpload)}
                        </Col>
                        {cookie.loadAll().superuser === "true" ?
                            <Col span={8}>
                                {getTilingComponent(slide, this.props.allIds, this.props.dispatch)}
                            </Col> :
                            null
                        }
                    </Row>
                </Col>
                <Col span={8} style={{ marginTop: 10 }}>
                    <Row>
                        {slide.unread ?
                            <b>{slide.name}</b> :
                            <span style={{fontWeight: 400}}>{slide.name}</span>
                        }
                    </Row>
                    {slide.case_id.length > 0 ?
                        <Row style={{ marginTop: 5, fontSize: 13 }}>
                            <span>{"CASE ID: " + slide.case_id}</span>
                        </Row> :
                        this.state.currentHoverRowID === slide.id && cookie.loadAll().is_staff === "true" ?
                            <Row style={{ marginTop: 5 }}>
                                {getAssignCaseButtonComponent(slide, this.handleAssignCase)}
                            </Row> :
                            null
                    }
                    <Row style={{ marginTop: 10 }}>
                        {getTagsComponent(slide, this.props.dispatch, this.props.urlState)}
                    </Row>
                </Col>
                <Col span={4}>
                    {getPreviewComponent(slide, this.is_cloud, this.isMobile)}
                </Col>
                <Col span={2}>
                    {getLabelComponent(slide, this.is_cloud, this.isMobile)}
                </Col>
                <Col span={slide.screenshots.length === 0 ? 7 : 6}>
                    {this.state.currentHoverRowID === slide.id && cookie.loadAll().is_staff === "true" ?
                        <div>
                            <Row style={{ marginTop: 10 }}>
                                <Col offset={cookie.loadAll().superuser === "true" ? 4 : cookie.loadAll().is_staff === "true" ? 16 : 20} span={4}>
                                    {getAssignCaseComponent(slide, this.handleAssignCase)}
                                </Col>
                                {cookie.loadAll().is_staff === "true" ?
                                    <Col span={4}>
                                        {getExportComponent(slide, this.props.allIds, this.props.dispatch)}
                                    </Col> :
                                    null
                                }
                                {cookie.loadAll().superuser === "true" ?
                                    <Col span={4}>
                                        {getDbEntryComponent(slide)}
                                    </Col> :
                                    null
                                }
                                {cookie.loadAll().superuser === "true" ?
                                    <Col span={4}>
                                        {getMorphleIDComponent(slide)}
                                    </Col> :
                                    null
                                }
                                {cookie.loadAll().superuser === "true" || (cookie.load('morpheus_setting') || {}).show_delete_slide_action ?
                                    <Col span={4}>
                                        {getDeleteComponent(slide, this.handleDelete)}
                                    </Col> :
                                    null
                                }
                            </Row>
                            <br />
                        </div> :
                        null
                    }
                    <Row style={this.state.currentHoverRowID === slide.id && cookie.loadAll().is_staff === "true" ? {} : { marginTop: 35 }}>
                        <Col offset={cookie.loadAll().superuser === "true" ? 0 : cookie.loadAll().is_staff === "true" ? 3 : 18} span={6}>
                            {slide.unread ?
                                <b>{slide.date}</b> :
                                <span>{slide.date}</span>
                            }
                        </Col>
                        {cookie.loadAll().is_staff === "true" ?
                            <Col span={5}>
                                {slide.unread ?
                                    <b>{slide.date}</b> :
                                    <span>{slide.date}</span>
                                }
                            </Col> :
                            null
                        }
                        {cookie.loadAll().superuser === "true" ?
                            <Col span={3}>
                                {getObjectiveComponent(slide, slideViewType.CASEVIEW)}
                            </Col> :
                            null
                        }
                        {cookie.loadAll().is_staff === "true" ?
                            <Col span={4}>
                                {slide.unread ?
                                    <b>{slide.scan_speed}</b> :
                                    <span>{slide.scan_speed}</span>
                                }
                            </Col> :
                            null
                        }
                        {cookie.loadAll().is_staff === "true" ?
                            <Col span={6}>
                                {slide.unread ?
                                    <b>{slide.scan_time}</b> :
                                    <span>{slide.scan_time}</span>
                                }
                            </Col> :
                            null
                        }
                    </Row>
                </Col>
                { slide.screenshots.length === 0 ? null :
                <Col span={1}>
                    <PictureOutlined onClick={(e) => {e.stopPropagation(); this.handleScreenshotPreview(slide)}} className="icon-hover" 
                        style={{marginTop: '25px', fontSize: '23px', marginLeft: '10px', padding: '10px'}} />
                </Col>}
            </Row>
        </div>
    }

    handleSave = row => {
        let newCaseScans = this.state.caseScans;
        let newCaseScansArray = this.state.caseScansArray;
        let index = newCaseScansArray.findIndex((item) => row.id === item.id);
        newCaseScans[index].slide_details = row.slide_details;
        newCaseScansArray[index].slide_details = row.slide_details;
        this.setState({
            caseScans: newCaseScans,
            caseScansArray: newCaseScansArray
        });
        this.sendDescToCloud(row.id, row.slide_details, newCaseScansArray[index].displayOrder);
    };

    sendDescToCloud = (slideId, desc, displayOrder) => {
        this.props.dispatch(descUpdateAlert(slideId, desc, displayOrder));
    };

    toAllSlidesPage = (e) => {
        this.setState({
            addSlideModalVisible: true,
        });
    }

    closeModal = () => {
        this.getCase();
        this.setState({
            addSlideModalVisible: false,
        });
    }

    handleInputTypeChange = (value) => {
        console.log("input",value)
        this.setState({inputType:value})
    }

    addCaseNotes = () => {
        this.setState({inputType:"case-notes"})
    }

    notaddCaseNotes = () => {
        this.setState({inputType:""})
    }

    // preglassData: {"diagnosis":"","quality":"","diagnostic-certainity":"","notes":""},
    //         postglassData: {
    //             "diagnosis":"","quality":"","diagnostic-certainity":"","notes":"","concordance":""
    //         },
    //         postWashoutData: {
    //             "diagnosis":""
    //         }

    handleChangeDiagnosis = (event) => {
        if(this.state.inputType === 'preglass'){
            let data = this.state.preglassData
            data['diagnosis'] = event.target.value
            this.setState({preglassData:data})
        }
        else if(this.state.inputType === 'postglass'){
            let data = this.state.postglassData
            data['diagnosis'] = event.target.value
            this.setState({postglassData:data})
        }
        else if(this.state.inputType === 'postwashout'){
            let data = this.state.postWashoutData
            data['diagnosis'] = event.target.value
            this.setState({postWashoutData:data})
        }
    }
    handleChangeQuality = (value) => {
        if(this.state.inputType === 'preglass'){
            let data = this.state.preglassData
            data['quality'] = value
            this.setState({preglassData:data})
        }
        else if(this.state.inputType === 'postglass'){
            let data = this.state.postglassData
            data['quality'] =value
            this.setState({postglassData:data})
        }
        else if(this.state.inputType === 'postwashout'){
            let data = this.state.postWashoutData
            data['quality'] = value
            this.setState({postWashoutData:data})
        }
    }
    handleChangeDiagnosticCertainity = (value) => {
        if(this.state.inputType === 'preglass'){
            let data = this.state.preglassData
            data['diagnostic-certainity'] = value
            this.setState({preglassData:data})
        }
        else if(this.state.inputType === 'postglass'){
            let data = this.state.postglassData
            data['diagnostic-certainity'] = value
            this.setState({postglassData:data})
        }
        else if(this.state.inputType === 'postwashout'){
            let data = this.state.postWashoutData
            data['diagnostic-certainity'] = value
            this.setState({postWashoutData:data})
        }
    }
    handleChangeNotes = (event) => {
        if(this.state.inputType === 'preglass'){
            let data = this.state.preglassData
            data['notes'] = event.target.value
            this.setState({preglassData:data})
        }
        else if(this.state.inputType === 'postglass'){
            let data = this.state.postglassData
            data['notes'] = event.target.value
            this.setState({postglassData:data})
        }
        else if(this.state.inputType === 'postwashout'){
            let data = this.state.postWashoutData
            data['notes'] = event.target.value
            this.setState({postWashoutData:data})
        }
        else if(this.state.inputType === 'case-notes'){
            let data = event.target.value
            this.setState({caseNotes:data})
        }
    }
    handleChangeConcordance = (value) => {
        if(this.state.inputType === 'preglass'){
            let data = this.state.preglassData
            data['concordance'] = value
            this.setState({preglassData:data})
        }
        else if(this.state.inputType === 'postglass'){
            let data = this.state.postglassData
            data['concordance'] = value
            this.setState({postglassData:data})
        }
        else if(this.state.inputType === 'postwashout'){
            let data = this.state.postWashoutData
            data['concordance'] = value
            this.setState({postWashoutData:data})
        }
    }
    handleChangeSlide = (value) => {
        if(this.state.inputType === 'preglass'){
            let data = this.state.preglassData
            data['slide_id'] = value
            console.log("valval",data)
            this.setState({preglassData:data})
        }
        else if(this.state.inputType === 'postglass'){
            let data = this.state.postglassData
            data['slide_id'] = value
            this.setState({postglassData:data})
        }
    }

    convertLiterals = (value) => {
        if(value === "preglass"){
            return "PRE-GLASS"
        }
        else if(value === "postglass"){
            return "POST-GLASS"
        }
        else{
            return value
        }
    }

    getSlideNamefromId = (value) => {
        let name = ""
        Object.values(this.state.caseScans).map((Scan, index) => {
            if(Scan.id === value){
                console.log(Scan.name)
                name = Scan.name
            }
        })
        return name;
    }

    submitData = () => {
        
        let data;
        if(this.state.inputType === 'preglass'){
         //   console.log(this.state.preglassData)
            data = this.state.preglassData
        }
        else if(this.state.inputType === 'postglass'){
         //   console.log(this.state.postglassData)
            data = this.state.postglassData
        }
        else if(this.state.inputType === 'postwashout'){
         //   console.log(this.state.postWashoutData)
            data = this.state.postWashoutData
        }
        else if(this.state.inputType === 'case-notes'){
            data = this.state.caseNotes
        }
        console.log("data", data)
        let caseValidationData = this.state.caseValidationData
        for(let i=0;i<caseValidationData.length; i++){
            if(caseValidationData[i]['input_type'] === this.convertLiterals(this.state.inputType) && caseValidationData[i]['slide_name'] === this.getSlideNamefromId(data['slide_id'])){
                message.info("PreGlass Data for the slide already recorded", 2.5);
                return;
            }
        }
        let url = `/api/store_validation_data/`;
        axios.post(url,querystring.stringify({'type':this.state.inputType,'case_id':this.state.currCase.id,'data':JSON.stringify(data),user: parseInt(cookie.load('user_id'))}))
            .then(response => {
                console.log(response.data)
                message.success("Case/Cases Successfully Updated");
            })
            .catch()   
        this.setState({
            preglassData:{"diagnosis":"","quality":"","diagnostic-certainity":"","notes":""}
        })
        this.setState({
            postglassData:{ "diagnosis":"","quality":"","diagnostic-certainity":"","notes":"","concordance":""}
        })    
        this.setState({
            postWashoutData:{ "diagnosis":""}
        })
        // this.getCaseValidationData();
        this.getCaseNotes();
        window.location.reload();
    }

    getCaseValidationData = () => {
        console.log("getting data ");
        let url = `/api/user_case_specific_validation_data/?case_id=${this.state.currCase.id}&user=${parseInt(cookie.load('user_id'))}`;
        axios.get(url)
            .then(response => {
                console.log("Getting data for ", this.state.currCase.id, parseInt(cookie.load('user_id')), url)
                console.log("user validation ", response.data.result)
                // let validation_data = response.data.result
                // setTimeout(() => {  this.setState({caseValidationData:response.data["result"]}) }, 2000);
                // let validation_data = response.data.result
                // this.setState({
                //     caseValidationData: response.data.result,
                // })

                this.setState(Object.assign({}, this.state, {
                    caseValidationData: response.data.result,
                }));
                console.log("printing again ", response.data.result, this.state.caseValidationData);
            })
    }

    getSlidesPostGlassEligibility = () => {
        let url = `/api/get_slides_postglass_eligibility/?case_id=${this.state.currCase.id}&user=${parseInt(cookie.load('user_id'))}`
        axios.get(url).then(response => {
            console.log("postpost",response.data['data'])
            this.setState({
                slidesPostGlassEligibility: response.data["data"],
                slidesPostGlassReason: response.data["reason"]
            })
        })
    }

    getCaseNotes = () => {
        let url = `/api/get_case_notes/?case_id=${this.state.currCase.id}`
        axios.get(url).then(response => {
            this.setState({caseNotesData:response.data["result"]})
        })
    }

    changeUser = (value) => {
        console.log(value)
        this.setState({
            user_id: value
        })
        let url = `/api/user_case_specific_validation_data/?case_id=${this.state.currCase.id}&user=${value}`;
        axios.get(url)
            .then(response => {
                console.log("Getting data for ", this.state.currCase.id, parseInt(cookie.load('user_id')), url)
                console.log("user validation ", response.data.result)
                // let validation_data = response.data.result
                // setTimeout(() => {  this.setState({caseValidationData:response.data["result"]}) }, 2000);
                // let validation_data = response.data.result
                // this.setState({
                //     caseValidationData: response.data.result,
                // })

                this.setState(Object.assign({}, this.state, {
                    caseValidationData: response.data.result,
                }));
                console.log("printing again ", response.data.result, this.state.caseValidationData);
                    // get new users status tag for that case
                    let url = `/api/get_status_tag/?user_id=${this.state.user_id}&case_id=${this.state.currCase.id}`;
                    axios.get(url).then(res => {
                        this.setState({
                            current_status_tag: res.data['tag'],
                        })
                    })
                
            })
    }


    updateCase = () => {
     let val = {
        id: this.state.currCase.id,
            case_id: this.state.case_id.toUpperCase(),
            patient_name: this.state.patient_name,
            gender :this.state.gender,
            receiving_date: this.state.receiving_date,
            part_designation: this.state.part_designation,
            case_type : this.state.case_type
        }
        let url = '/api/update_case/'
        axios.post(url, querystring.stringify(val), {headers: { Authorization: AuthHeader()}})
            .then(response => {
                message.success("Case Updated Successfully")
                this.getCase();
            })
            .catch(err => {
                message.error("Failed to Update Case. Contact Admin");
            })
            this.toggleInfoEdit();
        }

    onChangeReceivingDate = (date, dateString) => {
        this.setState({
        receiving_date: dateString
        });
        console.log("change", dateString, date)
        }

    onChangeReportingDate = (date, dateString) => {
        this.setState({
            reporting_date: dateString
        });
        console.log("change", dateString, date)
    }

    onChangeCaseId = (event) => {
        this.setState({
            case_id: event.target.value,
        })
        console.log("change", event.target.value)
    }

    onChangePatientName = (event) => {
        this.setState({
            patient_name : event.target.value,
        })
        console.log("change", event.target.value)
    }

    handleChangePartDesignation = (event) => {
        this.setState({
            part_designation:event.target.value
        });
    }

    handleCaseTypeChange = (value) => {
        this.setState({
            case_type:value
        });
    }
    onChangeGender = (value) => {
        console.log("change", value)
        this.setState({
            gender: value,
        });
    }

    onSaveTag = () => {
        let url = `/api/change_status_tag/`
        let post_value = {
            case_id: this.state.currCase.id,
            user_id: this.state.user_id,
            new_tag: this.state.current_status_tag 
        }
        
        axios.post(url, querystring.stringify(post_value),{headers:{Authorization:AuthHeader()}})
            .then(response => { 
                message.info("Status Updated")
            }).catch(err => {
                message.info("Status update failed. Contact Admin")
            })
    }

    onChangeTag = (value) => {
        console.log("eee", value)
        let new_tag;
        for(let i=0;i<this.state.status_tags.length;i++){
            if(parseInt(this.state.status_tags[i].id) === parseInt(value)){
                new_tag = this.state.status_tags[i];
            }
        }
        this.setState({ 
            current_status_tag: new_tag.tag,
        })   
    }

    render() {
        let tags_menu_items = [];
        let status_tags = this.state.status_tags;
        for(let i=0; i<status_tags.length;i++){
            tags_menu_items.push(
                <Select.Option key={status_tags[i].id} value={status_tags[i].id}>
                    {status_tags[i].tag}
                </Select.Option>
            )
        }

        let tags_menu = tags_menu_items

        let initial_username=cookie.loadAll().username;
        if((this.state.currCase||{}).assigned_users){
            let curr_case = JSON.parse(JSON.stringify(this.state.currCase))
            let assigned_users = curr_case['assigned_users']
            for(let i=0;i<assigned_users.length;i++){
                if(parseInt(assigned_users[i].id) === parseInt(this.state.user_id)){
                    initial_username = assigned_users[i].username;
                    break;
                }
            }
        }

        let editorButton;
        if(!this.state.infoEditable){
            editorButton = <Button style={{display: 'inline-block',float: 'right', marginTop:'-10px'}} onClick={this.toggleInfoEdit}> 
                                <EditOutlined />
                            </Button>
        }else{
            editorButton = <Button style={{display: 'inline-block',float: 'right',  marginTop:'-10px'}} onClick={this.updateCase}> 
                                SAVE
                            </Button>
        }

        

        
        let assigned_users_options;
        if((this.state.currCase||{}).assigned_users){
            let curr_case = JSON.parse(JSON.stringify(this.state.currCase))
            let assigned_users = curr_case['assigned_users']
            // assigned_users.push({
            //     "id":  cookie.load('user_id'),
            //     "username": cookie.load('username'),
            // })
            assigned_users_options = Object.values(assigned_users).map((user, index) => {
                return <Select.Option key={index} value={user.id}>{user.username}</Select.Option>
            })
        }
        console.log("case",this.state.currCase)
        let slide_options = Object.values(this.state.caseScans).map((Scan, index) => {
            return <Select.Option key={index} value={Scan.id}>{Scan.name}</Select.Option>
        })
        // console.log("opt", slide_options)
        let preglassData = this.state.preglassData
        let postglassData = this.state.postglassData
        let postWashoutData = this.state.postWashoutData
        const comments_columns = [
            {
                title: 'User',
                dataIndex: 'user_name',
                key: 'user_name',
                width: 100,
            },
            {
                title: 'Scan Name',
                dataIndex: 'slide_name',
                key: 'slide_name',
                width: 200,
            },
            {
                title: 'Observation',
                dataIndex: 'observation',
                key: 'observation',
            },
        ];

        let overallFetchingState = (this.state.isFetchingCase && this.state.isFetchingComments && this.state.isFetchingFiles
            && this.state.isFetchingPendingScans && this.state.isFetchingSlides);

        let overallErrorState = (this.state.hasCaseThrownError && this.state.hasCommentsThrownError && this.state.hasFilesThrownError
            && this.state.hasPendingScansThrownError && this.state.hasSlidesThrownError);

        let tableSource = [];

        tableSource = this.getTableSource(this.state.caseScansArray, this.getIntegersfromString, this.state.sortingMethod);

        let finalColumns = [{
            dataIndex: 'slideCard',
            key: 'slideCard',
        }];

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys: selectedRowKeys
                })
            },
            selectedRowKeys: this.state.selectedRowKeys
        };

        const onRow = (record, rowIndex) => {
            return {
                onContextMenu: (event) => {
                    event.preventDefault();
                    this.props.dispatch(makeMenuVisible({ visible: true, x: event.clientX, y: event.clientY, record: record, height: this.props.height }));
                },
                onMouseEnter: (event) => {
                    event.preventDefault();
                    this.setState({
                        currentHoverRowID: record.id,
                    });
                },
                onMouseLeave: (event) => {
                    event.preventDefault();
                    this.setState({
                        currentHoverRowID: -1,
                    });
                }
            };
        }

        
        const onFinishFailed = () => {
            message.error('Submit failed!');
          };

        let rowActions = this.isMobile ? undefined :
            [
                <Divider key={3} />,
                <Row key={0} className="row-actions">
                    {this.getRowActions()}
                </Row>
            ];

        const components = {
            body: {
                // row: EditableFormRow,
                cell: EditableCell,
            },
        };

        finalColumns = finalColumns.map(col => {
            if (col.dataIndex === 'tagsComponent') {
                return col;
            } else {
                return {
                    ...col,
                    onCell: record => ({
                        onClick: (event) => {
                            if (event.ctrlKey) {
                                linkViewerNewTab(record);
                            } else {
                                linkViewer(record);
                            }
                        },
                    }),
                };
            }
        });

        if (overallFetchingState || overallErrorState) {
            this.props.setTabsView(false);
        } else {
            this.props.setTabsView(true);
        }
        let earliest_stamp = null
        if(!this.state.isFetchingSlides && this.state.caseScansArray.length>0){
            earliest_stamp = this.state.caseScansArray[0].date_time.split('T')[0]
        }

        let infoComponent;

        if(!this.state.infoEditable){
            infoComponent =  <div><div className="heading-style sub-heading-style">
                                    <b className="case-scans-heading-style">Details</b>
                                    {/* <Button style={{display: 'inline-block',float: 'right'}} onClick={this.toggleInfoEdit}> 
                                        <EditOutlined />
                                    </Button> */}
                                    {editorButton}
                                </div>
                                <div>
                                    <br />
                                    <Row>
                                        <Col offset={1}>
                                            {this.state.isFetchingCase ? getLoadingComponent() :
                                                this.state.hasCaseThrownError ? getErrorComponent("Not able to retrieve the case details.") :
                                                    <div>
                                                        <Descriptions size="small" column={3}>
                                                            <Descriptions.Item label={<b>Case ID</b>}>{this.state.currCase.case_id}</Descriptions.Item>
                                                            <Descriptions.Item label={<b>Patient's Name</b>}>{this.state.currCase.patient_name}</Descriptions.Item>
                                                            <Descriptions.Item label={<b>DOB</b>}>{this.state.currCase.age_and_sex == "null/" ? "-" : this.state.currCase.age_and_sex }</Descriptions.Item>
                                                            <Descriptions.Item label={<b>Receiving Date</b>}>{this.state.currCase.receiving_date}</Descriptions.Item>
                                                                        {/* <Descriptions.Item label={<b>Reporting Date</b>}>{this.state.currCase.reporting_date}</Descriptions.Item> */}
                                                                        <Descriptions.Item label={<b>Part Designation</b>}>{this.state.currCase.part_designation}</Descriptions.Item>
                                                                        <Descriptions.Item label={<b>Case Type</b>}>{this.state.currCase.case_type}</Descriptions.Item>
                                                                        <Descriptions.Item label={<b>Earliest Scan Date</b>}>{earliest_stamp}</Descriptions.Item>
                                                                        {this.state.currCase.trf_uploaded?
                                                                        <Descriptions.Item label={null}>
                                                                            <Tag color="green" style={{cursor: 'pointer'}} onClick={() => this.openTRF("/case-files/" + this.state.currCase.id +"/case_trf/"+this.state.currCase.id+".pdf")}>
                                                                                View Ancillary Clinical Information
                                                                                </Tag>
                                                                        </Descriptions.Item>
                                                                        :
                                                                        <Descriptions.Item label={null}>
                                                                                <Upload
                                                                                    action="/api/upload_trf/"
                                                                                    headers={{ Authorization: AuthHeader() }}
                                                                                    maxCount={1}
                                                                                    data={{ 'case_id': this.state.currCase.id }}
                                                                                    accept="image/*, .pdf"
                                                                                    beforeUpload={this.beforeUpload}
                                                                                    onChange={this.handleChangeUploadTRF}
                                                                                >
                                                                                    <div>
                                                                                        <UploadOutlined/>
                                                                                        <b> Upload Ancillary Clinical Information</b>
                                                                                    </div>
                                                                                </Upload>
                                                                        </Descriptions.Item>
                                                                        }
                                                                    </Descriptions>
                                                    </div>
                                            }
                                        </Col>
                                    </Row>
                                    <br />
                                </div></div>
        }

        else{
            infoComponent = <div>
                                <div className="heading-style sub-heading-style">
                                    <b className="case-scans-heading-style">Details</b>
                                    {/* <Button style={{display: 'inline-block',float: 'right'}} onClick={this.toggleInfoEdit}> 
                                        <EditOutlined />
                                    </Button> */}
                                    {editorButton}
                                </div>
                                <div>
            <br />
            <Row>
                <Col offset={1}>
                    {this.state.isFetchingCase ? getLoadingComponent() :
                        this.state.hasCaseThrownError ? getErrorComponent("Not able to retrieve the case details.") :
                            <div>
                                <Descriptions size="small" column={3}>
                                    <Descriptions.Item label={<b>Case ID</b>}>
                                        <Input onChange={this.onChangeCaseId} value={this.state.case_id}>
                                        </Input>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<b>Patient's Name</b>}>
                                        <Input onChange={this.onChangePatientName} 
                                            value={this.state.patient_name}></Input>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<b>Gender</b>}>
                                                <Select className="input-field-add-case"
                                                                value={this.state.gender}
                                                                placeholder="Select Status"
                                                                onChange={this.onChangeGender}
                                                >
                                                    <Option value="M">Male</Option>
                                                    <Option value="F">Female</Option>
                                                    <Option value="Others">Others</Option>
                                                </Select>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<b>Receiving Date</b>}>
                                        <DatePicker onChange={this.onChangeReceivingDate}
                                            value={new moment(this.state.receiving_date)}
                                            className="input-field-add-case"
                                            allowClear={false}
                                        />
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<b>Part Designation</b>}>
                                        <Input placeholder="Part Designation" value={this.state.part_designation} onChange={this.handleChangePartDesignation} />
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<b>Case Type</b>}>
                                        <Select
                                            placeholder="Select Case Type"
                                            value={this.state.case_type}
                                            onChange={this.handleCaseTypeChange}
                                            className="input-field-add-case"
                                        >
                                            <Option value="H&E">H&E</Option>
                                            <Option value="IHC">IHC</Option>
                                            <Option value="Cyto(pap)">Cyto(pap)</Option>
                                            <Option value="Cyto(DQ)">Cyto(DQ)</Option>
                                            <Option value="Frozen">Frozen</Option>
                                            <Option value="Spec. stain - GMS">Spec. stain - GMS</Option>
                                            <Option value="Spec. stain - AFB">Spec. stain - AFB</Option>
                                            <Option value="Spec. stain - PAS">Spec. stain - PAS</Option>
                                            <Option value="Spec. stain - PASd">Spec. stain - PASd</Option>
                                            <Option value="Spec. stain -
                                            Trichrome">Spec. stain - Trichrome</Option>
                                            <Option value="Spec. stain - Iron">Spec. stain - Iron</Option>
                                            <Option value="Spec. stain - Retic">Spec. stain - Retic</Option>
                                        </Select>
                                    </Descriptions.Item>
                                </Descriptions>
                            </div>
                    }
                </Col>
            </Row>
            <br />
        </div>

        </div>
        }



        return (overallFetchingState) ?
            getLoadingComponent() :
            (overallErrorState) ?
                getErrorComponent() :
                <>
                <div>

                    {/* <div className="heading-style sub-heading-style">
                        <b className="case-scans-heading-style">Details</b>
                    </div> */}
                    <div>
                        {/* <br /> */}
                        {/* <Row>
                            <Col offset={1}>
                                {this.state.isFetchingCase ? getLoadingComponent() :
                                    this.state.hasCaseThrownError ? getErrorComponent("Not able to retrieve the case details.") :
                                        <div>
                                            <Descriptions size="small" column={3}>
                                                <Descriptions.Item label={<b>Case ID</b>}>{this.state.currCase.case_id}</Descriptions.Item>
                                                <Descriptions.Item label={<b>Patient's Name</b>}>{this.state.currCase.patient_name}</Descriptions.Item>
                                                <Descriptions.Item label={<b>DOB</b>}>{this.state.currCase.age_and_sex}</Descriptions.Item>
                                                <Descriptions.Item label={<b>Receiving Date</b>}>{this.state.currCase.receiving_date}</Descriptions.Item>
                                                <Descriptions.Item label={<b>Part Designation</b>}>{this.state.currCase.part_designation}</Descriptions.Item>
                                                <Descriptions.Item label={<b>Case Type</b>}>{this.state.currCase.case_type}</Descriptions.Item>
                                                <Descriptions.Item label={<b>Earliest Scan Date</b>}>{earliest_stamp}</Descriptions.Item>
                                                {this.state.currCase.trf_uploaded?
                                                <Descriptions.Item label={null}>
                                                       <Tag color="green" style={{cursor: 'pointer'}} onClick={() => this.openTRF("/case-files/" + this.state.currCase.case_id +"/case_trf/"+this.state.currCase.case_id+".pdf")}>
                                                         View Ancillary Clinical Information
                                                        </Tag>
                                                </Descriptions.Item>
                                                :
                                                <Descriptions.Item label={null}>
                                                        <Upload
                                                            action="/api/upload_trf/"
                                                            headers={{ Authorization: AuthHeader() }}
                                                            maxCount={1}
                                                            data={{ 'case_id': this.state.currCase.case_id }}
                                                            accept="image/*, .pdf"
                                                            beforeUpload={this.beforeUpload}
                                                            onChange={this.handleChangeUploadTRF}
                                                        >
                                                            <div>
                                                                <UploadOutlined/>
                                                                <b> Upload Ancillary Clinical Information</b>
                                                            </div>
                                                        </Upload>
                                                </Descriptions.Item>
                                                }
                                            </Descriptions>
                                        </div>
                                }
                            </Col>
                        </Row> */}
                        {infoComponent}
                        <br />
                    </div>

                    <div className="heading-style sub-heading-style">
                        <b className="case-scans-heading-style">Case Scans</b>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        {cookie.loadAll().is_staff === "true" ?
                            <Button onClick={this.toAllSlidesPage}>
                                Add Slides to Case
                            </Button> : null
                        }
                    </div>
                    <div>
                        <br />
                        <Row>
                            <Col>
                                {this.state.isFetchingSlides ? getLoadingComponent() :
                                    this.state.hasSlidesThrownError ? getErrorComponent("Not able to retrieve slides of the case.") :
                                        <div>
                                            <Row key={0} className="fixed-controls-dashboard" id="fixed-controls-dashboard">
                                                <Col span={24}>
                                                    {rowActions}
                                                    <Divider />
                                                </Col>
                                            </Row>
                                            <Row key={1} className="scrollable-dashboard">
                                                <Table
                                                    key={2}
                                                    loading={this.state.isFetching}
                                                    dataSource={tableSource}
                                                    columns={finalColumns}
                                                    pagination={false}
                                                    showHeader={false}
                                                    rowSelection={this.isMobile ? undefined : rowSelection}
                                                    onRow={onRow}
                                                    components={components}
                                                    scroll={{ y: '550px' }}
                                                    className="custom-table-height custom-table-selection"
                                                    rowClassName={(record, index) => (record.unread ? 'slide-table-rows custom-hover-table-box editable-row unread' : 'slide-table-rows custom-hover-table-box editable-row read')}
                                                />
                                            </Row>
                                        </div>
                                }
                            </Col>
                        </Row>
                        <br />
                    </div>

                    <div className="heading-style sub-heading-style">
                        <b className="case-scans-heading-style">Microscopy Observations</b>
                    </div>
                    <div>
                        <br />
                        <Row>
                            {/* <Col>
                                {this.state.isFetchingComments ? getLoadingComponent() :
                                    this.state.hasCommentsThrownError ? getErrorComponent("Not able to contact server. Please contact admin.") :
                                        <div>
                                            <Table
                                                className="custom-hover-table"
                                                style={{ whiteSpace: 'pre-wrap' }}
                                                key={4}
                                                dataSource={this.state.comments}
                                                columns={comments_columns}
                                                size='small'
                                                pagination={false}
                                                bordered
                                                scroll={{ y: '400px' }}
                                            />
                                        </div>
                                }
                            </Col> */}
                            <>
                            {/* <Select onChange={this.handleInputTypeChange
                            } style={{ width: 250, marginBottom: 50 }} placeholder="Select Input">
                                <Option value="preglass">Pre-Glass Diagnosis</Option>
                                <Option value="postglass">Post-Glass Diagnosis</Option>
                                <Option value="case-notes">Case Notes</Option>
                            </Select> */}
                            {this.state.inputType === "case-notes"?<p>Case Notes</p>
                            :
                            <Button type="default" onClick={this.addCaseNotes}>Add Case Notes</Button>}
                            
                            </>
                        </Row>
                        <Row style={{borderTop:'20px'}}>
                            <div>
                                {(this.state.inputType === "preglass")?
                                    <Form 
                                    labelCol={{
                                        span: 6,
                                      }}
                                      wrapperCol={{
                                        span: 8,
                                      }}
                                      layout="vertical"
                                     // onSubmit={onFinish}
                                    //   onFinishFailed={onFinishFailed}
                                    >
                                        <Form.Item label="Pre-Glass Diagnosis" style={{borderTop:'5'}} >
                                            <TextArea onChange={this.handleChangeDiagnosis} value={preglassData['diagnosis']}/>
                                        </Form.Item>
                                        <Form.Item label="Scan Name">
                                            <Select onChange={this.handleChangeSlide} value={preglassData['slide_id']}>
                                                {slide_options}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="Quality of Scan">
                                            <Select onChange={this.handleChangeQuality} value={preglassData['quality']}>
                                                <Select.Option value="High">High</Select.Option>
                                                <Select.Option value="Adequate">Adequate</Select.Option>
                                                <Select.Option value="Borderline Adequate">Borderline Adequate</Select.Option>
                                                <Select.Option value="Inadequate">Inadequate</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="Diagnostic Certainity">
                                            <Select onChange={this.handleChangeDiagnosticCertainity} value={preglassData['diagnostic-certainity']}>
                                                <Select.Option value="Highly Confident">Highly Confident</Select.Option>
                                                <Select.Option value="Mostly Confident">Mostly
                                                confident</Select.Option>
                                                <Select.Option value="Not Confident">Not confident</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        {/* <Form.Item label="Case Notes" value={preglassData['notes']}>
                                            <TextArea onChange={this.handleChangeNotes}/>
                                        </Form.Item> */}
                                        <Button type="primary" htmlType="submit" onClick={this.submitData}>
                                            Submit
                                        </Button>
                                    </Form>
                                :(this.state.inputType === "postglass")?
                                    <Form 
                                    labelCol={{
                                        span: 6,
                                    }}
                                    wrapperCol={{
                                        span: 8,
                                    }}
                                    layout="vertical"
                                    // onFinish={this.onFinish}
                                    >
                                        <Form.Item label="Post-Glass Diagnosis" style={{borderTop:'5'}}>
                                            <TextArea onChange={this.handleChangeDiagnosis} value={postglassData['diagnosis']}/>
                                        </Form.Item>
                                        <Form.Item label="Scan Name">
                                            <Select  onChange={this.handleChangeSlide} value={preglassData['slide_id']}>
                                                {slide_options}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="Quality of Scan">
                                            <Select onChange={this.handleChangeQuality} value={postglassData['quality']}>
                                                <Select.Option value="High">High</Select.Option>
                                                <Select.Option value="Adequate">Adequate</Select.Option>
                                                <Select.Option value="Borderline Adequate">Borderline Adequate</Select.Option>
                                                <Select.Option value="Inadequate">Inadequate</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="Diagnostic Certainity">
                                            <Select onChange={this.handleChangeDiagnosticCertainity} value={postglassData['diagnostic-certainity']}>
                                                <Select.Option value="Highly Confident">Highly Confident</Select.Option>
                                                <Select.Option value="Mostly Confident">Mostly Confident</Select.Option>
                                                <Select.Option value="Not Confident">Not confident</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        {/* <Form.Item label="Case Notes" value={postglassData['notes']}>
                                            <TextArea onChange={this.handleChangeNotes}/>
                                        </Form.Item> */}
                                        <Form.Item label="Post Glass Concordance Categories">
                                            <Select onChange={this.handleChangeConcordance} value={postglassData['concordance']}>
                                                <Select.Option value="Complete Agreement">Complete Agreement</Select.Option>
                                                <Select.Option value="Minor Disagree No Impact">Minor Disagree - No Clinical Impact</Select.Option>
                                                <Select.Option value="Minor Disagree Minimal Impact">Minor Disagree - Minimal Clinical Impact</Select.Option>
                                                <Select.Option value="Major Disagreement">Major Disagreement</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Button type="primary" htmlType="submit" onClick={this.submitData}>
                                            Submit
                                        </Button>
                                    </Form>
                                :(this.state.inputType === "case-notes")?
                                <Form 
                                    labelCol={{
                                        span: 6,
                                    }}
                                    wrapperCol={{
                                        span: 8,
                                    }}
                                    layout="vertical"
                                    >
                                        <Form.Item  style={{borderTop:'5'}}>
                                            <TextArea onChange={this.handleChangeNotes} value={this.state.caseNotes}/>
                                        </Form.Item>
                                        <Button  htmlType="submit" onClick={this.submitData}>
                                            Submit
                                        </Button>
                                        <Button  htmlType="submit" onClick={this.notaddCaseNotes} style={{marginLeft:'10px'}}>
                                            Cancel
                                        </Button>
                                    </Form>:null
                                }
                            </div>
                        </Row>
                        <br />
                        <Table
                            className="custom-hover-table-case custom-table-height custom-table-selection custom-table-header"
                            //  columns={validation_columns}
                            dataSource={this.state.caseNotesData}
                            size="small"
                            >
                            <Column title="DATE" dataIndex="date" width='250px'/>
                            <Column title="PATHOLOGIST"  dataIndex="user" width='250px'/>
                            <Column title="NOTES" dataIndex="notes"/>                            
                        </Table>
                        <br />
                        {/* <Table
                            className="custom-hover-table-case custom-table-height custom-table-selection custom-table-header"
                            //  columns={validation_columns}
                            dataSource={this.state.caseValidationData}
                            size="small"
                            >
                            <Column title="SCAN" dataIndex="slide_name"></Column>
                            <Column title="DIAGNOSIS_TYPE"  dataIndex="input_type"/>
                            <Column title="DATE" dataIndex="date"/>
                            <Column title="DIAGNOSIS"  dataIndex="diagnosis"/>
                            <Column title="QUALITY OF SCAN" dataIndex="quality_of_scan"/>
                            <Column title="DIAGNOSTIC CERTAINTY" dataIndex="diagnostic_certainty"/>
                            <Column title="NOTES"  dataIndex="notes"/>
                            <Column title="CONCORDANCE DESIGNATION" dataIndex="concordance_designation"/>
                        </Table> */}

                        {/* dropdown for staff to see everyones result */}
                        {cookie.loadAll().is_staff === "true"?
                        <Form.Item label="User Name">
                            <Select style={{width:'300px'}} placeholder={"Select assigned user to see info"} onChange={this.changeUser} defaultValue={cookie.loadAll().username}
                                  value={initial_username} > 
                                {assigned_users_options}
                            </Select>
                        </Form.Item>
                        :null}
                        <EditableFormTable 
                            currCase = {this.state.currCase}
                            caseValidationData = {this.state.caseValidationData}
                            caseScansArray = {this.state.caseScansArray}
                            slidesPostGlassEligibility = {this.state.slidesPostGlassEligibility}
                            user_id = {this.state.user_id}
                            slidesPostGlassReason = {this.state.slidesPostGlassReason}
                            app={false}
                            currSlideId={undefined}
                            />

                        <Row style={{marginTop:'20px'}}>
                            STATUS:
                        </Row>
                        <Row>
                            
                                <Form.Item>
                                    <Select style={{width:'300px'}} placeholder={"Select assigned user to see info"} onChange={this.onChangeTag} value={this.state.current_status_tag}>
                                        {tags_menu}
                                    </Select>
                                    <Button style={{marginTop:'5px', marginLeft:'5px'}} onClick={this.onSaveTag}>
                                    SAVE
                                    </Button>
                                </Form.Item>    
                        </Row>
                    <br />
                    </div>
                        <Divider style={{ backgroundColor: "#ffffff00" }} />
                        <SlideSuggestions modalVisible={this.state.addSlideModalVisible} currCase={this.state.currCase} closeModal={this.closeModal} />
                        <Modal visible={this.state.screenshotVisible} footer={null} onCancel={this.handleScreenshotCancel} width={1500}>
                            {getScreenshotCarousel(this.state.screenshots)}
                        </Modal>
                    </div>
                    {/* <div>
                        <div className="heading-style sub-heading-style">
                            <b className="case-scans-heading-style">Case Notes</b>
                        </div>

                        <br />
                        <Row>

                        </Row>
                    </div> */}
                    
                    <div>
                        <div className="heading-style sub-heading-style">
                            <b className="case-scans-heading-style">Uploaded Files</b>
                        </div>

                        <br />
                        
                        <Row>
                            <Col>
                                {this.state.isFetchingFiles ? getLoadingComponent() :
                                    this.state.hasFilesThrownError ? getErrorComponent("Not able to contact server. Please contact admin.") :
                                        <div>
                                            <Upload
                                                listType="picture"
                                                action="/api/upload_case_file/"
                                                headers={{ Authorization: AuthHeader() }}
                                                fileList={this.state.caseFileList}
                                                data={{ 'case_id': this.state.currCase.case_id }}
                                                onPreview={this.handlePreview}
                                                onChange={this.handleChangeCaseFile}
                                                onRemove={this.removeFileFromCase}
                                                showUploadList={{
                                                    showDownloadIcon: true,
                                                    downloadIcon: <ToTopOutlined />,
                                                }}
                                                className='upload-list-inline'
                                                accept="image/*, .pdf"
                                                beforeUpload={this.beforeUpload}
                                            >
                                                <div style={{ textAlign: 'center' }}>
                                                    <Button>
                                                        <UploadOutlined /> Upload
                                                    </Button>
                                                </div>
                                            </Upload>
                                            <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel} width={1000}>
                                                {this.state.showFile ?
                                                    <img alt="example" style={{ width: '100%' }} src={this.state.previewFile} />
                                                    : <div>No preview available</div>
                                                }
                                            </Modal>
                                            {
                                                this.state.caseFileList.length > 0 ?
                                                    null :
                                                    <div>
                                                        <Empty description="No Files Found" />
                                                    </div>
                                            }
                                        </div>
                                }
                            </Col>
                        </Row>
                        <br />
                    </div>

                    <Divider style={{ backgroundColor: "#ffffff00" }} />
                    <SlideSuggestions modalVisible={this.state.addSlideModalVisible} currCase={this.state.currCase} closeModal={this.closeModal} />
                    </>
    }
}

const mapStateToProps = (state) => {

    return {
        allSlides: state.slidesReducer,
        allIds: Object.keys(state.slidesReducer),
        numPages: state.dashboardReducer.numPages,
        urlState: state.searchReducer,
        height: state.contextMenuReducer.popupProps.height
    }
}
export default connect(mapStateToProps)(AllScansCliaCaseView);
