import {get, patch, post} from "../helper/request";
import {displayError} from "../helper/display.error";
import {LOAD_VIEWER_SETTINGS, UPDATE_VIEWER_SETTING} from "../actionTypes/viewer.settings.const";

export const loadViewerSettings = () => dispatch =>
	get("/api/viewer/settings/")
		.then(settings => dispatch({
			type: LOAD_VIEWER_SETTINGS,
			settings,
		}))
		.catch(error => displayError("Error in loading all viewer settings", error))

export const _updateSetting = setting => ({
	type: UPDATE_VIEWER_SETTING,
	setting,
});

export const loadViewerSetting = id => dispatch =>
	get(`/api/viewer/settings/${id}`)
		.then(setting => dispatch(_updateSetting(setting)))
		.catch(error => displayError("Error in loading viewer setting", error));

export const createViewerSetting = setting => dispatch =>
	post(`/api/viewer/settings/`, setting)
		.then(setting => dispatch(_updateSetting(setting)))
		.catch(error => displayError("Error in creating viewer setting", error));

export const updateViewerSetting = (id, setting) => dispatch => {
	patch(`/api/viewer/settings/${id}/`, setting)
		.then()
		.catch(error => displayError("Error in updating viewer setting", error));
	// [Priyanshu] - Dispatching asynchronously to update faster across application rather than waiting for the response
	dispatch(_updateSetting({id, ...setting}));
}
