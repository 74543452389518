import {Vector as VectorSource} from 'ol/source.js';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import {Stroke, Style} from 'ol/style.js';
import {getPrefixedUrl} from '../../../../../utils/utils';
import {
	getLayer,
	getMap,
	getProjection,
	getResolutions,
	getView,
	getZoomScale
} from "../../../../gammaviewer/utils/map_utils";

export const initialiseMap = (slide_data, anno_data, positiveLabels, negativeLabels, vector, onMapClick, setState) => {

	let tileSize = [anno_data.tile_width, anno_data.tile_height];
	let urlSuffix = `${slide_data.path}ugc/annotations/${anno_data.id}/{z}/x{x}y{y}.jpg`;
	let zoomLevels = anno_data.z_levels.split(",");
	let resolutions = getResolutions(slide_data, zoomLevels);
	let imageShape = getImageShape(slide_data, anno_data);
	let projection = getProjection(imageShape);
	let initState = {
		x: imageShape[0] / 2,
		y: imageShape[1] / 2,
		digitalZoomStatus: true,
		z: 0,
		r: 0
	}
	let view = getView(projection, resolutions, initState, {}, imageShape, 0);
	let url = getPrefixedUrl(urlSuffix, slide_data);
	let layer = getLayer(tileSize, projection, resolutions, url);
	let annomap = getMap(slide_data.id, view, layer, false);
    
    drawMarkers(vector, slide_data, anno_data, positiveLabels, negativeLabels);

    annomap.on('click', onMapClick);

    let maxZoom = zoomLevels.length - 1;
    let zoomScale =  getZoomScale(anno_data, maxZoom);

    setState({
        isFetching: false,
        slide_data: anno_data, 
        maxZoom, 
        zoomLevels,
        zoomScale, 
        view, 
        layer, 
        annomap
    });
}

const blue = "rgba(0, 91, 183, 0.81)";
const red = "#ff0000";

const blueStyle = [
    new Style({
        stroke: new Stroke({
            color: blue, 
            width: 1
        })
    })
]

const redStyle = [
    new Style({
        stroke: new Stroke({
            color: red, 
            width: 1
        })
    })
]

export const styleFunction = feature => feature.getProperties().positive ? redStyle : blueStyle;

export function findClosestFeature(vector, coordinate, distance) {  
    let x = coordinate[0];
    let y = coordinate[1];
    let closestFeature = null;
    let previousCityBlockDistance = Infinity;
    let extent = [x-distance, y-distance, x+distance, y+distance];
    vector.getSource().forEachFeatureInExtent(extent,function(feature) {
        let geo = feature.getGeometry();
        let coord = geo.getClosestPoint(coordinate);
        let minCityBlockDistance = Math.abs(x - coord[0]) + Math.abs(y - coord[1]);
        if (minCityBlockDistance <= distance && 
            minCityBlockDistance < previousCityBlockDistance) {
              previousCityBlockDistance = minCityBlockDistance;
              closestFeature = feature;
        }
    });
    return closestFeature;
}

export const drawMarkers = (vector, slide_data, anno_data, positiveLabels, negativeLabels) => {
    let allFeatures = [];
    positiveLabels.map((pos, index) => {
        let adjustedPos = [];
        
        pos.map((posItem) => {
            adjustedPos.push([slide_data.uperpixel * posItem[0], slide_data.uperpixel * posItem[1]]);
        });

        let feature = new Feature({
            geometry: new Polygon([adjustedPos]),
            id: anno_data.id + "p" + index,
            name: "polygon"
        })
        feature.set('positive', true);
        feature.setId(anno_data.id + "p" + index);
        allFeatures.push(feature);
    })
    negativeLabels.map((neg, index) => {
        let adjustedNeg = [];
        
        neg.map((negItem) => {
            adjustedNeg.push([slide_data.uperpixel * negItem[0], slide_data.uperpixel * negItem[1]]);
        });

        let feature = new Feature({
            geometry: new Polygon([adjustedNeg]),
            id: anno_data.id + "n" + index,
            name: "polygon"
        })
        feature.set('positive', false);
        feature.setId(anno_data.id + "n" + index);
        allFeatures.push(feature);
    })
    vector.setSource(new VectorSource({
        features: allFeatures, 
        wrapX: false
    }));
}

const getImageShape = (slide_data, anno_data) => {
    let imageWidth, imageHeight;

    imageWidth = slide_data.uperpixel *  anno_data.tile_width * anno_data.num_tiles_width;
    imageHeight = slide_data.uperpixel * anno_data.tile_height * anno_data.num_tiles_height;
    
    return [imageWidth, imageHeight];
}
