import {createTheme} from '@mui/material';

// base color palette
export const morphleBaseTheme1 = createTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#1c002e',
        },
        secondary: {
            main: '#00f548',
        },
        background: {
            default: '#b60f0f',
            paper: '#330470',
        },
        info: {
            main: '#f321f0',
        },
        divider: 'rgba(255,255,255,0.12)',
        text: {
            main: '#ffffff',
            primary: '#00ffff',
            secondary: '#ffffff',
            hint: 'rgba(227,15,15,0.5)',
            disabled: 'rgba(84,23,23,0.5)',
        },
        headBarIcon: {
            primary: '#ffffff',
            secondary: '#00f548',
        }
    }
});

// extension for tabs
export const morphleTheme1 = createTheme(morphleBaseTheme1, {
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    // fill: 'white', //@Spandan -- Used exclusively for DeepBio Logo, do not remove/modify
                    "&.Mui-selected": {
                        backgroundColor: morphleBaseTheme1.palette.primary.main,
                        color: morphleBaseTheme1.palette.secondary.main,
                        fill: morphleBaseTheme1.palette.secondary.main,
                        minWidth: 50,
                        maxWidth: 50
                    },
                    // "&.MuiTabs": {
                    //     backgroundColor: morphleBaseTheme1.palette.secondary.main,
                    //     minWidth: 40,
                    //     width: 40
                    // }
                    "&.MuiTab-root":{
                        minWidth: 50,
                        maxWidth: 50,
                    }
                },
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: morphleBaseTheme1.palette.secondary.main,
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root:  {
                    "&:disabled": {
                        backgroundColor: 'rgba(79, 87, 86, 0.3) !important',
                        color: '#828282 !important',

                    },

                }
            }
        },
        MuiButtonBase: {
          styleOverrides: {
              root: {
                  "&:disabled": {
                      // backgroundColor: '#C0C0C0 !important',
                      color: 'grey !important',
                      pointerEvents: 'auto !important',
                      cursor: 'not-allowed !important',
                      // backgroundColor: 'transparent !important',
                      // "& :hover": {
                      //     color: 'yellow !important',
                      //     pointerEvents: 'auto !important',
                      //     cursor: 'not-allowed !important',
                      // },
                  },

              },
          }
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    scrollbarColor: "#6b6b6b #2b2b2b",
                    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                        backgroundColor: "rgba(0,0,0,0)",
                        // scrollbarWidth: 'thin',
                        width:'0.4vw'
                    },
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                        borderRadius: 8,
                        backgroundColor: `${morphleBaseTheme1.palette.secondary.main}8D`,
                        minHeight: 4,
                        maxHeight: 8,
                    },
                    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                        backgroundColor: morphleBaseTheme1.palette.secondary.main,
                    },
                    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                        backgroundColor: morphleBaseTheme1.palette.secondary.main,
                        width:'0.8vw'
                    },
                    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: morphleBaseTheme1.palette.secondary.main,
                    },
                    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                        backgroundColor: morphleBaseTheme1.palette.secondary.main,
                    },
                },
            }
        },
        MuiInput:{
            styleOverrides:{
                root:{
                    borderRadius: 2,
                    justifyContent:'flex-end',
                    margin:0, padding:0,
                    alignItems: 'flex-end',
                    "&:hover": {
                        // backgroundColor: "rgba(0,255,255,0.3)"
                    },
                    "&$focused": {
                        backgroundColor: "red"
                    },
                    "& .MuiSelect-icon": {
                        color: "white",
                      },
                    "& .MuiSelect-select": {
                        padding:0,
                    },
                    "& .MuiInputBase-input": {
                        padding:0,
                        borderRadius: 2,
                    },
                    "& .MuiSelect-nativeInput": {
                        padding:0,
                    },
                    "& input" :{
                        textAlign:'right',
                    },
                    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                        display: "none"
                    }
                },
                underline: {
                    // "&:before": {
                    //     borderBottomColor: "#0ba338"
                    // },
                    "&:hover:not(.Mui-focused):before": {
                        borderBottomColor: "rgba(0,255,255,0.6)"
                    },
                    "&:after": {
                        borderBottomWidth: '1px',
                        borderBottomColor: "rgba(0,0,0,0)"
                    },
                },
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    justifyContent:'flex-end',
                    alignItems: 'center',
                    margin:0,
                    padding:0,
                },
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    padding: 0,
                    margin: 0,
                },
                input: {
                    padding:0,
                    margin:0,
                },
                "& .MuiInputBase-input": {
                    padding:0,
                    margin:0,
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    justifyContent:'flex-end !important',
                    alignItems: 'center',
                    margin:0,
                    padding:0,
                    borderRadius: 2,
                }
            }
        },
        MuiFilledInput: {
            styleOverrides:{
                root: {
                    // backgroundColor: "#0b1c3b",
                    borderRadius: 2,
                    alignItems: 'flex-end',
                    justifyContent:'flex-end',
                    margin:0, padding:0,
                    "&:hover": {
                        // backgroundColor: "rgba(0,255,255,0.3)"
                    },
                    "&$focused": {
                        backgroundColor: "red"
                    },
                    "& .MuiSelect-icon": {
                        color: "white",
                      },
                    "& .MuiSelect-select": {
                        padding:0,
                    },
                    "& .MuiInputBase-input": {
                        padding:0,
                        borderRadius: 2,
                    },
                    "& .MuiSelect-nativeInput": {
                        padding:0,
                    }
                },
                underline: {
                    "&:before": {
                        borderBottomColor: "#0ba338"
                    },
                    "&:hover:not(.Mui-focused):before": {
                        borderBottomColor: "rgba(0,255,255,0.6)"
                    },
                    "&:after": {
                        // focused
                        borderBottomWidth: '1px',
                        borderBottomColor: "secondary",
                    },
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides:{
                root: {
                    // backgroundColor: "#0b1c3b",
                    borderRadius: 2,
                    alignItems: 'flex-start',
                    justifyContent:'flex-start',
                    margin:0, padding:'0 !important',
                    "& .MuiSelect-icon": {
                        color: "white",
                    },
                    "& .MuiSelect-select": {
                        padding:0,
                    },
                    "& .MuiInputBase-input": {
                        padding:0,
                        paddingLeft:3,
                        paddingRight:3,
                        borderRadius: 2,
                    },
                    "& .MuiSelect-nativeInput": {
                        padding:0,
                    }
                },
                underline: {
                    "&:before": {
                        borderBottomColor: "#0ba338"
                    },
                    "&:hover:not(.Mui-focused):before": {
                        borderBottomColor: "rgba(0,255,255,0.6)"
                    },
                    "&:after": {
                        // focused
                        borderBottomWidth: '1px',
                        borderBottomColor: "secondary",
                    },
                },
            }
        },
        MuiInputLabel: {
            styleOverrides:{
                filled: {
                    color: "purple",
                    "& .Mui-focused": {
                        color: "green"
                    },
                    ".MuiFormControl-root:hover &:not($focused)": {
                    color: "red"
                    }
                }
            }
        },
    }
});
