import { BloodGridRBCActions } from "../actionTypes/bloodGridRBC.const"

const InitialState = {
  size_range_low:-1,
  size_range_high:-1,
  convexity_range_low:-1,
  convexity_range_high:-1,
  eccentricity_range_low:-1,
  eccentricity_range_high:-1,
  mean_color_range_low:-1,
  mean_color_range_high:-1,
  extreme_size_range_low:-1,
  extreme_size_range_high:-1,
  extreme_convexity_range_low:-1,
  extreme_convexity_range_high:-1,
  extreme_eccentricity_range_low:-1,
  extreme_eccentricity_range_high:-1,
  extreme_mean_color_range_low:-1,
  extreme_mean_color_range_high:-1,
  page: '',
  update_image: false,
  selected_grid:'',
  page_size:'',
  all_grids:[],
  zStack: 0,
  total_pages:0,
  total_count:0,
  new_selected_on_next_page:false,
  curr_grids:[],
  selected_grid_overall_id: '',
  fetching_grid: false,
}

export const bloodGridRBCReducer = ( state = InitialState , action) => {
  switch(action.type){
        case BloodGridRBCActions.RBC_UPDATE_SIZE_RANGE:
            if(action.response == undefined){
                return Object.assign({}, state, {InitialState});
            }
            console.table("xxxx", action.response)
            return Object.assign({}, state, {
                size_range_low:action.response[0],
                size_range_high: action.response[1],
            })

        case BloodGridRBCActions.RBC_UPDATE_CONVEXITY_RANGE:
            if(action.response == undefined){
                return Object.assign({}, state, {InitialState});
            }
            return Object.assign({}, state, {
                convexity_range_low:action.response[0],
                convexity_range_high: action.response[1],
            })

        case BloodGridRBCActions.RBC_UPDATE_ECCENTRICITY_RANGE:
            if(action.response == undefined){
                return Object.assign({}, state, {InitialState});
            }
            return Object.assign({}, state, {
                eccentricity_range_low:action.response[0],
                eccentricity_range_high: action.response[1],
            })

        case BloodGridRBCActions.RBC_UPDATE_MEAN_COLOR_RANGE:
            if(action.response == undefined){
                return Object.assign({}, state, {InitialState});
            }
            return Object.assign({}, state, {
                mean_color_range_low:action.response[0],
                mean_color_range_high: action.response[1],
            })

        case BloodGridRBCActions.RBC_UPDATE_EXTREME_SIZE_RANGE:
            if(action.response == undefined){
                return Object.assign({}, state, {});
            }
            return Object.assign({}, state, {
                extreme_size_range_low:action.response[0],
                extreme_size_range_high: action.response[1],
            })

        case BloodGridRBCActions.RBC_UPDATE_EXTREME_CONVEXITY_RANGE:
            if(action.response == undefined){
                return Object.assign({}, state, {});
            }
            return Object.assign({}, state, {
                extreme_convexity_range_low:action.response[0],
                extreme_convexity_range_high: action.response[1],
            })

        case BloodGridRBCActions.RBC_UPDATE_EXTREME_ECCENTRICITY_RANGE:
            if(action.response == undefined){
                return Object.assign({}, state, {});
            }
            return Object.assign({}, state, {
                extreme_eccentricity_range_low:action.response[0],
                extreme_eccentricity_range_high: action.response[1],
            })
        
        case BloodGridRBCActions.RBC_UPDATE_EXTREME_MEAN_COLOR_RANGE:
        if(action.response == undefined){
            return Object.assign({}, state, {});
        }
        return Object.assign({}, state, {
            extreme_mean_color_range_low:action.response[0],
            extreme_mean_color_range_high: action.response[1],
        })

        case BloodGridRBCActions.RBC_GRID_SELECTED:
            if(action.response == undefined){
                return Object.assign({}, state, {});
            }
            return Object.assign({}, state, {
                selected_grid:action.response,
                selected_grid_overall_id: action.response.overall_count,
            })
        
        case BloodGridRBCActions.RBC_CHANGE_IMAGE:
            return Object.assign({}, state, {
                update_image:action.response,
            })

        case BloodGridRBCActions.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: action.response,
            })

        case BloodGridRBCActions.UPDATE_PAGE_SIZE:
            return Object.assign({}, state, {
                page_size:action.response,
            })

        case BloodGridRBCActions.UPDATE_ALL_GRIDS:
            return Object.assign({}, state, {
                all_grids:action.response,
            })
        
        case BloodGridRBCActions.UPDATE_Z_STACK:
            return Object.assign({}, state, {
                zStack:action.response,
            })
        
        case BloodGridRBCActions.UPDATE_TOTAL_COUNT:
            return Object.assign({}, state, {
                total_count:action.response,
            })
        
        case BloodGridRBCActions.UPDATE_TOTAL_PAGES:
            return Object.assign({}, state, {
                total_pages:action.response,
            })

        case BloodGridRBCActions.UPDATE_NEW_SELECTED_ON_NEW_PAGE:
            return Object.assign({}, state, {
                new_selected_on_next_page: action.response,
            })   
            
        case BloodGridRBCActions.UPDATE_CURR_GRIDS:
            return Object.assign({}, state, {
                curr_grids: action.response,
            })

        case BloodGridRBCActions.SELECTED_GRID_OVERALL_ID:
            return Object.assign({}, state, {
                selected_grid_overall_id: action.response,
            })

        case BloodGridRBCActions.UPDATE_FETCHING_GRID:
            return Object.assign({}, state, {
                fetching_grid: action.response,
            })

        default :
            return state
  }
}