import React from  "react";
import cookie from "react-cookies";
import axios from "axios";
import Icon from '@ant-design/icons';
import { Row, Col, List, Typography, Slider, Select, message } from 'antd';
import { AuthHeader } from "../../../../helper/auth.token";
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import { Fill, Stroke, Style, Text as OlText } from 'ol/style.js';
import { Vector as VectorSource } from 'ol/source.js';
import LineString from 'ol/geom/LineString';
import Circle from 'ol/geom/Circle';
import { getIcon } from './drawing/draw_tool_maker';
import { getFormattedLength, getFormattedArea, convertSettingsToFilter, getPrefixedUrl } from '../../../../utils/utils';
import CommentComp from "../comments";
import { freehandDrawingKey } from "./drawing/draw_tool_keys";
import { AnnotationsConstants } from '../../../../utils/const';
import { Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

const {Text} = Typography;
const { Option } = Select;

export const getAnnotationList = (slide_id, taggerKey, updateStateCallback) => {
    let url = `/api/annotation/?slide=${slide_id}&anno_drawer=${taggerKey}`;
    axios
    .get(url, { headers: { Authorization: AuthHeader() } })
    .then(response => {
        let data = response.data;
        updateStateCallback(data);
    })
    .catch(err => {
        console.log("Failed Annotation", err);
    });
}

export const addAnnotation = (color, area, perimeter, key, coord, center, taggerType, z, slide_id, updateAnnotationsCallback,numTimesCalled) => {


    let value = {
        area: area,
        perimeter: perimeter,
        color: color,
        new_bounds: coord,
        slide: slide_id,
        creator: parseInt(cookie.load('user_id')),
        shape: key.db_key,
        tool_type: key.name,
        center: center.toString(), 
        anno_drawer: taggerType,
        created_z_level: z,
        // title: key.db_key == AnnotationsConstants.LINE ? getFormattedLength(perimeter) : key == freehandDrawingKey ? getFormattedArea(area) : "",
        title: key.db_key == AnnotationsConstants.LINE ? "R" + numTimesCalled + " " + getFormattedLength(perimeter) : "R" + numTimesCalled + " " + getFormattedArea(area),
        // title: "Region " + numTimesCalled,
        count: numTimesCalled
    };

    postAnnotationToHelper(value, updateAnnotationsCallback);
}

const addTitle = (annotation, title, updateAnnotationsCallback) => {
    annotation.title = title;
    let url = `/api/annotation/` + annotation.id + '/';
    axios
    .put(url, annotation, { headers: { Authorization: AuthHeader() } })
    .then(response => {
        if (response.status === 200 || response.status === 301 || response.status === 302) {
            message.success('Annotation Update Successful', 2.5);
        }
        else if (response.status === 403 || response.status === 401 || response.status === 400) {
            message.error('Annotation Update Failed', 2.5);
        }
        updateAnnotationsCallback();
    })
    .catch(err => {
        message.error('Annotation Update Failed', 2.5);
        console.log("Annotation title change failed", err);
    });
}

const changeColor = (annotation, color, updateAnnotationsCallback) => {
    annotation.color = color;
    let url = `/api/annotation/` + annotation.id + '/';
    axios
    .put(url, annotation, { headers: { Authorization: AuthHeader() } })
    .then(response => {
        if (response.status === 200 || response.status === 301 || response.status === 302) {
            message.success('Annotation Update Successful', 2.5);
        }
        else if (response.status === 403 || response.status === 401 || response.status === 400) {
            message.error('Annotation Update Failed', 2.5);
        }
        updateAnnotationsCallback();
    })
    .catch(err => {
        message.error('Annotation Update Failed', 2.5);
        console.log("Annotation color change failed", err);
    });
}

const postAnnotationToHelper = (value, updateAnnotationsCallback) => {
    let url = `/api/annotation/`;
    axios
    .post(url, value, { headers: { Authorization: AuthHeader() } })
    .then(response => {
        updateAnnotationsCallback();
    })
    .catch(err => {
        console.log("Annotation add failed", err);
    });
}

const colors = Object.values(AnnotationsConstants.ANNO_COLORS);

const annoColor = AnnotationsConstants.DEFAULT_ANNO_COLOR;

const baseText = new OlText({
    font: 'bold 20px "Open Sans", "Helvetica", "sans-serif"',
    placement: AnnotationsConstants.LINE,
    textBaseline: 'top',
    fill: new Fill({
        color: annoColor
    }), 
    backgroundFill: new Fill({
        color: "#ffffff"
    }), 
    backgroundStroke: new Fill({
        color: "#ffffff"
    })
})

const baseStyle = new Style({
    stroke: new Stroke({
        color: annoColor,
        width: 2
    }),
    fill: new Fill({
        color: 'rgba(255, 0, 0, 0.0)'
    })
});

const selectedStyle = new Style({
    stroke: new Stroke({
        color: "#00b3ff", 
        width: 8
    })
})

const otherStyle = new Style({
    stroke: new Stroke({
        color: "white", 
        width: 5
    })
})

const styleFunction = (feature, resolution) => {
    let baseStyleWithText = baseStyle;
    baseText.setText(feature.get('title'));
    if (feature.getProperties().color != undefined && feature.getProperties().color != "") {
        baseStyleWithText.getStroke().setColor(feature.getProperties().color);
        baseText.getFill().setColor(feature.getProperties().color);
    }
    baseStyleWithText.setText(baseText);
    if (feature.getProperties().selected) {
        return [
            selectedStyle, baseStyleWithText
        ]
    } else {
        return [
            otherStyle, baseStyleWithText
        ]
    }
}

export const drawAnnotations = (vector, annotations, selectedAnnotationId, showAnno) => {
    let allFeatures = [];
    annotations.map((annotation, index) => {
        let feature;
        if (annotation.new_bounds !== null) {
            if (annotation.shape === AnnotationsConstants.POLYGON) {
                feature = new Feature({
                    geometry: new Polygon(JSON.parse(annotation.new_bounds)),
                    id: annotation.id,
                    name: annotation.shape,
                    title: annotation.title,
                });
            }
            else if (annotation.shape === AnnotationsConstants.LINE) {
                feature = new Feature({
                    geometry: new LineString(JSON.parse(annotation.new_bounds)),
                    id: annotation.id,
                    name: annotation.shape,
                    title: annotation.title,
                });
            }
            else if (annotation.shape === AnnotationsConstants.CIRCLE) {
                let bounds = JSON.parse(annotation.new_bounds);
                let center = bounds[0];
                let radius = bounds[1];
                feature = new Feature({
                    geometry: new Circle(center, radius),
                    id: annotation.id,
                    name: annotation.shape,
                    title: ""
                });
            }
            feature.setId(annotation.id);
            feature.set('creator', annotation.anno_drawer);
            feature.set('selected', annotation.id == selectedAnnotationId);
            feature.set('color', annotation.color);
            feature.set('definition', annotation.name)
            allFeatures.push(feature);
        }
        return feature;
    });
    
    vector.setSource(new VectorSource({
        features: showAnno ? allFeatures : [],
        wrapX: false
    }))

    vector.setStyle(styleFunction);
}

const colorMap = {
    "0.2x": "red", 
    "0.4x": "#3498db",
    "0.7x": "#27ae60",
    "1.3x": "#8e44ad",
    "2.5x": "#34495e", 
    "5x": "#e84118",
    "10x": "#f6b93b",
    "20x": "#27ae60",
    "40x": "#3498db", 
    "80x": "#2c3e50",
    "100x": "#d35400",
    "200x": "#B53471",
}

export const getAnnotationsInListView = (currentAnnotations, slide, selectedAnnotationId, onSelectAnnotation, 
                settings, zoomScale, desiredZoomScale, onDeleteAnnotation, doRefreshAnnotationsFromBackend, urlState) => {

    let annotationList, selectedAnnotationDrawer, totalAreaDrawer;

    if (currentAnnotations.length > 0) {
        let areaSum = 0;
        currentAnnotations.map((item) => areaSum += item.area);
        console.log("area sum: ", getFormattedArea(areaSum));
        annotationList =  <Col key={0}
                                className="annotation-list scrollbar" 
                                span={10}>
                                <List
                                    size="small"
                                    bordered
                                    dataSource={currentAnnotations}
                                    renderItem={(item, index) => <List.Item 
                                                                    key={item.id} 
                                                                    style={(urlState || {}).presentCode != undefined ? {cursor: 'not-allowed'} : {cursor: 'pointer'}}
                                                                    className={item.id == selectedAnnotationId ? (urlState || {}).presentCode != undefined ? "selected-annotation scroll-row" : "selected-annotation" : ""} 
                                                                    onClick={(urlState || {}).presentCode != undefined ? null : ()=> onSelectAnnotation(item, index)}>
                                                                    {cookie.loadAll().deployment_mode === 'offline' ? 
                                                                        // item.shape === AnnotationsConstants.LINE ? [
                                                                        //     <div
                                                                        //         key={0} 
                                                                        //         className="annotation-magnification-indicator annotation-magnification-indicator-list-view unselectable" 
                                                                        //         style={{borderColor: item.color}}>
                                                                        //         {desiredZoomScale[Math.floor(item.created_z_level)] + "x"}
                                                                        //     </div>,
                                                                        //     <div style={{marginLeft: 30}}>
                                                                        //         <Row>
                                                                        //             <Col span={12}>
                                                                        //                 <div key={1} className="annotation-crop line" style={{zIndex: 1, borderColor: item.color}}>
                                                                        //                     {getIcon(item)}
                                                                        //                 </div>
                                                                        //             </Col>
                                                                        //             <Col span={12}>
                                                                        //                 <Text key={0} style={{fontSize:12, paddingTop: 10, color: item.color}} className="unselectable" ellipsis={true}>
                                                                        //                     {item.title}
                                                                        //                 </Text>
                                                                        //             </Col>
                                                                        //         </Row>
                                                                        //     </div>
                                                                        // ] : [
                                                                        // <div
                                                                        //     key={0} 
                                                                        //     className="annotation-magnification-indicator annotation-magnification-indicator-list-view unselectable" 
                                                                        //     style={{borderColor: item.color, color: "black", backgroundColor: "white"}}>
                                                                        //     {desiredZoomScale[Math.floor(item.created_z_level)] + "x"}
                                                                        // </div>, 
                                                                        // <img 
                                                                        //     key={1}
                                                                        //     className="annotation-crop"
                                                                        //     style={{filter:convertSettingsToFilter(settings), zIndex: 1, borderColor: item.color}}
                                                                        //     src={getPrefixedUrl(slide.path + '/ugc/annotations/' + item.id + '.jpg', slide)} />
                                                                        // ] 
                                                                        [
                                                                            <div
                                                                                key={0} 
                                                                                className="annotation-magnification-indicator-txtyviewer unselectable" 
                                                                                style={{background: item.color}}>
                                                                            </div>,
                                                                            <div>
                                                                                <Row>
                                                                                    <Col span={7} style={{width:'40px'}}>
                                                                                        <div key={1} className="annotation-crop line" style={{zIndex: 1, border: 'none', textAlign: 'center'}}>
                                                                                            {getIcon(item, true)}
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col span={14}>
                                                                                        <Text key={0} style={{fontSize:12, paddingTop: 10}} className="unselectable" ellipsis={true}>
                                                                                            {item.title}
                                                                                        </Text>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        ] : [
                                                                        <Text key={0} style={{fontSize:12}} className="unselectable" ellipsis={true}>
                                                                            {item.title}
                                                                        </Text>, 
                                                                        <div key={1} className="annotation-item-shape-icon">
                                                                            {getIcon(item)}
                                                                        </div>
                                                                        ]
                                                                    }
                                                                    {(urlState || {}).presentCode != undefined ? null:
                                                                        <Button
                                                                        type="default"
                                                                        shape="circle"
                                                                        style={{color: 'red'}}
                                                                        // icon={<CloseCircleOutlined />}
                                                                        className="delete-annotation"
                                                                        onClick={(e) => onDeleteAnnotation(e, item.id, index)}
                                                                    > X </Button>
                                                                    }
                                                                </List.Item>}
                                />
                            </Col>;
                            
                            ;
        totalAreaDrawer = <Row> 
        <Col key={2} offset={1} span={12} className="total-Area">
                          <Row className = "areaSum">
                             <b>Total Area : </b>{getFormattedArea(areaSum)}
                              </Row>      
             </Col>
             </Row>
    
        let selectedAnnotationContent;
    
        let selectedAnnoExist = true;

        if (selectedAnnotationId != null) {
            let selected;
            for (let i = 0; i < currentAnnotations.length; i++) {
                if (currentAnnotations[i].id == selectedAnnotationId) {
                    selected = currentAnnotations[i];
                    break;
                }
            }
            if(selected) {
                selectedAnnotationContent = <Col className="selected-annotation-drawer">
                                                    <Row
                                                        className="annotation-title" >
                                                        <Text 
                                                            strong={true}
                                                            editable={(urlState || {}).presentCode != undefined ? null : { onChange: (newTitle) => addTitle(selected, newTitle, doRefreshAnnotationsFromBackend)}}>
                                                            {selected.title === "" ? "Add Title" : selected.title}
                                                        </Text>
                                                    </Row>
                                                    <Row>
                                                        <Col span={8}>
                                                            <b>Color : </b>
                                                        </Col>
                                                        <Col span={16}>
                                                            <Select value={selected.color.toLowerCase()} style={{ width: 120, marginLeft: 10 }} onChange={(value) => changeColor(selected, value, doRefreshAnnotationsFromBackend)} size="small">
                                                                {colors.map((color, index) => {
                                                                    return <Option value={color.toLowerCase()}>
                                                                        <div className="color-icon-option" style={{backgroundColor: color.toLowerCase()}}></div>
                                                                    </Option>
                                                                })}
                                                            </Select>
                                                        </Col>
                                                    </Row>
                                                    { selected.shape == AnnotationsConstants.LINE ?
                                                        <Row>
                                                            <b>Length : </b>{getFormattedLength(selected.perimeter)}
                                                        </Row> :
                                                        [<Row key = {0}>
                                                            <b>Area : </b>{getFormattedArea(selected.area)}
                                                        </Row>, 
                                                        <Row key = {1}>
                                                            <b>Perimeter : </b>{getFormattedLength(selected.perimeter)}
                                                        </Row>]                                                        
                                                    }
                                                    <CommentComp annoId={selected.id}/>
                                                </Col>
            } else {
                selectedAnnoExist = false;
            }
        }

        selectedAnnotationDrawer = <Col key={1}
                                        span={14}
                                        className="selected-annotation-drawer-col">
                                        <Row 
                                            className="selected-annotation-drawer-row">
                                            {selectedAnnotationId != null && selectedAnnoExist ? 
                                            selectedAnnotationContent : <div className="no-annotation-placeholder unselectable">No Annotation Selected</div>}
                                        </Row>
                                    </Col>
    }  
    
    else {
        annotationList = <div style={{padding: 20}} key={0}>
            <br/>
            <br/>
            <div>No annotations available</div>
            <br/>
            <br/>
        </div>;
    }

    return [
        totalAreaDrawer,
        annotationList,               
        selectedAnnotationDrawer
    ]
}

export const getAnnotationsInGridView = (currentAnnotations, selectedAnnotationId, zoomScale, desiredZoomScale,
    slide, onSelectAnnotation, onDeleteAnnotation, refreshAnnotationsFromBackend, settings, 
    imgsPerCol, onChangeColSize, urlState) => {

    let annotationsGridParent, selectedAnnotationDrawer;

    let colSpan = parseInt(24 / imgsPerCol);
    let currentImgCount = 0;
    let annotationRow = [];
    let annotationsGrid = [];

    let anyPolygonShapes = false;

    for (let index = 0; index < currentAnnotations.length; index++){
        let annotation = currentAnnotations[index];
        let annotationDiv;

        if (annotation.shape === AnnotationsConstants.LINE) {
        } else { 
            anyPolygonShapes = true;
            annotationDiv = <Col key={currentImgCount} className={annotation.id == selectedAnnotationId ? "annotation-crop-div selected-annotation" : "annotation-crop-div"} 
                        span={colSpan} onClick={(urlState || {}).presentCode != undefined ? null : ()=> onSelectAnnotation(annotation, index)}>
                    <div 
                        className="annotation-magnification-indicator unselectable" 
                        style={{borderColor: annotation.color, color: "black", backgroundColor: "white"}}>
                        {desiredZoomScale[Math.floor(annotation.created_z_level)] + "x"}
                    </div>
                    <img 
                        className="annotation-crop"
                        style={{filter:convertSettingsToFilter(settings), zIndex: 1, borderColor: annotation.color}}
                        src={getPrefixedUrl(slide.path + '/ugc/annotations/' + annotation.id + '.jpg', slide)} />
                </Col>
        }

        if (currentImgCount == imgsPerCol) {
            annotationsGrid.push(<Row key={annotationsGrid.length}>{annotationRow}</Row>);
            annotationRow = [];
            currentImgCount = 0;
        }

        annotationRow.push(annotationDiv);
        currentImgCount ++;
    }

    if(annotationRow.length == 0 || !anyPolygonShapes) {
        annotationRow.push(<div style={{padding: 20}}>
            <br/>
            <br/>
            <div>No annotations available</div>
            <br/>
            <br/>
        </div>);
    }

    annotationsGrid.push(<Row key={annotationsGrid.length}>{annotationRow}</Row>);

    let gridSizeControl;

    if (!(annotationRow.length == 0 || !anyPolygonShapes)) {
        gridSizeControl =  <Row>
                            <Col span={4} style={{padding:"0.6em"}}>Grid Size</Col>
                            <Col span={20}>
                                <Slider
                                    className="grid-size-slider"
                                    min={2}
                                    max={6}
                                    onChange={onChangeColSize}
                                    value={imgsPerCol}
                                    disabled={(urlState || {}).presentCode != undefined} />
                            </Col>
                            </Row>
    }

    annotationsGridParent = 
        <Row className="annotations-grid scrollbar">
            {gridSizeControl}
            {annotationsGrid}
        </Row>

    return [
        annotationsGridParent, 
        selectedAnnotationDrawer
    ]
}
