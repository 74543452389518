import React, {Component} from "react";
import {Stack} from "@mui/material";
import {getAllRightBottomApps} from "./app_maker";
import {connect} from "react-redux";
import {checkAppPermission} from "./utils/gammaScanUtils";

class RightBottom extends Component {

	render() {
		return <Stack direction={"row-reverse"} spacing={0} alignItems={"flex-end"}>
			{getAllRightBottomApps().filter(app => checkAppPermission(app))
				.map(app => app.component)}
		</Stack>
	}
}

const mapStateToProps = (state) => {
	return {
		gammaState: state.gammaStateReducer,
	}
}

export default connect(mapStateToProps)(RightBottom);
