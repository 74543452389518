import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { AuthHeader } from '../helper/auth.token';
import { Spin, Statistic, Progress, Row, PageHeader, Divider, Col , Table, message } from 'antd';
import { sizeRangeChanged, convexityRangeChanged, eccentricityRangeChanged, meanColorRangeChanged , extremeSizeRangeChanged, extremeConvexityRangeChanged, extremeEccentricityRangeChanged, extremeMeanColorRangeChanged} from "../action/bloodGridRBC.action";
import { sizeRangeChangedPLT, extremeSizeRangeChangedPLT } from '../action/bloodGridPLT.action';
import { CheckOutlined } from '@ant-design/icons';
import Plot from 'react-plotly.js'
import { bloodClass } from '../utils/const';

export class BloodGraph extends Component {

    constructor(props) {
        super(props);

        this.state = {
            size: [],
            convexity: [],
            eccentricity: [],
            meancolor: [],
        }

    }

    getHistogramDataRBC = () => {
        let url = `/api/get_histogram_data_rbc/?slide_id=${this.props.slide_id}`
        axios.get(url).then(res => {
            let size = res.data['size']
            let convexity = res.data['convexity']
            let eccentricity = res.data['eccentricity']
            let meancolor = res.data['meancolor']
            this.setState({
                size: size,
                convexity: convexity,
                eccentricity: eccentricity,
                meancolor: meancolor,
            })
            console.log("changeme-size02", [Math.min(...size),Math.max(...size)])
            this.props.dispatch(extremeSizeRangeChanged([Math.min(...size),Math.max(...size)]))
            this.props.dispatch(extremeConvexityRangeChanged([Math.min(...convexity),Math.max(...convexity)]))
            this.props.dispatch(extremeEccentricityRangeChanged([Math.min(...eccentricity),Math.max(...eccentricity)]))
            this.props.dispatch(extremeMeanColorRangeChanged([Math.min(...meancolor),Math.max(...meancolor)]))
            this.props.dispatch(sizeRangeChanged([Math.min(...size),Math.max(...size)]))
            this.props.dispatch(convexityRangeChanged([Math.min(...convexity),Math.max(...convexity)]))
            this.props.dispatch(eccentricityRangeChanged([Math.min(...eccentricity),Math.max(...eccentricity)]))
            this.props.dispatch(meanColorRangeChanged([Math.min(...meancolor),Math.max(...meancolor)]))
        })
    }

    getHistogramDataPLT = () => {
        let url = `/api/get_histogram_data_plt/?slide_id=${this.props.slide_id}`
        axios.get(url).then(res => {
            let size = res.data['size']
            this.setState({
                size: size,
            })
            console.log("changeme-size01")
            this.props.dispatch(extremeSizeRangeChangedPLT([Math.min(...size),Math.max(...size)]))
            this.props.dispatch(sizeRangeChangedPLT([Math.min(...size),Math.max(...size)]))
        })
    }

    componentDidMount = () => {
        if(this.props.class == "RBC"){
            this.getHistogramDataRBC();
        }
        else if(this.props.class == bloodClass.PLT){
            this.getHistogramDataPLT();
        }
    }

    componentDidUpdate = (prevProps) => {
        // if(prevProps.size!=this.state.size){
            
        // }
    }


    componentWillUnmount = () => {

    }

    plotUpdateSize = (figure, graphDiv) => {
        if(figure['xaxis.range[0]'] != undefined){
            this.props.dispatch(sizeRangeChanged([figure['xaxis.range[0]'],figure['xaxis.range[1]']]))
            message.success('Area Filter Applied Successfully', 2.5);
        }
        else if(figure['xaxis.autorange']==true){
            this.props.dispatch(sizeRangeChanged([this.props.data.extreme_size_range_low, this.props.data.extreme_size_range_high]))
            message.success('Area Filter Reset Successful', 2.5);
        }
        // message.success('Area Filter Applied Successfully', 2.5);
    }

    plotUpdateSizePLT = (figure, graphDiv) => {
        if(figure['xaxis.range[0]'] != undefined){
            this.props.dispatch(sizeRangeChangedPLT([figure['xaxis.range[0]'],figure['xaxis.range[1]']]))
            message.success('Area Filter Applied Successfully', 2.5);
        }
        else if(figure['xaxis.autorange']==true){
            this.props.dispatch(sizeRangeChangedPLT([this.props.data.extreme_size_range_low, this.props.data.extreme_size_range_high]))
            message.success('Area Filter Reset Successful', 2.5);
        }
    }

    plotUpdateConvexity = (figure, graphDiv) => {
        if(figure['xaxis.range[0]'] != undefined){
            this.props.dispatch(convexityRangeChanged([figure['xaxis.range[0]'],figure['xaxis.range[1]']]))
            message.success('Convexity Filter Applied Successfully', 2.5);
        }
        else if(figure['xaxis.autorange']==true){
            this.props.dispatch(convexityRangeChanged([this.props.data.extreme_convexity_range_low, this.props.data.extreme_convexity_range_high]))
            message.success('Convexity Filter Reset Successful', 2.5);
        }
        
    }

    plotUpdateEccentricity = (figure, graphDiv) => {
        if(figure['xaxis.range[0]'] != undefined){
            this.props.dispatch(eccentricityRangeChanged([figure['xaxis.range[0]'],figure['xaxis.range[1]']]))
            message.success('Eccentricity Filter Applied Successfully', 2.5);
        }
        else if(figure['xaxis.autorange']==true){
            this.props.dispatch(eccentricityRangeChanged([this.props.data.extreme_eccentricity_range_low, this.props.data.extreme_eccentricity_range_high]))
            message.success('Eccentricity Filter Reset Successful', 2.5);
        }
        
    }

    plotUpdateMeanColor = (figure, graphDiv) => {
        if(figure['xaxis.range[0]'] != undefined){
            this.props.dispatch(meanColorRangeChanged([figure['xaxis.range[0]'],figure['xaxis.range[1]']]))
            message.success('Pallor Filter Applied Successfully', 2.5);
        }
        else if(figure['xaxis.autorange']==true){
            this.props.dispatch(meanColorRangeChanged([this.props.data.extreme_mean_color_range_low, this.props.data.extreme_mean_color_range_high]))
            message.success('Pallor Filter Reset Successful', 2.5);
        }
    }

    getSizeData = (data) => {
        let res = data.filter((val)=> {
            return (val>=this.props.data.size_range_low && val<=this.props.data.size_range_high);
        })
        return res;
    }

    getEccentricitydata = (data) => {
        let res = data.filter((val)=> {
            return (val>=this.props.data.eccentricity_range_low && val<=this.props.data.eccentricity_range_high);
        })
        return res;
    }

    getConvexityData = (data) => {
        let res = data.filter((val)=> {
            return (val>=this.props.data.convexity_range_low && val<=this.props.data.convexity_range_high);
        })
        return res;
    }

    getMeanColorData = (data) => {
        let res = data.filter((val)=> {
            return (val>=this.props.data.mean_color_range_low && val<=this.props.data.mean_color_range_high);
        })
        return res;
    }


    render() {
        let graphs;

        if(this.props.class == "RBC"){
            graphs = <React.Fragment><Row >
                    <Plot
                        // onUpdate = {this.plotUpdateSize}
                        // onUpdate = {(figure) => {console.log("figure-update", figure)}}
                        // onInitialized = {(figure)=> {console.log("figure-initialise", figure)}}
                        
                        onRelayout = {this.plotUpdateSize}
                        data = {
                            [
                                {
                                    // x:(this.state.size),
                                    x: this.getSizeData(this.state.size),
                                    type: 'histogram',
                                    mode: 'lines+markers',
                                    marker: { color: '#1cb2ba' }, 
                                }
                            ]
                        }
                        layout={{ width: (18.5)*(document.documentElement.clientWidth/100), height: (19)*(document.documentElement.clientHeight/100), 
                                    // title: 'Size vs Count',  
                                    paper_bgcolor:'rgba(0,0,0,0)',
                                    plot_bgcolor: "rgba(0,0,0,0)",
                                    font: {
                                        // family: 'Courier New, monospace',
                                        size: 11,
                                        color: 'white'
                                      },
                                    margin:{
                                        l: (3)*(document.documentElement.clientWidth/100),
                                        r: (1)*(document.documentElement.clientWidth/100),
                                        b: (5)*(document.documentElement.clientHeight/100),
                                        t: (2)*(document.documentElement.clientHeight/100),
                                        // pad: 5,
                                    },
                                    xaxis:{
                                        title:{
                                            text:'Size(Diameter in microns)'
                                        },
                                    },
                                    yaxis:{
                                        title:{
                                            text:'Count'
                                        },
                                        fixedrange: true
                                    },
                                    bargap:"0.4" 
                                }}
                        config = {
                        { modeBarButtonsToRemove:[ 'lasso2d','zoom2d', 'pan2d', 'select2d',  'zoomIn2d', 'zoomOut2d', 'autoScale2d','toggleSpikelines','hoverClosestCartesian',
                        'hoverCompareCartesian'],
                        displaylogo: false,}
                        }
                        
                    />
                </Row>
                <Row style={{marginTop:'2.5vh'}}>
                    <Plot
                        onRelayout = {this.plotUpdateConvexity}
                        data = {
                            [
                                {
                                    x:this.getConvexityData(this.state.convexity),
                                    type: 'histogram',
                                    mode: 'lines+markers',
                                    marker: { color: '#1cb2ba' }, 
                                }
                            ]
                        }
                        layout={{ width: (18.5)*(document.documentElement.clientWidth/100), height: (19)*(document.documentElement.clientHeight/100), 
                                    // title: 'Convexity vs Count',  
                                    paper_bgcolor:'rgba(0,0,0,0)',
                                    plot_bgcolor: "rgba(0,0,0,0)",
                                    font: {
                                        // family: 'Courier New, monospace',
                                        size: 11,
                                        color: 'white'
                                      },
                                    margin:{
                                        l: (3)*(document.documentElement.clientWidth/100),
                                        r: (1)*(document.documentElement.clientWidth/100),
                                        b: (5)*(document.documentElement.clientHeight/100),
                                        t: (2)*(document.documentElement.clientHeight/100),
                                        // pad: 5,
                                    },
                                    xaxis:{
                                        title:{
                                            text:'Unevenness'
                                        }
                                    },
                                    yaxis:{
                                        title:{
                                            text:'Count'
                                        },
                                        fixedrange: true
                                    },
                                    bargap:"0.4" 
                                }}
                        config = {
                        { modeBarButtonsToRemove:[ 'lasso2d','zoom2d', 'pan2d', 'select2d',  'zoomIn2d', 'zoomOut2d', 'autoScale2d','toggleSpikelines','hoverClosestCartesian',
                        'hoverCompareCartesian'],
                        displaylogo: false,}
                        }
                        
                    />
                </Row>
                <Row style={{marginTop:'2.5vh'}}>
                    <Plot
                        onRelayout = {this.plotUpdateEccentricity}
                        data = {
                            [
                                {
                                    x:this.getEccentricitydata(this.state.eccentricity),
                                    type: 'histogram',
                                    mode: 'lines+markers',
                                    marker: { color: '#1cb2ba' }, 
                                }
                            ]
                        }
                        layout={{ width: (18.5)*(document.documentElement.clientWidth/100), height: (19)*(document.documentElement.clientHeight/100), 
                                    // title: 'Eccentricity vs Count',  
                                    paper_bgcolor:'rgba(0,0,0,0)',
                                    plot_bgcolor: "rgba(0,0,0,0)",
                                    font: {
                                        // family: 'Courier New, monospace',
                                        size: 11,
                                        color: 'white'
                                      },
                                    margin:{
                                        l: (3)*(document.documentElement.clientWidth/100),
                                        r: (1)*(document.documentElement.clientWidth/100),
                                        t: (2)*(document.documentElement.clientHeight/100),
                                        b: (5)*(document.documentElement.clientHeight/100),
                                        // pad: 5,
                                    },
                                    xaxis:{
                                        title:{
                                            text:'Elongation'
                                        }
                                    },
                                    yaxis:{
                                        title:{
                                            text:'Count'
                                        },
                                        fixedrange: true
                                    } ,
                                    bargap:"0.4" 
                                }}
                        config = {
                        { modeBarButtonsToRemove:[ 'lasso2d','zoom2d', 'pan2d', 'select2d',  'zoomIn2d', 'zoomOut2d', 'autoScale2d','toggleSpikelines','hoverClosestCartesian',
                        'hoverCompareCartesian'],
                        displaylogo: false,}
                        }
                        
                    />
                </Row>
                {this.props.data.extreme_mean_color_range_low!=0 && this.props.data.extreme_mean_color_range_high!=0?
                <Row style={{marginTop:'2.5vh'}}>
                    <Plot
                        onRelayout = {this.plotUpdateMeanColor}
                        data = {
                            [
                                {
                                    x:this.getMeanColorData(this.state.meancolor),
                                    type: 'histogram',
                                    mode: 'lines+markers',
                                    marker: { color: '#1cb2ba' }, 
                                }
                            ]
                        }
                        layout={{ width: (18.5)*(document.documentElement.clientWidth/100), height: (19)*(document.documentElement.clientHeight/100), 
                                    // title: 'Convexity vs Count',  
                                    paper_bgcolor:'rgba(0,0,0,0)',
                                    plot_bgcolor: "rgba(0,0,0,0)",
                                    font: {
                                        // family: 'Courier New, monospace',
                                        size: 11,
                                        color: 'white'
                                      },
                                    margin:{
                                        l: (3)*(document.documentElement.clientWidth/100),
                                        r: (1)*(document.documentElement.clientWidth/100),
                                        b: (5)*(document.documentElement.clientHeight/100),
                                        t: (2)*(document.documentElement.clientHeight/100),
                                        // pad: 5,
                                    },
                                    xaxis:{
                                        title:{
                                            text:'Pallor Size'
                                        }
                                    },
                                    yaxis:{
                                        title:{
                                            text:'Count'
                                        },
                                        fixedrange: true
                                    },
                                    bargap:"0.4" 
                                }}
                        config = {
                        { modeBarButtonsToRemove:[ 'lasso2d','zoom2d', 'pan2d', 'select2d',  'zoomIn2d', 'zoomOut2d', 'autoScale2d','toggleSpikelines','hoverClosestCartesian',
                        'hoverCompareCartesian'],
                        displaylogo: false,}
                        }
                        
                    />
                </Row>:null}
                </React.Fragment>
        }
    else if (this.props.class == bloodClass.PLT){
       graphs = <div><Row style={{marginTop:'2.5vh'}}>
                    <Plot
                        // onUpdate = {this.plotUpdateSize}
                        // onUpdate = {(figure) => {console.log("figure-update", figure)}}
                        // onInitialized = {(figure)=> {console.log("figure-initialise", figure)}}
                        
                        onRelayout = {this.plotUpdateSizePLT}
                        data = {
                            [
                                {
                                    // x:(this.state.size),
                                    x: this.getSizeData(this.state.size),
                                    type: 'histogram',
                                    mode: 'lines+markers',
                                    marker: { color: '#1cb2ba' }, 
                                }
                            ]
                        }
                        layout={{  width: (18.5)*(document.documentElement.clientWidth/100), height: (30)*(document.documentElement.clientHeight/100),  
                                    // title: 'Size vs Count',  
                                    paper_bgcolor:'rgba(0,0,0,0)',
                                    plot_bgcolor: "rgba(0,0,0,0)",
                                    font: {
                                        // family: 'Courier New, monospace',
                                        size: 11,
                                        color: 'white'
                                      },
                                    uirevision:'true',
                                    margin:{
                                        l: (3)*(document.documentElement.clientWidth/100),
                                        r: (1)*(document.documentElement.clientWidth/100),
                                        b: (5)*(document.documentElement.clientHeight/100),
                                        t: (2)*(document.documentElement.clientHeight/100),
                                        // pad: 5,
                                    },
                                    xaxis:{
                                        title:{
                                            text:'Size(Diameter in microns)'
                                        },
                                        autorange:'true',
                                    },
                                    yaxis:{
                                        title:{
                                            text:'Count'
                                        },
                                        fixedrange:true
                                    },
                                    bargap:"0.4" 
                                }}
                        config = {
                        { modeBarButtonsToRemove:[ 'lasso2d','zoom2d', 'pan2d', 'select2d',  'zoomIn2d', 'zoomOut2d', 'autoScale2d','toggleSpikelines','hoverClosestCartesian',
                        'hoverCompareCartesian'],
                        displaylogo: false,}
                        }
                        
                    />
                </Row></div>
    }

      console.log("table_data",this.props.data)
        return (
            <Row span={24}>
                {graphs}
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      bloodGridRBCData: state.bloodGridRBCReducer,
    }
};

export default connect(mapStateToProps)(BloodGraph);
