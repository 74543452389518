import React, {Component} from "react";
import {connect} from "react-redux";
import StaticMap from "./components/StaticMap";
import {Grid} from "@mui/material";
import {addFeatureAnnotation} from "./utils/annotations_app_utils";
import {labellingLayer} from "./utils/layers_info";

class LabellingMap extends Component {

	render() {
		let labellingImage = this.props.labellingImage;
		if (!labellingImage || !this.props.annotations) return null;
		return <Grid container>
			{/*TODO: [Priyanshu] calculate extent automatically inside*/}
			<StaticMap id={`static-map`}
			           image={this.props.labellingImage} extent={[0, 0, 2441, 2041]}
			           annotations={this.props.annotations.filter(annotation =>
				           annotation.anno_drawer === labellingLayer.drawer &&
				           (annotation.meta || {}).labellingImage === labellingImage)}
			           vectorLayerProperties={labellingLayer}
			           addFeature={(feature, map, vector, tool) =>
				           addFeatureAnnotation(feature, map, vector, tool, this.props.slideId,
					           {labellingImage})}
			/>
		</Grid>
	}
}

const mapStateToProps = (state) => {
	let mapState = state.mapsStateReducer[state.gammaStateReducer.activeMapId];
	let slideId = mapState.slideId;
	let annotations = mapState.annotationState.annotations;
	let labellingImage = state.gammaStateReducer.labellingImage;
	return {slideId, annotations, labellingImage};
};

export default connect(mapStateToProps)(LabellingMap);

