import React, {Component} from "react";
import {
	Box,
	Checkbox,
	IconButton,
	MenuItem, Select, Stack, Tooltip,
	Typography,
	CardMedia,
	Grid, Divider
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CircleIcon from "@mui/icons-material/Circle";
import AnnotationsDownload from "../annotationsDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import {deleteAnnotation} from "../../../../action/maps.state.action";
import {connect} from "react-redux";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {morphleBaseTheme1} from "../../themes";

class AnnotationsExpandableListBlood extends Component {

	constructor(props) {
		super(props);

		this.state = {
			colorFilter: "ALL",
			page: 0,
			maxPages: 0,
			pageSize: 21,
			filteredAnnotations: null,
		}
	}

	componentDidMount() {
		this.checkTrigger();

		// if onColorFilter function is passed then do not filter internally, passed function will filter externally
		let filteredAnnotations = this.props.onColorFilter ? this.props.annotations :
			this.props.annotations.filter(annotation => this.state.colorFilter === 'ALL' ||
				annotation.color.toLowerCase() === this.state.colorFilter);
		this.setState({filteredAnnotations})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if((this.props.onColorFilter && this.props.onColorFilter !== prevProps.onColorFilter)
			|| (this.props.annotations && this.props.annotations !== prevProps.annotations) ){
			let filteredAnnotations = this.props.onColorFilter ? this.props.annotations :
				this.props.annotations.filter(annotation => this.state.colorFilter === 'ALL' ||
					annotation.color.toLowerCase() === this.state.colorFilter);

			this.setState({
				filteredAnnotations,
				maxPages: Math.ceil(filteredAnnotations.length/this.state.pageSize),
				page: 0,
			})
		}
	}

	checkTrigger = () => {

		if (this.props.trigger.expandAnnotation){
			let newPage=0;
			this.props.annotations.forEach((annotation, index) =>{
				if (this.props.trigger.expandAnnotation === annotation.id){
					annotation.expanded = true;
					newPage = this.getPage(index);
				} else {
					annotation.expanded = false;
				}
			})
			this.setState({
				page: newPage,
			})

		}
		delete this.props.trigger.expandAnnotation;
	}

	getPage = (index) => {
		return Math.floor(index/this.state.pageSize);
	}

	onAnnotationClick = (annotation) => {
		this.props.annotations.forEach(annotation_ =>
			annotation_.expanded = annotation.id === annotation_.id && !annotation.expanded
		);
		this.setState({});
		this.props.onAnnotationClick(annotation)
	}

	changeFilterColor = (e) => {
		if (this.props.onColorFilter)
			this.props.onColorFilter(e.target.value);
		this.setState({
			colorFilter: e.target.value,
		});
	}

	handlePrevPage = () => {
		if(this.state.page>0)
			this.setState({page: this.state.page-1})
	}

	handleNextPage = () => {
		if(this.state.page < this.state.maxPages-1)
			this.setState({page: this.state.page+1})
	}

	getDefaultTopComponent = () => {
		let menuItems = [];
		menuItems.push(
			<MenuItem value={"ALL"}>
				<Typography>ALL</Typography>
			</MenuItem>
		)
		Object.entries(this.props.colors).forEach(([name, color]) =>
			menuItems.push(
				<MenuItem label={name} value={color.toLowerCase()}>
					<CircleIcon fontSize="small" sx={{color:color.toLowerCase()}}/>
					{this.props.showColorLabels ? name : null}
				</MenuItem>
			)
		)
		return <Stack direction={"row"} paddingX={1.2} paddingY={0.5} justifyContent={"space-between"}>
			<Select variant={"filled"} value = {this.state.colorFilter} onChange={this.changeFilterColor}>
				{menuItems}
			</Select>
			{this.props.showDownload ? <AnnotationsDownload slideId={this.props.slideId} annoDrawer={0} /> : undefined}
		</Stack>
	}

	getMultipleSelectTopComponent = () =>
		<Stack direction={"row"} paddingX={1.2} justifyContent={"space-between"}>
			<Tooltip title={"Select All"} placement={"right"}>
				<Checkbox edge={"start"}
				          checked={this.props.annotations.reduce((checked, annotation) =>
					          checked && annotation.checked, true) || false}    // || false to handle undefined
				          disableRipple size={'medium'}
				          icon={<CheckBoxOutlineBlankIcon/>}
				          checkedIcon={<CheckBoxIcon color={"secondary"}/>}
				          onChange={e => {
					          this.props.annotations.forEach(annotation => annotation.checked = e.target.checked);
					          this.setState({});
				          }}/>
			</Tooltip>
			{this.props.actionOnSelected ?
				<Tooltip title={this.props.actionTooltip || "Run Action on selected annotation"} placement={"top"}>
					<IconButton onClick={() => this.props.actionOnSelected(
						this.props.annotations.filter(annotation => annotation.checked)
							.map(annotation => annotation.id))}
					            color={"secondary"}><PlayArrowIcon/></IconButton>
				</Tooltip> :
				undefined
			}
			<Tooltip title={"Delete Selected Annotations"} placement={"right"}>
				<IconButton onClick={() => this.props.annotations.filter(annotation => annotation.checked)
					.forEach(annotation =>
						this.props.dispatch(deleteAnnotation(this.props.activeMapId, annotation.id)))}
				            color={'secondary'}>
					<DeleteIcon/>
				</IconButton>
			</Tooltip>
		</Stack>

	getTopComponent = () => this.props.annotations.reduce((previousValue, currentValue) =>
		previousValue || currentValue.checked, false) ? this.getMultipleSelectTopComponent() :
		this.getDefaultTopComponent();

	getThumbnailComponent = (annotation) => {
		return( <CardMedia
			component="img"
			sx={{width: "3.8vw", border: annotation.expanded ? "3px solid" : "2px solid", borderRadius: "5px", borderColor: annotation.expanded ?
				 morphleBaseTheme1.palette.secondary.main : morphleBaseTheme1.palette.text.secondary }}
			image={annotation.thumbnail}
			onClick={(e)=>this.onAnnotationClick(annotation)}
			alt={annotation.id}
			/>)
	}
	
	getAnnotationsGrid = (annotations) => {
		let annotationsGridParent;
		let annotationDiv;

		let imgsPerCol = 3;
		let colSpan = parseInt(12 / imgsPerCol);
		let annotationsGrid = [];

		for (let annotation of annotations){

			annotationDiv = <Grid item xs={colSpan} id={`li-${annotation.id}`}>
									{this.getThumbnailComponent(annotation)}
							</Grid>

			annotationsGrid.push(annotationDiv);
		}

		annotationsGridParent =
			<Grid container rowSpacing={2} columnSpacing={{ xs: 2}}>
				{annotationsGrid}
			</Grid>

		return annotationsGridParent;


	}

	render() {
		this.checkTrigger();

		// if onColorFilter function is passed then do not filter internally, passed function will filter externally
		// let filteredAnnotations = this.props.onColorFilter ? this.props.annotations :
		// 	this.props.annotations.filter(annotation => this.state.colorFilter === 'ALL' ||
		// 		annotation.color.toLowerCase() === this.state.colorFilter);
		if (this.state.filteredAnnotations === null) return <div>No Blood Annotations Available</div>
		let startIndex = this.state.page * this.state.pageSize;
		let annotations = this.state.filteredAnnotations.slice(startIndex, startIndex + this.state.pageSize)

		return <Box sx={{height:'inherit'}}>
					{this.getAnnotationsGrid(annotations)}
					<Divider sx={{marginTop: '1vh', marginBottom: '1vh'}}/>
					<Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
						<IconButton variant={"outlined"} color={"secondary"} size={"small"}
								onClick={this.handlePrevPage}><ArrowBackIosIcon/></IconButton>
						<Typography>{this.state.page+1} / {this.state.maxPages}</Typography>
						<IconButton variant={"outlined"} color={"secondary"} size={"small"}
								onClick={this.handleNextPage}><ArrowForwardIosIcon/></IconButton>
					</Stack>
			</Box>
	}

}const mapStateToProps = (state) => {
	return {
		activeMapId: state.gammaStateReducer.activeMapId,
		trigger: state.triggerReducer,
	}
}

export default connect(mapStateToProps)(AnnotationsExpandableListBlood)
