import { Input, Row, Spin } from 'antd';
import axios from "axios";
import React, { Component } from 'react';
import { connect } from "react-redux";
import { setDeviceBusy, setDeviceIdle } from '../../action/device_status.action';
import {
    initPreviewForScan,
    takePreview,
    updatePreviewStatus,
    fetchingPreview,
    receivedPreviewStatus
} from "../../action/preview_status.action";
import { RegionSelectionConstants } from "../../actionTypes/region_selection.constant";
import "../../asset/style/scanner/scanner.css";
import { globalUrlPrefix, progressStage, specimenType } from "../../utils/const";
import ScannerTimeline from './scanner_timeline';
import LoadSlidesPrompt from './scanWorkflow/loadSlidesPrompt';
import ScanProgress from './scanWorkflow/scan_progress';
import SetScanParamsView from './scanWorkflow/set_scan_params_view_new';
import SetScanParamsBloodView from './scanWorkflow/set_scan_params_view_blood_new';
import { AuthHeader } from '../../helper/auth.token';
import { ScanApiConstant } from '../../actionTypes/scan.constant';


const { Search } = Input;

class BloodWorkflow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentProcessStage: progressStage.LOAD_SLIDES,
            cassetteSize: 0,
            isObjectiveSwitcherPresent: false,
            activeSlot: -1,
            busy:false, 
            showSummary: false,
            scannerProperties: {},
            deviceType: "",
        }
    }
    
    componentDidMount = () => {
        if (this.props.match.params.stage == progressStage.SET_PARAMETERS) {
            this.navigateToScannerDashboard();
        } else {
            this.getScannerProperties();
        }
    }

    componentDidUpdate = () => {
        // console.log(this.props);
    }

    navigateToScannerDashboard = () => {
        this.props.history.push("/" + globalUrlPrefix + "/scanners/" + this.props.match.params.id);
    }

    getScannerProperties = () => {
        this.props.dispatch(setDeviceBusy(this.props.match.params.id, "Setting Up"));
        const url = "/server/devices/" + this.props.match.params.id + "/settings/get_scanner_properties";
        axios
        .get(url)
        .then(res => {
            for (let i = 0; i < res.data.cassetteSize; i++) {
                this.initSlot(i, res.data.defaultConfig);
            }
            // if ((res.data.cassetteSize === 1 || res.data.cassetteSize === 2) && this.props.match.params.stage == progressStage.LOAD_SLIDES) {
            //     this.activate(0, res);
            // } else {
                console.log("Check " + res.data.cassetteSize)
                this.setState(Object.assign({}, this.state, {
                    cassetteSize: res.data.cassetteSize,
                    isObjectiveSwitcherPresent: res.data.isObjectiveSwitcherPresent,
                    allowScanOutputFormatChange: res.data.allowScanOutputFormatChange,
                    allowObjectiveChange: res.data.allowObjectiveChange,
                    defaultConfig: res.data.defaultConfig,
                    scannerProperties: res.data,
                    deviceType: res.data.deviceType,
                }));
                this.props.dispatch(setDeviceIdle(this.props.match.params.id));
            // }
        })
        .catch(err => {
            console.log(err);   
            this.props.dispatch(setDeviceIdle(this.props.match.params.id));
        });
    }

    activate = (key, res) => {
        this.setState(Object.assign({}, this.state, {activeSlot: key, showSummary: false}));
        this.takePreview(key, res);
    }

    takePreview = (slot, res) => {
        this.props.dispatch(updatePreviewStatus(slot, RegionSelectionConstants.GETTING_IMAGE));
        this.props.dispatch(setDeviceBusy(this.props.match.params.id, "Taking Preview"));
        this.props.dispatch(fetchingPreview(slot));
        let url = `/server/devices/` + this.props.match.params.id + "/take_preview?slot_id=" + slot;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(receivedPreviewStatus(ScanApiConstant.TAKEN_PREVIEW, JSON.parse(response.data), slot))
                    this.props.dispatch(setDeviceIdle(this.props.match.params.id));
                    this.setState(Object.assign({}, this.state, {
                        cassetteSize: res.data.cassetteSize,
                        isObjectiveSwitcherPresent: res.data.isObjectiveSwitcherPresent,
                        allowScanOutputFormatChange: res.data.allowScanOutputFormatChange,
                        allowObjectiveChange: res.data.allowObjectiveChange,
                        defaultConfig: res.data.defaultConfig,
                        scannerProperties: res.data,
                        currentProcessStage: progressStage.SET_PARAMETERS,
                    }));
                }
                else {
                    this.props.dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_TAKING_PREVIEW, response, slot))
                    this.props.dispatch(setDeviceIdle(this.props.match.params.id));
                }
            })
            .catch(err => {
                this.props.dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_TAKING_PREVIEW, err, slot))
                this.props.dispatch(setDeviceIdle(this.props.match.params.id));
                console.log(err)
            })
    }

    initSlot = (slot, defaultConfig) => {
        this.props.dispatch(initPreviewForScan(slot, defaultConfig));
    }

    handleProgressChange = (stage) => {
        this.setState({
            currentProcessStage: progressStage.stage,
        })
        this.props.history.push("/" + globalUrlPrefix + "/scannerflow/" + this.props.match.params.id + "/blood-scan/" + stage);
    }
    
    render() {

        let cassetteSize = this.state.cassetteSize;
        
        let slotMenu = null;
        let summaryDiv = null;

        let noRegionSelected = 0;
        for (let i = 0; i < cassetteSize; i++) {
            let preview = this.props.previews[i];
            if ((preview || {}).status == RegionSelectionConstants.NOT_STARTED) {
                noRegionSelected += 1;
            }
        }

        return (
            <div>
                {this.state.cassetteSize != 0 ?
                    <Row className="rounded-container">
                        <ScannerTimeline currentProcessIndex={this.props.match.params.stage} handleProgressChange={this.handleProgressChange} device_id={this.props.match.params.id} type={specimenType.BLOOD}/>
                        {this.props.match.params.stage == progressStage.LOAD_SLIDES ?
                            <SetScanParamsBloodView 
                                handleProgressChange={this.handleProgressChange} 
                                device_id={this.props.match.params.id}
                                currentProcessIndex={this.props.match.params.stage} 
                                previews={this.props.previews}
                                progressStage={this.props.match.params.stage}
                                scannerProperties={this.state.scannerProperties}
                                navigateToScannerDashboard={this.navigateToScannerDashboard}
                                defaultConfig={this.state.defaultConfig}
                            /> 
                            :
                            this.props.match.params.stage == progressStage.SET_PARAMETERS ?
                            <SetScanParamsBloodView 
                            handleProgressChange={this.handleProgressChange} 
                            device_id={this.props.match.params.id}
                            currentProcessIndex={this.props.match.params.stage} 
                            previews={this.props.previews}
                            progressStage={this.props.match.params.stage}
                            scannerProperties={this.state.scannerProperties}
                            navigateToScannerDashboard={this.navigateToScannerDashboard}
                            defaultConfig={this.state.defaultConfig}
                        /> 
                                :
                                this.props.match.params.stage == progressStage.SCANNING ?
                                    <ScanProgress 
                                        handleProgressChange={this.handleProgressChange} 
                                        device_id={this.props.match.params.id} 
                                        scannerProperties={this.state.scannerProperties}
                                    />
                                    : null
                        }
                        {/* {currentStageView} */}
                    </Row> :
                    <div style={{width: '100%', height: '800px'}} className="rounded-container">
                        <Spin spinning={this.state.cassetteSize == 0} tip="Taking Preview...">
                            <div style={{width: '100%', height: '800px'}}></div>
                        </Spin>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        previews : state.previewStatusReducer,
        deviceProperties : state.deviceReducer,
        deviceStatus: state.deviceStatusReducer,
    };
};

export default connect(mapStateToProps)(BloodWorkflow);
