import axios from "axios";
import {AuthHeader} from "../../helper/auth.token";
import {message} from 'antd';
import {ScaleLine} from 'ol/control.js';
import {getPrefixedUrl, getSuffixUrl} from '../../utils/utils';
import Static from "ol/source/ImageStatic";
import {mapLayerTypes} from "../../utils/const";
import {
	getDesiredZoomScale,
	getImageInfo,
	getImageLayers,
	getImageShape,
	getLayer,
	getMap,
	getProjection,
	getResolutions,
	getTileSize,
	getView,
	getZoomLevels,
	getZoomScale,
	getZValue,
	isZoomForTiled
} from "../gammaviewer/utils/map_utils";

export const getSlideAndInitialiseMapState = (value, setState, urlState, updateUrlState, updateLayers, onMapRenderingComplete, scrollActionOnMap) => {
    setState({
        isFetching: true
    });

    let slideUrl = `/api/slide/${value}/`;

    let headers = {
        headers: {
            Authorization: AuthHeader()
        }
    };

    axios.get(slideUrl, headers)
      .then(slideDataResponse => {
        if (slideDataResponse.status === 200) {
            let {background_color, txty, meta} = slideDataResponse.data;
            setState({
                backgroundColor: 'rgb(' + background_color[0] + ',' + background_color[1] + ',' + background_color[2] + ')',
                txtyInfo: txty,
            })
            loadMap(slideDataResponse.data, setState, urlState, updateUrlState, txty, updateLayers, onMapRenderingComplete, scrollActionOnMap);
        } else {
           setState({
               isErrored: true,
               isFetching: false, 
               errMessage: slideDataResponse.data
           });
         }
      })
      .catch((err) => {
        console.log("Error occured")
        message.error("Slide could not be found. Contact Admin.");
        console.log("error",err)
        setState({
            isErrored: true,
            isFetching: false, 
            errMessage: err,
        });
      });
}

export const loadMap = (slideData, setState, urlState, updateUrlState, txtyInfo, updateLayers, onMapRenderingComplete, scrollActionOnMap) => {
    let imageInfo = {};
	if (JSON.stringify(txtyInfo) !== JSON.stringify({}))
		imageInfo = getImageInfo(slideData, txtyInfo);
    initialiseMap(slideData, setState, urlState, updateUrlState, imageInfo, txtyInfo, updateLayers, onMapRenderingComplete, scrollActionOnMap);
}

export const recreateMapLayers = (slidemap, viewLevelsInfo, slide_data, imageInfo, projection, resolutions, txtyInfo, 
    tileSize, updateUrlState, setState, zStackLevel, minZLevel, maxZLevel, takeZStack) => {
    let nextZoom = slidemap.getView().getZoom();
    let urlSuffix = getSuffixUrl(slide_data, isZoomForTiled(nextZoom, viewLevelsInfo), zStackLevel);
    let url = getPrefixedUrl(urlSuffix, slide_data);
    let viewport = slidemap.getView().calculateExtent(slidemap.getSize());
    let newLayers;

    if (isZoomForTiled(nextZoom, viewLevelsInfo)) {
		let tiledZoom = Math.round(nextZoom - (viewLevelsInfo.lastStitchedLevel - viewLevelsInfo.lastTiledLevel));
        newLayers = getImageLayers(url, tiledZoom, imageInfo, slide_data, txtyInfo, viewport, viewLevelsInfo.lastTiledLevel, 
            zStackLevel, minZLevel, maxZLevel, takeZStack);
        let allOldLayers = slidemap.getLayers().getArray();

        let layersToRemove = [];

        for (let oi in allOldLayers) {

            let foundInNewLayers = false;
            let layerFoundAtIndex = -1;
            let oldLayer = allOldLayers[oi];
            
            for (let i = 0; i < newLayers.length; i++) {
                let newLayer = newLayers[i];
                if (oldLayer.values_.posI === newLayer.values_.posI && 
                    oldLayer.values_.posJ === newLayer.values_.posJ) {
                    if (oldLayer.values_.posZ !== newLayer.values_.posZ) {
                        let oldUrlParts = oldLayer.getSource().getUrl().split('/');
                        oldUrlParts[oldUrlParts.length - 2] = newLayer.values_.posZ;
                        oldLayer.setSource(new Static({
                            imageExtent: oldLayer.getSource().getImageExtent(),
                            url: oldUrlParts.join('/'),
                            imageSize: imageInfo.imageSizes[newLayer.values_.posZ],
                        }))
                        oldLayer.values_.posZ = newLayer.values_.posZ;
                    }
                    foundInNewLayers = true;
                    layerFoundAtIndex = i;
                }
            }
            
            if (foundInNewLayers) {
                newLayers.splice(layerFoundAtIndex, 1);
            } else if (oldLayer.values_.name !== mapLayerTypes.STITCHED_LAYER_NAME && oldLayer.values_.name !== mapLayerTypes.ANNOTATION_LAYER_NAME
				&& oldLayer.values_.name !== mapLayerTypes.BLOOD_ANNOTATION_LAYER_NAME && oldLayer.values_.name !== mapLayerTypes.BRAIN_META_VIEWER_ANNOTATION_LAYER_NAME) {
				layersToRemove.push(oi);
			} else if (oldLayer.values_.name === mapLayerTypes.ANNOTATION_LAYER_NAME ||
				oldLayer.values_.name === mapLayerTypes.BLOOD_ANNOTATION_LAYER_NAME ||
				oldLayer.values_.name === mapLayerTypes.BRAIN_META_VIEWER_ANNOTATION_LAYER_NAME) {
				oldLayer.setVisible(true);
			} else {
				oldLayer.setVisible(false);
			}
        }

        layersToRemove.reverse();
        
        for (let oi in layersToRemove) {
            let oldLayer = allOldLayers[layersToRemove[oi]];
            slidemap.removeLayer(oldLayer);
        }
        for(let index in newLayers) {
            slidemap.addLayer(newLayers[index]);
        }   
    } else {
        let allOldLayers = slidemap.getLayers().getArray();
        let stitchedLayerExists = false;
        let layersToRemove = [];
        
        for (let oi in allOldLayers) {
            let oldLayer = allOldLayers[oi];
			if (oldLayer.values_.name === mapLayerTypes.STITCHED_LAYER_NAME) {
				stitchedLayerExists = true;
				oldLayer.setVisible(true);
			} else if (oldLayer.values_.name === mapLayerTypes.ANNOTATION_LAYER_NAME ||
				oldLayer.values_.name === mapLayerTypes.BRAIN_META_VIEWER_ANNOTATION_LAYER_NAME) {
				oldLayer.setVisible(true);
			} else if (oldLayer.values_.name === mapLayerTypes.BLOOD_ANNOTATION_LAYER_NAME) {
				oldLayer.setVisible(true);
			} else if (oldLayer.values_.name === mapLayerTypes.TILED_LAYER_NAME) {
				layersToRemove.push(oi);
			}
        }

        layersToRemove.reverse();
        
        for (let oi in layersToRemove) {
            let oldLayer = allOldLayers[layersToRemove[oi]];
            slidemap.removeLayer(oldLayer);
        }

        if (!stitchedLayerExists) {
            newLayers = getLayer(tileSize, projection, resolutions, url);
            
            for(let index in newLayers) {
                slidemap.addLayer(newLayers[index]);
            }   
        } else {

        }
    }
    
    setState({
        layer: newLayers,
    }); 

    updateUrlState();

    return newLayers;
}

const initialiseMap = (slide_data, setState, urlState, updateUrlState, imageInfo, txtyInfo, updateLayers, onMapRenderingComplete, scrollActionOnMap) => {

    let tileSize = getTileSize(slide_data, false);
    let zoomLevels = getZoomLevels(slide_data);
    let resolutions = getResolutions(slide_data, zoomLevels);
    let maxZoom = zoomLevels.length - 1;
    let imageShape = getImageShape(slide_data);
	let projection = getProjection(imageShape);
	let view = getView(projection, resolutions, urlState, imageShape, urlState, slide_data.viewer_rotation);
	let zoom = urlState.z;
    
    let splitStitchedLevels = slide_data.z_levels.split(',');
    let lastStitchedLevel = parseInt(splitStitchedLevels[splitStitchedLevels.length - 1]);
    let tiled_levels = [];
    for (let i = 0; i < lastStitchedLevel - 2; i++) {
        tiled_levels.push(i);
    }
    let splitTiledLevels = slide_data.tiling_z_levels != null ? slide_data.tiling_z_levels.split(',') : tiled_levels;
    let lastTiledLevel = parseInt(splitTiledLevels[splitTiledLevels.length - 1]);
    let numberOfTiledLevels = lastTiledLevel - slide_data.smallest_tiled_level + 1;

    let viewLevelsInfo = {
        lastStitchedLevel,
        numberOfTiledLevels,
        lastTiledLevel,
        smallestTiledLevel: slide_data.smallest_tiled_level
    }

	let urlSuffix = getSuffixUrl(slide_data, isZoomForTiled(zoom, viewLevelsInfo), 0);
	let url = getPrefixedUrl(urlSuffix, slide_data);

	let layer;
	if (isZoomForTiled(zoom, viewLevelsInfo))
		layer = getImageLayers(url, zoom, imageInfo, slide_data, txtyInfo, [0, 0, 0, 0], lastTiledLevel, 0, -5, 5);
	else
		layer = getLayer(tileSize, projection, resolutions, url);

	let slidemap = getMap(slide_data.id, view, layer, true, scrollActionOnMap);
	let zoomScale = getZoomScale(slide_data, maxZoom, urlState);
	let desiredZoomScale = getDesiredZoomScale(slide_data, maxZoom);
	let ZValues = getZValue(zoomScale, desiredZoomScale);

	slidemap.addControl(
		new ScaleLine({
			units: "metric",
			minWidth: 100,
		})
	);

	slidemap.addEventListener('moveend', () => {
		updateLayers();
	}, false);

	slidemap.on('rendercomplete', () => {
		onMapRenderingComplete();
	});
        

    setState({
            isFetching: false,
            slide_data, 
            maxZoom, 
            zoomLevels,
            zoomScale, 
            view, 
            layer, 
            slidemap, 
            ZValues, 
            desiredZoomScale,
            // vectorLayer,
            // imageLog,
            imageShape,
            extent: projection.getExtent(),
            viewLevelsInfo,
            imageInfo,
            projection,
            txtyInfo,
            tileSize,
            resolutions,
        });
}

export const getQuadrant = (coordinates, tileCoord, slide_data, imageShape, imageLog, z, maxZoom) => {
	let divisions = Math.pow(2, z === maxZoom ? 2 : 1);
	let widthThreshold = (2049 * slide_data.uperpixel) / divisions;
    let heightThreshold = (2449 * slide_data.uperpixel) / divisions;
    let imageName = "x" + tileCoord[0] + "y" + tileCoord[1] + ".jpg";
    let imagePos = imageLog["map"]["stitching_info"]["map"][imageName]["map"]["Absolute Position"]["map"];
    let tl = [(imagePos["X"] * slide_data.uperpixel), (imagePos["Y"] * slide_data.uperpixel)];
    let xOffset = 0, yOffset = 0, currentXPos = tl[0], currentYPos = tl[1];
    for(let i = 0; i < divisions; i++) {
        xOffset = i;
        currentXPos += widthThreshold;
        if(currentXPos > coordinates[0]) {
            break;
        }
    }
    for(let i = 0; i < divisions; i++) {
        yOffset = i;
        currentYPos += heightThreshold;
        if(currentYPos > (imageShape[1] - coordinates[1])) {
            break;
        }
    }
    return [xOffset, yOffset];
}

export const getTiledImageSizeInMicrons = (slide_data) => {
    let tileSize = getTileSize(slide_data, true);
    tileSize[0] = tileSize[0] * slide_data.uperpixel;
    tileSize[1] = tileSize[1] * slide_data.uperpixel;
    return tileSize;

}

export const getTileNumber = (coordinates, slide_data, imageLog, imageShape) => {
    let widthThreshold = (2049 * slide_data.uperpixel);
    let heightThreshold = (2449 * slide_data.uperpixel);
    let minDistance = 1000000;
    let desiredX = -1;
    let desiredY = -1;
    for(let i = 0; i < slide_data.x_fields; i++) {
        for(let j = 0; j < slide_data.y_fields; j++) {
            let imageName = "x" + i + "y" + j + ".jpg";
			if (imageLog["map"]["stitching_info"]["map"][imageName]) {
				let imagePos = imageLog["map"]["stitching_info"]["map"][imageName]["map"]["Absolute Position"]["map"];
				if (coordinates[0] - (imagePos["X"] * slide_data.uperpixel) > 0 &&
					(imageShape[1] - coordinates[1]) - (imagePos["Y"] * slide_data.uperpixel) > 0 &&
					coordinates[0] - (imagePos["X"] * slide_data.uperpixel) < widthThreshold &&
					(imageShape[1] - coordinates[1]) - (imagePos["Y"] * slide_data.uperpixel) < heightThreshold) {
					let distance = Math.sqrt(Math.pow((coordinates[0] - (imagePos["X"] * slide_data.uperpixel)), 2) +
						Math.pow((imageShape[1] - coordinates[1] - (imagePos["Y"] * slide_data.uperpixel)), 2));
					if (distance < minDistance) {
						desiredX = i;
						desiredY = j;
                        minDistance = distance;
                    }
                }
            }
        }
    }
    return [desiredX, desiredY];
}
