import React, {Component} from 'react';

import {
	CheckCircleFilled,
	CloseOutlined,
	DoubleLeftOutlined,
	DoubleRightOutlined,
	PlusOutlined,
} from '@ant-design/icons';

import {Button, Col, Divider, Empty, Input, message, Row, Table, Tooltip} from 'antd';
import {connect} from 'react-redux'
import axios from 'axios';
import cookie from "react-cookies";
import {AuthHeader} from "../../../helper/auth.token"

import '../../../asset/style/neoviewer/slide_info.css'
import '../../../asset/style/custom_antd_table_style.css';
import {globalUrlPrefix, openInNewTabType} from '../../../utils/const';
import {
	decodeStringWithNewLine,
	encodeStringWithNewLine,
	getErrorComponent,
	getLoadingComponent
} from "../../dashboard/slidelist_utils";
import {updateCase} from '../../../action/slide.traversal.action';
import {makeMenuVisible} from "../../../action/context.menu.action";
import {dictSlideOrder} from '../../../utils/dictionary_slide_order';
import {linkViewer} from "../../../utils/utils";
import querystring from "query-string";

const { Column, ColumnGroup } = Table;

class CaseInfoApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slide_data: this.props.slideData,
            slides: [],
            curr_case: {},
            sample_id: this.props.slideData.name,
            patient_id: '',
            patient_name: '',
            age_sex: '',
            patient_scans_done: [],
            patient_scans_pending: [],
            inputValue: '',
            inputVisible: false,
            users: {},
            comments: [],
            viewMode: true,
            viewModeSlideFailed: false,
            lastIndex: -1,
            isFetchingDetails: true,
            isFetchingCases: true,
            isFetchingComments: true,
            hasDetailsThrownError: true,
            hasCasesThrownError: true,
            hasCommentsThrownError: true,
            unread_count: 0,
            caseNotesData: [],
            caseNotes:''
        }
    }

    getCaseNotes = (currCase) => {
        let url = `/api/get_case_notes/?case_id=${currCase.id}`
        axios.get(url).then(response => {
            this.setState({caseNotesData:response.data["result"]})
        })
    }

    get_patient_details = () => {
        this.setState({
            isFetchingDetails: true,
            hasDetailsThrownError: true,
            isFetchingCases: true,
            hasCasesThrownError: true,
        });
        let url = '/api/case/' + this.state.slide_data.case + `/`;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                let curr_case = {};
                if (response.data.id) {
                    curr_case = response.data;
                    this.get_patient_slides(curr_case.id);
                    this.setState({
                        viewMode: true,
                        curr_case,
                        patient_id: (curr_case.case_id || ''),
                        patient_name: (curr_case.patient_name || ''),
                        age_sex: (curr_case.age_and_sex || ''),
                        isFetchingDetails: false,
                        hasDetailsThrownError: false,
                    });
                    this.getCaseNotes(curr_case);
                } else {
                    this.setState({
                        viewMode: false,
                        isFetchingDetails: false,
                    });
                }
            })
            .catch(err => {
                console.log("Failed request", err);
                this.setState({
                    viewMode: true,
                    isFetchingDetails: false,
                    isFetchingCases: false,
                });
            });
    }

    get_patient_slides = (case_id) => {
        let url = '/api/nonpagslides/?case_id=' + case_id;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                let all_patient_scans_done = response.data;
                let patient_scans_done = [];
                let unread_count = 0;
                for (let i = 0; i < all_patient_scans_done.length; i++) {
                    all_patient_scans_done[i].key = all_patient_scans_done[i].id;
                    all_patient_scans_done[i].original_name = all_patient_scans_done[i].name;
                    all_patient_scans_done[i].name = all_patient_scans_done[i].unread ? 
                        <b> {this.getSlideNameComponent(all_patient_scans_done[i])} </b> : 
                        this.getSlideNameComponent(all_patient_scans_done[i]);
                    all_patient_scans_done[i].type = openInNewTabType.SLIDE;
                    if(this.state.slide_data.id != all_patient_scans_done[i].id) {
                        patient_scans_done.push(all_patient_scans_done[i]);
                        if(all_patient_scans_done[i].unread) {
                            unread_count++;
                        }
                    }
                }
                this.props.dispatch(updateCase(case_id, all_patient_scans_done, this.state.slide_data.id));
                this.setState({
                    patient_scans_done,
                    isFetchingCases: false,
                    hasCasesThrownError: false,
                    unread_count,
                });
            })
            .catch(err => {
                console.log("Failed request", err);
                this.setState({
                    isFetchingCases: false,
                });
            });
    }

    getSlideNameComponent = (slide) => {
        return (
            <span>
                {slide.name} &nbsp; 
                <CheckCircleFilled style={{color: "blue"}} />
            </span>
        );
    }

    get_comments = () => {
        let url = '/api/microscopy_observations/?slide_id=' + this.state.slide_data.id;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                let data = response.data;
                for (let i = 0; i < data.length; i++) {
                    data[i].key = data[i].id;
                    let modifiedValue = decodeStringWithNewLine(data[i].observation);
                    data[i].observation_original = data[i].observation;
                    data[i].observation = modifiedValue;
                }
                this.setState({
                    comments: data,
                    isFetchingComments: false,
                    hasCommentsThrownError: false,
                });
            })
            .catch(err => {
                console.log("Failed request", err);
                this.setState({
                    isFetchingComments: false,
                });
            });
    }

    componentDidMount = () => {
        this.get_comments();
        this.get_patient_details();
    }


    saveCaseNotes = () => {
        let data = this.state.inputValue
        let url = `/api/store_validation_data/`;
        axios.post(url, querystring.stringify({
            'type':"case-notes",'case_id':this.state.curr_case.id,'data':JSON.stringify(data),user: parseInt(cookie.load('user_id'))
        })).then(res => {
            message.success("Case Notes Updated Successfully")
            let caseNotesData = this.state.caseNotesData
            caseNotesData.push(res.data)
            this.setState({
                caseNotesData: caseNotesData,
                inputValue: '',
                inputVisible:false
            })
        })
    }

    addComment = () => {
        let modifiedValue = encodeStringWithNewLine(this.state.inputValue);
        let val = {
            "slide": this.state.slide_data.id,
            "case": this.state.curr_case.id,
            "observation": modifiedValue,
            "user": parseInt(cookie.loadAll().user_id)
        };
        let url = `/api/microscopy_observations/`;
        axios.post(url, val, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 201 || response.status === 200 || response.status === 301 || response.status === 302) {
                    let comments = this.state.comments;
                    response.data.key = response.data.id;
                    let modifiedValue = decodeStringWithNewLine(response.data.observation);
                    response.data.observation_original = response.data.observation;
                    response.data.observation = modifiedValue;
                    comments.push(response.data);
                    this.setState({
                        comments,
                        inputValue: '',
                        inputVisible: false,
                        lastIndex: comments.length - 1,
                    });
                    message.success('Observation Added Successfully', 2.5);
                    this.handleScroll();
                }
                else if (response.status === 403 || response.status === 401 || response.status === 400) {
                    message.error('Observation not inserted', 2.5);
                }
            })
            .catch(err => {
                message.error('Observation not inserted', 2.5);
            });
    }

    
    handleScroll = () => {
        var element = document.querySelector('.scroll-row');
        element.scrollIntoView();
    }

    showInput = () => {
        this.setState({ inputVisible: true }, () => this.input.focus());
    }

    handleInputChange = (e) => {
        this.setState({ inputValue: e.target.value });
    }

    changeInputView = () => {
        this.setState({ inputVisible: false, inputValue: '' });
    }

    goToCase = () => {
        let case_id = this.state.slide_data.case;
        if(this.state.slide_data.in_validation_study)
            window.location.href = "/" + globalUrlPrefix + "/clia_case/" + case_id;
        else
            window.location.href = "/" + globalUrlPrefix + "/case/" + case_id;
    }

    goToPrevSlide = () => {
        window.location.href = "/" + globalUrlPrefix + "/viewer/" + this.props.prev_slide_id;
    }

    goToNextSlide = () => {
        window.location.href = "/" + globalUrlPrefix + "/viewer/" + this.props.next_slide_id;
    }

    saveInputRef = input => this.input = input

    getPreviewUrl = (url, slide) => {
        let prefix;
        if (slide.status === 12) {
          prefix = "/scan-hdd/";
        }
        else {
          prefix = "/scan-ssd/";
        }
        return prefix + url;
      }
      
    getPreviewComponent = (slide, is_cloud, isMobile) => {
        let previewPath = slide.preview_image;
    
        if (is_cloud === false) {
            previewPath = previewPath.replace("/static/", "");
            previewPath = this.getPreviewUrl(previewPath.replace('stitched_small_viewer', 'selection_marked'), slide);
        }
        return <img
            width={isMobile ? 100 : 200}
            alt="logo"
            src={previewPath}
        />
    }
    
    getLabelComponent = (slide, is_cloud, isMobile) => {
        let labelPath = slide.preview_image;
    
        if (is_cloud === false) {
            labelPath = labelPath.replace("/static/", "");
            labelPath = this.getPreviewUrl(labelPath.replace('stitched_small_viewer', 'barcode_crop'), slide);
        } else {
            labelPath = labelPath.replace('stitched_small_viewer', 'barcode_crop');
        }
    
        return <img
            height={96}
            alt="barcode-does-not-exist"
            src={labelPath}
        />
    }
    render() {
        console.log("patient_scans_done", this.state.slide_data)
        const slide_columns = [
            {
                title: 'SLIDE',
                dataIndex: 'name',
                key: 'name',
            },{
                title: '',
                dataIndex: 'thumbnail',
                render:(text, record)=>{
                    console.log("record", record)
                    return <div>
                        {this.getLabelComponent(record,false, false)}
                        {this.getPreviewComponent(record,false, false)}
                    </div>
                }
            }
        ];

        const comments_columns = [
            {
                title: 'User',
                dataIndex: 'user_name',
                key: 'user_name',
                width: 100,
            },
            {
                title: 'Observation',
                dataIndex: 'observation',
                key: 'observation',
            },
        ];

        let overallFetchingState = (this.state.isFetchingCases && this.state.isFetchingComments && this.state.isFetchingDetails);

        let overallErrorState = (this.state.hasCasesThrownError && this.state.hasCommentsThrownError && this.state.hasDetailsThrownError);

        let patient_scans_done_count = 'Other Slides In Patient Folder (Total ' + this.state.patient_scans_done.length + ' slides found)';

        let slide_number = 'Slide ' + (this.props.current_index + 1) +  ' of ' + this.props.case_slides_in_store.length;
        let unread_slide_text = '(' + this.state.unread_count + ' unread)';

        const onRow = (record, rowIndex) => {
            return {
                onClick: (event) => {
                    if(event.ctrlKey) {
                        linkViewer(record);
                    } else {
                        linkViewer(record);
                    }
                },
                onContextMenu: (event) => {
                    event.preventDefault();
                    this.props.dispatch(makeMenuVisible({visible: true, x: event.clientX, y: event.clientY, record: record, height: this.props.height}));
                }
            };
        }
        
        let patient_scans_done_card = [
            <Row key="slide-info-details">
                <h3 className='slide-info-mp' style={{ textAlign: 'center', margin: "5px" }}>{patient_scans_done_count}</h3>
                <Divider />
                {this.state.isFetchingCases ? getLoadingComponent() :
                    this.state.hasCasesThrownError ? getErrorComponent("Not able to retrieve scans.") :
                    this.state.patient_scans_done.length > 0 ?
                        <Table 
                            className="custom-hover-table-case custom-table-height comment-info-table"
                            rowClassName={(record, index) => (record.unread ? 'slide-table-rows custom-hover-table-box unread' : 'slide-table-rows custom-hover-table-box read')} 
                            columns={slide_columns} 
                            dataSource={this.state.patient_scans_done} 
                            pagination={false} 
                            scroll={{ y: 230 }}
                            size="small"
                            onRow={(this.props.urlState || {}).presentCode != undefined ? null : onRow}
                        /> :
                        <Empty description="No Scans Found" />
                }
                
            </Row>
        ];

        let comments_info_card = [
            <Row key="comment-info-details">
                <h3 className='slide-info-mp' style={{ textAlign: 'center', margin: "5px" }}>Microscopy Observations</h3>
                <Divider />
                {this.state.inputVisible && !this.state.isFetchingComments && !this.state.hasCommentsThrownError && (
                    <div>
                        <Row>
                            <Col span={23}>
                                <Input.TextArea
                                    placeholder="Observation"
                                    type="text"
                                    size="small"
                                    ref={this.saveInputRef}
                                    onChange={this.handleInputChange}
                                    value={this.state.inputValue}
                                    autosize={{ minRows: 3, maxRows:8 }}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={3} offset={1}>
                                <Button
                                    type="primary"
                                    onClick={this.saveCaseNotes}
                                >
                                    Insert
                                </Button>
                            </Col>
                            <Col span={2} offset={1}>
                                <Tooltip placement="bottomRight" title="Cancel">
                                    <Button
                                        type="danger"
                                        onClick={this.changeInputView}
                                    >
                                        <CloseOutlined />
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    </div>
                    

                )}
                {!this.state.inputVisible && !this.state.isFetchingComments && !this.state.hasCommentsThrownError && (
                    <Button
                        onClick={this.showInput}
                        style={{ background: '#fff', borderStyle: 'dashed', marginBottom: '5px' }}
                        disabled={(this.props.urlState || {}).presentCode != undefined}
                    >
                        <PlusOutlined /> New Case Notes
                    </Button>
                )}
                <br />
                {this.state.isFetchingComments ? getLoadingComponent() :
                    this.state.hasCommentsThrownError ? getErrorComponent("Not able to retrieve observations.") :
                    // <Table
                    //     style={{ whiteSpace: 'pre-wrap'}}
                    //     className="custom-hover-table comment-info-table" 
                    //     rowClassName={(record, index) => (index === this.state.lastIndex ? 'scroll-row' : '')}
                    //     columns={comments_columns}
                    //     dataSource={this.state.comments}
                    //     pagination={false}
                    //     showHeader={true}
                    //     scroll={{ y: 230 }}
                    //     size="small"
                    //     bordered
                    // />
                    <Table
                            className="custom-hover-table-case custom-table-height custom-table-selection custom-table-header"
                            //  columns={validation_columns}
                            dataSource={this.state.caseNotesData}
                            size="small"
                            style={{height:'200px', overflowY:'scroll'}}
                            >
                            <Column title="DATE" dataIndex="date" width='5vw'/>
                            <Column title="PATHOLOGIST"  dataIndex="user" width='8vw'/>
                            <Column title="NOTES" dataIndex="notes"/>                            
                    </Table>
                }
            </Row>
        ];

        return (
            <div>
                <Row>
                    <Col span={24} className="slide-info-app-parent-case-info overlayed-component app-parent">
                        <br/>
                        <div className="slide-info-text" >
                            {overallFetchingState ? 
                                getLoadingComponent() :
                                overallErrorState ? 
                                    getErrorComponent() :
                                    this.state.viewModeSlideFailed ?
                                        getErrorComponent() :
                                        !this.state.viewMode ?
                                            <div>
                                                <br />
                                                <br />
                                                <span>No Cases Found</span>
                                            </div>
                                            :
                                            <Col  className="scroll" sm={{ span: 24, offset: 0 }} mg={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}>
                                                {!this.state.hasCasesThrownError && this.props.case_slides_in_store.length > 1 ?
                                                    <Row>
                                                        <Col span={9} offset={1}>
                                                            <Button
                                                                onClick={this.goToPrevSlide}
                                                                type="primary"
                                                                size="small"
                                                                ghost
                                                                disabled={(this.props.urlState || {}).presentCode != undefined}
                                                            >
                                                                <DoubleLeftOutlined /> Previous Slide
                                                            </Button>
                                                        </Col>
                                                        <Col span={5}>
                                                            <Row><b>{slide_number}</b></Row>
                                                            <Row style={{paddingLeft: '5px'}}>{unread_slide_text}</Row>
                                                        </Col>
                                                        <Col span={6} offset={1} push={2}>
                                                            <Button
                                                                onClick={this.goToNextSlide}
                                                                type="primary"
                                                                size="small"
                                                                ghost
                                                                disabled={(this.props.urlState || {}).presentCode != undefined}
                                                            >
                                                                Next Slide <DoubleRightOutlined />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    : null
                                                }
                                                <Divider style={{ top: "5px" }} />
                                                {this.state.isFetchingDetails ? getLoadingComponent() :
                                                    this.state.hasDetailsThrownError ? getErrorComponent("Not able to retrieve slide details.") :
                                                    <Row>
                                                        <Col sm={dictSlideOrder.OUTER_COL_DETAILS_SM} mg={dictSlideOrder.OUTER_COL_DETAILS_MG} lg={dictSlideOrder.OUTER_COL_DETAILS_LG}>
                                                            <Col sm={dictSlideOrder.INNER_COL_DETAILS_HEADING_SM} mg={dictSlideOrder.INNER_COL_DETAILS_HEADING_MG} lg={dictSlideOrder.INNER_COL_DETAILS_HEADING_LG} style={dictSlideOrder.FONTWEIGHT_HEADING}>Patient Name</Col>
                                                            <Col sm={dictSlideOrder.INNER_COL_DETAILS_VALUE_SM} mg={dictSlideOrder.INNER_COL_DETAILS_VALUE_MG} lg={dictSlideOrder.INNER_COL_DETAILS_VALUE_LG} style={dictSlideOrder.FONTWEIGHT_DETAILS}>{this.state.patient_name}</Col>

                                                            <Col sm={dictSlideOrder.INNER_COL_DETAILS_HEADING_SM} mg={dictSlideOrder.INNER_COL_DETAILS_HEADING_MG} lg={dictSlideOrder.INNER_COL_DETAILS_HEADING_LG} style={dictSlideOrder.FONTWEIGHT_HEADING}>DOB/SEX</Col>
                                                            <Col sm={dictSlideOrder.INNER_COL_DETAILS_VALUE_SM} mg={dictSlideOrder.INNER_COL_DETAILS_VALUE_MG} lg={dictSlideOrder.INNER_COL_DETAILS_VALUE_LG} style={dictSlideOrder.FONTWEIGHT_DETAILS}>{this.state.age_sex}</Col>

                                                            <Col sm={dictSlideOrder.INNER_COL_DETAILS_HEADING_SM} mg={dictSlideOrder.INNER_COL_DETAILS_HEADING_MG} lg={dictSlideOrder.INNER_COL_DETAILS_HEADING_LG} style={dictSlideOrder.FONTWEIGHT_HEADING}>SAMPLE ID</Col>
                                                            <Col sm={dictSlideOrder.INNER_COL_DETAILS_VALUE_SM} mg={dictSlideOrder.INNER_COL_DETAILS_VALUE_MG} lg={dictSlideOrder.INNER_COL_DETAILS_VALUE_LG} style={dictSlideOrder.FONTWEIGHT_DETAILS}>{this.state.sample_id}</Col>
                                                        </Col>
                                                        <Col>
                                                            <span style={{float :'left', marginTop: "20px"}}>
                                                                {(this.state.slide_data.case && this.state.slide_data.case != '') ? 
                                                                    <Button
                                                                        onClick={this.goToCase}
                                                                        type="primary"
                                                                        style={{ marginLeft: "15px" }}
                                                                        disabled={(this.props.urlState || {}).presentCode != undefined}
                                                                    >
                                                                        Go to Patient Folder
                                                                    </Button> : null
                                                                }
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                }
                                                <Divider style={{ top: "5px" }} />

                                                {comments_info_card}

                                                <Divider style={{ top: "5px" }} />

                                                {patient_scans_done_card}
                                            </Col>
                                }
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loginStatus: state.loginAlerts.loginStatus,
        next_slide_id: state.slideTraversalReducer.next_slide_id,
        prev_slide_id: state.slideTraversalReducer.prev_slide_id,
        case_slides_in_store: state.slideTraversalReducer.slides,
        current_index: state.slideTraversalReducer.current_index,
        urlState: state.viewerUrlReducer
    }
}

export default connect(mapStateToProps)(CaseInfoApp)
