import React, { Component } from 'react';
import { Row, Col, Input, Checkbox, Divider, Button, message, Slider, InputNumber } from 'antd';
import { connect } from "react-redux";
import { updateInputFieldInFocus, updateZStackLevels, updateStepSizeZStack, getLiveImageMoveAndFocus, getNearestTile } from "../../action/livemode.action";
import { liveModeLoadingMessages } from '../../utils/const';

class LiveModeZStackSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    zStackValueChange = (value) => {
        this.props.dispatch(updateZStackLevels(value));
    }

    zStackValueChangeAndTakeImage = (value) => {
        this.props.dispatch(updateZStackLevels(value));
        this.getLiveImage(value, this.props.livemode.stepSizeZStack);
    }

    stepSizeValueChange = (value) => {
        this.props.dispatch(updateStepSizeZStack(value));
    }

    stepSizeValueChangeAndTakeImage = (value) =>{
        this.props.dispatch(updateStepSizeZStack(value));
        this.getLiveImage(this.props.livemode.zStackLevels, value);
    }

    onFocusInputField = () => {
        this.props.dispatch(updateInputFieldInFocus(true));
    }

    onBlurInputField = () => {
        this.props.dispatch(updateInputFieldInFocus(false));
    }

    getLiveImage = (ZLevels, stepSize) => {
        let partsOfUrl = "api~stage~move_and_focus";
        let url = `/server/scano_text/` + this.props.livemode.deviceId + `/` + partsOfUrl;
        if (this.props.adminState.slotID != -1) {
            url += '?slotID=' + this.props.adminState.slotID;
        } else {
            url += '?slotID=';
        }
        url += "&x=0&y=0&z=0&doDrop=" + this.props.adminState.doDropDistanceAF +
            "&doAF=" + this.props.adminState.doAutoFocus + "&numZStack=" + (ZLevels * 2) + 
            "&zStackStep=" + stepSize + "&timestamp=" + Date.now();

        let nearestTile = getNearestTile(this.props.livemode.currentMapPosition[this.props.adminState.slotID].x, this.props.livemode.currentMapPosition[this.props.adminState.slotID].y, this.props.livemode.tileCenters[this.props.adminState.slotID]);
        let nearestTileY = parseInt(nearestTile.split('_')[1]);

        url += `&nearestTileY=` + nearestTileY;

        let errorMessage = '';
        if (this.props.adminState.doDropDistanceAF) {
            errorMessage = liveModeLoadingMessages.DROP_DISTANCE_AF;
        } else if (this.props.adminState.doAutoFocus) {
            errorMessage = liveModeLoadingMessages.AUTO_FOCUS;
        } else {
            errorMessage = liveModeLoadingMessages.MOVE;
        }

        this.props.dispatch(getLiveImageMoveAndFocus(url, errorMessage, this.props.adminState.slotID, this.props.adminState.takeZStack, [], false, 
            this.props.adminState.areaVisited, this.props.adminState.liveModePreviewVectorLayer, this.props.livemode.currentMapPosition));
    }

    getCameraAppButton = (text, onClickMethod) => {
        return (
            <Button size="small" onClick={onClickMethod}>
                {text}
            </Button>
        )
    }

    getCameraInputFieldRow = (haveRowStyle, heading, inputField) => {
        return (
            <Row style={haveRowStyle ? { marginTop: 5 } : {}}>
                <Col offset={1} span={14}>
                    <b>{heading}</b>
                </Col>
                <Col offset={1} span={8}>
                    {inputField}
                </Col>
            </Row>
        )
    }

    getCameraAppInputField = (inputValue, onChangeInput) => {
        return <Input size="small" value={inputValue} onFocus={this.onFocusInputField} onBlur={this.onBlurInputField} onChange={onChangeInput} className="camera-app-input-field" />
    }

    getCameraAppCheckbox = (inputValue, onChangeInput) => {
        return <Checkbox checked={inputValue} onChange={onChangeInput} className="custom-checkbox-style"></Checkbox>
    }

    getCameraSliderRow = (haveRowStyle, heading, slider, inputBox) => {
        return (
            <Row style={haveRowStyle ? { marginTop: 5 } : {}}>
                <Col offset={1} span={8}>
                    <b>{heading}</b>
                </Col>
                <Col offset={1} span={9}>
                    {slider}
                </Col>
                <Col offset={1} span={3}>
                    {inputBox}
                </Col>
            </Row>
        )
    }

    render() {

        let minZStackLayersValue = (((this.props.device || {}).scanner_health || {}).livemode_config || {}).minZStackLayers != undefined ? 
            (((this.props.device || {}).scanner_health || {}).livemode_config || {}).minZStackLayers : 5;
        let maxZStackLayersValue = (((this.props.device || {}).scanner_health || {}).livemode_config || {}).maxZStackLayers != undefined ? 
            (((this.props.device || {}).scanner_health || {}).livemode_config || {}).maxZStackLayers : 20;

        let minZStackStepSizeValue = (((this.props.device || {}).scanner_health || {}).livemode_config || {}).minZStackStepSize != undefined ? 
            (((this.props.device || {}).scanner_health || {}).livemode_config || {}).minZStackStepSize : 0.1;
        let maxZStackStepSizeValue = (((this.props.device || {}).scanner_health || {}).livemode_config || {}).maxZStackStepSize != undefined ? 
            (((this.props.device || {}).scanner_health || {}).livemode_config || {}).maxZStackStepSize : 2;

        let sliderDisabled = this.props.adminState.live_mode_action || !this.props.livemode.accessToLiveMode || this.props.livemode.useLiveModeInViewMode
			|| this.props.adminState.slotID < 0 || !this.props.adminState.main_light || this.props.adminState.doDropDistanceAF || !this.props.livemode.atLeastOneImageFetched;

        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={24} className="settings-heading">
                            <b>Z Stack Settings</b>
                        </Col>
                    </Row>
                    <Row>
                        <Col offset={1} span={22} className="custom-top-bottom-margin-divider">
                            <Divider />
                        </Col>
                    </Row>
                    {this.getCameraSliderRow(false, 'Layer Count',
                        <Slider value={this.props.livemode.zStackLevels} min={minZStackLayersValue} max={maxZStackLayersValue} tooltipVisible={false}
                            onChange={this.zStackValueChange} onAfterChange={this.zStackValueChangeAndTakeImage} style={{padding: 0, margin: '8px 0px 0px 0px'}}
                            disabled={sliderDisabled} />,
                        <InputNumber style={{width: '100%'}} className="card-color" size="small" value={(this.props.livemode.zStackLevels * 2) + 1} disabled={true} />
                    )}
                    {this.getCameraSliderRow(true, 'Step Size (μm)',
                        <Slider value={this.props.livemode.stepSizeZStack} min={minZStackStepSizeValue} max={maxZStackStepSizeValue} step={0.1} 
                            tooltipVisible={false}
                            onChange={this.stepSizeValueChange} onAfterChange={this.stepSizeValueChangeAndTakeImage} style={{padding: 0, margin: '8px 0px 0px 0px'}}
                            disabled={sliderDisabled} />,
                        <InputNumber style={{width: '100%'}} className="card-color" size="small" value={this.props.livemode.stepSizeZStack} disabled={true} />
                    )}
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        liveView: state.liveViewReducer,
        adminState: state.adminUrlReducer,
        livemode: state.livemodeReducer,
        device: state.deviceStatusReducer[state.livemodeReducer.deviceId]
    };
};

export default connect(mapStateToProps)(LiveModeZStackSettings);
