import { BloodConfigActions } from "../actionTypes/bloodConfig.const";

const InitialState = {
  yTop:1,
  yBottom: 10,
  countWBC: 100,
  countWBCSlotWise:{},
}

export const bloodConfigReducer = ( state = InitialState , action) => {
  switch(action.type){
        case BloodConfigActions.UPDATE_YTOP:
            return Object.assign({}, state, {
                yTop: action.response
            })
        case BloodConfigActions.UPDATE_YBOTTOM:
            return Object.assign({}, state, {
                yBottom: action.response
            })
        case BloodConfigActions.UPDATE_WBC_COUNT:
            console.log("updateCount", action.response)
            return Object.assign({}, state, {
                countWBC: action.response
            })
        case BloodConfigActions.UPDATE_COUNT_SLOT_WISE:
            let slot_count = state.countWBCSlotWise
            slot_count[action.response[0][0]] = action.response[0][1]
            console.log("set",action.response[0][0], action.response[0][1])
            return Object.assign({}, state, {
                countWBCSlotWise: slot_count,
            })
        case BloodConfigActions.RESET_COUNT_SLOT_WISE:
            return Object.assign({}, state, {
                countWBCSlotWise: {},
            })
        default :
            return state
  }
}