import React from "react";
import {SettingComponentType} from "../../../utils/const";
import {SliderRow} from "../components/components";

export const getSettingComponent = (setting, value, update) => {
	// [Priyanshu] setting default value
	if (value === undefined) update(setting.value);  // !value means 0 is true
	switch (setting.type) {
		case SettingComponentType.SLIDER:
			return <SliderRow label={setting.name} icon={setting.icon} value={value} min={setting.min}
							  max={setting.max} update={update}/>;
		default:
			return null
	}
}
