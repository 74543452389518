import React, {Component} from "react";
import {connect} from 'react-redux';
import {FormControl, Grid, IconButton, MenuItem, Select, Stack, TextField, Tooltip} from "@mui/material";
import {updateSlideData} from "../../../action/maps.state.action";
import {ReplayOutlined} from "@mui/icons-material";
import {getSettingComponent} from "../utils/settingsUtils";
import {degreeToRadian} from "../../../utils/utils";
import {RotationKey, SettingsKeys} from "../keys/Settings";
import {LoadingText} from "../components/components";
import {createViewerSetting, loadViewerSettings, updateViewerSetting} from "../../../action/viewer.settings.actions";


class SettingsApp extends Component {

	constructor(props) {
		super(props);
		this.rowHeight = '89px';

		this.state = {};
		this.props.dispatch(loadViewerSettings());
	}

	backup = () => {
		if (!this.props.slideState.backupViewerSettings ||
			this.props.slideState.backupViewerSettings.id !== this.props.viewerSetting.id)
			this.props.slideState.backupViewerSettings = {...this.props.viewerSetting}
	}


	updateViewerSettingId = id =>
		this.props.dispatch(updateSlideData(this.props.activeMapId, this.props.slide.id, {
			settings: id,
		}));

	restoreSettings = () => {
		if (this.props.slideState.backupViewerSettings) {
			this.props.dispatch(updateViewerSetting(this.props.slideState.backupViewerSettings.id,
				this.props.slideState.backupViewerSettings));
			this.updateViewerSettingId(this.props.slideState.backupViewerSettings.id);
		}
	}

    updateRotation = value => {
		this.props.slideState.slidemap.getView().setRotation(degreeToRadian(value));
		this.props.dispatch(updateSlideData(this.props.activeMapId, this.props.slide.id, {
			viewer_rotation: parseInt(value),
		}));
	}

	update = (setting, value) =>
		this.props.dispatch(updateViewerSetting(this.props.viewerSetting.id, {
			[setting.name.toLowerCase()]: parseInt(value),
		}));

	getViewerSettingsDropdown = () =>
		<Tooltip title={"Choose Settings"} placement={"left"}>
			<Select value={this.props.viewerSetting} MenuProps={{
				anchorOrigin: {
					vertical: "bottom",
					horizontal: "right",
				},
				transformOrigin: {
					vertical: "top",
					horizontal: "right",
				}
			}} onChange={e => this.updateViewerSettingId(e.target.value.id)}>
				{Object.values(this.props.viewerSettings)
					.filter(viewerSetting => viewerSetting !== this.props.bloodViewerSetting)
					.map(viewerSetting => <MenuItem value={viewerSetting}>{viewerSetting.name}</MenuItem>)}
				<TextField color='secondary' margin='normal' variant={"outlined"} placeholder={"+ new"}
						   size="small" sx={{px: 1, mx: 0, width: 80,}}
						   inputProps={{maxLength: 15}}
						   onKeyDown={(e) =>
							   e.keyCode === 13 ? [this.props.dispatch(createViewerSetting({
								   ...this.props.viewerSetting,
								   name: e.target.value,
							   })), e.target.value = ''] : e.stopPropagation()}/>
			</Select>
		</Tooltip>

	render() {
		if (!this.props.viewerSetting)
			return <LoadingText/>;

		this.backup();

		let viewerSettingsSelect =
			<Grid container paddingBottom={1} direction={"row"} justifyContent={"space-between"}>
				<Tooltip title={"Restore"} placement={"right"}>
					<IconButton color={"secondary"} onClick={() => this.restoreSettings()}>
						<ReplayOutlined/>
					</IconButton>
				</Tooltip>
				<FormControl variant="standard">
					{this.getViewerSettingsDropdown()}
				</FormControl>
			</Grid>

		return <Stack direction={"column"} spacing={2}>
			{viewerSettingsSelect}
			{getSettingComponent(RotationKey, this.props.slide.viewer_rotation, this.updateRotation)}
			{SettingsKeys.map(setting => getSettingComponent(setting,
				this.props.viewerSetting[setting.name.toLowerCase()], value => this.update(setting, value)))}
		</Stack>
	}
}

export const mapStateToProps = (state) => {
	let {activeMapId} = state.gammaStateReducer;
	let {slideState} = state.mapsStateReducer[activeMapId];
	let slide = slideState.slide_data;
	let viewerSettings = state.viewerSettingsReducer;
	let viewerSetting = viewerSettings[slide.settings];
	let bloodViewerSetting = viewerSettings[slide.bloodsettings];

	return {activeMapId, slideState, slide, viewerSettings, viewerSetting, bloodViewerSetting};
}

export default connect(mapStateToProps)(SettingsApp);
