import cookie from "react-cookies";
import axios from 'axios';
import {AuthHeader} from "../helper/auth.token"
import {presentAppKey} from "../component/neoviewer/apps/app_keys";
import {
	CLEAR_FOLLOWING_STATUS,
	CLEAR_PRESENTING_STATUS,
	INIT_STATE,
	START_PRESENTING,
	UPDATE_APP_CLICK_TIME,
	UPDATE_APP_CLOSED_STATE,
	UPDATE_APP_INSTALLED_STATE,
	UPDATE_APP_POSITION,
	UPDATE_COLLAPSE_MENU_STATE,
	UPDATE_DEVICES_ZOOM_LEVELS,
	UPDATE_MORPHLE_ID,
	UPDATE_ON_STITCHED_LAYER,
	UPDATE_PRESENT_TIMESTAMP,
	UPDATE_QUADRANT,
	UPDATE_SHAPE,
	UPDATE_STATE,
	UPDATE_TILE_COORDINATE,
	UPDATE_Z_STACK_LEVEL,
	UPDATE_Z_STACK_PARAMS
} from "../actionTypes/morpheus.state.constant";

export const getNewState = () => {
    return {
        z: 0, 
        r: -1,
        x: -1, 
        y: -1,
        showGrid: false,
        gridColor: "",
        gridSize: 500, 
        gridWidth: 3, 
        digitalZoomStatus: false,
        annoColor: "black",
        previewColor: false, 
        app_state: {},
        apps_installed: {},
        collapse_menu: {},
        apps_initialised: false, 
        slide_morphle_id: '',
        slide_id: -1,
        grid_timestamp: -1, 
        settings_timestamp: -1, 
        annotations_timestamp: -1, 
        zoom_timestamp: -1, 
        tileCoord: [],
        quadrant: [],
        takeZStack: false,
        numZLevels: -1,
        takeBidirectionalZStack: false,
        selectedShape: null,
        zStackLevel: 0,
        onStitchedLayer: false,
        levelJump: 1,
}}

const InitialState = getNewState()

export const viewerUrlReducer = ( state = InitialState , action) => {
    let returnableState = {};
    let allAppStates = {};
    let currentAppState = {};
    let newAppState = {};
    let encodedUrl;
    switch(action.type){
        case INIT_STATE:
            returnableState = Object.assign({}, state, action.urlState);
            return returnableState;
        case CLEAR_FOLLOWING_STATUS:
                returnableState = Object.assign({}, state, action.urlState);
                delete returnableState.presentCode;
                encodedUrl = urlEncoder(returnableState);
                window.history.replaceState(returnableState, 'viewer_url', encodedUrl);
                updateAppSettingsInDb(returnableState.app_state);
                return returnableState;
        case CLEAR_PRESENTING_STATUS:
            returnableState = Object.assign({}, state, action.urlState);
            delete returnableState.app_state[presentAppKey.id].code;
            updateAppSettingsInDb(returnableState.app_state);
            return returnableState;
        case START_PRESENTING:
            returnableState = Object.assign({}, action.urlState);
            encodedUrl = urlEncoder(returnableState);
            window.history.replaceState(returnableState, 'viewer_url', encodedUrl);
            updateAppSettingsInDb(returnableState.app_state);
            return returnableState;
        case UPDATE_STATE:
            returnableState = Object.assign({}, action.urlState);
            encodedUrl = urlEncoder(returnableState);
            window.history.replaceState(returnableState, 'viewer_url', encodedUrl);
            return returnableState;
        case UPDATE_COLLAPSE_MENU_STATE:
            let currentCollapseMenu = state.collapse_menu;
            let newCollapseMenu = Object.assign({}, currentCollapseMenu, {
                [action.collapseMenuItemKey]: action.collapseMenuItemStatus
            });
            returnableState = Object.assign({}, state, {
                collapse_menu: newCollapseMenu
            });
            if (!(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience) {
                updateCollapseMenuinDB(newCollapseMenu);
            }
            return returnableState;
        case UPDATE_APP_INSTALLED_STATE:
            let currentAppInstalled = state.apps_installed;
            let newAppsInstalled = Object.assign({}, currentAppInstalled, {
                [action.appName + "_installed"]: action.appInstalledStatus
            });
            returnableState = Object.assign({}, state, {
                apps_installed: newAppsInstalled
            });
            if(!(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience) {
                updateAppsInstalledinDB(newAppsInstalled);
            }
            return returnableState;      
        case UPDATE_APP_CLOSED_STATE:
            allAppStates = state.app_state;
            currentAppState = allAppStates[action.appName] || {};
            newAppState = Object.assign({}, allAppStates, {
                [action.appName]: Object.assign({}, currentAppState, {
                    closed: action.appClosedStatus}) });
            returnableState = Object.assign({}, state, {
                app_state : newAppState
            });
            if(!(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience) {
                updateAppSettingsInDb(newAppState);
            }
            return returnableState;
        case UPDATE_APP_POSITION:
            allAppStates = state.app_state;
            currentAppState = allAppStates[action.appName] || {};
            newAppState = Object.assign({}, allAppStates, {
                [action.appName]: Object.assign({}, currentAppState, {
                    position: action.appPosition}) });
            returnableState = Object.assign({}, state, {
                app_state : newAppState
            });
            if(!(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience) {
                updateAppSettingsInDb(newAppState);
            }
            return returnableState;
        case UPDATE_APP_CLICK_TIME:
            allAppStates = state.app_state;
            currentAppState = allAppStates[action.appName] || {};
            newAppState = Object.assign({}, allAppStates, {
                [action.appName]: Object.assign({}, currentAppState, {
                    clicked_at: new Date().getTime()}) });
            
            let appVsClickTime = [];

            for (let key in newAppState) {
                appVsClickTime.push({
                    key: key, 
                    val: newAppState[key].clicked_at === undefined ? 0 : newAppState[key].clicked_at
                })
            }

            appVsClickTime = appVsClickTime.sort(function (a, b) {
                return a.val - b.val;
            });

            appVsClickTime.map((item, index) => {
                newAppState[item.key].zIndex = 9 + 2 * index;
            })
            
            returnableState = Object.assign({}, state, {
                app_state : newAppState
            });
            if(!(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience) {
                updateAppSettingsInDb(newAppState);
            }
            return returnableState;
        case UPDATE_DEVICES_ZOOM_LEVELS:
            allAppStates = state.app_state;
            currentAppState = allAppStates[action.appName] || {};
            newAppState = Object.assign({}, allAppStates, {
                [action.appName]: Object.assign({}, currentAppState, {
                    [action.screenSize]: action.zoomRatio
                })
            });
            returnableState = Object.assign({}, state, {
                app_state : newAppState
            });
            updateAppSettingsInDb(newAppState, true);
            return returnableState;
        case UPDATE_MORPHLE_ID:
            returnableState = Object.assign({}, state, {
                slide_morphle_id : action.morphle_id,
                slide_id : action.slide_id
            });
            return returnableState;
        case UPDATE_PRESENT_TIMESTAMP:
            returnableState = Object.assign({}, state, {
                grid_timestamp : action.grid_timestamp,
                settings_timestamp : action.settings_timestamp,
                annotations_timestamp : action.annotations_timestamp,
                zoom_timestamp : action.zoom_timestamp
            });
            return returnableState;
        case UPDATE_TILE_COORDINATE:
            returnableState = Object.assign({}, state, {
                tileCoord : action.tileCoordinate
            });
            return returnableState;
        case UPDATE_QUADRANT:
            returnableState = Object.assign({}, state, {
                quadrant : action.quadrant
            });
            return returnableState;
        case UPDATE_Z_STACK_PARAMS:
            returnableState = Object.assign({}, state, {
                takeZStack: action.takeZStack,
                numZLevels: action.numZLevels,
                takeBidirectionalZStack: action.takeBidirectionalZStack,
                levelJump: action.levelJump,
            });
            return returnableState;
        case UPDATE_SHAPE:
            returnableState = Object.assign({}, state, {
                selectedShape: action.value
            });
            return returnableState;
        case UPDATE_Z_STACK_LEVEL:
            returnableState = Object.assign({}, state, {
                zStackLevel: action.value
            });
            return returnableState;
        case UPDATE_ON_STITCHED_LAYER:
            returnableState = Object.assign({}, state, {
                onStitchedLayer: action.value
            });
            return returnableState;
        default :
            returnableState = state
            return returnableState;
    }
}

export const updateCollapseMenuinDB = (collapse_menu, update_zoom_level_flag=false) => {
    if (cookie.loadAll().username != 'uscap') {
		let url = `/api/morpheus/settings/` + JSON.parse(localStorage.getItem('morpheus_setting')).id + `/`;
        axios.put(url, {
            collapse_menu
        }, {headers: {Authorization : AuthHeader()}})
            .then(response => {
                if(response.status === 200 || response.status === 301 || response.status === 302) {
                    if(update_zoom_level_flag) {
                        window.location.reload();
                    }
                }
                else if(response.status === 403 || response.status === 401 || response.status === 400){
                    console.log("Collapse Menu Save Failed", response);
                }
            })
            .catch(err =>{
                console.log("Collapse Menu Save Failed, ", err);
            });
    }
}

export const updateAppsInstalledinDB = (apps_installed, update_zoom_level_flag = false) => {
    if (cookie.loadAll().username != 'uscap') {
		let url = `/api/morpheus/settings/` + JSON.parse(localStorage.getItem('morpheus_setting')).id + `/`;
        axios.put(url, {
            apps_installed
        }, {headers: {Authorization : AuthHeader()}})
            .then(response => {
                if(response.status === 200 || response.status === 301 || response.status === 302) {
                    if(update_zoom_level_flag) {
                        window.location.reload();
                    }
                }
                else if(response.status === 403 || response.status === 401 || response.status === 400){
                    console.log("Apps Installed Save Failed", response);
                }
            })
            .catch(err =>{
                console.log("Apps Instaled Save Failed, ", err);
            });
    }
}


export const updateAppSettingsInDb = (app_state, update_zoom_level_flag = false) => {
    if (cookie.loadAll().username !== 'uscap') {
		let url = `/api/morpheus/settings/` + JSON.parse(localStorage.getItem('morpheus_setting')).id + `/`;
        axios.put(url, {
            app_state
        }, {headers: {Authorization : AuthHeader()}})
            .then(response => {
                if(response.status === 200 || response.status === 301 || response.status === 302) {
                    if(update_zoom_level_flag) {
                        window.location.reload();
                    }
                }
                else if(response.status === 403 || response.status === 401 || response.status === 400){
                    console.log("Position Update Save Failed", response);
                }
            })
            .catch(err =>{
                console.log("Position Update Save Failed, ", err);
            });
    }
}

export const urlEncoder = (partsOfUrl) => {
    let encodedUrl = '?';
    if (partsOfUrl === undefined) {
        return encodedUrl;
    }
    if ('x' in partsOfUrl) {
        encodedUrl += '&x=' + partsOfUrl.x;
    }
    if ('y' in partsOfUrl) {
        encodedUrl += '&y=' + partsOfUrl.y;
    }
    if ('z' in partsOfUrl) {
        encodedUrl += '&z=' + partsOfUrl.z;
    }
    if ('r' in partsOfUrl) {
        encodedUrl += '&r=' + partsOfUrl.r;
    }
    if ('digitalZoomStatus' in partsOfUrl) {
        encodedUrl += partsOfUrl.digitalZoomStatus ? '&digitalZoomStatus=true' : '';
    }
    if ('presentCode' in partsOfUrl) {
        encodedUrl += partsOfUrl.presentCode ? '&presentCode=' + partsOfUrl.presentCode : '';
    }
    return encodedUrl;
}
