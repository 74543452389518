import React, {Component} from "react";
import {DownOutlined} from '@ant-design/icons';
import {Col, Dropdown, Menu, Row} from "antd";
import {connect} from "react-redux";
import {logout} from "../action/login.action";
import {historyMP} from "../helper/history";
import SearchBar from "./dashboard/searchbar";
import {MorphleLogo} from "../utils/const";
import cookie from 'react-cookies';
import {Link} from 'react-router-dom';


class HeaderComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
      suggestions: []
    }
  }

  logout = () => {
    this.props.dispatch(logout());
  };

  render() {

   let admin_menu = cookie.loadAll().is_staff === "true" ? 
      [
      <Menu.Item key={0}>
        <Link target="_blank" to='/admin'>
          <p>Admin Mode</p>
        </Link>
      </Menu.Item>,
      ]
     : undefined;
    
    var searchBar = null;
    if (this.props.showSearchBar) {
      searchBar = <SearchBar />
    }

    return (
      <Row className="header-mp">
        <Col span={6} className="header-comp">
          <Link to='/login'>
              <img className="logo-mp" src={MorphleLogo} alt="logo"/>
          </Link>
        </Col>
        <Col span={8} className="header-comp">
          {searchBar}
        </Col>
        <Col span={6} className="header-comp" style={{ cursor: "pointer" }}>
          <Dropdown
            className="user-menu-drop-down"
            overlay={
              <Menu>
                {admin_menu}
                <Menu.Item key={2} onClick={() => historyMP.push("/categories/")}>
                  <p>Cases</p>
                </Menu.Item>
                <Menu.Item key={1} onClick={this.logout}>
                  <p>Logout</p>
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <Row>
              <Col span={12} style={{textAlign:"right"}}>
                <b>{cookie.loadAll().first_name}</b>
              </Col>
              <Col span={12} style={{textAlign:"left"}}>
                <DownOutlined />
              </Col>
            </Row>  
          </Dropdown>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.searchReducer.isFetching,
    loginStatus: state.loginAlerts.loginStatus,
    slideData: state.slidesReducer.slideData,
    username: state.loginAlerts.username,
    is_staff: state.loginAlerts.is_staff
  };
};

export default connect(mapStateToProps)(HeaderComp);
