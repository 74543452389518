import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import { AuthHeader } from "../../../helper/auth.token";
import { ReloadOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip, Button, message, Spin, Select } from 'antd';
import { trackTransforms } from '../../../utils/panzoomcanvas';
import { updateSlotCropPositionValue } from '../../../action/preview.tuning.action';
import ResizableRect from 'react-resizable-rotatable-draggable';
import { updateSelectedSlot } from '../../../action/preview.tuning.action';
import { ongoingMotionPreviewAction } from '../../../action/admin.state.action';
import { slotCorners } from '../../../utils/const';

import "../../../asset/style/manualmode/slots_marking_app.css";

import querystring from "query-string";
const { Option } = Select;

class SlotsMarkingApp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            initError: false,
            errorMessage: '',
            deviceIP: '',
            width: 139,
            height: 410,
            top: 100,
            left: 100,
            rotateAngle: 37,
            slotID: 0,
        }
        this.drag = false;
        this.rect = {};

        this.canvasRefSmall = React.createRef();

        this.smallImage = new Image();

        this.smallImage.onload = this.onLoadRunnerSmallImage;

        this.onLoadRunnerSmallImage = this.onLoadRunnerSmallImage.bind(this);

    }

    componentDidMount = () => {
        this.getSmallImage(0);
    }

    getSmallImage = (i) => {
        let timestamp = Date.now();
        this.setState({
            slotID: i,
        });
        this.props.dispatch(updateSelectedSlot(i));
        this.smallImage.src = "/diagnostics/preview/latest/" + (i * 2) + ".jpg?timestamp=" + timestamp;
    }

    onLoadRunnerSmallImage = () => {
        let canvas = this.canvasRefSmall.current;
        let ctx = canvas.getContext('2d');
        trackTransforms(ctx);
        let p1 = ctx.transformedPoint(0, 0);
        let p2 = ctx.transformedPoint(canvas.width, canvas.height);
        ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);

        ctx.save();
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.restore();

        ctx.drawImage(this.smallImage, 0, 0, this.smallImage.width * (600 / 1944), this.smallImage.height * (600 / 1944));
    }

    handleResize = (style, isShiftKey, type) => {
        // type is a string and it shows which resize-handler you clicked
        // e.g. if you clicked top-right handler, then type is 'tr'
        let { top, left, width, height } = style
        top = Math.round(top)
        left = Math.round(left)
        width = Math.round(width)
        height = Math.round(height)
        this.setState({
            top,
            left,
            width,
            height
        })
    }

    handleRotate = (rotateAngle) => {
        this.setState({
            rotateAngle
        })
    }

    handleDrag = (deltaX, deltaY) => {
        this.setState({
            left: this.state.left + deltaX,
            top: this.state.top + deltaY
        })
    }

    saveCoordinates = () => {
        let { width, top, left, height, rotateAngle } = this.state;
        let scale = 1944 / 600;
        let angle = rotateAngle * (Math.PI / 180);

        let x = left;
        let y = top;

        let tempX = -1 * (width / 2);
        let tempY = -1 * (height / 2);
        let rotatedX = (tempX * Math.cos(angle)) - (tempY * Math.sin(angle));
        let rotatedY = (tempX * Math.sin(angle)) + (tempY * Math.cos(angle));
        left = rotatedX + (x + (width / 2));
        top = rotatedY + (y + (height / 2));

        let points = {};
        let pointsArray = [];
            
        points[slotCorners.TL] = [Math.floor(left * scale), Math.floor(top * scale)];
        points[slotCorners.TR] = [Math.floor((left + (width * Math.cos(angle))) * scale), Math.floor((top + (width * Math.sin(angle))) * scale)];
        points[slotCorners.BL] = [Math.floor((left - (height * Math.sin(angle))) * scale), Math.floor((top + (height * Math.cos(angle))) * scale)];
        points[slotCorners.BR] = [Math.floor((left + (width * Math.cos(angle)) - (height * Math.sin(angle))) * scale), 
            Math.floor((top + (width * Math.sin(angle)) + (height * Math.cos(angle))) * scale)];
        
        pointsArray.push(points[slotCorners.TL]);
        pointsArray.push(points[slotCorners.TR]);
        pointsArray.push(points[slotCorners.BR]);
        pointsArray.push(points[slotCorners.BL]);

        this.props.dispatch(updateSlotCropPositionValue(points));
        this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, true));

        let value = {
            'points': JSON.stringify(pointsArray),
            'width': width,
            'height': height,
            'angle': rotateAngle,
            'slot_id': this.state.slotID,
        }

        let url = "/server/devices/" + this.props.currentState.deviceId + "/settings/save_cropped_slot_image/";
        axios.post(url, querystring.stringify(value), { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
                this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, false));
                message.success("Successfully saved mapping", 2.5);
            }
        })
        .catch(err => {
            console.log(err);
            this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, false));
            message.error("Not able to calculate mapping", 2.5);
        });
    }

    changeSlot = (value) => {
        this.props.dispatch(updateSelectedSlot(value));
    }

    render() {

        const { width, top, left, height, rotateAngle } = this.state;

        let slotsButton = [];


        for(let i = 0; i < this.props.adminState.numberOfSlots; i++) {
            slotsButton.push(
                <Col span={3} key={i}>
                    <Button type={this.state.slotID == i ? "danger" : "primary"} size="small" onClick={() => this.getSmallImage(i)}>
                        {i}
                    </Button>
                </Col>
            )
        }

        return (
            <Row className="app-parent overlayed-component slots-marking-app-width">
                <br />
                <div>
                    <Spin spinning={this.props.adminState.preview_app_action || this.props.adminState.motion_app_action}>
                        <Row>
                            <Row>
                                <Col offset={1} span={3}>
                                    <div style={{marginTop: 2}}>
                                        <b>Select Slot: </b>
                                    </div>
                                </Col>
                                {slotsButton}
                                <Col span={2}>
                                    <Tooltip title="Reload Image" placement="rightTop">
                                        <Button type={"primary"} size="small" onClick={() => this.getSmallImage(this.state.slotID)}>
                                            <ReloadOutlined />
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col offset={1}>
                                    <canvas ref={this.canvasRefSmall} id="preview-cassette-canvas-small" width="800" height="600"></canvas>
                                    <ResizableRect
                                        left={left}
                                        top={top}
                                        width={width}
                                        height={height}
                                        rotateAngle={rotateAngle}
                                        zoomable='n, w, s, e, nw, ne, se, sw'
                                        onRotate={this.handleRotate}
                                        onResize={this.handleResize}
                                        onDrag={this.handleDrag}
                                    />
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col offset={10} span={6}>
                                    <Button type="primary" onClick={this.saveCoordinates}>
                                        Save Coordinates
                                    </Button>
                                </Col>
                            </Row>
                        </Row>
                    </Spin>
                </div>
                <br />
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        adminState: state.adminUrlReducer,
        previewTuning: state.previewTuningReducer
    }
}

export default connect(mapStateToProps)(SlotsMarkingApp);
