import { BloodGridPLTActions } from "../actionTypes/bloodGridPLT.const";

const InitialState = {
  size_range_low:-1,
  size_range_high:-1,
  extreme_size_range_low:-1,
  extreme_size_range_high:-1,
  page: '',
  update_image: false,
  selected_grid:'',
  page_size:'',
  all_grids:[],
  zStack: 0,
  total_pages:0,
  total_count:0,
  new_selected_on_next_page:false,
  curr_grids:[],
  selected_grid_overall_id: '',
  fetching_grid: false,
}

export const bloodGridPLTReducer = ( state = InitialState , action) => {
  switch(action.type){
        case BloodGridPLTActions.PLT_UPDATE_SIZE_RANGE:
            if(action.response == undefined){
                return Object.assign({}, state, {InitialState});
            }
            return Object.assign({}, state, {
                size_range_low:action.response[0],
                size_range_high: action.response[1],
            })
        case BloodGridPLTActions.PLT_UPDATE_EXTREME_SIZE_RANGE:
            if(action.response == undefined){
                return Object.assign({}, state, {});
            }
            return Object.assign({}, state, {
                extreme_size_range_low:action.response[0],
                extreme_size_range_high: action.response[1],
            })
        case BloodGridPLTActions.PLT_GRID_SELECTED:
            return Object.assign({}, state, {
                selected_grid:action.response,
            })
        case BloodGridPLTActions.UPDATE_PAGE:
            return Object.assign({}, state, {
                page:action.response,
            })
        case BloodGridPLTActions.UPDATE_FETCHING_GRID:
            return Object.assign({}, state, {
                fetching_grid: action.response,
            })
        case BloodGridPLTActions.UPDATE_PAGE_SIZE:
            return Object.assign({}, state, {
                page_size: action.response,
            })
        case BloodGridPLTActions.UPDATE_TOTAL_PAGES:
            return Object.assign({}, state, {
                total_pages: action.response,
            })
        case BloodGridPLTActions.UPDATE_TOTAL_COUNT:
            return Object.assign({}, state, {
                total_count: action.response,
            })
        case BloodGridPLTActions.UPDATE_CURR_GRIDS:
            console.log("loadnext-curr", action.response)
            return Object.assign({}, state, {
                curr_grids: action.response,
            })
        case BloodGridPLTActions.SELECTED_GRID_OVERALL_ID:
            return Object.assign({}, state, {
                selected_grid_overall_id: action.response,
            })
        case BloodGridPLTActions.PLT_CHANGE_IMAGE:
            return Object.assign({}, state, {
                update_image: action.response,
            })
        default :
            return state
  }
}