import React, { Component } from 'react';
import cookie from "react-cookies";
import { message } from 'antd';
import { connect } from "react-redux";
import axios from 'axios';
import { AuthHeader } from '../../helper/auth.token';
import { liveModeLoadingMessages, previewCameraType } from '../../utils/const';
import { updateImageFetching, getLiveImageMoveAndFocus, updateAccessRevoked, updateAccessToLiveMode, updateCurrentMapPosition, getNearestTile, updateLastBound } from '../../action/livemode.action';

class KeyboardMovementListener extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        document.addEventListener('keyup', this.keyboardEventAction);
    }

    componentWillUnmount = () => {
        document.removeEventListener('keyup', this.keyboardEventAction);
    }

    keyboardEventAction = (e) => {
        console.log("keyboard_mov,this.props.adminState.live_mode_actionement-1",this.props.livemode.inputFieldInFocus, this.props.livemode.accessToLiveMode, this.props.livemode.useLiveModeInViewMode, this.props.adminState.live_mode_action)

        if (!this.props.livemode.inputFieldInFocus && this.props.livemode.accessToLiveMode && !this.props.livemode.useLiveModeInViewMode) {// && !this.props.adminState.live_mode_action

            if (this.props.adminState.slotID >= 0) {
                console.log("keyboard_movement-2")

                if (this.props.adminState.main_light) {
					console.log("keyboard_movement-3")

					if (!this.props.adminState.doDropDistanceAF && (this.props.liveView.url != undefined && this.props.livemode.atLeastOneImageFetched)) {
						if (e.code == 'ArrowRight') {
							if (this.props.adminState.previewCamera == previewCameraType.FOUR_X) {
								if (this.props.adminState.numberOfSlots > 1) {
									this.moveAndFocus(null, (this.props.livemode.movementStepSize * -1000), null);
									// this.moveAndFocus(null, (this.props.livemode.movementStepSize * -1000), null);
								} else {
									this.moveAndFocus(null, (this.props.livemode.movementStepSize * -1000), null);
								}
							} else {
								this.moveAndFocus(null, (this.props.livemode.movementStepSize * -1000), null);
							}
						} else if (e.code == 'ArrowDown') {
							if (this.props.adminState.previewCamera == previewCameraType.FOUR_X) {
								if (this.props.adminState.numberOfSlots > 1) {
									this.moveAndFocus((this.props.livemode.movementStepSize * -1000), null, null);
									// this.moveAndFocus((this.props.livemode.movementStepSize * -1000), null, null);
								} else {
									this.moveAndFocus((this.props.livemode.movementStepSize * -1000), null, null);
								}
							} else {
								this.moveAndFocus((this.props.livemode.movementStepSize * -1000), null, null);
							}
						} else if (e.code == 'ArrowLeft') {
							if (this.props.adminState.previewCamera == previewCameraType.FOUR_X) {
								if (this.props.adminState.numberOfSlots > 1) {
									this.moveAndFocus(null, (this.props.livemode.movementStepSize * 1000), null);
									// this.moveAndFocus(null, (this.props.livemode.movementStepSize * 1000), null);
								} else {
									this.moveAndFocus(null, (this.props.livemode.movementStepSize * 1000), null);
								}
							} else {
								this.moveAndFocus(null, (this.props.livemode.movementStepSize * 1000), null);
							}
						} else if (e.code == 'ArrowUp') {
							if (this.props.adminState.previewCamera == previewCameraType.FOUR_X) {
								if (this.props.adminState.numberOfSlots > 1) {
									this.moveAndFocus((this.props.livemode.movementStepSize * 1000), null, null);
									// this.moveAndFocus((this.props.livemode.movementStepSize *1000), null, null);
								} else {
									this.moveAndFocus((this.props.livemode.movementStepSize * 1000), null, null);
								}
							} else {
								this.moveAndFocus((this.props.livemode.movementStepSize * 1000), null, null);
							}
                        }
                    } else {
                        if (e.code == 'ArrowUp' || e.code == 'ArrowLeft' || e.code == 'ArrowDown' || e.code == 'ArrowRight') {
                            message.error("Please go to some position by clicking on the preview image first.", 2.5);
                        }
                    }
                } else {
                    if (e.code == 'ArrowUp' || e.code == 'ArrowLeft' || e.code == 'ArrowDown' || e.code == 'ArrowRight') {
                        message.error("Please switch on the main light.", 2.5);
                    }
                }
            } else {
                if (e.code == 'ArrowUp' || e.code == 'ArrowLeft' || e.code == 'ArrowDown' || e.code == 'ArrowRight') {
                    message.error("Please take the preview first.", 2.5);
                }
            }
        }
    }

    moveAndFocus = (x, y, z) => {
        this.props.dispatch(updateImageFetching(true));


        let partsOfUrl = "api~stage~move_and_focus";
        let url = `/server/scano_text/` + this.props.livemode.deviceId + `/` + partsOfUrl;

        if (this.props.adminState.slotID != -1) {
            url += '?slotID=' + parseInt(this.props.adminState.slotID);
        } else {
            url += '?slotID=';
        }

        if (x != null) {
            url += '&x=' + parseFloat(x);
        } else {
            url += '&x=';
        }

        if (y != null) {
            url += '&y=' + parseFloat(y);
        } else {
            url += '&y=';
        }

        if (z != null) {
            url += '&z=' + parseFloat(z);
        } else {
            url += '&z=';
        }

        // this.props.adminState.doAutoFocus
		url += "&doAF=" + this.props.adminState.doAutoFocus + "&numZStack=" + parseInt(this.props.livemode.zStackLevels * 2) +
			"&zStackStep=" + parseFloat(this.props.livemode.stepSizeZStack) +
			"&timestamp=" + Date.now();
		this.props.dispatch(getLiveImageMoveAndFocus(url, liveModeLoadingMessages.LOADING_IMAGE, this.props.adminState.slotID,
			this.props.adminState.takeZStack, true, this.props.adminState.areaVisited,
			this.props.adminState.liveModePreviewVectorLayer));


        // let partsOfUrl = "api~stage~get_image_pos_from_relative_stage_mov";
        // let url = `/server/scano/` + this.props.livemode.deviceId + `/` + partsOfUrl;

        // if (this.props.adminState.slotID != -1) {
        //     url += '?slotID=' + this.props.adminState.slotID;
        // } else {
        //     url += '?slotID=';
        // }

        // if (x != null) {
        //     url += '&x=' + x;
        // } else {
        //     url += '&x=';
        // }

        // if (y != null) {
        //     url += '&y=' + y;
        // } else {
        //     url += '&y=';
        // }

        // url += `&accessCode=` + cookie.loadAll().livemode_access_code;

        // let errorMessage = '';

        // if (this.props.adminState.doDropDistanceAF) {
        //     errorMessage = liveModeLoadingMessages.DROP_DISTANCE_AF;
        // } else if (this.props.adminState.doAutoFocus) {
        //     errorMessage = liveModeLoadingMessages.AUTO_FOCUS;
        // } else {
        //     errorMessage = liveModeLoadingMessages.MOVE;
        // }

        // let coordinate = [];

        // if (this.props.adminState.numberOfSlots > 1) {
        //     coordinate = [this.props.livemode.currentMapPosition[this.props.adminState.slotID].x - x, this.props.livemode.currentMapPosition[this.props.adminState.slotID].y + y];
        // } else {
        //     coordinate = [this.props.livemode.currentMapPosition[this.props.adminState.slotID].x + x, this.props.livemode.currentMapPosition[this.props.adminState.slotID].y - y];
        // }

        // let nearestTile = getNearestTile(coordinate[0], coordinate[1], this.props.livemode.tileCenters[this.props.adminState.slotID]);
        // let xDistance;
        // let yDistance;
        // if (this.props.adminState.numberOfSlots > 1) {
        //     xDistance = ((this.props.livemode.slideData.x_fields - 1) - parseInt(nearestTile.split('_')[0])) * this.props.livemode.slideData.x_step;
        //     yDistance = ((this.props.livemode.slideData.y_fields - 1) - parseInt(nearestTile.split('_')[1])) * this.props.livemode.slideData.y_step;
        // } else {
        //     xDistance = parseInt(nearestTile.split('_')[0]) * this.props.livemode.slideData.x_step;
        //     yDistance = parseInt(nearestTile.split('_')[1]) * this.props.livemode.slideData.y_step;
        // }

        // let nearestTileCenter = this.props.livemode.tileCenters[this.props.adminState.slotID][nearestTile];
        // let nearestTileY = parseInt(nearestTile.split('_')[1]);

        // url += `&nearestTileY=` + nearestTileY;
        // if(isNaN(nearestTileY)) {
        //     message.error("Please go to some position by clicking on the preview image first.", 2.5);
        //     this.props.dispatch(updateImageFetching(false));
        // } else {
        //     axios.get(url, { headers: { Authorization: AuthHeader() } })
        //         .then(response => {
        //             if (true) {
        //                 if (this.props.adminState.slotID >= 0) {

        //                     let data = response.data.pixelVal;
        //                     // console.log(x)
        //                     // console.log(y)
        //                     // console.log(data)
        //                     let newCurrentMapPosition = Object.assign({}, this.props.livemode.currentMapPosition);
        //                     newCurrentMapPosition[this.props.adminState.slotID] = {x: Math.trunc(coordinate[0]), y: Math.trunc(coordinate[1])};

        //                     let partsOfUrl = "api~stage~get_selected_area_coordinates";
        //                     let url = `/server/scano/` + this.props.livemode.deviceId + `/` + partsOfUrl + "?slot_id=" +
        //                         this.props.adminState.slotID + "&x=" + data.x + "&y=" + data.y + "&nearestTileY=" + nearestTileY;

        //                     axios.get(url, { headers: { Authorization: AuthHeader() } })
        //                         .then(res => {
        //                             let bounds = [];
        //                             let checkPoint1 = [], checkPoint2 = [], checkPoint3 = [], checkPoint4 = [], checkPoint5 = [];
        //                             console.log("preview--", this.props.adminState.previewCamera)
        //                             if (this.props.adminState.previewCamera == previewCameraType.FOUR_X) {
        //                                 if (this.props.adminState.numberOfSlots > 1) {
        //                                     // bounds = [
        //                                     //     [res.data["point 0"][0], this.props.adminState.previewImageExtent[3] - res.data["point 0"][1]],
        //                                     //     [res.data["point 0"][0], this.props.adminState.previewImageExtent[3] - res.data["point 1"][1]],
        //                                     //     [res.data["point 2"][0], this.props.adminState.previewImageExtent[3] - res.data["point 1"][1]],
        //                                     //     [res.data["point 2"][0], this.props.adminState.previewImageExtent[3] - res.data["point 0"][1]],
        //                                     //     [res.data["point 0"][0], this.props.adminState.previewImageExtent[3] - res.data["point 0"][1]],
        //                                     // ];
        //                                     // bounds = [
        //                                     //     [res.data["point 0"][0], this.props.adminState.previewImageExtent[3] - res.data["point 0"][1]],
        //                                     //     [res.data["point 0"][0], this.props.adminState.previewImageExtent[3] - res.data["point 2"][1]],
        //                                     //     [res.data["point 1"][0], this.props.adminState.previewImageExtent[3] - res.data["point 2"][1]],
        //                                     //     [res.data["point 1"][0], this.props.adminState.previewImageExtent[3] - res.data["point 0"][1]],
        //                                     //     [res.data["point 0"][0], this.props.adminState.previewImageExtent[3] - res.data["point 0"][1]],
        //                                     // ];
        //                                     bounds = [
        //                                         [xDistance + nearestTileCenter[0] - res.data["point 0"][0], res.data["point 0"][1] + nearestTileCenter[1] - yDistance],
        //                                         [xDistance + nearestTileCenter[0] - res.data["point 0"][0], res.data["point 2"][1] + nearestTileCenter[1] - yDistance],
        //                                         [xDistance + nearestTileCenter[0] - res.data["point 1"][0], res.data["point 2"][1] + nearestTileCenter[1] - yDistance],
        //                                         [xDistance + nearestTileCenter[0] - res.data["point 1"][0], res.data["point 0"][1] + nearestTileCenter[1] - yDistance],
        //                                         [xDistance + nearestTileCenter[0] - res.data["point 0"][0], res.data["point 0"][1] + nearestTileCenter[1] - yDistance],
        //                                     ];

        //                                     checkPoint1 = [xDistance + nearestTileCenter[0] - res.data["center"][0], res.data["center"][1] + nearestTileCenter[1] - yDistance];
        //                                     checkPoint2 = [xDistance + nearestTileCenter[0] - res.data["point 0"][0], res.data["point 0"][1] + nearestTileCenter[1] - yDistance];
        //                                     checkPoint3 = [xDistance + nearestTileCenter[0] - res.data["point 1"][0], res.data["point 1"][1] + nearestTileCenter[1] - yDistance];
        //                                     checkPoint4 = [xDistance + nearestTileCenter[0] - res.data["point 2"][0], res.data["point 2"][1] + nearestTileCenter[1] - yDistance];
        //                                     checkPoint5 = [xDistance + nearestTileCenter[0] - res.data["point 3"][0], res.data["point 3"][1] + nearestTileCenter[1] - yDistance];

        //                                 } else {
        //                                     // bounds = [
        //                                     //     [res.data["point 0"][0], this.props.adminState.previewImageExtent[3] - res.data["point 0"][1]],
        //                                     //     [res.data["point 0"][0], this.props.adminState.previewImageExtent[3] - res.data["point 2"][1]],
        //                                     //     [res.data["point 1"][0], this.props.adminState.previewImageExtent[3] - res.data["point 2"][1]],
        //                                     //     [res.data["point 1"][0], this.props.adminState.previewImageExtent[3] - res.data["point 0"][1]],
        //                                     //     [res.data["point 0"][0], this.props.adminState.previewImageExtent[3] - res.data["point 0"][1]],
        //                                     // ];
        //                                     bounds = [
        //                                         [res.data["point 0"][0] - xDistance + nearestTileCenter[0], yDistance + nearestTileCenter[1] - res.data["point 0"][1]],
        //                                         [res.data["point 0"][0] - xDistance + nearestTileCenter[0], yDistance + nearestTileCenter[1] - res.data["point 2"][1]],
        //                                         [res.data["point 1"][0] - xDistance + nearestTileCenter[0], yDistance + nearestTileCenter[1] - res.data["point 2"][1]],
        //                                         [res.data["point 1"][0] - xDistance + nearestTileCenter[0], yDistance + nearestTileCenter[1] - res.data["point 0"][1]],
        //                                         [res.data["point 0"][0] - xDistance + nearestTileCenter[0], yDistance + nearestTileCenter[1] - res.data["point 0"][1]],
        //                                     ];

        //                                     checkPoint1 = [res.data["center"][0] - xDistance + nearestTileCenter[0], yDistance + nearestTileCenter[1] - res.data["center"][1]];
        //                                     checkPoint2 = [res.data["point 0"][0] - xDistance + nearestTileCenter[0], yDistance + nearestTileCenter[1] - res.data["point 0"][1]];
        //                                     checkPoint3 = [res.data["point 1"][0] - xDistance + nearestTileCenter[0], yDistance + nearestTileCenter[1] - res.data["point 1"][1]];
        //                                     checkPoint4 = [res.data["point 2"][0] - xDistance + nearestTileCenter[0], yDistance + nearestTileCenter[1] - res.data["point 2"][1]];
        //                                     checkPoint5 = [res.data["point 3"][0] - xDistance + nearestTileCenter[0], yDistance + nearestTileCenter[1] - res.data["point 3"][1]];
        //                                 }
        //                             } else {
        //                                 bounds = [
        //                                     [res.data["point 0"][0], this.props.adminState.previewImageExtent[3] - res.data["point 0"][1]],
        //                                     [res.data["point 0"][0], this.props.adminState.previewImageExtent[3] - res.data["point 2"][1]],
        //                                     [res.data["point 1"][0], this.props.adminState.previewImageExtent[3] - res.data["point 2"][1]],
        //                                     [res.data["point 1"][0], this.props.adminState.previewImageExtent[3] - res.data["point 0"][1]],
        //                                     [res.data["point 0"][0], this.props.adminState.previewImageExtent[3] - res.data["point 0"][1]],
        //                                 ];

        //                                 checkPoint1 = [res.data["center"][0], this.props.adminState.previewImageExtent[3] - res.data["center"][1]];
        //                                 checkPoint2 = [res.data["point 0"][0], this.props.adminState.previewImageExtent[3] - res.data["point 0"][1]];
        //                                 checkPoint3 = [res.data["point 1"][0], this.props.adminState.previewImageExtent[3] - res.data["point 1"][1]];
        //                                 checkPoint4 = [res.data["point 2"][0], this.props.adminState.previewImageExtent[3] - res.data["point 2"][1]];
        //                                 checkPoint5 = [res.data["point 3"][0], this.props.adminState.previewImageExtent[3] - res.data["point 3"][1]];
        //                             }
        //                             if (this.checkPointLimit(checkPoint1[0], checkPoint1[1]) &&
        //                                 this.checkPointLimit(checkPoint2[0], checkPoint2[1]) &&
        //                                 this.checkPointLimit(checkPoint3[0], checkPoint3[1]) &&
        //                                 this.checkPointLimit(checkPoint4[0], checkPoint4[1]) &&
        //                                 this.checkPointLimit(checkPoint5[0], checkPoint5[1])) {
        //                                 // this.props.dispatch(markArea(bounds, this.props.adminState.areaVisited, this.props.adminState.liveModePreviewVectorLayer));
        //                                 let newLastBound = Object.assign({}, this.props.livemode.lastBound);
        //                                 newLastBound[this.props.adminState.slotID] = bounds;
        //                                 this.props.dispatch(updateLastBound(newLastBound));
        //                                 partsOfUrl = "api~stage~move_and_focus";
        //                                 url = `/server/scano_text/` + this.props.livemode.deviceId + `/` + partsOfUrl;

        //                                 if (this.props.adminState.slotID != -1) {
        //                                     url += '?slotID=' + this.props.adminState.slotID;
        //                                 } else {
        //                                     url += '?slotID=';
        //                                 }

        //                                 if (x != null) {
        //                                     url += '&x=' + x;
        //                                 } else {
        //                                     url += '&x=';
        //                                 }

        //                                 if (y != null) {
        //                                     url += '&y=' + y;
        //                                 } else {
        //                                     url += '&y=';
        //                                 }

        //                                 if (z != null) {
        //                                     url += '&z=' + z;
        //                                 } else {
        //                                     url += '&z=';
        //                                 }

        //                                 url += "&doAF=" + this.props.adminState.doAutoFocus + "&numZStack=" + (this.props.livemode.zStackLevels * 2) + 
        //                                     "&zStackStep=" + this.props.livemode.stepSizeZStack  +
        //                                     "&timestamp=" + Date.now();
        //                                 console.log("url--", url--)
        //                                 this.props.dispatch(getLiveImageMoveAndFocus(url, errorMessage, this.props.adminState.slotID, 
        //                                     this.props.adminState.takeZStack, bounds, true, this.props.adminState.areaVisited, 
        //                                     this.props.adminState.liveModePreviewVectorLayer, newCurrentMapPosition));
        //                             } else {
        //                                 message.error("Soft limits Hit!!", 2.5);
        //                                 this.props.dispatch(updateImageFetching(false));
        //                             }
        //                         })
        //                         .catch(err => {
        //                             console.log(err);
        //                             this.props.dispatch(updateImageFetching(false));
        //                         });
        //                 }
        //             } else {
        //                 console.log(response);
        //                 // message.error("Access Revoked!!", 2.5);
        //                 this.props.dispatch(updateAccessRevoked(true));
        //                 this.props.dispatch(updateAccessToLiveMode(false));
        //                 this.props.dispatch(updateImageFetching(false));
        //             }
        //         })
        //         .catch(err => {
        //             console.log(err);
        //             message.error("Soft Limits Hit!!", 2.5);
        //             this.props.dispatch(updateImageFetching(false));
        //         })
        }
    

    checkPointLimit = (x, y) => {
        // if (x < this.props.adminState.previewImageExtent[0] ||
        //     x > this.props.adminState.previewImageExtent[2] ||
        //     y < this.props.adminState.previewImageExtent[1] ||
        //     y > this.props.adminState.previewImageExtent[3]) {
        //     return false;
        // } else {
            return true;
        // }
    }

    render() {

        return (
            <div></div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        liveView: state.liveViewReducer,
        adminState: state.adminUrlReducer,
        livemode: state.livemodeReducer,
    };
};

export default connect(mapStateToProps)(KeyboardMovementListener);
