export const BloodGridActions = {
    BLOOD_CLASS_CHANGED: "BLOOD_CLASS_CHANGED",
    BLOOD_TAG_CHANGED: "BLOOD_TAG_CHANGED",
    BLOOD_FILTER_DEFAULT: "BLOOD_FILTER_DEFAULT",
    BLOOD_ANNO_SELECTED:"BLOOD_ANNO_SELECTED",
    BLOOD_ANNO_MOVE:"BLOOD_ANNO_MOVE",
    BLOOD_ANNO_STOP_MOVE: "BLOOD_ANNO_STOP_MOVE",
    BLOOD_ANNO_UPDATE_SUMMARY: "BLOOD_ANNO_UPDATE_SUMMARY",
    UPDATE_WBC_PAGE_SIZE: "UPDATE_WBC_PAGE_SIZE",
    UPDATE_PAGE: "UPDATE_PAGE",
    GRID_SELECTED: "GRID_SELECTED", 
} 