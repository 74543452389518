import React,{Component} from 'react';
import {Row , Col } from 'antd';
import PreviewComp from '../component/viewer/preview';
import PublicMap from '../component/viewer/viewerComp';
import Chat from '../component/viewer/chat';
import axios from "axios";
import { AuthHeader } from "../helper/auth.token";
import { message } from "antd";
import SlideInfoComp from "../component/viewer/slides.info";
import { connect } from "react-redux";
import cookie from 'react-cookies';
import { Projection } from 'ol/proj.js'
import { View } from "ol";
import { Collapse } from 'antd';
import { List } from 'antd';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import LineString from 'ol/geom/LineString';
import Circle from 'ol/geom/Circle';
import { Fill, Stroke, Style, Text as OlText } from 'ol/style.js';
import { Vector as VectorSource } from 'ol/source.js';
import { updateMapParams } from '../action/morpheus.state.action'
import WebSocketInstance from '../services/WebSocket'
import {isBloodViewer} from "../utils/utils";

const { Panel } = Collapse;

class ViewerContent extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            id: -1,
            view: {},
            projection: {},
            url: null,
            resolutions: [],
            imageSize: [],
            slide_data: {},
            annoList: [],
        }

        this.source = new VectorSource({ wrapX: false });
        WebSocketInstance.connect();

    }
    
    initialiseSlideMap = (slide_data, index) => {

        let imgWidth, imgHeight;

        if (isBloodViewer(slide_data)) {
          imgWidth = slide_data.uperpixel * slide_data.tile_width * slide_data.x_fields;
          imgHeight = slide_data.uperpixel * slide_data.tile_height * slide_data.y_fields;
        } else {
          imgWidth = slide_data.uperpixel * slide_data.stitched_tile_width * slide_data.stitched_x_max;
          imgHeight = slide_data.uperpixel * slide_data.stitched_tile_height * slide_data.stitched_y_max;
        }

        let resolutions = [];
        let z_levels;
        if (isBloodViewer(slide_data)) {
          z_levels = slide_data.tiling_z_levels.split(",");
        } else {
          z_levels = slide_data.z_levels.split(",");
        }
        (z_levels).forEach((level, index) => {
          resolutions.push(slide_data.uperpixel * Math.pow(2, parseInt(level)));
        });
        resolutions = resolutions.reverse();

        let getPointResolution = (resolution, point) => {
          var pointResolution = resolution;
          return pointResolution;
        };

        let center =  [imgWidth / 2, imgHeight / 2];

        let proj = new Projection({
          code: 'MORPHLE',
          units: 'microns',
          extent: [0, 0, imgWidth, imgHeight],
          metersPerUnit: 0.000001,
          global: true,
          getPointResolution: getPointResolution,
        });

        let view = new View({
          projection: proj,
          extent: proj.getExtent(),
          center: this.props.urlState.x === -1 ? center : [this.props.urlState.x, this.props.urlState.y],
          zoom: this.props.urlState.z,
          maxResolution: resolutions[0],
          maxZoom: this.props.urlState.digitalZoomStatus ? (resolutions.length - 1) : (resolutions.length - 2),
          rotation: this.props.urlState.r
        });

        this.is_cloud = (cookie.loadAll().deployment_mode === 'offline')?"false":"true";

        let static_path;
        if (this.is_cloud === "true") {
          static_path="https://storage.googleapis.com/"
        } else {
          if(slide_data.status === 12) {
            static_path = "/scan-hdd/" + slide_data.scandrive_id + "/";
          }
          else {
            static_path = "/scan-ssd/";
          }
        }

        let url;
        if (isBloodViewer(slide_data)) {
          url =  static_path + `${slide_data.bucket_name}/${slide_data.path}tiled/{z}/x{x}y{y}.jpg`;
        } else {
          url =  static_path + `${slide_data.bucket_name}/${slide_data.path}stitched/{z}/x{x}y{y}.jpg`;
        }
        
        let updatePermalink = () => {
          this.props.dispatch(updateMapParams(
            this.props.urlState, 
            view.getCenter()[0], 
            view.getCenter()[1], 
            view.getZoom(), 
            view.getRotation()
          ));
        };
    
        this.setState({
          view: view,
          projection: proj,
          url: url,
          resolutions: resolutions,
          imageSize: [imgWidth, imgHeight],
          isFetching: false,
          slide_data: slide_data, 
          updatePermalink: updatePermalink
        });
    }

    getSlide = value => {
        let url = `/api/slide/${value}/`;
        axios
          .get(url, { headers: { Authorization: AuthHeader() } })
          .then(response => {
            if (response.status === 200) {
                this.initialiseSlideMap(response.data, 0);
            } else {
              console.error(response.data);
              this.setState({
                  isFetching: false
              });
            }
          })
          .catch(err => {
            message.error("Slide could not be found. Contact Admin.");
            this.setState({
                isFetching: false
            });
            console.log(err);
          });
    }

    addBounds = () => {
      let val = this.state.annoList;
      let annoColor = '#' + this.props.urlState.annoColor;
      val.map(value => {
        let feature;
        if (value.new_bounds !== null) {
          if (value.shape === "polygon") {
            feature = new Feature({
              geometry: new Polygon(JSON.parse(value.new_bounds)),
              id: value.id,
              name: value.shape,
              title: value.title,

            });
          }
          else if (value.shape === "line") {
            feature = new Feature({
              geometry: new LineString(JSON.parse(value.new_bounds)),
              id: value.id,
              name: value.shape,
              title: value.title,
            });
          }
          else if (value.shape === "circle") {
            let bounds = JSON.parse(value.new_bounds);
            let center = bounds[0];
            let radius = bounds[1];
            feature = new Feature({
              geometry: new Circle(center, radius),
              id: value.id,
              name: value.shape,
              title: value.title
            });
          }
          let style = new Style({
            stroke: new Stroke({
              color: annoColor,
              width: 3
            }),
            fill: new Fill({
              color: 'rgba(0, 0, 0, 0.0)'
            }),
            text: new OlText({
              font: 'bold 20px "Open Sans", "Arial Unicode MS", "sans-serif"',
              placement: 'line',
              textBaseline: 'top',
              fill: new Fill({
                color: annoColor
              })
            })});
          feature.setStyle(style);
          feature.setId(value.id);
          if (value.shape !== "circle") {
            feature.getStyle().getText().setText(feature.get('title'));
          }
          if (this.source.hasFeature(feature)) {
            let featureToBeDeleted  =  this.source.getFeatureById(value.id);
            this.source.removeFeature(featureToBeDeleted); 
          }
          this.source.addFeature(feature);
        }
        return feature;
      });
    }

    getAnno = () => {
      let url = `/api/annotation/?slide=${this.props.slide_id}`;
      axios
        .get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
          let data = response.data;
          this.setState({
            annoList: data
          }, () => this.addBounds());
        })
        .catch(err => {
          console.log("Failed Annotation", err);
        });
    }

    componentWillUnmount = () => {
      clearTimeout(this.timer);
    }

    componentDidMount = () => {
        this.getSlide(this.props.slide_id);
        this.getAnno();
        let timerId = setInterval(
          () => this.getAnno(),
          2000
        );
        this.timer = timerId;
    }

    goToAnnoCenter = (item) => {
      let center = item.center.split(',').map((value) => {
        return parseInt(value)
      });
      this.state.view.animate({center: center});
    }

    render() {
        let isMobile = cookie.load('isMobile') === 'true';
        let previewComp = isMobile ? <div></div> : <PreviewComp/>;
        let slideInfoComp = isMobile ? <div></div> : <SlideInfoComp slide_data={this.state.slide_data} />;
        let viewerSize = isMobile ? 22:20;
        let singleAnno = () => {
          return (
            <List
              size="small"
              bordered
              dataSource={this.state.annoList}
              renderItem={item => <List.Item key={item.id} style={{cursor: 'pointer' }}onClick={()=> this.goToAnnoCenter(item)}>{item.title}</List.Item>}
            />
        )};
        let annotationList = [
          <Collapse key="anno-list" >
            <Panel header="Annotation List" key="1">
              {singleAnno()}
            </Panel>
          </Collapse>
          
        ]
        let chatBox = [
        <Collapse key="chat-box" >
          <Panel header="Chat" key="1">
            <Chat
              slide_id={this.props.slide_id}
              currentUser={cookie.loadAll().username}
            />
          </Panel>
        </Collapse>
        
        ];
        let rightSideBar = isMobile ? undefined : 
                          <Col span={4} className="viewer-right-sidebar">
                              {previewComp}
                              {annotationList}
                              {slideInfoComp}
                          </Col>;
        return(
 
            <div>
                {this.state.isFetching === false ?
                    (
                    <Row className="viewer-parent" > 
                        <Col span={viewerSize} className="viewer">
                          <PublicMap 
                              source={this.source}
                              map_id={0}
                              url={this.state.url} 
                              slide_data={this.state.slide_data}
                              projection={this.state.projection}
                              view={this.state.view}
                              resolutions={this.state.resolutions}
                              imageSize={this.state.imageSize}
                              sync_code={this.props.query_params.sync_code}
                              sync_browsing_enabled={false}
                              updatePermalink={this.state.updatePermalink}
                              annoList={this.state.annoList}
                          />
                        </Col>
                        {rightSideBar}
                    </Row>
                    ): (
                        <h5>Loading Slide. Please wait.</h5>
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      mode : state.loginAlerts.deployment_mode, 
      urlState: state.viewerUrlReducer,
    };
  };
  
export default connect(mapStateToProps)(ViewerContent);
