import React, {Component} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {AuthHeader} from "../helper/auth.token";

import BloodScan from "./bloodscan"
import BloodScan40x from "./bloodscan40x"
import BmaScan40x from "./bmascan40x"
import {viewerType} from "../utils/const";
import {loadMorpheusSetting} from "../action/morpheus.setting.action";
import {initSlides} from "../action/maps.state.action";

class BloodViewerSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            slide_data: "",
        };
        this.props.dispatch(loadMorpheusSetting());
        this.props.dispatch(initSlides([{id: this.props.match.params.id}]));
    }

    componentDidMount = () => {
        // this.getSlideData();
      };

    getSlideData = () => {
        let slideUrl = `/api/slide/${this.props.match.params.id}/`;
        let headers = {
          headers: {
            Authorization: AuthHeader(),
          },
        };
        axios.get(slideUrl, headers).then((res) => {
          console.log("slidedata2", res.data);
          this.setState({
            slide_data: res.data,
          });
        });
    };

    render() {
        return <BloodScan {...this.props}/>
        // if ((this.state.slide_data || {}).viewer_type === viewerType.BLOOD40X)
        //     return <BloodScan40x {...this.props}/>;
        // if ((this.state.slide_data || {}).viewer_type === viewerType.BMA40X)
        //     return <BmaScan40x {...this.props}/>;
        // if ((this.state.slide_data || {}).viewer_type === viewerType.BLOOD)
        //     return <BloodScan {...this.props}/>;
        // return null;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
};

export default connect(mapStateToProps)(BloodViewerSelector);
