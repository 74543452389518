import React, {Component} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Grid, IconButton, Popper, Tooltip} from "@mui/material";
import {connect} from "react-redux";
import AppsIcon from '@mui/icons-material/Apps';
import {openApp} from "../../action/triggers.action";
import {previewApp} from "./app_maker";

class AppTabs extends Component {

	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.state = this.initState();
	}

	initState = () => {
		this.allAppsKey = {
			id: "all-apps",
			title: "All Apps",
			showTitleOnHover: true,
			icon: <AppsIcon sx={{fontSize: 'inherit'}}/>,
			component: this.getAllAppsComponent(),
		}
		this.tabs = [this.props.allAppsTab ? this.getTabComponent(this.allAppsKey) : undefined,
			...this.props.apps.filter(app => app.makeSideBarIcon).map(app => this.getTabComponent(app))];
		return {app: false};
	}

	getAllAppsComponent = () =>
		<Grid container>
			{this.props.apps.map(app =>
				<Grid item xs={4} textAlign={"center"}>
					<IconButton onClick={() => this.handleChange(app)} size={'large'} color={'secondary'}>
						{app.icon}
					</IconButton>
					<Typography noWrap={true}>{app.title}</Typography>
				</Grid>
			)}
		</Grid>

	checkTrigger = () => {
		if (this.props.apps.includes(this.props.trigger.openApp)) {
			this.setState({
				app: this.props.trigger.openApp,
			});
			delete this.props.trigger.openApp;
		}
	}

	handleChange = (value) =>
		this.setState({
			app: value === this.state.app ? false : value,
		});

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevState.app && this.state.app)
			this.props.dispatch(openApp(previewApp));
	}

	getTabComponent = (app) =>
		<Tooltip title={app.showTitleOnHover ? app.title : ""} placement={"right"} value={app}>
			<Tab icon={app.icon} value={app} onClick={() => this.handleChange(app)}
				 sx={{fontSize: '3vh'}}/>
		</Tooltip>;

	render() {
		this.checkTrigger();
		return <Box ref={this.ref} display={"flex"} width={"fit-content"} alignItems={this.props.alignItems}
					height={this.props.height} bgcolor={"background.paper"}>
			<Tabs orientation="vertical" variant="sc`rollable" onChange={this.handleChange}
				  value={this.state.app}
				  sx={{borderRight: 1, borderColor: "divider"}}>
				{this.tabs}
			</Tabs>
			<Popper open={!!this.state.app} anchorEl={this.ref.current} placement={"right-start"}
					sx={{
						width: '17vw', height: this.ref.current?.clientHeight + 1, bgcolor: 'background.paper',
						overflow: 'auto', padding: 2, border: 1, borderColor: 'divider'
					}}>
				{this.state.app && this.state.app.component}
			</Popper>
		</Box>
	}
}

const mapStateToProps = (state) => {
	return {
		trigger: state.triggerReducer,
	}
}

export default connect(mapStateToProps)(AppTabs);

