import {
    CheckCircleOutlined, CopyOutlined, ExclamationOutlined, InboxOutlined,
    LeftCircleOutlined, RocketOutlined, ScanOutlined, SettingOutlined, SlidersOutlined, ToolOutlined, PoweroffOutlined, PlayCircleOutlined
} from '@ant-design/icons';
import { Button, Col, message, Row, Statistic, Icon } from 'antd';
import axios from "axios";
import React, { Component } from 'react';
import cookie from 'react-cookies';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import "../../asset/style/scanner/scanner.css";
import { globalUrlPrefix } from '../../utils/const';
import { RegionSelectionConstants } from '../../actionTypes/region_selection.constant';

class UserControls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scan_workflow_started: false,
        }
    }

    // componentDidMount = () => {
    //     let any_present = false;
    //     for(var key in this.props.previews){
    //         if(this.props.previews[key] != RegionSelectionConstants.NOT_STARTED){
    //             any_present = true;
    //         }
    //     }
    //     if(any_present){
    //         this.setScanWorkflowStarted();
    //         window.location.href = '/' + globalUrlPrefix + '/scannerflow/' + this.props.id + '/scan/0'
    //     }
    // }

    setScanWorkflowStarted = () => {
        this.setState(Object.assign({}, this.state, { scan_workflow_started: true }));
        this.props.allowWorkflowAccess();
    }

    setScanWorkflowCancelled = () => this.setState(Object.assign({}, this.state, { scan_workflow_started: false }));

    ejectSlide = () => {
        const url = "/server/devices/" + this.props.id + "/eject?background=false";
        axios
        .get(url)
        .then(res => {
            message.success("Ejected successfully.")
        })
        .catch(err => {
            message.error("Eject Failed. Contact Admin");
        });
    }

    startBloodScan = () => {
        const url = "/server/devices/" + this.props.id + "/start_blood_scan/";
        axios({
            method:"post",
            url : url, 
            data: {}
        })
        .then(response => {
            if (response.status === 200) {
                message.success("Started Scan");
            }
            else {
                message.error("Could not start scans. Please contact administrator.")
            }
        })
        .catch(err => {
            message.error("Could not start scans. Please contact administrator.")
        })
    }

    render() {
       
        var adminControls = null;
        var scanner_control = null;
        var scan_stats = null;
        var config_paths = (((this.props.device || {}).scanner_health || {}).config_paths || {});
        
        let qcSettingsChanged = (((this.props.device || {}).scanner_health || {}).scanner_response || {}).qcSettingsChanged;
        let deviceType =  (((this.props.device || {}).scanner_health || {}).scanner_response || {})['deviceType']
        let isHemoLens = deviceType && deviceType.includes("HEMOLENS");
        // let spanLength = isHemoLens?6:8;
        let spanLength = 8;
        
        if (cookie.loadAll().superuser === "true" && !this.state.scan_workflow_started) {
            scan_stats = [
                <Row key={0}>
                    <Col span={8}>
                    </Col>
                    <Col span={8} style={{"textAlign": "center"}}>
                        <Statistic precision={2} title="Scan Disk Space Left" value={this.props.device.scanner_health.free_space} suffix="GB" />
                        <Statistic precision={2} title="Hard Disk Space Left" value={this.props.device.scanner_health.free_hdd} suffix="GB" />    
                        <Statistic title="Scans Left" value={this.props.device.scanner_health.max_scans - this.props.device.scanner_health.total_scans} />
                        <br></br>
                        <Row><b>Scanner Name : </b>{config_paths.name}</Row>
                        <Row><b>Settings File : </b>{config_paths.settings}</Row>
                        <Row><b>Optics Path : </b>{config_paths.optics}</Row>
                        <Row><b>State Path : </b>{config_paths.state}</Row>
                        <Row><b>Test Path : </b>{config_paths.test}</Row>
                    </Col>
                    <Col span={8}>
                    </Col>
                </Row>
            ];
        } 

        if(qcSettingsChanged) {
            scanner_control = [];
        } else {
            if(this.state.scan_workflow_started){
                scanner_control = [
                    <Row key={0} className="rounded-container">
                        <Col className="scan-workflow-init" span={24}>
                            <Row style={{ fontSize: 20, padding: 30 }} className="arial-font">
                                Please insert slides in the cassette and place in the slot.
                            </Row>
                            <Row style={{ padding: 30 }}>
                                <Col onClick={this.setScanWorkflowCancelled} span={4} offset={8} style={{ cursor: "pointer" }}>
                                    <Row style={{ color: "#C93756" }}>
                                        <LeftCircleOutlined style={{ fontSize: 50 }} />
                                    </Row>
                                    <Row className="arial-font">Back</Row>
                                </Col>
                                <Link to={{ pathname: "workflow" }} style={{color:"inherit"}}>
                                    <Col span={4} style={{ cursor: "pointer" }}>
                                        <Row style={{ color: "#26A65B" }}>
                                            <CheckCircleOutlined style={{ fontSize: 50 }} />
                                        </Row>
                                        <Row className="arial-font">Done</Row>
                                    </Col>
                                </Link>
                            </Row>
                        </Col>
                    </Row>
                ]
            } else {
                scanner_control = [
                    <Row key={0} >
                        <Col span={12} offset={6}>
                            <Row className="scanner-main-control-buttons">
                                { (this.props.isServerReachable) && !this.props.loaderPresent ? 
                                    <Col offset={8} span={8} onClick={this.props.stopScannerServer} style={{cursor: 'pointer'}}>
                                        <Row style={{color:"#22A7F0"}}>
                                            <PoweroffOutlined className="scanner-main-control-icon" style={{color: 'red', fontSize: '50px'}} />
                                        </Row>
                                        <Row className="arial-font">Shutdown Scanner</Row>
                                    </Col>
                                    : null
                                }
                                { (this.props.isMorpheusReachable && !this.props.isServerReachable) ? 
                                    <Col offset={8} span={8} onClick={this.props.startScannerServer} style={{cursor: 'pointer'}}>
                                        <Row style={{color:"#22A7F0"}}>
                                            <PoweroffOutlined className="scanner-main-control-icon" style={{color: 'green', fontSize: '50px'}} />
                                        </Row>
                                        <Row className="arial-font">Start Scanner</Row>
                                    </Col>
                                    : null
                                }
                            </Row>
                        </Col>
                    </Row>,
                    <Row key={1} >
                        <Col span={12} offset={6}>
                            <Row className="scanner-main-control-buttons">
                                {/* <Col span={6} onClick={this.setScanWorkflowStarted}>
                                    <a className="scanner-user-controls">
                                    <Row style={{color:"#22A7F0"}}><ScanOutlined className="scanner-main-control-icon" /></Row>
                                    <Row className="arial-font">Take Scan</Row>
                                    </a>
                                </Col> */}
                                {isHemoLens? null:<Col span={spanLength} onClick={this.setScanWorkflowStarted}>
                                    <Link to={'/' + globalUrlPrefix + '/scannerflow/' + this.props.id + '/scan/0'} className="scanner-user-controls">
                                        <Row style={{color:"#22A7F0"}}><ScanOutlined className="scanner-main-control-icon" /></Row>
                                        <Row className="arial-font">{isHemoLens?"BMA Scan":"Take Scan"}</Row>
                                    </Link>
                                </Col>}
                                {isHemoLens?
                                <Col span={spanLength} onClick={this.setScanWorkflowStarted}>
                                    <Link to={'/' + globalUrlPrefix + '/scannerflow/' + this.props.id + '/blood-scan/0'} className="scanner-user-controls">
                                        <Row style={{color:"#8C2A24"}}><PlayCircleOutlined className="scanner-main-control-icon"/></Row>
                                        <Row className="arial-font">Blood Scan</Row>
                                    </Link>
                                </Col>: null}
                                <Col span={spanLength} onClick={this.ejectSlide}>
                                    <a className="scanner-user-controls">
                                    <Row style={{color:"#27ae60"}}><InboxOutlined className="scanner-main-control-icon" /></Row>
                                    <Row className="arial-font">Eject</Row>
                                    </a>
                                </Col>
                                <Col span={spanLength}>
                                    <a href={"/" + globalUrlPrefix + "/settings/" + this.props.id} className="scanner-user-controls">
                                    <Row><SettingOutlined className="scanner-main-control-icon" /></Row>
                                    <Row className="arial-font">Settings</Row>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                ]
            }
        }

        if (cookie.loadAll().superuser === "true" && !this.state.scan_workflow_started) {
            adminControls = [
                <Row key={0}>
                    <Col span={12} offset={6}>
                        <Row className="scanner-main-control-buttons">
                            { (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_live_mode ?
                            <Link className="scanner-user-controls" to={ "/" + globalUrlPrefix + "/livemode/" + this.props.id + "/"}>
                                <Col span={8}>
                                    <Row style={{color:"#d63031"}}>
                                        <Icon type="eye" className="scanner-main-control-icon"/>
                                    </Row>
                                    <Row className="arial-font">Live Mode</Row>
                                </Col>
                            </Link> :undefined }
                            <Link className="scanner-user-controls" to={ "/" + globalUrlPrefix + "/manualmode/" + this.props.id + "/"}>
                                <Col span={8}>
                                    <Row style={{color:"#d63031"}}>
                                        <SlidersOutlined className="scanner-main-control-icon" />
                                    </Row>
                                    <Row className="arial-font">Manual Mode</Row>
                                </Col>
                            </Link>
                            <Link className="scanner-user-controls" to={ "/" + globalUrlPrefix + "/tuning/" + this.props.id + "/"}>
                                <Col span={8}>
                                    <Row>
                                        <ToolOutlined className="scanner-main-control-icon" />
                                    </Row>
                                    <Row className="arial-font">Tuning</Row>
                                </Col>
                            </Link>
                        </Row>
                    </Col>
                </Row>
            ];
            if(qcSettingsChanged) {
                adminControls.push(
                    <Row key={1}>
                        <Col span={12} offset={9}>
                            <div>
                                <Button type='danger' style={{cursor: 'default'}}>
                                    <ExclamationOutlined style={{color:'white'}} /> QC Settings have changed. Please restart the scanner.
                                </Button>
                            </div>
                            <br/>
                        </Col>
                    </Row>
                )
            } else {
                adminControls.push(
                    <Row key={1}>
                        <Col span={12} offset={6}>
                            <Row className="scanner-main-control-buttons">
                                <Col span={8}>
                                    <a href={'/server/devices/' + this.props.id + '/take_last_scan/?scan_speed=FAST'} className="scanner-user-controls">
                                        <Row style={{color:"#f39c12"}}><CopyOutlined className="scanner-main-control-icon-retake" /></Row>
                                        <Row className="arial-font">Retake Fast</Row>
                                    </a>
                                </Col>
                                <Col span={8}>
                                    <a href={'/server/devices/' + this.props.id + '/take_last_scan/?scan_speed=MEDIUM'} className="scanner-user-controls">
                                        <Row style={{color:"#f39c12"}}><CopyOutlined className="scanner-main-control-icon-retake" /></Row>
                                        <Row className="arial-font">Retake Medium</Row>
                                    </a>
                                </Col>
                                <Col span={8}>
                                    <a href={'/server/devices/' + this.props.id + '/take_last_scan/?scan_speed=SLOW'} className="scanner-user-controls">
                                        <Row style={{color:"#f39c12"}}><CopyOutlined className="scanner-main-control-icon-retake" /></Row>
                                        <Row className="arial-font">Retake Slow</Row>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )
            }
        } else {
            if (!this.state.scan_workflow_started) {
                adminControls = [
                    <Row key={0}>
                        <Col span={12} offset={6}>
                            <Row className="scanner-main-control-buttons">
                                { (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_live_mode ?
                                <Link className="scanner-user-controls" to={ "/" + globalUrlPrefix + "/livemode/" + this.props.id + "/"}>                                
                                    <Col  offset={8} span={8}>
                                        <Row style={{color:"#d63031"}}>
                                            <Icon type="sliders" className="scanner-main-control-icon"/>
                                        </Row>
                                        <Row className="arial-font">Live Mode</Row>
                                    </Col>
                                </Link>:undefined}
                            </Row>
                        </Col>
                    </Row>
                ];
            }
        }

        return (
            <Row className="rounded-container">
                <Col span={24}>
                    {scanner_control}
                    {adminControls}
                </Col>
                <Col span={24}>
                    {scan_stats}
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        superuser: state.loginAlerts.superuser,
        device: state.deviceStatusReducer[ownProps.id],
        previews: state.previewStatusReducer
    };
};

export default connect(mapStateToProps)(UserControls);
