export const getZStackUrl = (imageUrl, zStack) => {
	imageUrl = imageUrl.split('/')
	if (zStack === 0) {
		let idx = imageUrl.indexOf('stack');
		if (idx === -1) {
			imageUrl = imageUrl.join('/')
			return imageUrl;
		}
		imageUrl[idx] = 'tiled'
		imageUrl.splice(idx + 1, 1);
	} else {
		let idx = imageUrl.indexOf('tiled')
		if (idx === -1) {
			let idx = imageUrl.indexOf('stack');
			imageUrl[idx + 1] = `S${zStack}`
		} else
			imageUrl[idx] = `stack/S${zStack}`
	}
	imageUrl = imageUrl.join('/')
	return imageUrl;
}
