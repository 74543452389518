import React, {Component} from "react";
import {connect} from 'react-redux';
import {updateDigitalZoomStatus} from "../../../action/maps.state.action";
import {BottomNavigation, BottomNavigationAction, IconButton, Stack} from "@mui/material";
import {ZoomIn, ZoomOut} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

class ZoomControl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openPanel: false,
        }
    }

    toggleDigitalZoom = () => {
        let currMaxZoom = this.props.slideState.view.getMaxZoom();
        if (this.props.mapState.digitalZoomStatus) {
            this.props.dispatch(updateDigitalZoomStatus(
                this.props.activeMapId,
                false
            ));

            this.props.slideState.view.setMaxZoom(currMaxZoom - 1);
            this.props.slideState.view.setZoom(this.props.slideState.ZValues[this.props.slideState.ZValues.length - 2]);
        } else {
            this.props.dispatch(updateDigitalZoomStatus(
                this.props.activeMapId,
                true
            ));
            this.props.slideState.view.setMaxZoom(currMaxZoom + 1);
        }
    }

    incrementZoomLevel = () => {
        let index = Math.floor(this.props.mapState.z);
        if (index !== this.props.slideState.ZValues) {
            this.props.slideState.view.setZoom(this.props.slideState.ZValues[index + 1]);
        }
    }

    decrementZoomLevel = () => {
        let index = Math.floor(this.props.mapState.z);
        if(index !== 0) {
            this.props.slideState.view.setZoom(this.props.slideState.ZValues[index - 1]);
        }
    }

    getIntegerZoomLevel = () => {
        let zoomFloor = Math.floor(this.props.mapState.z);
        let zoomMantissa = 0;
        let zoomCeil = Math.ceil(this.props.mapState.z);
        let actualZoomValue = (this.props.slideState.zoomScale[zoomFloor] +
            (zoomMantissa * (this.props.slideState.zoomScale[zoomCeil] - this.props.slideState.zoomScale[zoomFloor])));

        if (Number.isInteger(actualZoomValue)) {
            actualZoomValue = parseInt(actualZoomValue);
        } else {
            actualZoomValue = Math.round((actualZoomValue.toPrecision(2)) * 100) / 100;
        }

        return actualZoomValue;
    }

    checkIfDigital = () => {
        let zoomFloor = parseInt(Math.floor(this.props.mapState.z));
        return zoomFloor >= this.props.slideState.zoomScale.length - 1;
    }


    render() {
        // if (window.location.href.includes("/blood-viewer/")) return null;

        if (!this.props.slideState) return <div/>;
        let actualZoomValue = this.getIntegerZoomLevel();
        let isDigital = this.checkIfDigital();

        let showDigitalText = (Math.ceil(this.props.slideState.view.getZoom()) === this.props.slideState.view.getMaxZoom() &&
                this.props.mapState.z >= this.props.slideState.ZValues[Math.floor(this.props.slideState.view.getZoom())])
            || (!this.props.mapState.digitalZoomStatus && (this.props.mapState.z >= this.props.slideState.maxZoom));


        let zoomOverlayComponent;
        if (this.state.openPanel) {
            zoomOverlayComponent = <Box sx={{
                height: '20vh', width: '4.3vw', border: '0px', bgcolor: "rgba(51, 4, 112,0.8)",
                display: "flex", overflowY: 'scroll'
            }}>
                <Typography sx={{width: '100%'}}>
                    <Stack direction="column" justifyContent={"space-around"} marginTop={1}
                           marginBottom={1} sx={{height: '18vh'}}>
                        {showDigitalText ?
                            <IconButton
                                color={"secondary"}
                                onClick={this.toggleDigitalZoom}
                                sx={{height: '5vh', fontSize: '0.8vw'}}
                            >
                                <Typography sx={{fontSize: '0.8vw'}}>
                                    {this.props.mapState.digitalZoomStatus ? "Disable" : "Enable"}
                                    <br/>
                                    Digital
                                                        <br/>
                                                        Zoom
                                                    </Typography>
                                                </IconButton> :
                                                <IconButton
                                                    color={"secondary"}
                                                    onClick={this.incrementZoomLevel}
                                                    sx={{height:'5vh'}}
                                                >
                                                    <ZoomIn fontSize="large"/>
                                                </IconButton>
                                            }
                                            <IconButton
                                                color={"secondary"}
                                                onClick={this.decrementZoomLevel}
                                                sx={{height:'5vh'}}
                                            >
                                                <ZoomOut fontSize="large"/>
                                            </IconButton>
                                        </Stack>
                                    </Typography>
                                </Box>
        }

        let textColor ;
        textColor = isDigital ? "#ed6111" : this.state.openPanel ? "secondary" : "white";

        return(
            <Stack direction="column-reverse" justifyContent={"flex-end"}>
                <BottomNavigation sx={{borderRadius: 0, bgcolor: "rgba(51, 4, 112,0.8)", width: "4.3vw"}}
                                  value={this.state.app}
                                  onChange={() => this.setState({openPanel: !this.state.openPanel,})}>
                    <BottomNavigationAction icon={<>
                        <Typography variant={'h5'} align={"center"} gutterBottom={!isDigital}
                                    color={textColor}>
                            {actualZoomValue}X
                        </Typography>
                        {isDigital ?
                            <Typography marginBottom={1} variant="caption" align={"center"} color={textColor}>
                                Digital
                            </Typography> : null}
                    </>} />
                </BottomNavigation>
                {zoomOverlayComponent}
            </Stack>
        )
    }
}

const mapStateToProps = (state) => {
    let activeMapId = state.gammaStateReducer.activeMapId;
    let mapState = state.mapsStateReducer[activeMapId];
    let {slideState} = mapState;
    return {activeMapId, mapState, slideState};
}

export default connect(mapStateToProps)(ZoomControl);
