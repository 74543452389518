import React from "react";
import {SettingComponentType} from "../../../utils/const";
import Brightness6Icon from "@mui/icons-material/Brightness6";
import ContrastIcon from "@mui/icons-material/Contrast";
import OpacityIcon from "@mui/icons-material/Opacity";
import FilterFramesIcon from "@mui/icons-material/FilterFrames";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import FilterBAndWIcon from "@mui/icons-material/FilterBAndW";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";

export const RotationKey = {
	type: SettingComponentType.SLIDER,
	name: "Rotation",
	icon: <ThreeSixtyIcon/>,
	value: 0,
	min: 0,
	max: 360,
}

export const BrightnessKey = {
	type: SettingComponentType.SLIDER,
	name: "Brightness",
	icon: <Brightness6Icon/>,
	value: 50,
	min: 0,
	max: 200,
}

export const ContrastKey = {
	type: SettingComponentType.SLIDER,
	name: "Contrast",
	icon: <ContrastIcon/>,
	value: 50,
	min: 0,
	max: 100,
}

export const HueKye = {
	type: SettingComponentType.SLIDER,
	name: "Hue",
	icon: <OpacityIcon/>,
	value: 1,
	min: 0,
	max: 100,
}

export const SaturationKey = {
	type: SettingComponentType.SLIDER,
	name: "Saturation",
	icon: <FilterFramesIcon/>,
	value: 100,
	min: 0,
	max: 100,
}

export const InvertKey = {
	type: SettingComponentType.SLIDER,
	name: "Invert",
	icon: <InvertColorsIcon/>,
	value: 0,
	min: 0,
	max: 100,
}

export const GrayscaleKey = {
	type: SettingComponentType.SLIDER,
	name: "Grayscale",
	icon: <FilterBAndWIcon/>,
	value: 50,
	min: 0,
	max: 100,
}

export const SettingsKeys = [
	BrightnessKey,
	ContrastKey,
	HueKye,
	SaturationKey,
	InvertKey,
	GrayscaleKey,
]
