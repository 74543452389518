import React, {Component} from "react";
import {connect} from 'react-redux';
import {Grid} from "@mui/material";
import AnnotationsExpandableListBlood from "./annotationsExpandableListBlood";
import {locateAnnotationOnMap} from "../../utils/map_utils";
import {AnnotationsConstants} from "../../../../utils/const";
import {
    annotationLayer,
    getBloodAnnotationLayer,
} from "../../utils/layers_info";
import {updateActiveAnnoDrawer} from "../../../../action/gamma.state.action";

class BloodAnnotationsApp extends Component{

    initState = () => {
        this.mapState = this.props.mapsState[this.props.activeMapId];
        this.slideState = this.mapState.slideState;
        this.bloodAnnotationState = this.props.mapsState[this.props.activeMapId].bloodAnnotationState;
    }

    componentDidMount() {
        //change anno drawer on mounting only
        let bloodAnnotationAppLayer = getBloodAnnotationLayer(this.props.gammaState);
        let bloodAnnotationAppDrawer = bloodAnnotationAppLayer.anno_drawer;
        this.props.dispatch(updateActiveAnnoDrawer(bloodAnnotationAppDrawer))
    }

    componentWillUnmount() {
        let annoAppDrawer = annotationLayer.drawer;
        this.props.dispatch(updateActiveAnnoDrawer(annoAppDrawer))
    }

    emptyComponent = () => <Grid>
        No Blood Annotations Available
    </Grid>;

    render() {
        this.initState();
        if (!this.bloodAnnotationState) return this.emptyComponent();
        let annotations;
        if(this.bloodAnnotationState.annotations)
            annotations = this.bloodAnnotationState.annotations.filter(annotation => annotation.anno_drawer === 5); //WBC dumped as anno_drawer 5
        if(annotations === undefined || annotations.length === 0 )
            return this.emptyComponent();

        return <AnnotationsExpandableListBlood annotations={annotations}
                                          slideId={this.slideState.slide_data.id}
                                          onAnnotationClick={(annotation) =>
                                              locateAnnotationOnMap(this.slideState.slidemap, annotation)}
                                          colors={AnnotationsConstants.ANNO_COLORS}
                                          showDownload={false} />
    }
}

const mapStateToProps = (state) => {
    return {
        gammaState: state.gammaStateReducer,
        activeMapId: state.gammaStateReducer.activeMapId,
        mapsState: state.mapsStateReducer,
    }
}

export default connect(mapStateToProps)(BloodAnnotationsApp)
