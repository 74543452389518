import React, {Component} from 'react';
import {connect} from "react-redux";
import {debounce} from 'lodash';
import {updateMapInFocus, updateMapState, updateSlideState, updateZStackLevel} from "../../action/maps.state.action";
import {
	getSlideAndInitialiseMapState,
	isZoomForTiled,
	recreateMapLayers,
	toggleKeyboardInteractions
} from './utils/map_utils'
import {loadMorpheusSettings, updateMorphleID} from "../../action/morpheus.state.action";
import "../../asset/style/neoviewer/slidemap.css"
import cookie from 'react-cookies';
import {getErrorComponent} from '../dashboard/slidelist_utils';
import * as keys from '../neoviewer/apps/app_keys'
import {componentIds, globalUrlPrefix, MorphleLogo} from '../../utils/const'
import {convertSettingsToFilter} from '../../utils/utils';
import {updateActiveMap} from "../../action/gamma.state.action";
import SlideName from "./headbar_apps/SlideName";
import {Paper} from "@mui/material";
import {closeNotification, notifyError, notifyInfo} from "./utils/display.notification";
import RightBottom from "./rightBottom";
import ImageLayer from "ol/layer/Image";

class SlideMap extends Component {

	constructor(props) {
		super(props);

		this.screenSizeString = window.screen.width + 'x' + window.screen.height;

		this.state = {
			slide: -1,
			isFetching: true,
			isErrored: false,
			loadedMap: false,
			mapId: this.props.map_id,
			stackMode: 0,
			modalVisible: false,
			backgroundColor: "#FFFFFF",
			shiftScrollCount: 0,
			mapRenderComplete: false,
		};

		this.lastCachingCall = -1;
		this.upperZLevelsCached = 0;
		this.lowerZLevelsCached = 0;

		this.previewMapRef = null;
		this.props.dispatch(loadMorpheusSettings());
		this.cacheZStackLayers = debounce(this.cacheZStackLayers.bind(this), 1000);
		this.initMapState = true;

		if (cookie.loadAll().isMobile === 'true') {
			window.addEventListener('resize', () => {
				// We execute the same script as before
				let vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty('--vh', `${vh}px`);
			});
		}
	}

	initState = () => {
		this.mapState = this.props.mapsState[this.props.map_id];
		this.slideState = this.mapState.slideState;
		this.gammaState = this.props.gammaState;
	}

	addPreviewMapControl = (previewMapRef) => {
		this.previewMapRef = previewMapRef
		this.state.slidemap.addControl(this.previewMapRef)
	};

	updateMapState = () => {

		let tiledLayer = isZoomForTiled(this.state.view.getZoom(), this.state.viewLevelsInfo);
		if (!tiledLayer)
			this.props.dispatch(updateZStackLevel(this.props.map_id, 0));

		let zoomRatio = (((this.props.urlState || {}).app_state || {})[keys.displaySizeCalibrationKey.id] || {})[this.screenSizeString] || 1;
		if (zoomRatio > 1 && Number.isInteger(this.state.view.getZoom()) && this.state.view !== undefined && (this.props.urlState || {}).presentCode === undefined) {
			if (this.state.view.getZoom() <= this.state.view.getMaxZoom() - 1) {
				this.state.view.setZoom(this.state.ZValues[this.state.view.getZoom()]);
			} else if (this.state.view.getZoom() <= this.state.view.getMaxZoom()) {
				this.state.view.setZoom(this.state.ZValues[this.state.view.getZoom() - 1]);
			}
		}

		this.props.dispatch(updateMapState(
			this.props.map_id,
			{
				x: this.state.view.getCenter()[0],
				y: this.state.view.getCenter()[1],
				z: this.state.view.getZoom(),
				r: this.state.view.getRotation(),
				onStitchedLayer: !tiledLayer,
			}
		));
	};

	updateLayers = () => {
		this.setState({
			mapRenderComplete: false,
		});

		let maxZLevel = this.slideState.slide_meta && this.slideState.slide_meta.takeBidirectionalZStack ?
     (this.slideState.slide_meta.numZLevels) / 2 : (this.slideState.slide_meta.numZLevels);

    let minZLevel = this.slideState.slide_meta && this.slideState.slide_meta.takeBidirectionalZStack ?
      (-1 * ((this.slideState.slide_meta.numZLevels) / 2)) : 0;

		minZLevel = Math.floor(minZLevel);
		maxZLevel = Math.floor(maxZLevel);

		recreateMapLayers(this.state.slidemap, this.state.viewLevelsInfo, this.slideState.slide_data, this.state.imageInfo, this.state.projection,
			this.state.resolutions, this.state.txtyInfo, this.state.tileSize, this.updateMapState, (obj) => {
				obj.addPreviewMapControl = this.addPreviewMapControl;
				obj.goHome = this.goHome;
				this.setState(obj)
			}, this.mapState.zStackLevel, minZLevel, maxZLevel, this.slideState.slide_meta.takeZStack);
	}

	onMapRenderingComplete = () => {
		this.setState({
			mapRenderComplete: true,
		});
	}

	cacheZStackLayers = () => {
		this.lastCachingCall = Date.now();
		let lastCachingCall = this.lastCachingCall;
		this.upperZLevelsCached = 0;
		this.lowerZLevelsCached = 0;
		let maxZLevel = this.slideState.slide_meta.takeBidirectionalZStack ? (this.slideState.slide_meta.numZLevels - 1) / 2 :
			(this.slideState.slide_meta.numZLevels - 1);
		let timerInterval = 1000;
		let timer = 0;
		for (let i = 1; i <= maxZLevel + 1; i++) {
			let layers = this.state.slidemap.getLayers().getArray();
			setTimeout(() => this.cacheLayer(layers, i, lastCachingCall), timer);
			timer += timerInterval;
		}
	}

	cacheLayer = (layers, zLevel, lastCachingCall) => {
		if (lastCachingCall === this.lastCachingCall) {
			for (let i = 0; i < layers.length; i++) {
				let layer = layers[i];
				if (layer.values_.posS === (-1 * zLevel) || layer.values_.posS === zLevel) {
					layer.setVisible(true);
				}
			}
			setTimeout(() => this.layerVisibilityOff(layers), 10);
			this.upperZLevelsCached = this.upperZLevelsCached + 1;
			this.lowerZLevelsCached = this.lowerZLevelsCached - 1;
		}
	}

	layerVisibilityOff = (layers) => {
		for (let i = 0; i < layers.length; i++) {
			let layer = layers[i];
			if (!(layer instanceof ImageLayer) || layer.values_.posS === this.mapState.zStackLevel) {
				layer.setVisible(true);
			} else {
				layer.setVisible(false);
			}
		}
	}

	cacheZoomLevels = (upper, layers) => {
		let maxZLevel = this.slideState.slide_meta.takeBidirectionalZStack ? (this.slideState.slide_meta.numZLevels - 1) / 2 :
			(this.slideState.slide_meta.numZLevels - 1);
		if (upper && this.upperZLevelsCached < maxZLevel) {
			for (let i = 0; i < layers.length; i++) {
				let layer = layers[i];
				if (layer.values_.posS === (this.upperZLevelsCached + 1) || layer.values_.posS === (this.upperZLevelsCached + 2)) {
					layer.setVisible(true);
				}
			}
			setTimeout(() => this.layerVisibilityOff(layers), 10);
			this.upperZLevelsCached = this.upperZLevelsCached + 2;
		} else if (!upper && this.lowerZLevelsCached > (-1 * maxZLevel)) {
			for (let i = 0; i < layers.length; i++) {
				let layer = layers[i];
				if (layer.values_.posS === (this.lowerZLevelsCached - 1) || layer.values_.posS === (this.lowerZLevelsCached - 2)) {
					layer.setVisible(true);
				}
			}
			setTimeout(() => this.layerVisibilityOff(layers), 10);
			this.upperZLevelsCached = this.lowerZLevelsCached - 2;
		}
	}

	scrollActionOnMap = (e) => {
		if (e.type !== "wheel") {
			return true;
		} else {
			let maxZLevel = this.slideState.slide_meta.takeBidirectionalZStack ? (this.slideState.slide_meta.numZLevels - 1) / 2 :
				(this.slideState.slide_meta.numZLevels - 1);
			let minZLevel = this.slideState.slide_meta.takeBidirectionalZStack ? (-1 * ((this.slideState.slide_meta.numZLevels - 1) / 2)) : 0;

			if (this.slideState.slide_meta.takeZStack && e.originalEvent.shiftKey) {
				if (this.mapState.onStitchedLayer) {
					notifyError("zstackerror", "Z Stack only allowed on higher zoom levels.", false, 2500);
				} else {
					if (e.originalEvent.deltaY > 0 && this.mapState.zStackLevel > minZLevel) {
						this.props.dispatch(updateZStackLevel(this.props.map_id, this.mapState.zStackLevel - 1));
						this.setState({
							shiftScrollCount: this.state.shiftScrollCount + 1,
						});
						this.cacheZoomLevels(true, this.state.slidemap.getLayers().getArray());
					} else if (e.originalEvent.deltaY < 0 && this.mapState.zStackLevel < maxZLevel) {
						this.props.dispatch(updateZStackLevel(this.props.map_id, this.mapState.zStackLevel + 1));
						this.setState({
							shiftScrollCount: this.state.shiftScrollCount + 1,
						});
						this.cacheZoomLevels(false, this.state.slidemap.getLayers().getArray());
					}
				}
				return false;
			} else {
				return true;
			}
		}
	}

	componentDidMount = () => {
	}

	componentDidUpdate = (prevProps, prevState) => {

		if (prevProps.lastMapCount !== this.props.lastMapCount) {
			this.state.slidemap.setTarget(null);
			this.setState({
				loadedMap: false,
				mapRenderComplete: false,
			});
		}

		if (prevState.mapRenderComplete !== this.state.mapRenderComplete) {
			if (this.state.mapRenderComplete) {
				if (!this.mapState.onStitchedLayer) {
					this.cacheZStackLayers();
				} else {
					this.lastCachingCall = Date.now();
				}
				this.props.dispatch(updateSlideState(this.props.map_id, this.state));
			}
		}

		let prevMapState = prevProps.mapsState[this.props.map_id];
		if (prevMapState.zStackLevel !== this.mapState.zStackLevel) {
			let layers = this.state.slidemap.getLayers().getArray();
			for (let i = 0; i < layers.length; i++) {
				let layer = layers[i];
				layer.setVisible(true);
			}
			for (let i = 0; i < layers.length; i++) {
				let layer = layers[i];
				if (layer.values_.posS === this.mapState.zStackLevel || !(layer instanceof ImageLayer)) {
					layer.setVisible(true);
				} else {
					layer.setVisible(false);
				}
			}
			this.props.dispatch(updateSlideState(this.props.map_id, this.state));
		}
		if ((prevMapState.morpheusSettingInitialised !== this.mapState.morpheusSettingInitialised && !this.state.loadedMap)
			|| this.initMapState || prevProps.lastMapCount !== this.props.lastMapCount) {
			this.initMapState = false;
			getSlideAndInitialiseMapState(this.props.slide_id, (obj) => {
					obj.addPreviewMapControl = this.addPreviewMapControl;
					obj.goHome = this.goHome;
					this.setState(obj)
				}, this.activeMap, this.syncViews, this.props.map_id, this.mapState, this.props.urlState, this.updateMapState,
				this.updateLayers, this.onMapRenderingComplete, this.scrollActionOnMap);
		}

		if (!this.state.loadedMap && !this.state.isFetching && !this.state.isErrored) {
			this.state.slidemap.setTarget("map-" + this.props.map_id);
			if (this.previewMapRef) {
				console.log("Preview Map Was Undefined!!!");
				this.state.slidemap.addControl(this.previewMapRef);
			}
			this.setState({
				loadedMap: true
			})
			this.props.dispatch(updateMorphleID(this.slideState.slide_data.morphle_id, this.slideState.slide_data.id));
			this.props.dispatch(updateSlideState(this.props.map_id, this.state));
			if (this.mapState.x === -1) this.goHome();
    }
    if (this.props.mapsState[this.props.map_id].mapInFocus) {
      this.state.slidemap &&
        toggleKeyboardInteractions({ "slidemap": this.state.slidemap, "active": true })
    } else {
      this.state.slidemap && 
        toggleKeyboardInteractions({ "slidemap": this.state.slidemap, "active": false })
    }
	}

	activeMap = () => {
		if (this.props.activeMapId !== this.props.map_id)
			this.props.dispatch(updateActiveMap(this.props.map_id));
  }
  
  setMapInFocus = (mapInFocus) => {
    if (!this.props.showInFocus || 
      (this.props.mapsState[this.mapId] || {}).mapInFocus === mapInFocus ||
      this.props.showLarge) {
      return
    }

    this.props.dispatch(updateMapInFocus({
      mapId: this.props.map_id,
      mapInFocus,
    }))
  }

	// Priyanshu: idea is not to access the current position of the other maps, as it makes things unstable
	// algorithm is written such that it only uses the current position of the map which is in motion
	// this conclusion is reached after doing multiple tests
	syncViews = () => {
		if (this.gammaState.sync) {
			if (this.gammaState.syncQueue === 0 || this.gammaState.syncId === this.props.map_id) {
				this.gammaState.syncId = this.props.map_id;
				++this.gammaState.syncQueue;
				let [x, y] = this.state.view.getCenter();
				let dx = x - this.mapState.X;             // final - initial
				let dy = y - this.mapState.Y;
				let dz = this.state.view.getZoom() - this.mapState.Z;
				let dr = this.state.view.getRotation() - this.mapState.R;
				let count = Object.keys(this.props.mapsState).length;
				// let duration = 60 * (count-1);
				for (let map_id in this.props.mapsState) {
					if (map_id === this.props.map_id) continue;
					let mapState = this.props.mapsState[map_id];
					let cos = Math.cos(this.mapState.R - mapState.R);
					let sin = Math.sin(this.mapState.R - mapState.R);
					mapState.slideState.view.animate({
						center: [mapState.X + dx * cos + dy * sin,
							mapState.Y + dy * cos - dx * sin],
						zoom: mapState.Z + dz,
						rotation: mapState.R + dr,
						duration: 60
						// eslint-disable-next-line no-loop-func
					}, () => {
						if (--count <= 1) --this.gammaState.syncQueue;
					});
				}
			}
		}
	}

	stopViewing = () => {
		if (this.props.loginAlerts.is_staff || this.props.loginAlerts.superuser) {
			window.location.href = "/" + globalUrlPrefix + "/dashboard/";
		} else {
			window.location.href = "/" + globalUrlPrefix + "/cases/";
		}
	}

	goHome = () => {
		if (this.state.layer !== undefined) {
			this.state.slidemap.getView().fit(this.state.extent);
		}
	}

	render = () => {

		this.initState();

		if (this.state.isErrored) {
			console.log(this.state.errMessage);
		}

		// zStackNotificationStatus = undefined -> not notified, 1 -> notified, 0 -> notification closed
		if (this.gammaState.zStackNotificationStatus === undefined && this.state.shiftScrollCount <= 10 &&
			((this.slideState || {}).slide_meta || {}).takeZStack) {
			this.gammaState.zStackNotificationStatus = 1;
			notifyInfo('zStackNotification',
				<h4>
					Slides with ZStack Layers detected
					<br/>
					Use SHIFT + Mouse Scroll Wheel to navigate between Z-Stack Layers
				</h4>, false, 0);
		} else if (this.gammaState.zStackNotificationStatus === 1 && this.state.shiftScrollCount > 10) {
			this.gammaState.zStackNotificationStatus = 0;
			closeNotification('zStackNotification');
		}

		let borderStyle = this.props.activeMapId === this.props.map_id && Object.keys(this.props.mapsState).length > 1 ?
			{'border-style': 'solid', 'border-color': 'red'} : null;

		return (
			this.state.isFetching || this.state.isErrored ? <div/> : !this.mapState ?
        getErrorComponent() :
        <div style={{ "border": this.props.mapsState[this.props.map_id].mapInFocus ? "4px solid #32c730" : "inherit" }}>
          <div id={"map-" + this.props.map_id}
          onClick={() => this.setMapInFocus(true)}
              style={{
                height: this.props.height, backgroundColor: this.state.backgroundColor, ...borderStyle,
              filter: convertSettingsToFilter(this.props.viewerSettings[this.slideState.slide_data.settings])
              }}>
            {Object.keys(this.props.mapsState).length > 1 ?
              <Paper sx={{
                position: "absolute", zIndex: 2, right: 0, top: 0, borderRadius: 0,
                paddingInlineStart: 4
              }}>
                <SlideName mapId={this.props.map_id}/>
              </Paper> : null
            }
            {(this.props.urlState || {}).presentCode !== undefined ? null :
              <img className="morphle-watermark" id={componentIds.morphleWatermark} alt={'logo'}
                  src={MorphleLogo}/>}
            {/* [Priyanshu] since right bottom apps build layers we need to show it in blood viewer
            TODO: [Priyanshu] make layers in slidemap only not in layers
            */
              window.location.href.includes("/blood-viewer/") ?
                <div id={componentIds.rightBottomApps}
                    style={{position: 'absolute', bottom: 40, right: '2.4vw', zIndex: 2}}>
                  {<RightBottom/>}
                </div> : null
            }
          </div>
        </div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		urlState: state.viewerUrlReducer,
		loginAlerts: state.loginAlerts,
		mapsState: state.mapsStateReducer,
		gammaState: state.gammaStateReducer,
		activeMapId: state.gammaStateReducer.activeMapId,
		lastMapCount: state.gammaStateReducer.lastMapCount,
		viewerSettings: state.viewerSettingsReducer,
	};
};

export default connect(mapStateToProps)(SlideMap);
