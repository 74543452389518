import { FieldViewActions } from '../actionTypes/fieldView.constant';

export const fieldGridSelected = (value) => ({
    type : FieldViewActions.FILTER_GRID_SELECTED,
    response : value
});

export const fieldChangeImage = (value) => ({
    type : FieldViewActions.FILTER_CHANGE_IMAGE,
    resposne: value,
})

export const updatePageFieldView = (value) => ({
    type: FieldViewActions.UPDATE_PAGE,
    response: value
})

export const updatePageSize = (value) => ({
    type: FieldViewActions.UPDATE_PAGE_SIZE,
    response: value
})

export const updateAllGrids = (value) => ({
    type: FieldViewActions.UPDATE_ALL_GRIDS,
    response: value,
})

export const updateZStack = (value) => ({
    type: FieldViewActions.UPDATE_Z_STACK,
    response: value
})

export const resetZStack = () => ({
    type: FieldViewActions.RESET_Z_STACK,
})

export const reset = () => ({
    type: FieldViewActions.RESET,
})

export const updateZStackWrapper = (selected, zStack) => (dispatch) => {
    console.log("zstackChange", zStack, selected)
    let image_loc = selected.image;
        // "/scan-hdd/morphle_test/2021/12/19/03/18/1_2021-12-19_03-18-48-0000189_Delta39_S1T1R1/tiled/hpf/37_x5y22.jpg"
    let url = image_loc.split('/')
    if(zStack == 0) {
        let idx = url.indexOf('stack');
        if(idx==-1){
            return;
        }
        url[idx]='tiled'
        url.splice(idx+1,1);
    }
    else{
        let idx = url.indexOf('tiled')
        if(idx == -1){
            let idx = url.indexOf('stack');
            url[idx+1]=`S${zStack}`
        } 
        else{
            url[idx]=`stack/S${zStack}`
        }
    }
    url = url.join('/')
    let newSelected = selected;
    newSelected.image = url;
    dispatch(updateZStack(parseInt(zStack)));
    dispatch(fieldGridSelected(newSelected));
} 
