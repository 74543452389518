import Draw from "ol/interaction/Draw";
import Snap from "ol/interaction/Snap";
import Modify from "ol/interaction/Modify";
import {magicToolDrawingKey} from "../drawer/draw_tool_keys";
import {store} from "../../../helper/store";

export const isInteractionRelatedToDraw = interaction =>
	interaction instanceof Modify || interaction instanceof Draw || interaction instanceof Snap;

export const removeDrawInteractions = map =>
	map.getInteractions().getArray().filter(interaction => isInteractionRelatedToDraw(interaction)).forEach(
		interaction => map.removeInteraction(interaction)
	);

export const addDrawInteractions = (map, vector, tool, addFeature) => {
	let source = vector.getSource();
	if (!source || !tool) return;

	let modify = new Modify({
		source,
		snapToPointer: true,
		pixelTolerance: 20,
	});
	map.addInteraction(modify);
	modify.setActive(true);

	let draw = new Draw({
		source,
		type: tool.shape,
		...tool.params,
	});
	map.addInteraction(draw);
	draw.on("drawend", e => {
		if (tool === magicToolDrawingKey) {
			let magicToolState = store.getState().MagicToolReducer;
			addFeature(magicToolState.unsaved_magic_annotations[magicToolState.curr_level], map, vector, tool);
		} else addFeature(e.feature, map, vector, tool);
		map.getOverlays().clear();
	});

	map.addInteraction(new Snap({
		source,
		pixelTolerance: 20,
		snapToEdge: true,
		snapToVertices: true,
	}));
}

export const updateDrawInteractions = (map, vector, tool, addFeature) => {
	removeDrawInteractions(map);
	addDrawInteractions(map, vector, tool, addFeature);
}
