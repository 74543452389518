import React, { Component } from 'react';
import SlideMap from '../component/neoviewer/slidemap'
import SlideMapBrain from '../component/neoviewer/slidemap_brain'
import queryString from 'query-string'
import axios from 'axios';
import {Link} from 'react-router-dom';
import { connect } from "react-redux";
import { AuthHeader } from '../helper/auth.token';
import { closeDrawer } from "../action/dashboard.action";
import { makeSlideRead } from '../action/slides.action';
import { Spin, Statistic, Progress, Row, PageHeader, Divider, Col, Affix, Menu, Button, Slider, Tooltip } from 'antd';
import {
    ArrowLeftOutlined,
    PlayCircleOutlined,
    PauseCircleOutlined,
    CloseCircleOutlined,
    SaveOutlined,
    EditOutlined
} from '@ant-design/icons';
import { globalUrlPrefix } from '../utils/const';
import logo_square from "../asset/img/logo_square.png";
import { logout } from '../action/login.action';
import {addAnnotation, removeAnnotations, saveAnnotations, updateClusterId, getWholeCluster} from '../action/brainAnno.action'
import {updateAppClosedStatus} from "../action/morpheus.state.action"
import * as keys from '../component/neoviewer/apps/app_keys'
import { imageBrightessChanged } from "../action/viewerSetting.action";
import ZoomControls from '../component/neoviewer/apps/zoom_controls'

const { Countdown } = Statistic;


export class ScanBrain extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stitching_done: true,
            stitch_percent: 0.0,
            stitch_time: 0.0,
            scan_ongoing: false,
            isFetching: true,
            errorCount: 0,
            bloodAIMode : false,
        }

        this.max_txty_time = 0;
        this.timer = null;
        this.query_params = queryString.parse(this.props.location.search);
        this.slide_id = this.props.match.params.id;
        this.total_clusters = 3
    }

    componentDidMount = () => {
        this.props.dispatch(makeSlideRead(this.props.match.params.id));
        this.pollForStitchingStatus();
        this.timer = setInterval(() => this.pollForStitchingStatus(), 2000);
        this.props.dispatch(closeDrawer());
    }

    componentDidUpdate = () => {
        if(this.state.errorCount == 3) {
            this.props.dispatch(logout());
        }
    }

    pollForStitchingStatus = () => {
        this.setState({
            isFetching: true
        });

        let url = '/server/slide_status?id=' + this.slide_id;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                //console.log(response.data);
                let json_data = JSON.parse(response.data);
                if (json_data.status === 0) {
                    this.setState({
                        scan_ongoing: true,
                        stitching_done: false,
                        isFetching: false
                    });
                }
                if (json_data.status === 12 || json_data.status === 9) {
                    this.setState({
                        stitching_done: true,
                        scan_ongoing: false,
                        isFetching: false
                    });
                    clearInterval(this.timer);
                    this.timer = null;
                } else {
                    let txty_time = parseFloat(json_data.txty_time) / 60;
                    this.max_txty_time = Math.max(this.max_txty_time, txty_time)
                    let stitcher_percent = json_data.stitcher_percent;
                    let stitcher_time = json_data.stitcher_estimated_time / 60000;
                    if (stitcher_time === 0) {
                        stitcher_time = this.max_txty_time;
                    }
                    let final_percent = (parseFloat(stitcher_percent)).toFixed(2);
                    let final_time = (stitcher_time).toFixed(2);
                    let time_left = Date.now() + (final_time * 60 * 1000);
                    this.setState({
                        stitch_percent: final_percent,
                        stitch_time: time_left,
                        stitching_done: false,
                        isFetching: false
                    });
                }
            })
            .catch(err => {
                this.setState({
                    errorCount: this.state.errorCount + 1,
                });
                //console.log("Failed Getting Value", err);
            });
    }

    componentWillUnmount = () => {
        clearInterval(this.timer);
        this.timer = null;
    }

    goToDashboard = () => {
        window.location.href = '/' + globalUrlPrefix + '/dashboard'
    }

    togglebloodAIMode = () => {
        if(this.state.bloodAIMode){
            this.props.dispatch(removeAnnotations());
        }
        this.setState({
            bloodAIMode: !this.state.bloodAIMode,
        })
    }

    clearAllSelectedAnnotations = () => {
        this.props.dispatch(removeAnnotations());
    }

    saveAllSelectedAnnotations = () => {
        this.props.dispatch(saveAnnotations(this.props.brainAnno.selected_annotations));
    }

    toggleApp = () => {
        this.props.dispatch(updateAppClosedStatus(keys.brainAppKey.id, !(this.props.urlState.app_state.brain_app.closed), this.props.urlState))
    }

    onBrightnessChange = (value) => {
        this.props.dispatch(imageBrightessChanged(value));
    }

    updateClusterId = () => {
        let val = this.props.brainAnno.cluster_id
        val = (val+1)%this.total_clusters
        this.props.dispatch(updateClusterId(val));
        this.props.dispatch(removeAnnotations());
    }

    getWholeCluster = () => {
        this.props.dispatch(getWholeCluster(!this.props.brainAnno.get_whole_cluster));
    }

    render() {
        //console.log("reducer", this.props.brainAnno.selected_annotations)
        let stitchingInProgressComponent = <Row key="progress">
            <Row style={{ textAlign: 'center' }}>
                <h2>Stitching in Progress</h2>
                <Progress style={{ marginTop: "1%", marginBottom: "1%" }} type="circle" percent={parseFloat(this.state.stitch_percent)} />
                <Link to={"/" + globalUrlPrefix + "/dashboard"}>
                    <h2>Click to go back to Dashboard</h2>
                </Link>
            </Row>
        </Row>;

        let scanOngoingComponent = <Row>
            <Row style={{ textAlign: 'center', marginTop:"5em" }}>
                <b>Slide is being scanned. <br></br>Progress will be updated soon.</b>
                <Link to={"/" + globalUrlPrefix + "/dashboard"} className="click-to-go-to-dash"><h2>Click to go back to Dashboard</h2></Link>
            </Row>
        </Row>;

        let viewerComponent;

        if(!this.state.scan_ongoing && this.state.stitching_done && !this.state.isFetching) {
            viewerComponent = <SlideMapBrain key="viewer" slide_id={this.slide_id} map_id={0} ai_mode={this.state.bloodAIMode}/>;
        }

        let aiModeButton;
        if(!this.state.bloodAIMode){
            aiModeButton = 
            
                <Button onClick={this.togglebloodAIMode}  style={{border:'0px', marginLeft:'-10px'}}>
                    <Tooltip   text={"Turn ON AI"}>
                    <PlayCircleOutlined style={{fontSize:'30px'}}/>
                    </Tooltip>
                </Button>
            
        }
        else{
            aiModeButton = 
                <Tooltip text={"Turn OFF AI"} placement="right">
                    <Button onClick={this.togglebloodAIMode}  style={{border:'0px', marginLeft:'-10px'}}>
                            <PauseCircleOutlined style={{fontSize:'30px'}}/>
                    </Button>
                </Tooltip>
        }

        let AILoadingData;
        if(this.state.bloodAIMode){
            if(this.props.brainAnno.loading_state){
                AILoadingData=<Spin style={{marginLeft: '10px'}}/>
            }
            else{
                AILoadingData=
                <div>
                    <Tooltip text={"CHANGE CLUSTER"} placement="right">
                        <Button onClick={this.updateClusterId}>
                            {"C"+String(this.props.brainAnno.cluster_id+1)}
                        </Button>
                    </Tooltip>
                    <Tooltip text={this.props.brainAnno.getWholeCluster?"SHOW FEW RESULT":"SHOW ALL RESULTS"} placement="right">
                        <Button style={{marginTop:'10px',marginLeft:'-5px'}} onClick={this.getWholeCluster}>
                            {this.props.brainAnno.get_whole_cluster?"FEW":"ALL"}
                        </Button>
                    </Tooltip>
                </div>
            }
        }
        else{
            AILoadingData = null;
        }
        

        let actionsButtons;
        if(this.props.brainAnno.selected_annotations.length>0){
            actionsButtons=<div><Menu.Item>
            <Tooltip text={"CLEAR SELECTED RESULTS"} placement="right">
                <Button style={{border:'0px', marginLeft:'-10px'}} onClick={this.clearAllSelectedAnnotations}>
                    <CloseCircleOutlined style={{fontSize:'30px'}}/>
                </Button>
            </Tooltip>
        </Menu.Item>
        <Menu.Item>
            <Tooltip text={"SAVE SELECTED RESULTS"} placement="right">
                <Button style={{border:'0px', marginLeft:'-10px'}} onClick={this.saveAllSelectedAnnotations}>
                    <SaveOutlined style={{fontSize:'30px'}}/>
                </Button>
            </Tooltip>
        </Menu.Item></div>
        }
        else{
            actionsButtons=null;
        }

        let zoomApp=null;
        if(this.props.brainAnno.slideState!='' && this.props.brainAnno.slideState.view!=undefined && 
        this.props.brainAnno.slideState.zoomScale!=undefined && this.props.brainAnno.slideState.ZValues !=undefined
        && this.props.brainAnno.slideState.maxZoom != undefined && this.props.brainAnno.slideState.goHome !=undefined){
            //console.log("valued", this.props.brainAnno.slideState)
            zoomApp = <ZoomControls 
            view={this.props.brainAnno.slideState.view}
            zoomScale={this.props.brainAnno.slideState.zoomScale}
            ZValues={this.props.brainAnno.slideState.ZValues}
            maxZoom={this.props.brainAnno.slideState.maxZoom} 
            goHome={this.props.brainAnno.slideState.goHome} 
            overlayed={false}/>
        }

        let selectedButtons;
        if(this.props.brainAnno.selected_annotations.length>0){
            selectedButtons = <div>
                                            <Divider />
                                            <Row style={{marginLeft:'14px', marginTop:'10px'}}>
                                                <Button style={{border:'0px', marginLeft:'-10px'}} onClick={this.clearAllSelectedAnnotations}>
                                                    <CloseCircleOutlined style={{fontSize:'30px'}}/>
                                                </Button>
                                            </Row>
                                            <Row style={{marginLeft:'14px', marginTop:'20px'}}>
                                                <Button style={{border:'0px', marginLeft:'-10px'}} onClick={this.saveAllSelectedAnnotations}>
                                                    <SaveOutlined style={{fontSize:'30px'}}/>
                                                </Button>
                                            </Row>
                                           
                                            <Row style={{marginLeft:'14px', marginTop:'20px'}}>
                                                <Statistic title="Selected" value={this.props.brainAnno.selected_annotations.length}  style={{fontSize:'44px'}} valueStyle={{textAlign:'center', marginLeft:'-20px'}}></Statistic>
                                            </Row>
            </div>
        }

        let menuComponent;
        menuComponent = <Menu style={{width:'100px', height:'100%'}}>
                            <Menu.Item key="Back">
                                <Link to={'/' + globalUrlPrefix + '/dashboard'}>
                                <ArrowLeftOutlined 
                                style={{fontSize:'44px'}}/>
                                </Link>
                            </Menu.Item>
                            <br /><br />
                            <br/><br/>
                            <Menu.Item>
                                <img src={logo_square} style={{width:'40px'}}>
                                </img>
                            </Menu.Item>
                            <br/><br/>
                            <br/><br/>
                            <Menu.Item>
                                {aiModeButton}
                            </Menu.Item>
                            
                            <br/><br/>
                            <br/><br/>
                            <Menu.Item>
                                <Button style={{border:'0px', marginLeft:'-10px'}} onClick={this.clearAllSelectedAnnotations}>
                                    <CloseCircleOutlined style={{fontSize:'30px'}}/>
                                </Button>
                            </Menu.Item>
                            <Menu.Item>
                                <Button style={{border:'0px', marginLeft:'-10px'}} onClick={this.saveAllSelectedAnnotations}>
                                    <SaveOutlined style={{fontSize:'30px'}}/>
                                </Button>
                            </Menu.Item>
                            <br/><br/>
                            <br/><br/>
                            {/* {actionsButtons} */}
                           
                            <Menu.Item style={{height:'60px'}}>
                                <Statistic title="Selected" value={this.props.brainAnno.selected_annotations.length}  style={{fontSize:'44px',position:'absolute', bottom:0}}></Statistic>
                            </Menu.Item>
                            <br/><br/>
                            <br/><br/>
                            <Menu.Item>
                                <Button style={{border:'0px', marginLeft:'-10px'}} onClick={this.toggleApp}>
                                    <EditOutlined style={{fontSize:'30px'}}/>
                                </Button>
                            </Menu.Item>
                            <br/><br/>
                            <br/><br/>
                            {/* <Menu.Item style={{height:'250px', marginBottom:'10px'}}>

                            <Slider
                                vertical
                                min={1}
                                max={100}
                                onChange={this.onBrightnessChange}
                                onAfterChange={() => this.brightnessSlider.blur()}
                                value={this.props.viewerSettingData.brightness}
                                ref={(slider) => this.brightnessSlider = slider}
                                disabled={(this.props.urlState || {}).presentCode != undefined}
                            />

                            </Menu.Item> */}
                            <Menu.Item style={{height:'250px',border:'0px', marginLeft:'35px', marginTop:'300px'}}>
                                {zoomApp}
                            </Menu.Item>
                            <Menu.Item style={{position:'absolute',bottom:'10px'}}>
                                <Button style={{border:'0px', marginLeft:'-10px'}} onClick={this.toggleApp}>
                                    <EditOutlined style={{fontSize:'30px'}}/>
                                </Button>
                            </Menu.Item>
                            <Menu.Item>
                                {AILoadingData}
                            </Menu.Item>
                        </Menu>

        
        return (
            <div key="view">
                {
                    <Spin key="loading" tip="Loading..." spinning={this.state.isFetching} delay={500}>
                        {
                            (this.state.scan_ongoing === true) ? 
                                scanOngoingComponent : 
                                ((this.state.stitching_done === true) ? 
                                    <Row>
                                        <Col span={1} style={{marginTop:'5px', height:'100%'}}>
                                            {/* <Affix> */}
                                                {/* {menuComponent} */}
                                            {/* </Affix> */}
                                            <Row style={{marginLeft:'18px', marginTop:'10px'}}>
                                                <Link to={'/' + globalUrlPrefix + '/dashboard'}>
                                                {/* <ArrowLeftOutlined 
                                                style={{fontSize:'44px'}}/> */}
                                                <img src={logo_square} style={{width:'40px'}}></img>
                                                </Link>
                                            </Row>
                                            {/* <Row style={{marginLeft:'14px', marginTop:'14px'}}>
                                                <img src={logo_square} style={{width:'40px'}}></img>
                                            </Row> */}
                                            <Row style={{marginLeft:'18px', marginTop:'34px'}}>
                                                {aiModeButton}
                                            </Row>
                                             
                                            <Row style={{marginLeft:'18px', marginTop:'20px'}}>
                                                {AILoadingData}
                                            </Row>
                                            {/* <Row style={{marginLeft:'14px', marginTop:'14px'}}>
                                                <Button style={{border:'0px', marginLeft:'-10px'}} onClick={this.clearAllSelectedAnnotations}>
                                                    <CloseCircleOutlined style={{fontSize:'30px'}}/>
                                                </Button>
                                            </Row>
                                            <Row style={{marginLeft:'14px', marginTop:'14px'}}>
                                                <Button style={{border:'0px', marginLeft:'-10px'}} onClick={this.saveAllSelectedAnnotations}>
                                                    <SaveOutlined style={{fontSize:'30px'}}/>
                                                </Button>
                                            </Row>
                                           
                                            <Row style={{marginLeft:'14px', marginTop:'14px'}}>
                                                <Statistic title="Selected" value={this.props.brainAnno.selected_annotations.length}  style={{fontSize:'44px',position:'fixed', bottom:'280px'}}></Statistic>
                                            </Row> */}
                                            {/* <Row style={{ marginTop:'350px',marginLeft:'20px'}}> */}
                                            <Row style={{marginTop:'10px'}}>
                                                {selectedButtons}
                                            </Row>
                                            <Row style={{position:'fixed', bottom:'60px', marginLeft:'20px'}}>
                                                <Col style={{marginLeft:'-10px'}}>
                                                    {zoomApp}
                                                </Col>
                                            </Row>
                                            {/* <Row style={{marginLeft:'14px', marginTop:'24px'}}> */}
                                            <Row style={{position:'fixed', bottom:'20px', marginLeft:'14px'}}>
                                                <Button style={{border:'0px', marginLeft:'-10px'}} onClick={this.toggleApp}>
                                                    <EditOutlined style={{fontSize:'30px'}}/>
                                                </Button>
                                            </Row>
                                            
                                        </Col>
                                            
                                        <Col span={23}>
                                            {viewerComponent}
                                        </Col>
                                    </Row>
                                     : 
                                    stitchingInProgressComponent)
                        }
                    </Spin>
                }
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        brainAnno: state.brainAnnoGridReducer,
        urlState: state.viewerUrlReducer,
        viewerSettingData: state.viewerSettingReducer,
    };
};

export default connect(mapStateToProps)(ScanBrain);
