import React, {Component} from "react";
import {connect} from 'react-redux';
import cookie from "react-cookies";
import axios from "axios";
import {AuthHeader} from "../../../../helper/auth.token";
import {Button, Col, Divider, message, Row, Select as SelectAntd, Spin} from 'antd';
import {Fill, Stroke, Style} from 'ol/style.js';
import {Vector as VectorSource} from 'ol/source.js';
import {Vector as VectorLayer} from 'ol/layer.js';
import {click} from 'ol/events/condition.js';
import Select from 'ol/interaction/Select.js';
import {updateShape} from '../../../../action/morpheus.state.action'
import {getDrawingTools} from './drawing/draw_tool_maker'
import {
    addAnnotation,
    drawAnnotations,
    drawAnnotationsMagic,
    getAnnotationList,
    getAnnotationsInGridView,
    getAnnotationsInListView
} from './brain_annotation_app_utils.js'
import {AnnotationsConstants, mapLayerTypes} from "../../../../utils/const";
import "../../../../asset/style/neoviewer/annotations_app.css"
import {Link} from 'react-router-dom';
import querystring from "query-string";

const { Option } = SelectAntd;

class BrainAbstractAnnotator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedToolBrainMetaStasis: null,
            selectedToolFullTissue: null,
            selectedColor: "#7CFC00",
            continuousMode: false,
            isFetching: false,
            isDrawing: false,
            annotations: [],
            annotationsMetaStasis: [],
            annotationsFullTissue: [],
            busyMessage: "...",
            selectedAnnotationId: null,
            gridView: false,
            imgsPerColInGridView: 3,
            filteredColor: AnnotationsConstants.ALL_COLORS,
            showAnnoLayer: true,
            showAnnoLayerMetastasis: true,
            showAnnoLayerFullTissue: true,
            numTimesCalled: 1,
            magicToolStartPoint: [],
            magicToolCurrentPoint: [],
            magicToolCurrentResults: [], 
            currentLevel: 0,
            magicToolFetching : false,
        }

        this.timer = null;
        this.timerBrainMetaStasis = null;
        this.timerFullTissue = null;
        this.vector = new VectorLayer({
            source: new VectorSource({}),
            name: mapLayerTypes.ANNOTATION_LAYER_NAME,
        });

        this.magicLayerVector = new VectorLayer({
            source: new VectorSource({}),
            name: mapLayerTypes.ANNOTATION_LAYER_NAME,
        })
        this.vector.set('annotationLayer', true);

        this.magicLayerVector.set('annotationLayer', true)

        this.selectHover = new Select({
            condition: click,
            layers: [this.vector, this.magicLayerVector],
            wrapX: false,
            hitTolerance: 5,
            style: new Style({
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.0)',
                }),
                fill: new Fill({
                    color: 'rgba(0, 0, 0, 0.0)'
                })
            })
        });
    }

    handleAnnoColorChange = (color) => {
        this.setState({ selectedColor: color.hex });
    };

    onSelected = (newSelection) => {
        let newTool = newSelection === this.state.selectedTool ? null : newSelection;
        this.setState({
            selectedTool: newTool,
            selectedToolFullTissue: null,
            selectedToolBrainMetaStasis: null,
        })
        this.props.dispatch(updateShape(newTool));
    }

    onSelectedBrainMetaStasis = (newSelection) => {
        let newTool = newSelection === this.state.selectedToolBrainMetaStasis ? null : newSelection;
        this.setState({
            selectedToolBrainMetaStasis: newTool,
            selectedToolFullTissue: null
            // selectedTool: newTool,
        })
        this.props.dispatch(updateShape(newTool));
    }

    onSelectedFullTissue = (newSelection) => {
        let newTool = newSelection === this.state.selectedToolFullTissue ? null : newSelection;
        this.setState({
            selectedToolBrainMetaStasis: null,
            selectedToolFullTissue: newTool
            // selectedTool: newTool,
        })
        this.props.dispatch(updateShape(newTool));
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (nextState.isDrawing) return false;
        return true;
    }

    setDrawing = (value) => {

        if (value) {
            this.removeSelectInteraction();
        } else if (!this.state.continuousMode) {
            this.addSelectInteraction();
        }

        this.setState({ isDrawing: value });
    }

    setDrawingBrainMetaStasis = (value) => {
        if (value) {
            this.removeSelectInteraction();
        } else if (!this.state.continuousMode) {
            this.addSelectInteraction();

        }
        this.setState({ isDrawing: value });
    }

    setDrawingFullTissue = (value) => {
        if (value) {
            this.removeSelectInteraction();
        } else if (!this.state.continuousMode) {
            this.addSelectInteraction();

        }
        this.setState({ isDrawing: value });
    }

    redrawAnnotations = () => drawAnnotations(this.vector, this.state.annotations, this.state.selectedAnnotationId, this.state.showAnnoLayer);

    redrawAnnotationsMetastasis = (annotations) => drawAnnotations(this.vector, annotations, this.state.selectedAnnotationId, this.state.showAnnoLayer);

    redrawAnnotationsFullTissue = (annotations) => drawAnnotations(this.vector, annotations, this.state.selectedAnnotationId, this.state.showAnnoLayer);

    redrawAnnotationsMagicLayer = (annotations) => {
        //console.log("magic-annotations", annotations)
        drawAnnotationsMagic(this.magicLayerVector, annotations, null, true)
    }

    removeSelectInteraction = () => {
        this.props.slidemap.removeInteraction(this.selectHover);
    }

    changeSelectedAnnotation = (selectedId) => {
        this.setState({
            selectedAnnotationId: selectedId
        }, this.redrawAnnotations)
    }

    addSelectInteraction = () => {
        // this.props.slidemap.addInteraction(this.selectHover);
        // this.selectHover.on("select", e => {
        //     let selectedFeature = undefined;
        //     if (e.deselected.length > 0) {
        //         if (e.selected.length > 0) {
        //             if (e.selected[0].getId() !== e.deselected[0].getId() || e.selected[0].getId() != this.state.selectedAnnotationId) {
        //                 selectedFeature = e.selected[0];
        //             }
        //         }
        //     } else if (e.selected.length > 0) {
        //         selectedFeature = e.selected[0];
        //     }
        //     // if (selectedFeature.getProperties().creator != this.props.creator) selectedFeature = undefined;
        //     if (selectedFeature != undefined) {
        //         this.changeSelectedAnnotation(selectedFeature.getId());
        //         if (this.props.openAppOnAnnoClick && this.props.urlState.app_state[this.props.id].closed) {
        //             this.props.dispatch(updateAppClosedStatus(this.props.id, false, this.props.urlState));
        //         }
        //     } else {
        //         let annotations = this.state.annotationsFullTissue;
        //         annotations = annotations.concat(this.state.annotationsMetaStasis)
        //         this.selectHover.getFeatures().clear();
        //         this.setState({
        //             selectedAnnotationId: null
        //         }, this.redrawAnnotationsMetastasis(annotations));
        //     }
        // });
    }

    componentWillMount = () => {
        let zIndex = (this.props.slide.x_fields * 10000) + this.props.slide.y_fields + 1000000000;
        this.vector.setZIndex(zIndex);
        let magic_z_index = zIndex*10;
        this.magicLayerVector.setZIndex(magic_z_index)
        this.props.slidemap.addLayer(this.vector);
        this.props.slidemap.addLayer(this.magicLayerVector)
        this.addSelectInteraction();
    }

    escFunction = (event) => {
        let annotations = this.state.annotationsFullTissue;
        annotations = annotations.concat(this.state.annotationsMetaStasis)
        if (event.keyCode === 27) {
            this.selectHover.getFeatures().clear();
            this.setState({
                selectedAnnotationId: null
            }, this.redrawAnnotationsMetastasis(annotations))
        }
    }

    doRefreshAnnotationsFromBackend = () => getAnnotationList(this.props.slide.id, this.props.creator, (annotations) => {
        this.setState({
            annotations
        }, this.redrawAnnotations);
    })

    doRefreshAnnotationsFromBackendBrainMetastasis = () => getAnnotationList(this.props.slide.id, this.props.creator, false, (annotationsMetaStasis) => {
        if(this.state.selectedToolBrainMetaStasis == "Modify" || this.state.selectedToolFullTissue == "Modify"){
            return;
        }
        let annotations = annotationsMetaStasis;
        annotations = annotations.concat(this.state.annotationsFullTissue)
        // annotations.push(magicViewerAnnos)
        // console.log("magic-index", this.state.currentLevel)
        this.setState({
            annotationsMetaStasis,
        }, this.redrawAnnotationsMetastasis(annotations));
    })
    
    doRefreshAnnotationsFromBackendFullTissue = () => getAnnotationList(this.props.slide.id, this.props.creator, true, (annotationsFullTissue) => {
        let annotations = annotationsFullTissue;
        annotations = annotations.concat(this.state.annotationsMetaStasis)
        if(this.state.selectedToolBrainMetaStasis == "Modify" || this.state.selectedToolFullTissue == "Modify"){
            return;
        }
        this.setState({
            annotationsFullTissue,
        }, this.redrawAnnotationsFullTissue(annotations));
    })

    handleScroll = () => {
        var element = document.querySelector('.scroll-row');
        if (element != null) {
            element.scrollIntoView();
        }
    }

    componentDidMount = () => {
        // this.doRefreshAnnotationsFromBackend();
       // console.log("url",this.props.urlState)
        this.doRefreshAnnotationsFromBackendFullTissue();
        this.doRefreshAnnotationsFromBackendBrainMetastasis();
        // this.timer = setInterval(this.doRefreshAnnotationsFromBackend, 2000);
        this.timerFullTissue = setInterval(this.doRefreshAnnotationsFromBackendFullTissue, 2000);
        this.timerBrainMetaStasis = setInterval(this.doRefreshAnnotationsFromBackendBrainMetastasis, 2000);
        document.addEventListener("keydown", this.escFunction, false);
        if (((this.props.urlState.app_state || {})['present_app'] || {}).code != undefined) {
            let url = `/api/set_sync_browsing_annotations/?sync_code=${this.props.urlState.app_state['present_app'].code}&morphle_id=${this.props.urlState.slide_morphle_id}&selectedTool=${this.state.selectedTool}&selectedColor=${this.state.selectedColor}&continousMode=${this.state.continuousMode}&selectedAnnotation=${this.state.selectedAnnotationId}&gridView=${this.state.gridView}&imgsPerCol=${this.state.imgsPerColInGridView}`;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                })
                .catch(err => {
                    ////console.log("Failed Setting Key Value");
                });
        }
    }

    componentDidUpdate = (prevProps, prevState) => {

        let annotationValueChange = ((prevState.selectedTool != this.state.selectedTool) ||
            (prevState.selectedColor != this.state.selectedColor) ||
            (prevState.continuousMode != this.state.continuousMode) ||
            (prevState.selectedAnnotationId != this.state.selectedAnnotationId) ||
            (prevState.gridView != this.state.gridView) ||
            (prevState.imgsPerColInGridView != this.state.imgsPerColInGridView) ||
            (prevState.filteredColor != this.state.filteredColor));


        let timestampChange = (prevProps.urlState.annotations_timestamp != this.props.urlState.annotations_timestamp);

        if (this.props.annotationsViewCreator == undefined && ((this.props.urlState.app_state || {})['present_app'] || {}).code != undefined && annotationValueChange) {
            let url = `/api/set_sync_browsing_annotations/?sync_code=${this.props.urlState.app_state['present_app'].code}&morphle_id=${this.props.urlState.slide_morphle_id}&selectedTool=${this.state.selectedTool}&selectedColor=${this.state.selectedColor.slice(1)}&continuousMode=${this.state.continuousMode}&selectedAnnotation=${this.state.selectedAnnotationId}&gridView=${this.state.gridView}&imgsPerCol=${this.state.imgsPerColInGridView}&filteredColor=${this.state.filteredColor.replace("#", "")}&slide_id=${this.props.urlState.slide_id}`;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                })
                .catch(err => {
                    ////console.log("Failed Setting Key Value");
                });
        }

        if ((this.props.urlState || {}).presentCode != undefined && timestampChange) {
            let url = `/api/get_sync_browsing_annotations/?sync_code=${this.props.urlState.presentCode}&morphle_id=${this.props.urlState.slide_morphle_id}`;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    try {
                        if (response.data.selectedTool != undefined && response.data.selectedColor != undefined && response.data.continuousMode != undefined &&
                            response.data.selectedAnnotation != undefined && response.data.gridView != undefined && response.data.imgsPerCol != undefined &&
                            response.data.filteredColor != undefined) {
                            let selectedTool = response.data.selectedTool != 'null' ? response.data.selectedTool : null;
                            let selectedColor = "#" + response.data.selectedColor;
                            let continuousMode = response.data.continuousMode == 'true' ? true : false;
                            let selectedAnnotationId = response.data.selectedAnnotation != 'null' ? parseInt(response.data.selectedAnnotation) : null;
                            let gridView = response.data.gridView == 'true' ? true : false;
                            let imgsPerColInGridView = parseInt(response.data.imgsPerCol);
                            let filteredColor = "";
                            if (response.data.filteredColor == AnnotationsConstants.ALL_COLORS) {
                                filteredColor = response.data.filteredColor;
                            } else {
                                filteredColor = "#" + response.data.filteredColor;
                            }

                            this.setState({
                                selectedTool,
                                selectedColor,
                                continuousMode,
                                selectedAnnotationId,
                                gridView,
                                imgsPerColInGridView,
                                filteredColor,
                            })
                        }
                    } catch (err) {
                        //console.log(err);
                    }
                })
                .catch(err => {
                    //console.log("Failed Getting Value");
                });
        }
    }

    componentWillUnmount = () => {
        clearTimeout(this.timer);
        this.timer = null;
    }

    switchContinuousMode = (value) => {
        this.setState({
            continuousMode: value,
            selectedTool: (value) ? this.state.selectedTool : null
        }, () => this.setDrawing(false));
    }

    switchGridView = (e) => {
        this.setState({
            gridView: e.target.value == 1
        })
    }

    onChangeImgsPerColInGridView = (val) => {
        this.setState({
            imgsPerColInGridView: val
        })
    }

    onSelectAnnotationFromList = (annotation) => {
        let selectedFeature = this.vector.getSource().getFeatures().find((feature) => {
            return feature.getId() == annotation.id;
        });
        try{
            this.props.slidemap.getView().fit(selectedFeature.getGeometry());
        }
        catch(err){
            console.log(err)
        }
        this.setState({
            selectedAnnotationId: annotation.id
        }, this.redrawAnnotations);
    }

    onSelectAnnotationFromListBrainMetaStasis = (annotation) => {
        let selectedFeature = this.brainmetastasisVector.getSource().getFeatures().find((feature) => {
            return feature.getId() == annotation.id;
        });
        let annotations = this.state.annotationsFullTissue;
        annotations = annotations.concat(this.state.annotationsMetaStasis)
        this.props.slidemap.getView().fit(selectedFeature.getGeometry());
        this.setState({
            selectedAnnotationId: annotation.id
        }, this.redrawAnnotationsMetastasis(annotations));
    }

    onSelectAnnotationFromListFullTissue = (annotation) => {
        let selectedFeature = this.brainfulltissueVector.getSource().getFeatures().find((feature) => {
            return feature.getId() == annotation.id;
        });
        let annotations = this.state.annotationsFullTissue;
        annotations = annotations.concat(this.state.annotationsMetaStasis)
        this.props.slidemap.getView().fit(selectedFeature.getGeometry());
        this.setState({
            selectedAnnotationId: annotation.id
        }, this.redrawAnnotationsFullTissue(annotations));
    }

    onDeleteAnnotation = (e, id) => {
        this.setState({
            isFetching: true,
        })
        let url = `/api/annotation/${id}/ `;
        axios.delete(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 204 || response.status === 200 || response.status === 301 || response.status === 302) {
                    let features = this.vector.getSource().getFeatures();
                    let result = features.filter(index => index.getId() === id);
                    this.vector.getSource().removeFeature(result[0]);
                    let metastasis_annotations = this.state.annotationsMetaStasis;
                    for (let i = 0; i < metastasis_annotations.length; i++) {
                        if (metastasis_annotations[i].id == id) {
                            metastasis_annotations.splice(i, 1);
                            break;
                        }
                    }
                    let full_tissue_annotations = this.state.annotationsFullTissue;
                    for (let i = 0; i < full_tissue_annotations.length; i++) {
                        if (full_tissue_annotations[i].id == id) {
                            full_tissue_annotations.splice(i, 1);
                            break;
                        }
                    }
                    let annotations = metastasis_annotations;
                    annotations = annotations.concat(full_tissue_annotations)
                    this.setState({
                        annotationsMetaStasis: metastasis_annotations,
                        annotationsFullTissue: full_tissue_annotations,
                        selectedAnnotationId: (this.state.selectedAnnotationId == id) ? null : this.state.selectedAnnotationId
                    }, this.redrawAnnotationsMetastasis(annotations))
                }
                else if (response.status === 403 || response.status === 401 || response.status === 400) {
                    message.error('Annotation Delete Failed', 2.5);
                }
                this.setState({
                    isFetching: false
                })
            })
            .catch(err => {
                this.setState({
                    isFetching: false
                })
            });
        e.stopPropagation();
    }

    getAllColorOptions = (colors) => {
        let colorOptions = colors.map((color, index) => {
            return <Option value={color.toLowerCase()} key={index}>
                <div className="color-icon-option" style={{ backgroundColor: color.toLowerCase() }}></div>
            </Option>
        });
        colorOptions.unshift(<Option value={AnnotationsConstants.ALL_COLORS} key={colors.length + 1}>Color Filter(All)</Option>);
        return colorOptions;
    }

    changeColorFilter = (value) => {
        if (value.toLowerCase() != this.state.filteredColor.toLowerCase()) {
            this.setState({
                selectedAnnotationId: null,
            });
        }
        this.setState({
            filteredColor: value.toLowerCase(),
        });
    }

    toggleAnnotationLayer = () => {
        let layers = this.props.slidemap.getLayers().getArray();
        if (!this.state.showAnnoLayer) {
            // for (let i = 0; i < layers.length; i++) {
            //     let layer = layers[i];
            //     if (layer.values_.name == mapLayerTypes.ANNOTATION_LAYER_NAME) {
            //         layer.setVisible(false);
            //     }
            // }
            let annotations = this.state.annotationsFullTissue;
            annotations = annotations.concat(this.state.annotationsMetaStasis)
            // this.doRefreshAnnotationsFromBackend();
            this.doRefreshAnnotationsFromBackendBrainMetastasis(annotations);
            this.doRefreshAnnotationsFromBackendFullTissue(annotations);
            // let result = features.filter(index => index.getId() === id);
            // this.vector.getSource().removeFeature(result[0]);

        } else {
            this.vector.setSource(new VectorSource({
                features: [],
                wrapX: false
            }))
            //console.log("removing all feats")
            // for (let i = 0; i < layers.length; i++) {
            //     let layer = layers[i];
            //     if (layer.values_.name == mapLayerTypes.ANNOTATION_LAYER_NAME) {
            //         layer.setVisible(true);
            //     }
            // }
        }
        this.setState({
            showAnnoLayer: !this.state.showAnnoLayer,
        });
    }

    updateMagicToolStartPoint = (start) => {
       // console.log("magic-start", start)
        this.setState({
            magicToolStartPoint: start,
            currentLevel:0,
            magicToolCurrentResults:[],
            magicToolFetching: true,
        })
        // this.clearMagicTool();
        this.fetchMagicToolResults(start)
    }
    
    updateMagicToolCurrentPoint = (current) => {
      //  console.log("magic-current", current)
        let start = this.state.magicToolStartPoint
        let delta = Math.abs(Math.pow((current[0]-start[0]),2)+Math.pow((current[1]-start[1]),2))
        let index = parseInt(delta/150)
        index = Math.min(index, Math.max(this.state.magicToolCurrentResults.length-1,0))
        this.setState({
            magicToolCurrentPoint: current,
            currentLevel: index
        })
        let results = this.state.magicToolCurrentResults
        if(results.length != 0){
            //console.log("magic-result", [results[index]])
            //console.log("magic-index", index)
            this.redrawAnnotationsMagicLayer([results[index]])
        }
    }

    saveMagicAnnotaton = () => {
            let url = `/api/store_brain_annotations/`;
            let magic_annos = this.state.magicToolCurrentResults[this.state.currentLevel]
            axios.post(url, querystring.stringify({'annotations':JSON.stringify([magic_annos])}), { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    if (response.status === 200 || response.status === 301 || response.status === 302) {
                        message.success("Annotations Saved Successfully")
                        // this.doRefreshAnnotationsFromBackendBrainMetastasis();
                    }
                    else if (response.status === 403 || response.status === 401 || response.status === 400) {
                        // dispatch(caseListUpdateFailed(id, response));
                        message.info("Annotations couldnt be saved")
                        // this.clearMagicTool();
                    }
                }).then(res => {
                    this.clearMagicTool();
                })
                .catch(err => {
                    // dispatch(caseListUpdateFailed(id, err));
                });
        }

    clearMagicTool = () => {
        this.setState({
            magicToolCurrentPoint: [],
            magicToolStartPoint:[],
            magicToolCurrentResults:[],
            currentLevel: 0
        })
        this.magicLayerVector.setSource(new VectorSource({
            features:[],
            wrapX: false
        }))
    }
    
    fetchMagicToolResults = (start) => {
        let url = `/api/get_brain_slider/`;
        let val = {
            'start_point':JSON.stringify(start),
            'z': this.props.urlState.z,
            'slide': this.props.urlState.slide_id,
            'extent': this.props.slidemap.getView().calculateExtent(this.props.slidemap.getSize()),
            'user_id':  cookie.loadAll().user_id
        }
        //console.log("start-ex", val)
        axios.post(url,  querystring.stringify(val), { headers: { Authorization: AuthHeader() } })
            .then(res => {
                if(res.data == null){
                    if(this.state.selectedToolFullTissue){
                        message.info("Please Decrease Zoom Level")
                    }else{
                        message.info("Please Increase Zoom Level")
                    }
                    return;
                }
                //console.log("res",res)
                //console.log("magic-fetch", res)
                this.redrawAnnotationsMagicLayer([res.data[this.state.currentLevel]])
                this.setState({
                    magicToolCurrentResults: res.data,
                    magicToolFetching: false,
                })
            }).catch(err => {
                this.setState({
                    magicToolFetching: false
                })
                //console.log(err);
            })
    }


    render() {
        //console.log("magic-fetching", this.state.magicToolFetching)
        // console.log("selected tool", this.state.selectedToolBrainMetaStasis)
        let metastasis_area = 0;
        let fulltissue_area = 0;
        for (let i = 0; i < this.state.annotationsMetaStasis.length; i++) {
            metastasis_area += this.state.annotationsMetaStasis[i].area
        }
        for (let i = 0; i < this.state.annotationsFullTissue.length; i++) {
            fulltissue_area += this.state.annotationsFullTissue[i].area
        }

        //console.log("this", this.props.brainAnno)
        let currentAnnotations = this.state.annotations;

        if (this.state.filteredColor != AnnotationsConstants.ALL_COLORS) {
            currentAnnotations = currentAnnotations.filter((value, index) => {
                return value.color.toLowerCase() == this.state.filteredColor.toLowerCase();
            });
        }

        if ((this.props.urlState || {}).presentCode != undefined) {
            this.handleScroll();
        }

        let drawToolParams = {
            slidemap: this.props.slidemap,
            vector: this.vector,
            onSelected: this.onSelectedBrainMetaStasis,
            currentSelectedKey: this.state.selectedTool,
            continuousMode: this.state.continuousMode,
            setDrawing: this.setDrawing,
            addSelectInteraction: this.addSelectInteraction,
            removeSelectInteraction: this.removeSelectInteraction,
            addAnnotation: (area, perimeter, key, coord, center) => {
                //console.log("brain from ");
                addAnnotation(this.state.selectedColor, area, perimeter, key, coord, center, this.props.creator, this.props.urlState.z, this.props.slide.id, this.doRefreshAnnotationsFromBackend, this.state.numTimesCalled);
                this.state.numTimesCalled += 1;
            }
        }

        let drawToolParamsBrainMetastasis = {
            slidemap: this.props.slidemap,
            vector: this.vector,
            onSelected: this.onSelectedBrainMetaStasis,
            currentSelectedKey: this.state.selectedToolBrainMetaStasis,
            continuousMode: this.state.continuousMode,
            setDrawing: this.setDrawingBrainMetaStasis,
            addSelectInteraction: this.addSelectInteraction,
            removeSelectInteraction: this.removeSelectInteraction,
            updateMagicToolStartPoint : this.updateMagicToolStartPoint,
            updateMagicToolCurrentPoint : this.updateMagicToolCurrentPoint,
            clearMagicTool : this.clearMagicTool,
            saveMagicAnnotation : this.saveMagicAnnotaton,
            addAnnotation: (area, perimeter, key, coord, center) => {
                //console.log("brain from barin");
                addAnnotation("#0000FF", area, perimeter, key, coord, center, this.props.creator, this.props.urlState.z, this.props.slide.id, this.doRefreshAnnotationsFromBackendBrainMetastasis, this.state.numTimesCalled, false);
                this.state.numTimesCalled += 1;
            }
        }

        let drawToolParamsFullTissue = {
            slidemap: this.props.slidemap,
            vector: this.vector,
            onSelected: this.onSelectedFullTissue,
            currentSelectedKey: this.state.selectedToolFullTissue,
            continuousMode: this.state.continuousMode,
            setDrawing: this.setDrawingFullTissue,
            addSelectInteraction: this.addSelectInteraction,
            removeSelectInteraction: this.removeSelectInteraction,
            updateMagicToolStartPoint : this.updateMagicToolStartPoint,
            updateMagicToolCurrentPoint : this.updateMagicToolCurrentPoint,
            clearMagicTool : this.clearMagicTool,
            saveMagicAnnotation : this.saveMagicAnnotaton,
            addAnnotation: (area, perimeter, key, coord, center) => {
                //console.log("brain from bardfgdgdfgfdgin");
                addAnnotation(this.state.selectedColor, area, perimeter, key, coord, center, this.props.creator, this.props.urlState.z, this.props.slide.id, this.doRefreshAnnotationsFromBackendFullTissue, this.state.numTimesCalled, true);
                this.state.numTimesCalled += 1;
            }
        }

        let drawingTools = getDrawingTools(drawToolParams, this.props.drawToolKeys);

        let drawingToolsBrainMetastasis = getDrawingTools(drawToolParamsBrainMetastasis, this.props.drawToolKeys);

        let drawingToolsFullTissue = getDrawingTools(drawToolParamsFullTissue, this.props.drawToolKeys);

        let colSpan = 12 / drawingTools.length;

        let annotationsDiv;

        if (this.props.annotationsViewCreator == undefined) { // Use Default View if not supplied a function
            if (this.state.gridView && cookie.loadAll().deployment_mode === 'offline') {
                annotationsDiv = getAnnotationsInGridView(currentAnnotations, this.state.selectedAnnotationId, this.props.zoomScale,
                    this.props.desiredZoomScale, this.props.slide, this.onSelectAnnotationFromList, this.onDeleteAnnotation,
                    this.doRefreshAnnotationsFromBackend, this.props.viewerSettingData, this.state.imgsPerColInGridView,
                    this.onChangeImgsPerColInGridView, this.props.urlState);
            } else {
                // annotationsDiv = getAnnotationsInListView(currentAnnotations, this.props.slide, this.state.selectedAnnotationId, 
                //     this.onSelectAnnotationFromList, this.props.viewerSettingData, this.props.zoomScale, this.props.desiredZoomScale,
                //     this.onDeleteAnnotation, this.doRefreshAnnotationsFromBackend, this.props.urlState);
            }
        } else {
            annotationsDiv = this.props.annotationsViewCreator(currentAnnotations, this.state.selectedAnnotationId, this.onSelectAnnotationFromList,
                this.onDeleteAnnotation, this.doRefreshAnnotationsFromBackend);
        }

        let annotationsDivBrainMetaStasis = getAnnotationsInListView(this.state.annotationsMetaStasis, this.props.slide, this.state.selectedAnnotationId,
            this.onSelectAnnotationFromList, this.props.viewerSettingData, this.props.zoomScale, this.props.desiredZoomScale,
            this.onDeleteAnnotation, this.doRefreshAnnotationsFromBackendBrainMetastasis, this.props.urlState, false);

        let annotationsDivFullTissue = getAnnotationsInListView(this.state.annotationsFullTissue, this.props.slide, this.state.selectedAnnotationId,
            this.onSelectAnnotationFromList, this.props.viewerSettingData, this.props.zoomScale, this.props.desiredZoomScale,
            this.onDeleteAnnotation, this.doRefreshAnnotationsFromBackendFullTissue, this.props.urlState, true);

        let colors = AnnotationsConstants.COLORS_LIST;

        let brainMetaStasisTitle;
        if(this.state.magicToolFetching){
            brainMetaStasisTitle = <p style={{ textAlign: 'center' }}>Brain Metastasis <Spin /> </p>
        }else{
            brainMetaStasisTitle = <p style={{ textAlign: 'center' }}>Brain Metastasis</p>
        }
        return (
            <Row className={(this.props.extraClass === undefined ? "" : this.props.extraClass) + " annotations-app-parent overlayed-component app-parent"} id="annotations-app-parent-id">
                <Row>
                    <Col span={11}>
                        <Divider />
                        {/* <Row>
                        <Col span={10} offset={6}>
                        <CirclePicker width={'400px'} circleSize={18} colors={colors} 
                                      color={ this.state.selectedColor }
                                      onChangeComplete={(this.props.urlState || {}).presentCode != undefined ? null : this.handleAnnoColorChange }/>
                        </Col>
                    </Row> */}
                        {/* <Divider /> */}
                        <Row>
                            <p style={{ textAlign: 'center' }}>Brain Metastasis</p>
                            {/* {brainMetaStasisTitle} */}
                        </Row>
                        <Row>
                            {
                                drawingToolsBrainMetastasis.map((drawingTool, index) => {
                                    if (drawingTool.props.toolKey.name == "Freehand" || drawingTool.props.toolKey.name=="Magic Tool"
                                    || drawingTool.props.toolKey.name == "Modify")
                                        return <Col key={index} span={8} >
                                            {drawingTool}
                                        </Col>
                                })
                            }
                        </Row>
                        <Divider />
                        <Spin spinning={this.state.isFetching} tip={this.state.busyMessage}>
                            {this.props.showOptions ?
                                <Row>
                                    <Col>
                                        <Row>
{/* 
                                            <Col span={24} style={{ textAlign: 'center' }}>
                                                <Button size="small" onClick={this.toggleAnnotationLayer}>
                                                    {this.state.showAnnoLayer ? "HIDE ANNOTATIONS" : "SHOW ANNOTATIONS"}
                                                </Button>
                                            </Col> */}
                                        </Row>
                                    </Col>
                                </Row>
                                : undefined
                            }
                            <Row>
                                {annotationsDivBrainMetaStasis}
                            </Row>
                        </Spin>
                    </Col>
                    <Col span={1}>
                        <Divider type="vertical" style={{ color: 'black' }}></Divider>
                    </Col>
                    <Col span={11}>
                        <Divider />
                        <Row style={{ textAlign: 'center' }}>
                            <p>Full Tissue</p>
                        </Row>
                        <Row>
                            {
                                drawingToolsFullTissue.map((drawingTool, index) => {
                                    if (drawingTool.props.toolKey.name == "Freehand" || drawingTool.props.toolKey.name=="Magic Tool" || drawingTool.props.toolKey.name == "Modify")
                                        return <Col key={index} span={8}>
                                            {drawingTool}
                                        </Col>
                                })
                            }
                        </Row>
                        <Divider />
                        <Spin spinning={this.state.isFetching} tip={this.state.busyMessage}>
                            {this.props.showOptions ?
                                <Row >
                                    <Col>
                                        <Row>
                                            {/*                                             
                                            <Col span={24} style={{textAlign: 'center'}}>
                                                <span><b>Ratio:</b>{(fulltissue_area!=0 ?(metastasis_area / fulltissue_area).toPrecision(3):"   0")}</span>
                                            </Col> */}
                                        </Row>
                                    </Col>
                                </Row>
                                : undefined
                            }

                            <Row>
                                {annotationsDivFullTissue}
                            </Row>
                        </Spin>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <span><b>Ratio:</b>{(fulltissue_area != 0 ? "   " + String((metastasis_area / fulltissue_area).toPrecision(3)) : "   0")}</span>
                    </Col>
                </Row>
                <Divider />
                <Row style={{marginBottom:'10px'}}>
                    
                            <Col span={12} style={{ textAlign: 'center' }}>
                                <Button size="small" onClick={this.toggleAnnotationLayer}>
                                    {this.state.showAnnoLayer ? "HIDE ANNOTATIONS" : "SHOW ANNOTATIONS"}
                                </Button>
                            </Col>
                            <Col span={12} style={{textAlign: 'center'}}>
                                        <Link to={"/scan-hdd/morphle_test/" + this.props.slide.path + "ugc/shareable/" + "roi_info" + ".csv"}  target="_blank" download>
                                        <Button type="default" shape="round" icon="download" size={'small'}>
                                            Download CSV
                                        </Button></Link>
                                        </Col>
                </Row>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
        viewerSettingData: state.viewerSettingReducer,
        brainAnno: state.brainAnnoGridReducer,
    }
}

export default connect(mapStateToProps)(BrainAbstractAnnotator);
