import {CaseActions} from '../actionTypes/cases.constant';
import {searchFailed, searchInitiated} from "./search.case.action"
import {SearchAlertCaseConstant} from "../actionTypes/search.case.constant";
import axios from 'axios';
import {AuthHeader} from "../helper/auth.token"
import {message} from "antd";
import cookie from "react-cookies";
import querystring from "query-string";

export const changePage = (pageNumber) => ({
    type: CaseActions.CHANGE_PAGE_CASE,
    pageNumber: pageNumber
})

export const changeTotalNumberOfPages = (numPages) => ({
    type : CaseActions.CHANGE_TOTAL_NUMBER_OF_PAGES_CASE, 
    numPages: numPages
})

export const changeTotal = (total) => ({
    type : CaseActions.CHANGE_TOTAL_CASE, 
    total: total
})

export const allCasesLoadingSuccess = (success) => (dispatch) => {
    dispatch({
        type: CaseActions.ALL_CASES_UPDATE_SUCCESS,
        response: success
    });
    dispatch(changeTotalNumberOfPages(success.data.total_pages));
    dispatch(changePage(success.data.page_number));
    dispatch(changeTotal(success.data.count));
}

export const caseListUpdateSuccess = (id, updatedInfo) => ({
    type: CaseActions.CASE_LIST_UPDATE,
    updatedInfo: updatedInfo,
    id: id
})

export const caseListUpdateFailed = (id, error) => ({
    type: CaseActions.CASE_LIST_UPDATE_FAILED,
    response: error,
    id: id
})

export const deleteCase = (id) => ({
    type: CaseActions.CASE_LIST_DELETE,
    id: id
})


export const loadCases = (partsOfUrl) => dispatch => {
    dispatch(searchInitiated());

    var encodedUrl = urlEncoder(partsOfUrl);
    let url = `/api/case_read_status/` + encodedUrl;
    console.log("partsOfUrl",url)
    axios.get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
        .then(response => {
            if (response.status === 200) {
                dispatch(allCasesLoadingSuccess(response));
                dispatch({
                    type: SearchAlertCaseConstant.CASE_UPDATE_URL_STATE,
                    urlState: {
                        ...partsOfUrl,
                        isFetching: false,
                        page: response.data.page_number,
                        numPages: response.data.total_pages,
                        count: response.data.count
                    },
                });
            }
            else {
                dispatch(searchFailed(response));
            }
        })
        .catch(err => {
            dispatch(searchFailed(err));
        })
}


export const loadCaseStatus = () => dispatch => {
    let url = `/api/case_status/`;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
                dispatch({
                    type: CaseActions.UPDATE_CASE_STATUS,
                    status: response.data,
                });
            }
            else {
                dispatch(searchFailed(response));
            }
        })
        .catch(err => {
            dispatch(searchFailed(err));
        })
}


export const loadCaseIDs = () => dispatch => {
    let url = `/api/get_case_ids/`;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
                dispatch({
                    type: CaseActions.UPDATE_CASE_IDS,
                    case_ids: response.data.case_ids,
                });
            }
            else {
                dispatch(searchFailed(response));
            }
        })
        .catch(err => {
            dispatch(searchFailed(err));
        })
}


export const loadUsers = () => dispatch => {
    let url = `/api/user/`;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
                console.log("users++", response.data)
                dispatch({
                    type: CaseActions.UPDATE_USERS,
                    users: response.data,
                });
            }
            else {
                dispatch(searchFailed(response));
            }
        })
        .catch(err => {
            dispatch(searchFailed(err));
        })
}

export const caseUpdateAlert = (id, value, selectedRows = []) => (dispatch) => {
    let url = `/api/case/${id}/`
    axios.put(url, value, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200 || response.status === 301 || response.status === 302) {
                response.data.displayOrder = value.displayOrder;
                dispatch(caseListUpdateSuccess(id, response.data));
                if(selectedRows.length > 0 && id == selectedRows[selectedRows.length - 1]) {
                    message.success("Case/Cases Successfully Updated");
                }
            }
            else if (response.status === 403 || response.status === 401 || response.status === 400) {
                // dispatch(caseListUpdateFailed(id, response));
            }
        })
        .catch(err => {
            // dispatch(caseListUpdateFailed(id, err));
        });
}

// export const assignUsersUpdateAlert = (id, value, selectedRows = []) => (dispatch) => {
//     let url = `/api/assign_users_to_case/`;
    
//     axios.post(url, querystring.stringify(value), { headers: { Authorization: AuthHeader() } })
//         .then(response => {
//             if (response.status === 200 || response.status === 301 || response.status === 302) {
//                 dispatch(getCase(id, value, selectedRows));
//             }
//             else if (response.status === 403 || response.status === 401 || response.status === 400) {
//                 // dispatch(caseListUpdateFailed(id, response));
//             }
//         })
//         .catch(err => {
//             // dispatch(caseListUpdateFailed(id, err));
//         });
// }

export const changeCaseStatusOnUserChange = (id, assigned_users, value) => (dispatch) => {
    let url = `/api/change_case_status_on_user_change/`;
    axios.post(url, querystring.stringify({'case_id': id, 'assigned_users': assigned_users}), { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200 || response.status === 301 || response.status === 302) {
                dispatch(getCase(id, value));
            } else if (response.status === 403 || response.status === 401 || response.status === 400) {
                // dispatch(caseListUpdateFailed(id, response));
            }
        })
        .catch(err => {
            // dispatch(caseListUpdateFailed(id, err));
        });
}

export const assignUsersUpdateAlert = (ids, values, assigned_users, selectedRows = []) => (dispatch) => {
    let url = `/api/assign_users_to_case/`;
    axios.post(url, querystring.stringify({'ids':ids, 'assigned_users':assigned_users, 'notify_assigned_users':cookie.loadAll().notify_assigned_users}), { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200 || response.status === 301 || response.status === 302) {
                for(let i=0; i<ids.length; i++){
                    dispatch(getCase(ids[i], values[i], selectedRows));
                }
            }
            else if (response.status === 403 || response.status === 401 || response.status === 400) {
                // dispatch(caseListUpdateFailed(id, response));
            }
        })
        .catch(err => {
            // dispatch(caseListUpdateFailed(id, err));
        });
}

export const removeUserUpdateAlert = (id, value, urlState) => (dispatch) => {
    let url = `/api/remove_user_from_case/`;
    
    axios.post(url, querystring.stringify(value), { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200 || response.status === 301 || response.status === 302) {
                if((urlState.case_assigned || urlState.unread || urlState.read) && cookie.loadAll().user_id === value.user_id) {
                    dispatch(deleteCase(id));
                } else {
                    dispatch(getCase(id, value));
                }
                message.success("User successfully deleted from the case");
            }
            else if (response.status === 403 || response.status === 401 || response.status === 400) {
                // dispatch(caseListUpdateFailed(id, response));
            }
        })
        .catch(err => {
            // dispatch(caseListUpdateFailed(id, err));
        });
}

export const makeCaseRead = (id, updateFlag = false, value = {}) => (dispatch) => {
    let url = `/api/make_case_read/`;
    if(value.displayOrder) {
        value = {case_id: id, displayOrder: value.displayOrder};
    } else {
        value = {case_id: id};
    }
    axios.post(url, querystring.stringify(value), { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200 || response.status === 301 || response.status === 302) {
                if(updateFlag) {
                    dispatch(getCase(id, value));
                }
            }
            else if (response.status === 403 || response.status === 401 || response.status === 400) {
                // dispatch(caseListUpdateFailed(id, response));
            }
        })
        .catch(err => {
            // dispatch(caseListUpdateFailed(id, err));
        });
}

export const getCase = (id, value, selectedRows = []) => (dispatch) => {
    let url = `/api/case/${id}/`
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200 || response.status === 301 || response.status === 302) {
                response.data.displayOrder = value.displayOrder;
                dispatch(caseListUpdateSuccess(id, response.data));
                if(selectedRows.length > 0 && id == selectedRows[selectedRows.length - 1]) {
                    message.success("Case/Cases Successfully Updated");
                }
            }
            else if (response.status === 403 || response.status === 401 || response.status === 400) {
                // dispatch(caseListUpdateFailed(id, response));
            }
        })
        .catch(err => {
            // dispatch(caseListUpdateFailed(id, err));
        });
}

export const markCaseUnread = (curr_case) => dispatch => {
    let url = `/api/make_case_unread/?case_id=${curr_case.id}` 
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200 || response.status === 301 || response.status === 302) {
                dispatch(getCase(curr_case.id, curr_case));
            }
            else if (response.status === 403 || response.status === 401 || response.status === 400) {
                // dispatch(caseListUpdateFailed(id, response));
            }
        })
        .catch(err => {
            // dispatch(caseListUpdateFailed(id, err));
        });
}

export const markCaseAndSlidesRead = (curr_case) => dispatch => {
    let url = `/api/make_case_slides_read/?case_id=${curr_case.id}` 
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200 || response.status === 301 || response.status === 302) {
                dispatch(getCase(curr_case.id, curr_case));
            }
            else if (response.status === 403 || response.status === 401 || response.status === 400) {
                // dispatch(caseListUpdateFailed(id, response));
            }
        })
        .catch(err => {
            // dispatch(caseListUpdateFailed(id, err));
        });
}

export const urlEncoder = (partsOfUrl) => {
    console.log("xx", partsOfUrl)
    var encodedUrl = '?';
    if (partsOfUrl === undefined) {
        return encodedUrl;
    }
    if ('search' in partsOfUrl) {
        encodedUrl += partsOfUrl.search !== '' ? '&search=' + partsOfUrl.search : '';
    }
    if ('case_assigned' in partsOfUrl) {
        encodedUrl += partsOfUrl.case_assigned ? '&case_assigned=true' : '';
    }
    if ('unread' in partsOfUrl) {
        encodedUrl += partsOfUrl.unread ? '&unread=true' : '';
    }
    if ('read' in partsOfUrl) {
        encodedUrl += partsOfUrl.read ? '&read=true' : '';
    }
    if ('ordering' in partsOfUrl) {
        encodedUrl += '&ordering=' + partsOfUrl.ordering;
    }
    if ('page' in partsOfUrl) {
        encodedUrl += '&page=' + partsOfUrl.page;
    }
    if ('validation_id' in partsOfUrl){
        encodedUrl += '&validation_id=' + partsOfUrl.validation_id;
    }
    if ('type' in partsOfUrl){
        encodedUrl += '&type=' + partsOfUrl.type
    }
    if ('date__gte' in partsOfUrl) {
        encodedUrl += '&date__gte='+partsOfUrl.date__gte;
    }
    if ('date__lte' in partsOfUrl) {
        encodedUrl += '&date__lte='+partsOfUrl.date__lte;
    }
    return encodedUrl;
}
