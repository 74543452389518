import React from "react";
import SettingsIcon from '@mui/icons-material/Settings'
import GestureIcon from '@mui/icons-material/Gesture';
import SlideInfoApp from "./sidebarTop_apps/slide_info";
import GradingIcon from '@mui/icons-material/Grading';
import NavigationIcon from '@mui/icons-material/Navigation';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import {
	BorderColorRounded,
	Class,
	Crop169,
	ElectricalServicesTwoTone,
	Grid3x3,
	Info,
	KeyboardAltRounded,
	ScreenshotTwoTone,
	SettingsSuggestTwoTone,
} from "@mui/icons-material";
import {DeepBioIcon, IHCIcon, slideViewerType} from "../../utils/const";

import AnnotationsApp from "./sidebarTop_apps/annotationsApp";
import BloodAnnotationsApp from "./sidebarTop_apps/bloodApps/bloodAnnotationsApp";
import SettingsApp from "./sidebarTop_apps/settings";
import PreviewMap from "./sidebarBottom_apps/preview";
import PreviewIcon from '@mui/icons-material/Preview';
import Barcode from "./sidebarBottom_apps/barcode";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import GridOverlay from "./sidebarTop_apps/GridOverlay";
import Screenshot from "./sidebarTop_apps/Screenshot";
import Thumbnail from "./sidebarBottom_apps/thumbnail";
import DeepBioApp from "./sidebarTop_apps/deepBioApp";

import CliaApp from "./sidebarTop_apps/cliaApp";
import SlideNavigatorApp from "./sidebarTop_apps/slideNavigatorApp";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import IndxApp from "./sidebarTop_apps/indxApp";

import ZStackControl from "./rightBottom_apps/ZStackControlApp";
import ZoomControl from "./rightBottom_apps/zoomControl";
import Layers from "./rightBottom_apps/Layers";
import ShortcutsApp from "./sidebarTop_apps/Shortcuts";
import IHCApp from "./sidebarTop_apps/IHC";
import LabellingApp from "./sidebarTop_apps/Labelling";
import ConfigurationApp from "./sidebarTop_apps/Configuration";
import CaseInfo from "./sidebarTop_apps/CaseInfo";
import ReportApp from "./sidebarTop_apps/cap_report";
import ServicesApp from "./sidebarTop_apps/ServicesApp";

// Side Bar Top Apps

export const annotationsApp = {
	id: "annotations",
	component: <AnnotationsApp/>,//send your component
	defaultPermission: true,
	makeSideBarIcon: true,// show user without clicking
	icon: <GestureIcon sx={{fontSize: 'inherit'}}/>,// custom mui icon
	title: "Annotations", //will be shown inside all apps section only
	showTitleOnHover: false,//only works if "makeSideBarIcon=true" and title is given
};

const slideInfoApp = {
	id: "slide_info",
	component: <SlideInfoApp viewerType={slideViewerType.GAMMA_SLIDE_VIEWER}/>,
	defaultPermission: true,
	makeSideBarIcon: true,
	icon: <Info sx={{fontSize: 'inherit'}}/>,
	title: "Slide Info",
	showTitleOnHover: true,
};

const caseInfoApp = {
	id: "case_info",
	component: <CaseInfo/>,
	defaultPermission: false,
	makeSideBarIcon: true,
	icon: <Class sx={{fontSize: 'inherit'}}/>,
	title: "Case Info",
	showTitleOnHover: true,
};

export const capReport = {
	id: "cap_report",
	component: <ReportApp/>,
	defaultPermission: false,
	title: "Cap Report",
};

const settingsApp = {
	id: "settings",
	component: <SettingsApp/>,
	defaultPermission: true,
	makeSideBarIcon: true,
	icon: <SettingsIcon sx={{fontSize: 'inherit'}}/>,
	title: "Settings",
	showTitleOnHover: true,
};

const gridOverlayApp = {
	id: "grid-overlay",
	component: <GridOverlay/>,
	defaultPermission: true,
	makeSideBarIcon: false,
	icon: <Grid3x3 sx={{fontSize: 'inherit'}}/>,
	title: "Grid",
	showTitleOnHover: true,
};

const screenshotApp = {
	id: "screenshot",
	component: <Screenshot/>,
	defaultPermission: true,
	makeSideBarIcon: false,
	icon: <ScreenshotTwoTone sx={{fontSize: 'inherit'}}/>,
	title: "Screenshot",
	showTitleOnHover: true,
};

const shortcutsApp = {
	id: "shortcuts",
	component: <ShortcutsApp/>,
	defaultPermission: true,
	makeSideBarIcon: false,
	icon: <KeyboardAltRounded sx={{fontSize: 'inherit'}}/>,
	title: "Shortcuts",
	showTitleOnHover: true,
};

export const deepBioApp = {
	id: "deep_bio",
	component: <DeepBioApp/>,
	defaultPermission: false,
	makeSideBarIcon: true,
	icon: <DeepBioIcon sx={{filter: "brightness(100) contrast(100)"}}/>,
	title: "DeepBio",
	showTitleOnHover: true,
};

export const ihcApp = {
	id: "ihc",
	component: <IHCApp/>,
	defaultPermission: false,
	makeSideBarIcon: true,
	icon: <IHCIcon sx={{fontSize: 'inherit'}}/>,
	title: "IHC",
	showTitleOnHover: true,
};

export const labellingApp = {
    id: "labelling",
    component: <LabellingApp/>,
    defaultPermission: false,
    makeSideBarIcon: true,
    icon: <BorderColorRounded sx={{fontSize: 'inherit'}}/>,
    title: "Labelling",
    showTitleOnHover: true,
};

const cliaApp = {
	id: "clia",
	component: <CliaApp/>,
	defaultPermission: false,
	makeSideBarIcon: true,
	icon: <GradingIcon sx={{fontSize: 'inherit'}}/>,
	title: "Clia",
	showTitleOnHover: true,
};

const slideNavigationApp = {
	id: "slide_navigator",
	component: <SlideNavigatorApp/>,  //<SlideNavigatorApp />,
	defaultPermission: false,
	makeSideBarIcon: true,
	icon: <NavigationIcon sx={{fontSize: 'inherit'}}/>,
	title: "Slide Navigator",
	showTitleOnHover: true,
};

const servicesApp = {
	id: "services",
	component: <ServicesApp/>,
	defaultPermission: false,
	makeSideBarIcon: false,
	icon: <ElectricalServicesTwoTone sx={{fontSize: 'inherit'}}/>,
	title: "Services",
	showTitleOnHover: true,
};

const getIndxApp = () => {
	const id = "indx";
	return {
		id: id,
		component: <IndxApp/>,
		defaultPermission: false,
		makeSideBarIcon: false,
		icon: <DriveFileRenameOutlineIcon sx={{fontSize: 'inherit'}}/>,
		title: "Indx",
        showTitleOnHover: true,
    }
}

const getBloodAnnotationsApp = () => {
    const id = "blood_annotations";
    return {
        id: id,
        component: <BloodAnnotationsApp/>,
        defaultPermission: false,
        makeSideBarIcon: true,
        icon: <BloodtypeIcon sx={{fontSize: 'inherit'}}/>,
        title: "Blood Annotations",
        showTitleOnHover: true,
    }
}

export const configurationApp = ({
    id: "configuration",
    component: <ConfigurationApp/>,
    defaultPermission: false,
    makeSideBarIcon: true,
    icon: <SettingsSuggestTwoTone sx={{fontSize: 'inherit'}}/>,
    title: "Configuration",
    showTitleOnHover: true,
});

// Side Bar Bottom Apps

export const previewApp = {
	id: "preview-controls",
	component: <PreviewMap/>,
	defaultPermission: true,
	makeSideBarIcon: true,
	icon: <PreviewIcon sx={{fontSize: 'inherit'}}/>,
	title: "Preview",
	showTitleOnHover: true,
};

const thumbnailViewerApp = {
	id: "thumbnail",
	component: <Thumbnail/>,
	defaultPermission: true,
	makeSideBarIcon: true,
	icon: <Crop169 sx={{fontSize: 30}}/>,
	title: "Thumbnail",
	showTitleOnHover: true,
};

const barcodeApp = {
	id: "barcode",
	component: <Barcode/>,
	defaultPermission: true,
	makeSideBarIcon: true,
	icon: <QrCode2Icon sx={{fontSize: 'inherit'}}/>,
	title: "Barcode",
	showTitleOnHover: true,
};

// Right Bottom Apps

const layersApp = {
    id: "layers",
    component: <Layers opacity={1}/>,
    defaultPermission: true,
    title: "Layers",
    showTitleOnHover: true,
}

const zoomControlApp = {
    id: "zoomControl",
    component: <ZoomControl opacity={1}/>,
    defaultPermission: true,
    title: "Zoom Control",
    showTitleOnHover: true,
}

const zStackControlApp = {
    id: "zStackControl",
    component: <ZStackControl opacity={1}/>,
    defaultPermission: true,
    title: "Z Stack Control",
    showTitleOnHover: true,
}

export const getAllSideBarTopApps = () => {
    // now this should return a list of app components
    return [
		annotationsApp,
		settingsApp,
		slideInfoApp,
		caseInfoApp,
		gridOverlayApp,
		screenshotApp,
		cliaApp,
		slideNavigationApp,
		deepBioApp,
		ihcApp,
		servicesApp,
		getIndxApp(),
		getBloodAnnotationsApp(),
		labellingApp,
		shortcutsApp,
		configurationApp,
	];
}

export const getAllSideBarBottomApps = () => {
    return [
		previewApp,
		thumbnailViewerApp,
		barcodeApp,
	];
}

export const getAllRightBottomApps = () => {
    // app make in row reverse
    return [
        zStackControlApp,
        zoomControlApp,
        layersApp,
    ];
}
