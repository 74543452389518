import {BloodGridActions} from "../actionTypes/bloodGrid.constant";

export const reset = () => ({
    type : BloodGridActions.BLOOD_FILTER_DEFAULT,
  });

export const bloodClassChanged = (value) => ({
    type : BloodGridActions.BLOOD_CLASS_CHANGED,
    response: value
  });

export const bloodTagChanged = (value) => ({
    type : BloodGridActions.BLOOD_TAG_CHANGED,
    response: value 
});

export const bloodAnnoSelected = (value) => ({
    type : BloodGridActions.BLOOD_ANNO_SELECTED,
    response : value
});

export const bloodAnnoMove = () => ({
    type : BloodGridActions.BLOOD_ANNO_MOVE,
  });

export const bloodAnnoStopMove = () => ({
    type : BloodGridActions.BLOOD_ANNO_STOP_MOVE,
});

export const updateSummary = (value) => ({
    type : BloodGridActions.BLOOD_ANNO_UPDATE_SUMMARY,
    response: value,
})

export const updateWBCPageSize = (value)=> ({
    type : BloodGridActions.UPDATE_WBC_PAGE_SIZE,
    response: value
}) 

export const updatePage = (value) => ({
    type: BloodGridActions.UPDATE_PAGE,
    response: value
})

export const gridSelected = (value) => ({
    type: BloodGridActions.GRID_SELECTED,
    response: value,
})
