export const WBCViewActions = {
    FILTER_GRID_SELECTED:"FILTER_GRID_SELECTED",
    FILTER_CHANGE_IMAGE:"FILTER_CHANGE_IMAGE",
    UPDATE_PAGE: "UPDATE_PAGE", 
    UPDATE_PAGE_SIZE: "UPDATE_PAGE_SIZE",
    UPDATE_ALL_GRIDS:"UPDATE_ALL_GRIDS",
    UPDATE_Z_STACK:"UPDATE_Z_STACK",
    RESET_Z_STACK:"RESET_Z_STACK",
    RESET:"RESET",
    CHANGE_TAG: "CHANGE_TAG",
    UPDATE_SUMMARY: "UPDATE_SUMMARY"
} 