import React, {Component} from "react";
import queryString from "query-string";
import axios from "axios";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {AuthHeader} from "../helper/auth.token";
import {closeDrawer} from "../action/dashboard.action";
import {makeSlideRead} from "../action/slides.action";
import {Button, Col, Input, message, Progress, Row, Spin, Tooltip, Typography} from "antd";
import Popover from "@mui/material/Popover";
import {bloodClass, globalUrlPrefix, MorphleLogoBW} from "../utils/const";
import {logout} from "../action/login.action";
import BloodSummary from "./bloodsummary";
import {BloodGraph} from "./bloodgraphview";
import {bloodClassChanged} from "../action/bloodGrid.action";
import {resetWBC, resetZStackWBC, updatePageWBCView, wbcGridSelected,} from "../action/wbcview.action";
import {fieldGridSelected, reset, resetZStack, updatePageFieldView,} from "../action/fieldView.action";
import {FieldGridView} from "./fieldegridview";
import {Field100xView} from "./field100xView";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {
	CheckOutlined,
	EditOutlined,
	FundViewOutlined,
	LoadingOutlined,
	PicRightOutlined,
	PictureOutlined,
} from "@ant-design/icons";
import {FaSortAmountDown, FaSortAmountUp} from "react-icons/fa";
import "antd/dist/antd.css";
import "../asset/style/workflow/tab-pane.css";
import {
	rbcGridSelected,
	updateFetchingGridRBC,
	updatePageRBCView,
	updateSelectedGridOverallId,
} from "../action/bloodGridRBC.action";
import {
	pltGridSelected,
	updateFetchingGridPLT,
	updatePagePLT,
	updateSelectedGridOverallIdPLT,
} from "../action/bloodGridPLT.action";
import {FieldGridViewConfused} from "./fieldegridviewconfused";
import {FieldGridViewRbc} from "./fieldegridviewrbc";
import {FieldGridViewPlt} from "./fieldegridviewplt";
import {calculateClientHeightRatio, openEndPoint} from "../utils/utils";
import AnnotationsPaginatedGrid from "../component/gammaviewer/components/AnnotationsPaginatedGrid";
import SlideMap from "../component/gammaviewer/slidemap";
import {updateLastMapCount} from "../action/gamma.state.action";
import BloodSlideMap from "../component/neoviewer/bloodslidemap";
import SettingsApp from "../component/gammaviewer/sidebarTop_apps/settings";
import {Brightness6, SettingsBrightness, SettingsSuggestTwoTone} from "@mui/icons-material";
import ConfigurationApp from "../component/gammaviewer/sidebarTop_apps/Configuration";
import BloodSettings from "../component/gammaviewer/components/BloodSettings";
import {loadViewerSettings} from "../action/viewer.settings.actions";

// const {TabPane} = Tabs;
const {Text} = Typography;

export class BloodScan extends Component {
	constructor(props) {
		super(props);

		this.props.dispatch(loadViewerSettings());

		this.state = {
			stitching_done: true,
			stitch_percent: 0.0,
			stitch_time: 0.0,
			scan_ongoing: false,
			isFetching: true,
			errorCount: 0,
			blood_class: bloodClass.FIELD,
			navigate: false,
			selected_anno: "",
			view_mode: 1,
			slide_name: "",
			currZStackIndex: "",
			differential: false,
			confirmed: true,
			nameEditable: false,
			slide_data: "",
			size_asc: true,
			uneven_asc: false,
			elongation_asc: false,
			plt_size_asc: true,
			mean_color_asc: false,
			settingsAnchorEl: null,
		};

		this.max_txty_time = 0;
		this.timer = null;
		this.query_params = queryString.parse(this.props.location.search);
		this.slide_id = this.props.match.params.id;

		this.mapId = '0'
	}

	keydownListener = (event) => {
		if (event.shiftKey) return
		const key = event.keyCode

		switch (key) {
			case 39:
				this.loadNext(0)
				break
			case 37:
				this.loadPrev(0)
				break
			case 38:
				this.loadPrev(1)
				break
			case 40:
				this.loadNext(1)
				break
			default:
				return
		}
	}

	componentDidMount = () => {
		this.props.dispatch(makeSlideRead(this.props.match.params.id));
		this.pollForStitchingStatus();
		this.timer = setInterval(() => this.pollForStitchingStatus(), 2000);
		this.props.dispatch(closeDrawer());
		this.getSlideData();

		document.addEventListener("keydown", this.keydownListener);
	};

	getSlideData = () => {
		let slideUrl = `/api/slide/${this.props.match.params.id}/`;
		let headers = {
			headers: {
				Authorization: AuthHeader(),
			},
		};
		axios.get(slideUrl, headers).then((res) => {
			console.log("slidedata2", res.data);
			this.setState({
				slide_data: res.data,
				slide_name: res.data["name"],
				differential: res.data["differential"],
			});
		});
	};

	setSlideName = (slidename) => {
		let val = {
			slidename: slidename,
			slide_id: this.props.match.params.id,
		};
		let url = `/api/set_slide_name/`;
		axios
			.post(url, queryString.stringify(val), {
				headers: {Authorization: AuthHeader()},
			})
			.then((response) => {
				if (
					response.status === 200 ||
					response.status === 301 ||
					response.status === 302
				) {
					message.success("Name updated successfully", 1.5);
				} else {
					message.info("Failed to edit Name");
				}
			});
	};

	componentDidUpdate = (prevProps) => {
		if (this.state.errorCount === 3) {
			this.props.dispatch(logout());
		}
		if (prevProps.wbcGridData.tag !== this.props.wbcGridData.tag) {
			this.setState({
				confirmed: true,
			});
		}
	};

	pollForStitchingStatus = () => {
		this.setState({
			isFetching: true,
		});

		let url = "/server/slide_status/?id=" + this.slide_id;
		axios
			.get(url, {headers: {Authorization: AuthHeader()}})
			.then((response) => {
				let json_data = JSON.parse(response.data);
				if (json_data.status === 0) {
					this.setState({
						scan_ongoing: true,
						stitching_done: false,
						isFetching: false,
					});
				}
				if (json_data.status === 12 || json_data.status === 9) {
					this.setState({
						stitching_done: true,
						scan_ongoing: false,
						isFetching: false,
					});
					clearInterval(this.timer);
					this.timer = null;
				} else {
					let txty_time = parseFloat(json_data.txty_time) / 60;
					this.max_txty_time = Math.max(this.max_txty_time, txty_time);
					let stitcher_percent = json_data.stitcher_percent;
					let stitcher_time = json_data.stitcher_estimated_time / 60000;
					if (stitcher_time === 0) {
						stitcher_time = this.max_txty_time;
					}
					let final_percent = parseFloat(stitcher_percent).toFixed(2);
					let final_time = stitcher_time.toFixed(2);
					let time_left = Date.now() + final_time * 60 * 1000;
					this.setState({
						stitch_percent: final_percent,
						stitch_time: time_left,
						stitching_done: false,
						isFetching: false,
					});
				}
			})
			.catch((err) => {
				this.setState({
					errorCount: this.state.errorCount + 1,
				});
				console.log("Failed Getting Value", err);
			});
	};

	componentWillUnmount = () => {
		clearInterval(this.timer);
		this.timer = null;

		document.removeEventListener("keydown", this.keydownListener);
	};

	onTabChange = (key) => {
		switch (key) {
			default:
			case 0:
				if (this.state.blood_class === bloodClass.FIELD) {
					return;
				}
				this.props.dispatch(resetWBC());
				this.props.dispatch(resetZStackWBC());
				this.setState({
					blood_class: bloodClass.FIELD,
				});
				break;
			case 1:
				if (this.state.blood_class === bloodClass.WBC) {
					return;
				}
				this.setState({
					blood_class: bloodClass.WBC,
				});
				this.props.dispatch(reset());
				this.props.dispatch(resetZStack());
				this.props.dispatch(bloodClassChanged(bloodClass.WBC));
				break;
			case 2:
				if (this.state.blood_class === bloodClass.RBC) {
					return;
				}
				this.props.dispatch(resetWBC());
				this.props.dispatch(resetZStackWBC());
				this.props.dispatch(reset());
				this.props.dispatch(resetZStack());
				this.setState({
					blood_class: bloodClass.RBC,
				});
				break;
			case 3:
				if (this.state.blood_class === bloodClass.PLT) {
					return;
				}
				this.props.dispatch(resetWBC());
				this.props.dispatch(resetZStackWBC());
				this.setState({
					blood_class: bloodClass.PLT,
				});
				break;
		}
	};

	onTabChangeNonDiff = (key) => {
		if (key === 0) {
			if (this.state.blood_class === bloodClass.WBC) {
				return;
			}
			this.setState({
				blood_class: bloodClass.WBC,
			});
			this.props.dispatch(reset());
			this.props.dispatch(resetZStack());
			this.props.dispatch(bloodClassChanged(bloodClass.WBC));
		} else if (key === 1) {
			if (this.state.blood_class === bloodClass.FIELD) {
				return;
			}
			this.props.dispatch(resetWBC());
			this.props.dispatch(resetZStackWBC());
			this.setState({
				blood_class: bloodClass.FIELD,
			});
		}
	};

	changeViewMode = (mode) => {
		this.setState({
			view_mode: parseInt(mode),
		});
		this.props.dispatch(updateLastMapCount());
	};

	calcNewCount = (shiftColumn, rowLength, currCount, isNext) => {
		let sign = 1;
		let pageSize = this.props.wbcGridData.page_size;
		if (!isNext) {
			sign = -1;
		}
		let newCount;
		if (shiftColumn) {
			let end = false;
			if (isNext) {
				let temp = currCount % pageSize;
				if (temp === 0 || (temp < pageSize && temp > pageSize - rowLength)) {
					end = true;
				}
			} else {
				let temp = currCount % pageSize;
				if (temp >= 1 && temp <= rowLength) {
					end = true;
				}
			}
			if (!end) {
				newCount = currCount + sign * rowLength;
			} else {
				newCount = currCount;
			}
		} else {
			newCount = currCount + sign;
		}
		return newCount;
	};

	load = (grid) => {
		let anno_id = grid["id"];
		let slide_id = this.slide_id;
		if (this.state.blood_class === bloodClass.FIELD) {
			let page_size = this.props.fieldGridData.page_size;
			let url = `/api/get_selected_field_anno_page/?anno_id=${anno_id}&slide_id=${slide_id}&page_size=${page_size}`;
			axios.get(url).then((res) => {
				this.props.dispatch(updatePageFieldView(res.data["page"]));
				this.props.dispatch(fieldGridSelected(grid));
			});
		} else if (this.state.blood_class === bloodClass.WBC) {
			let page_size = this.props.wbcGridData.page_size;
			let page = Math.ceil(grid.count / page_size);
			this.props.dispatch(updatePageWBCView(page));
			this.props.dispatch(wbcGridSelected(grid));
		}
	};

	loadNext = (shiftColumn) => {
		let currCount;
		let newCount;
		let all_grids;
		let rowLength =
			this.state.blood_class === bloodClass.RBC ||
			this.state.blood_class === bloodClass.PLT
				? 8
				: 3;
		if (this.state.blood_class === bloodClass.FIELD) {
			currCount = this.props.fieldGridData.selected_grid.overall_count;
			newCount = this.calcNewCount(shiftColumn, rowLength, currCount, 1);
			all_grids = this.props.fieldGridData.all_grids;
		} else if (this.state.blood_class === bloodClass.WBC) {
			currCount = this.props.wbcGridData.selected_grid.overall_count;
			newCount = this.calcNewCount(shiftColumn, rowLength, currCount, 1);
			all_grids = this.props.wbcGridData.all_grids;
		} else if (this.state.blood_class === bloodClass.RBC) {
			let curr_grids = this.props.bloodGridRBCData.curr_grids;
			currCount = this.props.bloodGridRBCData.selected_grid.overall_count;
			newCount = this.calcNewCount(shiftColumn, rowLength, currCount, 1);
			let found = false;
			for (let i = 0; i < curr_grids.length; i++) {
				if (parseInt(curr_grids[i].overall_count) === newCount) {
					this.props.dispatch(rbcGridSelected(curr_grids[i]));
					found = true;
				}
			}
			if (
				!found &&
				newCount < this.props.bloodGridRBCData.total_count &&
				this.props.bloodGridRBCData.page <
				this.props.bloodGridRBCData.total_pages &&
				!this.props.bloodGridRBCData.fetching_grid
			) {
				let page_size = this.props.bloodGridRBCData.page_size;
				let newPage = Math.ceil((currCount + 1) / page_size);
				this.props.dispatch(updateSelectedGridOverallId(newCount));
				this.props.dispatch(updatePageRBCView(newPage));
				this.props.dispatch(updateFetchingGridRBC(true));
			}

			return;
		} else if (this.state.blood_class === bloodClass.PLT) {
			let curr_grids = this.props.bloodGridPLTData.curr_grids;
			currCount = this.props.bloodGridPLTData.selected_grid.overall_count;
			newCount = this.calcNewCount(shiftColumn, rowLength, currCount, 1);
			let found = false;
			for (let i = 0; i < curr_grids.length; i++) {
				if (parseInt(curr_grids[i].overall_count) === newCount) {
					this.props.dispatch(pltGridSelected(curr_grids[i]));
					found = true;
				}
			}
			if (
				!found &&
				newCount < this.props.bloodGridPLTData.total_count &&
				this.props.bloodGridPLTData.page <
				this.props.bloodGridPLTData.total_pages &&
				!this.props.bloodGridPLTData.fetching_grid
			) {
				let page_size = this.props.bloodGridPLTData.page_size;
				let newPage = Math.ceil((currCount + 1) / page_size);
				this.props.dispatch(updateSelectedGridOverallIdPLT(newCount));
				this.props.dispatch(updatePagePLT(newPage));
				this.props.dispatch(updateFetchingGridPLT(true));
			}
			return;
		}

		if (newCount <= all_grids.length) {
			this.load(all_grids[newCount - 1]);
		}
	};

	loadPrev = (shiftColumn) => {
		let currCount;
		let newCount;
		let all_grids;
		let rowLength =
			this.state.blood_class === bloodClass.RBC ||
			this.state.blood_class === bloodClass.PLT
				? 8
				: 3;
		if (this.state.blood_class === bloodClass.FIELD) {
			currCount = this.props.fieldGridData.selected_grid.overall_count;
			newCount = this.calcNewCount(shiftColumn, rowLength, currCount, 0);
			all_grids = this.props.fieldGridData.all_grids;
		} else if (this.state.blood_class === bloodClass.WBC) {
			currCount = this.props.wbcGridData.selected_grid.overall_count;
			newCount = this.calcNewCount(shiftColumn, rowLength, currCount, 0);
			all_grids = this.props.wbcGridData.all_grids;
		} else if (this.state.blood_class === bloodClass.RBC) {
			let curr_grids = this.props.bloodGridRBCData.curr_grids;
			currCount = this.props.bloodGridRBCData.selected_grid.overall_count;
			newCount = this.calcNewCount(shiftColumn, rowLength, currCount, 0);
			let found = false;
			for (let i = 0; i < curr_grids.length; i++) {
				if (parseInt(curr_grids[i].overall_count) === newCount) {
					this.props.dispatch(rbcGridSelected(curr_grids[i]));
					found = true;
				}
			}
			if (
				!found &&
				newCount >= 1 &&
				this.props.bloodGridRBCData.page > 1 &&
				!this.props.bloodGridRBCData.fetching_grid
			) {
				let page_size = this.props.bloodGridRBCData.page_size;
				let newPage = Math.ceil((currCount - 1) / page_size);
				this.props.dispatch(updatePageRBCView(newPage));
				this.props.dispatch(updateSelectedGridOverallId(newCount));
				this.props.dispatch(updateFetchingGridRBC(true));
			}
			return;
		} else if (this.state.blood_class === bloodClass.PLT) {
			let curr_grids = this.props.bloodGridPLTData.curr_grids;
			currCount = this.props.bloodGridPLTData.selected_grid.overall_count;
			newCount = this.calcNewCount(shiftColumn, rowLength, currCount, 0);
			let found = false;
			for (let i = 0; i < curr_grids.length; i++) {
				if (parseInt(curr_grids[i].overall_count) === newCount) {
					this.props.dispatch(pltGridSelected(curr_grids[i]));
					found = true;
				}
			}
			if (
				!found &&
				newCount < this.props.bloodGridPLTData.total_count &&
				this.props.bloodGridPLTData.page < this.props.bloodGridPLTData.total_pages &&
				!this.props.bloodGridPLTData.fetching_grid
			) {
				let page_size = this.props.bloodGridPLTData.page_size;
				let newPage = Math.ceil((currCount - 1) / page_size);
				this.props.dispatch(updateSelectedGridOverallIdPLT(newCount));
				this.props.dispatch(updatePagePLT(newPage));
				this.props.dispatch(updateFetchingGridPLT(true));
			}
			return;
		}

		if (newCount > 0) {
			this.load(all_grids[newCount - 1]);
		}
	};

	onTabChangeWBCTabs = (index) => {
		if (index === 0) {
			this.setState({
				confirmed: true,
			});
		} else {
			this.setState({
				confirmed: false,
			});
		}
	};

	setName = (val) => {
		this.setState({slide_name: val});
	};

	getBloodSettingsComponent = () => {
		if (this.state.view_mode === 3) return null;
		return <Tooltip title={"100X Viewer Settings"} placement={"right"}>
			<Button
				onClick={e => this.setState({
					bloodSettingsAnchorEl: e.currentTarget,
				})}
				style={{
					backgroundColor: "rgba(0,0,0,0)",
					borderColor: "rgba(0,0,0,0)",
					position: "absolute",
					left: "0px",
					bottom: "50px",
					width: "3vw",
					color: "white",
					padding: 0,
					height: "fit-content",
				}}
			>
				<Brightness6 style={{fontSize: "1.7vw"}}/>
			</Button>
		</Tooltip>
	};

	getBloodSettingsMenu = () => {
		let open = Boolean(this.state.bloodSettingsAnchorEl);
		if (this.state.view_mode === 3 || !open) return null;
		return (
			<Popover
				open={open}
				anchorEl={this.state.bloodSettingsAnchorEl}
				onClose={() => this.setState({
					bloodSettingsAnchorEl: null,
				})}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<div
					style={{
						backgroundColor: "#548fa1",
						width: "350px",
						height: "220px",
						padding: "15px",
					}}
				>
					<BloodSettings/>
				</div>
			</Popover>
		);
	};

	getGammaSettingsComponent = () => {
		if (this.state.view_mode === 3) return null;
		return <Tooltip title={"20X Viewer Settings"} placement={"right"}>
			<Button
				onClick={e => this.setState({
					settingsAnchorEl: e.currentTarget,
				})}
				style={{
					backgroundColor: "rgba(0,0,0,0)",
					borderColor: "rgba(0,0,0,0)",
					position: "absolute",
					left: "0px",
					bottom: "100px",
					width: "3vw",
					color: "white",
					padding: 0,
					height: "fit-content",
				}}
			>
				<SettingsBrightness style={{fontSize: "1.7vw"}}/>
			</Button>
		</Tooltip>

	};

	getGammaSettingsMenu = () => {
		let open = Boolean(this.state.settingsAnchorEl);
		if (this.state.view_mode === 3 || !open) return null;
		return (
			<Popover
				open={open}
				anchorEl={this.state.settingsAnchorEl}
				onClose={() => this.setState({
					settingsAnchorEl: null,
				})}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<div
					style={{
						backgroundColor: "#548fa1",
						width: "350px",
						height: "600px",
						padding: "15px",
					}}
				>
					<SettingsApp/>
				</div>
			</Popover>
		);
	};

	getConfigurationComponent = () => {
		if (this.state.view_mode === 3) return null;
		return <Tooltip title={"Configurations"} placement={"right"}>
			<Button
				onClick={e => this.setState({
					configurationAnchorEl: e.currentTarget,
				})}
				style={{
					backgroundColor: "rgba(0,0,0,0)",
					borderColor: "rgba(0,0,0,0)",
					position: "absolute",
					left: "0px",
					bottom: "150px",
					width: "3vw",
					color: "white",
					padding: 0,
					height: "fit-content",
				}}
			>
				<SettingsSuggestTwoTone style={{fontSize: "1.7vw"}}/>
			</Button>
		</Tooltip>
	}

	getConfigurationMenu = () => {
		let open = Boolean(this.state.configurationAnchorEl);
		if (this.state.view_mode === 3 || !open) return null;
		return (
			<Popover
				open={open}
				anchorEl={this.state.configurationAnchorEl}
				onClose={() => this.setState({
					configurationAnchorEl: null,
				})}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<div
					style={{
						backgroundColor: "#548fa1",
						width: "350px",
						padding: "15px",
					}}
				>
					<ConfigurationApp/>
				</div>
			</Popover>
		);
	}

	getGammaLink = () => {
		console.log("checkgamma")
		if (this.state.view_mode === 3) return null;
		return <Tooltip title={"Go to WSI viewer"} placement={"right"}>
			<Button
				onClick={() => openEndPoint(`/gamma-viewer/${this.slide_id}/`, true)}
				style={{
					backgroundColor: "rgba(0,0,0,0)",
					borderColor: "rgba(0,0,0,0)",
					position: "absolute",
					left: "0px",
					bottom: "0px",
					width: "3vw",
					color: "white",
					padding: 0,
					height: "fit-content",
				}}
			>
				<FundViewOutlined style={{fontSize: "1.7vw"}}/>
			</Button>
		</Tooltip>
	}

	getSortIcon = (isAsc) => {
		if (isAsc) {
			return <FaSortAmountUp style={{fontSize: "1vw", color: "#00F548"}}/>;
		}
		return <FaSortAmountDown style={{fontSize: "1vw", color: "#00F548"}}/>;
	};

	onClickSort = (stateToBeChanged, val) => {
		this.setState({
			[stateToBeChanged]: val,
		});
	};

	getHeader = () => {
		let nameComponent;
		if (!this.state.nameEditable) {
			nameComponent = (
				<React.Fragment>
					<Text code style={{color: "cyan"}}>
						<b>{this.state.slide_name}</b>
					</Text>
					<Tooltip title="Edit Name">
						<Button
							onClick={() => {
								this.setState({nameEditable: true});
							}}
							style={{
								backgroundColor: "rgba(0,0,0,0)",
								borderColor: "rgba(0,0,0,0)",
							}}
						>
							<EditOutlined
								style={{color: "rgba(255,255,255,0.3)", fontSize: "0.9vw"}}
							/>
						</Button>
					</Tooltip>
				</React.Fragment>
			);
		} else {
			nameComponent = (
				<React.Fragment>
					<Input
						autoFocus
						code
						onChange={(e) => this.setName(e.target.value)}
						value={this.state.slide_name}
						bordered={false}
						onPressEnter={() => {
							this.setState({nameEditable: false});
							this.setSlideName(this.state.slide_name);
						}}
						style={{
							textAlign: "center",
							fontSize: "0.9vw",
							width: "20vw",
							backgroundColor: "rgb(3, 17, 44)",
							borderColor: "white",
							color: "white",
						}}
					/>
					<Button
						style={{
							backgroundColor: "rgba(0,0,0,0)",
							borderColor: "rgba(0,0,0,0)",
						}}
						onClick={() => {
							this.setState({nameEditable: false});
							this.setSlideName(this.state.slide_name);
						}}
					>
						<CheckOutlined style={{color: "#00FF00", fontSize: "0.9vw"}}/>
					</Button>
				</React.Fragment>
			);
		}

		let viewModeToggleButton;
		if (this.state.view_mode === 1) {
			viewModeToggleButton = <Button
				style={{
					border: "0px",
					marginTop: "0.4vh",
					backgroundColor: "#0b1c3b",
				}}
				onClick={() => this.changeViewMode(3)}
			>
				<PictureOutlined style={{fontSize: "2.5vh", color: "cyan"}}/>
			</Button>
		} else {
			viewModeToggleButton = <Button
				style={{
					border: "0px",
					marginTop: "0.4vh",
					backgroundColor: "#0b1c3b",
				}}
				onClick={() => this.changeViewMode(1)}
			>
				<PicRightOutlined style={{fontSize: "2.5vh", color: "cyan"}}/>
			</Button>
		}

		return (
			<Row
				style={{
					textAlign: "center",
					backgroundColor: "#0b1c3b",
					height: "4.3vh",
				}}
			>
				<Col span={3} style={{textAlign: "left"}}>
					<Link to={"/" + globalUrlPrefix + "/dashboard"}>
						<img
							src={MorphleLogoBW}
							alt={"logo"}
							style={{
								height: "3vh",
								marginLeft: "0.3vw",
								marginTop: "0.5vh",
							}}
						></img>
					</Link>
				</Col>
				<Col span={18} style={{marginTop: "-0.1vh", fontSize: "2.8vh"}}>
					{nameComponent}
				</Col>
				<Col span={3} style={{textAlign: "right"}}>
					{viewModeToggleButton}
				</Col>
			</Row>
		);
	};

	getNonDifferentialView = () => {
		let differential = this.state.differential;

		return (

			<Tabs
				onSelect={(index) => {
					this.onTabChangeNonDiff(index);
				}}
			>
				<TabList>
					<Tab>
						<p>WBC</p>
					</Tab>
					<Tab>
						<p>FLD</p>
					</Tab>
				</TabList>

				<TabPanel
					style={this.state.blood_class === bloodClass.WBC ? {width: "100%"} : {}}
				>
					<Col
						span={this.state.view_mode === 1 ? 6 : this.state.view_mode === 2 ? 7 : this.state.view_mode === 3 ? 0 : 7}
					>
						<FieldGridView
							slide_id={this.slide_id}
							dispatch={this.props.dispatch}
							view_mode={this.state.view_mode}
							selected={this.props.wbcGridData.selected_grid}
							page={this.props.wbcGridData.page}
							fieldGridData={this.props.fieldGridData}
							mode={bloodClass.WBC}
							wbcGridData={this.props.wbcGridData}
							differential={differential}
							cssfilter={this.props.bloodViewerSetting}
						/>
					</Col>
					<Col
						span={this.state.view_mode === 1 ? 18 : this.state.view_mode === 2 ? 17 : this.state.view_mode === 3 ? 0 : 7}
						style={{paddingTop: "0.5vh"}}
					>
						<Field100xView
							selected={this.props.wbcGridData.selected_grid}
							view_mode={this.state.view_mode}
							dispatch={this.props.dispatch}
							all_grids={this.props.wbcGridData.all_grids}
							slide_id={this.slide_id}
							fieldGridData={this.props.wbcGridData}
							mode={bloodClass.WBC}
							differential={differential}
							cssfilter={this.props.bloodViewerSetting}
						/>
					</Col>
				</TabPanel>
				<TabPanel
					style={this.state.blood_class === bloodClass.FIELD ? {width: "100%"} : {}}
				>
					<Col
						style={{marginTop: "-0.5vh"}}
						span={this.state.view_mode === 1 ? 6 : this.state.view_mode === 2 ? 7 : this.state.view_mode === 3 ? 0 : 7}
					>
						<FieldGridView
							slide_id={this.slide_id}
							dispatch={this.props.dispatch}
							view_mode={this.state.view_mode}
							selected={this.props.fieldGridData.selected_grid}
							page={this.props.fieldGridData.page}
							fieldGridData={this.props.fieldGridData}
							mode={bloodClass.FIELD}
							differential={differential}
							cssfilter={this.props.bloodViewerSetting}
						/>
					</Col>
					<Col
						style={{paddingTop: "0.5vh"}}
						span={this.state.view_mode === 1 ? 18 : this.state.view_mode === 2 ? 17 : this.state.view_mode === 3 ? 0 : 7}
					>
						<Field100xView
							selected={this.props.fieldGridData.selected_grid}
							view_mode={this.state.view_mode}
							dispatch={this.props.dispatch}
							all_grids={this.props.fieldGridData.all_grids}
							slide_id={this.slide_id}
							fieldGridData={this.props.fieldGridData}
							mode={bloodClass.FIELD}
							differential={differential}
							cssfilter={this.props.bloodViewerSetting}
						/>
					</Col>
				</TabPanel>
			</Tabs>
		);
	};

	getSlideMap = () => {
		if (this.state.blood_class === bloodClass.FIELD)
			return <SlideMap
				key={`map-${this.mapId}`}
				height={calculateClientHeightRatio(0.9)}
				slide_id={this.slide_id}
				map_id={this.mapId}
				showInFocus={true}
				showLarge={this.state.view_mode !== 1}
			/>;

		let zooms = (this.state.slide_data || {}).z_levels;
		zooms = zooms ? zooms.split(",") : [];
		let zoomMax = 0;
		if (zooms !== undefined && zooms.length !== 0) {
			zoomMax = zooms.pop();
		}
		zoomMax = parseInt(zoomMax);
		return <BloodSlideMap
			key="bloodSlideMap"
			slide_id={this.slide_id}
			map_id={0}
			view_mode={this.state.view_mode}
			field_view={true}
			mode={this.state.blood_class}
			differential={this.state.differential}
			confirmed={this.state.confirmed}
			maxZoomLevel={zoomMax}
			styleSelector={"slide-map-container-wbc"}
		/>;
	}

	getDifferentialView = () => {
		let antIcon = (
			<LoadingOutlined
				style={{fontSize: 50, marginTop: "19vh", marginLeft: "-1.3vw"}}
				spin
			/>
		);
		let differential = this.state.differential;

		return (

			<Tabs onSelect={index => this.onTabChange(index)}>
				<TabList>
					<Tab><p>FLD</p></Tab>
					<Tab><p>WBC</p></Tab>
					<Tab><p>RBC</p></Tab>
					<Tab><p>PLT</p></Tab>
				</TabList>

				<TabPanel style={this.state.blood_class === bloodClass.FIELD ? {width: "88%", height: '91.5vh'} : {}}>
					<AnnotationsPaginatedGrid
						mapId={this.mapId}
						view_mode={this.state.view_mode}
						dispatch={this.props.dispatch}
						all_grids={this.props.wbcGridData.all_grids}
						slide_id={this.slide_id}
						fieldGridData={this.props.wbcGridData}
						mode={bloodClass.WBC}
						differential={differential}
						loadNext={this.loadNext}
						loadPrev={this.loadPrev}						
						annotations={this.fieldAnnotations}
						row={2} col={1}
						cssfilter={this.props.bloodViewerSetting}
					/>
				</TabPanel>

				<TabPanel style={this.state.blood_class === bloodClass.WBC ? {width: "100%"} : {}}>
					<Col style={{paddingTop: "0.5vh"}}
					     span={this.state.view_mode === 1 ? 5 : this.state.view_mode === 2 ? 7 :
						     this.state.view_mode === 3 ? 0 : 7}>
						<BloodSummary class={bloodClass.WBC} slide_id={this.slide_id}
						              wbcGridData={this.props.wbcGridData}
						              update_list={this.props.bloodGridData.update_list}
						              tag={this.props.wbcGridData.tag}/>
					</Col>
					<Col style={{paddingTop: "0vh", marginLeft: "1vw"}} span={this.state.view_mode === 1 ? 6 :
						this.state.view_mode === 2 ? 7 : this.state.view_mode === 3 ? 0 : 7}>
						{this.props.wbcGridData.tag === "" ?
							<FieldGridView slide_id={this.slide_id} dispatch={this.props.dispatch}
										   view_mode={this.state.view_mode}
										   selected={this.props.wbcGridData.selected_grid}
										   page={this.props.wbcGridData.page} fieldGridData={this.props.fieldGridData}
										   mode={bloodClass.WBC} wbcGridData={this.props.wbcGridData}
										   differential={differential}
										   loadNext={this.loadNext} loadPrev={this.loadPrev}
										   cssfilter={this.props.bloodViewerSetting}/> :
							<Tabs id="unconfirmed" selectedIndex={this.state.confirmed ? 0 : 1} onSelect={index =>
								this.onTabChangeWBCTabs(index)}>
								<TabList>
									<Tab><p>Confident</p></Tab>
									<Tab><p>Confusion</p></Tab>
								</TabList>
								<TabPanel>
									<FieldGridView slide_id={this.slide_id} dispatch={this.props.dispatch}
												   view_mode={this.state.view_mode}
												   selected={this.props.wbcGridData.selected_grid}
												   page={this.props.wbcGridData.page}
												   fieldGridData={this.props.fieldGridData}
												   mode={bloodClass.WBC} wbcGridData={this.props.wbcGridData}
												   differential={differential} loadNext={this.loadNext}
												   loadPrev={this.loadPrev}
												   cssfilter={this.props.bloodViewerSetting}/>
								</TabPanel>
								<TabPanel>
									<FieldGridViewConfused slide_id={this.slide_id} dispatch={this.props.dispatch}
														   view_mode={this.state.view_mode}
														   page={this.props.wbcGridData.page}
														   selected={this.props.wbcGridData.selected_grid}
														   mode={bloodClass.WBC}
														   fieldGridData={this.props.fieldGridData}
														   differential={differential}
														   wbcGridData={this.props.wbcGridData} loadNext={this.loadNext}
														   loadPrev={this.loadPrev}
														   cssfilter={this.props.bloodViewerSetting}/>
								</TabPanel>
							</Tabs>
						}
					</Col>
					<Col
						style={{paddingTop: "0vh", marginLeft: "-1vw"}}
						span={this.state.view_mode === 1 ? 13 : this.state.view_mode === 2 ? 17 : this.state.view_mode === 3 ? 0 : 7}
					>
						<Field100xView
							selected={this.props.wbcGridData.selected_grid}
							view_mode={this.state.view_mode}
							dispatch={this.props.dispatch}
							all_grids={this.props.wbcGridData.all_grids}
							slide_id={this.slide_id}
							fieldGridData={this.props.wbcGridData}
							mode={bloodClass.WBC}
							differential={differential}
							loadNext={this.loadNext}
							loadPrev={this.loadPrev}
							cssfilter={this.props.bloodViewerSetting}
						/>
					</Col>
				</TabPanel>
				<TabPanel style={this.state.blood_class === bloodClass.RBC ? {width: "100%"} : {}}>
					<Col
						style={{paddingTop: "1.5vh"}}
						span={this.state.view_mode === 1 ? 6 : this.state.view_mode === 2 ? 7 : this.state.view_mode === 3 ? 0 : 7}
					>
						<BloodGraph
							key={"RBC_GRAPH"}
							class={bloodClass.RBC}
							slide_id={this.slide_id}
							dispatch={this.props.dispatch}
							fieldGridData={this.props.fieldGridData}
							data={this.props.bloodGridRBCData}
						/>
					</Col>

					<Col
						span={this.state.view_mode === 1 ? 18 : this.state.view_mode === 2 ? 10 : this.state.view_mode === 3 ? 0 : 7}
					>
						<Spin
							indicator={antIcon}
							key="loading"
							spinning={this.props.bloodGridRBCData.fetching_grid}
						>
							<Tabs
								id="ordered"
							>
								<TabList>
									<Tab>
										<p>Unordered</p>
									</Tab>
									<Tab>
										<Row justify="space-between">
											<Col span={20}>Size</Col>
											<Tooltip
												placement="top"
												title={
													this.state.size_asc
														? "Sorted by Ascending"
														: "Sorted by Descending"
												}
												key={this.state.size_asc}
											>
												<Col
													span={4}
													onClick={() =>
														this.onClickSort(
															"size_asc",
															!this.state.size_asc
														)
													}
												>
													{this.getSortIcon(this.state.size_asc)}
												</Col>
											</Tooltip>
										</Row>
									</Tab>
									<Tab>
										<Row justify="space-between">
											<Col span={20}>Elongation</Col>
											<Tooltip
												placement="top"
												title={
													this.state.elongation_asc
														? "Sorted by Ascending"
														: "Sorted by Descending"
												}
												key={this.state.elongation_asc}
											>
												<Col
													span={4}
													onClick={() =>
														this.onClickSort(
															"elongation_asc",
															!this.state.elongation_asc
														)
													}
												>
													{this.getSortIcon(this.state.elongation_asc)}
												</Col>
											</Tooltip>
										</Row>
									</Tab>
									<Tab>
										<Row justify="space-between">
											<Col span={20}>Unevenness</Col>
											<Tooltip
												placement="top"
												title={
													this.state.uneven_asc
														? "Sorted by Ascending"
														: "Sorted by Descending"
												}
												key={this.state.uneven_asc}
											>
												<Col
													span={4}
													onClick={() =>
														this.onClickSort(
															"uneven_asc",
															!this.state.uneven_asc
														)
													}
												>
													{this.getSortIcon(this.state.uneven_asc)}
												</Col>
											</Tooltip>
										</Row>
									</Tab>
									{this.props.bloodGridRBCData
										.extreme_mean_color_range_low !== 0 &&
									this.props.bloodGridRBCData
										.extreme_mean_color_range_high !== 0 ? (
										<Tab>
											<Row justify="space-between">
												<Col span={20}>Pallor</Col>
												<Tooltip
													placement="top"
													title={
														this.state.mean_color_asc
															? "Sorted by Ascending"
															: "Sorted by Descending"
													}
													key={this.state.mean_color_asc}
												>
													<Col
														span={4}
														onClick={() =>
															this.onClickSort(
																"mean_color_asc",
																!this.state.mean_color_asc
															)
														}
													>
														{this.getSortIcon(this.state.mean_color_asc)}
													</Col>
												</Tooltip>
											</Row>
										</Tab>
									) : null}
								</TabList>
								<TabPanel>
									<FieldGridViewRbc
										slide_id={this.slide_id}
										dispatch={this.props.dispatch}
										view_mode={this.state.view_mode}
										selected={this.props.bloodGridRBCData.selected_grid}
										page={this.props.bloodGridRBCData.page}
										fieldGridData={this.props.bloodGridRBCData}
										mode={bloodClass.RBC}
										rbcGridData={this.props.bloodGridRBCData}
										bloodGridData={this.props.bloodGridRBCData}
										differential={differential}
										orderby="id"
										cssfilter={this.props.bloodViewerSetting}
									/>
								</TabPanel>
								{this.state.size_asc ? (
									<TabPanel key="asc-s">
										<FieldGridViewRbc
											slide_id={this.slide_id}
											dispatch={this.props.dispatch}
											view_mode={this.state.view_mode}
											selected={this.props.bloodGridRBCData.selected_grid}
											page={this.props.bloodGridRBCData.page}
											fieldGridData={this.props.bloodGridRBCData}
											mode={bloodClass.RBC}
											rbcGridData={this.props.bloodGridRBCData}
											bloodGridData={this.props.bloodGridRBCData}
											differential={differential}
											orderby="size"
											cssfilter={this.props.bloodViewerSetting}
										/>
									</TabPanel>
								) : (
									<TabPanel key="desc-s">
										<FieldGridViewRbc
											slide_id={this.slide_id}
											dispatch={this.props.dispatch}
											view_mode={this.state.view_mode}
											selected={this.props.bloodGridRBCData.selected_grid}
											page={this.props.bloodGridRBCData.page}
											fieldGridData={this.props.bloodGridRBCData}
											mode={bloodClass.RBC}
											rbcGridData={this.props.bloodGridRBCData}
											bloodGridData={this.props.bloodGridRBCData}
											differential={differential}
											orderby="-size"
											cssfilter={this.props.bloodViewerSetting}
										/>
									</TabPanel>
								)}
								{this.state.elongation_asc ? (
									<TabPanel key="asc-e">
										<FieldGridViewRbc
											slide_id={this.slide_id}
											dispatch={this.props.dispatch}
											view_mode={this.state.view_mode}
											selected={this.props.bloodGridRBCData.selected_grid}
											page={this.props.bloodGridRBCData.page}
											fieldGridData={this.props.bloodGridRBCData}
											mode={bloodClass.RBC}
											rbcGridData={this.props.bloodGridRBCData}
											bloodGridData={this.props.bloodGridRBCData}
											differential={differential}
											orderby="eccentricity"
											cssfilter={this.props.bloodViewerSetting}
										/>
									</TabPanel>
								) : (
									<TabPanel key="desc-e">
										<FieldGridViewRbc
											slide_id={this.slide_id}
											dispatch={this.props.dispatch}
											view_mode={this.state.view_mode}
											selected={this.props.bloodGridRBCData.selected_grid}
											page={this.props.bloodGridRBCData.page}
											fieldGridData={this.props.bloodGridRBCData}
											mode={bloodClass.RBC}
											rbcGridData={this.props.bloodGridRBCData}
											bloodGridData={this.props.bloodGridRBCData}
											differential={differential}
											orderby="-eccentricity"
											cssfilter={this.props.bloodViewerSetting}
										/>
									</TabPanel>
								)}
								{this.state.uneven_asc ? (
									<TabPanel key="asc-c">
										<FieldGridViewRbc
											slide_id={this.slide_id}
											dispatch={this.props.dispatch}
											view_mode={this.state.view_mode}
											selected={this.props.bloodGridRBCData.selected_grid}
											page={this.props.bloodGridRBCData.page}
											fieldGridData={this.props.bloodGridRBCData}
											mode={bloodClass.RBC}
											rbcGridData={this.props.bloodGridRBCData}
											bloodGridData={this.props.bloodGridRBCData}
											differential={differential}
											orderby="convexity"
											cssfilter={this.props.bloodViewerSetting}
										/>
									</TabPanel>
								) : (
									<TabPanel key="desc-c">
										<FieldGridViewRbc
											slide_id={this.slide_id}
											dispatch={this.props.dispatch}
											view_mode={this.state.view_mode}
											selected={this.props.bloodGridRBCData.selected_grid}
											page={this.props.bloodGridRBCData.page}
											fieldGridData={this.props.bloodGridRBCData}
											mode={bloodClass.RBC}
											rbcGridData={this.props.bloodGridRBCData}
											bloodGridData={this.props.bloodGridRBCData}
											differential={differential}
											orderby="-convexity"
											cssfilter={this.props.bloodViewerSetting}
										/>
									</TabPanel>
								)}
								{this.props.bloodGridRBCData.extreme_mean_color_range_low !==
								0 &&
								this.props.bloodGridRBCData.extreme_mean_color_range_high !==
								0 ? (
									this.state.mean_color_asc ? (
										<TabPanel key="asc-m">
											<FieldGridViewRbc
												slide_id={this.slide_id}
												dispatch={this.props.dispatch}
												view_mode={this.state.view_mode}
												selected={this.props.bloodGridRBCData.selected_grid}
												page={this.props.bloodGridRBCData.page}
												fieldGridData={this.props.bloodGridRBCData}
												mode={bloodClass.RBC}
												rbcGridData={this.props.bloodGridRBCData}
												bloodGridData={this.props.bloodGridRBCData}
												differential={differential}
												orderby="mean_color"
												cssfilter={this.props.bloodViewerSetting}
											/>
										</TabPanel>
									) : (
										<TabPanel key="desc-m">
											<FieldGridViewRbc
												slide_id={this.slide_id}
												dispatch={this.props.dispatch}
												view_mode={this.state.view_mode}
												selected={this.props.bloodGridRBCData.selected_grid}
												page={this.props.bloodGridRBCData.page}
												fieldGridData={this.props.bloodGridRBCData}
												mode={bloodClass.RBC}
												rbcGridData={this.props.bloodGridRBCData}
												bloodGridData={this.props.bloodGridRBCData}
												differential={differential}
												orderby="-mean_color"
												cssfilter={this.props.bloodViewerSetting}
											/>
										</TabPanel>
									)
								) : null}
							</Tabs>
						</Spin>
					</Col>
				</TabPanel>

				<TabPanel
					style={this.state.blood_class === bloodClass.PLT ? {width: "100%"} : {}}
				>
					<Col
						style={{paddingTop: "1vh"}}
						span={this.state.view_mode === 1 ? 6 : this.state.view_mode === 2 ? 7 : this.state.view_mode === 3 ? 0 : 7}
					>
						<BloodGraph
							key={"PLT_GRAPH"}
							class={bloodClass.PLT}
							slide_id={this.slide_id}
							dispatch={this.props.dispatch}
							data={this.props.bloodGridPLTData}
						/>
					</Col>
					<Col
						span={this.state.view_mode === 1 ? 18 : this.state.view_mode === 2 ? 10 : this.state.view_mode === 3 ? 0 : 7}
					>
						<Spin
							indicator={antIcon}
							key="loading"
							spinning={this.props.bloodGridPLTData.fetching_grid}
						>
							<Tabs
								id="ordered"
							>
								<TabList>
									<Tab>
										<p>Unordered</p>
									</Tab>
									<Tab>
										<Row justify="space-between">
											<Col span={20}>Size</Col>
											<Tooltip
												placement="top"
												title={
													this.state.plt_size_asc
														? "Sorted by Ascending"
														: "Sorted by Descending"
												}
												key={this.state.plt_size_asc}
											>
												<Col
													span={4}
													onClick={() =>
														this.onClickSort(
															"plt_size_asc",
															!this.state.plt_size_asc
														)
													}
												>
													{this.getSortIcon(this.state.plt_size_asc)}
												</Col>
											</Tooltip>
										</Row>
									</Tab>
								</TabList>
								<TabPanel>
									<FieldGridViewPlt
										slide_id={this.slide_id}
										dispatch={this.props.dispatch}
										view_mode={this.state.view_mode}
										selected={this.props.bloodGridPLTData.selected_grid}
										page={this.props.bloodGridPLTData.page}
										fieldGridData={this.props.bloodGridPLTData}
										mode={bloodClass.PLT}
										pltGridData={this.props.bloodGridPLTData}
										differential={differential}
										filter={"id"}
										cssfilter={this.props.bloodViewerSetting}
									/>
								</TabPanel>
								{this.state.plt_size_asc ? (
									<TabPanel key="asc-p-s">
										<FieldGridViewPlt
											slide_id={this.slide_id}
											dispatch={this.props.dispatch}
											view_mode={this.state.view_mode}
											selected={this.props.bloodGridPLTData.selected_grid}
											page={this.props.bloodGridPLTData.page}
											fieldGridData={this.props.bloodGridPLTData}
											mode={bloodClass.PLT}
											pltGridData={this.props.bloodGridPLTData}
											differential={differential}
											filter={"size"}
											cssfilter={this.props.bloodViewerSetting}
										/>
									</TabPanel>
								) : (
									<TabPanel key="desc-p-s">
										<FieldGridViewPlt
											slide_id={this.slide_id}
											dispatch={this.props.dispatch}
											view_mode={this.state.view_mode}
											selected={this.props.bloodGridPLTData.selected_grid}
											page={this.props.bloodGridPLTData.page}
											fieldGridData={this.props.bloodGridPLTData}
											mode={bloodClass.PLT}
											pltGridData={this.props.bloodGridPLTData}
											differential={differential}
											filter={"-size"}
											cssfilter={this.props.bloodViewerSetting}
										/>
									</TabPanel>
								)}
							</Tabs>
						</Spin>
					</Col>
				</TabPanel>
			</Tabs>
		);
	};

	getResults = () => {
		let differential = this.state.differential;

		let stitchingInProgressComponent = (
			<Row key="progress">
				<Row style={{textAlign: "center"}}>
					<h2>Stitching in Progress</h2>
					<Progress
						style={{marginTop: "1%", marginBottom: "1%"}}
						type="circle"
						percent={parseFloat(this.state.stitch_percent)}
					/>
					<Link to={"/" + globalUrlPrefix + "/dashboard"}>
						<h2>Click to go back to Dashboard</h2>
					</Link>
				</Row>
			</Row>
		);

		let scanOngoingComponent = (
			<Row>
				<Row style={{textAlign: "center", marginTop: "5em"}}>
					<b>
						Slide is being scanned. <br></br>Progress will be updated soon.
					</b>
					<Link
						to={"/" + globalUrlPrefix + "/dashboard"}
						className="click-to-go-to-dash"
					>
						<h2>Click to go back to Dashboard</h2>
					</Link>
				</Row>
			</Row>
		);

		let viewerComponent;

		if (
			!this.state.scan_ongoing &&
			this.state.stitching_done &&
			!this.state.isFetching
		) {

			viewerComponent = (
				<div style={{height: "100%"}}> {
					this.getSlideMap()
				}

					{this.state.view_mode === 1 ? (
						<Row
							style={
								this.state.blood_class === bloodClass.FIELD || !differential
									? {
										color: "white",
										fontSize: "1.3vw",
										position: "fixed",
										bottom: "1vh",
										right: "27vw",
									}
									: {
										color: "white",
										fontSize: "1.3vw",
										position: "fixed",
										bottom: "1vh",
										right: "4vw",
									}
							}
						>
							Area Scan @ 40x
						</Row>
					) : null}
				</div>
			);
		}

		if ((this.state.slide_data || {}).specimen_type === undefined)
			return null;
		return <div key="view">
			<Spin key="loading" tip="Loading..." spinning={this.state.isFetching} delay={500}>
				{this.state.scan_ongoing === true ? scanOngoingComponent : this.state.stitching_done === true ?
					<div>
						{this.getHeader()}
						<Row style={{backgroundColor: "#03112c", height: "95.7vh"}}>
							{differential ?
								[<Col span={this.state.view_mode !== 3 ? this.state.view_mode === 1 ?
									this.state.blood_class === bloodClass.FIELD ? 8 : 20 : 24 : 0}>
									{this.getDifferentialView()}
								</Col>,
									<Col
										span={this.state.view_mode === 1 ? this.state.blood_class === bloodClass.FIELD ? 16 : 4 :
											this.state.view_mode === 2 ? 0 : this.state.view_mode === 3 ? 24 : 10}
										class="viewer-component"
										id={"viewer-id"} style={{height: "95.5vh", backgroundColor: "#03112c"}}>
										{viewerComponent}
									</Col>] :
								[<Col span={this.state.view_mode !== 3 ? this.state.view_mode === 1 ? 16 : 24 : 0}>
									{this.getNonDifferentialView()}
								</Col>,
									<Col class="viewer-component" id={"viewer-id"}
									     style={{height: "96.2vh", backgroundColor: "#03112c"}}
									     span={this.state.view_mode === 1 ? 8 : this.state.view_mode === 2 ? 0 :
										     this.state.view_mode === 3 ? 24 : 10}>
										{viewerComponent}
									</Col>]}
						</Row>
					</div> : stitchingInProgressComponent}
			</Spin>
		</div>;
	}

	render() {
		let oldAnnotations = this.annotations;
		this.annotations = ((this.props.mapState || {}).annotationState || {}).annotations;
		if (oldAnnotations !== this.annotations && this.annotations) {
			this.fieldAnnotations = this.annotations.filter(annotation => annotation.anno_drawer === 4)
				.sort((annotation1, annotation2) => annotation1.id - annotation2.id);
		}
		let settingsComponent;
		settingsComponent = this.getBloodSettingsComponent();

		let settingsMenu;
		settingsMenu = this.getBloodSettingsMenu();

		let results = this.getResults();

		let gammaLink = this.getGammaLink();

		let gammaSettingsComponent = this.getGammaSettingsComponent();
		let gammaSettingsMenu = this.getGammaSettingsMenu();

		// let configurationComponent = this.getConfigurationComponent();
		// let configuationMenu = this.getConfigurationMenu();

		return (
			<>
				{results}
				{settingsComponent}
				{settingsMenu}
				{gammaSettingsComponent}
				{gammaSettingsMenu}
				{/*{configurationComponent}*/}
				{/*{configuationMenu}*/}
				{gammaLink}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	let mapState = state.mapsStateReducer[0];
	let slide = ((mapState || {}).slideState || {}).slide_data;
	let bloodViewerSetting = state.viewerSettingsReducer[(slide || {}).bloodsettings];
	return {
		bloodGridData: state.bloodGridReducer,
		urlState: state.viewerUrlReducer,
		bloodGridRBCData: state.bloodGridRBCReducer,
		bloodGridPLTData: state.bloodGridPLTReducer,
		fieldGridData: state.fieldViewReducer,
		wbcGridData: state.wbcViewReducer,
		pltGridData: state.bloodGridPLTReducer,
		mapState,
		slide,
		bloodViewerSetting,
	};
};

export default connect(mapStateToProps)(BloodScan);
