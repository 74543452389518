import React, { Component } from "react";
import { Col, Row, DatePicker, Select } from "antd";
import { connect } from "react-redux";
import cookie from "react-cookies";
import { getAssignedCases, getAll, clearAllFilters, getUnreadCases, getReadCases, setDateRange, changeOrdering } from "../../action/search.case.action";
import moment from 'moment';

const { RangePicker } = DatePicker
const Option = Select.Option;
var today = new Date();
var dd = today.getDate();

class FilterComp extends Component {
  constructor(props) {
    super(props);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.state = {
    };
  }

  handleTypeChange(value) {
    if (value === "case_assigned") {
      this.props.dispatch(getAssignedCases(this.props.urlState));
    } else if (value === "unread") {
      this.props.dispatch(getUnreadCases(this.props.urlState));
    } else if (value === "read") {
      this.props.dispatch(getReadCases(this.props.urlState));
    } else {
      this.props.dispatch(getAll(this.props.urlState));
    }
  }

  handleDateRangeChange(moment) {
    try {
      this.props.dispatch(setDateRange(moment[0].format("YYYY-MM-DD"), moment[1].format("YYYY-MM-DD"), this.props.urlState));
    } catch (err) {
      this.props.dispatch(setDateRange('', '', this.props.urlState));
    }
  }

  handleSortChange = (value) => {
    this.props.dispatch(changeOrdering(value, this.props.urlState))
  }

  handleClear() {
    this.props.dispatch(clearAllFilters());
  }

  render() {
    var isMobile = cookie.loadAll().isMobile === "true";
    return (
        this.props.isFetching === true ?
            <Row >
            </Row>
            :
            <Row style={isMobile ? {height:"6em"} : {}}>
              <Col span={6}>
                <Select
                    value={this.props.urlState.case_assigned ? "case_assigned" : this.props.urlState.unread ? "unread" : this.props.urlState.read ? "read" : "all"}
                    placeholder="Select Type"
                    onChange={this.handleTypeChange}
                    style={{width:180, marginLeft:8}}
                >
                  <Option value="case_assigned">Case Assigned To Me</Option>
                  <Option value="unread">Unread</Option>
                  <Option value="read">Read</Option>
                  <Option value="all">All</Option>
                </Select>
              </Col>
              <Col span={4}>
                <RangePicker
                    dateRender={current => {
                      const style = {};
                      if (current.date() === dd) {
                        style.backgroundColor = "transparent";
                        style.color = "black";
                      }
                      return (
                          <div className="ant-calendar-date" style={style}>
                            {current.date()}
                          </div>
                      );
                    }}
                    showToday
                    onChange={this.handleDateRangeChange}
                    value={(this.props.urlState.date__lte === undefined || this.props.urlState.date__lte.length === 0) ?
                        undefined : [moment(this.props.urlState.date__gte, "YYYY-MM-DD"), moment(this.props.urlState.date__lte, "YYYY-MM-DD")]}
                    format="YYYY-MM-DD"
                    defaultValue={[undefined, undefined]}
                />
              </Col>
              <Col span={4} offset={1}>
                <Select
                    value={this.props.urlState.ordering}
                    placeholder="Sort List"
                    onChange={this.handleSortChange}
                    style={{width:130}}
                >
                  <Option value="-id">
                    {" "}
                    ID
                  </Option>
                  <Option value="priority">
                    Priority
                  </Option>
                  <Option value="date">
                    Oldest First
                  </Option>
                  <Option value="-date">
                    Recent First
                  </Option>
                  <Option value={"status"}>
                    Status
                  </Option>
                </Select>
              </Col>
              {/* <Col span={3}>
              <Button
                style={{
                  marginLeft: 8,
                  backgroundColor: "white",
                  color: "gray"
                }}
                onClick={this.handleClear}
              >
                Clear Filters
              </Button>
            </Col> */}
            </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.searchCaseReducer.isFetching,
    loginStatus: state.loginAlerts.loginStatus,
    urlState: state.searchCaseReducer
  };
};

export default connect(mapStateToProps)(FilterComp);