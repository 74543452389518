import React, {Component} from "react";
import {BookOutlined, LayoutOutlined} from '@ant-design/icons';
import {Col, Divider, Row} from 'antd';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import Expandable from '../utils/expandable';
import {getAllApps} from './app_maker';
import {getAllApps as getAllTileViewerApps} from "../neotileviewer/app_maker";
import {getAllAppIcons} from './app_icon_maker';
import {getAllAppIcons as getAllTileViewerAppIcons} from '../neotileviewer/app_icon_maker';

import AppIcon from '../utils/app_icon'
import {globalUrlPrefix, MorphleLogoSquare, slideViewerType} from '../../utils/const'
import "../../asset/style/neoviewer/appboard.css"
import cookie from "react-cookies";

const allSlidesKey = {
    id: "all_slides",
    title: "All Slides",
    showTitle: true
}

const casesKey = {
    id: "cases", 
    title: "Cases", 
    showTitle: true
}

const dashboardKey = {
    id: "dashboard", 
    title: "Dashboard", 
    showTitle: true
}

class AppBoard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showBoard: false,
            showTrigger: true
        }
    }

    setShowBoard = (value) => {
        this.setState({
            showBoard: value
        })
    }

    setShowTrigger = (value) => {
        this.setState({
            showTrigger: value
        })
    }

    render(){
        //console.log("slideState", this.props.urlState)
        let apps_initialised = this.props.viewerType == slideViewerType.NORMAL_SLIDE_VIEWER ? 
            this.props.urlState.apps_initialised : this.props.tileViewerUrlState.apps_initialised;
        
        if (!apps_initialised) return null;

        let apps, appIcons;

        if (this.props.viewerType == slideViewerType.NORMAL_SLIDE_VIEWER) {
            apps = getAllApps(this.props.slideState, this.props.urlState, this.props.updateTileCoordinates);
            appIcons = getAllAppIcons(this.props.urlState);
        } else {
            apps = getAllTileViewerApps(this.props.slideState, this.props.tileViewerUrlState, this.props.updatePlotStatus, this.props.changeSpinningState);
            appIcons = getAllTileViewerAppIcons(this.props.tileViewerUrlState);
            console.log("apps_", apps, appIcons)
        }

        let appBoardSize = 4//Math.ceil(Math.sqrt(appIcons.length));

        appBoardSize -= appBoardSize%2;

        let colSpan = 24 / appBoardSize;

        let appIconsRow = [];
        let appIconsCol = [];
        let counter = 0;

        for (var i in appIcons) {
            counter ++;
            appIconsCol.push(
                <Col key={i} span={colSpan}>{appIcons[i]}</Col>
            )
            if (counter >= appBoardSize) {
                appIconsRow.push(
                    <Row key={i}>{appIconsCol}</Row>
                )
                appIconsCol = [];
                counter = 0;
            }
        }

        if (appIconsCol.length > 0) {
            appIconsRow.push(
                <Row key={appIcons.length + 1}>{appIconsCol}</Row>
            )
        }

        let appBoardDiv = <Row className="appboard-icons-grid">
                            {cookie.loadAll().superuser == 'true' || cookie.loadAll().is_staff == 'true' || (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).collapse_menu.scans || (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience ?
                                <div>
                                    <Col span={2}>
                                        <NavLink to={'/' + globalUrlPrefix + '/dashboard/'} className="dashboard-icon">
                                            <AppIcon key={allSlidesKey.id}
                                                appKey={allSlidesKey} 
                                                openable={false}
                                                viewerType={slideViewerType.NORMAL_SLIDE_VIEWER}
                                                content={
                                                    <LayoutOutlined />
                                                } />
                                        </NavLink>
                                    </Col> 
                                    {cookie.loadAll().is_staff === 'true' || cookie.loadAll().superuser === 'true' || 
                                        (!(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience && (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).collapse_menu.cases) ?
                                        <Col span={2} offset={6}>
                                            <NavLink to={'/' + globalUrlPrefix + '/cases/'} className="dashboard-icon">
                                                <AppIcon key={casesKey.id}
                                                    appKey={casesKey} 
                                                    openable={false}
                                                    viewerType={slideViewerType.NORMAL_SLIDE_VIEWER}
                                                    content={
                                                        <BookOutlined />
                                                    } />
                                            </NavLink>
                                        </Col> : null
                                    }
                                </div> : 
                                <Col span={2}>
                                    <NavLink to={'/' + globalUrlPrefix + '/cases/'} className="dashboard-icon">
                                        <AppIcon key={casesKey.id}
                                            appKey={casesKey} 
                                            openable={false}
                                            viewerType={slideViewerType.NORMAL_SLIDE_VIEWER}
                                            content={
                                                <LayoutOutlined />
                                            } />
                                    </NavLink>
                                </Col>
                            }      
                            <Divider width={80}/>
                            <Col>
                                {appIconsRow}
                            </Col>
                        </Row>
        let window_loc=window.location.href.split('?')[0].split('/').reverse()[1];
        let closedContent;
        if(window_loc === "gamma-viewer" || window_loc === "blood-viewer"){
            closedContent = null;
        }
        else{
            closedContent = <div>
                <img className="appboard-trigger" src={MorphleLogoSquare} alt='appboard'/>
            </div>
        }

        let class_;
        if (window_loc === "gamma-viewer"){
            class_ = "appboard-parent--";
        }else if (window_loc === "blood-viewer")
        {
            class_ = "appboard-parent-"
        } else {
            class_ = "appboard-parent"
        }

        return (
            <Row className="appboard" id="appboard">
                <Expandable
                    className={(this.props.urlState || {}).presentCode != undefined ? "appboard-parent-present-mode" : class_}
                    closedContent={closedContent}
                    expandedContent={<div>{appBoardDiv}</div>}
                    closedClassName="appboard-closed"
                    expandedClassName="appboard-expanded"
                    />
                    {apps}
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
        tileViewerUrlState: state.tileViewerUrlReducer
    }
}

export default connect(mapStateToProps)(AppBoard);
