import {combineReducers} from "redux";
import {loginAlerts} from "./login.reducer";
import {slidesReducer} from "./slides.reducer";
import {searchReducer} from "./search.reducer";
import {deviceAlert} from "./device.reducer";
import {dashboardReducer} from "./dashboard.reducer"
import {sessionStatusReducer} from "./session.reducer";
import {deviceStatusReducer} from "./device_status.reducer";
import {utilsReducer} from "./utils.reducer";
import {previewStatusReducer} from "./preview_status.reducer";
import {olAlert} from './ol.reducer';
import {adminUrlReducer} from './admin.state.reducer'
import {liveMainCameraPreviewReducer} from './livemaincamerapreview.reducer'
import {annoUpdate} from './anno.reducer';
import {liveViewReducer} from './liveview.reducer';
import {viewerSettingReducer} from './viewerSettings.reducer';
import {viewerUrlReducer} from './morpheus.state.reducer';
import {contextMenuReducer} from './context.menu.reducer';
import {slideTraversalReducer} from './slide.traversal.reducer';
import {casesReducer} from './cases.reducer';
import {searchCaseReducer} from './search.case.reducer';
import {caseStatusReducer} from './case.status.reducer';
import {searchUserReducer} from './search.user.reducer';
import {usersReducer} from './users.reducer';
import {previewTuningReducer} from './preview_tuning.reducer';
import {livemodeReducer} from './livemode.reducer';
import {tileViewerUrlReducer} from './tile.viewer.state.reducer';
import {bloodGridReducer} from "./bloodgrid.reducer";
import {bloodGridRBCReducer} from "./bloodGridRBC.reducer";
import {bloodGridPLTReducer} from './bloodGridPLT.reducer';
import {fieldViewReducer} from "./fieldView.reducer";
import {wbcViewReducer} from "./wbcview.reducer";
import {bloodConfigReducer} from "./bloodConfig.reducer"
import {gammaStateReducer} from "./gamma.state.reducer";
import {mapsStateReducer} from "./maps.state.reducer";
import {screenshotStateReducer} from "./screenshot.state.reducer";
import {triggerReducer} from "./triggers.reducer";
import {MagicToolReducer} from "./magic_tool.reducer";
import {deepBioReducer} from "./deepbio.reducer";
import {brainAnnoGridReducer} from './brainAnno.reducer';
import {viewerSettingsReducer} from "./viewer.settings.reducer";
import {morpheusSettingReducer} from "./morpheus.setting.reducer";

export const rootReducer = combineReducers({
	loginAlerts,
	slidesReducer,
	searchReducer,
	deviceAlert,
	dashboardReducer,
	sessionStatusReducer,
	deviceStatusReducer,
	utilsReducer,
  previewStatusReducer,
  liveViewReducer, 
  olAlert,
  annoUpdate,
  viewerSettingReducer, 
  viewerUrlReducer,
  contextMenuReducer,
  slideTraversalReducer,
  casesReducer,
  searchCaseReducer,
  caseStatusReducer,
  adminUrlReducer,
  liveMainCameraPreviewReducer,
  searchUserReducer,
  usersReducer,
  previewTuningReducer,
  livemodeReducer,
  tileViewerUrlReducer,
  bloodGridReducer,
  bloodGridRBCReducer,
  bloodGridPLTReducer,
	fieldViewReducer,
	wbcViewReducer,
	bloodConfigReducer,
	gammaStateReducer,
	mapsStateReducer,
	screenshotStateReducer,
	triggerReducer,
	MagicToolReducer,
	deepBioReducer,
	brainAnnoGridReducer,
	viewerSettingsReducer,
	morpheusSettingReducer,
});
