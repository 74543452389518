import React, {Component} from "react";
import {connect} from 'react-redux';
import {
    Box,
    Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	FormGroup,
	Grid,
	IconButton,
	Stack,
	Tooltip,
	Typography
} from "@mui/material";
import {deepBioLayer, deepBioResultLayer} from "../utils/layers_info";
import RefreshIcon from '@mui/icons-material/Refresh';
import {getAnnotationFeature, getAnnotationFromPoints} from "../utils/annotations_app_utils";
import {polygonDrawingKey} from "../drawer/draw_tool_keys";
import VectorSource from "ol/source/Vector";
import axios from "axios";
import {message} from "antd";
import {Fill, Stroke, Style, Text as OlText} from "ol/style.js";
import {AnnotationsConstants, DeepBioConstants} from "../../../utils/const";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {updateDeepBioDisplayColor, updateDeepBioResultsDisplayed} from "../../../action/deepbio.action";
import {updateActiveAnnoDrawer} from "../../../action/gamma.state.action";
import {retrieveAnnotations} from "../../../action/maps.state.action";
import AnnotationsExpandableList from "./AnnotationsExpandableList";
import {getEyeCheckbox, LoadingText} from "../components/components";
import {getDeepBioResultComponent, runDeepBioAI, uploadSlide} from "../utils/deepBioAppUtils";
import {Biotech, VisibilityOff} from "@mui/icons-material";
import {locateAnnotationOnMap} from "../utils/map_utils";
import {displayError} from "../../../helper/display.error";

class DeepBioApp extends Component {
    constructor(props) {
        super(props);

        this.drawer = deepBioLayer.drawer;

        this.state = {
            uploaded: false,
            upload_status: "",
            whole_slide_ai_status: "NOT_RUN",
            whole_slide_ai_result: {},
            whole_slide_ai_progress: 0,
            annotations_in_queue: [],
        }
    }

    initState = () => {
        this.activeMapId = this.props.activeMapId;
        this.mapState = this.props.mapsState[this.activeMapId];
        this.slideState = this.mapState.slideState;
        this.annotationState = this.mapState.annotationState;
    }

    getSlideUploadStatus = () => {
        let url = `/api/get_deep_bio_upload_stage/?id=${this.slideState.slide_data.id}`
        axios.get(url).then(res => {
            let uploaded = res.data['uploaded']
            if (uploaded !== 1) {
                this.setState({
                    uploaded: uploaded,
                })
            } else {
                this.setState({
                    uploaded: uploaded,
                    upload_status: res.data['status'],
                })
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.deepBioData !== this.props.deepBioData)
            this.updateDeepBioResultsDisplayed();
    }

    updateDeepBioResultsDisplayed = () => {
		let selected = this.props.deepBioData.ai_results_displayed;
		let vector = deepBioResultLayer.vector;
		let annotations_list = this.deepBioAnnotations;
        let filter = this.props.deepBioData.active_filter;
        let features = [];
        for(let i=0; i<selected.length; i++)
            if(selected[i] === "ALL") {
                let contours = (this.state.whole_slide_ai_result||{})['heatmap'];
                if (!contours) return;
                for(let i=0;i<contours.length;i++)
                    if (filter === "ALL" || filter.toLowerCase() === contours[i].color.toLowerCase()) {
                        let color = contours[i]['color'];
                        let bounds = contours[i]['contour'];
                        let new_anno = getAnnotationFromPoints(
                            color, 0, 0, polygonDrawingKey, bounds, [0,0], 2, 0,
                            this.slideState.slide_data.id
                        )
                        new_anno.title = contours[i]['label']
                        let new_anno_feature = getAnnotationFeature(new_anno);
                        new_anno_feature.set('parent_anno', "ALL");
                        features.push(new_anno_feature);
                    }
            } else
                for(let j=0;j<annotations_list.length; j++)
                    if(annotations_list[j].id === selected[i]) {
                        let annotation = annotations_list[j];
                        let contours = (annotation['result']||{})['heatmap'];
                        if(!contours) continue;
                        for(let i=0; i<contours.length; i++)
                            if (filter === "ALL" || filter.toLowerCase() === contours[i].color.toLowerCase()) {
                                let color = contours[i]['color'];
                                let bounds = contours[i]['contour'];
                                let new_anno = getAnnotationFromPoints(
                                    color, 0, 0, polygonDrawingKey, bounds, [0,0], 2, 0,
                                    this.slideState.slide_data.id
                                )
                                new_anno.title = contours[i]['label']
                                let new_anno_feature = getAnnotationFeature(new_anno);
                                new_anno_feature.set('parent_anno', annotations_list[j].id);
                                features.push(new_anno_feature);
                            }
                    }
        vector.setSource(
            new VectorSource({
                features: features,
                wrapX: false,
            })
        )
        vector.setStyle(this.styleFunctionDeepBio);
    }

    getWholeSlideAIStatus = () =>
        axios.get(`/api/get_deep_bio_whole_slide_run_status/?id=${this.slideState.slide_data.id}`)
            .then(response =>
                this.setState({
                    whole_slide_ai_status : response.data['status'],
                    whole_slide_ai_result : response.data['result'],
                    whole_slide_ai_progress : response.data['progress'],
                }))
            .catch(error => displayError("Error in getting deep bio status", error));

    componentDidMount() {
        // here we get the status of the slide i.e in what state the slide currently is
        // assume its uploaded
        // in our backend api before returning whether slide is already present or not we will check time too and return accordingly no uploading afterwards

        this.getSlideUploadStatus();
        this.getWholeSlideAIStatus();
        //change anno drawer on mounting only
        this.props.dispatch(updateActiveAnnoDrawer(deepBioLayer.drawer))
    }

    componentWillUnmount() {
        this.props.dispatch(updateActiveAnnoDrawer(null))
    }

    baseStyle = new Style({
            stroke: new Stroke({
            color: 'rgba(255, 0, 0, 0.0)',
            width: 2
        }),
        fill: new Fill({
            color: 'rgba(255, 0, 0, 0.0)'
        })
    });

    baseText = new OlText({
            font: 'bold 20px "Open Sans", "Helvetica", "sans-serif"',
            placement: AnnotationsConstants.LINE,
            textBaseline: 'top',
            fill: new Fill({
                color: "#00FF00"
            }),
            backgroundFill: new Fill({
                    color: "#ffffff"
            }),
            backgroundStroke: new Fill({
                    color: "#ffffff"
            })
})

    selectedStyle = new Style({
            stroke: new Stroke({
            color: "#00b3ff",
            width: 8
        })
    })

    otherStyle = new Style({
            stroke: new Stroke({
            color: "white",
            width: 0
        })
    })

    hexToRGBA = (hex, opacity=0.2) => {
        return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length/3 + '})', 'g')).map(function(l) { return parseInt(hex.length%2 ? l+l : l, 16) }).concat(isFinite(opacity) ? opacity : 1).join(',') + ')';
    }

    styleFunctionDeepBio = feature => {
        let baseStyleWithText = this.baseStyle;
        let baseText = this.baseText;
        this.baseText.setText(feature.get('title'));
        if(feature.getProperties().color) {
            baseStyleWithText.getStroke().setColor(feature.getProperties().color);
            baseText.getFill().setColor(feature.getProperties().color);
            baseStyleWithText.getFill().setColor(this.hexToRGBA(feature.getProperties().color))
            // baseStyleWithText.getFill().getColor().setOpacity(0.2)
        }
        baseStyleWithText.setText(baseText);
        if(feature.getProperties().selected) {
            return [this.selectedStyle, baseStyleWithText]
        }else{
            return [this.otherStyle, baseStyleWithText]
        }
    }


    runAIOnSelectedAnnotations = (selected_annotations) => {
        if(this.state.uploaded !== 2){
            message.info("Please Upload Slide First")
            return;
        }
        let slide = this.slideState.slide_data;
        let selected_annotation_ids = selected_annotations.map(annotation => annotation.id)
            .filter(id => !this.state.annotations_in_queue.includes(id));
        if (selected_annotation_ids.includes("ALL")) {
            selected_annotation_ids = ["ALL"];
        }
        this.setState({
            annotations_in_queue: [...this.state.annotations_in_queue, ...selected_annotation_ids],
        });
        return runDeepBioAI(slide.morphle_id, slide.id, selected_annotation_ids, () => {
            this.setState({
                annotations_in_queue: this.state.annotations_in_queue.filter(id => !selected_annotation_ids.includes(id)),
            });
            this.onRefreshApp();
        });
    }

    getMultipleSelectComponent = (selectedId) => {
        let selected_anno_ids = []
        for (const [key, value] of Object.entries(selectedId)) {
            if(value === true){
                selected_anno_ids.push(key)
            }
        }
        console.log("slide-x-after",selected_anno_ids)
        return <Grid sx={{height:'3vh'}}>
                    <Tooltip title={"Run AI on Selected"} placement={"right"}>
                    <IconButton onClick={(e)=>(this.runAIOnSelectedAnnotations(e, selected_anno_ids))}
                                color={"secondary"}><PlayArrowIcon/></IconButton>
                    </Tooltip>
                </Grid>
    }

    getTopComponent = () =>
        <Stack direction={"row"} justifyContent={"space-between"}>
            {this.state.uploaded === 0 ?
                <FormGroup>
                    <Button variant={"contained"} color={"secondary"} onClick={() =>
                        uploadSlide(this.slideState.slide_data.id, this.defaultRunAI, this.onRefreshApp)}>
                        Upload Slide
                    </Button>
                    <FormControlLabel control={<Checkbox defaultValue={false} onChange={e =>
                        this.defaultRunAI = e.target.checked} color={"secondary"} size={"small"}/>}
                                      label="Run AI with upload" />
                </FormGroup> :
                <Button sx={{width:'98%', backgroundColor:"rgba(108,122,137,1)!important",
                    color:'rgba(255,255,255,1) !important'}} disabled>
                    {this.state.uploaded === 1 ? this.state.upload_status : "Upload Completed"}
                </Button>
            }
            <IconButton onClick={this.onRefreshApp} color={"secondary"}><RefreshIcon/></IconButton>
        </Stack>

    onRefreshApp = () => {
        this.getSlideUploadStatus();
        this.props.dispatch(retrieveAnnotations(this.activeMapId, this.slideState.slide_data.id));
        this.getWholeSlideAIStatus();
    }

    onEyeCheckbox = (annotation, checked) => {
        if (checked)
            this.props.dispatch(updateDeepBioResultsDisplayed([annotation.id,
                ...this.props.deepBioData.ai_results_displayed.filter(id => id !== annotation.id)]));
        else this.props.dispatch(updateDeepBioResultsDisplayed(
            [...this.props.deepBioData.ai_results_displayed.filter(id => id !== annotation.id)]));
    }

    getDeepBioAnnotationAction = (annotation) => {
        if (this.state.annotations_in_queue.includes(annotation.id))
            return <CircularProgress color={"secondary"} size={20} sx={{marginX: 1}}/>;
        let deepBioAnalysis = (annotation.deep_bio_analysis || [])[0];
        return deepBioAnalysis && deepBioAnalysis['fields'] &&  deepBioAnalysis['fields']['status'] !== 'NOT_RUN'?
            deepBioAnalysis['fields']['status'] === 'FINISHED' ?
                getEyeCheckbox(undefined, false,
                    e => this.onEyeCheckbox(annotation, e.target.checked), <VisibilityOff />) :
                <Tooltip title={deepBioAnalysis['fields']['status']} placement="top-start">
                    <Box sx={{ position: 'relative', display: 'inline-flex', marginLeft:"-14px" }}>
                        <CircularProgress variant="determinate" value={deepBioAnalysis['fields']['progress']}
                                          color={"secondary"} />
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography variant="caption" component="div" color="text.secondary">
                                {`${Math.round(deepBioAnalysis['fields']['progress'])}%`}
                            </Typography>
                        </Box>
                    </Box>
                </Tooltip> :
            <Tooltip title={this.state.uploaded === 2 ? "Run AI" : this.state.uploaded === 1 ?
                "Waiting for slide to upload" : "Upload Slide to Run AI"} placement={"bottom"}>
                <IconButton onClick={() => (this.runAIOnSelectedAnnotations([annotation]))}
                            disabled={this.state.uploaded !== 2}
                            color={"secondary"}><PlayArrowIcon/></IconButton>
            </Tooltip>
    }

    render() {
        this.initState();

		if (!(this.annotationState || {}).annotations) return <LoadingText/>;

        this.deepBioAnnotations = this.annotationState.annotations.filter(annotation =>
            annotation.anno_drawer === this.drawer);

        let annotations = [{
            id: "ALL",
            title: "Whole Slide",
            icon: <Biotech/>,
            result: this.state.whole_slide_ai_result,
            deep_bio_analysis: [{
                fields: {
                    status: this.state.whole_slide_ai_status,
                    progress: this.state.whole_slide_ai_progress,
                }
            }],
            color: "ALL",
            onlyShowResult: true,
        }, ...this.deepBioAnnotations];

        annotations.forEach(annotation => {
            annotation.actionComponent = this.getDeepBioAnnotationAction(annotation);
            annotation.resultComponent = getDeepBioResultComponent((annotation.result || {}).summary);
        });

        let annotationsComponent = <AnnotationsExpandableList annotations={annotations}
                                                              slideId={this.slideState.slide_data.id}
                                                              colors={DeepBioConstants.LABELS}
                                                              showColorLabels={true}
                                                              actionOnSelected={this.runAIOnSelectedAnnotations}
                                                              actionTooltip={"Run AI on selected Annotations"}
                                                              onColorFilter={color =>
                                                                  this.props.dispatch(updateDeepBioDisplayColor(color))}
                                                              onAnnotationClick={(annotation) =>
                                                                  locateAnnotationOnMap(this.slideState.slidemap,
                                                                      annotation)}
        />

        return <Stack direction={"column"} spacing={1}>
            {this.getTopComponent()}
            {annotationsComponent}
        </Stack>
    }
}

const mapStateToProps = (state) => {
    return {
        gammaState: state.gammaStateReducer,
        activeMapId: state.gammaStateReducer.activeMapId,
        mapsState: state.mapsStateReducer,
        trigger: state.triggerReducer,
        deepBioData: state.deepBioReducer,
    }
}

export default connect(mapStateToProps)(DeepBioApp);
