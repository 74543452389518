import React, {Component} from 'react';
import axios from 'axios';
import {connect} from "react-redux";
import cookie from "react-cookies";
import {watchPollStatus} from "../action/device_status.action";
import {sagaMiddleware} from "../helper/store";
import {AuthHeader} from '../helper/auth.token';
import {closeDrawer} from "../action/dashboard.action";
import {Button, Col, Empty, Icon, Input, message, Row} from 'antd';
import {globalUrlPrefix, liveModeLoadingMessages, MorphleLogo} from '../utils/const';
import LiveModeCameraView from '../component/livemode/camera_view_live_mode_new';
import {
    arrangeAppsLiveMode,
    closeAllAdminApps,
	loadAdminSettings,
	ongoingReloadSettingsAction,
	updateCameraConfig,
	updateDoAutoFocus,
	updateLiveModeAction,
	updateShowZStackImages,
	updateSlotID,
	updateTakeZStack
} from "../action/admin.state.action";
import {updateLiveImagePolling, updateLiveView, updateLiveViewUrl} from "../action/liveview.action";
import PreviewLiveMode from "../component/livemode/preview_live_mode_new";
// import PreviewLiveMode from "../component/livemode/preview_live_mode_new_txtyviewer";
import LiveModeSidePanel from "../component/livemode/side_panel";
import KeyboardMovementListener from "../component/livemode/keyboard_movement_listener";
import {
	toggleShowChat,
	updateAccessRevoked,
	updateAccessToLiveMode,
	updateAtLeastOneImageFetched,
	updateChatMessages,
	updateCurrentMapPosition,
	updateCurrentPreview,
    updateDeviceId,
    updateExposure,
    updateGamma,
    updateLastBound,
    updateLiveModeStatus,
    updatePreviewsDone,
    updateScheduledPreviews,
    updateTakePreviewInLiveMode,
    updateTileCenters,
    updateUseLiveModeInViewMode
} from '../action/livemode.action';
import {generateLiveModeSuffixCode, otpGenerator} from "../utils/utils";

import "../asset/style/livemode/livemode.css";


export class LiveMode extends Component {

    constructor(props) {
        super(props);

        this.state = {
            deviceId: this.props.match.params.id,
            liveModeBusy: false,
            liveModeBusyMessage: '',
            token: '',
            code: '',
            errorMessage: '',
            checkingStatus: true,
        }

        this.props.dispatch(closeDrawer());

        this.props.dispatch(loadAdminSettings());

        this.props.dispatch(updateDeviceId(this.props.match.params.id));

        this.checkLiveModeAvailabilityTimer = null;

        this.pollLiveModeStatusTimer = null;
        this.messagePollingTimer = null;
        this.previewStatusPolling = null;

        this.task = null
    }

    checkForLoginCookie = () => {
        if (((this.props.device || {}).scanner_health || {}).device_ip != undefined) {
            this.checkForAccess();
            clearInterval(this.pollTillCookieUpdation);
        }
    }

    componentDidMount = () => {
	    this.task = sagaMiddleware.run(watchPollStatus, this.props.dispatch, this.props.match.params.id);

        // this.checkLiveModeAvailability();
		this.getCameraConfig();
		this.getObjectiveConfig();
        // window.addEventListener('beforeunload', this.freeLiveMode);
        // this.checkLiveModeAvailabilityTimer = setInterval(this.checkLiveModeAvailability, 2000);
        this.pollTillCookieUpdation = setInterval(this.checkForLoginCookie, 100);
        this.messagePollingTimer = setInterval(this.getMessages, 1000);
        this.previewStatusPolling = setInterval(this.getPreviewStatus, 1000);
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.livemode.messages.length != 0 && prevProps.livemode.messages.length != this.props.livemode.messages.length) {
            this.props.dispatch(toggleShowChat(true));
            if (this.props.livemode.showChat) {
                let objDiv = document.getElementById("chatMessageDiv");
                objDiv.scrollTop = objDiv.scrollHeight;
            }
        }
        if (prevProps.livemode.showChat != this.props.livemode.showChat && this.props.livemode.showChat) {
            let objDiv = document.getElementById("chatMessageDiv");
            objDiv.scrollTop = objDiv.scrollHeight;
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(updateSlotID(-1));
        this.props.dispatch(updateLiveView(undefined));
        this.props.dispatch(updateLiveViewUrl(undefined));
        this.props.dispatch(updateTakeZStack(true));
        this.props.dispatch(updateShowZStackImages(true));
        this.props.dispatch(updateDoAutoFocus(false));
        this.props.dispatch(updateAccessToLiveMode(false));
        this.props.dispatch(updateAtLeastOneImageFetched(false));
        this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.TAKING_PREVIEW));
        this.props.dispatch(updateCurrentPreview(-1));
        this.props.dispatch(updateScheduledPreviews([]));
        this.props.dispatch(updatePreviewsDone([]));
        this.props.dispatch(updateCurrentMapPosition({}));
        this.props.dispatch(updateTileCenters({}));
        this.props.dispatch(updateLastBound({}));

        clearInterval(this.pollLiveModeStatusTimer);
        clearInterval(this.pollTillCookieUpdation);
        clearInterval(this.messagePollingTimer);
        clearInterval(this.previewStatusPolling);

        this.task.cancel()
        // this.freeLiveMode();
        // window.removeEventListener('beforeunload', this.freeLiveMode);
    }

    getPreviewStatus = () => {
		let partsOfUrl = "api~settings~get_preview_status/";
		let url = `/server/scano/` + this.props.match.params.id + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    if (response.data.first != -1) {
                        this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.TAKING_PREVIEW));
                    }
                    this.props.dispatch(updateCurrentPreview(response.data.first));
                    this.props.dispatch(updateScheduledPreviews(response.data.second.first));
                    this.props.dispatch(updatePreviewsDone(response.data.second.second));
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    getMessages = () => {
		let partsOfUrl = "api~settings~get_chat_messages/";
		let url = `/server/scano/` + this.props.match.params.id + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(updateChatMessages(response.data));
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    freeLiveMode = () => {
        clearInterval(this.checkLiveModeAvailabilityTimer);
        this.checkLiveModeAvailabilityTimer = null;
        let url = `/api/free_live_mode`;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {

                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    checkLiveModeAvailability = () => {
        let url = `/api/check_live_mode_availability/?token=${this.state.token}`;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    if (response.data.busy) {
                        this.setState({
                            liveModeBusy: true,
                            liveModeBusyMessage: 'Someone else is using live mode. Please wait for sometime...',
                        });
                    } else {
                        this.setState({
                            liveModeBusy: false,
                            liveModeBusyMessage: '',
                            token: response.data.token,
                        });
                    }
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    getObjectiveConfig = () => {
        let partsOfUrl = "api~camera~get_objective_config";
        let url = `/server/scano/` + this.props.match.params.id + `/` + partsOfUrl + `?objective_type=` + this.props.app_state.objectiveType;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(updateCameraConfig(response.data.rectWidth, response.data.rectHeight, response.data.uperPixel));
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    goToDashboard = (e) => {
        window.location.href = "/" + globalUrlPrefix + "/dashboard/";
    }

    toggleAppsPanel = (e) => {
        this.setState({
            appsPanelOpen: !this.state.appsPanelOpen
        });
    }

    getLiveImagePollingStatus = () => {
        let partsOfUrl = "api~camera~get_live_image_polling_status";
        let url = `/server/scano/` + this.props.match.params.id + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(updateLiveImagePolling(response.data));
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    reloadSettings = (e) => {
        this.props.dispatch(ongoingReloadSettingsAction(this.props.app_state, true));
        let partsOfUrl = "api~settings~reload";
        let url = `/server/scano/` + this.state.deviceId + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    message.success("Successfully reloaded settings.", 2.5);
                }
                else {
                    console.log(response);
                    message.error("Failed to reload settings!!", 2.5);
                }
                this.props.dispatch(ongoingReloadSettingsAction(this.props.app_state, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Failed to reload settings!!", 2.5);
                this.props.dispatch(ongoingReloadSettingsAction(this.props.app_state, false));
            })
    }

    checkForAccess = () => {
        let partsOfUrl = "api~settings~initial_check_for_access";
        let url = `/server/scano_text/` + this.state.deviceId + `/` + partsOfUrl + `?code=` + cookie.loadAll().livemode_access_code + `&time=` + Date.now();
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(updateTakePreviewInLiveMode(false));
                    this.props.dispatch(updateAccessToLiveMode(true));
                    this.props.dispatch(updateUseLiveModeInViewMode(false));
                    this.props.dispatch(updateAccessRevoked(false));
                    this.props.dispatch(updateLiveModeStatus(response.data, response.data.slotLoaded, this.props.app_state.liveModePreviewVectorLayer, this.props.livemode.useLiveModeInViewMode, false));
                    this.getCameraConfig();
                }
                else {
                    console.log(response);
                    this.props.dispatch(updateTakePreviewInLiveMode(true));
                    this.props.dispatch(updateAccessToLiveMode(false));
                    this.props.dispatch(updateUseLiveModeInViewMode(false));
                    this.props.dispatch(updateAccessRevoked(false));
                }
                this.setState({
                    checkingStatus: false,
                });
            })
            .catch(err => {
                console.log(err);
                this.props.dispatch(updateTakePreviewInLiveMode(true));
                this.props.dispatch(updateAccessToLiveMode(false));
                this.props.dispatch(updateUseLiveModeInViewMode(false));
                this.props.dispatch(updateAccessRevoked(false));
                this.setState({
                    checkingStatus: false,
                });
            })
    }

    revokeAccess = () => {
        let code = otpGenerator(5) + generateLiveModeSuffixCode();
        this.props.dispatch(updateLiveModeAction(true, 'Loading...'));
        let partsOfUrl = "api~settings~set_access_code";
        let url = `/server/scano_text/` + this.state.deviceId + `/` + partsOfUrl + `?code=` + code;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    cookie.save('livemode_access_code', code, { path: '/' });
                    this.props.dispatch(updateTakePreviewInLiveMode(false));
                    this.props.dispatch(updateAccessToLiveMode(true));
                    this.props.dispatch(updateUseLiveModeInViewMode(false));
                    this.props.dispatch(updateAccessRevoked(false));
                    this.props.dispatch(updateLiveModeStatus(response.data, response.data.slotLoaded, this.props.app_state.liveModePreviewVectorLayer, this.props.livemode.useLiveModeInViewMode, false));
                    this.getCameraConfig();
                }
                else {
                    console.log(response);
                    message.error("Not able to give access. Please contact admin!!", 2.5);
                }
                this.props.dispatch(updateLiveModeAction(false, 'Loading...'));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to give access. Please contact admin!!", 2.5);
                this.props.dispatch(updateLiveModeAction(false, 'Loading...'));
            })
    }

    getAccess = () => {
        let code = generateLiveModeSuffixCode();
        this.props.dispatch(updateLiveModeAction(true, 'Loading...'));
        let partsOfUrl = "api~settings~code_check_for_access";
        let url = `/server/scano_text/` + this.state.deviceId + `/` + partsOfUrl + `?prefixCode=` + this.state.code + `&suffixCode=` + code;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    cookie.save('livemode_access_code', (this.state.code + code), { path: '/' });
                    this.props.dispatch(updateTakePreviewInLiveMode(false));
                    this.props.dispatch(updateAccessToLiveMode(true));
                    this.props.dispatch(updateUseLiveModeInViewMode(false));
                    this.props.dispatch(updateAccessRevoked(false));
                    this.props.dispatch(updateLiveModeStatus(response.data, response.data.slotLoaded, this.props.app_state.liveModePreviewVectorLayer, this.props.livemode.useLiveModeInViewMode, false));
                    this.getCameraConfig();
                }
                else {
                    console.log(response);
                    this.setState({
                        errorMessage: "Incorrect Code!!",
                    });
                }
                this.props.dispatch(updateLiveModeAction(false, 'Loading...'));
            })
            .catch(err => {
                console.log(err);
                message.error("Some error occured. Please contact admin!!", 2.5);
                this.props.dispatch(updateLiveModeAction(false, 'Loading...'));
            })
    }

    pollLiveModeStatus = () => {
        let partsOfUrl = "api~health~livemode";
        let url = `/server/scano_text/` + this.state.deviceId + `/` + partsOfUrl + `?update_code=` + this.props.livemode.latestLiveModeDataTimestamp + `&time=` + Date.now();
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(updateLiveModeStatus(response.data, response.data.slotLoaded, this.props.app_state.liveModePreviewVectorLayer, this.props.livemode.useLiveModeInViewMode, false));
                    this.getCameraConfig();
                }
                else {
                    console.log(response);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    getCameraConfig = () => {
        let partsOfUrl = "api~settings~get_camera_config";
        let url = `/server/scano_text/` + this.state.deviceId + `/` + partsOfUrl + `?update_code=` + this.props.livemode.latestLiveModeDataTimestamp + `&time=` + Date.now();
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(updateExposure(response.data.exposureTime));
                    this.props.dispatch(updateGamma(response.data.gamma));
                }
                else {
                    console.log(response);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    viewLiveMode = () => {
        this.props.dispatch(updateUseLiveModeInViewMode(true));
        this.props.dispatch(updateAccessToLiveMode(true));
        this.pollLiveModeStatusTimer = setInterval(this.pollLiveModeStatus, 1000);
    }

    closeAllApps = () => {
        this.props.dispatch(closeAllAdminApps());
    }

    arrangeAppsInLiveMode = () => {
        this.setState({
            appsPanelOpen: false,
        });
        this.props.dispatch(arrangeAppsLiveMode());
    }

    livemodeCodeChange = (e) => {
        if (e.target.value.length <= 4) {
            this.setState({
                code: e.target.value,
                errorMessage: "",
            });
        }
    }

    render() {

        // let apps = [];

        // if(this.props.app_state.apps_initialised) {
        //     apps = getAllApps(this.state, this.props.app_state, true);
        // }
        return (
            <div>
                {this.state.liveModeBusy ?
                    <Empty description={this.state.liveModeBusyMessage} /> :
                    // <Spin spinning={this.props.app_state.live_mode_action} tip={this.props.app_state.loadingMessage}>
                    <div className="relative-position">
                        <div className={this.props.livemode.accessToLiveMode ? "" : "blur"}>
                            <Row>
                                <Col lg={2} xl={2} xxl={1} className="sidepanel-background-color">
                                    <LiveModeSidePanel />
                                </Col>
                                <Col span={1} className="full-height main-background-color">
                                </Col>
                                <Col span={15} className="full-height main-background-color" style={{ position: 'relative' }}>
                                    {this.props.liveView.url != undefined && this.props.livemode.atLeastOneImageFetched?// && this.props.livemode.currentMapPosition[this.props.app_state.slotID] != undefined ?
                                        <LiveModeCameraView /> :
                                        <div>
                                            <br />
                                            <br />
                                            <Empty description={this.props.app_state.slotID < 0 ? "Please select a slot to begin." : "No images taken yet."}
                                                style={{ color: '#d9d9d9' }}
                                                image={<Icon style={{fontSize:80, color:"#d9d9d9"}} type="info-circle"/>}
                                            />
                                        </div>
                                    }
                                    {/* <LiveModeCameraView /> */}
                                    {this.props.app_state.live_mode_action ?
                                        <div className="main-background-color loading-message-livemode">
                                            <b style={{ color: 'white' }}>{this.props.app_state.loadingMessage}</b>
                                        </div>
                                        : null
                                    }
                                </Col>
                                <Col span={1} className="full-height main-background-color">
                                </Col>
                                <Col lg={{ span: 5 }} xl={{ span: 5 }} xxl={{ span: 6 }} className="sidepanel-background-color">
                                    <PreviewLiveMode />
                                </Col>
                            </Row>
                            <KeyboardMovementListener />
                            {/* {apps}    */}
                        </div>
                        {!this.props.livemode.accessToLiveMode ?
                            <div className="initial-options-div main-background-color">
                                    <Row style={{width: '100%'}}>
                                        <Col span={24}>
                                            <Row>
                                                <img className="logo-live-mode" src={MorphleLogo}/>
                                            </Row>
                                            <Row className="live-mode-initial-message unselectable">
                                                Welcome to the Live Microscopy Experience.
                                            </Row>
                                            <Row className="live-mode-initial-message unselectable" style={{fontSize: 16, fontStyle: 'italic'}}>
                                                please choose one of the following modes to continue
                                            </Row>
                                            {this.props.livemode.accessRevoked ? 
                                                <div>
                                                    <Row>
                                                        <Col offset={7} span={10}>
                                                                <span className="initial-div-error-message">Your session has been revoked.</span>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                </div> : null
                                            }
                                            {(cookie.loadAll().superuser == 'true' || cookie.loadAll().staff == 'true') ?
                                            <Row>
                                                <Col span={10} offset={7}>
                                                    <Button onClick={this.revokeAccess} style={{ width: '100%' }} className="revoke-panel-buttons unselected-style">
                                                        Controller Mode
                                                    </Button>
                                                </Col>
                                            </Row>
                                            :
                                            [
                                            <Row>
                                                <Col span={10} offset={7}>
                                                    <Row>
                                                        <Col span={16}>
                                                            <Input 
                                                            placeholder="Enter Code for Controller Mode" 
                                                            style={{width: '100%', borderTopRightRadius: 0, borderBottomRightRadius: 0 }} 
                                                            value={this.state.code} 
                                                            onChange={this.livemodeCodeChange} 
                                                            className="initial-div-input-field custom-input-background-placeholder-color" />
                                                        </Col>
                                                        <Col span={8}>
                                                            <Button 
                                                            onClick={this.getAccess} 
                                                            style={{ width: '100%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} 
                                                            className="revoke-panel-buttons unselected-style">
                                                                Get Access
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>, 
                                            this.state.errorMessage.length > 0 ?
                                                <Row style={{height: '1em'}} type="flex">
                                                    <Col offset={7} style={{textAlign: 'left', display: 'flex'}}>
                                                        <span className="initial-div-error-message">{this.state.errorMessage}</span>
                                                    </Col>
                                                </Row> :
                                                <div style={{height: '1em'}}></div>
                                            ]
                                            } 
                                            <br />
                                            <Row>
                                                <Col span={10} offset={7}>
                                                    <Button onClick={this.viewLiveMode} style={{ width: '100%' }} className="revoke-panel-buttons unselected-style">
                                                        Observer Mode
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                            </div> 
                            : null
                        }
                    </div>
                    // </Spin> 
                }
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.adminUrlReducer,
        device: state.deviceStatusReducer[ownProps.match.params.id],
        liveView: state.liveViewReducer,
        livemode: state.livemodeReducer,
    };
};

export default connect(mapStateToProps)(LiveMode);
