export const Services = [
	{
		name: "Nginx",
		service: 'nginx.service',
		description: 'Nginx service is responsible for serving the viewer, APIs and scan images',
	},
	{
		name: "Morpheus",
		service: 'morpheus.service',
		description: 'Morpheus is the main application server that serves as the central point of database, ' +
			'scanners and viewer',

	},
	{
		name: "Proxy",
		service: 'morpheus.proxy.service',
		description: 'Proxy service is a self-hosted web service responsible for exposing the morpheus server ' +
			'to the internet',
	},
	{
		name: "Supervisor",
		service: 'supervisor.service',
		description: 'Supervisor is a process control system that is responsible for managing the morpheus ' +
			'application server',
	}
]
