import {UPDATE_EXTENSION} from "../actionTypes/screenshot.state.constant";

const initialState = {
	extension: '.jpeg',
	updated: false,
}

export const screenshotStateReducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_EXTENSION:
			return Object.assign({}, state, {
				extension: action.extension,
			});
		default:
			return state;
	}
}
