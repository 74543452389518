import { LivemodeConstants } from '../actionTypes/livemode.constant';

export const getNewState = () => {
    return {
        inputFieldInFocus: false,
        zStackLevels: 4,
        stepSizeZStack: 0.4,
        movementStepSize: 0.07,
        imageFetching: false,
        deviceId: -1,
        exposureInputValue: 20,
        gammaInputValue: 1,
        accessToLiveMode: false,
        useLiveModeInViewMode: false,
        latestLiveModeDataTimestamp: -1,
        generatedAccessCode: '',
        accessRevoked: false,
        takePreviewInLiveMode: true,
        atLeastOneImageFetched: false,
        showChat: false,
        messages: [],
        currentMapPosition: {},
        tileCenters: {},
        slideData: {},
        currentPreview: -1,
        scheduledPreviews: [],
        previewsDone: [],
        useCoverSlipObjective: true,
        lastBound: {},
        globalX: 0,
        globalY: 0,
        visitedTiles: {},
        syncVisitedTilesTrigger: false,
}}

const InitialState = getNewState()

export const livemodeReducer = ( state = InitialState , action) => {
    let returnableState = {};
    switch(action.type){
        case LivemodeConstants.UPDATE_INPUT_FIELD_IN_FOCUS:
            returnableState = Object.assign({}, state);
            returnableState.inputFieldInFocus = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_Z_STACK_LEVELS:
            returnableState = Object.assign({}, state);
            returnableState.zStackLevels = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_STEP_SIZE_Z_STACK:
            returnableState = Object.assign({}, state);
            returnableState.stepSizeZStack = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_MOVEMENT_STEP_SIZE:
            returnableState = Object.assign({}, state);
            returnableState.movementStepSize = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_IMAGE_FETCHING:
            returnableState = Object.assign({}, state);
            returnableState.imageFetching = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_DEVICE_ID:
            returnableState = Object.assign({}, state);
            returnableState.deviceId = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_EXPOSURE:
            returnableState = Object.assign({}, state);
            returnableState.exposureInputValue = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_GAMMA:
            returnableState = Object.assign({}, state);
            returnableState.gammaInputValue = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_ACCESS_TO_LIVE_MODE:
            returnableState = Object.assign({}, state);
            returnableState.accessToLiveMode = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_USE_LIVE_MODE_IN_VIEW_MODE:
            returnableState = Object.assign({}, state);
            returnableState.useLiveModeInViewMode = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_LATEST_LIVE_MODE_DATA_TIMESTAMP:
            returnableState = Object.assign({}, state);
            returnableState.latestLiveModeDataTimestamp = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_GENERATED_ACCESS_CODE:
            returnableState = Object.assign({}, state);
            returnableState.generatedAccessCode = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_ACCESS_REVOKED:
            returnableState = Object.assign({}, state);
            returnableState.accessRevoked = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_TAKE_PREVIEW_IN_LIVE_MODE:
            returnableState = Object.assign({}, state);
            returnableState.takePreviewInLiveMode = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_ATLEAST_ONE_IMAGE_FETCHED:
            returnableState = Object.assign({}, state);
            returnableState.atLeastOneImageFetched = action.value;
            return returnableState;
        case LivemodeConstants.TOGGLE_SHOW_CHAT:
            returnableState = Object.assign({}, state);
            returnableState.showChat = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_CHAT_MESSAGES:
            returnableState = Object.assign({}, state);
            returnableState.messages = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_CURRENT_MAP_POSITION:
            returnableState = Object.assign({}, state);
            returnableState.currentMapPosition = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_TILE_CENTERS:
            returnableState = Object.assign({}, state);
            returnableState.tileCenters = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_SLIDE_DATA:
            returnableState = Object.assign({}, state);
            returnableState.slideData = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_CURRENT_PREVIEW:
            returnableState = Object.assign({}, state);
            returnableState.currentPreview = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_SCHEDULED_PREVIEWS:
            returnableState = Object.assign({}, state);
            returnableState.scheduledPreviews = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_PREVIEWS_DONE:
            returnableState = Object.assign({}, state);
            returnableState.previewsDone = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_USE_COVERSLIP_OBJECTIVE:
            returnableState = Object.assign({}, state);
            returnableState.useCoverSlipObjective = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_LAST_BOUND:
            returnableState = Object.assign({}, state);
            returnableState.lastBound = action.value;
            return returnableState;
        case LivemodeConstants.UPDATE_GLOBAL_COORDS:
            returnableState = Object.assign({}, state);
            returnableState.globalX = action.value[0];
            returnableState.globalY = action.value[1];
            return returnableState;
        case LivemodeConstants.UPDATE_VISITED_TILES:
            returnableState = Object.assign({}, state);
            returnableState.visitedTiles[action.value[1]] = action.value[0];
            return returnableState;
        case LivemodeConstants.SYNC_VISITED_TILES_TRIGER:
            console.log("sync-1", action.value)
            returnableState = Object.assign({}, state);
            returnableState.syncVisitedTilesTrigger = action.value;
            return returnableState;
        
        default :
            returnableState = state
            return returnableState;
    }
}