import React, {Component} from "react";
import {connect} from 'react-redux';
import {Divider, Stack} from '@mui/material';
import HeadBarSettings from "./headbar_apps/headbar_settings";
import {MorphleLogoBW} from "../../utils/const";
import '../../asset/style/gammaviewer/header_gammaViewer.css'
import SplitSync from "./headbar_apps/SplitSync";
import GridOverlay from "./headbar_apps/GridOverlay";
import Screenshot from "./headbar_apps/Screenshot";
import ExportSlideToPdf from "./headbar_apps/ExportSlideToPdf";
import Profile from "./headbar_apps/Profile";
import ShareLink from "./headbar_apps/ShareLink";
import SlideName from "./headbar_apps/SlideName";
import {openEndPoint} from "../../utils/utils";
import CaseImage from "./headbar_apps/CaseImage";
import AnnotationDrawer from "./headbar_apps/anno_drawer";

class HeadBar extends Component {

    initData = () => {
        this.activeMapId = this.props.activeMapId;
        this.mapState = this.props.mapsState[this.activeMapId];
        this.slideState =this.mapState.slideState;
    }

    render() {
        if(Object.keys(this.props.mapsState).length === 0) {
            return <Stack direction={"row"} sx={{width: "100vw", height: '5vh'}}/>
        }
        for (let mapId in this.props.mapsState) {
            if (!this.props.mapsState[mapId].slideState)
                return <Stack direction={"row"} sx={{width: "100vw", height: '5vh'}}/>;
        }

        this.initData()

        return (
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}
                   width={"100vw"} height={'5vh'} bgcolor={"background.paper"}>
                <Stack spacing={2} justifyContent={"flex-start"}
                       direction={"row"}
                       divider={<Divider orientation="vertical" flexItem/>}>
                    <img src={MorphleLogoBW} className={"morphle-header-logo"} style={{cursor: 'pointer'}}
                         onClick={(event) =>
                             openEndPoint("/dashboard", event.ctrlKey)}
                         alt={"MorphleBWLogo"}
                         onAuxClick={() => openEndPoint("/dashboard", true)}
                    />
                    <HeadBarSettings/>
                    <AnnotationDrawer/>
                        <SplitSync />
                        <GridOverlay />
                        <Screenshot />
                        {this.slideState.slide_data.case !== null ? <CaseImage/> : null }
					<ExportSlideToPdf/>
					<></>
                </Stack>
                <Stack spacing={1} alignItems="center"
                    direction={"row"} justifyContent="flex-end"
                    divider={<Divider orientation="vertical" flexItem />}>
                    <div/>
                    {Object.keys(this.props.mapsState).length === 1 ? <SlideName mapId={0}/> : null}
                    <ShareLink />
                    <Profile />
                </Stack>
            </Stack>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        activeMapId: state.gammaStateReducer.activeMapId,
        mapsState: state.mapsStateReducer,
    };
};


export default connect(mapStateToProps)(HeadBar);
