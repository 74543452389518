import {BloodConfigActions} from '../actionTypes/bloodConfig.const'

export const yTopChange = (value) => ({
    type: BloodConfigActions.UPDATE_YTOP,
    response: value,
})

export const yBottomChange = (value) => ({
    type: BloodConfigActions.UPDATE_YBOTTOM,
    response: value,
})

export const countWBC = (value) => ({
    type: BloodConfigActions.UPDATE_WBC_COUNT,
    response: value,
})

export const countWBCSLotWiseUpdate = (value) => ({
    type: BloodConfigActions.UPDATE_COUNT_SLOT_WISE,
    response: value,
})

export const resetCountSlotWise = () => ({
    type: BloodConfigActions.RESET_CONFIG_SLOT_WISE,
})

export const yTopChanger = (y_val, txty_info, slide_info) => dispatch => {
    let y_val_pixel = (y_val)/slide_info.uperpixel;
    let y_pixel_transformed = (slide_info.stitched_tile_height*slide_info.stitched_y_max)-y_val_pixel
    let closest_dist=Number.MAX_VALUE
    let closest_txty = ''
    for(var key in txty_info){
        if(txty_info.hasOwnProperty(key)){
            if((Math.abs(txty_info[key]['pos']['Y']-y_pixel_transformed))<closest_dist){
                closest_txty = txty_info[key]
                closest_dist = Math.abs(txty_info[key]['pos']['Y']-y_pixel_transformed)
            }
        }
    }
    console.log("change", closest_txty.y_index)
    dispatch(yTopChange(parseInt(closest_txty.y_index)))
}

export const yBottomChanger = (y_val, txty_info, slide_info) => dispatch => {
    let y_val_pixel = (y_val)/slide_info.uperpixel;
    let y_pixel_transformed = (slide_info.stitched_tile_height*slide_info.stitched_y_max)-y_val_pixel
    let closest_dist=Number.MAX_VALUE
    let closest_txty = ''
    for(var key in txty_info){
        if(txty_info.hasOwnProperty(key) ){
            if((Math.abs(txty_info[key]['pos']['Y']-y_pixel_transformed))<closest_dist){
                closest_txty = txty_info[key]
                closest_dist = Math.abs(txty_info[key]['pos']['Y']-y_pixel_transformed)
            }
        }
    }
    console.log("change", closest_txty.y_index)     
    dispatch(yBottomChange(parseInt(closest_txty.y_index)))
}

export const updateCountSlotWise = (slot_id, count) => dispatch => {
    dispatch(countWBCSLotWiseUpdate([slot_id, count]))
}