import { Icon as LegacyIcon } from '@ant-design/compatible';
import Icon, {
    ApiOutlined, CheckOutlined,
    ClusterOutlined, ExclamationCircleOutlined, LinkOutlined,
    PicCenterOutlined, PoweroffOutlined, QuestionOutlined, VideoCameraOutlined
} from '@ant-design/icons';
import axios from 'axios';
import React, { Component } from "react";
import { connect } from "react-redux";
import { Badge, Row, Col, Spin, message, Button, Divider, notification, Progress, Alert } from 'antd';
import UserControls from "../component/scanner/user_controls"
import { ProgressStateConstant } from "../actionTypes/progress_state.constant"
import ScanWorkflow from "../component/scanner/scan_workflow"
import LoaderWorkflow from "../component/scanner/loader_workflow"
import { AuthHeader } from "../helper/auth.token"
import { globalUrlPrefix, progressStage } from "../utils/const"
import { updateLiveImagePolling } from "../action/liveview.action";
import { Link, Redirect } from 'react-router-dom';
import { watchPollStatus } from "../action/device_status.action";
// import ScanProgress from "../component/scanner/scan_progress";
import { sagaMiddleware } from "../helper/store";
// import { RegionSelectionConstants } from '../actionTypes/region_selection.constant';

class ScannerView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allow_workflow_access: false,
            starting_scanner: false, 
            scannerServerBusy: false, 
            scannerServerBusyMessage: "Stopping Scanner",
            any_present : false,
            keepCrashedNotification: true,
        }
        this.usbConnectionCodeDict = {
            DISCONNECTED : "DISCONNECTED",
            CONNECTED : "CONNECTED",
            INITIALISED : "INITIALISED", 
            ERRORED : "ERRORED", 
            DOES_NOT_EXIST: "DOES_NOT_EXIST",
            UNKNOWN: "UNKNOWN"
        }
        this.hardwareDict = {
            CAMERA : "Main Camera", 
            SERIAL : "Serial", 
            PREVIEW_CAMERA : "Preview Camera",
            UPS: "UPS",
            POWER: "Power"
        }
        this.powerStatusDict = {
            UNKNOWN : "UNKNOWN",
            DISCONNECTED : "DISCONNECTED",
            CONNECTED : "CONNECTED",
            ONLINE: "ONLINE",
            ONBATT: "ONBATT"
        }
        this.systemStateDict = {
            CRITICAL : "CRITICAL",
            WARNING : "WARNING",
            AVAILABLE : "AVAILABLE"
        }
        this.notificationTypeDict = {
            HDD : 'hdd', 
            SSD : 'ssd', 
            STOP : 'stop'
        }

        this.task = null
    }

    startScanner = () => {
        let id = this.props.match.params.id;
        this.setStartingScanner();
        let url = `/server/devices/` + id + '/start_scanner/';
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.setDoneStartingScanner();
                }
                else {
                    this.setDoneStartingScanner();
                    message.error("Failed to Start Scanner. Contact Admin");
                }
            })
            .catch(err => {
                this.setDoneStartingScanner();
                console.log(err);
            })

    }

    startScannerServer = () => {
        let id = this.props.match.params.id;
        this.setScannerServerBusy("Booting Scanner");
        let url = `/server/devices/` + id + '/start_scanner_server/';
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status !== 200) {
                    message.error("Failed to Start Scanner. Contact Admin!");
                    this.setScannerServerIdle();
                }
            })
            .catch(err => {
                this.setScannerServerIdle();
                console.log(err);
            })

    }

    stopScannerServer = () => {
        let id = this.props.match.params.id;
        this.setScannerServerBusy("Shutting Down Scanner");
        let url = `/server/devices/` + id + '/stop_scanner_server/';
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status !== 200) {
                    message.error("Failed to Stop Scanner. Contact Admin!");
                }
                this.setScannerServerIdle();
            })
            .catch(err => {
                this.setScannerServerIdle();
                console.log(err);
            })

    }

    setStartingScanner = () => this.setState(Object.assign({}, this.state, { starting_scanner: true }));

    setDoneStartingScanner = () => this.setState(Object.assign({}, this.state, { starting_scanner: false }));

    setScannerServerBusy = (message) => this.setState(Object.assign({}, this.state, { scannerServerBusy: true, scannerServerBusyMessage : message }));

    setScannerServerIdle = () => this.setState(Object.assign({}, this.state, { scannerServerBusy: false }));

    componentDidMount() {
        this.task = sagaMiddleware.run(watchPollStatus, this.props.dispatch, this.props.match.params.id);
        this.getLiveImagePollingStatus();
        this.allowWorkflowAccess();
    }

    componentWillUnmount() {
        this.task.cancel()
    }

    getLiveImagePollingStatus = () => {
        let partsOfUrl = "api~camera~get_live_image_polling_status";
        let url = `/server/scano/` + this.props.match.params.id + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(updateLiveImagePolling(response.data));
                }
                else {
                    console.log(response);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    allowWorkflowAccess = () => this.setState(Object.assign({}, this.state, { allow_workflow_access: true }));

    revokeWorkflowAccess = () => this.setState(Object.assign({}, this.state, { allow_workflow_access: false }));

    getIconType = (usbConnectionCode) => {
        if (usbConnectionCode === this.usbConnectionCodeDict.INITIALISED) {
            return <CheckOutlined className="hardware-status-icon" style={{color:this.getColorType(usbConnectionCode)}}/>
        } else if (usbConnectionCode === this.usbConnectionCodeDict.DISCONNECTED) {
            return <ApiOutlined className="hardware-status-icon" style={{color:this.getColorType(usbConnectionCode)}}/>
        } else if (usbConnectionCode === this.usbConnectionCodeDict.CONNECTED) {
            return <LinkOutlined className="hardware-status-icon" style={{color:this.getColorType(usbConnectionCode)}}/>
        } else if (usbConnectionCode === this.usbConnectionCodeDict.ERRORED) {
            return <ExclamationCircleOutlined />
        } else if (usbConnectionCode === this.usbConnectionCodeDict.UNKNOWN) {
            return <QuestionOutlined className="hardware-status-icon" style={{color:this.getColorType(usbConnectionCode)}}/>
        }
    }

    getColorType = (usbConnectionCode) => {
        if (usbConnectionCode === this.usbConnectionCodeDict.INITIALISED) {
            return "#27ae60";
        } else if (usbConnectionCode == this.usbConnectionCodeDict.DISCONNECTED) {
            return "#c0392b";
        } else if (usbConnectionCode == this.usbConnectionCodeDict.CONNECTED) {
            return "#27ae60";
        } else if (usbConnectionCode == this.usbConnectionCodeDict.ERRORED) {
            return "#c0392b";
        } else if (usbConnectionCode == this.powerStatusDict.ONBATT || usbConnectionCode == this.usbConnectionCodeDict.UNKNOWN) {
            return "#FF0000";
        } 
    }

    getHelpText = (device, usbConnectionCode) => {
        let line1, line2;

        if (usbConnectionCode === this.usbConnectionCodeDict.INITIALISED) {
            line1 = device + " is connected and initialised";
            line2 = "";
        } else if (usbConnectionCode === this.usbConnectionCodeDict.DISCONNECTED) {
            line1 = device + " is disconnected";
            line2 = "Please reconnect the cable tagged " + device;
            if(device === this.hardwareDict.POWER){
                line1 = "Please switch on the power button of machine and Initialize";
                line2 = "Note: Do not disconnect any of USB cables, if you do restart scanner.";
            }
        } else if (usbConnectionCode === this.usbConnectionCodeDict.CONNECTED) {
            line1 = device + " is connected and ready for startup";
            line2 = "";
        } else if (usbConnectionCode === this.usbConnectionCodeDict.ERRORED) {
            line1 = "Please remove and reconnect the cable tagged " + device;
            line2 = "Click the START SCANNER button once done";
        }else{
            line1 = "Checking the Status";
            line2 = "";
        }

        return [
            <span key={0}>{line1}</span>, 
            <br key={1}></br>,
            <span key={2} className="hardware-section-retry">{line2}</span>
        ]
    }

    getNotificationTitle = (stateVariable, type, crashedScan = {}) => {
        let title;
        if(type === this.notificationTypeDict.SSD){
            if(stateVariable.ssdStatus === this.systemStateDict.WARNING){
                title = "Low Disk Space on SSD";
            } else if (stateVariable.ssdStatus === this.systemStateDict.CRITICAL){
                title = "Critically Low Disk Space on SSD";
            }    
        }else if(type === this.notificationTypeDict.HDD){
            if(stateVariable.hddStatus === this.systemStateDict.WARNING){
                title = "Low Disk Space on HDD";
            } else if (stateVariable.hddStatus === this.systemStateDict.CRITICAL){
                title = "Critically Low Disk Space on HDD";
            }
        }else if(type === this.notificationTypeDict.STOP){
            title = "Cancelling Scan"
        }else if(type === this.notificationTypeDict.CRASHED){
            title = crashedScan.scanName
        }
        console.log(title)
        return title;
    }

    getNotificationText = (stateVariable, type, crashedScan = {}) => {
        let text;
        console.log(crashedScan)
        if(type === this.notificationTypeDict.SSD){
            if(stateVariable.ssdStatus === this.systemStateDict.WARNING){
                text = "Please delete unwanted items from SSD for smoother scanning.";
            } else if (stateVariable.ssdStatus === this.systemStateDict.CRITICAL){
                text = "Please analyze and make disk space on SSD to regain access to scanning.";
            }    
        }else if(type === this.notificationTypeDict.HDD){
            if(stateVariable.hddStatus === this.systemStateDict.WARNING){
                text = "Please consider deleting some scans or upload scans to cloud.";
            } else if (stateVariable.hddStatus === this.systemStateDict.CRITICAL){
                text = "Delete scans or upload to cloud to regain access to scanning.";
            }
        }else if(type === this.notificationTypeDict.STOP){
            text = "Critically Low Disk Space. Cancelling scans.";
        }else if(type === this.notificationTypeDict.CRASHED){
            text = crashedScan.message;
        }
        return (<div>{text}<br></br>{"Please contact support for further help."}</div>);
    }


    getIconForDevice = (device) => {
        if (device === this.hardwareDict.CAMERA) {
            return <VideoCameraOutlined className="hardware-status-icon" />
        } else if (device === this.hardwareDict.SERIAL) {
            return <ClusterOutlined className="hardware-status-icon" />
        }  else if (device === this.hardwareDict.PREVIEW_CAMERA) {
            return <PicCenterOutlined className="hardware-status-icon" />
        } else if (device === this.hardwareDict.UPS) {
            return <PoweroffOutlined className="hardware-status-icon" />
        } 
    }

    getHardwareRowPanel = (device, status) => {
        return (
            <Row className="hardware-status-row">
                <Col span={3} className="hardware-status-icon-div">
                    {this.getIconForDevice(device)}
                </Col>
                <Col span={17} className="hardware-section-header">
                    <b>{device}</b>
                    <Divider />
                    {this.getHelpText(device, status)}
                </Col>
                <Col span={4} className="hardware-status-icon-div">
                    {this.getIconType(status)}
                </Col>
            </Row>
        );
    }

    getPowerStatusBlock = (deviceHEAD, deviceText, status) => {
        return <div>
        <Col span={17} className="hardware-section-header">
            <b>{deviceHEAD}</b>
            <Divider />
            {this.getHelpText(deviceText, status)}
        </Col>
        <Col span={4} className="hardware-status-icon-div">
            <Icon 
                className="hardware-status-icon" 
                type={status === this.usbConnectionCodeDict.DISCONNECTED && deviceText === this.hardwareDict.POWER? 'alert' :this.getIconType(status)}
                style={{color:this.getColorType(status)}} >
            </Icon>
        </Col>
        </div>
    }

    getUPSRowPanel = (device, status, upsPowerStatus, upsMandatory, powerStatus) => {
        return (
            <Row className="hardware-status-row">
                <Col span={3} className="hardware-status-icon-div">
                    {this.getIconForDevice(device)}
                </Col>
                {upsMandatory ? upsPowerStatus.powerStatus === this.powerStatusDict.ONBATT ?
                <div>
                <Col span={17} className="hardware-section-header">
                    <b>{device}</b>
                    <Divider />
                    <Row className="power-fail-message">
                        <span style={{color:"#c0392b"}}><ExclamationCircleOutlined></ExclamationCircleOutlined></span>  &nbsp;
                        UPS is not connected to wall power socket. Please check. 
                    </Row>
                </Col>
                <Col span={4} className="hardware-status-icon-div">
                    <Icon 
                        className="hardware-status-icon" 
                        type='alert' 
                        style={{color:this.getColorType(upsPowerStatus.powerStatus)}} >
                    </Icon>
                </Col>
                </div> : powerStatus === this.usbConnectionCodeDict.DISCONNECTED && status !== this.usbConnectionCodeDict.DISCONNECTED ? this.getPowerStatusBlock(device, this.hardwareDict.POWER, powerStatus)
                : this.getPowerStatusBlock(device, device, status) : this.getPowerStatusBlock(this.hardwareDict.POWER, this.hardwareDict.POWER, powerStatus)}
            </Row>
        );
    }

    openPowerNotification = (key, upsPowerStatus) => {
        notification.open({
            key,
            message: upsPowerStatus.powerStatus === this.powerStatusDict.ONBATT ? 'Scanner on Battery Power' : 
                        'UPS disconnected from computer',
            icon: <LegacyIcon type={upsPowerStatus.timeToShutdownInSeconds > 0 ?'alert':'frown'} style={{color:"#FF0000"}} ></LegacyIcon>,
            duration: 0,
            description: upsPowerStatus.timeToShutdownInSeconds > 0 ? <div><b>{upsPowerStatus.powerStatus === this.powerStatusDict.ONBATT ? 'Please consider reconnecting to power supply.':
                  'Unable to get the the power state. Please check the cable.'}</b>
             <p>Time remaininig before the shutdown. </p>
             <Progress status="active" percent={(upsPowerStatus.timeToShutdownInSeconds/upsPowerStatus.timeBufferForPowerFailure)*100} 
                       showInfo={false} 
                       strokeColor={{
                        from: '#ff3300',
                        to: '#87d068',
                      }}/>
             {Math.floor(upsPowerStatus.timeToShutdownInSeconds/60)}:{upsPowerStatus.timeToShutdownInSeconds%60} Minutes</div> :
        <div>{"Scanner Stopped. Connect the Power Supply to resume."}</div>,
            placement: 'bottomRight',
        });
    };

    closeNotification = (key) => {
        notification.close(key);
    };

    isObjEmpty = (obj) => {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    openSystemStateNotification = (key, systemState, type, crashedScan = {}) => {
        if(!this.isObjEmpty(systemState)){
            notification.open({
                key,
                message: this.getNotificationTitle(systemState, type, crashedScan),
                icon: <LegacyIcon type={type === this.notificationTypeDict.HDD || type === this.notificationTypeDict.SSD ? 'hdd':'frown'} 
                style={{color: (systemState.hddStatus === this.systemStateDict.WARNING && type === this.notificationTypeDict.HDD) || 
                        (systemState.ssdStatus === this.systemStateDict.WARNING && type === this.notificationTypeDict.SSD) ? 
                    "#EA8D16" : "#FF0000"}} ></LegacyIcon>,
                duration: 0,
                description: this.getNotificationText(systemState, type, crashedScan),
                placement: 'bottomRight',
                onClose: this.close
            });
        }
    };

    close = () => {
        this.setState({
            keepCrashedNotification: false
        })
    };

    checkStage = () => {
        let id = this.props.match.params.id;
        this.setStartingScanner();
        let url = `/server/devices/` + id + "/manualmode/check-stage/";
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                message.info("Stage Check Success");
                this.setDoneStartingScanner();
            })
            .catch(err => {
                message.error("Stage Check Failure");
                this.setDoneStartingScanner();
            });
    }

    render() {
        // commented because unused
        // let taken_any_preview = true;
        // // console.log("prevs ", this.props.previews);
        // // console.log("loader_present",)
        // for(var key in this.props.previews){
        //     console.debug(key, this.props.previews[key].status);
        //     if(this.props.previews[key].status !== undefined && 
        //        this.props.previews[key].status !== RegionSelectionConstants.NOT_STARTED){
        //         taken_any_preview = true;
        //     }
        // }

        if (!this.props.sessionValid)
            return <Alert message="You have just logged in somewhere else. This session will logout! Please login again to activate this session" banner />

        let allUsbDevicesConnected = true; // FIXME: this is actually all USB and RJ45 devices (since we have a RPi preview camera now)
        let scannerStatus = (((this.props.device || {}).scanner_health || {}).scanner_response || {});
        let usbDependenciesStatus = (scannerStatus || {}).usbDependenciesStatus;
        let httpDependenciesStatus =  (scannerStatus || {}).httpDependenciesStatus;
        let upsPowerStatus = ((scannerStatus || {}).upsPowerStatus || {});
        let powerStatus = ((scannerStatus || {}).powerStatus || {});
        let upsMandatory = ((((this.props.device || {}).scanner_health || {}).scanner_response || {}).usbDependenciesStatus || {}).upsStatus !== this.usbConnectionCodeDict.DOES_NOT_EXIST;
        let systemState = ((scannerStatus || {}).systemState || {});
        let isCriticalDisk = systemState.ssdStatus === this.systemStateDict.CRITICAL || systemState.hddStatus === this.systemStateDict.CRITICAL;
        let isMorpheusReachable = ((this.props.device || {}).scanner_health || {}).device_name !== undefined;
        let isServerReachable = ((this.props.device || {}).scanner_health || {}).server_up;
        isServerReachable = isServerReachable === undefined ? false : isServerReachable;
        let hardwareError = false;
        let stageEncoderFailure = scannerStatus.stageEncoderFailure;

        let recommendServerRestart = (scannerStatus || {}).recommendServerRestart;
        recommendServerRestart = recommendServerRestart === undefined ? false : recommendServerRestart;

        if (usbDependenciesStatus !== undefined) {
            for (let key in usbDependenciesStatus) {
                if (usbDependenciesStatus[key] !== this.usbConnectionCodeDict.INITIALISED && key !== 'upsStatus') {
                    hardwareError = true;
                }
                if (usbDependenciesStatus[key] !== this.usbConnectionCodeDict.INITIALISED && 
                    usbDependenciesStatus[key] !== this.usbConnectionCodeDict.CONNECTED && key !== 'upsStatus'
                    && key !== 'previewStatus') {
                    allUsbDevicesConnected = false;
                }
                if (key === 'previewStatus' 
                    && ((usbDependenciesStatus['previewStatus'] !== this.usbConnectionCodeDict.INITIALISED
                         && usbDependenciesStatus['previewStatus'] !== this.usbConnectionCodeDict.CONNECTED)
                       && (httpDependenciesStatus['piCameraStatus'] !== this.usbConnectionCodeDict.INITIALISED
                         && httpDependenciesStatus['piCameraStatus'] !== this.usbConnectionCodeDict.CONNECTED)) ) {
                    allUsbDevicesConnected = false;
                }
            }

            // Check for ups Power connection on start
            
            if (upsMandatory) {
                if(upsPowerStatus.deviceStarted === false 
                    && upsPowerStatus.powerStatus === this.powerStatusDict.ONBATT) {
                    allUsbDevicesConnected = false;
                }
                
                if(upsPowerStatus.deviceStarted === false 
                    && usbDependenciesStatus.upsStatus !== this.usbConnectionCodeDict.INITIALISED) {
                    allUsbDevicesConnected = false;
                }
            }
        }
    
        // commented because unused
        // let readyToStart = ((this.props.device || {}).scanner_health || {}).ready_to_start;
        // readyToStart = readyToStart === undefined ? false : readyToStart;

        let isScanning = scannerStatus.scanning; 
        isScanning = isScanning === undefined ? false : isScanning;
        let isInitialized = scannerStatus.initialized; 
        isInitialized = isInitialized === undefined ? false : isInitialized;
        let isStartedInit = scannerStatus.startedInit; 
        isStartedInit = isStartedInit === undefined ? false : isStartedInit;

        // commented because unused
        // let istakingPreviews = scannerStatus.takingPreviews;
        // istakingPreviews = istakingPreviews === undefined ? false : istakingPreviews;
        // commented because unused
        // let isusingLoader = scannerStatus.usingLoader;
        // isusingLoader = isusingLoader === undefined ? false : isusingLoader;
        let isBusy = scannerStatus.busy;
        isBusy = isBusy === undefined ? false : isBusy;
        let uiBusy = isBusy || (this.props.device || {}).uiBusy;
        uiBusy = uiBusy === undefined ? false : uiBusy;
        let progressState = this.props.match.params.progress_stage === undefined ? ProgressStateConstant.MAINPAGE : this.props.match.params.progress_stage;

        let loaderPresent = ((((this.props.device || {}).scanner_health || {}).scanner_response || {}).loaderConfig || {}).loaderPresent;
        
        let scanner_control = null;
        let busyMessage = null;

        if (this.state.scannerServerBusy || ((this.props.device || {}).scanner_health || {}).server_starting) {
            uiBusy = true;
            busyMessage = this.state.scannerServerBusyMessage;
        }

        if (isBusy) {
            let busyOrder = scannerStatus.busyOrder;
            let busyDict = scannerStatus.busyDict;
            busyMessage = busyOrder.length > 0 ? busyDict[busyOrder[busyOrder.length - 1]] : "";
        } else if (uiBusy) {
            isBusy = true;
        }

        if (isServerReachable && recommendServerRestart) {
            scanner_control = <Row className="rounded-container">
                <Col className="hardware-status-panel" span={12} offset={6}>
                    <Row className="troubleshoot-fail-message">
                        <span style={{color:"#c0392b"}}><ExclamationCircleOutlined></ExclamationCircleOutlined></span>  &nbsp;
                        Hardware is not able to restart after multiple resets.
                    </Row>
                    <Row className="troubleshoot-steps">
                        <ol>
                            <li>Remove the cable tagged <b>CAMERA</b></li>
                            <li>Put the cable tagged <b>CAMERA</b> in the same USB port it was removed from</li>
                            <li>Remove the two cables tagged <b>SERIAL</b> and <b>PREVIEW CAMERA</b></li>
                            <li>Put the cables tagged <b>SERIAL</b> and <b>PREVIEW CAMERA</b> in the same USB and LAN ports they were removed from</li>
                            <li>Check the main <b>POWER</b> cable and switch. The switch should be in the <b>ON</b> position and lit <b>RED</b></li>
                            <li>Click the <b>SHUTDOWN SCANNER</b> button below and then click <b>START SCANNER</b> on the next screen</li>
                        </ol>
                    </Row>
                    <Row>
                        <Col offset={10} span={8} onClick={this.stopScannerServer} style={{cursor: 'pointer'}}>
                            <Row style={{color:"#22A7F0"}}>
                                <Col offset={2} span={22}>
                                    <PoweroffOutlined className="scanner-main-control-icon" style={{color: 'red', fontSize: '50px'}} />
                                </Col>
                            </Row>
                            <Row className="arial-font">
                                <Col offset={1} span={14}>Shutdown Scanner</Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        } else if (stageEncoderFailure) {
            scanner_control = <Row className="rounded-container">
                <Col className="hardware-status-panel" span={12} offset={6}>
                    <Row span={17} className="hardware-section-header">
                        <h2>{"Stage is not set properly. Please fix the stage and check again."}</h2>
                        {scannerStatus.encoderMessage}
                    </Row>
                    <Row className="scanner-start-button-div">
                        <Button className="scanner-start-button" type="primary" size="large" onClick={this.checkStage}>
                            Check Stage
                        </Button>
                    </Row><Divider></Divider>
                    <Row span={17} className="hardware-section-header">
                        <h4>{"Note: Contact Support if unable to fix this after multiple attempts."}</h4>
                    </Row>
                </Col>
            </Row>
        }else if (!isInitialized && (!allUsbDevicesConnected || hardwareError) && this.props.device && usbDependenciesStatus) {
            if (this.state.scannerServerBusy) {
                this.setScannerServerIdle();
            }
            let previewCameraStatus = (usbDependenciesStatus["previewStatus"] === this.usbConnectionCodeDict.CONNECTED)
                                        || (httpDependenciesStatus["piCameraStatus"] === this.usbConnectionCodeDict.CONNECTED) ? 
                                        this.usbConnectionCodeDict.CONNECTED 
                                        : ( (usbDependenciesStatus["previewStatus"] === this.usbConnectionCodeDict.INITIALISED) 
                                            || (httpDependenciesStatus["piCameraStatus"] === this.usbConnectionCodeDict.INITIALISED) ) ? 
                                            this.usbConnectionCodeDict.INITIALISED : this.usbConnectionCodeDict.DISCONNECTED;
            scanner_control = <Row className="rounded-container">
                <Col className="hardware-status-panel" span={12} offset={6}>
                    <Row span={17} className="hardware-section-header">
                        {"Please ensure that scanner is connected to uninteruppted power supply"}
                    </Row>
                    { isStartedInit ? this.getUPSRowPanel(this.hardwareDict.UPS, usbDependenciesStatus["upsStatus"], upsPowerStatus, upsMandatory, powerStatus): undefined}
                    {this.getHardwareRowPanel(this.hardwareDict.SERIAL, usbDependenciesStatus["arduinoStatus"])}
                    {this.getHardwareRowPanel(this.hardwareDict.CAMERA, usbDependenciesStatus["cameraStatus"])}
                    {this.getHardwareRowPanel(this.hardwareDict.PREVIEW_CAMERA, previewCameraStatus )}
                    <Row className="scanner-start-button-div">
                        <Button className="scanner-start-button" type="danger" size="large" disabled={!allUsbDevicesConnected || isCriticalDisk} onClick={this.startScanner}>
                            Initialize Scanner
                        </Button>
                    </Row>
                </Col>
            </Row>
        } else if (isMorpheusReachable && !isServerReachable) {
            scanner_control = <Row className="rounded-container">
                <Col className="hardware-status-panel" span={12} offset={6} style={{borderWidth:0}}>
                    <Row className="scanner-start-button-div">
                        <Button className="scanner-start-button" type="danger" size="large" onClick={this.startScannerServer}>
                            Start Scanner 
                            {/* <br></br>
                            Morphle Digital Pathology  */}
                        </Button>
                    </Row>
                </Col>
            </Row>
        } else if (isMorpheusReachable) {
            if (loaderPresent) {
                scanner_control = <LoaderWorkflow id={this.props.match.params.id} revokeWorkflowAccess={this.revokeWorkflowAccess} />;
            }
            else {
                // console.log("Can come here");
                // if (taken_any_preview) 
                // {
                //     // scanner_control = <ScanProgress 
                //     //                     id={this.props.match.params.id}
                //     //                     loaderPresent={loaderPresent} />
                //     console.log("at preview stage");
                //     window.location.pathname = globalUrlPrefix + '/scannerflow/' + this.props.match.params.id + '/scan/1' ;//+ progressStage.SET_PARAMETERS;
                // } else 
                if (isScanning) {
                    window.location.pathname = globalUrlPrefix + '/scannerflow/' + this.props.match.params.id + '/scan/' + progressStage.SCANNING;
                } else if (progressState === ProgressStateConstant.WORKFLOW) {
                    if (this.state.allow_workflow_access || loaderPresent) {
                        if (loaderPresent !== undefined) {
                            scanner_control = loaderPresent ? <LoaderWorkflow id={this.props.match.params.id} revokeWorkflowAccess={this.revokeWorkflowAccess} /> :
                                <ScanWorkflow id={this.props.match.params.id} revokeWorkflowAccess={this.revokeWorkflowAccess} />
                        }
                    } else {
                        scanner_control = <Redirect to={'/' + globalUrlPrefix + '/scanners/' + this.props.match.params.id + '/'}></Redirect>
                    }
                } else if (allUsbDevicesConnected) {
                    scanner_control = <UserControls 
                                        id={this.props.match.params.id} 
                                        allowWorkflowAccess={this.allowWorkflowAccess}
                                        isServerReachable={isServerReachable}
                                        isMorpheusReachable={isMorpheusReachable}
                                        loaderPresent={loaderPresent}
                                        stopScannerServer={this.stopScannerServer}
                                        startScannerServer={this.startScannerServer} />
                } else {
                    scanner_control = 
                        <Row className="rounded-container">
                            <Col className="hardware-status-panel" span={12} offset={6}>
                                <Row className="troubleshoot-fail-message">
                                    <span style={{color:"#c0392b"}}><ExclamationCircleOutlined></ExclamationCircleOutlined></span>  &nbsp;
                                    All required Hardware is not connected.
                                </Row>
                                <Row className="troubleshoot-steps">
                                    <ol>
                                        <li>Please wait for at most 2 minutes to allow the hardware to recover on its own.</li>
                                    </ol>
                                </Row>
                                <Row>
                                    <Col offset={9} span={8} onClick={this.stopScannerServer} style={{cursor: 'pointer'}}>
                                        <Row style={{color:"#22A7F0"}}>
                                            <Col offset={3} span={22}>
                                                <PoweroffOutlined className="scanner-main-control-icon" style={{color: 'red', fontSize: '50px'}} />
                                            </Col>
                                        </Row>
                                        <Row className="arial-font">
                                            <Col offset={0} span={14}>Force Shutdown Scanner</Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                }
            }
        } else {
            scanner_control = <Row className="rounded-container">
                <Col className="hardware-status-panel" span={12} offset={6} style={{borderWidth:0}}>
                    <Row className="scanner-start-button-div">
                        Scanner Server is Down. Please contact Administrator.<br/>
                        Please check your internet connection, and reload page.
                    </Row>
                    <Row className="scanner-start-button-div">
                        <Button
                            className="scanner-start-button"
                            type="primary"
                            size="large"
                            onClick={() => window.location.reload()}>
                            Retry
                        </Button>
                    </Row>
                </Col>
            </Row>
        }

        const powerNotifierKey = "powerNotification"; 
        if(upsPowerStatus.deviceStarted === true && 
            (upsPowerStatus.powerStatus === this.powerStatusDict.ONBATT || upsPowerStatus.powerStatus === this.usbConnectionCodeDict.DISCONNECTED)){
                this.openPowerNotification(powerNotifierKey, upsPowerStatus)
            }
            else{
                this.closeNotification(powerNotifierKey)
        }

        // [TODO] Move notification keys to a map
        const systemNotifierKeySSD = "systemNotificationSSD"; 
        const systemNotifierKeyHDD = "systemNotificationHHD"; 
        const systemNotifierKeyStop = "systemNotificationStop";
        const systemNotifierKeyCrashed = "systemNotificationCrashed";
        if(systemState.ssdStatus !== this.systemStateDict.AVAILABLE){
            this.openSystemStateNotification(systemNotifierKeySSD, systemState, this.notificationTypeDict.SSD)
        }else{
            this.closeNotification(systemNotifierKeySSD)
        }
        if(systemState.hddStatus !== this.systemStateDict.AVAILABLE){
            this.openSystemStateNotification(systemNotifierKeyHDD, systemState, this.notificationTypeDict.HDD)
        }else{
            this.closeNotification(systemNotifierKeyHDD)
        }
        if(systemState.stoppingScan === true){
            this.openSystemStateNotification(systemNotifierKeyStop, systemState, this.notificationTypeDict.STOP)
        }else{
            this.closeNotification(systemNotifierKeyStop)
        }
        let crashedScantimeStamp = (((scannerStatus || {}).crashedScan || {}).timeStamp || 0)
        if((crashedScantimeStamp != 0) && (crashedScantimeStamp != (localStorage.getItem("crashedScanTimeStamp") || 0))){
            this.openSystemStateNotification(systemNotifierKeyCrashed, systemState, this.notificationTypeDict.CRASHED, ((scannerStatus || {}).crashedScan || {}))
            localStorage.setItem("crashedScanTimeStamp", (((scannerStatus || {}).crashedScan || {}).timeStamp || 0))
        }


        if (scanner_control === null || scanner_control === undefined) {
            console.error("code bug, scanner_control is null or undefined");
        }

        let process_status = ((this.props.device || {}).scanner_health || {}).process_status;
        let badges = [];
        for(var p in process_status){
            if(process_status[p][1] == 0){
                badges.push(<Badge status="default" />)
            }else if(process_status[p][1] == 1){
                badges.push(<Badge status="processing" />)
            }else{
                badges.push(<Badge status="error" />)
            }
        }

        return (
            <div>
                <Row className="rounded-container scanner-name-div">
                    <Col className="scanner-name arial-font" span={23}>
                        <Link to={"/" + globalUrlPrefix + "/scanners/" + this.props.match.params.id + "/"} className="device-name">
                            {((this.props.device || {}).scanner_health || {}).device_name + " - Control Panel"}
                        </Link>
                        {/* { (isServerReachable) && !loaderPresent ? 
                            <div style={{height: 'auto', width: '100px', float: 'right'}}>
                                <PoweroffOutlined onClick={this.stopScannerServer} style={{color: 'red', fontSize: '50px'}}/>
                            </div>
                        : null }
                        { (isMorpheusReachable && !isServerReachable) ?
                            <div style={{height: 'auto', width: '100px', float: 'right'}}>
                                <PoweroffOutlined onClick={this.startScannerServer} style={{color: 'green', fontSize: '50px'}}/>
                            </div>
                        : null } */}
                    </Col>
                    <Col span={1}>
                       {badges}
                    </Col>
                </Row>
                {/* <Spin spinning={(((isBusy && !isScanning) || uiBusy) && !loaderPresent) ||  */}
                <Spin spinning={uiBusy || this.state.starting_scanner} tip={busyMessage} size="large">
                    {scanner_control}
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        device: state.deviceStatusReducer[ownProps.match.params.id],
        previews: state.previewStatusReducer,
        sessionValid: state.sessionStatusReducer.sessionValid,
    };
};

export default connect(mapStateToProps)(ScannerView);
