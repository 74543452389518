import {store} from "../../../helper/store";
import {updateAppsInstalled} from "../../../action/morpheus.setting.action";

export const checkAppPermission = app => {
	let morpheusSetting = store.getState().morpheusSettingReducer;
	app.permission = morpheusSetting.apps_installed[app.id];
	if (app.permission === undefined)
		store.dispatch(updateAppsInstalled({
			...morpheusSetting.apps_installed,
			[app.id]: app.defaultPermission || false,
		}));
	return app.permission;
}
