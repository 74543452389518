import React, {Component} from "react";
import {connect} from "react-redux";
import TawkTo from "tawkto-react";
import {TawkBotConfig} from "../../../utils/const";

class TawkBot extends Component {

	constructor(props) {
		super(props);

		this.state = {
			tawkBot: null,
		}
	}

	componentDidMount = () => {
		let newTawkBot = new TawkTo(TawkBotConfig.propertyId, TawkBotConfig.widgetId);
		newTawkBot.setAttributes(
			{
				visibility: {
					position: 'br'
				}
			},
			function (error) {
				console.log("tawkbot", error);
			}
		)
		newTawkBot.showWidget();
		this.setState({tawkBot: newTawkBot});
	}

	componentWillUnmount() {
		this.state.tawkBot.hideWidget();
	}

	render() {
		// TawkTo API automcatically creates an element and appends it to the page, no need to return anything here.
		return <></>
	}
}

const mapStateToProps = (state) => {
	return {
		gammaState: state.gammaStateReducer,
	}
}

export default connect(mapStateToProps)(TawkBot);