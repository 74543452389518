import { SearchAlertCaseConstant } from "../actionTypes/search.case.constant";
import { loadCases, loadCaseStatus, loadUsers } from "./cases.action";
import { getNewState } from "../reducer/search.case.reducer";

export const searchInitiated = () => ({
    type: SearchAlertCaseConstant.CASE_SEARCH_INITIATED
})

export const searchSuccess = () => ({
    type: SearchAlertCaseConstant.CASE_SEARCH_SUCCESS
})

export const searchFailed = (error) => ({
    type: SearchAlertCaseConstant.CASE_SEARCH_FAILED,
    response: error
})

export const clearAllFilters = () => dispatch => {
    dispatch(loadCases(getNewState()));
}

export const getAssignedCases = (currentState) => dispatch => {
    dispatch(loadCases({
        ...currentState,
        case_assigned: true, 
        unread: false, 
        read: false, 
        page: 1
    }))
}

export const getUnreadCases = (currentState) => dispatch => {
    dispatch(loadCases({
        ...currentState,
        case_assigned: false, 
        unread: true, 
        read: false, 
        page: 1
    }))
}

export const getReadCases = (currentState) => dispatch => {
    dispatch(loadCases({
        ...currentState,
        case_assigned: false, 
        unread: false, 
        read: true, 
        page: 1
    }))
}

export const getAll = (currentState) => dispatch => {
    dispatch(loadCases({
        ...currentState,
        case_assigned: false, 
        unread: false, 
        read: false, 
        page: 1
    }))
}

export const changePage = (pageNumber, currentState) => dispatch => {
    dispatch(loadCases({
        ...currentState, 
        page: pageNumber
    }))
}

export const updateCaseSearch = (value, currentState) => dispatch => {
    var newState = {
        ...currentState,
        search: value, 
        page: 1
    }
    dispatch(loadCases(newState));
}

export const setDateRange = (newDateGte, newDateLte, currentState) => dispatch => {
    dispatch(loadCases({
        ...currentState,
        date__gte: newDateGte,
        date__lte: newDateLte,
        page: 1
    }));
}

export const updateValidationId = (value, currentState) => dispatch => {
    var newState = {
        ...currentState,
        validation_id : value,
        page: 1
    }
    dispatch(loadCases(newState))
}

export const changeOrdering = (newOrdering, currentState) => dispatch => {
    dispatch(loadCases({
        ...currentState,
        ordering: newOrdering,
        page: 1
    }));
}

export const updateUrlStateInReducer = (oldState) => dispatch => {
    var newState = urlParser(oldState);
    dispatch(loadCases(newState));
    dispatch(loadCaseStatus());
    dispatch(loadUsers());
}

export const updateUrlStateInReducerClia = (oldState, type) => dispatch => {
    var newState = urlParserClia(oldState,type);
    console.log("newstate",newState)
    dispatch(loadCases(newState));
    dispatch(loadCaseStatus());
    dispatch(loadUsers());
}

export const urlParserClia = (parsedPartsOfUrl, type) => {
    try{
        var url = window.location.href.split('?')[1];
        var validation_id =  window.location.href.split('/')[5].split('?')[0];
        
        // console.log("vvv_id", validation_id, window.location.href.split('/')[5].split('?')[0])
        var partsOfUrl = url.split('&');
        let case_assigned_flag = false;
        partsOfUrl.map((part) => {
            var key = part.split('=')[0];
            var value = part.split('=')[1];
            if (key === 'search') {
                if (value.length > 0) parsedPartsOfUrl.search = value;
            } else if (key === 'case_assigned') {
                case_assigned_flag = true;
                parsedPartsOfUrl.case_assigned = value === 'true';
            } else if (key === 'unread') {
                parsedPartsOfUrl.unread = value === 'true';
            } else if (key === 'read') {
                parsedPartsOfUrl.read = value === 'true';
            } else if (key === 'page') {
                parsedPartsOfUrl.page = parseInt(value);
            } else if (key === 'ordering') {
                parsedPartsOfUrl.ordering = value;
            } else if (key === 'date__gte') {
                parsedPartsOfUrl.date__gte = value;
            } else if (key === 'date__lte') {
                parsedPartsOfUrl.date__lte = value;
            }
        });
        if (!case_assigned_flag) {
            parsedPartsOfUrl.case_assigned = false;
        }
    }catch(err){
        console.log('Could not parse Url : ' + err.message);
    }
    parsedPartsOfUrl.validation_id = validation_id;
    parsedPartsOfUrl.type = type;
    return parsedPartsOfUrl;
}

export const urlParser = (parsedPartsOfUrl) => {
    try {
        var url = window.location.href.split('?')[1];
        var partsOfUrl = url.split('&');
        let case_assigned_flag = false;
        partsOfUrl.map((part) => {
            var key = part.split('=')[0];
            var value = part.split('=')[1];
            if (key === 'search') {
                if (value.length > 0) parsedPartsOfUrl.search = value;
            } else if (key === 'case_assigned') {
                case_assigned_flag = true;
                parsedPartsOfUrl.case_assigned = value === 'true';
            } else if (key === 'unread') {
                parsedPartsOfUrl.unread = value === 'true';
            } else if (key === 'read') {
                parsedPartsOfUrl.read = value === 'true';
            } else if (key === 'page') {
                parsedPartsOfUrl.page = parseInt(value);
            }
        });
        if (!case_assigned_flag) {
            parsedPartsOfUrl.case_assigned = false;
        }
    } catch (err) {
        console.log('Could not parse Url : ' + err.message);
    }
    return parsedPartsOfUrl;
}