import {LOAD_VIEWER_SETTINGS, UPDATE_VIEWER_SETTING} from "../actionTypes/viewer.settings.const";

export const viewerSettingsReducer = (state = {}, action) => {

	switch (action.type) {
		case LOAD_VIEWER_SETTINGS:
			return action.settings.reduce((settings, setting) => Object.assign(settings, {
				[setting.id]: setting,
			}), {});

		case UPDATE_VIEWER_SETTING:
			return Object.assign({}, state, {
				[action.setting.id]: Object.assign({}, state[action.setting.id], action.setting),
			});

		default:
			return state;
	}
}
