import React, { Component } from 'react';
import axios from 'axios';
import cookie from 'react-cookies';
import { AuthHeader } from '../../helper/auth.token';
import { Row, Col, Input, Checkbox, Divider, Button, Slider, InputNumber, message } from 'antd';
import { connect } from "react-redux";
import { updateInputFieldInFocus, updateMovementStepSize } from "../../action/livemode.action";
import { updateLiveModeAction } from '../../action/admin.state.action';
import { liveModeLoadingMessages } from "../../utils/const";

class LiveModeGeneralSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coarseFocusZLevel: 1500,
        }
    }

    stepSizeChange = (value) => {
        this.props.dispatch(updateMovementStepSize(value));
    }

    setCoarseFocusZLevel = (e) => {
        this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.SETTINGS_COARSE_Z_LEVEL));
        let partsOfUrl = "api~stage~set_drop_window_live_mode";
        let url = `/server/scano/` + this.props.livemode.deviceId + `/` + partsOfUrl + '?z_level=' + this.state.coarseFocusZLevel;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    message.success("Coarse Focus Z Level set successfully!!", 2.5);
                }
                else {
                    console.log(response);
                    message.error("Not able to set z level!!", 2.5);
                    this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.SETTINGS_COARSE_Z_LEVEL));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set z level!!", 2.5);
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.SETTINGS_COARSE_Z_LEVEL));
            })
    }

    zLevelChange = (e) => {
        this.setState({
            coarseFocusZLevel: e.target.value
        });
    }

    onFocusInputField = () => {
        this.props.dispatch(updateInputFieldInFocus(true));
    }

    onBlurInputField = () => {
        this.props.dispatch(updateInputFieldInFocus(false));
    }

    getCameraAppButton = (text, onClickMethod) => {
        return (
            <Button size="small" onClick={onClickMethod}>
                {text}
            </Button>
        )
    }

    getCameraInputFieldRow = (haveRowStyle, heading, inputField) => {
        return (
            <Row style={haveRowStyle ? { marginTop: 5 } : {}}>
                <Col offset={1} span={14}>
                    <b>{heading}</b>
                </Col>
                <Col offset={1} span={8}>
                    {inputField}
                </Col>
            </Row>
        )
    }

    getCameraAppInputField = (inputValue, onChangeInput) => {
        return <Input size="small" value={inputValue} onFocus={this.onFocusInputField} onBlur={this.onBlurInputField} onChange={onChangeInput} className="camera-app-input-field" />
    }

    getCameraAppCheckbox = (inputValue, onChangeInput) => {
        return <Checkbox checked={inputValue} onChange={onChangeInput} className="custom-checkbox-style"></Checkbox>
    }

    getCameraSliderRow = (haveRowStyle, heading, slider, inputBox) => {
        return (
            <Row style={haveRowStyle ? { marginTop: 5 } : {}}>
                <Col offset={1} span={7}>
                    <b>{heading}</b>
                </Col>
                <Col offset={1} span={9}>
                    {slider}
                </Col>
                <Col offset={1} span={4}>
                    {inputBox}
                </Col>
            </Row>
        )
    }

    render() {

        let image = document.getElementById('liveCameraImage');

        let maxXYVal = 0.75;

        if (image != null) {
			// maxXYVal = Math.min((image.width * 0.0000825 * 2.5).toFixed(1), (image.height * 0.0000825 * 2.5).toFixed(1));
			maxXYVal = Math.min((image.width * 0.0003 * 2.5).toFixed(1), (image.height * 0.0003 * 2.5).toFixed(1));
		}

        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={24} className="settings-heading">
                            <b>General Settings</b>
                        </Col>
                    </Row>
                    <Row>
                        <Col offset={1} span={22} className="custom-top-bottom-margin-divider">
                            <Divider />
                        </Col>
                    </Row>
                    {this.getCameraSliderRow(false, 'XY Step Size (mm.)',
                        <Slider value={this.props.livemode.movementStepSize} min={0.01} max={maxXYVal} step={0.01} tooltipVisible={false}
                            onChange={this.stepSizeChange} style={{padding: 0, margin: '8px 0px 0px 0px'}}
                            disabled={this.props.adminState.live_mode_action || !this.props.livemode.accessToLiveMode || this.props.livemode.useLiveModeInViewMode} /*onFocus={this.onFocusInputField} onBlur={this.onBlurInputField}*/ />,
                        <InputNumber style={{width: '100%'}} className="card-color" size="small" value={this.props.livemode.movementStepSize} disabled={true} />
                    )}
                    {/* <br />
                    {cookie.loadAll().superuser === 'true' ?
                        <Row>
                            <Col offset={1} span={9}>
                                <b>Coarse Focus Z Level</b>
                            </Col>
                            <Col offset={1} span={8}>
                                <Input size="small" value={this.state.coarseFocusZLevel} onFocus={this.onFocusInputField} onBlur={this.onBlurInputField} onChange={this.zLevelChange} className="camera-app-input-field" />
                            </Col>
                            <Col span={4} style={{marginLeft: 4}}>
                                <Button size="small" onClick={this.setCoarseFocusZLevel} className="card-color">
                                    Set
                                </Button>
                            </Col>
                        </Row>
                        : null
                    } */}
                    {/* {this.getCameraInputFieldRow(false, 'XY Step Size (mm.): ',
                        this.getCameraAppInputField(this.props.livemode.movementStepSize, this.stepSizeChange)
                    )}
                    <br />
                    <Row>
                        <Col offset={3} span={18}>
                            <Row style={{borderBottom: '1px solid #e8e8e8'}}>
                                <Col span={13} style={{borderRight: '1px solid #e8e8e8', textAlign: 'left'}}>Image Width</Col>
                                <Col span={11} style={{textAlign: 'right'}}>{image != null ? (image.width * 0.0002496 * 2.1).toFixed(3) + " mm." : Math.trunc(0 * 0.0002496) + " mm."}</Col>
                            </Row>
                            <Row>
                                <Col span={13} style={{borderRight: '1px solid #e8e8e8', textAlign: 'left'}}>Image Height</Col>
                                <Col span={11} style={{textAlign: 'right'}}>{image != null ? (image.height * 0.0002496 * 2.1).toFixed(3) + " mm." : Math.trunc(0 * 0.0002496) + " mm."}</Col>
                            </Row>
                        </Col>
                    </Row> */}
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        liveView: state.liveViewReducer,
        adminState: state.adminUrlReducer,
        livemode: state.livemodeReducer,
    };
};

export default connect(mapStateToProps)(LiveModeGeneralSettings);
