import React, {Component} from  "react";
import BrainAbstractAnnotator from './brain_abstract_annotator'
import { taggerKey } from './tagger_key'
import "../../../../asset/style/neoviewer/annotations_app.css"

export default class BrainApp extends Component {

    constructor(props) {
        super(props);
    }

    render(){

        return (
            <BrainAbstractAnnotator 
                creator={taggerKey.BRAIN_AI}
                showOptions={true}
                openAppOnAnnoClick={true}
                {...this.props}
                />
        )
    }
}