import {
    ApartmentOutlined,
	BarChartOutlined,
	BookOutlined,
	DoubleLeftOutlined,
	DoubleRightOutlined,
	InfoCircleOutlined,
	LogoutOutlined,
	ProfileOutlined,
	SettingOutlined,
	ShareAltOutlined,
	SyncOutlined,
	TeamOutlined,
	ToolOutlined
} from '@ant-design/icons';
import {Affix, Button, Col, Input, Layout, Menu, Modal, Row, Spin} from 'antd';
import 'antd/dist/antd.css';
import React, {Component} from "react";
import cookie from "react-cookies";
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import {closeDrawer, openDrawer} from "../action/dashboard.action";
import {logout} from "../action/login.action";
import "../asset/style/morpheus.css";
import ScannersListComp from "../component/dashboard/scannerspane";
import ClassifierUI from '../component/viewer/classifierView';
import {urls} from "../helper/backend.urls";
import {historyMP} from '../helper/history';
import {PrivateRoute} from '../helper/private.route';
import {globalUrlPrefix, MorphleLogo, MorphleLogoSquare} from '../utils/const';
import AddCaseView from '../view/addcase';
import AddValidationView from '../view/addvalidation'
import CaseListView from '../view/caselist';
import CasesView from '../view/casesview';
import {CaseView} from './caseview';
import CategoryView from '../view/category';
import OldScan from '../view/oldscan';
import ScannerView from '../view/scanner';
import SingleCaseView from '../view/singlecaseview';
import {SyncViewerView} from './sync-viewer';
import AddUserView from './adduser';
import AllScansView from "./dashboard";
import EditUserView from './edituser';
import ManageUsers from './manageusers';
import SettingsView from './settings';
import SlideTimeStatistics from './slideTimeStatisticsView';
import StatsDashboard from './statsDashboard';
import {TuningView} from './tuning';
import ScanWorkflowNew from '../component/scanner/scan_workflow_new';
import LivePlot from './liveplot';
import BloodWorkflow from '../component/scanner/blood_workflow';
import BloodPreviewSettingsComp from '../component/scanner/scanWorkflow/previewSettingsComponentBlood'
import CliaView from './cliaview';
import SummaryView from './summaryview';
import CliaBarrier from './cliahome';
import AddTiledView from './addtiledview';
import validationStudies from './validationStudies';
import SingleValidationView from './singlevalidationstudy'
import {checkCollapseMenuPermission} from "../utils/morpheus_utils";
import Devices from "./Devices";
import {loadMorpheusSetting} from "../action/morpheus.setting.action";
import ServicesView from "./Services";
import {LoadingText} from "../component/gammaviewer/components/components";
import {sagaMiddleware} from '../helper/store'
import {startPollingForPollables, stopPollingForPollables, watchForPollables} from '../action/slides.action'

const {Sider, Content} = Layout;


export class Morpheus extends Component {

	constructor(props) {
		super(props);

		this.props.dispatch(loadMorpheusSetting());

		this.isMobile = cookie.load('isMobile') === 'true';
		this.sideMenuContent = this.getSideMenu();

		this.state = {
			collapsed: this.isMobile,
			sessionValid: true
		};

        this.pollingTask = null
	}

    componentDidMount = () => {
       // [Ashish-Priyanshu]
        //sagaMiddleware.run(watchSessionValidity, this.props.dispatch);
        // var interval = 10000;  // in milliseconds
        // var intervalId = setInterval(this.checkSessionValidity, interval);
        // this.sessionCheckIntervalId = intervalId;

    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const ids_to_poll_unchanged =
         prevProps.ids_to_poll &&
         prevProps.ids_to_poll.reduce(
            (prev, curr, idx) => (this.props.ids_to_poll && this.props.ids_to_poll[idx] === curr) && prev,
            true)

        if (!prevProps.ids_to_poll || !ids_to_poll_unchanged) {
            this.props.dispatch(startPollingForPollables())
            this.pollingTask = sagaMiddleware.run(watchForPollables, (this.props.ids_to_poll || []))
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(stopPollingForPollables())
        if (this.pollingTask) this.pollingTask.cancel()

        clearInterval(this.sessionCheckIntervalId)
    };

    checkSessionValidity = () => {
        if (!this.props.sessionValid) {
            let message = "We logged you out because you signed in somewhere else."
            let resolution = "Please Contact Administrator if you did not sign-in elsewhere";
            this.logout(message, resolution);
        }
    }

    toggle = () => {
        if (this.props.drawerCollapsed) {
            this.props.dispatch(openDrawer());
        } else {
            this.props.dispatch(closeDrawer());
        }
    };

    onCollapse = collapsed => {
        if (collapsed) {
            this.props.dispatch(closeDrawer());
        } else {
            this.props.dispatch(openDrawer());
        }
    };

    logout = (displayMessage = null, resolution = null) => {
        this.props.dispatch(logout(displayMessage, resolution));
    };

    goToAdminMode = () => {
        window.location.href =
            urls.current + "/admin";
    }

    getSideMenu = () => {
        let urlParts = window.location.href.split('/');
        let component = urlParts[4].split('?')[0];
        if (component === 'dashboard') component = "scans";
        else if (component === 'case') component = "cases";
        else if (component === 'scanners') component = "scanner-" + urlParts[5];
        else if (component === 'adduser' || component === 'edituser') component = 'manageusers';

        const createScannersList = (key, defaultPermission) =>
            checkCollapseMenuPermission(key, defaultPermission) ? <ScannersListComp key={key}/> : undefined;


		let offlineComponent = (cookie.loadAll().deployment_mode === 'offline' && !(this.props.morpheusSetting.is_audience)) ?
			[
				createScannersList(
					"scanners",
					false,
				),
			] : undefined;

        let askForSyncCode = () => {
            Modal.confirm({
                title: 'Please enter the code shared by the presenter',
                icon: <InfoCircleOutlined />,
                content: (
                    <Input placeholder="Enter code here" id="sync-code" />
                ),
                onOk() {
                    var code = document.getElementById("sync-code").value;
                    var slideId = code.split('-')[0];
                    var syncCode = code.split('-')[1];
                    historyMP.push('/' + globalUrlPrefix + '/viewer/' + slideId + '?presentCode=' + syncCode);
                },
                onCancel() {
                }
            });
        }

        const createLinkMenuItem = (key, icon, span, link_to, defaultPermission) =>
            checkCollapseMenuPermission(key, defaultPermission) ?
                <Menu.Item key={key}>
                    <Link to={link_to}>
                        {icon}
                        <span>{span}</span>
                    </Link>
                </Menu.Item> : undefined;

        const createOnClickMenuItem = (key, icon, span, onClick, defaultPermission) =>
            checkCollapseMenuPermission(key, defaultPermission) ?
                <Menu.Item key={key} onClick={onClick}>
                    {icon}
                    <span>{span}</span>
                </Menu.Item> : undefined;

        return (
            <Affix>
                <Row>
                    {this.props.drawerCollapsed ? <img className="logo-square" src={MorphleLogoSquare} alt={"logo"}
                                                       onClick={() => {
														   if (cookie.loadAll().is_staff === 'true' || cookie.loadAll().superuser === 'true' ||
															   this.props.morpheusSetting.collapse_menu.scans || this.props.morpheusSetting.is_audience) {
															   historyMP.push('/' + globalUrlPrefix + '/dashboard');
														   } else {
															   historyMP.push('/' + globalUrlPrefix + '/cases');
														   }
                                                       }}/> :
                        <img className="logo" alt={"logo"} src={MorphleLogo} onClick={() => {
							if (cookie.loadAll().is_staff === 'true' || cookie.loadAll().superuser === 'true' ||
								this.props.morpheusSetting.collapse_menu.scans || this.props.morpheusSetting.is_audience) {
								historyMP.push('/' + globalUrlPrefix + '/dashboard');
							} else {
								historyMP.push('/' + globalUrlPrefix + '/cases');
							}
                        }}/>}
                </Row>
                <Row className="login-user-row">
                    <span className="login-user-prefix">Logged in as </span>
                    <br />
                    <span className="login-user">{cookie.load('first_name')}</span>
                </Row>
                <Row>
                    <Menu
                        mode="inline"
                        defaultOpenKeys={['scanners']}
                        defaultSelectedKeys={[component]}
                        className={"side-menu " + (this.props.drawerCollapsed ? '' : 'side-menu-border')}>
                        <Menu.Item key="toggle" onClick={this.toggle}>
                            <b> {this.props.drawerCollapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
                                <span>{this.props.drawerCollapsed ? "Open Menu" : "Collapse Menu"}</span></b>
                        </Menu.Item>
                        {createLinkMenuItem(
                            "scans",
                            <ProfileOutlined/>,
                            "All Scans",
                            '/' + globalUrlPrefix + '/dashboard',
                            true,
                        )}
                        {createLinkMenuItem(
                            "cases",
                            <BookOutlined/>,
                            "Cases",
                            '/' + globalUrlPrefix + '/cases',
                            true,
                        )}
                         {createLinkMenuItem(
                             "clia",
                             <BookOutlined/>,
                             "Clia Validations",
                             '/' + globalUrlPrefix + '/clia_validations',
                             cookie.loadAll().superuser === 'true',
                         )}
                        {createLinkMenuItem(
                            "stats",
                            <BarChartOutlined/>,
                            "Stats Dashboard",
                            '/' + globalUrlPrefix + '/stats',
                            cookie.loadAll().superuser === 'true',
                        )}
                        {createLinkMenuItem(
                            "slidestatistics",
                            <BarChartOutlined/>,
                            "Slide Statistics",
                            '/' + globalUrlPrefix + '/slidestatistics',
                            cookie.loadAll().superuser === 'true',
                        )}
                        {createLinkMenuItem(
							"devices",
							<ApartmentOutlined/>,
							"Devices",
							'/' + globalUrlPrefix + '/devices',
							cookie.loadAll().superuser === 'true',
						)}
                        {offlineComponent}
                        {createLinkMenuItem(
                            "sync",
                            <SyncOutlined/>,
                            "Sync Mode",
							'/' + globalUrlPrefix + '/sync',
							false
						)}
						{createOnClickMenuItem(
							"share",
							<ShareAltOutlined/>,
							"Join Slide Share",
							askForSyncCode,
							false,
						)}
						{createLinkMenuItem(
							"addtiledscan",
							<BookOutlined/>,
							"Add Tiled Scan",
							"/" + globalUrlPrefix + "/add-tiled-scan",
							false,
						)}
						{createLinkMenuItem(
							"services",
							<ToolOutlined/>,
							"Services",
							"/" + globalUrlPrefix + "/services",
							false,
						)}

						{createLinkMenuItem(
							"manageusers",
							<TeamOutlined/>,
							"Manage Users",
							'/' + globalUrlPrefix + '/manageusers',
							cookie.loadAll().superuser === 'true',
						)}
						{cookie.loadAll().superuser === 'true' ?
							<Menu.Item key="admin" onClick={this.goToAdminMode}>
								<SettingOutlined/>
								<span>Admin Mode</span>
							</Menu.Item> : undefined}
						{!(this.props.morpheusSetting.is_audience) ?
							<Menu.Item key="logout" onClick={this.logout}>
								<LogoutOutlined/>
								<span>Logout</span>
							</Menu.Item> : undefined}
                    </Menu>
                </Row>
            </Affix>
        );
    }

    render() {
        if (!this.props.morpheusSetting.viewer)
            return <LoadingText/>;
        let mobileHeader = null;
        let sideMenu = null;

        if (this.isMobile) {
            mobileHeader = <Row className="mobile-header">
                <Col span={24}>
                    <img className="logo-mobile" alt={"logo"} src={MorphleLogo}
                         onClick={() => historyMP.push('/' + globalUrlPrefix + '/dashboard')}/>
                    <Row className="login-user-row">
                        <span className="login-user-prefix">Logged in as </span>
                        <br/>
                        <span className="login-user">{cookie.load('first_name')}</span>
                        <br/>
                        <Button key="7" onClick={this.logout}>
                            <LogoutOutlined/>
                            <span>Logout</span>
                        </Button>
                    </Row>
                </Col>
            </Row>
        } else {
            sideMenu = <Sider className="sider" collapsed={this.props.drawerCollapsed} onCollapse={this.onCollapse}>
                {this.sideMenuContent}
            </Sider>
        }
        return (
            <Spin spinning={this.props.uiBusy} tip={this.props.busyMessage}>
                <Layout id="outer-container">
                    {sideMenu}
                    <Layout className="main-content">
                        {mobileHeader}
                        <Content id="content">
                            <PrivateRoute path={"/" + globalUrlPrefix + "/dashboard"} component={AllScansView}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/scannerflow/:id/scan/:stage"}
                                          component={ScanWorkflowNew}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/scannerflow/:id/blood-scan/:stage"}
                                          component={BloodWorkflow}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/scanners/:id/:progress_stage?"}
                                          component={ScannerView}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/tuning/:id/"} component={TuningView}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/sync/"} component={SyncViewerView}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/case-edit/:id"} component={CaseView}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/categories/"} component={CategoryView}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/category/:id/"} component={CaseListView}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/classifier/:id/:spectype/:subtype/"}
                                          component={ClassifierUI}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/tuning/:id/"} component={TuningView}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/oldviewer/:id/"} component={OldScan}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/cases"} component={CasesView}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/case/:id/"} component={SingleCaseView}/>
							<PrivateRoute path={"/" + globalUrlPrefix + "/addcase/"} component={AddCaseView}/>
							<PrivateRoute path={"/" + globalUrlPrefix + "/services/"} component={ServicesView}/>
							<PrivateRoute path={"/" + globalUrlPrefix + "/manageusers/"} component={ManageUsers}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/adduser/"} component={AddUserView}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/edituser/:id/"} component={EditUserView}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/settings/:id/"} component={SettingsView}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/slidestatistics"}
                                          component={SlideTimeStatistics}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/devices"} component={Devices}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/stats"} component={StatsDashboard}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/add-tiled-scan"} component={AddTiledView}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/live_plot/:path/"} component={LivePlot}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/bloodpreview/:device_id/:slot_id/:id/"}
                                          component={BloodPreviewSettingsComp}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/clia"} component={CliaView}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/addvalidation/"}
                                          component={AddValidationView}/>
                            {/* <PrivateRoute path={"/" + globalUrlPrefix + "/summary"} component={SummaryView} /> */}
                            <PrivateRoute path={"/" + globalUrlPrefix + "/clia_barrier/"} component={CliaBarrier}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/clia_case/:id/"} component={SingleCaseView}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/clia_validations"}
                                          component={validationStudies}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/clia_validation/:id"}
                                          component={SingleValidationView}/>
                            <PrivateRoute path={"/" + globalUrlPrefix + "/summary/:id"} component={SummaryView}/>
                        </Content>
                    </Layout>
                </Layout>
            </Spin>
        )
    }
}

const mapStateToProps = (state) => {
    return {
		morpheusSetting: state.morpheusSettingReducer,
		sessionValid: state.sessionStatusReducer.sessionValid,
		drawerCollapsed: state.dashboardReducer.drawerCollapsed,
		uiBusy: state.utilsReducer.uiBusy,
		busyMessage: state.utilsReducer.busyMessage,
		popupProps: state.contextMenuReducer.popupProps,
        ids_to_poll: state.slidesReducer.ids_to_poll
	};
};

export default connect(mapStateToProps)(Morpheus);
