import React, {Component} from "react";
import {connect} from 'react-redux';
import {TwitterPicker} from 'react-color';

import "../../../asset/style/neoviewer/grid_overlay.css"
import {Divider, Grid} from "@mui/material";
import {StyledSlider1, StyledSlider2, StyledSwitch} from "../components/StyledComponents";
import {updateGridColor, updateGridSize, updateGridStatus, updateGridWidth} from "../../../action/maps.state.action";

class GridOverlay extends Component {

	render() {
		this.activeMapId = this.props.activeMapId;
		this.gridState = this.props.mapsState[this.activeMapId].gridState;
		return (
			<Grid container>
				<Grid item xs={12}>
					<span style={{float :'right'}}>
						<StyledSwitch color={"secondary"} checked={this.gridState.grid}
						              onChange={(event, value) =>
							              this.props.dispatch(updateGridStatus(this.activeMapId, value))}/>
					</span>
				</Grid>
				<Grid item xs={12}>
					<Divider style={{margin: "10px"}}/>
					<h4 style={{color: 'white', textAlign: 'center', margin: "5px"}}>Line Width</h4>
					<StyledSlider1 min={2} max={6} value={this.gridState.width}
								   onChange={(event, width) =>
									   this.props.dispatch(updateGridWidth(this.activeMapId, width))}
								   sx={{width: "96%"}}
								   valueLabelDisplay="auto"/>
				</Grid>
				<Grid item xs={12}>
					<Divider style={{margin: "10px"}}/>
					<h4 style={{color: 'white', textAlign: 'center', margin: "5px"}}>Color</h4>
					<TwitterPicker triangle={""} onChange={(color) =>
						this.props.dispatch(updateGridColor(this.activeMapId, color.hex))}/>
				</Grid>
				<Grid item xs={12}>
					<Divider style={{margin: "10px"}}/>
					<h4 style={{color: 'white', textAlign: 'center', margin: "5px"}}>Spacing (um)</h4>
					<StyledSlider2 min={100} max={1500} step={5}
					               value={this.gridState.size}
					               onChange={(event, size) =>
						               this.props.dispatch(updateGridSize(this.activeMapId, size))}
					               marks={[
						               {
							               value: 500,
							               label: <strong className="unselectable">micro<br/>scope</strong>
						               }
					               ]}
								   valueLabelDisplay="auto"
								   sx={{width:"96%"}}
									/>
				</Grid>
			</Grid>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		activeMapId: state.gammaStateReducer.activeMapId,
		mapsState: state.mapsStateReducer,
	}
}

export default connect(mapStateToProps)(GridOverlay);
