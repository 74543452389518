import React, {Component} from 'react';
import {message} from 'antd';
import { updateSlide } from '../../../action/slide.update.action';
import { updateZStackParams } from '../../../action/morpheus.state.action';
import EditableTagGroup from '../../viewer/viewer.tags'
import axios from 'axios';
import cookie from "react-cookies";

import '../../../asset/style/neoviewer/slide_info.css';
import {globalUrlPrefix, slideViewerType} from '../../../utils/const';
import { getPrefixedUrl } from '../../../utils/utils';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DoneIcon from '@mui/icons-material/Done';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {Box} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

import {
    Checkbox,
    Grid, IconButton,
    Link, List, ListItem, ListItemButton, 
    ListItemIcon, ListItemText,
    Popover,
    TextField
} from "@mui/material";
import {connect} from "react-redux";
import Nestable from "react-nestable";
import 'react-nestable/dist/styles/index.css'
import Typography from "@mui/material/Typography";
import {AuthHeader} from "../../../helper/auth.token";
import {ContentCopy, ExpandLess, ExpandMore} from "@mui/icons-material";
import {UnmountClosed} from "react-collapse";
import SlideNavigatorExpandedComponent from "./slide_navigator_expanded_component";
import {Alert} from "@mui/lab";

import querystring from "query-string";

class SlideNavigatorApp extends Component {
    constructor(props){
        super(props);
        this.state = {
            current_folder: "",
            current_folder_id: -1,
            folders: [],
            slides: [],
            new_folder_name: "",
            fetching: false,
            curr_page: 1,
            openAddNewFolder: false,
            anchorEl: null,
            pathNavigator:[],
            selected_folders: [],
            selected_slides: [],
            copied_folders: [],
            copied_slides: [],
            expanded: [],
            total_pages: 0,
            open_delete_confirmation: false,
            hover_id: null,
            search_active: false,
            search_keyword: "",
        }
        this.itemsPerPage = 10;
    }

    getData = () => {
        this.setState({
            fetching: true,
        })
        let value = {
             current_folder: this.state.current_folder,
             current_folder_id: this.state.current_folder_id,
             page: this.state.page,
         }
         let url = `/api/get_folder_data/`
        axios.post(url, querystring.stringify(value), { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200 || response.status === 301 || response.status === 302) {
                    console.log("response==>", response)

                    let folders_count = response.data['folders'].length;
                    let slides_count = response.data['slides'].length;
                    let total_count = folders_count + slides_count;
                    let total_pages;

                    if((total_count%this.itemsPerPage) == 0){
                        total_pages = Math.floor(total_count/this.itemsPerPage);
                    } else {
                        total_pages = Math.floor(total_count/this.itemsPerPage) + 1
                    }

                    this.setState({
                        folders: response.data['folders'],
                        slides: response.data['slides'],
                        fetching: false,
                        total_pages: parseInt(total_pages),
                        curr_page: 1,
                    })
                }
                else if (response.status === 403 || response.status === 401 || response.status === 400) {
                    this.setState({
                        fetching: false,
                        total_pages: 0,
                    })
                }
            })
    }

    componentDidMount = () => {
        this.getData();
        this.setSourceInPath();
    }

    setSourceInPath = () => {
        let path_nav = this.state.pathNavigator;
        path_nav.push({
            "name": "Home",
            "parent": ""
        })
        this.setState({
            pathNavigator: path_nav,
        })
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if(prevState.current_folder_id != this.state.current_folder_id){
            console.log("xxx--")
            this.getData();
        }
    }


    openSlide = (e, id) => {
        let url = "/" + globalUrlPrefix + "/gamma-viewer/" + id;
        window.open(url, "_blank")
    }

    changeFolder = (e, folder) => {
        let path_nav = this.state.pathNavigator;
        path_nav.push({
            "name": folder.name,
            "id": folder.id,
        })
        for(let i=0; i<path_nav.length; i++){
            if(path_nav[i].id == folder.id){
                path_nav.splice(i+1);
            }
        }

        this.setState({
            current_folder: folder.name=="Home"?"":folder.name,
            current_folder_id: folder.id,
            pathNavigator: path_nav,
        })
    }

    addToSelectedFolders = (e, folder_id) => {
        console.time("tt-1");
        let selected_folders = this.state.selected_folders;
        if(selected_folders.indexOf(folder_id) != -1){
            let idx = selected_folders.indexOf(folder_id);
            selected_folders.splice(idx, 1);
        }else{
            selected_folders.push(folder_id);
        }
        this.setState({
            selected_folders: selected_folders,
        })
        console.timeEnd("tt-1")
    }

    addToSelectedSlides = (e, slide_id) => {
        let selected_slides = this.state.selected_slides;
        if(selected_slides.indexOf(slide_id) != -1){
            let idx = selected_slides.indexOf(slide_id);
            selected_slides.splice(idx, 1);
        } else {
            selected_slides.push(slide_id);
        }
        this.setState({
            selected_slides: selected_slides,
        })
    }

    getCurrentLevelFolderAndSlidesSelectable = () => {
        let folders = this.state.folders;
        let slides = this.state.slides;
        let itemsPerPage = this.itemsPerPage;
        let currPage = this.state.curr_page;
        let list = [];
        let folder_last_idx = folders.length-1;
        let items = folders.concat(slides);
        let idx_start = (currPage-1)*itemsPerPage;
        for(let i=idx_start; i<Math.min(items.length, idx_start+itemsPerPage); i++){
            if(i<=folder_last_idx){
                list.push(
                    <ListItem divider={true}>
                        <ListItemIcon>
                            <Checkbox sx={{padding: '0 !important'}} disableRipple={true} checked={this.state.selected_folders.indexOf(items[i].id) != -1} onChange={(e)=> this.addToSelectedFolders(e, items[i].id)} color={"secondary"}/>
                        </ListItemIcon>
                        <ListItemText
                            primary={items[i].name}
                            sx={{cursor: 'pointer'}}
                            onClick={(e) => this.changeFolder(e, items[i])}
                        />
                    </ListItem>
                )
            }else{
                list.push(
                    <div>
                        <ListItem divider={true}>
                            <ListItemIcon>
                                <Checkbox sx={{padding: '0 !important'}} disableRipple={true} checked={this.state.selected_slides.indexOf(items[i].id) != -1} onChange={(e)=> this.addToSelectedSlides(e, items[i].id)} color={"secondary"}/>
                            </ListItemIcon>
                            <ListItemText
                                primary={items[i].name.substring(0, 15) + `${items[i].name.length>10?"..":""}`}
                                onClick={(e)=>this.openSlide(e, items[i].id)}
                                sx={{cursor:'pointer'}}
                                // secondary="Slide"
                            />
                            <ListItemButton onClick={(e)=> this.handleExpansionAndReverse(e, items[i].id)} sx={{alignContent:'right', right:"-10px"}}>
                                {
                                    this.state.expanded.indexOf(items[i].id) === -1
                                        ? <ExpandMore/>
                                        : <ExpandLess/>
                                }
                            </ListItemButton>
                        </ListItem>
                        <UnmountClosed key={items[i].id} isOpened={this.state.expanded.indexOf(items[i].id)!=-1}>
                            {
                                this.getExpandedComponentSlide(items[i])
                            }
                        </UnmountClosed>
                    </div>
                )
            }
        }
        return list;
    }

    getCurrentLevelFoldersAndSlides = () => {
        let folders = this.state.folders;
        let slides = this.state.slides;
        let itemsPerPage = this.itemsPerPage;
        let currPage = this.state.curr_page;
        let list = [];
        let folder_last_idx = folders.length-1;
        let items = folders.concat(slides);
        console.log("folders-slides", items, folder_last_idx)
        let idx_start = (currPage-1)*itemsPerPage;
        for(let i=idx_start; i<Math.min(items.length, idx_start+itemsPerPage); i++){
            if(i<=folder_last_idx){
                list.push(
                    <ListItem divider={true}>
                        <ListItemIcon onClick={(e)=>this.addToSelectedFolders(e, items[i].id)}>
                            <FolderIcon color={"secondary"}/>
                        </ListItemIcon>
                        <ListItemText
                            primary={items[i].name}
                            sx={{cursor: 'pointer'}}
                            onClick={(e) => this.changeFolder(e, items[i])}
                        />
                    </ListItem>
                )
            }else{
                list.push(
                    <div>
                        <ListItem divider={true}>
                            <ListItemIcon onClick={(e)=>this.addToSelectedSlides(e, items[i].id)}>
                                <InsertDriveFileIcon color={"secondary"}/>
                            </ListItemIcon>
                            <ListItemText
                                primary={items[i].name.substring(0, 15) + `${items[i].name.length>10?"..":""}`}
                                onClick={(e)=>this.openSlide(e, items[i].id)}
                                sx={{cursor:'pointer', alignContent:'left', width:'85%'}}
                                // secondary="Slide"
                            />
                            <ListItemButton onClick={(e)=> this.handleExpansionAndReverse(e, items[i].id)} sx={{alignContent:'right', right:"-10px"}}>
                                {
                                    this.state.expanded.indexOf(items[i].id) === -1
                                        ? <ExpandMore/>
                                        : <ExpandLess/>
                                }
                            </ListItemButton>
                        </ListItem>

                        <UnmountClosed key={items[i].id} isOpened={this.state.expanded.indexOf(items[i].id)!=-1}>
                            {
                                this.getExpandedComponentSlide(items[i])
                            }
                        </UnmountClosed>
                    </div>
                )
            }
        }

        // for(let i=0; i<folders.length; i++){
        //     list.push(
        //         <ListItem divider={true}>
        //             <ListItemIcon onClick={(e)=>this.addToSelectedFolders(e, folders[i].id)}>
        //                 <FolderIcon color={"secondary"}/>
        //             </ListItemIcon>
        //             <ListItemText
        //                 primary={folders[i].name}
        //                 sx={{cursor: 'pointer'}}
        //                 onClick={(e) => this.changeFolder(e, folders[i])}
        //             />
        //         </ListItem>
        //     )
        // }
        // for(let i=0; i<slides.length; i++){
        //     console.log("xxx-id--", slides[i].id)
        //     list.push(
        //         <div>
        //             <ListItem divider={true}>
        //                 <ListItemIcon onClick={(e)=>this.addToSelectedSlides(e, slides[i].id)}>
        //                     <InsertDriveFileIcon color={"secondary"}/>
        //                 </ListItemIcon>
        //                 <ListItemText
        //                     primary={slides[i].name.substring(0, 15) + `${slides[i].name.length>10?"..":""}`}
        //                     onClick={(e)=>this.openSlide(e, slides[i].id)}
        //                     sx={{cursor:'pointer', alignContent:'left', width:'85%'}}
        //                     // secondary="Slide"
        //                 />
        //                 <ListItemButton onClick={(e)=> this.handleExpansionAndReverse(e, slides[i].id)} sx={{alignContent:'right', right:"-10px"}}>
        //                     {
        //                         this.state.expanded.indexOf(slides[i].id) === -1
        //                             ? <ExpandMore/>
        //                             : <ExpandLess/>
        //                     }
        //                 </ListItemButton>
        //             </ListItem>
        //
        //             <UnmountClosed key={slides[i].id} isOpened={this.state.expanded.indexOf(slides[i].id)!=-1}>
        //                 {
        //                     this.getExpandedComponentSlide(slides[i])
        //                 }
        //             </UnmountClosed>
        //         </div>
        //     )
        // }
        return list;
    }

    handleExpansionAndReverse = (e, id) => {
        console.log("xxx-id", id)
        let expanded = this.state.expanded;
        if(expanded.indexOf(id) !== -1){
            console.log("xxx-eal")
            let idx = expanded.indexOf(id);
            //expanded =
            expanded.splice(idx, 1);
        } else {
            expanded.push(id);
        }
        console.log("xxx-expanded", expanded)
        this.setState({
            expanded: expanded,
        })
    }

    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
            openAddNewFolder: true,
            new_folder_name: "",
        })
    }

    handleClose = () => {
        this.setState({
            anchorEl: null,
            openAddNewFolder: false,
        })
    }

    updateNewFolderName = (e) => {
        this.setState({
            new_folder_name: e.target.value,
        })
    }

    createNewFolder = () => {
        let val  = {
            parent_folder:this.state.current_folder,
            parent_folder_id: this.state.current_folder_id,
            new_folder: this.state.new_folder_name,
        };

        let url = '/api/create_new_folder/';
        axios.post(url, querystring.stringify(val), { headers: { Authorization: AuthHeader() } })
            .then(res => {
                message.info("New folder created successfully!")
                this.setState({
                    anchorEl: null,
                    openAddNewFolder: false,
                    new_folder_name: "",
                })
                this.getData();
            })
    }

    copySelected = () => {
        let selected_folders = this.state.selected_folders;
        let selected_slides = this.state.selected_slides;
        if(selected_folders.length == 0 && selected_slides.length == 0){
            message.info("Nothing Selected to COPY")
            return;
        }
        this.setState({
            copied_folders: selected_folders,
            copied_slides: selected_slides,
            selected_folders: [],
            selected_slides: [],
        })
        message.info("Copied Successfully")
    }

    pasteCopied = () => {
        let copied_folders = this.state.copied_folders;
        let copied_slides = this.state.copied_slides;
        if(copied_folders.length == 0 && copied_slides.length == 0){
            message.info("Nothing Copied To Paste")
            return;
        }
        //backend call to do so
        let url = "/api/update_parents/"
        let data = {
            "new_parent_folder": this.state.current_folder,
            "new_parent_folder_id": this.state.current_folder_id,
            "folders": this.state.copied_folders,
            "slides": this.state.copied_slides
        }
        axios.post(url, querystring.stringify(data),{headers: { Authorization: AuthHeader() } })
            .then(res => {
                this.getData();
            })
    }

    deleteSelected = (e) => {
        if(this.state.selected_slides.length==0 && this.state.selected_folders.length==0){
            message.info("Nothing selected to delete")
            return;
        }
        this.setState({
            open_delete_confirmation: true,
            anchorEl:e.currentTarget,
        })
    }

    handleClose = () => {
        this.setState({
            anchorEl: null,
            open_delete_confirmation: false,
        })
    }

    handleMultipleSelect = () => {
        if(this.state.selected_folders.length == this.state.folders.length && this.state.selected_slides.length == this.state.slides.length){
            this.setState({
                selected_folders: [],
                selected_slides: [],
            })
        }else{
            let s_folders = [];
            let s_slides = [];
            for(let i =0 ;i< this.state.folders.length; i++){
                s_folders.push(this.state.folders[i].id)
            }
            for(let i=0 ;i<this.state.slides.length; i++){
                s_slides.push(this.state.slides[i].id)
            }
            this.setState({
                selected_folders: s_folders,
                selected_slides: s_slides,
            })
        }
    }

    addNewFolderButton = () => {
        let id = this.state.openAddNewFolder?'simple-popover':undefined;

        return <Grid container spacing={1} sx={{justifyContent:"flex-end"}}>
                    <Grid item>
                        <IconButton aria-describedby={id} variant={"contained"} color={"secondary"} onClick={this.handleClick}>
                            <CreateNewFolderIcon/>
                        </IconButton>
                        <Popover
                            id={id}
                            open={this.state.openAddNewFolder}
                            anchorEl={this.state.anchorEl}
                            onClose={this.handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                        >
                            {/*<Typography sx={{ p: 2 }}>The content of the Popover.</Typography>*/}
                            <TextField
                                color={"secondary"}
                                // variant={"filled"}
                                autoFocus={true}
                                id={"new_folder_name"}
                                onChange={this.updateNewFolderName}
                                required={true}
                                value={this.state.new_folder_name}
                                // size={"medium"}
                                placeholder={"Add New Folder Name"}
                                margin={"dense"}
                            />
                            <IconButton sx={{mt:1, mb:1}} color={"secondary"} onClick={this.createNewFolder}>
                                <DoneIcon/>
                            </IconButton>
                        </Popover>
                    </Grid>
                    <Grid item>
                        <IconButton color={"secondary"} onClick={this.copySelected}>
                            <ContentCutIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton color={"secondary"} onClick={this.pasteCopied}>
                            <ContentPasteIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton color={"secondary"} onClick={this.deleteSelected}>
                            <DeleteIcon/>
                        </IconButton>
                        <Popover
                            open={this.state.open_delete_confirmation}
                            anchorEl={this.state.anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            onClose={this.handleClose}
                        >
                            {/*<div>Hello@@</div>*/}
                            {this.getDeleteConfirmationComponent()}
                        </Popover>
                    </Grid>
                    <Grid item>
                        <IconButton color={"secondary"} onClick={this.handleMultipleSelect}>
                            {
                                this.state.selected_folders.length == this.state.folders.length && this.state.selected_slides.length == this.state.slides.length ?
                                    <CheckBoxIcon/>:
                                    <CheckBoxOutlineBlankIcon/>
                            }
                        </IconButton>
                    </Grid>
                    {/*<Grid item>*/}
                    {/*    <IconButton color={"secondary"} onClick={this.toggleSearch}>*/}
                    {/*        {*/}
                    {/*            this.state.search_active ?*/}
                    {/*                <SearchOffIcon/>:*/}
                    {/*                <SearchIcon/>*/}
                    {/*        }*/}
                    {/*    </IconButton>*/}
                    {/*</Grid>*/}
               </Grid>
    }

    toggleSearch = () => {
        if(!this.state.search_active){
            let url = `/api/activate_search/`
            axios.get(url).then(
                res => {
                    console.log("hey")
                }
            )
        }
        this.setState({
            search_active: !this.state.search_active,
        })
    }

    deleteItems = () => {
        let folders = this.state.folders;
        let slides = this.state.slides;
        let vals = {
            "folders": folders,
            "slides": slides,
        }
    }

    getDeleteConfirmationComponent = () => {
        let folders = this.state.selected_folders;
        let slides = this.state.selected_slides;

        return <Box sx={{width:'10vw'}}>
                    <Typography align={"center"} color={"red"}>
                        Confirm DELETION of <br/>
                        <b>{`${folders.length} Folders`}</b> <br/>
                        <b>{`${slides.length} Slides`}</b> <br/>
                        <Alert severity={"warning"}>All the Slides in the folders will also be permanently deleted</Alert>
                        {/*{`Confirm DELETION of ${folders.length}-Folders and ${slides.length}-Slides`}*/}
                    </Typography>
                    <Grid display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <IconButton color={"secondary"}>
                            <CheckIcon/>
                        </IconButton>
                        <IconButton color={"secondary"} onClick={this.handleClose}>
                            <CancelIcon/>
                        </IconButton>
                    </Grid>
               </Box>
    }

    getCurrentNavigationPath = () => {
        let nav_path = this.state.pathNavigator;
        let comp = []
        for(let i=0; i<nav_path.length; i++){
            comp.push(
                <Link onClick={(e)=>this.changeFolder(e, nav_path[i])} color={"secondary"}>
                    {nav_path[i].name}
                </Link>
            )
            if(i!=nav_path.length-1){
                comp.push(">")
            }
        }
        return <div>
                    {comp}
               </div>
    }

    getCopyButton = () => {
        return <div>
                    <IconButton color={"secondary"}>
                        <ContentCopy />
                    </IconButton>
               </div>
    }

    getExpandedComponentSlide = (slide) => {
        //return //<Box>
                 return   <SlideNavigatorExpandedComponent
                        slide = {slide}
                    />
               //</Box>
    }

    nextPage = () => {
        let curr_page = this.state.curr_page;
        console.log("total_pages", this.state.total_pages)
        if(curr_page == this.state.total_pages){
            return;
        }
        this.setState({
            curr_page: curr_page+1,
        })
    }

    prevPage = () => {
        let curr_page = this.state.curr_page;
        if(curr_page == 1 || curr_page == 0){
            return;
        }
        this.setState({
            curr_page: curr_page-1,
        })
    }

    getPaginationComponent = () => {
        return <Grid container spacing={2} justifyContent={"flex-end"}>
                    <Grid item>
                        <IconButton
                            color={"secondary"}
                            sx={{padding:'0px'}}
                            fontSize={'large'}
                            disabled={this.state.curr_page == 1}
                            onClick={this.prevPage}
                        >
                            <ArrowLeftIcon sx={{fontSize:"3vh"}}/>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography sx={{fontSize:"2vh"}}>
                            {this.state.curr_page}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            sx={{padding:'0px'}}
                            color={"secondary"}
                            disabled={this.state.curr_page == this.state.total_pages}
                            onClick={this.nextPage}
                        >
                            <ArrowRightIcon  sx={{fontSize:"3vh"}}/>
                        </IconButton>
                    </Grid>
               </Grid>
    }

    updateSearch = (e) => {
        this.setState({
            search_keyword : e.target.value,
        })
    }

    getSearchComponent = () => {
        return <Grid sx={{width: '102%', margin:'0px'}}>
                    <Box>
                        <Grid>
                            <TextField
                                color={"secondary"}
                                autoFocus={true}
                                onChange={this.updateSearch}
                                value={this.state.search_keyword}
                                placeholder={"Search.."}
                                margin={"dense"}
                            />
                        </Grid>
                    </Box>
               </Grid>
    }


    render() {
        let current_level;
        if(this.state.selected_folders.length>0 || this.state.selected_slides.length>0){
            current_level = this.getCurrentLevelFolderAndSlidesSelectable()
        }else{
            current_level = this.getCurrentLevelFoldersAndSlides();
        }
        let add_new_folder_btn = this.addNewFolderButton();
        let copy_btn = this.getCopyButton();
        let current_navigation_path = this.getCurrentNavigationPath();
        return <Grid sx={{width:'102%', margin:'0px'}}>
                    <Box>
                        <Grid>
                            {add_new_folder_btn}
                            {current_navigation_path}
                        </Grid>
                    </Box>
                    <Box sx={{overflow:'scroll', height:'56vh', marginTop:'1vh', width:"inherit"}}>
                        <List>
                            {current_level}
                        </List>
                    </Box>
                    <Box sx={{marginTop:'2vh'}}>
                        {this.getPaginationComponent()}
                    </Box>
               </Grid>
    }
}

const mapStateToProps = (state) => {
    return{
    }
}

export default connect(mapStateToProps)(SlideNavigatorApp)


// renderItem = ({item}) => {
//     if(item.folder){
//         return <Typography sx={{color:'green'}}> {item.text} </Typography>
//     }else{
//         return item.text
//     }
// }
//
// onChange = (items) => {
//     console.log("xxx-items", items);
// }
// const items = [
//     { id: 0, text: 'All Slides', folder: true },
//     { id: 1, text: 'Slide Folder 1', folder: true},
//     {
//         id: 2, text: 'Slide Folder 2', folder: true,
//         children: [
//             { id: 3, text: 'Slide-0001' }
//         ]
//     },
//     { id: 4, text: 'Slide-0002', folder: false },
//     { id: 5, text: 'Slide-0003', folder: false },
//     { id: 6, text: 'Slide-0004', folder: false }
// ];
//
// return <Nestable
//     items={items}
//     renderItem={this.renderItem}
//     onChange={this.onChange}
// />
