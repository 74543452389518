import React, {Component} from "react";
import {Button, Col, DatePicker, Row, Select, Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons"
import {connect} from "react-redux";
import cookie from "react-cookies";
import {
  changeOrdering,
  clearAllFilters,
  getAll,
  getAllInvalid,
  getBarcodeFailed,
  getCaseNotAssigned,
  getStarred,
  setDateRange,
  setDevice
} from "../../action/search.action";
import moment from 'moment';
import axios from "axios";
import {checkCollapseMenuPermission} from "../../utils/morpheus_utils";

var today = new Date();
var dd = today.getDate();

const { RangePicker } = DatePicker;

const Option = Select.Option;

class FilterComp extends Component {
  constructor(props) {
    super(props);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleDeviceChange = this.handleDeviceChange.bind(this);
    this.state = {
      valType: undefined,
      valSort: "-date_time",
      valDateRange: undefined,
      devices: [],
    };
  }

  handleTypeChange(value) {
    if (value === "starred") {
      this.props.dispatch(getStarred(this.props.urlState));
    } else if (value === "barcode_failed") {
      this.props.dispatch(getBarcodeFailed(this.props.urlState));
    } else if (value === "case_not_assigned") {
      this.props.dispatch(getCaseNotAssigned(this.props.urlState));
    } else if (value === "invalid") {
      this.props.dispatch(getAllInvalid(this.props.urlState));
    } else {
      this.props.dispatch(getAll(this.props.urlState));
    }
  }

  handleSortChange(value) {
    this.props.dispatch(changeOrdering(value, this.props.urlState));
  }

  handleDeviceChange(value) {
    this.props.dispatch(setDevice(value, this.props.urlState));
  }

  handleDateRangeChange(moment) {
    try {
      this.props.dispatch(setDateRange(moment[0].format("YYYY-MM-DD"), moment[1].format("YYYY-MM-DD"), this.props.urlState));
    } catch (err) {
      this.props.dispatch(setDateRange('', '', this.props.urlState));
    }
  }

  getDevices = () => {
    let url = '/api/get_devices/'
    axios.get(url).then(res => {
      this.setState({
        devices: res.data['data'],
      })
    })
  }

  defaultFilterApply() {
  }

  handleClear() {
    this.props.dispatch(clearAllFilters());
  }

  componentWillMount = () => {
    this.getDevices();
  }

  render() {
    var isMobile = cookie.loadAll().isMobile === "true";
    let device_options = [
      <Option value={""}>
        All
      </Option>
    ]
    if (this.state.devices.length > 0) {
      for (let i = 0; i < this.state.devices.length; i++) {
        device_options.push(
            <Option value={this.state.devices[i].id}>
              {this.state.devices[i].name}
            </Option>
        )
      }
    }

    return (
        this.props.isFetching === true ?
            <Row>
            </Row>
            :
            <Row style={isMobile ? {height: "6em"} : {}}>
              <Col span={4}>
                <Select
                    value={this.props.urlState.starred ? "starred" : this.props.urlState.barcode_failed ?
                        "barcode_failed" : this.props.urlState.case_not_assigned ? "case_not_assigned" : "all"}
                    placeholder="Select Type"
                    onChange={this.handleTypeChange}
                    style={{width: 150, marginLeft: 8}}
                >
                  <Option value="case_not_assigned">Case Not Assigned</Option>
                  <Option value="barcode_failed">Barcode Failed</Option>
                  <Option value="starred">Starred</Option>
                  <Option value="invalid">Invalid <Tooltip
                      title="Lists Scans that are Cancelled, Errored Out or are in Progress"><InfoCircleOutlined/></Tooltip></Option>
                  <Option value="all">All</Option>
                </Select>
              </Col>
              <Col span={8}>
                <RangePicker
                    dateRender={current => {
                      const style = {};
                      if (current.date() === dd) {
                        style.backgroundColor = "transparent";
                        style.color = "black";
                      }
                      return (
                          <div className="ant-calendar-date" style={style}>
                            {current.date()}
                          </div>
                      );
                    }}
                    showToday
                    onChange={this.handleDateRangeChange}
                    value={this.props.urlState.date__lte.length === 0 ? undefined : [moment(this.props.urlState.date__gte, "YYYY-MM-DD"), moment(this.props.urlState.date__lte, "YYYY-MM-DD")]}
                    format="YYYY-MM-DD"
                    defaultValue={[undefined, undefined]}
                />
              </Col>
              <Col span={5}>
                <Select
                    value={this.props.urlState.ordering}
                    placeholder="Sort List"
                    onChange={this.handleSortChange}
                    style={{width: 130}}
                >
                  <Option value="name">
                    {" "}
                    A-Z
                  </Option>
                  <Option value="-name">
                    {" "}
					  Z-A
				  </Option>
					<Option value="date_time">
						Oldest First
					</Option>
					<Option value="-date_time">
						Recent First
					</Option>
				</Select>
			  </Col>
				{checkCollapseMenuPermission("devices", false) ?
					<Col span={3}>
						<Select
							value={this.props.urlState.device_id}
							placeholder="Sort Devices"
							onChange={this.handleDeviceChange}
							style={{width: 130}}
						>
							{device_options}
						</Select>
					</Col> : null}
				<Col span={3}>
					<Button
						style={{
							marginLeft: 8,
							backgroundColor: "white",
							color: "gray"

						}}
						onClick={this.handleClear}
					>
                  Clear Filters
                </Button>
              </Col>
            </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.searchReducer.isFetching,
    loginStatus: state.loginAlerts.loginStatus,
    urlState: state.searchReducer,
  };
};

export default connect(mapStateToProps)(FilterComp);
