import React, {Component} from "react";
import {connect} from "react-redux";
import {BottomNavigation, BottomNavigationAction, Box, Grid, Stack} from "@mui/material";
import LayersIcon from '@mui/icons-material/Layers';
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Typography from "@mui/material/Typography";
import {getAnnotationFeature, getAnnotationsFeature, styleFunction} from '../utils/annotations_app_utils'
import Select from "ol/interaction/Select.js";
import {click, pointerMove} from 'ol/events/condition.js';
import {reloadMagicToolData, updateCurrentLevel} from "../../../action/magic_tool.action";
import {deleteAnnotation, retrieveAnnotations} from "../../../action/maps.state.action";
import {getEyeCheckbox} from "../components/components";
import {checkAppPermission} from "../utils/gammaScanUtils";
import {annotationLayer, getAllSlideLayers} from "../utils/layers_info";
import {createVectorLayer, removeAllSelectInteractions, removeAllVectorLayers} from "../utils/map_utils";
import {degreeToRadian} from "../../../utils/utils";

class Layers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openLayersPanel: false,
        }
        this.initVectorFlag = true;
        this.initState();
    }

    initState() {
        if (this.activeMapId && this.activeMapId !== this.props.activeMapId)
            this.removeSelectInteractions();

        this.activeMapId = this.props.gammaState.activeMapId;
        this.mapState = this.props.mapsState[this.activeMapId];
        this.slideState = this.mapState.slideState;
        this.annotationState = this.mapState.annotationState;
        this.deepBioState = this.mapState.deepBioState;
        this.bloodAnnotationState = this.mapState.bloodAnnotationState
    }

    componentDidMount() {
        this.initialiseVectorLayersAndStore();
        document.addEventListener("keydown", this.keyDownListener, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyDownListener, false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // TODO: move it to slidemap
        if (prevProps.lastMapCount !== this.props.lastMapCount || prevProps.activeMapId !== this.activeMapId)
            this.initVectorFlag = true;

        if (this.initVectorFlag && this.slideState) {
            this.initVectorFlag = false;
            this.initialiseVectorLayersAndStore();
        }


        let prevAnnotationState = prevProps.mapsState[this.activeMapId].annotationState || {};
        if (this.annotationState && prevAnnotationState.annotations !== this.annotationState.annotations) {
            this.slideState.slidemap.getLayers().forEach(layer => {
                if (layer.values_.drawer !== undefined && layer.values_.type !== 'blood')
                    layer.setSource(
                        new VectorSource({
                            features: getAnnotationsFeature(this.annotationState.annotations.filter(
                                annotation => annotation.anno_drawer === layer.values_.drawer)),
                            wrapX: false,
                        })
                    );
            });
            // [Priyanshu] - if selected annotation is deleted, remove the selection feature
            if (this.selectFeatureInteraction &&
                !this.annotationState.annotations.map(annotation => annotation.id).includes((this.annotationState.selectedAnnotation || {}).id))
                this.selectFeatureInteraction.getFeatures().clear();
        }

        if ((prevAnnotationState || {}).markedAnnotation !== (this.annotationState || {}).markedAnnotation) {
            if (prevAnnotationState.markedAnnotation) {
                let vector = this.slideState.slidemap.getLayers().getArray().find(layer =>
                    layer.values_.drawer === prevAnnotationState.markedAnnotation.anno_drawer);
                if (vector && vector.getSource()) {
                    let featureToRemove = vector.getSource().getFeatures().find(feature =>
                        feature.getId() === prevAnnotationState.markedAnnotation.id);
                    let annotationToAdd = this.annotationState.annotations.find(annotation =>
                        annotation.id === prevAnnotationState.markedAnnotation.id);
                    featureToRemove && vector.getSource().removeFeature(featureToRemove);
                    if (annotationToAdd) {
                        annotationToAdd.marked = false;
                        vector.getSource().addFeature(getAnnotationFeature(annotationToAdd));
                    }
                }
            }
            if (this.annotationState.markedAnnotation) {
                this.annotationState.markedAnnotation.marked = true;
                let vector = this.slideState.slidemap.getLayers().getArray().find(layer =>
                    layer.values_.drawer === this.annotationState.markedAnnotation.anno_drawer);
                if (vector && vector.getSource()) {
                    let featureToRemove = vector.getSource().getFeatures().find(feature =>
                        feature.getId() === this.annotationState.markedAnnotation.id);
                    let annotationToAdd = this.annotationState.annotations.find(annotation =>
                        annotation.id === this.annotationState.markedAnnotation.id);
                    featureToRemove && vector.getSource().removeFeature(featureToRemove);
                    if (annotationToAdd) {
                        annotationToAdd.marked = true;
                        vector.getSource().addFeature(getAnnotationFeature(annotationToAdd));
                        this.slideState.slidemap.getView().animate({
                            rotation: degreeToRadian((this.slideState.slide_data || {}).viewer_rotation),
                            center: JSON.parse(annotationToAdd.center),
                            duration: 500,
                        }, () => this.selectFeatureInteraction.getFeatures().clear());
                    }
                }
            }
        }

        let prevBloodAnnotationState = prevProps.mapsState[this.activeMapId].bloodAnnotationState;
        if (this.bloodAnnotationState && (prevBloodAnnotationState || {}).annotations !== this.bloodAnnotationState.annotations) {
            this.slideState.slidemap.getLayers().forEach(layer => {
                if (layer.values_.drawer !== undefined && layer.values_.type === 'blood') {
                    layer.setSource(
                        new VectorSource({
                            features: getAnnotationsFeature(this.bloodAnnotationState.annotations.filter(
                                annotation => annotation.anno_drawer === layer.values_.drawer)),
                            wrapX: false,
                        })
                    );
                    layer.setStyle(styleFunction);
                }
            });
        }

        if ((this.annotationState || {}).selectedAnnotation && this.selectFeatureInteraction &&
            !this.selectFeatureInteraction.getFeatures().getArray()
                .includes(this.annotationState.selectedAnnotation.feature)) {
            this.selectFeatureInteraction.getFeatures().clear();
            this.selectFeatureInteraction.getFeatures().push(this.annotationState.selectedAnnotation.feature);
        }


        if (!prevProps.magicToolData.reload && this.props.magicToolData.reload) {
			let features = ((this.annotationState || {}).annotations || []).filter(annotation => annotation.anno_drawer === 0)
				.map(annotation => annotation.feature);
			let start = this.props.magicToolData.start_point;
            let current = this.props.magicToolData.curr_point;
            let delta = Math.abs(Math.pow((current[0] - start[0]), 2) + Math.pow((current[1] - start[1]), 2))
            let index = parseInt(delta / 150)
            index = Math.min(index, Math.max(this.props.magicToolData.unsaved_magic_annotations.length - 1, 0))
            let temp_features = this.props.magicToolData.unsaved_magic_annotations[index];
            let all_feats = features
            if(temp_features !== undefined) {
                all_feats = all_feats.concat(temp_features);
            }
			all_feats && all_feats[0] && annotationLayer.vector.setSource(
				new VectorSource({
					features: all_feats,
					wrapX: false
				})
			);
            this.props.dispatch(reloadMagicToolData(false));
            this.props.dispatch(updateCurrentLevel(index));
        }
    }

    keyDownListener = (event) => {
        if (event.target.tagName === 'INPUT' || event.shiftKey || event.ctrlKey || event.altKey)
            return;
        let annotation;
        switch (event.key) {
            case "Escape":
                if (this.selectFeatureInteraction)
                    this.selectFeatureInteraction.getFeatures().clear();
                break;
            case "Delete":
                if (this.selectedAnnotationId)
                    annotation = this.annotationState.annotations.find(annotation =>
                        annotation.id === this.selectedAnnotationId);
                this.props.dispatch(deleteAnnotation(this.props.activeMapId, annotation));
                break;
            default:
                break;
        }
    }

    // TODO: Move it to slidemap
    initialiseVectorLayersAndStore = () => {
        this.props.dispatch(retrieveAnnotations(this.activeMapId, this.mapState.slideId));
        removeAllVectorLayers(this.slideState.slidemap);
        removeAllSelectInteractions(this.slideState.slidemap);
		let layers = getAllSlideLayers().filter(layer => checkAppPermission(layer));
		let vectors = layers.map(layer => createVectorLayer(this.slideState.slidemap, layer));
        this.selectFeatureInteraction = new Select({
            condition: click,
            layers: vectors.filter(vector => vector.values_.onFeatureSelect),
            wrapX: false,
            hitTolerance: 5,
        });

        this.selectFeatureInteraction.on("select", e => {
            if (e.selected[0] && (!e.deselected[0] || e.selected[0].getId() !== e.deselected[0].getId() ||
                e.selected[0].getId() !== this.selectedAnnotationId)) {
                    this.selectedAnnotationId = e.selected[0].getId();
                    Object.values(e.target.featureLayerAssociation_).forEach(layer =>
                        layer.values_.onFeatureSelect(this.activeMapId, e.selected[0]));
            }
            else this.selectFeatureInteraction.getFeatures().clear();
        });
        this.slideState.slidemap.addInteraction(this.selectFeatureInteraction);
        this.hoverFeatureInteraction = new Select({
            condition: pointerMove,
            layers: vectors.filter(vector => vector.values_.onFeatureHover),
            wrapX: false,
            hitTolerance: 5,
        });
        this.slideState.slidemap.addInteraction(this.hoverFeatureInteraction);
    }

    removeSelectInteractions = () => {
        this.slideState.slidemap.getInteractions().forEach(interaction => {
            if (interaction instanceof Select)
                this.slideState.slidemap.removeInteraction(interaction);
        });
    }

    handleVectorLayerChange = (e, vector) => {
        let slidemap = this.slideState.slidemap;
        if(e.target.checked)
            slidemap.addLayer(vector);
        else
            slidemap.removeLayer(vector);
    }

    render() {
        this.initState();
        if (window.location.href.includes("/blood-viewer/")) return null;
        if (!this.slideState) return <div/>;

        let layersOverlayComponent;
        if (this.state.openLayersPanel) {
            layersOverlayComponent = <div>

                <Box sx={{
                    height: 'inherit', width: 'auto', border: '5px', bgcolor: "rgba(51, 4, 112,0.8)",
                    display: "flex", overflowY: 'scroll'
                }}>
                    <Typography sx={{width: '100%'}}>
                        <Stack direction="column" spacing={2} justifyContent={"flex-start"} marginTop={1}
                               marginBottom={1}>
                            {this.slideState.slidemap.getLayers().array_.filter(layer => layer instanceof VectorLayer)
                                .map(vector =>
                                    <Grid container sx={{height: '20px'}} sm={12}>
                                        <Grid item paddingX={1}>
                                            {getEyeCheckbox(undefined, true,
                                                e => this.handleVectorLayerChange(e, vector))}
                                        </Grid>
                                        <Grid item>
                                            <Typography sx={{marginTop:'7px'}}>
                                                {vector.values_.name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Stack>
                    </Typography>

                </Box>
            </div>
        }
        return(
            <Stack direction={"column-reverse"} alignItems={"flex-end"} >
                <BottomNavigation sx={{borderRadius:0, bgcolor:"rgba(51, 4, 112,0.8)"}} value={this.state.app}
                                  onChange={() => this.setState({openLayersPanel : !this.state.openLayersPanel,})}>
                    <BottomNavigationAction
                        icon={this.state.openLayersPanel ? <LayersIcon color="secondary" /> : <LayersIcon />}
                        sx={{padding: 0}} />
                </BottomNavigation>
                {layersOverlayComponent}
            </Stack>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        gammaState: state.gammaStateReducer,
        activeMapId: state.gammaStateReducer.activeMapId,
        lastMapCount: state.gammaStateReducer.lastMapCount,
        mapsState: state.mapsStateReducer,
        magicToolData: state.MagicToolReducer,
        trigger: state.triggerReducer,
    }
}

export default connect(mapStateToProps)(Layers);
