import React, {Component} from 'react';
import {connect} from "react-redux";
import {Empty} from 'antd';
import '../../../asset/style/workflow/tab-pane.css'
import Field100xImage from "./field100ximage";
import {Grid, IconButton, Stack, Typography} from "@mui/material";
import {ArrowCircleLeftTwoTone, ArrowCircleRightTwoTone} from "@mui/icons-material";
import {updateMapInFocus, updateMarkedAnnotation} from "../../../action/maps.state.action";
import Field100xView from '../../../view/field100xView';

class AnnotationsPaginatedGrid extends Component {

	constructor(props) {
		super(props);
		this.state = this.initState();
	}

	componentDidMount() {
		document.addEventListener("keydown", this.keyDownListener);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.keyDownListener);
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.annotations !== this.props.annotations || prevProps.row !== this.props.row ||
			prevProps.col !== this.props.col) {
			this.setState(this.initState());
			this.selectField(this.props.annotations[0]);
		}
		if (prevProps.markedAnnotation !== this.props.markedAnnotation) {
			let page = Math.floor(this.props.annotations.indexOf(this.props.markedAnnotation) / this.gridsPerPage) + 1;
			if (page > 0 && page !== this.state.page)
				this.setState({
					page: page,
				});
		}
	}

	initState = () => {
		this.device_id = (((Object.values(this.props.mapsState)[0] || {}).slideState||{}).slide_data || {}).device;
		this.row = this.props.row || 1;
		this.col = this.props.col || 1;
		this.gridsPerPage = this.props.row * this.props.col;
		this.totalPages = Math.ceil((this.props.annotations || []).length / this.gridsPerPage);
		return ({
			page: 1,
		});
	}

	isSafeIndex = index => index >= 0 && index < this.props.annotations.length;

	keyDownListener = (event) => {
		const { mapInFocus } = this.props.mapsState[this.props.mapId] || {}

		if (event.target.tagName === 'INPUT' || event.ctrlKey || event.altKey || mapInFocus)
			return;

		switch (event.key) {
			case 'ArrowLeft':
				this.updateMarkedAnnotationIndex(-1)
				break
			case 'ArrowRight':
				this.updateMarkedAnnotationIndex(1)
				break
			case 'ArrowUp':
				this.updateMarkedAnnotationIndex(-this.col)
				break
			case 'ArrowDown':
				this.updateMarkedAnnotationIndex(this.col)
				break
			default:
				return;
		}
	}

	transformAnnotation = (a) => {
		try {
			let meta = { ...JSON.parse(a.meta) }
			return {
				...a,
				meta,
			}
		} catch (err) {
			return a
		}
	}

	paginate = () => {
		this.start = (this.state.page - 1) * this.gridsPerPage;
		this.end = this.start + this.gridsPerPage;
		this.annotations = (this.props.annotations || []).slice(this.start, this.end);
	};

	increasePage = () => {
		this.setState({page: Math.min(this.totalPages, this.state.page + 1)});
		this.updateMarkedAnnotationIndex(2)
	}

	decreasePage = () => {
		this.setState({page: Math.max(1, this.state.page - 1)});
		this.updateMarkedAnnotationIndex(-2)
	}

	selectField = (field) => {
		this.props.dispatch(updateMarkedAnnotation(0, field));

		const mapInFocus = this.props.mapsState[this.props.mapId].mapInFocus
		if (mapInFocus) {
			this.props.dispatch(updateMapInFocus({ mapId: this.props.mapId, mapInFocus: false }))
		}
	}

	updateMarkedAnnotationIndex = (updateIndexBy) => {
		const currIdx = this.props.annotations.findIndex(({id}) => this.props.markedAnnotation.id === id)
		if (!this.isSafeIndex(currIdx)) return

		const updatedIdx = currIdx + updateIndexBy
		if (!this.isSafeIndex(updatedIdx)) return

		this.selectField(this.props.annotations[updatedIdx])
	}

	get100xgrid = () => {
		let height = `${100 / this.row}%`;
		let xs = 12 / this.col;

		return <Grid
			key={`grid_${this.state.page}`}
			container
			minHeight={"90vh"}
			>
			{this.annotations
				.map(a => this.transformAnnotation(a))
				.map((annotation, idx) =>
					<Grid
						key={`annotation_grid_${this.state.page}_${idx}`}
						item
						height={height}
						xs={xs}
						onTouchStart={() => this.selectField(annotation)}
						onClick={() => this.selectField(annotation)}
					>
						{this.device_id > 7 && (!!annotation.meta && !!annotation.meta.initPos && ((annotation.meta.initPos[0] + annotation.meta.initPos[1]) > 0)) ?
							<Field100xView
								showMinimal={true}
								selected={annotation}
								view_mode={1}
								dispatch={this.props.dispatch}
								all_grids={this.annotations}
								slide_id={this.props.slide_id}
								fieldGridData={this.props.fieldGridData}
								mode={this.props.mode}
								differential={this.props.differential}
								loadNext={this.props.loadNext}
								loadPrev={this.props.loadPrev}
								annotations={this.props.annotations}
								showSelected={!this.props.mapsState[this.props.mapId].mapInFocus &&
									(this.props.markedAnnotation && this.props.markedAnnotation.id === annotation.id)
								}
								cssfilter={this.props.cssfilter}
							/>
							:
							<Field100xImage
								annotation={annotation}
								selected={!this.props.mapsState[this.props.mapId].mapInFocus &&
									(this.props.markedAnnotation && this.props.markedAnnotation.id === annotation.id)}
								cssfilter={this.props.cssfilter}
							/>
						}
					</Grid>
				)}
		</Grid>
	}

	getPageNumber = () => this.totalPages > 0 ?
		<Stack direction={"row"} justifyContent={"space-evenly"}>
			<IconButton onClick={this.decreasePage} disabled={this.state.page === 1}>
				<ArrowCircleLeftTwoTone fontSize={"large"} sx={{color: 'cyan'}}/>
			</IconButton>
			<Typography alignSelf={"center"} variant={'h6'} color={"white"}>
				{this.start + 1}-
				{this.start + this.annotations.length} out of {this.props.annotations.length} 100x Fields
			</Typography>
			<IconButton onClick={this.increasePage} disabled={this.state.page === this.totalPages}>
				<ArrowCircleRightTwoTone fontSize={"large"} sx={{color: 'cyan'}}/>
			</IconButton>
		</Stack> : null;

	render() {
		if ((this.props.annotations || []).length === 0)
			return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
		this.paginate();
		return <Stack height={this.props.height || "98.5%"} direction={"column"} justifyContent={"space-between"}>
			{this.get100xgrid()}
			{this.getPageNumber()}
		</Stack>;
	}
}

const mapStateToProps = (state) => ({
	mapsState: state.mapsStateReducer,
	markedAnnotation: (state.mapsStateReducer[0].annotationState || {}).markedAnnotation,
});

export default connect(mapStateToProps)(AnnotationsPaginatedGrid);

