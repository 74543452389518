import React, {Component} from 'react';
import {connect} from "react-redux";
import {debounce} from 'lodash';
import {Button, Col, Icon, message, Row} from 'antd';
import {
	loadMorpheusSettings,
	updateAppClosedStatus,
	updateMapParams,
	updateMorphleID,
	updateOnStitchedLayer,
	updateUrlStateInReducer,
	updateZStackLevel
} from "../../action/morpheus.state.action"
import {getSlideAndInitialiseMapState, recreateMapLayers} from './map_utils'
import AppBoard from './appboard'
import "../../asset/style/neoviewer/slidemap.css"
import cookie from 'react-cookies';
import {getErrorComponent} from '../dashboard/slidelist_utils';
import {AuthHeader} from "../../helper/auth.token";
import axios from "axios";
import * as keys from '../neoviewer/apps/app_keys'
import {globalUrlPrefix, mapLayerTypes, MorphleLogo, slideViewerType} from '../../utils/const';
import {checkIfMobile} from '../../utils/utils';
import {isZoomForTiled} from "../gammaviewer/utils/map_utils";

class SlideMap extends Component {

  constructor(props) {
    super(props);

    this.screenSizeString = window.screen.width + 'x' + window.screen.height;

    this.state = {
      slide: -1,
      isFetching: true,
      isErrored: false,
      loadedMap: false, 
      mapId: this.props.map_id,
      stackMode: 0,
      modalVisible: false,
      backgroundColor: "#FFFFFF",
      shiftScrollCount: 0,
      mapRenderComplete: false,
    };

    this.lastCachingCall = -1;
    this.upperZLevelsCached = 0;
    this.lowerZLevelsCached = 0;
    
    this.previewMapRef = null;
    this.props.dispatch(loadMorpheusSettings());
    this.props.dispatch(updateUrlStateInReducer(this.props.urlState));
    this.imageDownloadTimer = null;

    this.cacheZStackLayers = debounce(this.cacheZStackLayers.bind(this), 1000);
    
    if (cookie.loadAll().isMobile === 'true') {
      window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    }
  }

  addPreviewMapControl = (previewMapRef) => this.previewMapRef = previewMapRef;
          
  updateUrlState = () => {
	  let tiledLayer = isZoomForTiled(this.state.view.getZoom(), this.state.viewLevelsInfo);
	  if (!tiledLayer) {
		  this.props.dispatch(updateZStackLevel(0));
	  }
	  this.props.dispatch(updateOnStitchedLayer(!tiledLayer));
	  let zoomRatio = (((this.props.urlState || {}).app_state || {})[keys.displaySizeCalibrationKey.id] || {})[this.screenSizeString] || 1;
	  if (zoomRatio > 1 && Number.isInteger(this.state.view.getZoom()) && this.state.view && !(this.props.urlState || {}).presentCode) {
		  if (this.state.view.getZoom() <= this.state.view.getMaxZoom() - 1) {
			  this.state.view.setZoom(this.state.ZValues[this.state.view.getZoom()]);
		  } else if (this.state.view.getZoom() <= this.state.view.getMaxZoom()) {
			  this.state.view.setZoom(this.state.ZValues[this.state.view.getZoom() - 1]);
		  }
	  }

	  this.props.dispatch(updateMapParams(
		  this.props.urlState,
		  this.state.view.getCenter()[0],
		  this.state.view.getCenter()[1],
		  this.state.view.getZoom(),
		  this.state.view.getRotation(),
	  ));
  };

  updateLayers = () => {
    this.setState({
      mapRenderComplete: false,
    });

    let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels) / 2 : (this.props.urlState.numZLevels);
    let minZLevel = this.props.urlState.takeBidirectionalZStack ?  (-1 * ((this.props.urlState.numZLevels) / 2)) : 0;
    
    minZLevel = Math.floor(minZLevel);
    maxZLevel = Math.floor(maxZLevel);

    minZLevel = Math.floor(minZLevel);
    maxZLevel = Math.floor(maxZLevel);

    recreateMapLayers(this.state.slidemap, this.state.viewLevelsInfo, this.state.slide_data, this.state.imageInfo, this.state.projection, 
      this.state.resolutions, this.state.txtyInfo, this.state.tileSize, this.updateUrlState, (obj) => {
        obj.addPreviewMapControl = this.addPreviewMapControl;
        obj.goHome = this.goHome;
        this.setState(obj)}, this.props.urlState.zStackLevel, minZLevel, maxZLevel, this.props.urlState.takeZStack);
  }

  onMapRenderingComplete = () => {
    this.setState({
      mapRenderComplete: true,
    });
  }

  cacheZStackLayers = () => {
    console.log("Caching started")
    this.lastCachingCall = Date.now();
    let lastCachingCall = this.lastCachingCall;
    this.upperZLevelsCached = 0;
    this.lowerZLevelsCached = 0;
    let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels - 1) / 2 : (this.props.urlState.numZLevels - 1);
    let timerInterval  = 1000;
    let timer = 0;
    for(let i = 1; i <= maxZLevel + 1; i++) {
      let layers = this.state.slidemap.getLayers().getArray();
      setTimeout(() => this.cacheLayer(layers, i, lastCachingCall), timer);
      timer += timerInterval;
    }
  }

  cacheLayer = (layers, zLevel, lastCachingCall) => {
    if (lastCachingCall === this.lastCachingCall) {
      for(let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        if (layer.values_.posS === (-1 * zLevel) || layer.values_.posS === zLevel) {
          layer.setVisible(true);
        }
      }
      setTimeout(() => this.layerVisibilityOff(layers), 10);
      this.upperZLevelsCached = this.upperZLevelsCached + 1;
      this.lowerZLevelsCached = this.lowerZLevelsCached - 1;
    }
  }

  layerVisibilityOff = (layers) => {
    for(let i = 0; i < layers.length; i++) {
      let layer = layers[i];
      if(layer.values_.posS === this.props.urlState.zStackLevel ||
          layer.values_.name === mapLayerTypes.ANNOTATION_LAYER_NAME ||
          layer.values_.name === mapLayerTypes.STITCHED_LAYER_NAME) {
        layer.setVisible(true);
      } else {
        layer.setVisible(false);
      }
    }
  }

  cacheZoomLevels = (upper, layers) => {
    let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels - 1) / 2 : (this.props.urlState.numZLevels - 1);
    if (upper && this.upperZLevelsCached < maxZLevel) {
      for(let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        if (layer.values_.posS === (this.upperZLevelsCached + 1) ||
            layer.values_.posS === (this.upperZLevelsCached + 2)) {
          layer.setVisible(true);
        }
      }
      setTimeout(() => this.layerVisibilityOff(layers), 10);
      this.upperZLevelsCached = this.upperZLevelsCached + 2;
    } else if(!upper && this.lowerZLevelsCached > (-1 * maxZLevel)) {
      for(let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        if (layer.values_.posS === (this.lowerZLevelsCached - 1) ||
            layer.values_.posS === (this.lowerZLevelsCached - 2)) {
          layer.setVisible(true);
        }
      }
      setTimeout(() => this.layerVisibilityOff(layers), 10);
      this.upperZLevelsCached = this.lowerZLevelsCached - 2;
    }
  }

  scrollActionOnMap = (e) => {
    if (e.type !== "wheel") {
      return true;
    } else {
      let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels - 1) / 2 : (this.props.urlState.numZLevels - 1);
      let minZLevel = this.props.urlState.takeBidirectionalZStack ?  (-1 * ((this.props.urlState.numZLevels - 1) / 2)) : 0;

      if (this.props.urlState.takeZStack && e.originalEvent.shiftKey) {
        if(this.props.urlState.onStitchedLayer) {
          message.error("Z Stack only allowed on higher zoom levels.", 2.5);
        } else {
          if (e.originalEvent.deltaY > 0 && this.props.urlState.zStackLevel > minZLevel) {
            this.props.dispatch(updateZStackLevel(this.props.urlState.zStackLevel - 1));
            this.setState({
              shiftScrollCount: this.state.shiftScrollCount + 1,
            });
            this.cacheZoomLevels(true, this.state.slidemap.getLayers().getArray());
          } else if (e.originalEvent.deltaY < 0 && this.props.urlState.zStackLevel < maxZLevel) {
            this.props.dispatch(updateZStackLevel(this.props.urlState.zStackLevel + 1));
            this.setState({
              shiftScrollCount: this.state.shiftScrollCount + 1,
            });
            this.cacheZoomLevels(false, this.state.slidemap.getLayers().getArray());
          }
        }
        return false;
      } else {
        return true;
      }
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(prevState.mapRenderComplete !== this.state.mapRenderComplete) {
      if(this.state.mapRenderComplete) {
        console.log("Map rendered complete")
        if (!this.props.urlState.onStitchedLayer) {
          this.cacheZStackLayers();
        } else {
          this.lastCachingCall = Date.now();
        }
      }
    }

    if(prevProps.urlState.zStackLevel !== this.props.urlState.zStackLevel) {
      let layers = this.state.slidemap.getLayers().getArray();
      for(let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        layer.setVisible(true);
      }
      for(let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        if(layer.values_.posS === this.props.urlState.zStackLevel ||
            layer.values_.name === mapLayerTypes.ANNOTATION_LAYER_NAME ||
            layer.values_.name === mapLayerTypes.STITCHED_LAYER_NAME) {
          layer.setVisible(true);
        } else {
          layer.setVisible(false);
        }
      }
    }

    if(prevProps.urlState.apps_initialised !== this.props.urlState.apps_initialised && !this.state.loadedMap) {
      getSlideAndInitialiseMapState(this.props.slide_id, (obj) => {
        obj.addPreviewMapControl = this.addPreviewMapControl;
        obj.goHome = this.goHome;
        this.setState(obj)
      }, this.props.urlState, this.updateUrlState, this.updateLayers, this.onMapRenderingComplete, this.scrollActionOnMap);
    }

    if (!this.state.loadedMap && !this.state.isFetching && !this.state.isErrored) {
      this.state.slidemap.setTarget("map-" + this.props.map_id);
      if (this.previewMapRef !== undefined) {
        console.log("Preview Map Was Undefined!!!");
        this.state.slidemap.addControl(this.previewMapRef);
      }
        this.setState({
          loadedMap: true
        })
        this.props.dispatch(updateMorphleID(this.state.slide_data.morphle_id, this.state.slide_data.id));
      if (this.props.urlState.x === -1 ) this.goHome();
    }
  }

  downloadImage = (url) => {
    let image = new Image();
    image.src = url;
  }

  componentWillUnmount = () => {
	  let url = `/api/clean_sync_browsing/?sync_code=${((this.props.urlState.app_state || {})['present_app'] || {}).code}&morphle_id=${this.state.slide_data.morphle_id}`;
	  axios.get(url, {headers: {Authorization: AuthHeader()}})
		  .then(() => {
		  })
		  .catch(() => {
			  console.log("Failed Cleaning");
		  });
  }

  getBounds = (tileCoord, quadrant) => {
    let imageName = "x" + tileCoord[0] + "y" + tileCoord[1] + ".jpg";
    let maxZoom = parseInt(this.state.slide_data.z_levels.split(",")[this.state.slide_data.z_levels.split(",").length - 1]);
    let divisions = Math.pow(2, this.props.urlState.z === maxZoom ? 2:
        this.props.urlState.z === maxZoom - 1 ? 1 : 0);
    let widthThreshold = (2049 * this.state.slide_data.uperpixel) / divisions;
    let heightThreshold = (2449 * this.state.slide_data.uperpixel) / divisions;
    let xOffset = this.props.urlState.z >= maxZoom - 1 ? quadrant[0] : 0;
    let yOffset = this.props.urlState.z >= maxZoom - 1 ? quadrant[1] : 0;

    if (this.state.imageLog["map"]["stitching_info"]["map"][imageName] !== undefined) {
      let imagePos = this.state.imageLog["map"]["stitching_info"]["map"][imageName]["map"]["Absolute Position"]["map"];
      let pointA = [(xOffset * widthThreshold) + (imagePos["X"] * this.state.slide_data.uperpixel), (-1 * yOffset * heightThreshold) + this.state.imageShape[1] - ((imagePos["Y"] * this.state.slide_data.uperpixel) + heightThreshold)];
      let pointB = [(xOffset * widthThreshold) + (imagePos["X"] * this.state.slide_data.uperpixel) + widthThreshold, (-1 * yOffset * heightThreshold) + this.state.imageShape[1] - ((imagePos["Y"] * this.state.slide_data.uperpixel) + heightThreshold)];
      let pointC = [(xOffset * widthThreshold) + (imagePos["X"] * this.state.slide_data.uperpixel) + widthThreshold, (-1 * yOffset * heightThreshold) + this.state.imageShape[1] - (imagePos["Y"] * this.state.slide_data.uperpixel)];
      let pointD = [(xOffset * widthThreshold) + (imagePos["X"] * this.state.slide_data.uperpixel), (-1 * yOffset * heightThreshold) + this.state.imageShape[1] - (imagePos["Y"] * this.state.slide_data.uperpixel)];
      return [[pointA, pointB, pointC, pointD, pointA]];
	} else {
		return [[]];
	}
  }

	openZoomAdjustmentApp = (e) => {
		this.props.dispatch(updateAppClosedStatus(keys.displaySizeCalibrationKey.id, false, this.props.urlState));
		e.stopPropagation();
	}

	stopViewing = () => {
		if (this.props.loginAlerts.is_staff || this.props.loginAlerts.superuser) {
			window.location.href = "/" + globalUrlPrefix + "/dashboard/";
		} else {
			window.location.href = "/" + globalUrlPrefix + "/cases/";
		}
	}

	updateTileCoordinates = (tileCoordinates) => {
		let stackimage = Object.assign({}, this.state.stackimage);
		stackimage.tileCoord = tileCoordinates;
		this.setState({
      stackimage,
    });
  }

  goHome = () => {
    if (this.state.layer !== undefined) {
      this.state.slidemap.getView().fit(this.state.extent);
    }
  }

  render = () => {

    let isMObile = checkIfMobile();

    if (this.state.isErrored) {
      console.log(this.state.errMessage);
    }
    return (
      this.state.isFetching || this.state.isErrored ? <div>Loading</div> :
        isNaN(this.props.urlState.x) || isNaN(this.props.urlState.y) || isNaN(this.props.urlState.z) || isNaN(this.props.urlState.r) ?
        getErrorComponent() :
        <div>
          <div className={(this.props.urlState || {}).presentCode !== undefined ? "no-pointer-activity" : ""}>
            <Row className="slide-map-row"> 
                <Col className="slide-map-col">
                  <Row>
					  {(this.props.urlState || {}).presentCode !== undefined ?
						  <div>
							  <div className="border-divider border-divider-top"></div>
							  <div className="border-divider border-divider-left"></div>
							  <div className="border-divider border-divider-right"></div>
							  <div className="border-divider border-divider-bottom"></div>
						  </div>
						  : null
					  }
					  <AppBoard slideState={this.state} updateTileCoordinates={this.updateTileCoordinates}
								viewerType={slideViewerType.NORMAL_SLIDE_VIEWER} showIcon={true}/>
					  {(this.props.urlState || {}).presentCode ?
						  <Button className="stop-presentation" type='danger' onClick={this.stopViewing}>
							  Exit Presentation
						  </Button> :
						  null
					  }
					  <Col tabIndex="0" id={"map-" + this.props.map_id} className="slide-map-original"
						   style={{backgroundColor: this.state.backgroundColor}}>
						  <div id="myposition"></div>
						  {(this.props.urlState || {}).presentCode ? null :
							  <img className="morphle-watermark" id="morphle-watermark" src={MorphleLogo}
								   alt={"logo"}/>}
						  {(((this.props.urlState || {}).app_state || {})[keys.displaySizeCalibrationKey.id] || {})[this.screenSizeString] === undefined
						  && !(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience ?
							  <div className="adjustment-error">
								  <Button type='danger' onClick={this.openZoomAdjustmentApp}>
									  <Icon type="exclamation" style={{color: 'white'}}/> Different screen size
									  detected.
									  Click to adjust the size.
								  </Button>
							  </div>
							  : null
						  }
						  {this.state.shiftScrollCount <= 10 && !isMObile && this.props.urlState.takeZStack ?
							  <div className="main-background-color loading-message-slidemap">
								  <b style={{color: 'white'}}>Use SHIFT + Mouse Scroll Wheel to navigate between Z-Stack
									  Layers</b>
							  </div>
							  : null
						  }
					  </Col>
				  </Row>
                </Col>
            </Row>
          </div>
        </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    urlState: state.viewerUrlReducer,
    loginAlerts: state.loginAlerts,
  };
};

export default connect(mapStateToProps)(SlideMap);
