export const UPDATE_MAP_STATE = "UPDATE_MAP_STATE";
export const UPDATE_MAPS_STATE = "UPDATE_ALL_MAP_STATE";
export const UPDATE_SLIDE_STATE = "UPDATE_SLIDE_STATE";
export const UPDATE_GRID_STATE = "UPDATE_GRID_STATE";
export const UPDATE_VIEWER_SETTINGS = "UPDATE_VIEWER_SETTINGS";
export const UPDATE_BLOOD_VIEWER_SETTINGS = "UPDATE_BLOOD_VIEWER_SETTINGS";
export const UPDATE_ANNOTATION_STATE = "UPDATE_ANNOTATION_STATE";
export const ADD_ANNOTATION = "ADD_ANNOTATION";
export const DELETE_ANNOTATION = "DELETE_ANNOTATION";
export const UPDATE_BLOOD_ANNOTATION_STATE = "UPDATE_BLOOD_ANNOTATION_STATE";
export const UPDATE_IHC_STATE = "UPDATE_IHC_STATE";
export const UPDATE_MAP_IN_FOCUS = "UPDATE_MAP_IN_FOCUS"