import React, {Component} from "react";
import {connect} from "react-redux";
import {IconButton, MenuItem, Select, Stack, TextField,} from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '@mui/icons-material/Circle';
import {FaCircle} from "react-icons/all";
import Typography from "@mui/material/Typography";
import {AnnotationsConstants} from "../../../utils/const";
import {getFormattedArea, getFormattedLength} from "../../../utils/utils";
import AnnotationComments from "./annotationComments";
import {deleteAnnotation, updateAnnotation,} from "../../../action/maps.state.action";
import {getInfoRow, HorizontalDivider} from "../components/components";

class AnnotationListExpandedComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit_mode: false,
            filteredColor: AnnotationsConstants.ALL_COLORS,
            inputValue: this.props.annotation.title,
        }
    }

    toggleEditMode = () =>
        this.setState({
            edit_mode: !this.state.edit_mode,
        });

    getEditComponent = () => <IconButton onClick={this.toggleEditMode} color={'secondary'}>
        {this.state.edit_mode ? <EditOffIcon/> : <ModeEditIcon/>}
    </IconButton>

    getDeleteComponent = () => <IconButton onClick={() =>
        this.props.dispatch(deleteAnnotation(this.props.activeMapId, this.props.annotation))}
                                           color={'secondary'}> <DeleteIcon/> </IconButton>

    updateAnnotationGeneric = (annotation) =>
        this.props.dispatch(updateAnnotation(this.props.activeMapId, annotation));

    getColorAndNameComponent = () =>
        <Stack direction={"row"}>
            <FaCircle style={{color: this.props.annotation.color, marginTop: '0.3vh'}}/>
            <Typography gutterBottom={true} sx={{marginLeft: '0.5vw'}}>{this.props.annotation.title}</Typography>
        </Stack>

    getColorAndNameEditComponent = () =>
        <Stack direction={"row"}>
            <Select size={"small"} value={this.props.annotation.color.toLowerCase()}
                    onChange={e => this.updateAnnotationGeneric({
                        id: this.props.annotation.id,
                        color: e.target.value,
                    })}>
                {Object.values(AnnotationsConstants.ANNO_COLORS).map(color =>
                    <MenuItem value={color.toLowerCase()}>
                        <CircleIcon sx={{color: color.toLowerCase()}}/>
                    </MenuItem>
                )}
            </Select>
            <TextField autoFocus color='secondary' variant="filled"
                       value={this.state.inputValue} onKeyDown={e => {
                if (e.key === 'Enter') {
                    this.updateAnnotationGeneric({
                        id: this.props.annotation.id,
                        title: this.state.inputValue,
                    });
                    this.setState({edit_mode: false});
                    e.preventDefault();
                }
            }}
                       onChange={e =>
                           this.setState({
                               inputValue: e.target.value
                           })}
                       sx={{px: 1, backgroundColor: '#0b1c3b', justifyContent: 'center'}}/>
        </Stack>

    getInfoComponent = () =>
        <Stack direction={"column"}>
            <HorizontalDivider/>
            {this.props.annotation.shape === AnnotationsConstants.LINE ?
                getInfoRow("Length", getFormattedLength(this.props.annotation.perimeter)) :
                [getInfoRow("Area", getFormattedArea(this.props.annotation.area)),
                    <HorizontalDivider/>,
                    getInfoRow("Perimeter", getFormattedLength(this.props.annotation.perimeter))]}
        </Stack>

    render() {
        return <Stack direction={"column"} spacing={1}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                {this.state.edit_mode ? this.getColorAndNameEditComponent() : this.getColorAndNameComponent()}
                <Stack direction={"row-reverse"}>
                    {this.getDeleteComponent()}
                    {this.getEditComponent()}
                </Stack>
            </Stack>
            {this.props.extra}
            {this.getInfoComponent()}
            <AnnotationComments annoId={this.props.annotation.id}/>
        </Stack>
    }
}

const mapStateToProps = (state) => ({
    activeMapId: state.gammaStateReducer.activeMapId,
});

export default connect(mapStateToProps)(AnnotationListExpandedComponent)
