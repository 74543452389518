import { WBCViewActions } from "../actionTypes/wbcView.const";

const InitialState = {
    page: '',
    update_image: false,
    selected_grid: '',
    page_size:'',
    all_grids:[],
    zStack: 0,
    tag: '',
    update_summary: false,
}

export const wbcViewReducer = (state = InitialState, action) => {
    switch(action.type){
        case WBCViewActions.UPDATE_PAGE:
            console.log("page", action.response)
            return Object.assign({}, state, {
                page: action.response,
            })
        case WBCViewActions.FILTER_CHANGE_IMAGE:
            console.log("hfjisdhfj", action.response)
            return Object.assign({}, state, {
                update_image:action.response,
            })
        case WBCViewActions.FILTER_GRID_SELECTED:
            console.log("hfjisdhfj", action.response)
            return Object.assign({}, state, {
                selected_grid: action.response,
            })
        case WBCViewActions.UPDATE_PAGE_SIZE:
            return Object.assign({}, state, {
                page_size: action.response,
            })
        case WBCViewActions.UPDATE_ALL_GRIDS:
            return Object.assign({}, state, {
                all_grids: action.response,
            })
        case WBCViewActions.UPDATE_Z_STACK:
            return Object.assign({}, state, {
                zStack: action.response
            })
        case WBCViewActions.RESET_Z_STACK:
            return Object.assign({}, state, {
                zStack: 0,
            })
        case WBCViewActions.CHANGE_TAG:
            return Object.assign({}, state, {
                tag: action.response
            })
        case WBCViewActions.UPDATE_SUMMARY:
            return Object.assign({}, state, {
                update_summary: action.response
            })
        case WBCViewActions.RESET:
            return Object.assign({}, state, {
                page: '',
                update_image: false,
                selected_grid: '',
                page_size:'',
                all_grids:[],
                zStack: 0
            })
        default :
            return state
    }
}