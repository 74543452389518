import {SlideActions} from '../actionTypes/slides.constant';
import {changePage, changeTotal, changeTotalNumberOfPages} from "../action/dashboard.action"
import {searchFailed, searchInitiated} from "../action/search.action"
import {setUIBusy, setUIIdle} from '../action/utils.action';
import {SearchAlertConstant} from "../actionTypes/search.constant";
import {DashboardConstants} from "../actionTypes/dashboard.constant";
import axios from 'axios';
import {AuthHeader} from "../helper/auth.token"
import {message} from "antd";
import {call, delay, put, race, take} from 'redux-saga/effects'
import cookie from "react-cookies";
import querystring from "query-string";

export const allSlidesLoadingSuccess = (success) => (dispatch) => {
    dispatch({
        type: SlideActions.ALL_SLIDES_UPDATE_SUCCESS,
        response: success
    });
    dispatch(changeTotalNumberOfPages(success.data.total_pages));
    dispatch(changePage(success.data.page_number));
    dispatch(changeTotal(success.data.count));
}

export const slideListUpdateInitiated = (id) => ({
    type: SlideActions.SLIDE_LIST_UPDATE_INITIATED,
    id: id
})

export const slideListUpdateSuccess = (id, updatedInfo) => ({
    type: SlideActions.SLIDE_LIST_UPDATE,
    updatedInfo: updatedInfo,
    id: id
})

export const setSlideListFetched = (id) => ({
    type: SlideActions.SET_SLIDE_LIST_ITEM_FETCHED,
    id: id
})

export const slideListDeleteSuccess = (id) => ({
    type: SlideActions.SLIDE_LIST_DELETE,
    id: id
})

export const slideListUpdateFailed = (id, error) => ({
    type: SlideActions.SLIDE_LIST_UPDATE_FAILED,
    response: error,
    id: id
})

export const slideStatusFetched = (id, data) => ({
    type: SlideActions.SLIDE_STATUS_UPDATED,
    status: data,
    id: id,
})

export const slideUpdateAlert = (id, value) => (dispatch) => {
    dispatch(slideListUpdateInitiated(id));
    let url = `/api/slide/${id}/`
    axios.put(url, value, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200 || response.status === 301 || response.status === 302) {
                response.data.displayOrder = value.displayOrder;
                dispatch(slideListUpdateSuccess(id, response.data));
            }
            else if (response.status === 403 || response.status === 401 || response.status === 400) {
                dispatch(slideListUpdateFailed(id, response));
            }
        })
        .catch(err => {
            dispatch(slideListUpdateFailed(id, err));
        });
}

export const descUpdateAlert = (id, value, displayOrder) => (dispatch) => {
    dispatch(slideListUpdateInitiated(id));
    let url = `/api/add_desc/`;
    let val = {"desc": value, "slide_id" : id};
    axios.post(url, querystring.stringify(val), {headers: {Authorization : AuthHeader()}})
        .then(response => {
            if (response.status === 200 || response.status === 301 || response.status === 302) {
                response.data.displayOrder = displayOrder;
                dispatch(slideListUpdateSuccess(id, response.data));
                message.success('Slide Update Successful', 2.5);
            }
            else if (response.status === 403 || response.status === 401 || response.status === 400) {
                dispatch(slideListUpdateFailed(id, response));
                message.success('Slide Update Failed', 2.5);
            }
        })
        .catch(err => {
            dispatch(slideListUpdateFailed(id, err));
        });
}

export const makeSlideRead = (id, updateFlag = false, value = {}) => (dispatch) => {
    // Skipping this call on OH server, don't merge this code
    return
    // let url = `/api/make_slide_read/`;
    // let value = {slide_id: id};
    // axios.post(url, querystring.stringify(value), { headers: { Authorization: AuthHeader() } })
    //     .then(response => {
    //         if (response.status === 200 || response.status === 301 || response.status === 302) {
    //             if(updateFlag) {
    //                 response.data.displayOrder = value.displayOrder;
    //                 dispatch(slideListUpdateSuccess(id, response.data));
    //             }
    //         }
    //         else if (response.status === 403 || response.status === 401 || response.status === 400) {
    //             // dispatch(caseListUpdateFailed(id, response));
    //         }
    //     })
    //     .catch(err => {
    //         // dispatch(caseListUpdateFailed(id, err));
    //     });
}

export const loadSlides = (partsOfUrl) => dispatch => {
    dispatch(searchInitiated());

    var encodedUrl = urlEncoder(partsOfUrl);
    let url = `/api/slide/` + encodedUrl;
    axios.get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
        .then(response => {
            if (response.status === 200) {
                dispatch(allSlidesLoadingSuccess(response));
                dispatch({
                    type: SearchAlertConstant.UPDATE_URL_STATE,
                    urlState: {
                        ...partsOfUrl,
                        isFetching: false,
                        page: response.data.page_number,
                        numPages: response.data.total_pages,
                        total: response.data.count
                    },
                });
                let ids_to_poll = response.data.results.map(slide => slide.id);
                dispatch({
                    type: SlideActions.SET_POLLING_SLIDES,
                    ids_to_poll
                })
            }
            else {
                dispatch(searchFailed(response));
            }
        })
        .catch(err => {
            dispatch(searchFailed(err));
        })
}

export const loadInvalidSlides = (partsOfUrl) => dispatch => {
    dispatch(searchInitiated());

    var encodedUrl = urlEncoder(partsOfUrl);
    let url = `/api/invalidslides/` + encodedUrl;
    axios.get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
        .then(response => {
            if (response.status === 200) {
                dispatch(allSlidesLoadingSuccess(response));
                dispatch({
                    type: SearchAlertConstant.UPDATE_URL_STATE,
                    urlState: {
                        ...partsOfUrl,
                        isFetching: false,
                        page: response.data.page_number,
                        numPages: response.data.total_pages,
                        total: response.data.count
                    },
                });
                let ids_to_poll = response.data.results.map(slide => slide.id);
                dispatch({
                    type: SlideActions.SET_POLLING_SLIDES,
                    ids_to_poll
                })
            }
            else {
                dispatch(searchFailed(response));
            }
        })
        .catch(err => {
            dispatch(searchFailed(err));
        })
}

export const changeSlideOwner = (slide, slideOwner) => dispatch => {
    dispatch(setUIBusy());
    let url = `/api/change_slide_owner/?slide_id=` + slide.id + '&slide_owner=' + slideOwner;
    axios.get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
        .then(response => {
            if (response.status === 200) {
                response.data.result.displayOrder = slide.displayOrder;
                dispatch(slideListUpdateSuccess(slide.id, response.data.result));
            }
            dispatch(setUIIdle());
        })
        .catch(err => {
            message.error("Not able to change owner.", 2.5);
            console.log(err);
            dispatch(setUIIdle());
        })
}

export const changeOwnerFilteredSlides = (partsOfUrl, slideOwner, totalSlides) => dispatch => {
    dispatch(setUIBusy());
    var encodedUrl = urlEncoder(partsOfUrl);
    let url = `/api/nonpagslides/` + encodedUrl;
    axios.get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
        .then(response => {
            if (response.status === 200) {
                if(response.data.length == totalSlides) {
                    let slidesIDs = response.data.map((value, index) => {
                        return value.id;
                    });
                    let value = {
                        slide_ids: slidesIDs,
                        slide_owner: slideOwner
                    }
                    url = `/api/change_owner_filtered_slides/`;
                    axios.post(url, querystring.stringify(value), { headers: { Authorization: AuthHeader() } })
                        .then(response => {
                            if (response.status === 200 || response.status === 301 || response.status === 302) {
                                message.success("Owner changed successfully.", 2.5);
                                dispatch(loadSlides(partsOfUrl));
                            }
                            dispatch(setUIIdle());
                        })
                        .catch(err => {
                            message.error("Not able to change owner.", 2.5);
                            console.log(err);
                            dispatch(setUIIdle());
                        });
                } else {
                    message.error("Not able to change owner.", 2.5);
                    dispatch(setUIIdle());
                }

            }
        })
        .catch(err => {
            message.error("Not able to change owner.", 2.5);
            console.log(err);
            dispatch(setUIIdle());
        })
}

export const loadTags = () => dispatch => {
    let url = `/api/get_all_tags/`;
    axios.get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
        .then(response => {
            if (response.status === 200) {
                console.log(response)
                dispatch({
                    type: DashboardConstants.INIT_TAGS,
                    tags: response.data.tags,
                });
            }
            else {
                console.log(response);
            }
        })
        .catch(err => {
            console.log(err);
        })
}

export const urlEncoder = (partsOfUrl) => {
    var encodedUrl = '?';
    if (partsOfUrl === undefined) {
        return encodedUrl;
    }
    if ('tags' in partsOfUrl) {
        var tags = partsOfUrl.tags;
        if (tags.length > 0) {
            encodedUrl += '&tags=';
            tags.map((tag) => encodedUrl += tag.replace('#', '%23') + '%20');
        }
    }
    if ('searches' in partsOfUrl) {
        var searches = partsOfUrl.searches;
        searches.map((search) => encodedUrl += '&search=' + search);
    }
    if ('starred' in partsOfUrl) {
        encodedUrl += partsOfUrl.starred ? '&starred=true' : '';
    }
    if ('barcode_failed' in partsOfUrl) {
        encodedUrl += partsOfUrl.barcode_failed ? '&barcode_failed=true' : '';
    }
    if ('case_not_assigned' in partsOfUrl) {
        encodedUrl += partsOfUrl.case_not_assigned ? '&case_not_assigned=true' : '';
    }
    if ('ordering' in partsOfUrl) {
        encodedUrl += '&ordering=' + partsOfUrl.ordering;
    }
    if ('date__gte' in partsOfUrl) {
        encodedUrl += '&date__gte=' + partsOfUrl.date__gte;
    }
    if ('date__lte' in partsOfUrl) {
        encodedUrl += '&date__lte=' + partsOfUrl.date__lte;
    }
    if ('page' in partsOfUrl) {
        encodedUrl += '&page=' + partsOfUrl.page;
    }
    if ('device_id' in partsOfUrl) {
        if (!partsOfUrl.device_id) {
            encodedUrl += '&device_id=' + "";
        } else {
            encodedUrl += '&device_id=' + parseInt(partsOfUrl.device_id)
        }
    }
    return encodedUrl;
}


export const deleteSlide = (value, urlState) => (dispatch) => {
    dispatch(slideListUpdateInitiated(value.id));
    let url = `/server/delete_scan/?morphle_id=${value.morphle_id}`;
    axios
        .get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
                dispatch(loadSlides(urlState));
                dispatch(slideListDeleteSuccess(value.id));
            } else {
                message.error(response.data);
                dispatch(slideListUpdateFailed(value.id, response));
            }
        })
        .catch(err => {
            message.error("Delete could not be Performed. Contact Admin.");
            dispatch(slideListUpdateFailed(value.id, err));
        });
};


function* pollForPollables(ids_to_poll) {
    while (cookie.load('token')) {
        try {
            let tilingStatusUrl = "/server/get_pollables/";
            const { data } = yield call(() => axios.post(tilingStatusUrl, { 'ids_to_poll': ids_to_poll }));
            yield put(updatePollables(Object.assign({}, data)));
            yield delay(2000);
            if (data.keep_polling === false) {
                yield put(stopPollingForPollables());
            }
        } catch (err) {
            console.log(err);
            // yield put(failedToUpdatePollables(err));
        }
    }
}

export const watchForPollables = function* (ids_to_poll) {
    yield take(SlideActions.START_POLLING);
    yield race([
        call(pollForPollables, ids_to_poll),
        take(SlideActions.STOP_POLLING)
    ]);
}

export const updatePollables = (data) => {
    return {
        type: SlideActions.UPDATE_POLLING,
        data: data
    }
}

export const failedToUpdatePollables = (id, err) => ({
    type: SlideActions.FAILED_IN_TILING_POLLING_UPDATE,
    err: err,
    id: id
})

export const startPollingForPollables = () => ({
    type: SlideActions.START_POLLING
})

export const stopPollingForPollables = () => ({
    type: SlideActions.STOP_POLLING
})

export const startTiling = (morphle_id, id, ids_to_poll) => dispatch => {
    dispatch(slideListUpdateInitiated(id));
    let url = `/server/start_tiling/?morphle_id=${morphle_id}`;
    axios
        .get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            dispatch(setSlideListFetched(id));
            dispatch({
                type: SlideActions.SET_POLLING_SLIDES,
                ids_to_poll
            })
            message.success("Tiling Started For: " + morphle_id);
        })
        .catch(err => {
            message.error("Error. Could not schedule for tiling.");
        });
}

export const startExport = (morphle_id, name, id, ids_to_poll) => dispatch => {
    dispatch(slideListUpdateInitiated(id));
    let url = `/server/start_export/?morphle_id=${morphle_id}`;
    axios
        .get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            dispatch(setSlideListFetched(id));
             dispatch({
                type: SlideActions.SET_POLLING_SLIDES,
                ids_to_poll
            })
            message.success("Export Started For: " + name);
        })
        .catch(err => {
            dispatch(setSlideListFetched(id));
            message.error("Error. Could not schedule for export.");
        });
}

export const startUpload = (morphle_id, id, ids_to_poll, upload_debug, upload_debug_only) => dispatch => {
    dispatch(slideListUpdateInitiated(id));
    let url = `/server/start_upload/?morphle_id=${morphle_id}&upload_debug=${upload_debug}&upload_debug_only=${upload_debug_only}`;
    axios
        .get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            dispatch(setSlideListFetched(id));
             dispatch({
                type: SlideActions.SET_POLLING_SLIDES,
                ids_to_poll
            })
            message.success("Upload Started For: " + morphle_id);
        })
        .catch(err => {
            message.error("Upload Failed. Contact Admin.");
        });
}

export const startDebugUpload = (morphle_id, id, ids_to_poll) => dispatch => {
    dispatch(slideListUpdateInitiated(id));
    let url = `/start_debug_upload/?morphle_id=${morphle_id}`;
    axios
        .get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            dispatch(setSlideListFetched(id));
             dispatch({
                type: SlideActions.SET_POLLING_SLIDES,
                ids_to_poll
            })
            message.success("Upload Started For: " + morphle_id);
        })
        .catch(err => {
            message.error("Upload Failed. Contact Admin.");
            console.log(err)
        });
}

export const getSlideStatus = (morphle_id, slot_id) => dispatch => {
    let url = `/server/slide_status/?morphle_id=`+morphle_id;
    axios.get(url)
        .then(response => {
            console.log(response);
            let data = JSON.parse(response.data)
            dispatch(slideStatusFetched(slot_id, data))
        })
        .catch(err => {
            console.log("error");
        })
}
