import React, {Component} from "react";
import ImageLayer from "ol/layer/Image";
import Static from "ol/source/ImageStatic";
import Projection from "ol/proj/Projection";
import {Map, View,} from "ol";
import {getCenter} from "ol/extent";
import {isArrayContentEqual} from "../../../utils/utils";
import VectorSource from "ol/source/Vector";
import {getAnnotationsFeature} from "../utils/annotations_app_utils";
import {connect} from "react-redux";
import {updateDrawInteractions} from "../utils/drawUtils";
import {createVectorLayer} from "../utils/map_utils";

class StaticMap extends Component {

	constructor(props) {
		super(props);

		this.defaultExtent = [0, 0, 100, 100];
		this.defaultViewProperties = {
			zoom: 1,
			minZoom: 1,
			maxZoom: 3,
		}
		this.initialiseMap();
	}

	initialiseMap = () => {
		this.imageLayer = new ImageLayer({});
		this.map = new Map({
			layers: [this.imageLayer],
		});
		this.vectorLayer = createVectorLayer(this.map, this.props.vectorLayerProperties);
		this.createViewAndSetSources();
	}

	// taken from https://stackoverflow.com/a/51063852
	getMeta = (url, callback) => {
		var img = new Image();
		img.src = url;
		img.onload = function() { callback(this.width, this.height); }
	}

	createViewAndSetSources = () => {
		let _this = this;
		this.getMeta(
			this.props.image,
			function(width, height) {
				_this.createView(width, height);
				_this.setImageSource();
				_this.setVectorSource();
			}
		);
	}

	createView = (w, h) => {
		this.extent = [0, 0, w, h] || this.defaultExtent;
		this.view = new View({
			projection: new Projection({
				units: 'pixels',
				extent: this.extent,
			}),
			center: getCenter(this.extent),
			...this.defaultViewProperties,
		});
		this.map.setView(this.view);
	}

	setImageSource = () => {
		this.imageLayer.setSource(new Static({
			url: this.props.image,
			projection: this.view.getProjection(),
			imageExtent: this.view.getProjection().getExtent(),
		}));
	}

	setVectorSource = () => {
		this.vectorLayer.setSource(new VectorSource({
			features: getAnnotationsFeature(this.props.annotations),
			wrapX: false,
		}));
	}

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
		this.map.setTarget(null);

		if (!isArrayContentEqual(prevProps.extent || [], this.props.extent || []) ||
			prevProps.slideId !== this.props.slideId || prevProps.image !== this.props.image)
			// TODO: [Priyanshu] figure out how to update extent and source without initializing the map again
			this.initialiseMap();

		if (prevProps.annotations !== this.props.annotations)
			this.setVectorSource();

		if (prevProps.selectedDrawTool !== this.props.selectedDrawTool)
			updateDrawInteractions(this.map, this.vectorLayer, this.props.selectedDrawTool, this.props.addFeature);

		this.map.setTarget(this.props.id);
	}

	render() {
		return <div id={this.props.id} style={{width: '100%', height: '100%'}}/>;
	}
}

const mapStateToProps = (state) => {
	let selectedDrawTool = state.gammaStateReducer.selectedDrawTool;
	return {selectedDrawTool};
};

export default connect(mapStateToProps)(StaticMap);
