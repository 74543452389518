import React, {Component} from 'react';
import axios from "axios";
import {AuthHeader} from '../../../helper/auth.token';
import cookie from "react-cookies";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {displayError} from "../../../helper/display.error";
import {HorizontalDivider} from "../components/components";
import {StyledInputBase} from "../components/StyledComponents";

class AnnotationComments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: [],
            submitting: false,
            value: '',
            data: null,
            username: cookie.loadAll().username,
            userId: cookie.loadAll().user_id,
        }
    }

    componentDidMount() {
        this.getComment(this.props.annoId);
    }

    getComment(value) {
        if(value !== null) {
            let url = `/api/comments/?annotation=${value}`
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    if (response.status === 201 || response.status === 200 || response.status === 301 || response.status === 302) {
                        this.extractComment(response.data);
                    }
                    else if (response.status === 403 || response.status === 401 || response.status === 400) {
                        displayError('Comments Update Failed');
                    }
                })
                .catch(err => displayError("Comments Update Failed", err));
            }
    }

    handleSubmit = () => {
        if (!this.state.value) {
            return;
        }
        setTimeout(() => {
            let val = { "author": this.state.username, "content": this.state.value };
            this.sendComment(val);
        }, 100);

        this.setState({
            submitting: true,
        });

        setTimeout(() => {
            this.setState({
                submitting: false,
                value: '',
                comments: [
                    ...this.state.comments,
                    {
                        author: this.state.username,
                        content: this.state.value
                    },
                ],
            });
        }, 100);
    }

    extractComment = (data) => {
        var com = [];
        data.reverse().map(index => {
            let par = JSON.parse(index.comment);
            com.push(par);
            return par;
        });
        this.setState({
            comments: com
        });
        this.updateScroll();
    }

    updateScroll() {
        let element = document.getElementById("messageBody");
        if (element)
            element.scrollTop = element.scrollHeight;
    }

    handleChange = (e) => {
        this.setState({
            value: e.target.value,
        });
    }

    sendComment(value) {
        let val = { "user": this.state.userId, "comment": JSON.stringify(value), "annotation": this.props.annoId };
        let url = `/api/comments/`;
        axios.post(url, val, {headers: {Authorization: AuthHeader()}})
            .then(response => {
                if (response.status === 201 || response.status === 200 || response.status === 301 || response.status === 302) {
                    this.updateScroll();
                } else if (response.status === 403 || response.status === 401 || response.status === 400)
                    displayError('Failed to add comments');
            })
            .catch(err => displayError("Failed to add comments", err));
    }

    getComments = () =>
        <Stack direction={"column"} spacing={0.5} maxHeight={200} overflow={"auto"}>
            {this.state.comments.map((comment, index) => (
                <div key={index}>
                    <HorizontalDivider sx={{my: 0.5, opacity: 0.4}}/>
                    <Typography variant="body1" sx={{fontWeight: "bold"}}>{comment.author}</Typography>
                    <Typography variant="body2">{comment.content}</Typography>
                </div>
            ))}
        </Stack>

    getInput = () =>
        <StyledInputBase
            size={'small'}
            placeholder="Type Comment..."
            onChange={this.handleChange}
            disabled={this.state.submitting}
            value={this.state.value}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    this.handleSubmit();
                    e.preventDefault();
                }
            }}
        />

    render() {
        return <Stack direction={"column"}>
            <Typography variant={"overline"} align={"left"} sx={{fontSize: "0.9vw", fontWeight: "bold"}}>
                Comments
            </Typography>
            {this.getComments()}
            {this.getInput()}
        </Stack>
    }
}

export default AnnotationComments;

