import React, {Component} from 'react';
import {connect} from "react-redux";
import {debounce} from 'lodash';
import {Button, Col, Icon, message, Row} from 'antd';
import {
	loadMorpheusSettings,
	updateAppClosedStatus,
	updateDigitalZoomStatus,
	updateMapParams,
	updateMorphleID,
	updateOnStitchedLayer,
	updateUrlStateInReducer,
	updateZStackLevel
} from "../../action/morpheus.state.action"
import {getSlideAndInitialiseMapState, recreateMapLayers} from './map_utils'
import {AnnotationsConstants, globalUrlPrefix, mapLayerTypes, MorphleLogo} from "../../utils/const";
import "../../asset/style/neoviewer/slidemap.css"
import cookie from 'react-cookies';
import {getErrorComponent} from '../dashboard/slidelist_utils';
import {AuthHeader} from "../../helper/auth.token";
import axios from "axios";
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import Circle from 'ol/geom/Circle';
import LineString from 'ol/geom/LineString'
import VectorSource from 'ol/source/Vector';
import {Fill, Stroke, Style} from 'ol/style.js';
import * as keys from '../neoviewer/apps/app_keys'
import {checkIfMobile} from '../../utils/utils';
import {KeyboardPan, Translate} from 'ol/interaction';
import {Vector as VectorLayer} from 'ol/layer.js';
import {bloodAnnoSelected, bloodAnnoStopMove, reset, updatePage} from "../../action/bloodGrid.action";
import {fieldGridSelected, updateAllGrids, updatePageFieldView} from '../../action/fieldView.action';
import {updateAllGridsWBC, updatePageWBCView, wbcGridSelected} from '../../action/wbcview.action';
import {yBottomChanger, yTopChanger} from '../../action/bloodConfig.action';
import {click} from 'ol/events/condition.js';
import Select from 'ol/interaction/Select.js';

import querystring from "query-string";
import {isZoomForTiled} from "../gammaviewer/utils/map_utils";


const style = new Style({
  stroke: new Stroke({
    color: '#7CFC00',
    width: 2,
  }),
  fill: new Fill({
    color: 'rgba(0, 0, 0, 0.0)'
  }),
})


const PreviewLineStyle = new Style({
  stroke: new Stroke({
    color: '#7CFC00',
    width: 5,
  }),
  fill: new Fill({
    color: 'rgba(256, 256, 256, 0.1)'
  }),
})

const PreviewLineStyleSelected = new Style({
  stroke: new Stroke({
    color: 'blue',
    width: 5,
  }),
  fill: new Fill({
    color: 'rgba(256, 256, 256, 0.1)'
  }),
})

const PreviewRegionStyleGray = new Style({
  stroke: new Stroke({
    color: '#424240',
    width: 5,
  }),
  fill: new Fill({
    color: 'rgb(100,100,100, 0.1)'
  }),
})

const styleFunction = (feature) => {
	if (feature.getProperties().color) {
		style.getStroke().setColor(feature.getProperties().color);
		style.getStroke().setWidth(feature.getProperties().width);
	}
  return [style,]
}

class BloodPreviewSlideMap extends Component {

  constructor(props) {
    super(props);

    this.screenSizeString = window.screen.width + 'x' + window.screen.height;

    this.state = {
      slide: -1,
      isFetching: true,
      isErrored: false,
      loadedMap: false,
      mapId: this.props.map_id,
      stackMode: 0,
      modalVisible: false,
      backgroundColor: "#FFFFFF",
      shiftScrollCount: 0,
      mapRenderComplete: false,
      view_mode: '',
      all_on_map: [],
      all_on_map_field: [],
      yTopMicronPosn: '',
      yBottomMicronPosn: '',
      preview_loaded: false,
    };

    this.vector = new VectorLayer({
      source: new VectorSource({}),
      name: mapLayerTypes.BLOOD_ANNOTATION_LAYER_NAME,
    });

    this.vector.set('annotationLayer', true);

    this.selectHover = new Select({
      condition: click,
      layers: [this.vector],
      wrapX: false,
      hitTolerance: 5,
      style: new Style({
        stroke: new Stroke({
          color: '#7CFC00',
          // color: 'rgba(0, 0, 0, 0.0)',
          width: 2,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0.0)'
        })
      })
    });

    this.initMapState = true;

    this.lastCachingCall = -1;
    this.upperZLevelsCached = 0;
    this.lowerZLevelsCached = 0;

    this.previewMapRef = null;
    this.props.dispatch(loadMorpheusSettings());
    this.props.dispatch(updateUrlStateInReducer(this.props.urlState));
    this.imageDownloadTimer = null;

    this.cacheZStackLayers = debounce(this.cacheZStackLayers.bind(this), 1000);

    if (cookie.loadAll().isMobile === 'true') {
      window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    }
  }

  addSelectInteraction = () => {
    this.selectHover.on("select", e => {
      // this.drawAnnotations(this.state.all_on_map)
      if(this.props.view_mode==4){
        console.log("selected")
        let feature = e.selected[0]
        if(!feature){
            for (let i = 0; i < this.vector.getSource().getFeatures().length; i++) {
              this.vector.getSource().getFeatures()[i].setStyle(PreviewLineStyle)
            }
        }
        if(feature){
          for (let i = 0; i < this.vector.getSource().getFeatures().length; i++) {
            this.vector.getSource().getFeatures()[i].setStyle(PreviewLineStyle)
          }
          console.log("selected--feature", feature)
          feature.setStyle(PreviewLineStyleSelected)
        }
        return;
      }
      if (this.props.field_view == true) {
        if (e.selected.length == 0) {
          return;
        }
        let feature = e.selected[0];
        let anno_id = feature.get('id')
        let slide_id = this.props.slide_id
        if (this.props.mode == "FIELD") {
          let page_size = this.props.fieldGridData.page_size
          let url = `/api/get_selected_field_anno_page/?anno_id=${anno_id}&slide_id=${slide_id}&page_size=${page_size}`
          axios.get(url).then(res => {
            console.log("page", res.data['page'])
            let annos = this.state.all_on_map_field
            for (let i = 0; i < annos.length; i++) {
              if (annos[i].id == feature.get('id')) {
                this.props.dispatch(updatePageFieldView(res.data["page"]))
                this.props.dispatch(fieldGridSelected(annos[i]))
                // this.props.dispatch(resetZStack());
                // this.props.dispatch(updateZStackWrapper(annos[i], this.props.fieldGridData.zStack))
                this.drawAnnotationsFieldView(this.state.all_on_map_field);
              }
            }
          })
        }
        else if (this.props.mode == "WBC") {
          let page_size = this.props.fieldGridData.page_size
          let url = `/api/get_selected_field_anno_page_wbc/?anno_id=${anno_id}&slide_id=${slide_id}&page_size=${page_size}`
          axios.get(url).then(res => {
            console.log("page", res.data['page'])
            let annos = this.state.all_on_map_field
            for (let i = 0; i < annos.length; i++) {
              if (annos[i].id == feature.get('id')) {
                this.props.dispatch(updatePageWBCView(res.data["page"]))
                this.props.dispatch(wbcGridSelected(annos[i]))
                // this.props.dispatch(updateZStackWrapperWBC(annos[i], this.props.wbcGridData.zStack))
                this.drawAnnotationsFieldView(this.state.all_on_map_field);
                // this.props.dispatch(resetZStack());
                break;
              }
            }
          })
        }

      }
      else {
        if (e.selected.length == 0 || this.props.bloodGridData.class != "WBC") {
          return;
        }
        let feature = e.selected[0];
        let anno_id = feature.get('id')
        let slide_id = this.props.slide_id
        let tag = this.props.bloodGridData.tag
        let page_size = this.props.bloodGridData.page_size
        let url = `/api/get_selected_anno_page/?anno_id=${anno_id}&slide_id=${slide_id}&tag=${tag}&page_size=${page_size}`
        axios.get(url).then(res => {
          console.log("page", res.data['page'])
          let annos = this.state.all_on_map
          for (let i = 0; i < annos.length; i++) {
            if (annos[i].oot_id == feature.get('id')) {
              this.props.dispatch(reset());
              this.props.dispatch(bloodAnnoSelected(annos[i]));
              this.props.dispatch(updatePage(res.data["page"]))
              break;
            }
          }
        })
      }
    });
  }

  drawAnnotationsFieldView = (annotations) => {
    let vector = this.vector
    let new_annotations = annotations
    // for(let i=0;i<new_annotations.length;i++){
    //   if(new_annotations[i].id == this.props.fieldGridData.selected_grid.id){
    //     new_annotations.splice(i,1)
    //   }
    // }
    // new_annotations.push(this.props.fieldGridData.selected_grid.id)
    this.setState({
      all_on_map_field: new_annotations,
    })
    let showAnno = true;
    let allFeatures = [];
    // let annotations=[annotation,]
    console.log("annotation", annotations)

    annotations = annotations.map((annotation, index) => {
      let feature;
      if (annotation.new_bounds !== null) {
        //   if (annotation.shape === AllAnnotationsConstants.POLYGON) {
        let bounds = JSON.parse(annotation.new_bounds);
        let feature;
        if (annotation.shape == AnnotationsConstants.POLYGON) {
          feature = new Feature({
            geometry: new Polygon(bounds),
            id: annotation.id,
            name: annotation.shape,
            title: annotation.title,
          });
        }
        else if (annotation.shape == AnnotationsConstants.CIRCLE) {
          let center = bounds[0];
          let radius = bounds[1];
          feature = new Feature({
            geometry: new Circle(center, radius),
            id: annotation.id,
            name: annotation.shape,
            title: "",
          });
        }

        //   }
        // feature.setId(annotation.oot_id);
        // feature.set('creator', annotation.anno_drawer);
        feature.set('color', annotation.color)
        if (annotation.id == ((this.props.fieldGridData || {}).selected_grid || {}).id) {
          feature.set('width', 6);
        }
        else {
          feature.set('width', 2);
        }
        allFeatures.push(feature);
      }
      return feature;
    });
    console.log("allFeatures", allFeatures)
    vector.setSource(new VectorSource({
      features: allFeatures,
      wrapX: false
    }))

    vector.setStyle(styleFunction);
  }

  drawAnnotations = (annotations) => {
    let vector = this.vector
    let new_annotations = annotations
    for (let i = 0; i < new_annotations.length; i++) {
      if (new_annotations[i].id == this.props.bloodGridData.selected_anno.id) {
        new_annotations.splice(i, 1)
      }
    }
    new_annotations.push(this.props.bloodGridData.selected_anno)
    this.setState({
      all_on_map: new_annotations,
    })
    let showAnno = true;
    let allFeatures = [];
    // let annotations=[annotation,]
    annotations = annotations.map((annotation, index) => {
      let feature;
      if (annotation.new_bounds !== null) {
        console.log("annotation", annotation)
        if (annotation.shape === AnnotationsConstants.POLYGON) {
          feature = new Feature({
            geometry: new Polygon(JSON.parse(annotation.new_bounds)),
            id: annotation.oot_id,
            name: annotation.shape,
            title: annotation.title,
          });
        }
        feature.setId(annotation.oot_id);
        feature.set('creator', annotation.anno_drawer);
        feature.set('color', annotation.color);
        allFeatures.push(feature);
      }
      return feature;
    });
    console.log("allFeatures", allFeatures)
    // vector.setSource(new VectorSource({
    //     features:allFeatures,
    //     wrapX: false
    // }))

    vector.setStyle(styleFunction);
  }

  addPreviewMapControl = (previewMapRef) => this.previewMapRef = previewMapRef;

   updateUrlState = () => {
     console.log("view--4", this.state.view.getZoom());
    let tiledLayer = isZoomForTiled(this.state.view.getZoom(), this.state.viewLevelsInfo);
    if (!tiledLayer) {
      this.props.dispatch(updateZStackLevel(0));
    }
    this.props.dispatch(updateOnStitchedLayer(!tiledLayer));
    let zoomRatio = (((this.props.urlState || {}).app_state || {})[keys.displaySizeCalibrationKey.id] || {})[this.screenSizeString] || 1;
    console.log("view--5",(((this.props.urlState || {}).app_state || {})[keys.displaySizeCalibrationKey.id] || {}), this.screenSizeString);
    if (zoomRatio > 1 && Number.isInteger(this.state.view.getZoom()) && this.state.view != undefined && (this.props.urlState || {}).presentCode == undefined) {  
      console.log("view--6");
      if (this.state.view.getZoom() <= this.state.view.getMaxZoom() - 1) {
        console.log("view--7");
        this.state.view.setZoom(this.state.ZValues[this.state.view.getZoom()]);
      } else if (this.state.view.getZoom() <= this.state.view.getMaxZoom()) {
        console.log("view--8");
        this.state.view.setZoom(this.state.ZValues[this.state.view.getZoom() - 1]);
      }
    }
    console.log("view--9", this.state.view)
    this.props.dispatch(updateMapParams(
      this.props.urlState,
      this.state.view.getCenter()[0],
      this.state.view.getCenter()[1],
      this.state.view.getZoom(),
      this.state.view.getRotation(),
    ));
  };

  updateLayers = () => {
    this.setState({
      mapRenderComplete: false,
    });

    let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels) / 2 : (this.props.urlState.numZLevels);
    let minZLevel = this.props.urlState.takeBidirectionalZStack ? (-1 * ((this.props.urlState.numZLevels) / 2)) : 0;

    minZLevel = Math.floor(minZLevel);
    maxZLevel = Math.floor(maxZLevel);

    recreateMapLayers(this.state.slidemap, this.state.viewLevelsInfo, this.state.slide_data, this.state.imageInfo, this.state.projection,
      this.state.resolutions, this.state.txtyInfo, this.state.tileSize, this.updateUrlState, (obj) => {
        obj.addPreviewMapControl = this.addPreviewMapControl;
        obj.goHome = this.goHome;
        this.setState(obj)
      }, this.props.urlState.zStackLevel, minZLevel, maxZLevel, this.props.urlState.takeZStack);
  }

  onMapRenderingComplete = () => {
    this.setState({
      mapRenderComplete: true,
    });
  }

  cacheZStackLayers = () => {
    console.log("Caching started")
    this.lastCachingCall = Date.now();
    let lastCachingCall = this.lastCachingCall;
    this.upperZLevelsCached = 0;
    this.lowerZLevelsCached = 0;
    let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels - 1) / 2 : (this.props.urlState.numZLevels - 1);
    let timerInterval = 1000;
    let timer = 0;
    for (let i = 1; i <= maxZLevel + 1; i++) {
      let layers = this.state.slidemap.getLayers().getArray();
      setTimeout(() => this.cacheLayer(layers, i, lastCachingCall), timer);
      timer += timerInterval;
    }
  }

  cacheLayer = (layers, zLevel, lastCachingCall) => {
    if (lastCachingCall == this.lastCachingCall) {
      for (let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        if (layer.values_.posS == (-1 * zLevel) || layer.values_.posS == zLevel) {
          layer.setVisible(true);
        }
      }
      setTimeout(() => this.layerVisibilityOff(layers), 10);
      this.upperZLevelsCached = this.upperZLevelsCached + 1;
      this.lowerZLevelsCached = this.lowerZLevelsCached - 1;
    }
  }

  layerVisibilityOff = (layers) => {
    for (let i = 0; i < layers.length; i++) {
      let layer = layers[i];
      if (layer.values_.posS == this.props.urlState.zStackLevel || layer.values_.name == mapLayerTypes.ANNOTATION_LAYER_NAME || layer.values_.name == mapLayerTypes.STITCHED_LAYER_NAME || layer.values_.name == mapLayerTypes.BLOOD_ANNOTATION_LAYER_NAME) {
        layer.setVisible(true);
      } else {
        layer.setVisible(false);
      }
    }
  }

  cacheZoomLevels = (upper, layers) => {
    let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels - 1) / 2 : (this.props.urlState.numZLevels - 1);
    if (upper && this.upperZLevelsCached < maxZLevel) {
      for (let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        if (layer.values_.posS == (this.upperZLevelsCached + 1) || layer.values_.posS == (this.upperZLevelsCached + 2)) {
          layer.setVisible(true);
        }
      }
      setTimeout(() => this.layerVisibilityOff(layers), 10);
      this.upperZLevelsCached = this.upperZLevelsCached + 2;
    } else if (!upper && this.lowerZLevelsCached > (-1 * maxZLevel)) {
      for (let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        if (layer.values_.posS == (this.lowerZLevelsCached - 1) || layer.values_.posS == (this.lowerZLevelsCached - 2)) {
          layer.setVisible(true);
        }
      }
      setTimeout(() => this.layerVisibilityOff(layers), 10);
      this.upperZLevelsCached = this.lowerZLevelsCached - 2;
    }
  }

  scrollActionOnMap = (e) => {
    if (e.type !== "wheel") {
      return true;
    } else {
      let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels - 1) / 2 : (this.props.urlState.numZLevels - 1);
      let minZLevel = this.props.urlState.takeBidirectionalZStack ? (-1 * ((this.props.urlState.numZLevels - 1) / 2)) : 0;

      if (this.props.urlState.takeZStack && e.originalEvent.shiftKey) {
        if (this.props.urlState.onStitchedLayer) {
          message.error("Z Stack only allowed on higher zoom levels.", 2.5);
        } else {
          if (e.originalEvent.deltaY > 0 && this.props.urlState.zStackLevel > minZLevel) {
            this.props.dispatch(updateZStackLevel(this.props.urlState.zStackLevel - 1));
            this.setState({
              shiftScrollCount: this.state.shiftScrollCount + 1,
            });
            this.cacheZoomLevels(true, this.state.slidemap.getLayers().getArray());
          } else if (e.originalEvent.deltaY < 0 && this.props.urlState.zStackLevel < maxZLevel) {
            this.props.dispatch(updateZStackLevel(this.props.urlState.zStackLevel + 1));
            this.setState({
              shiftScrollCount: this.state.shiftScrollCount + 1,
            });
            this.cacheZoomLevels(false, this.state.slidemap.getLayers().getArray());
          }
        }
        return false;
      } else {
        return true;
      }
    }
  }

  getExtentAllAnnotations = (final_extent) => {
    let slide_id = this.props.slide_id;
    let extent = final_extent
    let val = {
      id: slide_id,
      extent: extent,
      tag: this.props.bloodGridData.tag
    }
    let url = `/api/get_extent_all_annotations/`
    axios.post(url, querystring.stringify(val), { headers: { Authorization: AuthHeader() } })
      .then(res => {
        this.drawAnnotations(res.data['data'])
        console.log("new_anno", res.data['data'])
      })
  }

  componentDidMount = () => {
    // getSlideAndInitialiseMapState(this.props.slide_id, (obj) => {
    //   obj.goHome = this.goHome;
    //   obj.addPreviewMapControl = this.addPreviewMapControl;
    //   this.setState(obj)
    // }, this.props.urlState, this.updateUrlState);
    if (this.props.field_view == true) {
      this.getAllFieldViewAnnotations();
    }
    console.log("preview_map_mounted")
  }
  getAllFieldViewAnnotations = () => {
    let slide_id = this.props.slide_id;
    if (this.props.mode == "FIELD") {
      let url = `/api/get_all_field_view_annotations/?id=${slide_id}`
      axios.get(url)
        .then(res => {
          this.drawAnnotationsFieldView(res.data['data'])
          this.props.dispatch(updateAllGrids(res.data['data']))
          console.log("new_anno", res.data['data'])
        })
    } else if (this.props.mode == "WBC") {
      //here make a request to wbc  
      let url = `/api/get_all_field_view_annotations_wbc/?id=${slide_id}`
      axios.get(url)
        .then(res => {
          this.drawAnnotationsFieldView(res.data['data'])
          this.props.dispatch(updateAllGridsWBC(res.data['data']))
          console.log("new_anno", res.data['data'])
        })
    }

  }

  onTranslateEnd = () => {
    console.log("translation ended")
  }

  changeYPosn = (feat) => {
    if(feat.getId()==1){
      this.props.dispatch(yBottomChanger(feat.getGeometry().getExtent()[3],this.state.txtyInfo, this.state.slide_data))
    }
    else if(feat.getId()==2){
      this.props.dispatch(yTopChanger(feat.getGeometry().getExtent()[1],this.state.txtyInfo, this.state.slide_data))
    }
  }

  getAnimationZoom = (pt1, pt2) => {
    let zoomLev = 5;
    let diff = pt2 - pt1;
    if(diff > 50000) return 1;
    else if(diff > 30000) return 2;
    else if (diff > 15000) return 3;
    else if (diff > 7500) return 4;
    return zoomLev;
  }

  componentDidUpdate = (prevProps, prevState) => {

    if (prevProps.overrideScanRegion != this.props.overrideScanRegion) {
      if (this.props.overrideScanRegion) {
        this.state.slidemap.addInteraction(this.selectOL)
        this.state.slidemap.addInteraction(this.translateOL)
        this.state.slidemap.addInteraction(this.selectHover);

        this.vector.setStyle(PreviewLineStyle)
        this.vector.getSource().getFeatures().map(feat => {
            feat.setStyle(PreviewLineStyle)
        })
      } else {
        this.state.slidemap.removeInteraction(this.selectOL)
        this.state.slidemap.removeInteraction(this.translateOL)
        this.state.slidemap.removeInteraction(this.selectHover);

        this.vector.setStyle(PreviewRegionStyleGray)
        this.vector.getSource().getFeatures().map(feat => {
          feat.setStyle(PreviewRegionStyleGray)
        })
      }
    }

    if (prevState.loadedMap==false && this.state.loadedMap==true && this.props.view_mode == 4 && this.state.slide_data) {
      let ytop = this.props.y_top
      let ybottom = this.props.y_bottom
      console.log("yy1", ytop, ybottom, this.state.txtyInfo)
      let slide = this.state.slide_data;
      let txtyInfo = this.state.txtyInfo
      let yTopMicronPosn = undefined;
      let yBottomMicronPosn = undefined;
      for (var key in txtyInfo) {
        // check if the property/key is defined in the object itself, not in parent
        if (txtyInfo.hasOwnProperty(key) && yTopMicronPosn == undefined && parseInt(txtyInfo[key].y_index) == parseInt(ytop)) {
          yTopMicronPosn = txtyInfo[key]['pos']['Y']
        }
        if (txtyInfo.hasOwnProperty(key) && yBottomMicronPosn == undefined && parseInt(txtyInfo[key].y_index) == parseInt(ybottom)) {
          yBottomMicronPosn = txtyInfo[key]['pos']['Y']
        }
      }

      if (yTopMicronPosn == undefined) {
        for (var key in txtyInfo) {
          if (txtyInfo.hasOwnProperty(key)){
            yTopMicronPosn = txtyInfo[key]['pos']['Y']
            console.log("__qq3", yTopMicronPosn)
            break;
          }
        }
      }

      if (yBottomMicronPosn == undefined) {
        for (var key in txtyInfo) {
          if (txtyInfo.hasOwnProperty(key)){
            yBottomMicronPosn = txtyInfo[key]['pos']['Y']
            console.log("__qq4", yBottomMicronPosn)
            break;
          }
        }
      }

      yTopMicronPosn = (slide.stitched_tile_height * (slide.stitched_y_max) - yTopMicronPosn) * slide.uperpixel
      yBottomMicronPosn = (slide.stitched_tile_height * (slide.stitched_y_max) - yBottomMicronPosn) * slide.uperpixel
      console.log("posn", yTopMicronPosn, yBottomMicronPosn, slide)
      let u_x1 = -1 * Math.pow(10, 6)
      let u_y1 = -1 * Math.pow(10, 6)
      let b_x1 = Math.pow(10, 6)
      let b_y1 = yBottomMicronPosn
      let grayFeature1 = new Feature({geometry:new Polygon([[[u_x1, u_y1], [b_x1, u_y1], [b_x1, b_y1], [u_x1, b_y1], [u_x1, u_y1]]]), id:1})
      grayFeature1.setId(1)
      let u_x2 = 1 * Math.pow(10, 6)
      let u_y2 = 1 * Math.pow(10, 6)
      let b_x2 = -1 * Math.pow(10, 6)
      let b_y2 = yTopMicronPosn
      let grayFeature2 = new Feature({geometry:new Polygon([[[u_x2, u_y2], [b_x2, u_y2], [b_x2, b_y2], [u_x2, b_y2], [u_x2, u_y2]]]), id:2})
      console.log("vector-", this.vector.getSource().getFeatures())
      grayFeature2.setId(2)
      this.vector.setSource(new VectorSource({
        features: [grayFeature1, grayFeature2],
        wrapX: false
      }))
      if (this.props.overrideScanRegion) {
        this.vector.setStyle(PreviewLineStyle)
        grayFeature1.setStyle(PreviewLineStyle)
        grayFeature2.setStyle(PreviewLineStyle)
      } else {
        this.vector.setStyle(PreviewLineStyle)
        grayFeature1.setStyle(PreviewRegionStyleGray)
        grayFeature2.setStyle(PreviewRegionStyleGray)
      }

      let animationZoom = this.getAnimationZoom(yBottomMicronPosn, yTopMicronPosn);

      let centrePosY = (yBottomMicronPosn + (yTopMicronPosn-yBottomMicronPosn)/2);
      let centerPosX = 11000;
      if (animationZoom>3) centerPosX = 10500;

      console.log("__qq", yBottomMicronPosn, yTopMicronPosn)

      this.state.view.animate({
        center: [centerPosX, centrePosY],
        zoom: animationZoom,
        duration: 0
      })


      this.selectOL = new Select(
       {
        style: function(feature,resolution){
                    return PreviewLineStyle;
              },
        }
      );

      this.translateOL = new Translate({
        features: this.selectOL.getFeatures(),
        style: function(feature,resolution){
          return PreviewLineStyle;
          }
      });

      this.translateOL.on('translateend', evt => {
        evt.features.forEach(feat => {
          if(feat.getId()){
            this.changeYPosn(feat);
          }
          
        })
      })

      this.state.slidemap.getView().setRotation(Math.PI/2)
    }

    if (prevProps.fieldGridData.selected_grid.id != this.props.fieldGridData.selected_grid.id) {
      let selected = this.props.fieldGridData.selected_grid
      this.drawAnnotationsFieldView(this.state.all_on_map_field);
      if (selected) {
        let center = selected.center
        // console.log("center", center)
        this.state.slidemap.getView().animate(
          { rotation: 0 }, { center: [center[0], center[1]] }, { zoom: 9 });
      }

      // let center = JSON.parse(selected.center)
      // console.log("center", center)
      // this.state.slidemap.getView().animate(
      //   {rotation: 0}, {center: [center[0], center[1]]});
    }

    if (prevProps.mode != this.props.mode) {
      this.vector.setSource(new VectorSource({
        features: [],
        wrapX: false
      }))
    }

    if ((prevProps.field_view == false && this.props.field_view == true) || (prevProps.mode != this.props.mode)) {
      this.getAllFieldViewAnnotations();
    }

    if (prevState.mapRenderComplete != this.state.mapRenderComplete) {
      if (this.state.mapRenderComplete) {
        console.log("Map rendered complete")
        if (!this.props.urlState.onStitchedLayer) {
          this.cacheZStackLayers();
        } else {
          this.lastCachingCall = Date.now();
        }
      }
    }

    if (this.props.bloodGridData.selected == true) {
      console.log("bloodgrid", this.props.bloodGridData)
      if (this.props.bloodGridData.move == true) {
        // if(prevProps.bloodGridData.selected_anno.id!= this.props.bloodGridData.selected_anno.id){
        // console.log("props",prevProps.bloodGridData.selected_anno.id, this.props.bloodGridData.selected_anno.id)
        // this.state.slidemap.addLayer(this.vector);
        console.log("xxx")
        let center = JSON.parse(this.props.bloodGridData.selected_anno.center)
        // console.log("anno", this.props.bloodGridData.selected_anno)
        // console.log("centre",(bbox[0]+bbox[2])/2,(bbox[1]+bbox[3])/2)
        // console.log("z",parseInt(this.state.slide_data.z_levels.split(',').pop()))
        this.state.slidemap.getView().animate(
          { rotation: 0 }, { center: [center[0], center[1]] }, { zoom: parseInt(this.state.slide_data.z_levels.split(',').pop()) });
        message.info("Going to selected position", 2)

        // 
        if (this.props.bloodGridData.class == "WBC") {
          let extent = this.state.slidemap.getView().calculateExtent(this.state.slidemap.getSize())//ignoring zoom for now
          let height_offset = Math.abs(extent[3] - extent[1]) / 2
          let width_offset = Math.abs(extent[2] - extent[0]) / 2
          let final_extent = [center[0] - width_offset, center[1] - height_offset, center[0] + width_offset, center[1] + height_offset]
          this.getExtentAllAnnotations(final_extent);
        } else {
          this.drawAnnotations([this.props.bloodGridData.selected_anno]);
        }
        this.props.dispatch(bloodAnnoStopMove());


        // }
      }
      // else{
      //   this.state.slidemap.addLayer(this.vector);
      //   console.log("xxx")
      //   this.drawAnnotations();
      //   this.state.slidemap.getView().animate({zoom: 5}, 
      //     {rotation: 0}, {center: [2000,3000]});
      //     message.info("Going to screenshot position");
      // }
    }

    if (prevProps.view_mode != this.props.view_mode) {
      this.setState({
        loadedMap: false,
        mapRenderComplete: false,
      })
    }

    // if(this.props.expandedView &&  !this.state.load_preview){
    //   console.log("change_view", this.state.load_twice, this.props.expandedView  && !this.state.preview_loaded)
    //   if(parseInt(this.state.load_twice)==1){
    //     console.log("change_view-switch")
    //     this.setState({
    //       load_preview:true
    //     })
    //   }
    //   let x= this.state.load_twice+1
    //   this.setState({
    //     loadedMap: false,
    //     mapRenderComplete: false,
    //     load_twice: x,
    //   })
    //   console.log("change_view-end", this.state.load_twice, this.props.expandedView  && !this.state.preview_loaded)
    // }

    if (prevProps.urlState.zStackLevel != this.props.urlState.zStackLevel) {
      let layers = this.state.slidemap.getLayers().getArray();
      for (let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        layer.setVisible(true);
      }
      for (let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        if (layer.values_.posS == this.props.urlState.zStackLevel || layer.values_.name == mapLayerTypes.ANNOTATION_LAYER_NAME || layer.values_.name == mapLayerTypes.STITCHED_LAYER_NAME || layer.values_.name == mapLayerTypes.BLOOD_ANNOTATION_LAYER_NAME
        ) {
          layer.setVisible(true);
        } else {
          layer.setVisible(false);
        }
      }
    }

    if (prevProps.urlState.apps_initialised !== this.props.urlState.apps_initialised && !this.state.loadedMap
        && !this.state.preview_loaded || this.initMapState) {
      this.initMapState = false;
      if (this.props.mode === "PREVIEW") {
        this.setState({
          preview_loaded: true,
        })
      }
      console.log("initialise", prevProps.urlState.apps_initialised, this.props.urlState.apps_initialised)
      getSlideAndInitialiseMapState(this.props.slide_id, (obj) => {
        obj.addPreviewMapControl = this.addPreviewMapControl;
        obj.goHome = this.goHome;
        this.setState(obj)
      }, this.props.urlState, this.updateUrlState, this.updateLayers, this.onMapRenderingComplete, this.scrollActionOnMap);
    }

    if (!this.state.loadedMap && !this.state.isFetching && !this.state.isErrored) {
      this.state.slidemap.setTarget("map-" + this.props.map_id);
      if (this.previewMapRef != undefined) {
        console.log("Preview Map Was Undefined!!!");
        this.state.slidemap.addControl(this.previewMapRef);
        this.props.dispatch(updateDigitalZoomStatus(this.props.urlState, true))
        this.state.slidemap.getInteractions().forEach(interaction => {
          if (interaction instanceof KeyboardPan) {
            this.state.slidemap.removeInteraction(interaction)
          }
        });
        
      }
      this.setState({
        loadedMap: true
      })

      let zIndex = (this.state.slide_data.x_fields * 100000000) + this.state.slide_data.y_fields + 10000000000;
      this.vector.setZIndex(zIndex);
      this.state.slidemap.addLayer(this.vector);
      this.addSelectInteraction();
      this.props.dispatch(updateMorphleID(this.state.slide_data.morphle_id, this.state.slide_data.id));

      console.log("vector- adding layer to map ", this.vector);

      // if (this.props.urlState.takeZStack) {
      //   document.getElementById("map-" + this.props.map_id).addEventListener('wheel', this.scrollActionOnMap, false);
      // } else {
      //   this.state.slidemap.addInteraction(new MouseWheelZoom());
      // }

      // let self = this;
      // let url = getPrefixedUrl(this.state.slide_data.bucket_name + "/" + this.state.slide_data.path + 'meta/slide_meta.json', this.state.slide_data);
      // axios.get(url)
      //   .then(slideMetaRes => {
      //     let stackimage ={};
      //     if(slideMetaRes.data.takeZStack) {
      //         this.state.slidemap.addEventListener('singleclick', (e) => {
      //             if(self.props.urlState.selectedShape == null) {
      //               let tileCoord = getTileNumber(e.coordinate, self.state.slide_data, self.state.imageLog, self.state.imageShape);
      //               if(tileCoord[0] != -1) {
      //                   stackimage.size = getTiledImageSizeInMicrons(self.state.slide_data);
      //                   stackimage.tileCoord = tileCoord;
      //                   self.setState({
      //                       stackimage,
      //                   });
      //                   self.props.dispatch(updateTileCoordinate(tileCoord));
      //                   let quadrant = getQuadrant(e.coordinate, tileCoord, self.state.slide_data, self.state.imageShape, self.state.imageLog, self.state.view.getZoom(), self.state.maxZoom);
      //                   self.props.dispatch(updateQuadrant(quadrant));

      //                   let bounds = self.getBounds(tileCoord, quadrant);


      //                   let style = new Style({
      //                     stroke: new Stroke({
      //                         color: '#50ace9',
      //                     }),
      //                     fill: new Fill({
      //                         color: 'rgba(0, 0, 0, 0.0)'
      //                     })
      //                   });

      //                   let feature = new Feature({
      //                       geometry: new Polygon(bounds),
      //                   });

      //                   feature.setStyle(style);
      //                   // feature.set('color', 'black');
      //                   self.state.vectorLayer.setSource(new VectorSource({
      //                       features: [feature],
      //                       wrapX: false
      //                   }));

      //                   let time = 100;
      //                   let initialStart = slideMetaRes.data.takeBidirectionalZStack ? -2 : 1;
      //                   let initialEnd = slideMetaRes.data.takeBidirectionalZStack ? 2 : 4;
      //                   let finalStart = slideMetaRes.data.takeBidirectionalZStack ? (-1 * ((slideMetaRes.data.numZLevels - 1) / 2)) : 5;
      //                   let finalEnd = slideMetaRes.data.takeBidirectionalZStack ? ((slideMetaRes.data.numZLevels - 1) / 2) : slideMetaRes.data.numZLevels - 1;
      //                   for(let i = initialStart; i <= initialEnd; i++) {
      //                     let urlSuffix = `${self.state.slide_data.bucket_name}/${self.state.slide_data.path}stack`;
      //                     let url = getPrefixedUrl(urlSuffix, self.state.slide_data);
      //                     url = `${url}/x${self.props.urlState.tileCoord[0]}y${self.props.urlState.tileCoord[1]}/${i}.${self.state.slide_data.img_type}`;
      //                     self.imageDownloadTimer = setTimeout(() => self.downloadImage(url), time);
      //                     time += 100;
      //                   }
      //                   time += 500;
      //                   for(let i = finalStart; i <= finalEnd; i++) {
      //                     if(i < -2 && i > 2) {
      //                       let urlSuffix = `${self.state.slide_data.bucket_name}/${self.state.slide_data.path}stack`;
      //                       let url = getPrefixedUrl(urlSuffix, self.state.slide_data);
      //                       url = `${url}/x${self.props.urlState.tileCoord[0]}y${self.props.urlState.tileCoord[1]}/${i}.${self.state.slide_data.img_type}`;
      //                       self.imageDownloadTimer = setTimeout(() => self.downloadImage(url), time);
      //                       time += 100;
      //                     }
      //                   }
      //               }
      //             }
      //         }, false)
      //     }
      //   })
      //   .catch(err => {
      //     console.log("Failed request", err);
      //   });
      if (this.props.urlState.x === -1) this.goHome();


    }
  }

  downloadImage = (url) => {
    let image = new Image();
    image.src = url;
  }

  componentWillUnmount = () => {
    if(!this.state.slide_data){
      return ;
    }
    let url = `/api/clean_sync_browsing/?sync_code=${((this.props.urlState.app_state || {})['present_app'] || {}).code}&morphle_id=${this.state.slide_data.morphle_id}`;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
      .then(response => {
      })
      .catch(err => {
        console.log("Failed Cleaning");
      });
  }

  getBounds = (tileCoord, quadrant) => {
    let imageName = "x" + tileCoord[0] + "y" + tileCoord[1] + ".jpg";
    let maxZoom = parseInt(this.state.slide_data.z_levels.split(",")[this.state.slide_data.z_levels.split(",").length - 1]);
    let divisions = Math.pow(2, this.props.urlState.z == maxZoom ? 2 : this.props.urlState.z == maxZoom - 1 ? 1 : 0);
    let widthThreshold = (2049 * this.state.slide_data.uperpixel) / divisions;
    let heightThreshold = (2449 * this.state.slide_data.uperpixel) / divisions;
    let xOffset = this.props.urlState.z >= maxZoom - 1 ? quadrant[0] : 0;
    let yOffset = this.props.urlState.z >= maxZoom - 1 ? quadrant[1] : 0;

    if (this.state.imageLog["map"]["stitching_info"]["map"][imageName] != undefined) {
      let imagePos = this.state.imageLog["map"]["stitching_info"]["map"][imageName]["map"]["Absolute Position"]["map"];
      let pointA = [(xOffset * widthThreshold) + (imagePos["X"] * this.state.slide_data.uperpixel), (-1 * yOffset * heightThreshold) + this.state.imageShape[1] - ((imagePos["Y"] * this.state.slide_data.uperpixel) + heightThreshold)];
      let pointB = [(xOffset * widthThreshold) + (imagePos["X"] * this.state.slide_data.uperpixel) + widthThreshold, (-1 * yOffset * heightThreshold) + this.state.imageShape[1] - ((imagePos["Y"] * this.state.slide_data.uperpixel) + heightThreshold)];
      let pointC = [(xOffset * widthThreshold) + (imagePos["X"] * this.state.slide_data.uperpixel) + widthThreshold, (-1 * yOffset * heightThreshold) + this.state.imageShape[1] - (imagePos["Y"] * this.state.slide_data.uperpixel)];
      let pointD = [(xOffset * widthThreshold) + (imagePos["X"] * this.state.slide_data.uperpixel), (-1 * yOffset * heightThreshold) + this.state.imageShape[1] - (imagePos["Y"] * this.state.slide_data.uperpixel)];
      return [[pointA, pointB, pointC, pointD, pointA]];
    } else {
      return [[]];
    }
  }

  openZoomAdjustmentApp = (e) => {
    this.props.dispatch(updateAppClosedStatus(keys.displaySizeCalibrationKey.id, false, this.props.urlState));
    e.stopPropagation();
  }

  stopViewing = (e) => {
    if (this.props.loginAlerts.is_staff || this.props.loginAlerts.superuser) {
      window.location.href = "/" + globalUrlPrefix + "/dashboard/";
    } else {
      window.location.href = "/" + globalUrlPrefix + "/cases/";
    }
  }

  toggleModal = () => {
    let modalVisible = !this.state.modalVisible;
    this.setState({
      modalVisible,
    });
  }

  updateTileCoordinates = (tileCoordinates) => {
    let stackimage = Object.assign({}, this.state.stackimage);
    stackimage.tileCoord = tileCoordinates;
    this.setState({
      stackimage,
    });
  }

  goHome = () => {
    if (this.state.layer != undefined) {
      this.state.slidemap.getView().fit(this.state.extent);
    }
  }

  render = () => {
    // console.log("field_view", this.props.match.params.slot_id)
    // console.log("aa", this.props.bloodGridData)
    console.log("expandedSlide", this.props.expandedView)
    if(this.state.slidemap)
      console.log("slideData", this.state.slidemap.getView())
    // if(this.state.slidemap!=undefined)
    //   console.log("extent", this.state.slidemap.getView().calculateExtent(this.state.slidemap.getSize()))
    console.log("vector-src", this.props.urlState.apps_initialised, this.state.loadedMap)
    let isMObile = checkIfMobile();
    let viewer;
    if (this.props.view_mode == 3) {
      viewer = <Col tabIndex="0" id={"map-" + this.props.map_id} className={"slide-map-container-full"} style={{ backgroundColor: this.state.backgroundColor }} >
        <div id="myposition"></div>
        {(this.props.urlState || {}).presentCode != undefined ? null : <img className="morphle-watermark" id="morphle-watermark" src={MorphleLogo}></img>}
        {(((this.props.urlState || {}).app_state || {})[keys.displaySizeCalibrationKey.id] || {})[this.screenSizeString] == undefined
          && !(cookie.load('morpheus_setting') || {}).is_audience ?
          <div className="adjustment-error">
            <Button type='danger' onClick={this.openZoomAdjustmentApp}>
              <Icon type="exclamation" style={{ color: 'white' }} /> Different screen size detected. Click to adjust the size.
            </Button>
          </div>
          : null
        }
        {this.state.shiftScrollCount <= 10 && !isMObile && this.props.urlState.takeZStack ?
          <div className="main-background-color loading-message-slidemap">
            <b style={{ color: 'white' }}>Use SHIFT + Mouse Scroll Wheel to navigate between Z-Stack Layers</b>
          </div>
          : null
        }
      </Col>
    }
    else if (this.props.view_mode == 4) {
      viewer = <Col tabIndex="0" id={"map-" + this.props.map_id} className={"slide-map-container-preview"} style={{ backgroundColor: this.state.backgroundColor }} >
        <div id="myposition"></div>
        {(this.props.urlState || {}).presentCode != undefined ? null : <img className="morphle-watermark" id="morphle-watermark" src={MorphleLogo}></img>}
        {(((this.props.urlState || {}).app_state || {})[keys.displaySizeCalibrationKey.id] || {})[this.screenSizeString] == undefined
          && !(cookie.load('morpheus_setting') || {}).is_audience ?
          <div className="adjustment-error">
			  {/* <Button type='danger' onClick={this.openZoomAdjustmentApp}>
              <Icon type="exclamation" style={{ color: 'white' }} /> Different screen size detected. Click to adjust the size.
              </Button> */}
          </div>
          : null
        }
        {this.state.shiftScrollCount <= 10 && !isMObile && this.props.urlState.takeZStack ?
          <div className="main-background-color loading-message-slidemap">
            <b style={{ color: 'white' }}>Use SHIFT + Mouse Scroll Wheel to navigate between Z-Stack Layers</b>
          </div>
          : null
        }
      </Col>
    }
    else {
      viewer = <Col tabIndex="0" id={"map-" + this.props.map_id} className={"slide-map-container"} style={{ backgroundColor: this.state.backgroundColor }}>
        <div id="myposition"></div>
        {(this.props.urlState || {}).presentCode != undefined ? null : <img className="morphle-watermark" id="morphle-watermark" src={MorphleLogo}></img>}
        {(((this.props.urlState || {}).app_state || {})[keys.displaySizeCalibrationKey.id] || {})[this.screenSizeString] == undefined
          && !(cookie.load('morpheus_setting') || {}).is_audience ?
          <div className="adjustment-error">
            {/* <Button type='danger' onClick={this.openZoomAdjustmentApp}>
              <Icon type="exclamation" style={{color:'white'}} /> Different screen size detected. Click to adjust the size.
            </Button> */}
          </div>
          : null
        }
        {this.state.shiftScrollCount <= 10 && !isMObile && this.props.urlState.takeZStack ?
          // <div className="main-background-color loading-message-slidemap">
          //   <b style={{ color: 'white' }}>Use SHIFT + Mouse Scroll Wheel to navigate between Z-Stack Layers</b>
          // </div>
          null
          : null
        }
      </Col>
    }

    if (this.state.isErrored) {
      console.log(this.state.errMessage);
    }

    // if(isNaN(this.props.urlState.x) || isNaN(this.props.urlState.y) || isNaN(this.props.urlState.z) || isNaN(this.props.urlState.r)){
    //   this.props.dispatch(updateMapParamsPresent(this.props.urlState,0,0,0,0,this.props.urlState.app_state))
    // }
    return (
      this.state.isFetching || this.state.isErrored ? <div>Loading</div> :
        isNaN(this.props.urlState.x) || isNaN(this.props.urlState.y) || isNaN(this.props.urlState.z) || isNaN(this.props.urlState.r) ?
          getErrorComponent() :
          <div>
            <div className={(this.props.urlState || {}).presentCode != undefined ? "no-pointer-activity" : ""}>
              <Row className="slide-map-row">
                <Col className="slide-map-col">
                  <Row>
                    {(this.props.urlState || {}).presentCode != undefined ?
                      <div>
                        <div className="border-divider border-divider-top"></div>
                        <div className="border-divider border-divider-left"></div>
                        <div className="border-divider border-divider-right"></div>
                        <div className="border-divider border-divider-bottom"></div>
                      </div>
                      : null
                    }
                    {/* <AppBoard slideState={this.state} updateTileCoordinates={this.updateTileCoordinates} viewerType={slideViewerType.NORMAL_SLIDE_VIEWER} /> */}
                    {/* {(this.props.urlState || {}).presentCode != undefined ?
                      <Button className="stop-presentation" type='danger' onClick={this.stopViewing}>
                        Exit Presentation
                      </Button> :
                      null
                    } */}
                    {/* <Col tabIndex="0" id={"map-" + this.props.map_id} className={this.props.view_mode!=3?"slide-map-container":"slide-map-container-full"} style={{backgroundColor: this.state.backgroundColor}} >
                        <div id="myposition"></div>
                        {(this.props.urlState || {}).presentCode != undefined ? null : <img className="morphle-watermark" id="morphle-watermark" src={logo}></img>}
                        {(((this.props.urlState || {}).app_state || {})[keys.displaySizeCalibrationKey.id] || {})[this.screenSizeString] == undefined 
                          && !(cookie.load('morpheus_setting') || {}).is_audience ? 
                          <div className="adjustment-error">
                              <Button type='danger' onClick={this.openZoomAdjustmentApp}>
                                <Icon type="exclamation" style={{color:'white'}} /> Different screen size detected. Click to adjust the size.
                              </Button>
                          </div>
                          : null
                        }
                        {this.state.shiftScrollCount <= 10 && !isMObile && this.props.urlState.takeZStack ?
                          <div className="main-background-color loading-message-slidemap">
                            <b style={{ color: 'white' }}>Use SHIFT + Mouse Scroll Wheel to navigate between Z-Stack Layers</b>
                          </div>
                          : null
                        }
                      </Col> */}
                    {viewer}
                    {/* <Row style={{textAlign:'center'}}>
                        Low Power Scan
                      </Row> */}
                  </Row>

                </Col>
              </Row>
            </div>
          </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    urlState: state.viewerUrlReducer,
    loginAlerts: state.loginAlerts,
    bloodGridData: state.bloodGridReducer,
    fieldGridData: state.fieldViewReducer,
    wbcGridData: state.wbcViewReducer,
  };
};

export default connect(mapStateToProps)(BloodPreviewSlideMap);
