export const bloodAnnoGridActions = {
    // UPDATE_COUNT: "UPDATE_COUNT",
    ADD_ANNOTATION: "ADD_ANNOTATION",
    SAVE_ANNOTATIONS: "SAVE_ANNOTATIONS",
    REMOVE_ANNOTATIONS: "REMOVE_ANNOTATIONS",
    UPDATE_LOADING_STATE: "UPDATE_LOADING_STATE",
    UPDATE_TOTAL_AREA: "UPDATE_TOTAL_AREA",
    UPDATE_COUNT_SAVED: "UPDATE_COUNT_SAVED",
    UPDATE_SLIDE_STATE: "UPDATE_SLIDE_STATE",
    UPDATE_CLUSTER_ID: "UPDATE_CLUSTER_ID",
    GET_WHOLE_CLUSTER: "UPDATE_GET_WHOLE_CLUSTER",
    UPDATE_METASTASIS_AREA: "UPDATE_METASTASIS_AREA",
    UPDATE_FULL_TISSUE_AREA: "UPDATE_FULL_TISSUE_AREA",
}