import React, {Component} from  "react";
import {connect} from 'react-redux';
import { HomeOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import { updateDigitalZoomStatus } from "../../../action/morpheus.state.action"
import { AuthHeader } from "../../../helper/auth.token";
import axios from "axios";
import cookie from 'react-cookies';

import "../../../asset/style/neoviewer/zoom_controls.css"

class ZoomControls extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

        this.twoXZoomLevelUsers = ['delhidermpath', 'nims.highres'];
    }

    componentDidUpdate = (prevProps) => {
        if ((this.props.urlState || {}).presentCode != undefined && prevProps.urlState.zoom_timestamp != this.props.urlState.zoom_timestamp) {
            let url = `/api/get_sync_browsing_zoom/?sync_code=${this.props.urlState.presentCode}&morphle_id=${this.props.urlState.slide_morphle_id}`;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                try {
                    if(response.data.digitalZoomStatus != undefined) {
                        let digitalZoomStatus = response.data.digitalZoomStatus == 'true' ? true : false;
                        let currMaxZoom = this.props.view.getMaxZoom();
                        if(!(prevProps.urlState.zoom_timestamp == -1 && !digitalZoomStatus)) {
                            if (!digitalZoomStatus) {
                                this.props.dispatch(updateDigitalZoomStatus(
                                    this.props.urlState, 
                                    digitalZoomStatus
                                ));
                                this.props.view.setMaxZoom(currMaxZoom - 1);
                                this.props.view.setZoom(this.props.ZValues[this.props.ZValues.length - 2]);
                            } else {
                                this.props.dispatch(updateDigitalZoomStatus(
                                    this.props.urlState, 
                                    digitalZoomStatus
                                ));
                                this.props.view.setMaxZoom(currMaxZoom + 1);
                            }
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            })
            .catch(err => {
                console.log("Failed Getting Value");
            });
        }
    }

    toggleDigitalZoom = () => {
        let currMaxZoom = this.props.view.getMaxZoom();
        let digitalZoomStatus;
        if (this.props.urlState.digitalZoomStatus) {
            this.props.dispatch(updateDigitalZoomStatus(
                this.props.urlState, 
                false
            ));
            if(this.twoXZoomLevelUsers.includes(cookie.loadAll().username)) {
                this.props.view.setMaxZoom(currMaxZoom - 1);
                this.props.view.setZoom(this.props.ZValues[this.props.ZValues.length - 3]);
                digitalZoomStatus = false;
            } else {
                this.props.view.setMaxZoom(currMaxZoom - 1);
                this.props.view.setZoom(this.props.ZValues[this.props.ZValues.length - 2]);
                digitalZoomStatus = false;
            }
        } else {
            this.props.dispatch(updateDigitalZoomStatus(
                this.props.urlState, 
                true
            ));
            this.props.view.setMaxZoom(currMaxZoom + 1);
            digitalZoomStatus = true;
        }
        if(((this.props.urlState.app_state || {})['present_app'] || {}).code != undefined) {
            let url = `/api/set_sync_browsing_zoom/?sync_code=${this.props.urlState.app_state['present_app'].code}&morphle_id=${this.props.urlState.slide_morphle_id}&digitalZoomStatus=${digitalZoomStatus}`;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
            })
            .catch(err => {
                console.log("Failed Setting Key Value");
            });
        }
    }

    incrementZoomLevel = () => {
        let index = Math.floor(this.props.urlState.z);
        if(index != this.props.ZValues) {
            this.props.view.setZoom(this.props.ZValues[index + 1]);
        }
        // this.props.view.setZoom(Math.min(this.props.maxZoom, parseInt(Math.floor(this.props.urlState.z)) + 1));
    }

    decrementZoomLevel = () => {
        let index = Math.floor(this.props.urlState.z);
        if(index != 0) {
            this.props.view.setZoom(this.props.ZValues[index - 1]);
        }
        // this.props.view.setZoom(Math.max(0, parseInt(Math.floor(this.props.urlState.z)) - 1));
    }

    getIntegerZoomLevel = () => {
        let zoomFloor = parseInt(Math.floor(this.props.urlState.z));
        let zoomMantissa = this.props.urlState.z - zoomFloor;
        let zoomCeil = parseInt(Math.ceil(this.props.urlState.z));
        let actualZoomValue = (this.props.zoomScale[zoomFloor] + (zoomMantissa * (this.props.zoomScale[zoomCeil] - this.props.zoomScale[zoomFloor])));
        
        if (Number.isInteger(actualZoomValue)) {
            actualZoomValue = parseInt(actualZoomValue);
        } else {
            actualZoomValue = Math.round(actualZoomValue * 100) / 100;
        }

        return actualZoomValue;
    }

    checkIfDigital = () => {
        let zoomFloor = parseInt(Math.floor(this.props.urlState.z));
        return zoomFloor >= this.props.zoomScale.length - 1;
    }

    render(){
        let actualZoomValue = this.getIntegerZoomLevel();
        let isDigital = this.checkIfDigital();
        
        let showDigitalText = false;

        if(this.twoXZoomLevelUsers.includes(cookie.loadAll().username)) {
            if(this.props.urlState.digitalZoomStatus) {
                showDigitalText = (Math.ceil(this.props.view.getZoom()) == this.props.view.getMaxZoom() && 
                    this.props.urlState.z.toFixed(5) >= this.props.ZValues[Math.floor(this.props.view.getZoom())].toFixed(5)) 
                    || (!this.props.urlState.digitalZoomStatus && (this.props.urlState.z >= this.props.maxZoom) );
            } else {
                showDigitalText = (Math.ceil(this.props.view.getZoom()) == this.props.view.getMaxZoom() - 1 && 
                    this.props.urlState.z.toFixed(5) >= this.props.ZValues[Math.floor(this.props.view.getZoom()) - 1].toFixed(5)) 
                    || (!this.props.urlState.digitalZoomStatus && (this.props.urlState.z >= this.props.maxZoom - 1) );
            }
        } else {
            showDigitalText = (Math.ceil(this.props.view.getZoom()) == this.props.view.getMaxZoom() && 
                    this.props.urlState.z.toFixed(5) >= (this.props.ZValues[Math.floor(this.props.view.getZoom())]||this.props.ZValues[0]).toFixed(5)) 
                    || (!this.props.urlState.digitalZoomStatus && (this.props.urlState.z >= this.props.maxZoom) );
        }
        

        return (
            <div className={this.props.overlayed?"app-parent overlayed-component":""}>
                <Row>
                    <Button 
                        className="zoom-controls-button disable-button-present-mode"
                        onClick={this.props.goHome}
                        disabled={(this.props.urlState || {}).presentCode != undefined}>
                        <HomeOutlined className="zoom-controls-icon" />
                    </Button>
                </Row>
                <Row>
                {showDigitalText ? 
                    <Button 
                        className={(this.props.urlState || {}).presentCode != undefined ? "zoom-controls-button digizoom-button-disabled disable-button-present-mode" : "zoom-controls-button digizoom-button"}
                        onClick={this.toggleDigitalZoom}
                        disabled={(this.props.urlState || {}).presentCode != undefined}
                        >   
                        <span>{this.props.urlState.digitalZoomStatus ? "Disable": "Enable"}<br></br>Digital<br></br>Zoom</span>
                    </Button> : 
                    <Button 
                        className={"zoom-controls-button plus-button disable-button-present-mode"}
                        onClick={this.incrementZoomLevel}
                        disabled={(this.props.urlState || {}).presentCode != undefined}
                        >
                        <PlusOutlined className="zoom-controls-icon" />
                    </Button>
                    }
                </Row>
                <Row>
                    <Col className="zoom-controls-button unselectable zoom-level-button">
                        <Row style={isDigital ? {lineHeight:"1.5em"} : {}}>{actualZoomValue}X</Row>
                        {isDigital ? <Row style={{fontSize:10, lineHeight:"1.5em"}}>Digital</Row> : undefined}
                    </Col>
                </Row>
                <Row>
                    <Button 
                        className="zoom-controls-button minus-button disable-button-present-mode"
                        onClick={this.decrementZoomLevel}
                        disabled={(this.props.urlState || {}).presentCode != undefined}
                        ><MinusOutlined className="zoom-controls-icon" /></Button>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
    }
}

export default connect(mapStateToProps)(ZoomControls);
