import { ScanApiConstant } from '../actionTypes/scan.constant';
import axios from 'axios';
import { AuthHeader } from "../helper/auth.token";
import { setDeviceIdle, setDeviceBusy } from './device_status.action';
import { message } from 'antd'
import { drawCrossHairInitial } from '../component/scanner/slot_component_utils';
import { RegionSelectionConstants } from '../actionTypes/region_selection.constant';
import { stringify } from 'query-string';

export const initPreviewForScan = (slot, defaultConfig) => ({
    type: ScanApiConstant.INIT_PREVIEW,
    slot: slot, 
    defaultConfig: defaultConfig
})

export const fetchingPreview = (slot) => ({
    type: ScanApiConstant.TAKING_PREVIEW,
    slot: slot
})

export const receivedPreviewStatus = (type, response, slot) => ({
    type: type,
    response: response,
    slot: slot
})

export const setDoTilingOnly = (slot) => ({
    type: ScanApiConstant.SET_DO_TILING_ONLY, 
    slot: slot
})

export const setDoTilingAfterScan = (slot) => ({
    type: ScanApiConstant.SET_DO_TILING_AFTER_SCAN, 
    slot: slot
})

export const unsetDoTilingOnly = (slot) => ({
    type: ScanApiConstant.UNSET_DO_TILING_ONLY, 
    slot: slot
})

export const unsetDoTilingAfterScan = (slot) => ({
    type: ScanApiConstant.UNSET_DO_TILING_AFTER_SCAN, 
    slot: slot
})

export const setSpecimenType = (slot, specimenType) => ({    
    type: ScanApiConstant.SPECIMEN_TYPE_CHANGE, 
    specimenType: specimenType, 
    slot: slot
})

export const setObjectiveType = (slot, objectiveType) => ({
    type: ScanApiConstant.OBJECTIVE_TYPE_CHANGE, 
    objectiveType: objectiveType, 
    slot: slot
})

export const setScanOutputFormat = (slot, scanOutputFormat) => ({
    type: ScanApiConstant.SCAN_OUTPUT_FORMAT_CHANGE, 
    scanOutputFormat: scanOutputFormat, 
    slot: slot
})

export const selectionDone = (slot) => ({
    type: ScanApiConstant.REGION_SELECTION_DONE, 
    slot:slot
})

export const selectionCancelled = (slot) => ({
    type: ScanApiConstant.SELECTION_CANCELLED, 
    slot:slot
})

export const setPreviewStatusToConfirmed = (slot, region, actualRegion) => ({
    type: ScanApiConstant.SELECTION_CONFIRMED, 
    slot: slot, 
    region: region, 
    actualRegion: actualRegion
})

export const setScanSpeed = (slot, speed) => ({
    type: ScanApiConstant.SPEED_CHANGE, 
    slot: slot,
    speed: speed
})

export const setScanZLevels = (slot, value) => ({
    type: ScanApiConstant.Z_STACK_LEVELS_CHANGE, 
    slot: slot,
    value: value
})

export const setScanZStepSize = (slot, value) => ({
    type: ScanApiConstant.STEP_SIZE_CHANGE, 
    slot: slot,
    value: value
})

export const setScanSpeedForAllSlots = (speed) => ({
    type: ScanApiConstant.SPEED_CHANGE_FOR_ALL_SLOTS, 
    speed: speed
})

export const setScanSpeedForTray = (tray, speed) => ({
    type: ScanApiConstant.SPEED_CHANGE_FOR_TRAY, 
    tray: tray,
    speed: speed
})

export const setSpecimenTypeForTray = (tray, specimenType) => ({
    type: ScanApiConstant.SPECIMEN_CHANGE_FOR_TRAY, 
    tray: tray,
    specimenType: specimenType,
})

export const setObjectiveTypeForTray = (tray, objectiveType) => ({
    type: ScanApiConstant.OBJECTIVE_CHANGE_FOR_TRAY, 
    tray: tray,
    objectiveType: objectiveType,
})

export const setScanName = (slot, name) => ({
    type: ScanApiConstant.NAME_CHANGE, 
    slot: slot,
    name: name
})

export const setDropDistancePoint = (slot, dropDistancePoint) => ({
    type: ScanApiConstant.UPDATE_DROP_DISTANCE_POINT, 
    slot: slot,
    dropDistancePoint: dropDistancePoint
})

export const setDropDistancePointOnly = (slot, dropDistancePoint) => ({
    type: ScanApiConstant.UPDATE_DROP_DISTANCE_POINT_ONLY, 
    slot: slot,
    dropDistancePoint: dropDistancePoint
})

export const setDropDistancePointLength = (slot, dropDistancePoint) => ({
    type: ScanApiConstant.UPDATE_DROP_DISTANCE_POINT_LENGTH, 
    slot: slot,
    dropDistancePoint: dropDistancePoint
})

export const setFocusPoints = (slot, focusPoints) => ({
    type: ScanApiConstant.UPDATE_FOCUS_POINTS, 
    slot: slot,
    focusPoints: focusPoints
})

export const updatePreviewStatus = (slot, status) => ({
    type: ScanApiConstant.UPDATE_STATUS, 
    slot: slot,
    status: status
})

export const updateActionOngoing = (slot, status, message) => ({
    type: ScanApiConstant.UPDATE_ACTION_ONGOING, 
    slot: slot,
    actionOngoing: status,
    message: message
})

export const updateCanvasSize = (slot, size) => ({
    type: ScanApiConstant.UPDATE_CANVAS_SIZE, 
    slot: slot,
    size: size
})

export const changeSpecimenType = (id, slot, specimenType) =>(dispatch)=>{
    let url = `/server/devices/` + id + "/set_specimen_type?slotID=" + slot + "&specimenType=" + specimenType
    axios.get(url, { headers: { Authorization: AuthHeader() } }).then(response => {
        if (response.status === 200) {
            dispatch(setSpecimenType(slot, specimenType))
        }
    })
    .catch(err => {
        console.log(err);
    })
}

export const changeScanSpeed = (id, slot, scanSpeed) =>(dispatch)=>{
    let url = `/server/devices/` + id + "/set_scan_speed?slotID=" + slot + "&scanSpeed=" + scanSpeed
    axios.get(url, { headers: { Authorization: AuthHeader() } }).then(response => {
        if (response.status === 200) {
            dispatch(setScanSpeed(slot, scanSpeed))
        }
    })
    .catch(err => {
        console.log(err);
    })
}

export const changeSlideName = (id, slot, slideName) =>(dispatch)=>{
    console.log("slidenam2",slideName)
    let url = `/server/devices/` + id + "/set_slide_name?slotID=" + slot + "&slideName=" + slideName
    axios.get(url, { headers: { Authorization: AuthHeader() } }).then(response => {
        if (response.status === 200) {
            dispatch(setScanName(slot, slideName))
        }
    })
    .catch(err => {
        console.log(err);
    })
}
export const takePreviewBlood = (id, slot) => (dispatch) => {
    dispatch(setDeviceBusy(id, "Taking Preview"));
    dispatch(fetchingPreview(slot));
    let url = `/server/devices/` + id + `/take_preview_background?slot_id=${slot}&blood=${true}`;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
                // dispatch(receivedPreviewStatus(ScanApiConstant.TAKEN_PREVIEW, JSON.parse(response.data), slot))
                console.log("set device idle")
                dispatch(setDeviceIdle(id));
            }
            else {
                dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_TAKING_PREVIEW, response, slot))
                dispatch(setDeviceIdle(id));
            }
        })
        .catch(err => {
            dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_TAKING_PREVIEW, err, slot))
            dispatch(setDeviceIdle(id));
            console.log(err)
        })
}

export const takePreview = (id, slot) => (dispatch) => {
    dispatch(setDeviceBusy(id, "Taking Preview"));
    dispatch(fetchingPreview(slot));
    let url = `/server/devices/` + id + "/take_preview_background?slot_id=" + slot;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
                // dispatch(receivedPreviewStatus(ScanApiConstant.TAKEN_PREVIEW, JSON.parse(response.data), slot))
                dispatch(setDeviceIdle(id));
                // dispatch(updatePreviewStatus(slot, RegionSelectionConstants.CONFIRMED))
            }
            else {
                dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_TAKING_PREVIEW, response, slot))
                dispatch(setDeviceIdle(id));
            }
        })
        .catch(err => {
            dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_TAKING_PREVIEW, err, slot))
            dispatch(setDeviceIdle(id));
            console.log(err)
        })
}

export const takePreviewBma = (id, slot) => (dispatch) => {
    dispatch(setDeviceBusy(id, "Taking Preview"));
    dispatch(fetchingPreview(slot));
    let url = `/server/devices/` + id + `/take_preview_background?slot_id=${slot}&bma=${true}`;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
                // dispatch(receivedPreviewStatus(ScanApiConstant.TAKEN_PREVIEW, JSON.parse(response.data), slot))
                dispatch(setDeviceIdle(id));
                // dispatch(updatePreviewStatus(slot, RegionSelectionConstants.CONFIRMED))
            }
            else {
                dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_TAKING_PREVIEW, response, slot))
                dispatch(setDeviceIdle(id));
            }
        })
        .catch(err => {
            dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_TAKING_PREVIEW, err, slot))
            dispatch(setDeviceIdle(id));
            console.log(err)
        })
}

export const takeSelectedPreview = (id, selectedSlots, callback, blood=false) => (dispatch) => {
    dispatch(setDeviceBusy(id, "Taking Preview"));
    selectedSlots.map(slot => {
        dispatch(fetchingPreview(slot));
    })
    let url;
    if (blood) {
        url = `/server/devices/` + id + "/take_all_previews?data=" + selectedSlots+"&blood=true";
    } else {
        url = `/server/devices/` + id + "/take_all_previews?data=" + selectedSlots;
    }
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
                // selectedSlots.map((slot, index) => {
                //     dispatch(receivedPreviewStatus(ScanApiConstant.TAKEN_PREVIEW, JSON.parse(response.data)[index], slot))
                // });
                dispatch(setDeviceIdle(id));
            }
            else {
                selectedSlots.map((slot) => {
                    dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_TAKING_PREVIEW, response, slot))
                });
                dispatch(setDeviceIdle(id));
            }
        })
        .catch(err => {
            selectedSlots.map((slot) => {
                dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_TAKING_PREVIEW, err, slot))
            });
            dispatch(setDeviceIdle(id));
            console.log(err)
        })
}

export const takeAllPreviews = (id, cassetteSize, setShowSummary, selectedSlots) => (dispatch) => {
    dispatch(setDeviceBusy(id, "Taking Preview"));
    for (var i = 0; i < cassetteSize; i++) {
        dispatch(fetchingPreview(i));
    }
    let url = `/server/devices/` + id + "/take_all_previews/?data=" + selectedSlots;
    axios.get(url, { headers: { Authorization: AuthHeader() }})
        .then(response => {
            if (response.status === 200) {
                let previewResponses = JSON.parse(response.data);
                for (var i = 0; i < cassetteSize; i++) {
                    let previewResponse = previewResponses[i];
                    let reg = {
                        data: { index: 0 },
                        height: 100,
                        isChanging: false,
                        new: false,
                        width: 100,
                        x: 0,
                        y: 0,
                    }
                    let x1 = parseInt((reg.x * previewResponse.previewWidth) / 100.0);
                    let x2 = parseInt(((reg.x + reg.width) * previewResponse.previewWidth) / 100.0);
                    let y1 = parseInt((reg.y * previewResponse.previewHeight) / 100.0);
                    let y2 = parseInt(((reg.y + reg.height) * previewResponse.previewHeight) / 100.0);
                    let actualReg = [x1, y1, x2, y2];
                    dispatch(receivedPreviewStatus(ScanApiConstant.TAKEN_PREVIEW, previewResponse, i));
                    dispatch(setPreviewStatusToConfirmed(i, reg, actualReg));
                }
                dispatch(setDeviceIdle(id));
                setShowSummary();
            }
            else {
                for (var i = 0; i < cassetteSize; i++) {
                    dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_TAKING_PREVIEW, response, i));
                }
                dispatch(setDeviceIdle(id));
            }
        })
        .catch(err => {
            for (var i = 0; i < cassetteSize; i++) {
                dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_TAKING_PREVIEW, err, i));
            }
            dispatch(setDeviceIdle(id));
            console.log(err)
        })
}

export const selectionConfirmed = (id, slot, regionInCanvas, actualRegion, image, name, startScanCallback, cassetteSize, specimenType, 
    objectiveType, preview, dropDistanceCanvas,greenBoxRegion) => (dispatch) => {
    dispatch(setDeviceBusy(id));
    let url = '/server/devices/' + id + "/send_threshold_image/?slot_id=" + slot;
    axios({
        method:"post",
        url : url, 
        data: {
            imgBase64: image, 
            specimenType: specimenType,
            objectiveType: objectiveType,
            actualRegion: actualRegion,
            previewData: preview,
            greenBoxRegion: greenBoxRegion,
        }
    })
    .then(response => {
        if (response.status === 200) {
            dispatch(setPreviewStatusToConfirmed(slot, regionInCanvas, actualRegion));
            dispatch(setDropDistancePoint(slot, response.data.dropDistancePoint));
            dispatch(setFocusPoints(slot, response.data.focusPoints));
            dispatch(setDeviceIdle(id));
            // if (cassetteSize === 1) {
            //     startScanCallback();
            // }
        }
        else {
            dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_CONFIRMING_SELECTION, response, slot))
            dispatch(setDeviceIdle(id));
        }
    })
    .catch(err => {
        dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_CONFIRMING_SELECTION, err, slot))
        dispatch(setDeviceIdle(id));
    })
}

export const dropDistanceConfirmed = (id, slot, preview, focusPointsCanvas) => dispatch => {
    dispatch(setDeviceBusy(id));
    let url = '/server/devices/' + id + "/set_drop_distance_point/?slot_id=" + slot;
    axios({
        method:"post",
        url : url, 
        data: {
            points: preview.dropDistancePoint
        }
    })
    .then(response => {
        if (response.status === 200) {
            dispatch(updatePreviewStatus(slot, RegionSelectionConstants.START_FOCUS_POINTS_SELECTION));
            dispatch(setDeviceIdle(id));
        }
        else {
            dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_CONFIRMING_SELECTION, response, slot))
            dispatch(setDeviceIdle(id));
        }
    })
    .catch(err => {
        dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_CONFIRMING_SELECTION, err, slot))
        dispatch(setDeviceIdle(id));
    })
}

export const onDeleteFocusPoint = (id, slot, preview, focusPointsCanvas, islandID, index) => dispatch => {
    dispatch(setDeviceBusy(id));

    let partsOfUrl = "api~scan~delete_focus_point";
    let url = `/server/scano/` + id + `/` + partsOfUrl + "/?slot_id=" + slot + "&island_id=" + islandID + "&index=" + index;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
                //Plot focus points on canvas
                let pointDrawn = false;
                for (let island in response.data.focusPoints) {
                    for (let i = 0; i < response.data.focusPoints[island].length; i++) {
                        let focusUnit = response.data.focusPoints[island][i];
                        if (focusUnit.length > 0) {
                            drawCrossHairInitial(focusUnit[0].x, focusUnit[0].y, focusPointsCanvas,
                                {clearCanvas: !pointDrawn, color: 'green', size: 5, width: 1}, preview);
                            pointDrawn = true;
                        }
                    }
                }
                if (!pointDrawn) {
                    let canvasContext = focusPointsCanvas.getContext("2d");
                    canvasContext.clearRect(0, 0, focusPointsCanvas.width, focusPointsCanvas.height);
                }
                dispatch(setFocusPoints(slot, response.data.focusPoints));
            }
            else {
                dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_CONFIRMING_SELECTION, response, slot))
            }
            dispatch(setDeviceIdle(id));
        })
        .catch(err => {
            dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_CONFIRMING_SELECTION, err, slot))
            dispatch(setDeviceIdle(id));
        })
}

export const onAddFocusPoint = (event, id, slot, preview, focusPointsCanvas) => dispatch => {
    let rect = focusPointsCanvas.getBoundingClientRect();
    let canvasx = rect.x;
    let canvasy = rect.y;
    let mousex = parseInt(((event.pageX - canvasx) / rect.width) * focusPointsCanvas.width);
    let mousey = parseInt(((event.pageY - canvasy) / rect.height) * focusPointsCanvas.height);
    let x = mousex;
    let y = mousey;

    if (x===0 && y===0){
        return;
    }

    x = x-window.scrollX;
    y = y-window.scrollY;

    let realX = Math.trunc(x * (preview.response.previewWidth / focusPointsCanvas.width));
    let realY = Math.trunc(y * (preview.response.previewHeight / focusPointsCanvas.height));

    dispatch(setDeviceBusy(id));

    let partsOfUrl = "api~scan~add_focus_point";
    let url = `/server/scano/` + id + `/` + partsOfUrl + "/?slot_id=" + slot + "&x=" + realX + "&y=" + realY;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
                //Plot focus points on canvas
                let pointDrawn = false;
                for (let island in response.data.focusPoints) {
                    for (let i = 0; i < response.data.focusPoints[island].length; i++) {
                        let focusUnit = response.data.focusPoints[island][i];
                        if (focusUnit.length > 0) {
                            drawCrossHairInitial(focusUnit[0].x, focusUnit[0].y, focusPointsCanvas,
                                {clearCanvas: !pointDrawn, color: 'green', size: 5, width: 1}, preview);
                            pointDrawn = true;
                        }
                    }
                }
                dispatch(setFocusPoints(slot, response.data.focusPoints));
            }
            else {
                dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_CONFIRMING_SELECTION, response, slot))
            }
            dispatch(setDeviceIdle(id));
        })
        .catch(err => {
            dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_CONFIRMING_SELECTION, err, slot))
            dispatch(setDeviceIdle(id));
        })
}

export const startScanning = (id, previewData, bloodConfig, isBlood_) => (dispatch) => {
    console.log("pre-scan-start", previewData);
    dispatch(setDeviceBusy(id, "Initialising"));
    let typeBlood = false;
    if(isBlood_!= undefined && isBlood_) typeBlood = true;
    let url = '/server/devices/' + id + '/start_scan/';
    axios({
        method:"post",
        url : url, 
        data: {
            previewData: previewData,
            bloodConfig: bloodConfig == null ? null: bloodConfig.countWBCSlotWise,
            isBlood: typeBlood
        }
    })
    .then(response => {
        if (response.status === 200) {
            message.success("Started Scans");
            dispatch(setDeviceIdle(id));
        }
        else {
            message.error("Could not start scans. Please contact administrator.")
            dispatch(setDeviceIdle(id));
        }
    })
    .catch(err => {
        message.error("Could not start scans. Please contact administrator.")
        dispatch(setDeviceIdle(id));
    })
}

export const cancelScanning = (id, loaderPresent) => (dispatch) => {
    dispatch(setDeviceBusy(id, "Cancelling"));
    let url = loaderPresent ? '/server/devices/' + id + '/cancel_loader/' : '/server/devices/' + id + '/cancel/';
    axios
    .get(url)
    .then(response => {
        if (response.status === 200) {
            message.success("Cancelled Scanning");
            dispatch(setDeviceIdle(id));
        }
        else {
            message.error("Could not cancel scanning. Please contact administrator.")
            dispatch(setDeviceIdle(id));
        }
    })
    .catch(err => {
        message.error("Could not cancel scanning. Please contact administrator.")
        dispatch(setDeviceIdle(id));
    })
}

export const cancelPreview = (id, loaderPresent) => (dispatch) => {
    dispatch(setDeviceBusy(id, "Cancelling Preview"));
    let url = loaderPresent ? '/server/devices/' + id + '/loader/cancel_preview/' : '/server/devices/' + id + '/cancel_preview/';
    axios
    .get(url)
    .then(response => {
        if (response.status === 200) {
            message.success("Cancelled Preview");
            dispatch(setDeviceIdle(id));
        }
        else {
            message.error("Error. Could not cancel preview. Please contact administrator.")
            dispatch(setDeviceIdle(id));
        }
    })
    .catch(err => {
        message.error("Could not cancel preview. Please contact administrator.")
        dispatch(setDeviceIdle(id));
    })
}

export const getDefaultConfig = (deviceId, slotId) => (dispatch) => {
    dispatch(setDeviceBusy(deviceId, "Setting Up"));
    const url = "/server/devices/" + deviceId + "/settings/get_scanner_properties";
    axios
    .get(url)
    .then(res => {
        dispatch(initPreviewForScan(slotId, res.data.defaultConfig));
        dispatch(setDeviceIdle(slotId));
    })
    .catch(err => {
        console.log(err);   
        dispatch(setDeviceIdle(deviceId));
    });
}
