import '@ant-design/compatible/assets/index.css';
import {Button, Col, Input, message, Popconfirm, Row, Select} from 'antd';
import axios from "axios";
import React, {Component} from "react";
import {connect} from "react-redux";
import '../asset/style/add_case.css';
import {AuthHeader} from "../helper/auth.token";

const { TextArea } = Input;
const { Option } = Select;

const insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index)
]

class EntriesList extends Component{
    render() {
      return (
          <>
            {
              Object.keys(this.props.entriesToSend).map(function(key) {
		      console.log(key)
                return <Row gutter={[16, 24]}><Col>
			      <Popconfirm
    title="Are you sure to delete this task?"
    onConfirm={this.props.deleteEntry}
    okText="Yes"
    cancelText="No"
  >
			      <Button key = {key} value={key}>{this.props.entriesToSend[key][2]}</Button>
			      </Popconfirm>
			      </Col>
			      </Row>
              }.bind(this))
            }
          </>
       );
     }
   }


class AddTiledView extends Component {
    constructor(props) {
        super(props);

        let morphle_id = '';

        this.state = {
            morphle_id: morphle_id,
            bucket_name: 'morphle-analytics',
            path: '',
            entriesToSend: []
        }
    }

    componentDidMount = () => {
    }

    componentDidUpdate = (prevProps) => {
    }

    changeMorphleId = (event) => {
        this.setState({
            morphle_id: event.target.value
        });
    }

	deleteEntry = (e) => {
		console.log(this.state.entriesToSend);
		var array = [...this.state.entriesToSend];
		array.splice(parseInt(e.target.value),1);
		this.setState({
			entriesToSend: array
		});
	}

    changePath = (event) => {
        this.setState({
            path: event.target.value
        });
    }

    changeBucketName = (event) => {
        this.setState({
            bucket_name: event.target.value
        });
    }

    addSlideEntry = (e) => {
        let entry = [this.state.bucket_name, this.state.path, this.state.morphle_id];
        this.state.entriesToSend.push(entry);
        this.setState({
            bucket_name: 'morphle-analytics',
            morphle_id: '',
            path: ''
        });
        console.log(this.state.entriesToSend)
    }

    createCase = (e) => {
        let url = '/start_tiled/?entries_to_send='+this.state.entriesToSend;
        console.log(url)
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(result => {
                this.setState({
                    morphle_id: '',
                    path: '',
                    entriesToSend: []
                })
            })
            .catch(err => {
                if(err.response.status == 406) {
                    message.error(err.response.data, 2.5);
                } else {
                    message.error("Failed to Create Tiled Scan. Contact Admin");
                }
                console.log(err);
            });
        
    }

    getEntries = () => {
        console.log(this.state.entriesToSend.length>0)
        return Object.keys(this.state.entriesToSend).map((key) => {
          let value = this.state.entriesToSend[key];
          return <Button value={value.morphle_id}>{value.morphle_id}</Button>
        });
    }

    render() {

        return (
            <div>
                <Row>
                    <Col span={24} offset={0} className="all-slides">
                        <div className="heading-style main-heading-style">
                            <b>Create Tiled Scan</b>
                        </div>
                        <div>
                            <Row>
                                <Col offset={2} pull={1}>
                                    <div>
                                        <Row className="add-case-row">
                                            <Col span={5} className="add-case-row-header">
                                                <b>Morphle ID</b>
                                            </Col>
                                            <Col span={8}>
                                                <Input placeholder="1_2022-02-24_01-11-56-0000454_Beta154_S1T0R0" value={this.state.morphle_id}
                                                    className={this.state.id_check_flag ? "input-field-add-case errors-input" : "input-field-add-case"}
                                                    onChange={this.changeMorphleId}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="add-case-row">
                                            <Col span={5} className="add-case-row-header">
                                                <b>Zip File Path</b>
                                            </Col>
                                            <Col span={8}>
                                                <Input placeholder="anand-diagnostics/c-neo_1_2022-02-24_01-11-56-0000454_Beta154_S1T0R0.zip" value={this.state.path} className="input-field-add-case" onChange={this.changePath} />
                                            </Col>
                                        </Row>
                                        <Row className="add-case-row">
                                            <Col span={5} className="add-case-row-header">
                                                <b>Bucket Name</b>
                                            </Col>
                                            <Col span={8}>
                                                <Input placeholder="morphle-analytics" value={this.state.bucket_name} className="input-field-add-case" onChange={this.changeBucketName} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <br />
                        </div>

                        <div className="submit-row-add-case">
                            <Button type="primary"
                                onClick={this.addSlideEntry}
                                disabled={!(this.state.morphle_id.length > 0 && this.state.path.length > 0 && this.state.bucket_name.length > 0)}>
                                Add Entry
                            </Button>
                        </div>
                        <Col className="add-case-row">
                            <EntriesList entriesToSend={this.state.entriesToSend} deleteEntry={this.deleteEntry}></EntriesList>
                        </Col>
                        <div className="submit-row-add-case">
                            <Button type="primary"
                                onClick={this.createCase}
                                disabled={!(this.state.entriesToSend.length > 0)}>
                                Start Upload
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        morphle_id: state.morphle_id,
        path: state.path,
        bucket_name: state.bucket_name
    }
}

export default connect(mapStateToProps)(AddTiledView);
