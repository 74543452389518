import React, {Component} from "react";
import {IconButton, Stack, Tooltip} from "@mui/material";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import {morphleBaseTheme1} from "../themes";
import {addScreenshotToReport} from "../utils/screenshot_utils";
import {connect} from "react-redux";
import axios from "axios";
import {AuthHeader} from "../../../helper/auth.token";
import {componentIds} from "../../../utils/const";
import {initSlideData} from "../../../action/maps.state.action";

class CaseImage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			currCase: {},
		}
	}

    initData = () => {
        this.activeMapId = this.props.activeMapId;
        this.mapState = this.props.mapsState[this.activeMapId];
        this.slideState =this.mapState.slideState;
        this.viewerSettings = this.slideState.slide_data.viewer_settings;
    }

    getCurrCase = () => {
        if (this.slideState.slide_data.case !== null){

            let url = '/api/case/' + this.slideState.slide_data.case + `/`;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    if (response.data.id) {
                        this.setState({
                            currCase : response.data,
                        })
                    }
                })
                .catch(err => {
                    console.log("Failed request", err);
                });
        }
    }

    componentDidMount() {
        this.initData();
        this.getCurrCase();
    }

	cb = () => this.props.dispatch(initSlideData(this.activeMapId, this.mapState.slideId));

    render() {
        this.initData();
        if (!this.slideState) return <div />;

        let componentDisabled = (this.slideState.slide_data||{}).case === null
        let loc = {x: this.mapState.x, y: this.mapState.y, z : this.mapState.z, r: this.mapState.r}

        return <Stack direction={"row"} spacing={1} justifyContent="flex-start" sx={{alignItems: 'center'}}>
            <Tooltip title={componentDisabled ? "Slide has no Case Assigned" : "Add to Case"} placement={"bottom"}>
                <IconButton size="small" disabled={componentDisabled}
							onClick={() => addScreenshotToReport(componentIds.slideMaps, this.slideState.slide_data,
								this.props.screenshotState.extension, this.state.currCase, this.viewerSettings, loc, this.cb)}>
                    <AddPhotoAlternateIcon sx={{color: morphleBaseTheme1.palette.headBarIcon.primary}} />
                </IconButton>
            </Tooltip>
        </Stack>
    }
}


const mapStateToProps = (state) => {
    return {
        activeMapId: state.gammaStateReducer.activeMapId,
        mapsState: state.mapsStateReducer,
        screenshotState: state.screenshotStateReducer,
    }
}
export default connect(mapStateToProps)(CaseImage)
