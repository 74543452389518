import React, { Component } from 'react';
import { connect } from "react-redux";
import SlideMap from '../component/neoviewer/slidemap'
import queryString from 'query-string'
import axios from 'axios';
import {Link} from 'react-router-dom';
import cookie from "react-cookies";
import { AuthHeader } from '../helper/auth.token';
import { closeDrawer } from "../action/dashboard.action";
import { makeSlideRead } from '../action/slides.action';
import { Spin, Statistic, Progress, Row, PageHeader, Divider, Col , Table,Button, Dropdown, Menu, message, Pagination, Popover, Checkbox, Empty} from 'antd';
import { globalUrlPrefix } from '../utils/const';
import { logout } from '../action/login.action';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as enhancementKeys from '../component/neoviewer/apps/settings/enhancement_keys' 
import { fieldChangeImage, fieldGridSelected, updatePageFieldView, updatePageSize, updateAllGrids, resetZStack , updateZStackWrapper} from '../action/fieldView.action';
import { wbcChangeImage, wbcGridSelected, updatePageSizeWBC, updatePageWBCView, resetZStackWBC , updateZStackWrapperWBC, updateWBCSummary} from '../action/wbcview.action';
import {rbcChangeImage, rbcGridSelected, updatePageSizeRBC, updatePageRBCView, updateAllGridsRBC, updateTotalCountRBC, updateTotalPagesRBC, updateNextPageSelected, updateCurrentGrids, updateSelectedGridOverallId, updateFetchingGridRBC} from '../action/bloodGridRBC.action'
import {updatePageSizePLT, updateTotalPagesPLT, updateTotalCountPLT, updateCurrGridsPLT, pltGridSelected, updateSelectedGridOverallIdPLT, updatePagePLT, updateFetchingGridPLT, pltChangeImage} from '../action/bloodGridPLT.action'

import {
    EditOutlined,
    InfoCircleOutlined,
    RightOutlined,
    LeftOutlined,
    EditTwoTone,
    LoadingOutlined
} from '@ant-design/icons';
import '../asset/style/workflow/tab-pane.css'
import { floor } from 'lodash';
import { updatePage } from '../action/bloodGrid.action';
import {bloodClass} from '../utils/const'

import querystring from "query-string";

export class FieldGridViewPlt extends Component {

    constructor(props) {
        super(props);

        this.state = {
            grid_data : [],
            tag_list: [],
            page: 1,
            total_pages:'',
            slide_path:'',
            total_count:'',
            first_time: false,
            tag_list: [],
            hover_grid_index: -1,
            dimensionChanged:false,
            imgdim: 0,
            listannos:[],
            spinning: false,
            first_time_redis: true,
            only_one_call: true,
        }
    }
        
    componentDidMount = () => {
        window.addEventListener('resize', this.updateDimensions);
        // document.addEventListener('keydown', this.pressShiftLeft)
        // document.addEventListener('keydown', this.pressShiftRight)
        this.setGridSize()
    }

    updateDimensions = () => {
        this.setState({ dimensionChanged: true });
    };


    firstSelectPLT = () => {
        this.props.dispatch(pltChangeImage(true));
        this.props.dispatch(pltGridSelected(this.state.grid_data[0]));
        this.props.dispatch(updateSelectedGridOverallIdPLT((this.state.grid_data[0]||{}).overall_count))
        this.props.dispatch(updatePagePLT(this.state.page))
        this.props.dispatch(updateFetchingGridPLT(false));
    }

    // firstSelect = () => {
    //     // this.props.dispatch(resetZStack());
    //     this.props.dispatch(fieldChangeImage(true));
    //     this.props.dispatch(fieldGridSelected(this.state.grid_data[0]));
    //     this.props.dispatch(updateZStackWrapper(this.state.grid_data[0], this.props.fieldGridData.zStack))
    //     this.props.dispatch(updatePage(this.state.page))
    // }

    // firstSelectWBC = () => {
    //     if(this.state.grid_data.length==0){
    //         return;
    //     }
    //     this.props.dispatch(wbcChangeImage(true));
    //     this.props.dispatch(wbcGridSelected(this.state.grid_data[0]));
    //     this.props.dispatch(updateZStackWrapperWBC(this.state.grid_data[0], this.props.fieldGridData.zStack))
    //     this.props.dispatch(updatePageWBCView(this.state.page))
    // }

    // firstSelectRBC = () => {
    //     this.props.dispatch(rbcChangeImage(true));
    //     this.props.dispatch(rbcGridSelected(this.state.grid_data[0]));
    //     this.props.dispatch(updateSelectedGridOverallId((this.state.grid_data[0]||{}).overall_count))
    //     this.props.dispatch(updatePageRBCView(this.state.page))
    //     this.props.dispatch(updateFetchingGridRBC(false));
    // }

    // firstSelectPLT = () => {
    //     this.props.dispatch(pltChangeImage(true));
    //     this.props.dispatch(pltGridSelected(this.state.grid_data[0]));
    //     this.props.dispatch(updateSelectedGridOverallIdPLT((this.state.grid_data[0]||{}).overall_count))
    //     this.props.dispatch(updatePagePLT(this.state.page))
    //     this.props.dispatch(updateFetchingGridPLT(false));
    // }

    getGridData = (page) => {
        if(this.props.mode == bloodClass.PLT){
            this.props.dispatch(updateFetchingGridPLT(true))
            let page_size = this.props.view_mode!=1?28:this.pageSize
            let val = {
                slide_id: this.props.slide_id,
                page:page,
                page_size: page_size,
                size_filter: ((this.props.pltGridData.size_range_low==-1 && this.props.pltGridData.size_range_high==-1)||(this.props.pltGridData.size_range_low==this.props.pltGridData.extreme_size_range_low && this.props.pltGridData.size_range_high==this.props.pltGridData.extreme_size_range_high))?false:true,
                size_range_low: this.props.pltGridData.size_range_low,
                size_range_high: this.props.pltGridData.size_range_high,
                filter:this.props.filter
            }
            let url = `/api/retrieve_grid_view_plt/`
            axios.post(url,queryString.stringify(val),{ headers: { Authorization: AuthHeader() }})
                .then(res => {
                    this.setState({
                        grid_data: res.data['data'],
                        total_pages: res.data['total_pages'],
                        total_count:res.data['count']
                    })
                    // this.props.dispatch(updateAllGridsRBC(res.data['data']))
                    this.props.dispatch(updateTotalPagesPLT(res.data['total_pages']))
                    this.props.dispatch(updateTotalCountPLT(res.data['count']))
                    this.props.dispatch(updateCurrGridsPLT(res.data['data']))

                    let curr_grids = res.data['data']
                    let selected_grid_id = this.props.pltGridData.selected_grid_overall_id
                    for(let i=0; i<curr_grids.length && selected_grid_id!=''; i++){
                        if(curr_grids[i].overall_count == selected_grid_id){
                            this.props.dispatch(pltGridSelected(curr_grids[i]));
                        }
                    }
                    if((res.data['data']||{}).length == 0){
                        
                            // this.props.dispatch(rbcGridSelected(''));
                            this.props.dispatch(updateSelectedGridOverallIdPLT(""))
                            this.setState({
                                first_time:true,
                                // page: 1,
                            })
                        
                    }
                    if(res.data['data'].length>0 && !this.state.first_time){
                        setTimeout(this.firstSelectPLT, 500)
                        this.props.dispatch(updateFetchingGridPLT(false));
                        this.setState({
                            first_time:true,
                            // page: 1,
                        })
                    }
                    else{
                        this.props.dispatch(updateFetchingGridPLT(false));
                    }
                })
        }
    }

    handleMenuClick = (e, annotation) => {
        let new_tag = this.state.tag_list[e.key]
        let oot_id = annotation.oot_id
        let value = {
            "new_tag" : new_tag,
            "oot_id" : oot_id,
        }
        let url = '/api/updateOOT/'
        axios.post(url, querystring.stringify(value), { headers: { Authorization: AuthHeader() } })
        .then(response => {
        if (response.status === 200 || response.status === 301 || response.status === 302) {
            this.getGridData(this.state.page);
            this.props.dispatch(updateWBCSummary(true));
            message.success("Tag updated successfully")
            if(annotation.id == (this.props.selected||{}).id){
                if(this.props.selected.count >=this.props.selected.total_count){
                    this.props.loadPrev(0);    
                } else {
                    this.props.loadNext(0);
                }
                // this.props.selected.count
            }
            }
        else if (response.status === 403 || response.status === 401 || response.status === 400) {
                        // dispatch(caseListUpdateFailed(id, response));
                message.info("Failed to update Tag. Contact admin.")
            }
        })
        .catch(err => {
        });
    }

    componentDidUpdate = (prevProps) => {

        if(this.state.dimensionChanged){
            this.setGridSize()
            this.setState({dimensionChanged:false})
        }
        if(((document.getElementById('fieldimg_0')||{}).offsetWidth)!=undefined && (this.state.imgdim==undefined))
            this.setState({imgdim:(document.getElementById('fieldimg_0')||{}).offsetWidth})

        if(prevProps.view_mode != this.props.view_mode){
            console.log("c-1")
            this.getGridData(this.state.page);
        }
        if(prevProps.page != this.props.page){
            console.log("c-2")
            this.onChangePage(this.props.page)
        }
        if(this.state.only_one_call && 
            this.props.pltGridData.size_range_low != -1 &&
            this.props.pltGridData.size_range_high!= -1 &&
            this.props.pltGridData.extreme_size_range_low!= -1 &&
            this.props.pltGridData.extreme_size_range_high!= -1){
                console.log("ccc--", this.state.only_one_call)
                this.loadRedis(this.pageSize)
                this.setState({
                    only_one_call: false,
                })
        }
        if(this.state.first_time && ((prevProps.pltGridData||{}).size_range_low!=(this.props.pltGridData||{}).size_range_low ||
                                    (prevProps.pltGridData||{}).size_range_high!=(this.props.pltGridData||{}).size_range_high||
                                    (prevProps.pltGridData||{}).extreme_size_range_low!=(this.props.pltGridData||{}).extreme_size_range_low||
                                    (prevProps.pltGridData||{}).extreme_size_range_high!=(this.props.pltGridData||{}).extreme_size_range_high)){
                                        console.log("c-5",(this.props.pltGridData||{}).size_range_low)
                                        this.props.dispatch(updatePagePLT(1))
                                        this.getGridData(1);
                                        this.loadRedis(this.pageSize);
                                        this.setState({
                                            page:1,
                                            first_time: false,
                                        })
                                    }
    }

    setGridSize = () => {
        let picker = Math.max(((document.documentElement.clientHeight)*12/100),((document.documentElement.clientWidth)*6/100))
        let noOfGrids = Math.floor(document.documentElement.clientHeight/picker)-1
        console.log("sz",(document.documentElement.clientHeight)*12/100, (document.documentElement.clientWidth)*6/100)
        if(this.props.mode == bloodClass.PLT){
            // this.pageSize = Math.floor(document.documentElement.clientHeight/130)*((document.documentElement.clientWidth<1300)?7:8);
            this.pageSize = noOfGrids*8;
            this.props.dispatch(updatePageSizePLT(this.pageSize))
        }
        this.getGridData(this.state.page);
        this.setState({imgdim:(document.getElementById('fieldimg_0')||{}).offsetWidth})
        this.loadRedis(this.pageSize);
    }

    loadRedis = (pageSize) => {
        console.log("c-42",this.props.pltGridData)
        if(this.props.mode==bloodClass.PLT){
            let low=this.props.pltGridData.size_range_low;
            let high=this.props.pltGridData.size_range_high;
            if(this.props.pltGridData.size_range_low == -1 && this.props.pltGridData.size_range_high == -1){
                low = this.props.pltGridData.extreme_size_range_low;
                high = this.props.pltGridData.extreme_size_range_high;
            }
            let val = {
                slide_id: this.props.slide_id,
                page_size: pageSize,
                size_filter: ((this.props.pltGridData.size_range_low==-1 && this.props.pltGridData.size_range_high==-1) || (this.props.pltGridData.size_range_low==this.props.pltGridData.extreme_size_range_low && this.props.pltGridData.size_range_high==this.props.pltGridData.extreme_size_range_high))?false:true,
                size_range_low: low,
                size_range_high: high,   
                first_time: this.state.first_time_redis,
                filter:this.props.filter
            }
            console.log("redis data", this.props.pltGridData.extreme_size_range_low, high)
            let url = `/api/load_redis_plt/`
            axios.post(url, queryString.stringify(val), { headers: { Authorization: AuthHeader() }})
                .then(res => {
                    this.setState({
                        first_time_redis:false
                    })
            })
        }
    }

    onChangePage = (p) => {
        console.log("page-a",p)
        if(this.props.mode==bloodClass.FIELD){
            this.props.dispatch(updatePageFieldView(p));
        }
        else if(this.props.mode==bloodClass.WBC){
            this.props.dispatch(updatePageWBCView(p));
        }
        else if(this.props.mode==bloodClass.RBC){
            this.props.dispatch(updatePageRBCView(p));
        }
        else if(this.props.mode == bloodClass.PLT){
            this.props.dispatch(updatePagePLT(p));
        }
        this.setState({
            page: p,
        })
        this.getGridData(p);
    }


    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateDimensions);
    }

    convertSettingsToFilter = (settings) => {
        let cssStyle = ""
      
        for (var key in enhancementKeys) {
          let enhancementKey = enhancementKeys[key];
          cssStyle += enhancementKey.cssKey + "(" + (settings[enhancementKey.id] * enhancementKey.multiplier / enhancementKey.max) + enhancementKey.unit + ") ";
        }
      
        return cssStyle;
      }
 
    
    addHover = (idx) => {
        console.log("mouse_change", idx)
        this.setState({
            hover_grid_index: idx,
        })
    }


    getAnnotationsInGridView = (currentAnnotations) => {
    
        let annotationsGridParent, selectedAnnotationDrawer;
        
        let imgsPerCol = this.props.view_mode==1?(this.props.mode==bloodClass.RBC||this.props.mode==bloodClass.PLT)?8:3:4;
        let colSpan = parseInt(24 / imgsPerCol);
        let currentImgCount = 0;
        let annotationRow = [];
        let annotationsGrid = [];
    
        let tags = this.state.tag_list;
        let menu_items = tags.map((tag, index)=> {
            return <Menu.Item key={index}>{tag}</Menu.Item>
        })
        
        if(currentAnnotations.length == 0){
            annotationsGridParent = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={this.props.mode==bloodClass.WBC?{marginTop: '40vh', width:'17.1vw'}:( this.props.mode==bloodClass.RBC || this.props.mode==bloodClass.PLT )?{marginTop: '40vh',width:'58vw', height:'46vh'}:{width:'11.5vw'}}/>
            
            return annotationsGridParent;
        }


        for (let index = 0; index < currentAnnotations.length; index+=1){
            let annotation = currentAnnotations[index];
            let annotationDiv;

            const menu = (
                <Menu onClick={(e)=>this.handleMenuClick(e,currentAnnotations[index])}>
                    {menu_items}
                </Menu>
            )

            let content;
            if(this.props.mode == bloodClass.WBC){
                content = (
                    <div>
                        <span>{annotation.class.toUpperCase()}</span>
                    </div>
                )
            }
            let checkbox_tag;
            if(this.props.mode == bloodClass.WBC  && this.props.wbcGridData.tag!=''){
                let selected = currentAnnotations[index].oot_id
                checkbox_tag = <Checkbox className="custom" checked={this.state.listannos.indexOf(selected)!=-1} key={selected} style={{position:"absolute", backgroundColor:"rgba(0,0,0,0)", border:'0px', borderColor:'rgba(0,0,0,0)', marginTop:'0.5vh', marginLeft:'0.7vw'}} onChange={(e)=>this.selectMultiple(e,currentAnnotations[index])}></Checkbox>
            }else{
                checkbox_tag = null;
            }

            let content_popover=null;
            if(this.props.mode == bloodClass.WBC && this.state.hover_grid_index==index){
                content_popover = <Popover placement="top" content={content} style={{backgroundColor:"#03112c", border:'0px', borderColor:'rgba(0,0,0,0)'}} trigger="click">
                                        <Button ghost={true} style={{border:'0px',position:'absolute', marginLeft:'3.4vw',marginTop:'0.5vh', backgroundColor:'rgba(0,0,0,0)' }}>
                                            <InfoCircleOutlined style={{color:'black'}}/>
                                        </Button>
                                </Popover>
            }
            else{
                content_popover = null;
            }

            let edit_tag;
            if(this.props.mode == bloodClass.WBC  && this.props.wbcGridData.tag!='' && this.state.hover_grid_index==index){
                edit_tag = <Dropdown overlay={menu} trigger="click" style={{backgroundColor:'rgba(0,0,0,0)'}}>
            <Button style={{ backgroundColor:'rgba(255,255,255,0.5)', position:'absolute', border:'0px solid black', marginTop:(this.state.imgdim-25), marginLeft:(this.state.imgdim-(2*(this.state.imgdim+3))),width:this.state.imgdim-2, fontWeight:"bold"}}>
                    <span style={{textAlign:"center", width: '3.25vw'}}>EDIT<EditOutlined style={{color: "black", }}/></span> </Button>
                            </Dropdown>
            }else{
                edit_tag = null;
            }
            // let customHeight = (12)*(document.documentElement.clientHeight/100)
            // let customWidth = (2.5)*(document.documentElement.clientWidth/100)
            annotationDiv =
                    <div onMouseEnter={()=> {this.addHover(index)}} onMouseLeave={()=> {this.addHover(-1)}}> 
                        <Col key={currentImgCount} span={colSpan}>   
                            <Row style={this.props.mode==bloodClass.FIELD?{minHeight: '5vw', height:'10vh'}:{minHeight: '6vw', height: '12vh'}}>
                                {this.props.differential?checkbox_tag:null}
                                {this.props.differential?content_popover:null}
                                <img
                                    id={`fieldimg_${index}`}
                                    className={`annotation-crop imageDim`}
                                    style = {annotation.id == (this.props.selected||{}).id?{borderColor:'#7CFC00', borderWidth:'4px', width:'5.2vw', filter: `brightness(${(this.props.cssfilter||{}).brightness}%) contrast(${(this.props.cssfilter||{}).contrast}%)`}:{width:'5.2vw', filter: `brightness(${(this.props.cssfilter||{}).brightness}%) contrast(${(this.props.cssfilter||{}).contrast}%)`}}
                                    src={annotation.thumbnail} 
                                    onClick={()=>{console.log("click");this.onGridClicked(annotation);}}
                                    onLoad = {()=> {document.getElementById('fieldimg_'+index).className = 'annotation-crop';}}
                                    onError = {()=> {document.getElementById('fieldimg_'+index).className = 'annotation-crop';}}
                                />
                                {this.props.differential?edit_tag:null}
                                {/* {edit_tag} */}
                            </Row>
                        </Col>
                    </div>
    
            if (currentImgCount == imgsPerCol) {
                annotationsGrid.push(<Row key={annotationsGrid.length}>{annotationRow}</Row>);
                annotationRow = [];
                currentImgCount = 0;
            }
    
            annotationRow.push(annotationDiv);
            currentImgCount ++;
        }
    
        // if(annotationRow.length == 0 || !anyPolygonShapes) {
        //     annotationRow.push(<div style={{padding: 20}}>
        //         <br/>
        //         <br/>
        //         <div>No annotations available</div>
        //         <br/>
        //         <br/>
        //     </div>);
        // }
    
        annotationsGrid.push(<Row key={annotationsGrid.length}>{annotationRow}</Row>);
        // console.log("annoGrid", currentAnnotations.length)
        annotationsGridParent = 
            <Row>
                {annotationsGrid}
            </Row>
    
        return annotationsGridParent;
    }

    onGridClicked = (annotation) => {
        if(this.props.view_mode != 1 && this.props.view_mode != 2){
            return;
        }
        console.log("grid clicked", annotation)
        if(this.props.mode==bloodClass.FIELD){
            // this.props.dispatch(resetZStack());
            this.props.dispatch(fieldChangeImage(true));
            this.props.dispatch(fieldGridSelected(annotation))
            this.props.dispatch(updateZStackWrapper(annotation, this.props.fieldGridData.zStack))
        }else if(this.props.mode==bloodClass.WBC){
            // this.props.dispatch(resetZStackWBC());
            this.props.dispatch(wbcChangeImage(true));
            this.props.dispatch(wbcGridSelected(annotation))
            this.props.dispatch(updateZStackWrapperWBC(annotation, this.props.fieldGridData.zStack))
        }else if(this.props.mode == bloodClass.PLT){
            this.props.dispatch(pltChangeImage(true));
            this.props.dispatch(pltGridSelected(annotation))
            this.props.dispatch(updateSelectedGridOverallIdPLT(annotation.overall_count))
        }
        else if(this.props.mode==bloodClass.RBC){
            this.props.dispatch(rbcChangeImage(true));
            this.props.dispatch(rbcGridSelected(annotation))
            this.props.dispatch(updateSelectedGridOverallId(annotation.overall_count))
        }
    }

    decreasepage = () => {
        let curr = this.state.page;
        let all_images = document.getElementsByTagName("img");
            for(let i=0; i< all_images.length;i++){
                console.log("testing222", all_images[i].className)
                if(all_images[i].className == 'annotation-crop imageBright' || all_images[i].className == 'annotation-crop'){
                    all_images[i].className = 'annotation-crop imageDim';
                }    
            }
        if(curr==1){
            return;
        }
        this.onChangePage(curr-1);
    }

    increasepage = () => {
        let curr = this.state.page;
        let all_images = document.getElementsByTagName("img");
            for(let i=0; i< all_images.length;i++){
                console.log("testing222", all_images[i].className)
                if(all_images[i].className == 'annotation-crop imageBright' || all_images[i].className == 'annotation-crop'){
                    all_images[i].className = 'annotation-crop imageDim';
                }    
            }
        if(curr == this.state.total_pages){
            return;
        }
        this.onChangePage(curr+1);
    }

    render() {
        let annotationsGridParent = this.getAnnotationsInGridView(this.state.grid_data)

        let selectCounter=null;
        if((this.props.mode == bloodClass.RBC || this.props.mode == bloodClass.PLT) && this.props.selected){
            selectCounter = <div style={{position:'fixed', bottom:'1.4vh', marginLeft:'52vw', textAlign:'center', fontSize:'2vh', color:'white'}}>
                                    #{(this.props.selected||{}).overall_count}
                                </div>
        }

        let gridNumber = null;
        if(!(this.props.differential)){
            gridNumber = <React.Fragment>
                <Button  style={{textAlign:'center', backgroundColor:'#03112c' ,borderColor:'rgba(0,0,0,0)', borderWidth:'0px'}} onClick={this.decreasepage} disabled={this.state.page==1}><LeftOutlined style={{color:'cyan',fontSize:'1vw'}}/></Button>
                        Showing {((this.state.page-1)*this.pageSize)+1}-{Math.min((this.state.page*this.pageSize), this.state.total_count)} of {this.state.total_count}
                        <Button  style={{textAlign:'center', backgroundColor:'#03112c' ,borderColor:'rgba(0,0,0,0)', borderWidth:'0px'}} onClick={this.increasepage}><RightOutlined style={{color:'cyan',fontSize:'1vw'}}/></Button>
            </React.Fragment>

        } else {
            if(this.state.total_count>0){
                gridNumber= <React.Fragment>
                <Button  style={this.props.mode==bloodClass.FIELD?{position:'fixed', bottom:'1vh', marginLeft:'0.5vw', textAlign:'center', backgroundColor:'#03112c' ,borderColor:'rgba(0,0,0,0)', borderWidth:'0px'}
                                :this.props.mode==bloodClass.WBC?{position:'fixed', bottom:'1vh', marginLeft:'-2vw', textAlign:'center', backgroundColor:'#03112c' ,borderColor:'rgba(0,0,0,0)', borderWidth:'0px'}
                                    :{position:'fixed', bottom:'1vh', marginLeft:'12.5vw', textAlign:'center', backgroundColor:'#03112c' ,borderColor:'rgba(0,0,0,0)', borderWidth:'0px'}} 
                                        onClick={this.decreasepage} disabled={this.state.page==1}><LeftOutlined style={{color:'cyan',fontSize:'1vw'}}/></Button>
                <div style={this.props.mode==bloodClass.FIELD?{position:'fixed', bottom:'1.4vh', marginLeft:'3.5vw', textAlign:'center'}
                                :this.props.mode==bloodClass.WBC?{position:'fixed', bottom:'1.4vh', marginLeft:'2.5vw', textAlign:'center'}
                                    :{position:'fixed', bottom:'1.4vh', marginLeft:'22vw', textAlign:'center'}}>
                                        Showing {((this.state.page-1)*this.pageSize)+1}-{Math.min((this.state.page*this.pageSize), this.state.total_count)} of {this.state.total_count}
                            </div>
                <Button  style={this.props.mode==bloodClass.FIELD?{position:'fixed', bottom:'1vh', marginLeft:'13vw', textAlign:'center', backgroundColor:'#03112c' ,borderColor:'rgba(0,0,0,0)', borderWidth:'0px'}
                :this.props.mode==bloodClass.WBC?{position:'fixed', bottom:'1vh', marginLeft:'13.5vw', textAlign:'center', backgroundColor:'#03112c' ,borderColor:'rgba(0,0,0,0)', borderWidth:'0px'}
                    :{position:'fixed', bottom:'1vh', marginLeft:'40vw', textAlign:'center', backgroundColor:'#03112c' ,borderColor:'rgba(0,0,0,0)', borderWidth:'0px'}} 
                        onClick={this.increasepage} disabled={this.state.page>=this.state.total_pages}><RightOutlined style={{color:'cyan',fontSize:'1vw'}}/></Button>
                {selectCounter}
                </React.Fragment>  
            }
        }
        let tags = this.state.tag_list;
        let menu_items = tags.map((tag, index)=> {
            return <Menu.Item key={index}>{tag}</Menu.Item>
        })

        const menuMultiple = (
            <Menu onClick={(e)=>this.handleMultipleAnnoChange(e)}>
                {menu_items}
            </Menu>
        )
        
        let editMultiple = null;
        if(this.state.listannos.length != 0 ){
            editMultiple = <Dropdown overlay={menuMultiple} trigger="click" style={{backgroundColor:'rgba(0,0,0,0)', position:'absolute', marginTop:"-30vh", zIndex:3}}>
            <Button style={{ backgroundColor:'rgba(255,255,255,0.8)',position:"absolute", border:'0px solid black', marginTop:(-this.state.imgdim/2.5),marginLeft:(this.state.imgdim*1.15),width:this.state.imgdim*1.3, height:'3vh', fontWeight:"bold"}}>
                    <span style={{textAlign:"center", fontSize:'0.9vw', height: 'inherit'}}>ASSIGN<EditOutlined style={{color: "black", }}/></span> </Button>
                            </Dropdown>
        }

        return (
            <Spin  spinning={this.state.spinning} style={{backgroundColor:"rgba(0,0,0,0)", }}>
                <div style={{backgroundColor:'#03112c', marginTop:'0.5vh', height:"50vh" }}>
                    {editMultiple}
                            {annotationsGridParent}
                            <Row style={this.props.mode==bloodClass.FIELD?{position:'fixed', bottom:'1vh'}:this.props.mode==bloodClass.WBC?this.props.differential?{position:'fixed', bottom:'1vh', marginLeft:'2.5vw'}:{position:'fixed', bottom:'1vh'}:{position:'fixed', bottom:'1vh', marginLeft:'0.7vw'}}>
                                <Col span={24} style={this.props.view_mode==1?{  color:'white', fontSize:'1vw'}:{ marginTop:'-10px',marginLeft:'290px'}}>
                                    {gridNumber}
                                </Col>
                            </Row>
                </div>
            </Spin>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bloodGridData: state.bloodGridReducer,
        urlState: state.viewerUrlReducer,
        // bloodGridRBCData: state.bloodGridRBCReducer,
    };
};

export default connect(mapStateToProps)(FieldGridViewPlt);

