import React, {Component} from 'react';
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import {Stack, Tooltip} from "@mui/material";
import {StyledTypography} from "./StyledComponents";
import {getZStackUrl} from "../../bloodviewer/utils/fieldUtils";
import {connect} from "react-redux";

class Field100xImage extends Component {

	constructor(props) {
		super(props);

		this.minZoom = 100;
		this.maxZoom = 800;

		// For devices with 0.5R reducer, increasing scaleMultiplier to accomodate large FOV
		const device_id = (((Object.values(this.props.mapsState)[0] || {}).slideState||{}).slide_data || {}).device;
		this.changeDefaultZoom = !!(device_id > 7 && (this.props.annotation.meta && this.props.annotation.meta.initPos));

		this.state = this.initState();
		this.imageRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener("keydown", this.keyDownListener, false);
		document.addEventListener("keyup", this.keyUpListener, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.keyDownListener, false);
		document.removeEventListener("keyup", this.keyUpListener, false);
	}

	keyDownListener = (event) => {
		if (event.target.tagName === 'INPUT' || event.ctrlKey || event.altKey)
			return;
		switch (event.code) {
			case "Shift":
			case "ShiftLeft":
			case "ShiftRight":
				this.setState({scrollDisabled: true});
				break;
			default:
				break;
		}
	}

	keyUpListener = (event) => {
		if (event.target.tagName === 'INPUT' || event.ctrlKey || event.altKey)
			return;
		switch (event.key) {
			case "Shift":
			case "ShiftLeft":
			case "ShiftRight":
				this.setState({scrollDisabled: false});
				break;
			default:
				break;
		}
	}

	initState = () => {
		this.meta = (typeof(this.props.annotation.meta) === "string") ? JSON.parse(this.props.annotation.meta) : this.props.annotation.meta;
		return {
			zStack: this.meta.initZStack || 0,
			zoom: this.changeDefaultZoom ? this.minZoom * 2 : this.minZoom,
			loaded: false,
			scrollDisabled: false,
		}
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.annotation !== this.props.annotation && this.props.annotation)
			this.setState(this.initState());
		if (prevProps.scale !== this.props.scale)
			this.imageRef.current.centerView(this.convertZoomToScale(this.minZoom));
	}

	convertZoomToScale = (zoom) => zoom / (500 / this.props.scale);

	convertScaleToZoom = (scale) => scale * 500 / this.props.scale;

	//[Priyanshu] compare zStack only with undefined, as 0 is false.
	setZStack = (zStack) => zStack !== undefined ? this.setState({zStack}) : null;

	higherZstack = () => this.setZStack(this.meta.ZStacks[this.meta.ZStacks.indexOf(this.state.zStack) + 1]);

	lowerZstack = () => this.setZStack(this.meta.ZStacks[this.meta.ZStacks.indexOf(this.state.zStack) - 1]);

	zoomIn = (ref) => {
		ref = ref || this.imageRef.current;
		let zoom = Math.min(this.state.zoom * 1.2, this.maxZoom);
		ref.centerView(this.convertZoomToScale(zoom));
		this.setState({zoom});
	}

	zoomOut = (ref) => {
		ref = ref || this.imageRef.current;
		let zoom = Math.max(this.state.zoom * 0.8, this.minZoom);
		ref.centerView(this.convertZoomToScale(zoom));
		this.setState({zoom});
	}

	onWheel = (ref, event) =>
		event.shiftKey ? event.wheelDeltaY > 0 ? this.higherZstack() : this.lowerZstack() : null;

	render() {
		let zStackMicrons = (this.state.zStack * this.meta.ZStackStep).toFixed(2);
		return <TransformWrapper ref={this.imageRef} initialScale={this.convertZoomToScale(this.minZoom)}
		                         wheel={{
			                         step: this.state.scrollDisabled ? 0 : 0.3,
		                         }}
		                         minScale={this.convertZoomToScale(this.minZoom)}
		                         maxScale={this.convertZoomToScale(this.maxZoom)}
		                         onZoomStop={context =>
			                         this.setState({zoom: this.convertScaleToZoom(context.state.scale)})}
		                         onWheelStart={this.onWheel}>
			<TransformComponent wrapperStyle={{
				borderStyle: 'solid', borderWidth: '4px', width: '100%',
				height: '100%', borderColor: this.props.selected ? '#32c730' : '#03112c',
				backgroundColor: '#03112c'
			}}>
				<img
					src={getZStackUrl(this.props.annotation.image, this.state.zStack)}
					style={{filter: `brightness(${(this.props.cssfilter || {}).brightness}%) contrast(${(this.props.cssfilter || {}).contrast}%)`}}
					alt={""}
					onLoad={() => this.state.scrollDisabled ? null : this.imageRef.current.centerView(this.convertZoomToScale(this.changeDefaultZoom ? this.minZoom*2 : this.minZoom))}
				/>
			</TransformComponent>
			<Stack sx={{position: 'relative', bottom: '5vh', width: '100%'}} direction={"row"}
			       justifyContent={"space-around"}>
				<Tooltip title={'Scroll/pinch to zoom in/out'} placement={'top'}>
					<StyledTypography>{this.state.zoom.toFixed(0)} X</StyledTypography>
				</Tooltip>
				<Tooltip title={'Shift + Scroll/pinch to toggle Z Stack up/down'} placement={'top'}>
					<StyledTypography>{zStackMicrons >= 0 ? "+" + zStackMicrons : zStackMicrons} μm</StyledTypography>
				</Tooltip>
			</Stack>
		</TransformWrapper>
	}
}

const mapStateToProps = (state) => ({
	scale: state.gammaStateReducer.scale,
	mapsState: state.mapsStateReducer,
})

export default connect(mapStateToProps)(Field100xImage);
