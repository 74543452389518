import React, {Component} from  "react";
import {connect} from 'react-redux';
import Draw from 'ol/interaction/Draw.js';
import { Snap, Modify, Select } from 'ol/interaction.js';
import { Overlay,  } from "ol";
import { getCenter } from 'ol/extent.js'
import LineString from 'ol/geom/LineString';
import '../../../../../asset/style/neoviewer/draw_tool.css';
import axios from "axios";
import { AuthHeader } from "../../../../../helper/auth.token";
import { AnnotationsConstants } from "../../../../../utils/const";

import querystring from "query-string";

class OverlayMeasure {
    constructor(map, element = document.getElementById("popup"), offset = [0, -5], positioning = 'bottom-center',   className = 'ol-tooltip-measure ol-tooltip .ol-tooltip-static') {
      this.map = map;
      this.overlay = new Overlay({
        element: element,
        offset: offset,
        positioning: positioning,
        className: className
      });
      this.overlay.setPosition([0,0]);
      this.overlay.element.style.display = 'block';      
      this.map.addOverlay(this.overlay);    
    }
  }

class DrawTool extends Component {

    constructor(props) {
        super(props);

        this.draw = null;
        this.snap = null;
        this.shiftPressed = false;
    }

    componentDidUpdate = () => {
        // console.log("shiftPressed",this.shiftPressed)
        // console.log("update")
        if(!this.shiftPressed){
            this.props.slidemap.removeInteraction(this.draw);
            this.props.slidemap.removeInteraction(this.snap);
            this.props.slidemap.removeInteraction(this.modify);
        }
        document.removeEventListener("keydown", this.escFunction, false);
        if (this.props.currentSelectedKey === this.props.toolKey.name && !this.shiftPressed) {
            this.modify = new Modify({
                source: this.props.vector.getSource(),
                snapToPointer: true,
                pixelTolerance : 20,
            })
            this.props.slidemap.addInteraction(this.modify)
            this.modify.setActive(true);
            this.draw = new Draw({
                source: this.props.vector.getSource(),
                type: this.props.toolKey.shape, 
                ...this.props.toolParams
            });
            this.props.slidemap.addInteraction(this.draw);
            if (this.props.snapDrawing || true) {
                this.snap = new Snap({ source: this.props.vector.getSource(),pixelTolerance : 20,snapToEdges: true, snapToVertices: true });
                this.props.slidemap.addInteraction(this.snap);
            }

            this.modify.on("modifystart", this.onModifyStart, this)
            this.modify.on("modifyend", this.onModifyEnd, this)
            
            this.draw.on("drawstart", this.onDrawStart, this);
            this.draw.on("drawend", this.onDrawEnd, this);
            document.addEventListener("keydown", this.escFunction, false);
            document.addEventListener("keydown", this.shiftKeyPress, false);
            document.addEventListener("keyup",this.shiftKeyUnpress, false);
            document.addEventListener("keydown", this.mouserightclick, false)
            document.addEventListener('contextmenu', (e) => {
                this.props.slidemap.getOverlays().clear();
            }, false);
            this.calDistance = (overlay, overlayPosition, distance) => {  
                if(parseInt(distance) == 0) {    
                  overlay.setPosition([0,0]);       
                }
                else {
                  overlay.setPosition(overlayPosition);      
                  if (distance >= 1000) {
                    overlay.element.innerHTML = (distance/1000).toFixed(2) + ' mm';
                    overlay.element.innerHTML = '<p style="color: DarkBlue; font-weight: bold; font-size:20px; text-shadow: 0 0 5px white">'+(distance/1000).toFixed(2) + 'mm'+ '</p>'
                  }
                  else {
                    overlay.element.innerHTML = '<p style="color: DarkBlue; font-weight: bold; ; font-size:20px; text-shadow: 0 0 5px white">'+distance.toFixed(2) + 'um'+ '</p>' ;
                  }
                }    
              }

            this.calAngle = (overlay, overlayPosition, coordinates, e) => {
                // 1. get all the features that are linestrings 
                // 2. get coordinates of all those features 
                // 3. make line equations for all these features and check whether snap point satisfies any
                // 4. if any satisfied find angle between snap line and the line which we snapped to
                // 5. return the angle
                var features = this.props.vector.getSource().getFeatures();
                features = features.filter(f => {
                    if (f.getGeometry().getType() == "LineString" && e.target.flatCoordinates.length==4){
                        return true;
                    }
                    else{
                        return false;
                    }
                })
                let coords_lines = features.map(f => {
                    return f.getGeometry().getCoordinates();
                })
                let found_any = false;
                //console.log.log(coords_lines)
                for(let i=0; i<coords_lines.length; i++){
                    let line = coords_lines[i];
                    let point1 = line[0];
                    let point2 = line[1];
                    let x1 = point1[0]
                    let y1 = point1[1]
                    let x2 = point2[0]
                    let y2 = point2[1]
                    let snap_point = coordinates[1]
                    let a = snap_point[0]
                    let b = snap_point[1]
                    // console.log("LHS",((b-y1)*(x2-x1)))
                    // console.log("RHS",((y2-y1)*(a-x1)))
                    if(((b-y1)*(x2-x1)).toFixed(2)==((y2-y1)*(a-x1)).toFixed(2)){
                        found_any = true;
                        let p = coordinates[0]
                        let c = p[0]
                        let d = p[1]
                        let ms = (d-b)/(c-a)
                        let ml = (y2-y1)/(x2-x1)
                        let anglerad = Math.atan((ms-ml)/(1+ms*ml))
                        // let anglerad = Math.atan(ms) - Math.atan(ml)
                        let angledeg = (anglerad * 180)/Math.PI
                        // if(angledeg<0){
                        //     angledeg = 180-angledeg;
                        // }
                        if(Math.abs(angledeg)>89){
                            angledeg=90
                        }
                        console.log("angle",parseInt(angledeg))
                        overlay.setPosition(overlayPosition); 
			if(String(parseInt(Math.ceil(angledeg)))!== "NaN"){
                        	overlay.element.innerHTML='<p style="color: DarkBlue; font-weight: bold; font-size:15px; margin-botton: -10px;text-shadow: 0 0 5px white">'+parseInt(Math.ceil(angledeg))+ '&#176'+ '</p>';
			}else{
				overlay.element.innerHTML = ''
			}
			let angle = parseInt(Math.ceil(angledeg))
                        if(parseInt(Math.abs(angle)) >= 85 && Math.abs(angle) != 90){
                            let slope = (y2-y1)/(x2-x1);
                            // let nb = ((d*slope)-(x1)+(y1/slope))/(1+(1/slope))
                            // let na = x1 + ((nb-y1)/slope)
                            let na = (d+(c/slope)+(x1*slope)-y1)/(slope+(1/slope))
                            let nb = (slope*(na-x1))+y1
                            e.target.setCoordinates([coordinates[0],[na,nb]]);
                            return [na,nb];
                            // this.draw.appendCoordinates([na,nb]);
                        }
                        
                    }
                }
                if(!found_any){
                    // /* this.props.slidemap.getOverlays().pop(); */
                    overlay.setPosition(overlayPosition); 
                    // overlay.element.innerHTML='<p style="color: DarkBlue; font-weight: bold; font-size:10px; margin-botton: -10px">+''+'</p>';
                    overlay.element.innerHTML = ''
                }
                return []
            }
            
        } 
    }

    shiftKeyPress = (event) => {
        if(event.keyCode === 16){
            this.shiftPressed = true
        }
    }
    shiftKeyUnpress = (event) => {
        if(event.keyCode === 16){
            this.shiftPressed = false
        }
    }

    escFunction = (event) => {
        if(event.keyCode === 27) {
            if(this.props.toolKey.name == AnnotationsConstants.MAGIC_TOOL){
                this.props.clearMagicTool();
            }
            this.stopDrawing();
            event.stopPropagation();
            this.props.slidemap.getOverlays().clear();
        }
    }

    onModifyStart = (event) => {
        // console.log(event, "modify start")
        this.props.slidemap.removeInteraction(this.snap);
    }

    onModifyEnd = (event) => {
        console.log("modify end",event.features.getArray(), event.features.getArray()[0].getId(),event.features.getArray()[0].getGeometry().getCoordinates()[0])
        let new_data = []
        let feature_array = event.features.getArray()
        for(let i=0; i<feature_array.length; i++){
            let id = feature_array[i].getId();
            let bounds = feature_array[i].getGeometry().getCoordinates()[0]
            new_data.push({
                "anno_id": id,
                "new_bounds" : bounds,
            })
        }

        let val = {
            'data': JSON.stringify(new_data)
        }
        let url = '/api/modify_annotation/'
        axios.post(url, querystring.stringify(val),  { headers: { Authorization: AuthHeader() } })
            .then(res => {
                console.log("anno updated successfully")
            }).catch(err => {
                console.log("anno couldnt be updated")
            })
        if (this.props.snapDrawing || true) {
            this.snap = new Snap({ source: this.props.vector.getSource(),pixelTolerance : 20,snapToEdges: true, snapToVertices: true });
            this.props.slidemap.addInteraction(this.snap);
        }
    }

    stopDrawing = () => {
        console.log("stop-drawing")
        if (!this.props.continuousMode) {
            this.props.slidemap.removeInteraction(this.draw);
        }
        this.props.setDrawing(false);
        this.props.slidemap.getOverlays().clear();
    }

    onDrawStart = (e) => {
        console.log("drawstart")
        this.props.setDrawing(true);
        this.coordinates_length = 0;
        this.partDistanceOverlay = null;
        this.partAngleOverlay = null;
        this.overlay = new Overlay({
            element: document.getElementById('popup'),
            offset: [0, 5],
            positioning: 'bottom-center',
            className : 'ol-tooltip-measure ol-tooltip .ol-tooltip-static',
        })
        
        e.feature.getGeometry().on('change',this.onGeomChange);
        if(this.props.toolKey.name == AnnotationsConstants.MAGIC_TOOL){
            let start = this.props.getGeometricParams(e.feature.getGeometry()).coordinates[0]
            console.log("start", this.props.getGeometricParams(e.feature.getGeometry()),this.props.slidemap.getView().calculateExtent(this.props.slidemap.getSize()))
            this.props.updateMagicToolStartPoint(start)
        }
        else{
            this.totalAreaDistanceOverlay = this.props.slidemap.addOverlay(this.overlay);
        }
        // console.log("draw_start", this.props.toolKey,this.props.getGeometricParams(e.feature.getGeometry()))
    }

    onDrawEnd = (e) => {
        console.log("drawend", this.props.toolKey)
        // if(this.props.toolKey.name == "Modify"){
        //     return;
        // }
        let feature = e.feature;
        let geometry = feature.getGeometry();
        let params = this.props.getGeometricParams(geometry);
        if(this.props.toolKey.name == AnnotationsConstants.MAGIC_TOOL){
            // we need to store the current magic annotation layer 
            this.props.saveMagicAnnotation();
        }else{
            this.props.addAnnotation(params.area, params.perimeter, this.props.toolKey, JSON.stringify(params.coordinates), getCenter(geometry.getExtent()), params.count);    
        }
        this.stopDrawing();
        this.props.slidemap.getOverlays().clear();
    }

    onGeomChange = (e) => {    
        //console.log("change", e.target.getCoordinates())
        console.log("onchange")
        let geomType = e.target.getType();
        let coordinates = e.target.getCoordinates();
        if(this.props.toolKey.name == AnnotationsConstants.MAGIC_TOOL){
            this.props.updateMagicToolCurrentPoint( e.target.getCoordinates()[1])
        }
        let setToNinety = false;
        // console.log("geomType", geomType)
        if(geomType != "LineString" || this.props.toolKey.name == AnnotationsConstants.MAGIC_TOOL||((e.target||{}).flatCoordinates||{}).length>4){
            return;
        } 
        if (coordinates.length > this.coordinates_length) {                
          this.partDistanceOverlay = new OverlayMeasure(this.props.slidemap).overlay;
          this.partAngleOverlay = new OverlayMeasure(this.props.slidemap).overlay;
          this.coordinates_length =  coordinates.length;      
        }
        else {                     
          this.coordinates_length =  coordinates.length;            
        }    
        
        let partLine = new LineString([coordinates[this.coordinates_length-2], coordinates[this.coordinates_length-1]]);     
        this.calDistance(this.partDistanceOverlay, partLine.getFlatMidpoint(), partLine.getLength());  
        let coords = this.calAngle(this.partAngleOverlay, coordinates[1], coordinates, e)
        // if(coords.length==2){
        //     e.target.setCoordinates([coordinates[0],coords]);
        //     // this.calDistance(this.partDistanceOverlay, partLine.getFlatMidpoint(), partLine.getLength());
        // } 
    }



    render(){
        // console.log("magic", this.props)
        // window.addEventListener("auxclick", (event) => {
        //     if (event.button === 2) alert("Right click");
        //   });
        // console.log("toolparams",this.props.toolParams)
        // console.log("interactions",this.props.slidemap.getInteractions())
        // console.log(this.props.toolKey.name)
        var features = this.props.vector.getSource().getFeatures();
        // features.forEach(feature => {
        //     console.log(feature.getGeometry().getCoordinates())
        // });
        var types = features.map(f => {
            if (f.getGeometry().getType() == "LineString"){
                return f.getGeometry().getCoordinates();
            }
            else{
                return "x";
            }
        })
        var geometry = features.map(f => {
            return f.getGeometry();
        })
        var coords = geometry.map(g => {
            return g.getCoordinates();
        })
        // console.log(coords)
        // console.log(geometry)
        return (
            <div className={((this.props.urlState || {}).presentCode != undefined ? "drawing-tool-icon-present " : "drawing-tool-icon ") + (this.props.currentSelectedKey === this.props.toolKey.name ? " drawing-tool-open" : "")}
                onClick={(this.props.urlState || {}).presentCode != undefined ? null : () => this.props.onSelected(this.props.toolKey.name)}>
                <div>
                    {this.props.content}
                </div>
                <div className="drawing-tool-icon-text unselectable">
                    {this.props.toolKey.name}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
        magicToolData: state.MagicToolReducer,
    }
}

export default connect(mapStateToProps)(DrawTool);
