import React, {Component} from  "react";
import {Row, Col, Tabs, Input, Spin, Checkbox, InputNumber, Divider } from 'antd';
import {connect} from 'react-redux';
import cookie from "react-cookies";
import { getVerticalBrushPanel, getCanvasComponentNew, getStainChooser, getSpeedPanel } from "../slot_component_utils";
import { scanTypeVals } from '../../../utils/const';
import { specimenType, scanSpeed } from "../slots";
import { countWBC, updateCountSlotWise } from '../../../action/bloodConfig.action';

const { TabPane } = Tabs;

class GeneralPreviewSettingsComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scanType: this.props.preview.speed == scanSpeed.ZSTACK ? scanTypeVals.Z_STACK : scanTypeVals.WSI,
            specimenTypes:[],
            wbcCount:100,
        }
    }

    componentDidUpdate = () => {
    }

    componentDidMount = () => {
        let specimens_=[]
        if((((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response||{}).supportedSpecimenTypes){
            (((this.props.deviceStatus[this.props.device_id] || {}).scanner_health || {}).scanner_response||{}).supportedSpecimenTypes.map((index)=>{
                specimens_.push(index);
            })
        }
        this.setState({
            specimenTypes: specimens_,
        })

        if(((this.props.bloodConfig||{}).countWBCSlotWise||{})[this.props.id]){
            this.setState({
                wbcCount: ((this.props.bloodConfig||{}).countWBCSlotWise||{})[this.props.id]
            })
        }
    }

    setWBCCount = (count) => {
        console.log("wbc-change", count)
        this.setState({
            wbcCount: count,
        })
        this.props.dispatch(updateCountSlotWise([this.props.id, count]));
    }

    changeScanType = (key) => {
        if(key == scanTypeVals.Z_STACK){
            this.props.onScanSpeedChangeStack();
        }
        this.setState({
            scanType: key,
        });
    }
    

    render() {
        // console.log("prevxx", this.props.preview.specimenType)
        let is_superuser = cookie.loadAll().superuser === "true";
        
        return(
            <div>
                <Row style={{height: '100%'}}>
                    <Col span={20}>
                        <Row>
                            {getCanvasComponentNew(this.props.status, this.props.previewCanvasRef, this.props.thresholdCanvasRef,
                                this.props.brushEraserPrevCanvasRef, this.props.regions, this.props.onCanvasUpdate, this.props.allowPainting, this.props.startPainting, this.props.paintCanvas, 
                                this.props.stopPainting, this.props.dropDistanceCanvas, this.props.dropDistanceCanvasRef, this.props.focusPointsCanvas, 
                                this.props.focusPointsCanvasRef, this.props.preview, this.props.id, this.props.device_id, this.props.brushType,
                                this.props.showBrushOrEraserSize)}
                        </Row>
                        <br />
                        <Row>
                            <Col className="selected-area-component">
                                Selected Area : {this.props.regionArea} mm2
                            </Col>
                        </Row>
                    </Col>
                    <Col span={4}>
                        {getVerticalBrushPanel(this.props.brushType, this.props.brushSize, this.props.changeBrushType, this.props.movedBrushSizeSlider)}
                        <Row className="side-panel-section">
                            <Col>
                                <span className="arial-font" style={{marginRight:10, fontSize:13}}><b>Slide Name</b></span>
                                <Spin spinning={this.props.reading_barcode} tip="Trying to Read Barcode...">
                                    <Input value={this.props.preview.slideName} placeholder={this.props.preview.response.slideName} 
                                        style={{width: 260}} onChange={this.props.onScanNameChange} />
                                </Spin>
                            </Col>
                        </Row>
                        <Row className="side-panel-section">
                            <Col>
                                {getStainChooser(this.props.onSpecimenTypeChange, this.props.preview, this.state.specimenTypes)}
                            </Col>
                        </Row>
                        {is_superuser ?
                            <Row className="side-panel-section">
                                <Col span={24}>
                                    <br></br>
                                    <Row style={{fontSize:13}}>
                                        <Col span={24}>
                                            <Checkbox checked={this.props.preview.doTilingOnly} onChange={this.props.toggleTiling}>
                                                Do Tiling Only
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <Row style={{fontSize:13}}>
                                        <Col span={24}>
                                            <Checkbox checked={this.props.preview.doTilingAfterScan} onChange={this.props.toggleTilingAfterScan}>
                                                Do Tiling After Scan
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> :
                            null
                        }
                        <Row className="side-panel-section">
                            {this.props.preview.specimenType == "BM"?
                             <Tabs activeKey={this.state.scanType} size="small">
                             {/* onChange={this.changeScanType} */}
                             <TabPane tab="WSI" key={scanTypeVals.WSI}>

                                 {/* TODO: Implement WBC Count number */}
                                 <Col>
                                     <span><b># WBC: </b>
                                         <InputNumber min={1} max={5000} value={this.state.wbcCount} onChange={this.setWBCCount} />
                                     </span>
                                 </Col>
                                 {/* This will take a normal scan.
                                 <Col>
                                 {
                                     getSpeedPanel(this.props.onScanSpeedChange, this.props.preview)
                                 }
                                 </Col>
                                 <br></br>
                                 <Col>{
                                     (this.props.preview || {}).speed == scanSpeed.FAST ? "Mostly Flat Tissue.": 
                                     (this.props.preview || {}).speed == scanSpeed.MEDIUM ? "Some Artifacts." : "Very Heavy Artifacts. Can Have Folds" }</Col> */}
                             </TabPane>
                             {/* {is_superuser || (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_take_z_scan ?
                                 <TabPane tab="Z Scan" key={scanTypeVals.Z_STACK}>
                                     <Row key={0} style={{ padding: 10 }}>
                                         <Row style={{fontSize:13}}>
                                             <Col span={10} className="z-stack-scan-text">
                                                 Z Stack Levels
                                             </Col>
                                             <Col span={14}>
                                                 <Input className="z-stack-scan-input-field" value={this.props.preview.zStackLevels} 
                                                     placeholder={10} onChange={this.props.onZLevelsChange} />
                                             </Col>
                                         </Row>
                                         <Row style={{fontSize:13}}>
                                             <Col span={10} className="z-stack-scan-text">
                                                 Step Size (um)
                                             </Col>
                                             <Col span={14}>
                                                 <Input className="z-stack-scan-input-field" value={this.props.preview.stepSize} 
                                                     placeholder={0.5} onChange={this.props.onStepSizeChange} />
                                             </Col>
                                         </Row>
                                     </Row>
                                 </TabPane>
                                 : null
                             } */}
                         </Tabs>:
                            <Tabs activeKey={this.state.scanType} onChange={this.changeScanType} size="small">
                                <TabPane tab="WSI" key={scanTypeVals.WSI}>
                                    This will take a normal scan.
                                    <Col>
                                    {
                                        getSpeedPanel(this.props.onScanSpeedChange, this.props.preview)
                                    }
                                    </Col>
                                    <br></br>
                                    <Col>{
                                        (this.props.preview || {}).speed == scanSpeed.FAST ? "Mostly Flat Tissue.": 
                                        (this.props.preview || {}).speed == scanSpeed.MEDIUM ? "Some Artifacts." : "Very Heavy Artifacts. Can Have Folds" }</Col>
                                </TabPane>
                                {is_superuser || (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_take_z_scan ?
                                    <TabPane tab="Z Scan" key={scanTypeVals.Z_STACK}>
                                        <Row key={0} style={{ padding: 10 }}>
                                            <Row style={{fontSize:13}}>
                                                <Col span={10} className="z-stack-scan-text">
                                                    Z Stack Levels
                                                </Col>
                                                <Col span={14}>
                                                    <Input className="z-stack-scan-input-field" value={this.props.preview.zStackLevels} 
                                                        placeholder={10} onChange={this.props.onZLevelsChange} />
                                                </Col>
                                            </Row>
                                            <Row style={{fontSize:13}}>
                                                <Col span={10} className="z-stack-scan-text">
                                                    Step Size (um)
                                                </Col>
                                                <Col span={14}>
                                                    <Input className="z-stack-scan-input-field" value={this.props.preview.stepSize} 
                                                        placeholder={0.5} onChange={this.props.onStepSizeChange} />
                                                </Col>
                                            </Row>
                                        </Row>
                                    </TabPane>
                                    : null
                                }
                            </Tabs>}
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return{
        preview: state.previewStatusReducer[ownProps.id],
        deviceStatus: state.deviceStatusReducer,
        bloodConfig: state.bloodConfigReducer,
    }
}

export default connect(mapStateToProps)(GeneralPreviewSettingsComp);
