import React, {Component} from "react";
import {connect} from "react-redux";
import {getSettingComponent} from "../utils/settingsUtils";
import {Stack} from "@mui/material";
import {ConfigurationKeys} from "../keys/Configurations";
import CircularProgress from "@mui/material/CircularProgress";
import {updateConfigurations} from "../../../action/morpheus.setting.action";

class ConfigurationApp extends Component {

	update = (configuration, value) => {
		configuration.value = value;
		this.props.dispatch(updateConfigurations({
			...this.props.configurations,
			[configuration.name]: value,
		}));
	}

	render() {
		if (!this.props.configurations)
			return <div style={{height: 'inherit'}}>
				<CircularProgress color={'secondary'} sx={{marginTop: '75%', marginLeft: '40%'}}/>
			</div>

		return <Stack direction={"column"}>
			{ConfigurationKeys.map(configuration => getSettingComponent(configuration,
				this.props.configurations[configuration.name],
				value => this.update(configuration, value)))}
		</Stack>
	}
}

const mapStateToProps = (state) => ({
	morpheusSettingId: state.gammaStateReducer.morpheusSettingID,
	configurations: state.gammaStateReducer.configurations,
});

export default connect(mapStateToProps)(ConfigurationApp);
