import {Component} from "react";
import {IconButton, Stack, Typography} from "@mui/material";
import {PowerSettingsNewTwoTone, RestartAltTwoTone} from "@mui/icons-material";
import {post} from "../../../helper/request";
import {checkServicePermission} from "../../../utils/morpheus_utils";
import {Services} from "../../../const/Services";

class ServicesApp extends Component {

	constructor(props) {
		super(props);
		this.state = this.initState();
		this.services = Services.filter(service => checkServicePermission(service));
	}

	initState = () => ({});

	componentDidMount() {
		this.checkAllStatus();
	}

	checkAllStatus = () => this.services.map(service => this.status(service));

	start = service =>
		post(`/api/services/${service.service}/start/`).then(status => this.update(service, status));

	stop = service =>
		post(`/api/services/${service.service}/stop/`).then(status => this.update(service, status))

	toggle = service => service.status ? this.stop(service) : this.start(service);

	restart = service =>
		post(`/api/services/${service.service}/restart/`).then(status => this.update(service, status))

	status = service =>
		post(`/api/services/${service.service}/status/`).then(status => this.update(service, status))

	update = (service, status) => {
		service.status = status;
		this.setState({});
	}

	getServicesComponent = () =>
		this.services.map(service => (
			<Stack key={service.name} direction="row" justifyContent={"space-between"} alignItems={"center"}>
				<Typography color={"whitesmoke"}>{service.name}</Typography>
				<Stack direction={"row-reverse"} spacing={1} alignItems={"center"}>
					<IconButton onClick={() => this.restart(service)}>
						<RestartAltTwoTone color={"secondary"}/>
					</IconButton>
					<IconButton onClick={() => this.toggle(service)}>
						<PowerSettingsNewTwoTone color={service.status ? "success" : "error"}/>
					</IconButton>
				</Stack>
			</Stack>
		));

	render() {
		return <Stack direction={"column"} spacing={1}>
			<Typography align={"center"} variant={"overline"} fontSize={20} color={"white"}>Services</Typography>
			{this.getServicesComponent()}
		</Stack>
	}
}

export default ServicesApp;
