import React, {Component} from "react";
import {Autocomplete, Button, Chip, IconButton, Popover, Stack, Tab, Tabs, TextField, Tooltip} from "@mui/material";
import {connect} from "react-redux";
import {BorderVertical, Sync} from "@mui/icons-material";
import {updateSync} from "../../../action/gamma.state.action";
import {morphleBaseTheme1} from "../themes";
import {typeAheadSlideSearch} from "../utils/typeaheads";
import {mapSlides} from "../utils/splitSyncUtils";

class SplitSync extends Component {

	constructor(props) {
		super(props);

		let slides = Object.keys(this.props.mapsState).map(mapId => ({
			id: this.props.mapsState[mapId].slideState.slide_data.id,
			name: this.props.mapsState[mapId].slideState.slide_data.name,
		}));

		this.permanentSlides = slides.slice(0, 1);

		this.state = {
			anchorEl: null,
			value: false,
			selectedSlides: slides,
			options: [],
		}

		if (this.props.sync) this.saveMapsPosition();
	}

	mapSlides = () => mapSlides(this.state.selectedSlides);

	handleTabChange = (event, value) => {
		event.stopPropagation();
		this.setState({
			anchorEl: event.currentTarget,
			value: value,
		});
	}

	handleTabClose = () => {
		this.setState({
			anchorEl : null,
			value: false
		});
	}

	resetMaps = () => {
		this.setState({
			selectedSlides: this.permanentSlides,
		}, this.mapSlides);
	}

	toggleSync = () => {
		if (Object.keys(this.props.mapsState).length < 2) return;
		if (!this.props.sync)
			this.saveMapsPosition();
		this.props.dispatch(updateSync(!this.props.sync));
		this.setState({});
	}

	// reducer variables are directly modified instead of dispatching,
	// since we don't need any state change for older positions, no need to dispatch, reduce lags wherever possible
	saveMapsPosition = () => {
		let mapsState = this.props.mapsState;
		for (let map_id in mapsState) {
			let mapState = mapsState[map_id];
			// saving current state to use in sync mode, not changing the object pointer as not dispatching
			mapState.X = mapState.x;
			mapState.Y = mapState.y;
			mapState.Z = mapState.z;
			mapState.R = mapState.r;
		}
	}

	render() {

		return (
			<>
				<Tabs value={this.state.value} onChange={this.handleTabChange} scrollButtons="auto">
					<Tooltip title={"Split"} placement={"bottom"}>
						<Tab icon={<BorderVertical/>}/>
					</Tooltip>
					<Tooltip title={"Sync"} placement={"bottom"}>
						<IconButton onClick={this.toggleSync} sx={{verticalAlign: 'middle'}}>
							{this.props.sync ?
								<Sync sx={{color: morphleBaseTheme1.palette.headBarIcon.secondary}}/> :
								<Sync sx={{color: morphleBaseTheme1.palette.headBarIcon.primary}}/>}
						</IconButton>
					</Tooltip>
				</Tabs>
				<Popover open={!!this.state.anchorEl} anchorEl={this.state.anchorEl}
						 anchorOrigin={{
							 vertical: 'bottom',
							 horizontal: 'left',
						 }}
						 onClose={this.handleTabClose}>
					<Autocomplete multiple sx={{width: '9vw'}} defaultValue={this.permanentSlides} disableClearable
								  freeSolo forcePopupIcon={false} value={this.state.selectedSlides}
								  onChange={(event, value) =>
									  this.setState({
										  selectedSlides: [...this.permanentSlides,
								              ...value.filter(slide => this.permanentSlides.indexOf(slide) === -1)],
						              })}
					              onInputChange={(e, v) =>
						              typeAheadSlideSearch(v, (options) => this.setState({
							              options: options
						              }))}
					              renderInput={(params) =>
						              <TextField {...params} variant="outlined" placeholder={"Type Slide Name..."}/>}
					              renderTags={(slides, getSlideProps) =>
						              slides.map((slide, index) =>
							              <Chip label={slide.name} variant={"filled"}
							                    sx={{width: "90%", justifyContent: "right"}}
							                    {...getSlideProps({index})}
							                    disabled={this.permanentSlides.indexOf(slide) !== -1}/>)}
					              options={this.state.options}
					              getOptionLabel={option => option.name}/>
					<Stack direction={"row"} justifyContent={"space-around"}>
						<Button disabled={this.props.sync} color={"secondary"}
						        onClick={this.mapSlides}>Split</Button>
						<Button disabled={Object.keys(this.props.mapsState).length < 2 ||
							this.props.sync} color={"secondary"}
						        onClick={this.resetMaps}>Reset</Button>
					</Stack>
				</Popover>
			</>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		mapsState: state.mapsStateReducer,
		sync: state.gammaStateReducer.sync,
		splitLock: state.gammaStateReducer.splitLock,
	}
}

export default connect(mapStateToProps)(SplitSync)
