import React, {Component} from 'react';
import {Row} from 'antd';
import WrappedNormalLoginForm from './login';
import {globalUrlPrefix} from '../utils/const';
import {tokens} from '../helper/backend.tokens';
import {getCsrfToken, login} from "../action/login.action";
import {connect} from "react-redux";

class LoginView extends Component {

    componentWillMount() {
        this.props.dispatch(getCsrfToken());
    }

    render() {
        let redirectPage = ((this.props.location.state) || {}).from !== undefined ?
			(this.props.location.state.from)
			: "/" + globalUrlPrefix + "/dashboard";

        let prevLocationStatus = ((this.props.location.state) || {}).from !== undefined;

        let proxyLoginStatus = false;

        let search = ((this.props.location.state || {}).from || {}).search;
        let demoLogin = false;
        if (search) {
            demoLogin = search.includes("demo=true");
        }

        var url = window.location.href.split('?')[1];
        let token = '';
        let displayMessage = null;
        let resolution = null;
        if (url) {
            var partsOfUrl = url.split('&');
            partsOfUrl.map((part) => {
                var key = part.split('=')[0];
                var value = part.split('=')[1];
                if (key === 'token') {
                    token = value;
                } else if (key === 'message') {
                    displayMessage = value;
                } else if (key === 'resolution') {
                    resolution = value;
                }
            });
        }


        if ((this.props.location.state || {}).token) {
            token = (this.props.location.state || {}).token;
        }

        console.log(token)

        if (token === tokens.proxy_user_uscap) {
            proxyLoginStatus = true;
            let loginDetails = {
                username: "uscap",
                password: "morphledigipath",
                csrfmiddlewaretoken: this.props.csrfToken,
                remember: false,
            }
            this.props.dispatch(login(loginDetails, proxyLoginStatus));
        }

        if (token === tokens.proxy_user_website) {
            proxyLoginStatus = true;
            let loginDetails = {
                username: "morphle-website",
                password: "morphledigipath",
                csrfmiddlewaretoken: this.props.csrfToken,
                remember: false,
            }
            this.props.dispatch(login(loginDetails, proxyLoginStatus));
        }

        return(
            <Row className="login-mp">
                    <WrappedNormalLoginForm redirectPage={redirectPage}
                        prevLocationStatus={prevLocationStatus} demoLogin={demoLogin}
                        proxyLoginStatus={proxyLoginStatus} message={displayMessage}
                        resolution={resolution} />
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return state.loginAlerts;
}

export default connect(mapStateToProps)(LoginView);
