import React, {Component} from "react";
import {connect} from "react-redux";
import {Box, Grid, Popover, Slider, Tab, Tabs, Tooltip} from '@mui/material';
import Brightness6Icon from '@mui/icons-material/Brightness6';
import ContrastIcon from '@mui/icons-material/Contrast';
import {brightnessKey, contrastKey} from '../utils/viewerSettingsKeys'
import {loadViewerSetting, updateViewerSetting} from "../../../action/viewer.settings.actions";
import {Loading} from "../components/components";

class HeadBarSettings extends Component {
	constructor(props) {
		super(props);
		this.state = this.initState();
		this.props.dispatch(loadViewerSetting(this.props.slide.settings));
	}

	initState = () => {
		return {
			anchorEl: null,
			setting: false
		}
	}

	handleTabChange = (event, setting) =>
		this.setState({
			anchorEl: event.currentTarget,
			setting,
		});

	handleClose = () => {
		this.setState({
            anchorEl: null,
			setting: false
        });
    }

    getImageEnhancementSliderComponent = (enhancementKey, onChange) => {
		let value = this.props.viewerSetting[enhancementKey.id];
        return <Grid item sm={9} marginLeft={3} marginTop={'0.3vh'}>
            <Slider
                value = {value}
                defaultValue={value}
                key={`slider-${enhancementKey.id}`}
                min={enhancementKey.min}
                max={enhancementKey.max}
                onChange={onChange}
                color={'secondary'}
            />
        </Grid>
    }

    render() {
		if (!this.props.viewerSetting)
			return <Loading/>;

		return (
			<Box>
				<Tabs value={this.state.setting} onChange={this.handleTabChange} variant="fullWidth">
					<Tooltip title={"Brightness"} placement={"bottom"} value={brightnessKey}>
						<Tab icon={<Brightness6Icon/>} value={brightnessKey}/>
					</Tooltip>
					<Tooltip title={"Contrast"} placement={"bottom"} value={contrastKey}>
						<Tab icon={<ContrastIcon/>} value={contrastKey}/>
					</Tooltip>
				</Tabs>
				<Popover open={!!this.state.anchorEl} anchorEl={this.state.anchorEl}
						 anchorOrigin={{
							 vertical: 'bottom',
							 horizontal: 'left',
						 }}
						 onClose={this.handleClose}>
					<Grid container sx={{width: '10vw'}} alignItems={"center"}>
						{this.getImageEnhancementSliderComponent(this.state.setting,
							(event, newValue) =>
								this.props.dispatch(updateViewerSetting(this.props.viewerSetting.id, {
									[this.state.setting.id]: newValue,
								})))}
					</Grid>
				</Popover>
            </Box>
        )
    }
}

const mapStateToProps = (state) => {
	let activeMapId = state.gammaStateReducer.activeMapId;
	let mapState = state.mapsStateReducer[activeMapId];
	let slide = mapState.slideState.slide_data;
	let viewerSetting = state.viewerSettingsReducer[slide.settings];
	return {activeMapId, mapState, slide, viewerSetting};
}

export default connect(mapStateToProps)(HeadBarSettings)
