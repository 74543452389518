import React, {Component} from "react";
import {connect} from 'react-redux';
import {MenuItem, Select, Stack, Tooltip} from "@mui/material";
import {Select as select_ol} from 'ol/interaction';

import {AnnotationsConstants} from "../../../utils/const";
import CircleIcon from '@mui/icons-material/Circle';
import * as drawToolKeys from "../drawer/draw_tool_keys";
import {getAllDrawingTools} from "../drawer/draw_tool_keys";
import DrawTool from "../drawer/draw_tool";
import {updateSelectedDrawColor, updateSelectedDrawTool} from "../../../action/gamma.state.action";
import {annotationLayer, deepBioLayer, ihcLayer, labellingLayer} from "../utils/layers_info";

// keep this generalised anno_drawer_id from props can control this and ultimately decided which layer it stores to
// we can get the active anno drawer from annotationData redux
// props needed :-
// 1. vector to add draw, snap, modify interactions accordingly
// 2. add_annotation_function to store annotation after its finished drawing
// 3. list of tools to show
// 4. for cross selection of drawn annos we will have to maintain seperate redux

class AnnotationDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: true, // till the vector is not there in props it will stay in fetching state
            isDrawing: false, // not sure abt the use
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.keyDownListener, false);
        document.addEventListener("keyup", this.keyUpListener, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyDownListener, false);
        document.removeEventListener("keyup", this.keyUpListener, false);
        // component is unmounting when splitting map, due to the fact circular progress replace it for while
        // we need to reset tools for this too
        this.resetTools();
    }

    keyDownListener = (event) => {
        if (!this.props.keyboardShortcutsEnable || event.target.tagName === 'INPUT') return;
        this.keyDownTimeStamp = event.timeStamp;
        for (let key in drawToolKeys)
            if (drawToolKeys[key].shortcutKeyCode === event.code)
                return this.onSelected(drawToolKeys[key]);
    }
    /* TODO: future idea of creating shortcut for drawing annotations using the shortcut (by long pressing)
             that's why time difference is necessary - Priyanshu
    */
    keyUpListener = (event) => {
        if (!this.props.keyboardShortcutsEnable || event.target.tagName === 'INPUT') return;
        // time difference for how long key is pressed, if td is more than value, then key was used to draw annotation
        // so after key up, remove the selected annotation tool, since drawing is finished
        if (event.timeStamp - this.keyDownTimeStamp > 1000) // TODO: reduce 1000 after adding keyboard based drawing
            this.onSelected(null);
        this.keyDownTimeStamp = null;
    }

    initState = () => {
        // reset tool if active Map id changed
        if (this.activeMapId && this.props.activeMapId !== this.activeMapId) {
            this.resetTools();
        }
        this.activeMapId = this.props.activeMapId;
        this.mapState = this.props.mapsState[this.activeMapId];
        this.slideState = this.mapState.slideState;
		// TODO: [Priyanshu] instead of using activeAnnoDrawer, use activeAnnoLayer directly
        switch (this.props.activeAnnoDrawer) {
            default:
            case 0:
				this.vector = annotationLayer.vector;
                break;
            case 1:
				this.vector = deepBioLayer.vector;
                break;
            case 2:
				this.vector = ihcLayer.vector;
                break;
            case 3:
				this.vector = labellingLayer.vector;
                break;
        }
    }

    changeDrawingColor = e => this.props.dispatch(updateSelectedDrawColor(e.target.value));

    getColorDropBarComponent = () =>
        <Tooltip title={"Colors"} placement={"bottom"}>
            <Select variant={"filled"} value={this.props.selectedDrawColor.toLowerCase()}
                    onChange={(e) => this.changeDrawingColor(e)}>
                {Object.values(AnnotationsConstants.ANNO_COLORS).map(color =>
                    <MenuItem value={color.toLowerCase()}>
                        <CircleIcon sx={{color: color.toLowerCase()}}/>
                    </MenuItem>)}
            </Select>
        </Tooltip>;

    resetTools = () => {
        this.props.dispatch(updateSelectedDrawTool(null));
        this.slideState.slidemap.getInteractions().forEach((interaction) => {
            if (interaction instanceof select_ol) {
                interaction.setActive(true)
            }
        });
    }

    onSelected = (newSelection) => {
        if (!newSelection || newSelection === this.props.selectedDrawTool)
            this.resetTools();
        else {
            this.props.dispatch(updateSelectedDrawTool(newSelection));
            this.slideState.slidemap.getInteractions().forEach((interaction) => {
                if (interaction instanceof select_ol) {
                    interaction.setActive(false)
                }
            });
        }

    }

    setDrawing = (value) => {
        if (value === false) this.props.dispatch(updateSelectedDrawTool(null));
    }

    render() {
        this.initState();
        if (!this.vector)
            return <div />
        return <Stack direction={"row"} spacing={3} justifyContent="flex-start" alignItems='center'>
            {[this.getColorDropBarComponent(),
				getAllDrawingTools().map(tool =>
					<DrawTool tool={tool} vector={this.vector} onSelected={this.onSelected}
							  selectedDrawTool={this.props.selectedDrawTool}
							  selectedColor={this.props.selectedDrawColor}
							  resetTools={this.resetTools}
					/>),
            ]}
        </Stack>
    }
}

const mapStateToProps = (state) => {
    return {
        activeMapId: state.gammaStateReducer.activeMapId,
        activeAnnoDrawer: state.gammaStateReducer.activeAnnoDrawer,
        selectedDrawTool: state.gammaStateReducer.selectedDrawTool,
        selectedDrawColor: state.gammaStateReducer.selectedDrawColor,
        keyboardShortcutsEnable: state.gammaStateReducer.keyboardShortcutsEnable,
        mapsState: state.mapsStateReducer,
    }
}

export default connect(mapStateToProps)(AnnotationDrawer);
