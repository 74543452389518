import React from  "react";
import axios from "axios";
import { AuthHeader } from "../../helper/auth.token";
import { Row, Col, message, Divider } from 'antd';
import { Map, View, Feature, Overlay } from "ol";
import { Projection } from 'ol/proj.js'
import TileLayer from "ol/layer/Tile";
import TileImage from 'ol/source/TileImage';
import TileGrid from 'ol/tilegrid/TileGrid';
import { ScaleLine } from 'ol/control.js';
import { MouseWheelZoom, defaults } from 'ol/interaction';
import { getPrefixedUrl, fullColorHex, getPosString, hsvToRgb } from '../../utils/utils';
import { tileViewerPlotColors, tileViewerMinZoomIncrement } from '../../utils/const';
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Stroke, Fill, Style, Text as OlText } from "ol/style";
import Polygon from "ol/geom/Polygon";
import { AnnotationsConstants } from "../../utils/const";
import Circle from "ol/geom/Circle";
import LineString from "ol/geom/LineString";

const annoColor = AnnotationsConstants.DEFAULT_ANNO_COLOR;

const baseText = new OlText({
    font: 'bold 15px "Open Sans", "Helvetica", "sans-serif"',
    placement: AnnotationsConstants.LINE,
    textBaseline: 'top',
    overflow: true,
    fill: new Fill({
        color: annoColor
    }), 
    backgroundFill: new Fill({
        color: "#ffffff"
    }), 
    backgroundStroke: new Fill({
        color: "#ffffff"
    })
})

const baseStyle = new Style({
    stroke: new Stroke({
        color: annoColor,
        width: 2
    }),
    fill: new Fill({
        color: 'rgba(255, 0, 0, 0.0)'
    })
});

const otherStyle = new Style({
    stroke: new Stroke({
        color: "white", 
        width: 1
    })
})

const styleFunction = (feature) => {
    let baseStyleWithText = baseStyle;
    if (feature.get('title') === undefined) {
        baseText.setText("");
    } else {
        baseText.setText(feature.get('title').toString());
    }
    let strokeWidth = feature.getProperties().strokeWidth !== undefined ? feature.getProperties().strokeWidth : 2;
    if (feature.getProperties().color !== undefined && feature.getProperties().color !== "") {
        baseStyleWithText.getStroke().setColor(feature.getProperties().color);
        baseStyleWithText.getStroke().setWidth(strokeWidth);
        if (feature.getProperties().fill) {
            baseStyleWithText.getFill().setColor(feature.getProperties().color);
        } else {
            baseStyleWithText.getFill().setColor('rgba(255, 0, 0, 0.0)');
        }
        baseText.getFill().setColor(feature.getProperties().color);
    }
    baseStyleWithText.setText(baseText);
    return [
        otherStyle, baseStyleWithText
    ]
}

export const getSlideAndInitialiseMapState = (value, setState, urlState, updateUrlState, onMouseMove, updateOverlayText) => {
    setState({
        isFetching: true
    })
    let slideUrl = `/api/slide/${value}/`;
    let slideMetaUrl = `/api/get_meta_data_tile_viewer/?id=${value}`;

    let headers = {
        headers: {
            Authorization: AuthHeader()
        }
    };

    let slideDataRequest = axios.get(slideUrl, headers);
    let slideMetaRequest = axios.get(slideMetaUrl, headers);

    axios.all([
        slideDataRequest,
        slideMetaRequest
    ])
        .then(axios.spread((slideDataResponse, slideMetaResponse) => {

            if (slideMetaResponse.status === 200) {
                plotScanpath(slideMetaResponse.data.islands_meta, setState);
                getTXTYInfo(slideMetaResponse.data, setState);
                setState({
                    tileViewerSlideMeta: slideMetaResponse.data,
                    islandData: slideMetaResponse.data.islands_meta,
                });
            }

            if (slideDataResponse.status === 200) {
                initialiseSlideMap(slideDataResponse.data, setState, urlState, updateUrlState, onMouseMove, updateOverlayText);
            } else {
                setState({
                    isErrored: true,
                    isFetching: false,
                    errMessage: slideDataResponse.data
                });
            }
        }))
        .catch((err) => {
            console.log("Error occured", err)
            message.error("Slide could not be found. Contact Admin.");
            console.log(err)
            setState({
                isErrored: true,
                isFetching: false,
                errMessage: err,
            });
        });
}

const plotScanpath = (data, setState) => {
    let motorToCombMap = [];
    for (let i = 0; i < data.length; i++) {
        for(let ci = 0; ci < (data[i].combList || []).length; ci++) {

            let comb = data[i].combList[ci];

            for(let fi = 0; fi < comb.fieldList.length; fi++) {
                let ele = comb.fieldList[fi];

                if (ele.blank) continue;

                let motorX = ele.position.xPosition;
                let motorY = ele.position.yPosition;
                
                motorToCombMap[motorX + "_" + motorY] = {i, ci};
            }
        }
    }
    setState({
        motorToCombMap,
    });
}

const getTXTYInfo = (metaData, setState) => {
    let txtyMetaData = [metaData.scan_meta_0_txty, metaData.scan_meta_1_txty, metaData.scan_meta_2_txty, metaData.scan_meta_3_txty];
    let txtyInfo = {};
    for(let i = 0; i < 4; i++) {
        let data = txtyMetaData[i];
        for(let imgInfo in data["img_infos"]) {
            let x = parseInt(imgInfo.split('y')[0].split('x')[1]);
            let y = parseInt(imgInfo.split('y')[1].split('.' + metaData.file_type)[0]);
            txtyInfo[x + "_" + y] = {};
            txtyInfo[x + "_" + y]["NORTH"] = data["img_infos"][imgInfo]["NORTH"]["ty"] + "_" + data["img_infos"][imgInfo]["NORTH"]["tx"];
            txtyInfo[x + "_" + y]["WEST"] = data["img_infos"][imgInfo]["WEST"]["tx"] + "_" + data["img_infos"][imgInfo]["WEST"]["ty"];
        }
    }
    setState({
        txtyInfo,
    });
}

const initialiseSlideMap = (slide_data, setState, urlState, updateUrlState, onMouseMove, updateOverlayText) => {

    let tileSize = [slide_data.tile_width, slide_data.tile_height];

    let urlSuffix = `${slide_data.path}tiled_raw/{z}/x{x}y{y}.` + slide_data.img_type;
    let rescanUrlSuffix = `${slide_data.path}tiled_pre_rescan/{z}/x{x}y{y}.` + slide_data.img_type;
    let enhUrlSuffix = `${slide_data.path}tiled_pre_enh/{z}/x{x}y{y}.` + slide_data.img_type;

    let zoomLevels = getZoomLevels(slide_data);
    let resolutions = getResolutions(slide_data, zoomLevels);
    let imageShape = getImageShape(slide_data);
    let projection = getProjection(imageShape);
    let view = getView(projection, resolutions, urlState, imageShape, slide_data.viewer_rotation, urlState);
    let url = getPrefixedUrl(urlSuffix, slide_data);
    let layer = getLayer(tileSize, projection, resolutions, url);
    let slidemap = getMap(view, layer, true);
    let maxZoom = zoomLevels.length - 1 + tileViewerMinZoomIncrement;
    let zoomScale = getZoomScale(slide_data, maxZoom);
    slidemap.addControl(
        new ScaleLine({
            units: "metric",
            minWidth: 100,
            // className: "map-scale"
        })
    );

    slidemap.addEventListener('moveend', updateUrlState, false);

    slidemap.addEventListener('pointermove', onMouseMove, false);
    
    // add rescan layer
    let rescanUrl = getPrefixedUrl(rescanUrlSuffix, slide_data);
    let rescanLayer = getLayer(tileSize, projection, resolutions, rescanUrl);
    slidemap.addLayer(rescanLayer);

    // add enh layer
    let enhUrl = getPrefixedUrl(enhUrlSuffix, slide_data);
    let enhLayer = getLayer(tileSize, projection, resolutions, enhUrl);
    slidemap.addLayer(enhLayer);

    // Setting up path plot
    let pathPlotSource = getNewVectorSource();
    let pathPlotLayer = getPlotVectorLayer(pathPlotSource, projection);
    setPlotLayer(pathPlotLayer, slide_data, slidemap, true);

    // Setting up dynamic focus points plot
    let dynamicFocusPlotSource = getNewVectorSource();
    let dynamicFocusPlotLayer = getPlotVectorLayer(dynamicFocusPlotSource, projection);
    setPlotLayer(dynamicFocusPlotLayer, slide_data, slidemap, false);

    // Setting up FFT Heatmap plot
    let FFTHeatmapPlotSource = getNewVectorSource();
    let FFTHeatmapPlotLayer = getPlotVectorLayer(FFTHeatmapPlotSource, projection);
    setPlotLayer(FFTHeatmapPlotLayer, slide_data, slidemap, false);

    // Setting up FFT Recolored plot
    let FFTRecoloredPlotSource = getNewVectorSource();
    let FFTRecoloredPlotLayer = getPlotVectorLayer(FFTRecoloredPlotSource, projection);
    setPlotLayer(FFTRecoloredPlotLayer, slide_data, slidemap, false);

    // Setting up TXTY Info plot
    let txtyInfoPlotSource = getNewVectorSource();
    let txtyInfoPlotLayer = getPlotVectorLayer(txtyInfoPlotSource, projection);
    setPlotLayer(txtyInfoPlotLayer, slide_data, slidemap, false);

    // Setting up TXTY Text Info plot
    let txtyTextInfoPlotSource = getNewVectorSource();
    let txtyTextInfoPlotLayer = getPlotVectorLayer(txtyTextInfoPlotSource, projection);
    setPlotLayer(txtyTextInfoPlotLayer, slide_data, slidemap, false);
    
    // Setting up Stitching Disagreement plot
    let stitchingDisagreementPlotSource = getNewVectorSource();
    let stitchingDisagreementPlotLayer = getPlotVectorLayer(stitchingDisagreementPlotSource, projection);
    setPlotLayer(stitchingDisagreementPlotLayer, slide_data, slidemap, false);

    // Setting up FFT FourD plot
    let fftFourDPlotSource = getNewVectorSource();
    let fftFourDPlotLayer = getPlotVectorLayer(fftFourDPlotSource, projection);
    setPlotLayer(fftFourDPlotLayer, slide_data, slidemap, false);

    // Setting up FFT FourD Text plot
    let fftFourDTextPlotSource = getNewVectorSource();
    let fftFourDTextPlotLayer = getPlotVectorLayer(fftFourDTextPlotSource, projection);
    setPlotLayer(fftFourDTextPlotLayer, slide_data, slidemap, false);

    // Setting up Ratio FFT FourD plot
    let ratioFFTFourDPlotSource = getNewVectorSource();
    let ratioFFTFourDPlotLayer = getPlotVectorLayer(ratioFFTFourDPlotSource, projection);
    setPlotLayer(ratioFFTFourDPlotLayer, slide_data, slidemap, false);

    let ratioFFTFourDTextPlotSource = getNewVectorSource();
    let ratioFFTFourDTextPlotLayer = getPlotVectorLayer(ratioFFTFourDTextPlotSource, projection);
    setPlotLayer(ratioFFTFourDTextPlotLayer, slide_data, slidemap, false);

    // Setting up Focus FourD plot
    let focusFourDPlotSource = getNewVectorSource();
    let focusFourDPlotLayer = getPlotVectorLayer(focusFourDPlotSource, projection);
    setPlotLayer(focusFourDPlotLayer, slide_data, slidemap, false);

    // Setting up Sparse FFT Heatmap plot
    let sparseFFTHeatmapPlotSource = getNewVectorSource();
    let sparseFFTHeatmapPlotLayer = getPlotVectorLayer(sparseFFTHeatmapPlotSource, projection);
    setPlotLayer(sparseFFTHeatmapPlotLayer, slide_data, slidemap, false);

    // Setting up Stitch Info plot
    let stitchInfoPlotSource = getNewVectorSource();
    let stitchInfoPlotLayer = getPlotVectorLayer(stitchInfoPlotSource, projection);
    setPlotLayer(stitchInfoPlotLayer, slide_data, slidemap, false);

    // Setting up Repair Info plot
    let repairInfoPlotSource = getNewVectorSource();
    let repairInfoPlotLayer = getPlotVectorLayer(repairInfoPlotSource, projection);
    setPlotLayer(repairInfoPlotLayer, slide_data, slidemap, false);

    // Setting up Planned Drop Distance plot
    let plannedDropDistancePlotSource = getNewVectorSource();
    let plannedDropDistancePlotLayer = getPlotVectorLayer(plannedDropDistancePlotSource, projection);
    setPlotLayer(plannedDropDistancePlotLayer, slide_data, slidemap, false);

    // Setting up Actual Drop Distance plot
    let actualDropDistancePlotSource = getNewVectorSource();
    let actualDropDistancePlotLayer = getPlotVectorLayer(actualDropDistancePlotSource, projection);
    setPlotLayer(actualDropDistancePlotLayer, slide_data, slidemap, false);

    // Setting up Stitch Disagreements plot
    let stitchDisagreementPlotSource = getNewVectorSource();
    let stitchDisagreementPlotLayer = getPlotVectorLayer(stitchDisagreementPlotSource, projection);
    setPlotLayer(stitchDisagreementPlotLayer, slide_data, slidemap, false);

    // Setting up Stitch Deltas plot
    let stitchDeltasPlotSource = getNewVectorSource();
    let stitchDeltasPlotLayer = getPlotVectorLayer(stitchDeltasPlotSource, projection);
    setPlotLayer(stitchDeltasPlotLayer, slide_data, slidemap, false);

    // Setting up Stitch Disagreements plot v2
    let stitchDisagreementv2PlotSource = getNewVectorSource();
    let stitchDisagreementv2PlotLayer = getPlotVectorLayer(stitchDisagreementv2PlotSource, projection);
    setPlotLayer(stitchDisagreementv2PlotLayer, slide_data, slidemap, false);

    // Setting up Stitch Disagreements text plot v2
    let stitchDisagreementTextv2PlotSource = getNewVectorSource();
    let stitchDisagreementTextv2PlotLayer = getPlotVectorLayer(stitchDisagreementTextv2PlotSource, projection);
    setPlotLayer(stitchDisagreementTextv2PlotLayer, slide_data, slidemap, false);

    // Setting up Stitching v2 Round0 Debug info (DOUBT_LEVEL_STITCHING)
    let stitchPreRound0Disagreementv2PlotSource = getNewVectorSource();
    let stitchPreRound0Disagreementv2PlotLayer = getPlotVectorLayer(stitchPreRound0Disagreementv2PlotSource, projection);
    setPlotLayer(stitchPreRound0Disagreementv2PlotLayer, slide_data, slidemap, false);
    let stitchPostRound0Disagreementv2PlotSource = getNewVectorSource();
    let stitchPostRound0Disagreementv2PlotLayer = getPlotVectorLayer(stitchPostRound0Disagreementv2PlotSource, projection);
    setPlotLayer(stitchPostRound0Disagreementv2PlotLayer, slide_data, slidemap, false);
    let stitchRound0TileMotionv2PlotSource = getNewVectorSource();
    let stitchRound0TileMotionv2PlotLayer = getPlotVectorLayer(stitchRound0TileMotionv2PlotSource, projection);
    setPlotLayer(stitchRound0TileMotionv2PlotLayer, slide_data, slidemap, false);
    let stitchRound0Graphv2PlotSource = getNewVectorSource();
    let stitchRound0Graphv2PlotLayer = getPlotVectorLayer(stitchRound0Graphv2PlotSource, projection);
    setPlotLayer(stitchRound0Graphv2PlotLayer, slide_data, slidemap, false);

    // Setting up Stitching v2 Round1 Debug info (DOUBT_LEVEL_STITCHING)
    let stitchPreRound1Disagreementv2PlotSource = getNewVectorSource();
    let stitchPreRound1Disagreementv2PlotLayer = getPlotVectorLayer(stitchPreRound1Disagreementv2PlotSource, projection);
    setPlotLayer(stitchPreRound1Disagreementv2PlotLayer, slide_data, slidemap, false);
    let stitchPostRound1Disagreementv2PlotSource = getNewVectorSource();
    let stitchPostRound1Disagreementv2PlotLayer = getPlotVectorLayer(stitchPostRound1Disagreementv2PlotSource, projection);
    setPlotLayer(stitchPostRound1Disagreementv2PlotLayer, slide_data, slidemap, false);
    let stitchRound1TileMotionv2PlotSource = getNewVectorSource();
    let stitchRound1TileMotionv2PlotLayer = getPlotVectorLayer(stitchRound1TileMotionv2PlotSource, projection);
    setPlotLayer(stitchRound1TileMotionv2PlotLayer, slide_data, slidemap, false);
    let stitchRound1Graphv2PlotSource = getNewVectorSource();
    let stitchRound1Graphv2PlotLayer = getPlotVectorLayer(stitchRound1Graphv2PlotSource, projection);
    setPlotLayer(stitchRound1Graphv2PlotLayer, slide_data, slidemap, false);

    // Setting up Stitching v2 Round2 Debug info (DOUBT_LEVEL_STITCHING)
    let stitchPreRound2Disagreementv2PlotSource = getNewVectorSource();
    let stitchPreRound2Disagreementv2PlotLayer = getPlotVectorLayer(stitchPreRound2Disagreementv2PlotSource, projection);
    setPlotLayer(stitchPreRound2Disagreementv2PlotLayer, slide_data, slidemap, false);
    let stitchPostRound2Disagreementv2PlotSource = getNewVectorSource();
    let stitchPostRound2Disagreementv2PlotLayer = getPlotVectorLayer(stitchPostRound2Disagreementv2PlotSource, projection);
    setPlotLayer(stitchPostRound2Disagreementv2PlotLayer, slide_data, slidemap, false);
    let stitchRound2TileMotionv2PlotSource = getNewVectorSource();
    let stitchRound2TileMotionv2PlotLayer = getPlotVectorLayer(stitchRound2TileMotionv2PlotSource, projection);
    setPlotLayer(stitchRound2TileMotionv2PlotLayer, slide_data, slidemap, false);
    let stitchRound2Graphv2PlotSource = getNewVectorSource();
    let stitchRound2Graphv2PlotLayer = getPlotVectorLayer(stitchRound2Graphv2PlotSource, projection);
    setPlotLayer(stitchRound2Graphv2PlotLayer, slide_data, slidemap, false);

    // Setting up Stitching v2 Round3 Debug info (DOUBT_LEVEL_STITCHING)
    let stitchPreRound3Disagreementv2PlotSource = getNewVectorSource();
    let stitchPreRound3Disagreementv2PlotLayer = getPlotVectorLayer(stitchPreRound3Disagreementv2PlotSource, projection);
    setPlotLayer(stitchPreRound3Disagreementv2PlotLayer, slide_data, slidemap, false);
    let stitchPostRound3Disagreementv2PlotSource = getNewVectorSource();
    let stitchPostRound3Disagreementv2PlotLayer = getPlotVectorLayer(stitchPostRound3Disagreementv2PlotSource, projection);
    setPlotLayer(stitchPostRound3Disagreementv2PlotLayer, slide_data, slidemap, false);
    let stitchRound3TileMotionv2PlotSource = getNewVectorSource();
    let stitchRound3TileMotionv2PlotLayer = getPlotVectorLayer(stitchRound3TileMotionv2PlotSource, projection);
    setPlotLayer(stitchRound3TileMotionv2PlotLayer, slide_data, slidemap, false);
    let stitchRound3Graphv2PlotSource = getNewVectorSource();
    let stitchRound3Graphv2PlotLayer = getPlotVectorLayer(stitchRound3Graphv2PlotSource, projection);
    setPlotLayer(stitchRound3Graphv2PlotLayer, slide_data, slidemap, false);

    // Setting up Stitch Graph plot v2
    let stitchGraphv2PlotSource = getNewVectorSource();
    let stitchGraphv2PlotLayer = getPlotVectorLayer(stitchGraphv2PlotSource, projection);
    setPlotLayer(stitchGraphv2PlotLayer, slide_data, slidemap, false);

    // Setting up Stitch Graph text plot v2
    let stitchGraphTextv2PlotSource = getNewVectorSource();
    let stitchGraphTextv2PlotLayer = getPlotVectorLayer(stitchGraphTextv2PlotSource, projection);
    setPlotLayer(stitchGraphTextv2PlotLayer, slide_data, slidemap, false);

    // Setting up Streak plot
    let streakPlotSource = getNewVectorSource();
    let streakPlotLayer = getPlotVectorLayer(streakPlotSource, projection);
    setPlotLayer(streakPlotLayer, slide_data, slidemap, true);

    // Setting up Focus Profile plot
    let focusProfilePlotSource = getNewVectorSource();
    let focusProfilePlotLayer = getPlotVectorLayer(focusProfilePlotSource, projection);
    setPlotLayer(focusProfilePlotLayer, slide_data, slidemap, false);

    // Setting up Focus Points plot
    let focusPointsPlotSource = getNewVectorSource();
    let focusPointsPlotLayer = getPlotVectorLayer(focusPointsPlotSource, projection);
    setPlotLayer(focusPointsPlotLayer, slide_data, slidemap, false);

    // Setting up FFT Fine plot
    let fftFinePlotSource = getNewVectorSource();
    let fftFinePlotLayer = getPlotVectorLayer(fftFinePlotSource, projection);
    setPlotLayer(fftFinePlotLayer, slide_data, slidemap, false);

    // Setting up FFT Fine Text plot
    let fftFineTextPlotSource = getNewVectorSource();
    let fftFineTextPlotLayer = getPlotVectorLayer(fftFineTextPlotSource, projection);
    setPlotLayer(fftFineTextPlotLayer, slide_data, slidemap, false);

    // Setting up Focus Triangles plot
    let focusTrianglesPlotSource = getNewVectorSource();
    let focusTrianglesPlotLayer = getPlotVectorLayer(focusTrianglesPlotSource, projection);
    setPlotLayer(focusTrianglesPlotLayer, slide_data, slidemap, false);

    // Setting up Focus Filter Triangles plot
    let focusFilterTrianglesPlotSource = getNewVectorSource();
    let focusFilterTrianglesPlotLayer = getPlotVectorLayer(focusFilterTrianglesPlotSource, projection);
    setPlotLayer(focusFilterTrianglesPlotLayer, slide_data, slidemap, false);

    // Setting up Planned vs Actual plot
    let plannedVsActualFocusPlotSource = getNewVectorSource();
    let plannedVsActualFocusPlotLayer = getPlotVectorLayer(plannedVsActualFocusPlotSource, projection);
    setPlotLayer(plannedVsActualFocusPlotLayer, slide_data, slidemap, false);

    // Setting up Planned vs Golden plot
    let plannedVsGoldenFocusPlotSource = getNewVectorSource();
    let plannedVsGoldenFocusPlotLayer = getPlotVectorLayer(plannedVsGoldenFocusPlotSource, projection);
    setPlotLayer(plannedVsGoldenFocusPlotLayer, slide_data, slidemap, false);

    // Setting up Planned vs Actual Down plot
    let plannedVsActualFocusDownPlotSource = getNewVectorSource();
    let plannedVsActualFocusDownPlotLayer = getPlotVectorLayer(plannedVsActualFocusDownPlotSource, projection);
    setPlotLayer(plannedVsActualFocusDownPlotLayer, slide_data, slidemap, false);

    // Setting up Planned vs Golden Down plot
    let plannedVsGoldenFocusDownPlotSource = getNewVectorSource();
    let plannedVsGoldenFocusDownPlotLayer = getPlotVectorLayer(plannedVsGoldenFocusDownPlotSource, projection);
    setPlotLayer(plannedVsGoldenFocusDownPlotLayer, slide_data, slidemap, false);

    // Setting up Islands plot
    let islandsPlotSource = getNewVectorSource();
    let islandsPlotLayer = getPlotVectorLayer(islandsPlotSource, projection);
    setPlotLayer(islandsPlotLayer, slide_data, slidemap, false);

    // Setting up Islands plot
    let rescanIslandsPlotSource = getNewVectorSource();
    let rescanIslandsPlotLayer = getPlotVectorLayer(rescanIslandsPlotSource, projection);
    setPlotLayer(rescanIslandsPlotLayer, slide_data, slidemap, false);

    // Setting up tilt focus pts plot
    let tiltFocusPointsPlotSource = getNewVectorSource();
    let tiltFocusPointsPlotLayer = getPlotVectorLayer(tiltFocusPointsPlotSource, projection);
    setPlotLayer(tiltFocusPointsPlotLayer, slide_data, slidemap, false);

    // Setting up county tilt info plot (2dot8 change)
    let countyTiltInfoPlotSource = getNewVectorSource();
    let countyTiltInfoPlotLayer = getPlotVectorLayer(countyTiltInfoPlotSource, projection);
    setPlotLayer(countyTiltInfoPlotLayer, slide_data, slidemap, false);
   
    let overlay = new Overlay({
        autoPan: true,
        autoPanAnimation: {
            duration: 250
        }
    });
    slidemap.addOverlay(overlay);

    slidemap.on('singleclick', updateOverlayText);

    setState({
        isFetching: false,
        slide_data,
        maxZoom,
        zoomLevels,
        zoomScale,
        projection,
        view,
        layer,
        rescanLayer, 
        enhLayer,
        slidemap,
        overlay,
        pathPlotSource,
        pathPlotLayer,
        dynamicFocusPlotSource,
        dynamicFocusPlotLayer,
        FFTHeatmapPlotSource,
        FFTHeatmapPlotLayer,
        FFTRecoloredPlotSource,
        FFTRecoloredPlotLayer,
        txtyInfoPlotSource,
        stitchingDisagreementPlotSource,
        txtyInfoPlotLayer,
        txtyTextInfoPlotSource,
        txtyTextInfoPlotLayer,
        fftFourDPlotSource,
        fftFourDPlotLayer,
        ratioFFTFourDPlotSource,
        ratioFFTFourDPlotLayer,
        ratioFFTFourDTextPlotSource,
        ratioFFTFourDTextPlotLayer,
        focusFourDPlotSource,
        focusFourDPlotLayer,
        sparseFFTHeatmapPlotSource,
        sparseFFTHeatmapPlotLayer,
        stitchInfoPlotSource,
        stitchInfoPlotLayer,
        repairInfoPlotSource,
        repairInfoPlotLayer,
        plannedDropDistancePlotSource,
        plannedDropDistancePlotLayer,
        actualDropDistancePlotSource,
        actualDropDistancePlotLayer,
        stitchDisagreementPlotSource,
        stitchDisagreementPlotLayer,
        stitchDeltasPlotSource,
        stitchDeltasPlotLayer,
        stitchDisagreementv2PlotSource,
        stitchDisagreementv2PlotLayer,
        stitchDisagreementTextv2PlotSource,
        stitchDisagreementTextv2PlotLayer,
        
        stitchPreRound0Disagreementv2PlotSource,
        stitchPreRound0Disagreementv2PlotLayer,
        stitchPostRound0Disagreementv2PlotSource,
        stitchPostRound0Disagreementv2PlotLayer,
        stitchRound0TileMotionv2PlotSource,
        stitchRound0TileMotionv2PlotLayer,
        stitchRound0Graphv2PlotSource,
        stitchRound0Graphv2PlotLayer,
        
        stitchPreRound1Disagreementv2PlotSource,
        stitchPreRound1Disagreementv2PlotLayer,
        stitchPostRound1Disagreementv2PlotSource,
        stitchPostRound1Disagreementv2PlotLayer,
        stitchRound1TileMotionv2PlotSource,
        stitchRound1TileMotionv2PlotLayer,
        stitchRound1Graphv2PlotSource,
        stitchRound1Graphv2PlotLayer,
        
        stitchPreRound2Disagreementv2PlotSource,
        stitchPreRound2Disagreementv2PlotLayer,
        stitchPostRound2Disagreementv2PlotSource,
        stitchPostRound2Disagreementv2PlotLayer,
        stitchRound2TileMotionv2PlotSource,
        stitchRound2TileMotionv2PlotLayer,
        stitchRound2Graphv2PlotSource,
        stitchRound2Graphv2PlotLayer,
        
        stitchPreRound3Disagreementv2PlotSource,
        stitchPreRound3Disagreementv2PlotLayer,
        stitchPostRound3Disagreementv2PlotSource,
        stitchPostRound3Disagreementv2PlotLayer,
        stitchRound3TileMotionv2PlotSource,
        stitchRound3TileMotionv2PlotLayer,
        stitchRound3Graphv2PlotSource,
        stitchRound3Graphv2PlotLayer,
        
        stitchGraphv2PlotSource,
        stitchGraphv2PlotLayer,
        stitchGraphTextv2PlotSource,
        stitchGraphTextv2PlotLayer,
        streakPlotSource,
        streakPlotLayer,
        focusProfilePlotSource,
        focusProfilePlotLayer,
        focusPointsPlotSource,
        focusPointsPlotLayer,
        fftFourDTextPlotSource,
        fftFourDTextPlotLayer,
        fftFinePlotSource,
        fftFinePlotLayer,
        fftFineTextPlotSource,
        fftFineTextPlotLayer,
        focusTrianglesPlotSource,
        focusTrianglesPlotLayer,
        focusFilterTrianglesPlotSource,
        focusFilterTrianglesPlotLayer,
        plannedVsActualFocusPlotSource,
        plannedVsActualFocusPlotLayer,
        plannedVsGoldenFocusPlotSource,
        plannedVsGoldenFocusPlotLayer,
        plannedVsActualFocusDownPlotSource,
        plannedVsActualFocusDownPlotLayer,
        plannedVsGoldenFocusDownPlotSource,
        plannedVsGoldenFocusDownPlotLayer,
        islandsPlotSource,
        islandsPlotLayer,
        rescanIslandsPlotSource,
        rescanIslandsPlotLayer,
        tiltFocusPointsPlotSource,
        tiltFocusPointsPlotLayer,
        countyTiltInfoPlotSource,
        countyTiltInfoPlotLayer
    });
}

const getNewVectorSource = () => {
    return new VectorSource({});
}

const getPlotVectorLayer = (source, projection) => {
    return new VectorLayer({
        source: source,
        extent: projection.getExtent(),
    });
}

const setPlotLayer = (pathPlotLayer, slide_data, slidemap, visible) => {
    pathPlotLayer.setStyle(styleFunction);

    let zIndex = (slide_data.x_fields * 100000) + slide_data.y_fields;
    pathPlotLayer.setZIndex(zIndex);
    slidemap.addLayer(pathPlotLayer);

    pathPlotLayer.setVisible(visible);
}

const getImageShape = (slide_data) => {
    let imageWidth, imageHeight;

    imageWidth = slide_data.uperpixel * slide_data.tile_width * slide_data.x_fields;
    imageHeight = slide_data.uperpixel * slide_data.tile_height * slide_data.y_fields;

    return [imageWidth, imageHeight];
}

export const getProjection = (imageShape) => {

    return new Projection({
        code: 'MORPHLE',
        units: 'microns',
        extent: [0, 0, imageShape[0], imageShape[1]],
        metersPerUnit: 0.000001,
        global: true,
        getPointResolution: (resolution, point) => resolution,
    });
}

export const getView = (projection, resolutions, initStateWith, imageShape, viewer_rotation) => {

    viewer_rotation = 0;

    let maxZoom = initStateWith.digitalZoomStatus ? (resolutions.length - 1) : (resolutions.length - 2);
    return new View({
        projection: projection,
        extent: projection.getExtent(),
        center: initStateWith.x === -1 ? imageShape : [initStateWith.x, initStateWith.y],
        zoom: initStateWith.z,
        maxResolution: resolutions[0] * (Math.pow(2, tileViewerMinZoomIncrement)),
        maxZoom: maxZoom + tileViewerMinZoomIncrement,
        rotation: initStateWith.r !== -1 ? initStateWith.r : ((viewer_rotation * Math.PI) / 180)
    });
}

export const getLayer = (tileSize, projection, resolutions, url) => {

    return new TileLayer({
        extent: projection.getExtent(),
        renderMode: "vector",
        source: new TileImage({
            tileGrid: new TileGrid({
                extent: projection.getExtent(),
                origin: [0, projection.getExtent()[3]],
                resolutions: resolutions,
                tileSize: tileSize,
            }),
            projection: projection,
            url: url,
            wrapX: false,
            crossOrigin: 'anonymous'
        }),
    });
}

export const getMap = (view, layer, setKeyboardInteractions) => {

    return new Map({
        controls: [],
        interactions: defaults({ mouseWheelZoom: false }).extend([
            new MouseWheelZoom({
                constrainResolution: true // force zooming to a integer zoom
            })
        ]),
        target: null,
        layers: [
            layer,
        ],
        view: view,
        keyboardEventTarget: setKeyboardInteractions ? document : null,
        loadTilesWhileAnimating: true,
        loadTilesWhileInteracting: true
    });
}

const getZoomLevels = (slide_data) => {

    return slide_data.tiling_z_levels.split(",");
}

export const getResolutions = (slide_data, zoomLevels) => {

    let resolutions = [];
    (zoomLevels).forEach((level) => {
        resolutions.push(slide_data.uperpixel * Math.pow(2, parseInt(level)));
    });

    resolutions = resolutions.reverse();

    return resolutions;
}

export const getZoomScale = (slide_data, max_zoom) => {
    var scale = [];
    let max = (0) + parseInt(max_zoom);
    let i = max - 1;
    if (slide_data.objective_type === "hundred_x") {
        scale[max] = 200;
    } else if (slide_data.objective_type === "twenty_x") {
        scale[max] = 40;
    } else if (slide_data.objective_type === "sixty_x") {
        scale[max] = 120;
    } else {
        scale[max] = 80;
    }
    for (i; i > -1; i--) {
        scale[i] = parseFloat((scale[i + 1] / 2).toFixed(1));
    }
    return scale;
}

export const plotComb = (comb, slideData, plotCombOnLayer, combBoundaryColor, slideMeta, ci) => {
    for(let fi = 0; fi < comb.fieldList.length; fi++) {
        let ele = comb.fieldList[fi];

        let motorX = ele.position.xPosition;
        let motorY = ele.position.yPosition;

        if (motorX === 0 && motorY === 0) continue;

        let imagePositions = mapMotorToCanvas(motorX, motorY, slideData, slideMeta);
        let xImage = imagePositions.x;
        let yImage = imagePositions.y;
        
        if (ele.startOfStrip) {
            drawCircle(xImage, yImage, 0.5 * slideData.tile_height * slideData.uperpixel, plotCombOnLayer, "", 2, tileViewerPlotColors.startOfStripColor);
        } else if (ele.endOfStrip) {
            drawCircle(xImage, yImage, 0.5 * slideData.tile_height * slideData.uperpixel, plotCombOnLayer, "", 2, tileViewerPlotColors.endOfStripColor);
        }
        if (ele.startOfSubpath) {
            drawCircle(xImage, yImage, 1 * slideData.tile_height * slideData.uperpixel, plotCombOnLayer, "", 2, tileViewerPlotColors.startOfSubpathColor, "Comb " + ci);
        } else if(ele.endOfSubpath) {
            drawCircle(xImage, yImage, 1 * slideData.tile_height * slideData.uperpixel, plotCombOnLayer, "", 2, tileViewerPlotColors.endOfSubpathColor);
        }
    }

    var startX = comb.combBoundaryStart.xPosition;
    var startY = comb.combBoundaryStart.yPosition;
    var endX = comb.combBoundaryEnd.xPosition;
    var endY = comb.combBoundaryEnd.yPosition;
    drawRectangle(mapMotorRectToCanvasRect(startX, startY, endX, endY, slideData, slideMeta),
        plotCombOnLayer, false, 2, combBoundaryColor);
}

export const drawDynamicFocusPoints = (slideMetaData, slideData, vectorSource) => {
    let data = slideMetaData.dynamic_focus;
    data.map(function(ele){
        let canvasPositions = mapMotorToCanvas(ele.xPosition, ele.yPosition, slideData, slideMetaData);
        let x = canvasPositions.x;
        let y = canvasPositions.y;
        drawCircle(x, y, 0.5 * slideData.tile_height * slideData.uperpixel, vectorSource, "", 2, tileViewerPlotColors.actualFocusColor);
    });
}

export const plotRescanBlurInfo = (slidemap, slideMeta, slideData, projection, setState) => {
    let swapVectorSource = getNewVectorSource();
    let swapVectorLayer = getPlotVectorLayer(swapVectorSource, projection);
    slidemap.addLayer(swapVectorLayer);

    let rescanLayers = [[], [], []];

    let bigBlurPath = slideMeta.big_blur_retakes;
    let bigBlurFocusPath = slideMeta.big_blur_focus;
    let smallBlurRetakes = slideMeta.small_blur_retakes;
    let swapMeta = [slideMeta.swap_meta_0, slideMeta.swap_meta_1, slideMeta.swap_meta_2, slideMeta.swap_meta_3];

    let bigBlurFocusPathSizes = [0.5, 0.8, 1.0];
    let bigBlurPathSizes = [2.0, 2.5, 3.0];
    let smallBlurRetakesSizes = [1.2, 1.5, 1.8];
    let sizeOfMarker = 0.3;

    for (let i = 0; i < 3; i++) {
        if (i + 1 < bigBlurFocusPath.length) {
            rescanLayers[i].push(plotSquareForPosition2DArraylist(bigBlurFocusPath[i + 1], slideData, slideMeta, slidemap, projection, "#80ff00", bigBlurFocusPathSizes[i + 1], false));
        }
        
        if (bigBlurPath[0][0] !== undefined) {
            if (bigBlurPath[0][0].hasOwnProperty('xPosition')) {
                rescanLayers[i].push(plotSquareForPosition2DArraylist(bigBlurPath[i], slideData, slideMeta, slidemap, projection, "#130f40", bigBlurPathSizes[i], false));
            } else {
                rescanLayers[i].push(plotRescanGroup(bigBlurPath[i], slideData, slidemap, projection, slideMeta, "#130f40", bigBlurPathSizes[i], false));
            }
        }

        if (i < smallBlurRetakes.length) {
            if (smallBlurRetakes[0].constructor === Array) {
                rescanLayers[i].push(plotSquareForPosition2DArraylist(smallBlurRetakes[i], slideData, slideMeta, slidemap, projection, "red", smallBlurRetakesSizes[i], false));
            } else {
                rescanLayers[i].push(plotSquareForPosition2DArraylist(smallBlurRetakes, slideData, slideMeta, slidemap, projection, "red", 1.5, false));
            }
        }

        for (let j = 0; j < 4; j++) {
            let data = swapMeta[j];
            for(let di = 0; di < data.length; di++) {
                let x = parseInt(data[di].split('y')[0].split('x')[1]);
                let y = parseInt(data[di].split('y')[1].split('.' + slideData.img_type)[0]);
                let bounds = [
                    [y - 0.25, x + sizeOfMarker],
                    [y + 0.25, x + sizeOfMarker],
                    [y + 0.25, x + (1 - sizeOfMarker)],
                    [y - 0.25, x + (1 - sizeOfMarker)],
                    [y - 0.25, x + sizeOfMarker]
                ];
                drawRectangle(bounds, swapVectorSource, false, 2, "#f4c842");
            }
        }
    }
    setState({
        rescanLayers,
        swapVectorSource,
        swapVectorLayer,
    });
}

export const plotFFTHeatmapNew = (slideMetaData, slideData, vectorSource) => {
    let data = slideMetaData.post_scan_blur_vals_java;

    let level1 = 0;
    let level2 = 0;
    let rvalues = [], bvalues = [], yvalues = [];
    if (slideData.scan_speed === "FAST") {
        level1 = data["fastl1"];
        level2 = data["fastl2"];
    } else if (slideData.scan_speed === "MEDIUM") {
        level1 = data["mediuml1"];
        level2 = data["mediuml2"];
    }  else if (slideData.scan_speed === "SLOW") {
        level1 = data["slowl1"];
        level2 = data["slowl2"];
    }
    for (let key in data) {
        if (key.includes('old')) continue;
        try {
            if (data[key] < level1) {
                rvalues.push(data[key]);
            } else if (data[key] < level2) {
                bvalues.push(data[key]);
            } else {
                yvalues.push(data[key]);
            }
        } catch {

        }
    }

    let rdmin = Math.min.apply(Math, rvalues);
    let rdmax = Math.max.apply(Math, rvalues);
    let bdmin = Math.min.apply(Math, bvalues);
    let bdmax = Math.max.apply(Math, bvalues);
    let ydmin = Math.min.apply(Math, yvalues);
    let ydmax = Math.max.apply(Math, yvalues);

    let offset = 100;

    for (let key in data) {
        if (key.includes('old')) continue;
        try {
            let motorPos = slideMetaData.raw.motor_mappings[key];
            let Y = parseFloat(motorPos.split("_Y_")[1])
            let X = parseFloat(motorPos.split("_Y_")[0].split("X_")[1])
            let motorData = {}
            motorData.xPosition = X;
            motorData.yPosition = Y;
            let hex;

            if (data[key] < level1) {
                hex = fullColorHex(Math.round(offset + ((255 - offset) * (data[key] - rdmin) / (rdmax - rdmin))), 0, 0);
            } else if (data[key] < level2) {
                hex = fullColorHex(0, 0, Math.round(offset + ((255 - offset) * (data[key] - bdmin) / (bdmax - bdmin))));
            } else {
                hex = fullColorHex(Math.round(offset + ((255 - offset) * (data[key] - ydmin) / (ydmax - ydmin))), Math.round(offset + ((255 - offset) * (data[key] - ydmin) / (ydmax - ydmin))), 0);
            }
            plotSquare(motorData, slideData, slideMetaData, vectorSource, hex, 1.5, 2);
        } catch {

        }
    }
}


export const plotFFTHeatmapNewRecolored = (slideMetaData, slideData, vectorSource) => {
    let data = slideMetaData.post_scan_blur_vals_java;

    let level1 = 0;
    let level2 = 0;
    let rvalues = [], bvalues = [], yvalues = [];
    if (slideData.scan_speed === "FAST") {
        level1 = data["fastl1"];
        level2 = data["fastl2"];
    } else if (slideData.scan_speed === "MEDIUM") {
        level1 = data["mediuml1"];
        level2 = data["mediuml2"];
    }  else if (slideData.scan_speed === "SLOW") {
        level1 = data["slowl1"];
        level2 = data["slowl2"];
    }
    for (let key in data) {
        if (key.includes('old')) continue;
        try {
            if (data[key] < level1) {
                rvalues.push(data[key]);
            } else if (data[key] < level2) {
                bvalues.push(data[key]);
            } else {
                yvalues.push(data[key]);
            }
        } catch {

        }
    }

    let offset = 100;
    let rindex = 0, bindex = 0, yindex = 0;

    for (let key in data) {
        if (key.includes('old')) continue;
        try {
            let motorPos = slideMetaData.raw.motor_mappings[key];
            let Y = parseFloat(motorPos.split("_Y_")[1])
            let X = parseFloat(motorPos.split("_Y_")[0].split("X_")[1])
            let motorData = {}
            motorData.xPosition = X;
            motorData.yPosition = Y;
            let hex;

            if (data[key] < level1) {
                hex = fullColorHex(Math.round(offset + ((255 - offset) * (rindex++/rvalues.length))), 0, 0);
            } else if (data[key] < level2) {
                hex = fullColorHex(0, 0, Math.round(offset + ((255 - offset) * (bindex++/bvalues.length))));
            } else {
                hex = fullColorHex(Math.round(offset + ((255 - offset) * (yindex/yvalues.length))), Math.round(offset + ((255 - offset) * (yindex++/yvalues.length))), 0);
            }
            plotSquare(motorData, slideData, slideMetaData, vectorSource, hex, 1.5, 2);
        } catch {

        }
    }
}

export const plotTXTY = (slideMetaData, slideData, vectorSource) => {

    // let txtyMetaData = [slideMetaData.scan_meta_0_txty, slideMetaData.scan_meta_1_txty, slideMetaData.scan_meta_2_txty, slideMetaData.scan_meta_3_txty];
    let txtyMetaDataNew = [slideMetaData.scan_meta_v2]
    for(let i = 0; i < 1; i++) {
        let size = 3;
        let margin = size / 10;
        let sizeOfMarker = 0.3;
        let data = txtyMetaDataNew[i];
        for(let key in data) {
            // skip images used for gap filling
            if (key.endsWith("_5")) {
                continue;
            }
            let x = parseInt(key.split('y')[0].split('x')[1]);
            let dx = 0;
            let y = parseInt(key.split('y')[1].split('.' + slideData.img_type)[0].split("_")[0]);
            let dy = parseInt(key.split('y')[1].split('.' + slideData.img_type)[0].split("_")[1]);
            plotTXTYUnit(data[key], slideData, x, dx, y, dy, margin, sizeOfMarker, vectorSource);
        }
    }
}

export const plotTXTYText = (slideMetaData, slideData, vectorSource) => {

    // let txtyMetaData = [slideMetaData.scan_meta_0_txty, slideMetaData.scan_meta_1_txty, slideMetaData.scan_meta_2_txty, slideMetaData.scan_meta_3_txty];
    let txtyMetaDataNew = [slideMetaData.scan_meta_v2]
    for(let i = 0; i < 1; i++) {
        let size = 3;
        let margin = size / 10;
        let sizeOfMarker = 0.3;
        let data = txtyMetaDataNew[i];
        for(let key in data) {
            // skip images used for gap filling
            if (key.endsWith("_5")) {
                continue;
            }
            let x = parseInt(key.split('y')[0].split('x')[1]);
            let y = parseInt(key.split('y')[1].split('.' + slideData.img_type)[0]);
            plotTXTYUnitText(data[key], slideData, x, y, margin, sizeOfMarker, vectorSource);
        }
    }
}


export const plotTXTYUnit = (v, slideData, x, dx, y, dy, margin, sizeOfMarker, vectorSource) => {

    if(!isNaN(dy) && dy !== 0)
    {
        console.log("skip -- ", dy);
        return;
    }

    let color = fullColorHex(255, 255, 153);

    y = slideData.y_fields - y - 1;

    // NORTH MARKERS
    let bounds = [
        [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
        [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
        [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y + (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
        [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y + (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
        [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)]
    ];
    if(v["top"] !== undefined){
        if(v["top"]["ty"] === 0 || v["top"]["errMessage"] !== ""){
            color = fullColorHex(255, 0, 0);
            drawRectangle(bounds, vectorSource, true, 2, color);
        }

        if(v["top"]["retryCount"] !== undefined && v["top"]["retryCount"] === 1){
            color = fullColorHex(255, 255, 0);
            drawRectangle(bounds, vectorSource, false, 2, color);
        }
    }
    // drawRectangle(bounds, vectorSource, true, 2, color);
    // if (v["top"] !== undefined){
        // drawLine((x + 0.5) * (slideData.tile_width * slideData.uperpixel), (y + 1) * (slideData.tile_height * slideData.uperpixel), 1, vectorSource, v["top"]["ty"] + "_" + v["top"]["tx"], 1, "#00ffea");
    // }

    // WEST MARKERS
    color = fullColorHex(255, 255, 153);
    bounds = [
        [(x - (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
        [(x - (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
        [(x + (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
        [(x + (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
        [(x - (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)]
    ];
    if(v["left"] !== undefined){
        if(v["left"]["tx"] === 0 || v["left"]["errMessage"] !== ""){
            color = fullColorHex(255, 0, 0);
            drawRectangle(bounds, vectorSource, true, 2, color);
        }

        if(v["left"]["retryCount"] !== undefined && v["left"]["retryCount"] === 1){
            color = fullColorHex(255, 255, 0);
            drawRectangle(bounds, vectorSource, false, 2, color);
        }
    }
    // drawRectangle(bounds, vectorSource, true, 2, color);
    // if (v["left"] !== undefined){
        // drawLine(x * (slideData.tile_width * slideData.uperpixel), (y + 0.5) * (slideData.tile_height * slideData.uperpixel), 1, vectorSource, v["left"]["tx"] + "_" + v["left"]["ty"], 1, "#00ffea");
    // }
}

export const plotTXTYUnitText = (v, slideData, x, y, margin, sizeOfMarker, vectorSource) => {

    y = slideData.y_fields - y - 1;

    // let color = fullColorHex(255, 255, 153);
    // NORTH MARKERS
    // let bounds = [
    //     [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
    //     [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
    //     [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y + (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
    //     [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y + (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
    //     [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)]
    // ];
    // if(v["top"] !== undefined){
    //     if(v["top"]["ty"] === 0){
    //         color = fullColorHex(255, 0, 0);
    //     }
    // }
    // drawRectangle(bounds, vectorSource, true, 2, color);
    if (v["top"] !== undefined){
        drawLine((x + 0.5) * (slideData.tile_width * slideData.uperpixel), (y + 1) * (slideData.tile_height * slideData.uperpixel), 1, vectorSource, v["top"]["ty"] + "_" + v["top"]["tx"], 1, "#00ffea");
    }

    // WEST MARKERS
    // color = fullColorHex(255, 255, 153);
    // bounds = [
    //     [(x - (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
    //     [(x - (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
    //     [(x + (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
    //     [(x + (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
    //     [(x - (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)]
    // ];
    // if(v["left"] !== undefined){
    //     if(v["left"]["tx"] === 0){
    //         color = fullColorHex(255, 0, 0);
    //     }
    // }
    // drawRectangle(bounds, vectorSource, true, 2, color);
    if (v["left"] !== undefined){
        drawLine(x * (slideData.tile_width * slideData.uperpixel), (y + 0.5) * (slideData.tile_height * slideData.uperpixel), 1, vectorSource, v["left"]["tx"] + "_" + v["left"]["ty"], 1, "#00ffea");
    }
}

export const plotFFTFourd = (slideMetaData, slideData, vectorSource) => {
    let dataArray = transformFFTFourDData(slideMetaData.post_scan_pre_norm_blur_vals_java_fourd, slideData);
    let size = 2;
    let margin = size / 10;
    let sizeOfMarker = 0.3;
    for(let fftVals in dataArray[0]) {
        let x = parseInt(fftVals.split('y')[0].split('x')[1]);
        let y = parseInt(fftVals.split('y')[1].split('.' + slideData.img_type)[0]);
        plotFftFourDAreaUnit(dataArray[0][fftVals], slideData, x, y, margin, sizeOfMarker, vectorSource, dataArray[1], dataArray[2]);
    }
}

export const plotFftFourDAreaUnit = (v, slideData, x, y, margin, sizeOfMarker, vectorSource, mindiff, maxdiff) => {

    y = slideData.y_fields - y - 1;

    // NORTH MARKERS
    if(v["NorthDiff"] !== undefined){
        let numCrops = v["NorthDiff"].length;
        for(let crop = 0; crop < numCrops ; crop++){
            let hue = 180 - Math.floor(180 * ((v["NORTH"][crop] - mindiff) / (maxdiff - mindiff)));
            let rgbColor = hsvToRgb(hue / 360, 1, 1);
            let color = fullColorHex(rgbColor.r, rgbColor.g, rgbColor.b);
            let xStart = 0.1 + crop*(0.8/numCrops) + 0.1;
            let xEnd = 0.1 + (crop+1)*(0.8/numCrops) - 0.1;
            let bounds = [
                [(x + xStart) * (slideData.tile_width * slideData.uperpixel), (y + 1) * (slideData.tile_height * slideData.uperpixel)],
                [(x + xEnd) * (slideData.tile_width * slideData.uperpixel), (y + 1) * (slideData.tile_height * slideData.uperpixel)],
                [(x + xEnd) * (slideData.tile_width * slideData.uperpixel), (y - (margin) + 1) * (slideData.tile_height * slideData.uperpixel)],
                [(x + xStart) * (slideData.tile_width * slideData.uperpixel), (y - (margin) + 1) * (slideData.tile_height * slideData.uperpixel)],
                [(x + xStart) * (slideData.tile_width * slideData.uperpixel), (y + 1) * (slideData.tile_height * slideData.uperpixel)]
            ];
            drawRectangle(bounds, vectorSource, true, 2, color);
        }
    }

    // WEST MARKERS

    if(v["WestDiff"] !== undefined){
        let numCrops = v["WestDiff"].length;
        for(let crop = 0; crop < numCrops ; crop++){
            let hue = 180 - Math.floor(180 * ((v["WEST"] - mindiff) / (maxdiff - mindiff)));
            let rgbColor = hsvToRgb(hue / 360, 1, 1);
            let color = fullColorHex(rgbColor.r, rgbColor.g, rgbColor.b);
            let yStart = 0.1 + crop*(0.8/numCrops) + 0.1;
            let yEnd = 0.1 + (crop+1)*(0.8/numCrops) - 0.1;
            let bounds = [
                [(x) * (slideData.tile_width * slideData.uperpixel), (y + yStart) * (slideData.tile_height * slideData.uperpixel)],
                [(x) * (slideData.tile_width * slideData.uperpixel), (y + yEnd) * (slideData.tile_height * slideData.uperpixel)],
                [(x + (margin)) * (slideData.tile_width * slideData.uperpixel), (y + yEnd) * (slideData.tile_height * slideData.uperpixel)],
                [(x + (margin)) * (slideData.tile_width * slideData.uperpixel), (y + yStart) * (slideData.tile_height * slideData.uperpixel)],
                [(x) * (slideData.tile_width * slideData.uperpixel), (y + yStart) * (slideData.tile_height * slideData.uperpixel)]
            ];
            drawRectangle(bounds, vectorSource, true, 2, color);
        }
    }

    // EAST MARKERS

    if(v["EastDiff"] !== undefined){
        let numCrops = v["EastDiff"].length;
        for(let crop = 0; crop < numCrops ; crop++){
            let hue = 180 - Math.floor(180 * ((v["EAST"] - mindiff) / (maxdiff - mindiff)));
            let rgbColor = hsvToRgb(hue / 360, 1, 1);
            let color = fullColorHex(rgbColor.r, rgbColor.g, rgbColor.b);
            let yStart = 0.1 + crop*(0.8/numCrops) + 0.1;
            let yEnd = 0.1 + (crop+1)*(0.8/numCrops) - 0.1;
            let bounds = [
                [(x + 1) * (slideData.tile_width * slideData.uperpixel), (y + yStart) * (slideData.tile_height * slideData.uperpixel)],
                [(x + 1) * (slideData.tile_width * slideData.uperpixel), (y + yEnd) * (slideData.tile_height * slideData.uperpixel)],
                [(x + 1 - (margin)) * (slideData.tile_width * slideData.uperpixel), (y + yEnd) * (slideData.tile_height * slideData.uperpixel)],
                [(x + 1 - (margin)) * (slideData.tile_width * slideData.uperpixel), (y + yStart) * (slideData.tile_height * slideData.uperpixel)],
                [(x + 1) * (slideData.tile_width * slideData.uperpixel), (y + yStart) * (slideData.tile_height * slideData.uperpixel)]
            ];
            drawRectangle(bounds, vectorSource, true, 2, color);
        }
    }

    // SOUTH MARKERS
    if(v["SouthDiff"] !== undefined){
        let numCrops = v["SouthDiff"].length;
        for(let crop = 0; crop < numCrops ; crop++){
            let hue = 180 - Math.floor(180 * ((v["SOUTH"] - mindiff) / (maxdiff - mindiff)));
            let rgbColor = hsvToRgb(hue / 360, 1, 1);
            let color = fullColorHex(rgbColor.r, rgbColor.g, rgbColor.b);
            let xStart = 0.1 + crop*(0.8/numCrops) + 0.1;
            let xEnd = 0.1 + (crop+1)*(0.8/numCrops) - 0.1;
            let bounds = [
                [(x + xStart) * (slideData.tile_width * slideData.uperpixel), (y) * (slideData.tile_height * slideData.uperpixel)],
                [(x + xEnd) * (slideData.tile_width * slideData.uperpixel), (y) * (slideData.tile_height * slideData.uperpixel)],
                [(x + xEnd) * (slideData.tile_width * slideData.uperpixel), (y + (margin)) * (slideData.tile_height * slideData.uperpixel)],
                [(x + xStart) * (slideData.tile_width * slideData.uperpixel), (y + (margin)) * (slideData.tile_height * slideData.uperpixel)],
                [(x + xStart) * (slideData.tile_width * slideData.uperpixel), (y) * (slideData.tile_height * slideData.uperpixel)]
            ];
            drawRectangle(bounds, vectorSource, true, 2, color);
        }
    }
}

export const plotFFTFourdText = (slideMetaData, slideData, vectorSource) => {
    let dataArray = transformFFTFourDData(slideMetaData.post_scan_pre_norm_blur_vals_java_fourd, slideData);
    let size = 2;
    let margin = size / 10;
    let sizeOfMarker = 0.3;
    for(let fftVals in dataArray[0]) {
        let x = parseInt(fftVals.split('y')[0].split('x')[1]);
        let y = parseInt(fftVals.split('y')[1].split('.' + slideData.img_type)[0]);
        plotFftFourDTextUnit(dataArray[0][fftVals], slideData, x, y, margin, sizeOfMarker, vectorSource, dataArray[1], dataArray[2]);
    }
}

export const plotFftFourDTextUnit = (v, slideData, x, y, margin, sizeOfMarker, vectorSource, mindiff, maxdiff) => {

    y = slideData.y_fields - y - 1;

    // NORTH MARKERS
    let numCrops;

    if(v["NORTH"]){
        numCrops = v["NORTH"].length;
        for(let crop = 0; crop < numCrops ; crop++)
        {
            drawLine((x + 0.1 + (0.8/numCrops)* (crop+0.5)) * (slideData.tile_width * slideData.uperpixel), 
                    (y + 1 - (margin / 2)) * (slideData.tile_height * slideData.uperpixel), 
                    1, vectorSource, Math.round(v["NORTH"][crop]*100)/100, 1, "#000000");
        }
    }

    // WEST MARKERS
    if(v["WEST"]){
        numCrops = v["WEST"].length;
        for(let crop = 0; crop < numCrops ; crop++)
        {
            drawLine((x + (margin / 2)) * (slideData.tile_width * slideData.uperpixel), 
            (y + 0.1 + (0.8/numCrops)* (crop+0.5)) * (slideData.tile_height * slideData.uperpixel),
                1, vectorSource, Math.round(v["WEST"][numCrops - crop - 1]*100)/100, 1, "#000000");
        }
    }

    // EAST MARKERS
    if(v["EAST"]){
        numCrops = v["EAST"].length;
        for(let crop = 0; crop < numCrops ; crop++)
        {
            drawLine((x + 1 - (margin / 2)) * (slideData.tile_width * slideData.uperpixel), 
            (y + 0.1 + (0.8/numCrops)* (crop+0.5)) * (slideData.tile_height * slideData.uperpixel), 
            1, vectorSource, Math.round(v["EAST"][numCrops - crop - 1]*100)/100, 1, "#000000");
        }
    }

    // SOUTH MARKERS
    if(v["SOUTH"]){
        numCrops = v["SOUTH"].length;
        for(let crop = 0; crop < numCrops ; crop++)
        {
            drawLine((x + 0.1 + (0.8/numCrops)* (crop+0.5)) * (slideData.tile_width * slideData.uperpixel), 
            (y + (margin / 2)) * (slideData.tile_height * slideData.uperpixel), 
            1, vectorSource, Math.round(v["SOUTH"][crop]*100)/100, 1, "#000000");
        }
    }
}

export const transformFFTFourDData = (data, slideData) => {
    let maxdiff = Number.MIN_VALUE;
    let mindiff = Number.MAX_VALUE;
    for(let imageKey in data) {
        let x = parseInt(imageKey.split('y')[0].split('x')[1]);
        let y = parseInt(imageKey.split('y')[1].split('.' + slideData.img_type)[0]);

        // Checking North diff
        let newX = x;
        let newY = y-1;
        let newkey = "x" + newX + "y" + newY + ".jpg";
        if (newX >= 0 && newY >= 0 && newX < slideData.x_fields && newY < slideData.y_fields && data[newkey] !== undefined){
            let diffs = []
            if(data[imageKey]["NORTH"] && data[newkey]["SOUTH"]){
                for(let crop = 0; crop < data[imageKey]["NORTH"].length; crop++){
                    let diff = Math.abs(data[imageKey]["NORTH"][crop] - data[newkey]["SOUTH"][crop]);
                    if (diff > maxdiff){
                        maxdiff = diff;
                    }
                    if (diff < mindiff){
                        mindiff = diff;
                    }
                    diffs.push(diff);
                }       
            }
            data[imageKey]["NorthDiff"] = diffs;
            data[newkey]["SouthDiff"] = diffs;     
        }

        // Checking West diff
        newX = x -1;
        newY = y;
        newkey = "x" + newX + "y" + newY + ".jpg";
        if (newX >= 0 && newY >= 0 && newX < slideData.x_fields && newY < slideData.y_fields && data[newkey] !== undefined){
            let diffs = []
            if(data[imageKey]["WEST"] && data[newkey]["EAST"]){
                for(let crop = 0; crop < data[imageKey]["WEST"].length; crop++){
                    let diff = Math.abs(data[imageKey]["WEST"] - data[newkey]["EAST"]);
                    if (diff > maxdiff){
                        maxdiff = diff;
                    }
                    if (diff < mindiff){
                        mindiff = diff;
                    }
                    diffs.push(diff);
                }
            }
            data[imageKey]["WestDiff"] = diffs;
            data[newkey]["EastDiff"] = diffs;      
        } 
    }
    console.log("MinDIff: " + mindiff + ", MaxDiff: " + maxdiff);
    return [data, mindiff, maxdiff];
}

export const plotFFTFine = (slideMetaData, slideData, vectorSource) => {
    let data = slideMetaData.fft_fine;
    let maxdiff = Number.MIN_VALUE;
    let mindiff = Number.MAX_VALUE;
    for(let fftVals in data) {
        if (data[fftVals]["diff"] > maxdiff){
            maxdiff = data[fftVals]["diff"];
        }
        if (data[fftVals]["diff"] < mindiff){
            mindiff = data[fftVals]["diff"];
        }
    }
    let size = 2;
    let margin = size / 10;
    let sizeOfMarker = 0.3;
    for(let fftVals in data) {
        let key1 = fftVals.split('-')[0];
        let key2 = fftVals.split('-')[1];
        let x1 = parseInt(key1.split('y')[0].split('x')[1]);
        let y1 = parseInt(key1.split('y')[1].split('.' + slideData.img_type)[0]);
        let x2 = parseInt(key2.split('y')[0].split('x')[1]);
        let y2 = parseInt(key2.split('y')[1].split('.' + slideData.img_type)[0]);
        if(data[fftVals].hasOwnProperty("NORTH")) {
            plotFftFourDFineAreaUnit(data[fftVals], slideData, x1, y1, margin, sizeOfMarker, vectorSource, mindiff, maxdiff, "NORTH");
            plotFftFourDFineAreaUnit(data[fftVals], slideData, x2, y2, margin, sizeOfMarker, vectorSource, mindiff, maxdiff, "SOUTH");
        } else {
            plotFftFourDFineAreaUnit(data[fftVals], slideData, x1, y1, margin, sizeOfMarker, vectorSource, mindiff, maxdiff, "WEST");
            plotFftFourDFineAreaUnit(data[fftVals], slideData, x2, y2, margin, sizeOfMarker, vectorSource, mindiff, maxdiff, "EAST");
        }
    }
}

export const plotFftFourDFineAreaUnit = (v, slideData, x, y, margin, sizeOfMarker, vectorSource, mindiff, maxdiff, direction) => {
    y = slideData.y_fields - y - 1;

    let hue = 180 - Math.floor(180 * ((v["diff"] - mindiff) / (maxdiff - mindiff)));
    let rgbColor = hsvToRgb(hue / 360, 1, 1);
    let color = fullColorHex(rgbColor.r, rgbColor.g, rgbColor.b);

    // NORTH MARKERS
    if(direction === "NORTH") {
        let bounds = [
            [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y + 1) * (slideData.tile_height * slideData.uperpixel)],
            [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y + 1) * (slideData.tile_height * slideData.uperpixel)],
            [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y - (margin) + 1) * (slideData.tile_height * slideData.uperpixel)],
            [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y - (margin) + 1) * (slideData.tile_height * slideData.uperpixel)],
            [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y + 1) * (slideData.tile_height * slideData.uperpixel)]
        ];
        drawRectangle(bounds, vectorSource, true, 2, color);
    }

    // WEST MARKERS
    if(direction === "WEST") {
        let bounds = [
            [(x) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
            [(x) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
            [(x + (margin)) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
            [(x + (margin)) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
            [(x) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)]
        ];
        drawRectangle(bounds, vectorSource, true, 2, color);
    }

    // EAST MARKERS
    if(direction === "EAST") {
        let bounds = [
            [(x + 1) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
            [(x + 1) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
            [(x + 1 - (margin)) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
            [(x + 1 - (margin)) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
            [(x + 1) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)]
        ];
        drawRectangle(bounds, vectorSource, true, 2, color);
    }

    // SOUTH MARKERS
    if(direction === "SOUTH") {
        let bounds = [
            [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y) * (slideData.tile_height * slideData.uperpixel)],
            [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y) * (slideData.tile_height * slideData.uperpixel)],
            [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y + (margin)) * (slideData.tile_height * slideData.uperpixel)],
            [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y + (margin)) * (slideData.tile_height * slideData.uperpixel)],
            [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y) * (slideData.tile_height * slideData.uperpixel)]
        ];
        drawRectangle(bounds, vectorSource, true, 2, color);
    }
}

export const plotFFTFineText = (slideMetaData, slideData, vectorSource) => {
    let data = slideMetaData.fft_fine;
    let maxdiff = Number.MIN_VALUE;
    let mindiff = Number.MAX_VALUE;
    for(let fftVals in data) {
        if (data[fftVals]["diff"] > maxdiff){
            maxdiff = data[fftVals]["diff"];
        }
        if (data[fftVals]["diff"] < mindiff){
            mindiff = data[fftVals]["diff"];
        }
    }
    let size = 2;
    let margin = size / 10;
    let sizeOfMarker = 0.3;
    for(let fftVals in data) {
        let key1 = fftVals.split('-')[0];
        let key2 = fftVals.split('-')[1];
        let x1 = parseInt(key1.split('y')[0].split('x')[1]);
        let y1 = parseInt(key1.split('y')[1].split('.' + slideData.img_type)[0]);
        let x2 = parseInt(key2.split('y')[0].split('x')[1]);
        let y2 = parseInt(key2.split('y')[1].split('.' + slideData.img_type)[0]);
        if(data[fftVals].hasOwnProperty("NORTH")) {
            plotFftFourDFineTextUnit(data[fftVals], slideData, x1, y1, margin, sizeOfMarker, vectorSource, mindiff, maxdiff, "NORTH");
            plotFftFourDFineTextUnit(data[fftVals], slideData, x2, y2, margin, sizeOfMarker, vectorSource, mindiff, maxdiff, "SOUTH");
        } else {
            plotFftFourDFineTextUnit(data[fftVals], slideData, x1, y1, margin, sizeOfMarker, vectorSource, mindiff, maxdiff, "WEST");
            plotFftFourDFineTextUnit(data[fftVals], slideData, x2, y2, margin, sizeOfMarker, vectorSource, mindiff, maxdiff, "EAST");
        }
    }
}

export const plotFftFourDFineTextUnit = (v, slideData, x, y, margin, sizeOfMarker, vectorSource, mindiff, maxdiff, direction) => {

    y = slideData.y_fields - y - 1;

    // NORTH MARKERS
    if(direction === "NORTH") {
        drawLine((x + 0.5) * (slideData.tile_width * slideData.uperpixel), (y + 1 - (margin / 2)) * (slideData.tile_height * slideData.uperpixel), 1, vectorSource, Math.trunc(v["NORTH"]), 1, "#808080");
    }
    // WEST MARKERS
    if(direction === "WEST") {
        drawLine((x + (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + 0.5) * (slideData.tile_height * slideData.uperpixel), 1, vectorSource, Math.trunc(v["WEST"]), 1, "#808080");
    }
    // EAST MARKERS
    if(direction === "EAST") {
        drawLine((x + 1 - (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + 0.5) * (slideData.tile_height * slideData.uperpixel), 1, vectorSource, Math.trunc(v["EAST"]), 1, "#808080");
    }
    // SOUTH MARKERS
    if(direction === "SOUTH") {
        drawLine((x + 0.5) * (slideData.tile_width * slideData.uperpixel), (y + (margin / 2)) * (slideData.tile_height * slideData.uperpixel), 1, vectorSource, Math.trunc(v["SOUTH"]), 1, "#808080");
    }
}

export const plotFFTRatioFourd = (slideMetaData, slideData, vectorSource) => {
    let data = slideMetaData.post_scan_pre_norm_blur_vals_java_fourd;
    let ratioThresh = slideMetaData.rescan_thresh;
    let size = 3;
    let margin = size / 10;
    let sizeOfMarker = 0.3;
    for(let imageKey in data) {
        let xc = parseInt(imageKey.split('y')[0].split('x')[1]);
        let yc = parseInt(imageKey.split('y')[1].split('.' + slideData.img_type)[0]);

        let v = {};
        let north_neigh = "x" + xc + "y" + (yc-1);
        if(data[north_neigh] !== undefined && data[north_neigh]["SOUTH"] !== undefined) {
            let ratios = []
            if(data[imageKey]["NORTH"] && data[north_neigh]["SOUTH"]){
                for(let crop = 0; crop < data[imageKey]["NORTH"].length; crop++){
                    let cur_north_val = data[imageKey]["NORTH"][crop];
                    let north_neigh_south_val = data[north_neigh]["SOUTH"][crop];
                    let rr = Math.round(1000 * Math.min(cur_north_val, north_neigh_south_val) / Math.max(cur_north_val, north_neigh_south_val));
                    ratios.push(rr);
                    if(rr <= ratioThresh * 1000){
                        if(cur_north_val < north_neigh_south_val){
                            v['rescan'] = true
                        }else{
                            v['rescan_north'] = true;
                        }
                    }
                }
            }      
            v['NORTH'] = ratios;
        }

        let west_neigh = "x" + (xc - 1) + "y" + yc;
        if(data[west_neigh] !== undefined && data[west_neigh]["EAST"] !== undefined) {
            let ratios = []
            if(data[imageKey]["WEST"] && data[west_neigh]["EAST"]){
                for(let crop = 0; crop < data[imageKey]["WEST"].length; crop++){
                    let cur_west_val = data[imageKey]["WEST"][crop];
                    let west_neigh_east_val = data[west_neigh]["EAST"][crop];
                    let rr = Math.round(1000 * Math.min(cur_west_val, west_neigh_east_val) / Math.max(cur_west_val, west_neigh_east_val));
                    ratios.push(rr);
                    if(rr <= ratioThresh * 1000){
                        if(cur_west_val < west_neigh_east_val){
                            v['rescan'] = true
                        }else{
                            v['rescan_west'] = true;
                        }
                    }
                }
            }
            v['WEST'] = ratios;
        }

        plotFftRatioFourDUnit(v, slideData, xc, yc, margin, sizeOfMarker, vectorSource, ratioThresh);
    }
}

export const plotFftRatioFourDUnit = (v, slideData, x, y, margin, sizeOfMarker, vectorSource, ratioThresh) => {

    y = slideData.y_fields - y - 1;

    if(v["NORTH"] !== undefined) {
        let numCrops = v["NORTH"].length;
        for(let crop=0;crop<v["NORTH"].length; crop++){
            let color = "#000000" + "50";
            if (v['NORTH'][crop] <= ratioThresh * 1000) {
                color = "#00f000" + "70";
            }

            let xStart = 0.1 + crop*(0.8/numCrops) + 0.1;
            let xEnd = 0.1 + (crop+1)*(0.8/numCrops) - 0.1;

            // NORTH MARKERS
            let bounds = [
                [(x + xStart) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
                [(x + xEnd) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
                [(x + xEnd) * (slideData.tile_width * slideData.uperpixel), (y + (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
                [(x + xStart) * (slideData.tile_width * slideData.uperpixel), (y + (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
                [(x + xStart) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)]
            ];
            drawRectangle(bounds, vectorSource, true, 2, color);

            // if(v["rescan"]){
            //     let bounds = [
            //         [(x + 0.3) * (slideData.tile_width * slideData.uperpixel), (y + 0.3) * (slideData.tile_height * slideData.uperpixel)],
            //         [(x + 0.7) * (slideData.tile_width * slideData.uperpixel), (y + 0.3) * (slideData.tile_height * slideData.uperpixel)],
            //         [(x + 0.7) * (slideData.tile_width * slideData.uperpixel), (y + 0.7) * (slideData.tile_height * slideData.uperpixel)],
            //         [(x + 0.3) * (slideData.tile_width * slideData.uperpixel), (y + 0.7) * (slideData.tile_height * slideData.uperpixel)],
            //         [(x + 0.3) * (slideData.tile_width * slideData.uperpixel), (y + 0.3) * (slideData.tile_height * slideData.uperpixel)]
            //     ];
            //     drawRectangle(bounds, vectorSource, true, 2, "#FFC0CB");        
            // }

            // if(v["rescan_west"]){
            //     let bounds = [
            //         [(x + 0.3) * (slideData.tile_width * slideData.uperpixel), (y + 0.3 -1) * (slideData.tile_height * slideData.uperpixel)],
            //         [(x + 0.7) * (slideData.tile_width * slideData.uperpixel), (y + 0.3 -1) * (slideData.tile_height * slideData.uperpixel)],
            //         [(x + 0.7) * (slideData.tile_width * slideData.uperpixel), (y + 0.7 -1) * (slideData.tile_height * slideData.uperpixel)],
            //         [(x + 0.3) * (slideData.tile_width * slideData.uperpixel), (y + 0.7 -1) * (slideData.tile_height * slideData.uperpixel)],
            //         [(x + 0.3) * (slideData.tile_width * slideData.uperpixel), (y + 0.3 -1) * (slideData.tile_height * slideData.uperpixel)]
            //     ];
            //     drawRectangle(bounds, vectorSource, true, 2, "#FFC0CB");        
            // }
        }
    }

    if(v["WEST"] !== undefined) {
        let numCrops = v["WEST"].length;
        for(let crop=0;crop<v["WEST"].length; crop++){
            let color = "#000000" + "50";
            if (v['WEST'][numCrops - 1 - crop] <= ratioThresh * 1000) {
                color = "#00f000" + "70";
            }

            let yStart = 0.1 + crop*(0.8/numCrops) + 0.1;
            let yEnd = 0.1 + (crop+1)*(0.8/numCrops) - 0.1;

            // WEST MARKERS
            let bounds = [
                [(x - (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + yStart) * (slideData.tile_height * slideData.uperpixel)],
                [(x - (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + yEnd) * (slideData.tile_height * slideData.uperpixel)],
                [(x + (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + yEnd) * (slideData.tile_height * slideData.uperpixel)],
                [(x + (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + yStart) * (slideData.tile_height * slideData.uperpixel)],
                [(x - (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + yStart) * (slideData.tile_height * slideData.uperpixel)]
            ];
            drawRectangle(bounds, vectorSource, true, 2, color);

            // if(v["rescan"]){
            //     let bounds = [
            //         [(x + 0.3) * (slideData.tile_width * slideData.uperpixel), (y + 0.3) * (slideData.tile_height * slideData.uperpixel)],
            //         [(x + 0.7) * (slideData.tile_width * slideData.uperpixel), (y + 0.3) * (slideData.tile_height * slideData.uperpixel)],
            //         [(x + 0.7) * (slideData.tile_width * slideData.uperpixel), (y + 0.7) * (slideData.tile_height * slideData.uperpixel)],
            //         [(x + 0.3) * (slideData.tile_width * slideData.uperpixel), (y + 0.7) * (slideData.tile_height * slideData.uperpixel)],
            //         [(x + 0.3) * (slideData.tile_width * slideData.uperpixel), (y + 0.3) * (slideData.tile_height * slideData.uperpixel)]
            //     ];
            //     drawRectangle(bounds, vectorSource, true, 2, "#FFC0CB");        
            // }

            // if(v["rescan"]){
            //     let bounds = [
            //         [(x + 0.3 -1) * (slideData.tile_width * slideData.uperpixel), (y + 0.3) * (slideData.tile_height * slideData.uperpixel)],
            //         [(x + 0.7 -1) * (slideData.tile_width * slideData.uperpixel), (y + 0.3) * (slideData.tile_height * slideData.uperpixel)],
            //         [(x + 0.7 -1) * (slideData.tile_width * slideData.uperpixel), (y + 0.7) * (slideData.tile_height * slideData.uperpixel)],
            //         [(x + 0.3 -1) * (slideData.tile_width * slideData.uperpixel), (y + 0.7) * (slideData.tile_height * slideData.uperpixel)],
            //         [(x + 0.3 -1) * (slideData.tile_width * slideData.uperpixel), (y + 0.3) * (slideData.tile_height * slideData.uperpixel)]
            //     ];
            //     drawRectangle(bounds, vectorSource, true, 2, "#FFC0CB");        
            // }
        }
    }
}

export const plotFFTRatioFourDText = (slideMetaData, slideData, vectorSource) => {
    let data = slideMetaData.post_scan_pre_norm_blur_vals_java_fourd;
    let ratioThresh = slideMetaData.rescan_thresh;
    console.log("ratio thresh : " , ratioThresh);

    let size = 3;
    let margin = size / 10;
    let sizeOfMarker = 0.3;
    for(let imageKey in data) {
        let xc = parseInt(imageKey.split('y')[0].split('x')[1]);
        let yc = parseInt(imageKey.split('y')[1].split('.' + slideData.img_type)[0]);

        let v = {};
        let north_neigh = "x" + xc + "y" + (yc-1);
        if(data[north_neigh] !== undefined && data[north_neigh]["SOUTH"] !== undefined) {
            let ratios = []
            if(data[imageKey]["NORTH"] && data[north_neigh]["SOUTH"]){
                for(let crop = 0; crop < data[imageKey]["NORTH"].length; crop++){
                    let cur_north_val = data[imageKey]["NORTH"][crop];
                    let north_neigh_south_val = data[north_neigh]["SOUTH"][crop];
                    let rr = Math.round(1000 * Math.min(cur_north_val, north_neigh_south_val) / Math.max(cur_north_val, north_neigh_south_val));
                    ratios.push(rr);
                }
            }
            v['NORTH'] = ratios;
        }

        let west_neigh = "x" + (xc - 1) + "y" + yc;
        if(data[west_neigh] !== undefined && data[west_neigh]["EAST"] !== undefined) {
            let ratios = []
            if(data[imageKey]["WEST"] && data[west_neigh]["EAST"]){
                for(let crop = 0; crop < data[imageKey]["WEST"].length; crop++){
                    let cur_west_val = data[imageKey]["WEST"][crop];
                    let west_neigh_east_val = data[west_neigh]["EAST"][crop];
                    let rr = Math.round(1000 * Math.min(cur_west_val, west_neigh_east_val) / Math.max(cur_west_val, west_neigh_east_val));
                    ratios.push(rr);
                }
            }
            v['WEST'] = ratios;
        }

        plotFftRatioFourDTextUnit(v, slideData, xc, yc, margin, sizeOfMarker, vectorSource, ratioThresh);
    }
}

export const plotFftRatioFourDTextUnit = (v, slideData, x, y, margin, sizeOfMarker, vectorSource, ratioThresh) => {

    y = slideData.y_fields - y - 1;

    if(v["NORTH"] !== undefined) {
        let numCrops = v["NORTH"].length;
        for(let crop=0;crop<v["NORTH"].length; crop++){
            let cssLabel = tileViewerPlotColors.fftRatioCleanColor;
            if (v['NORTH'][crop] <= ratioThresh * 1000){
                cssLabel = tileViewerPlotColors.fftRatioStrongColor;
            }
            drawLine((x + 0.1 + (0.8/numCrops)* (crop+0.5)) * (slideData.tile_width * slideData.uperpixel), 
            (y + 1) * (slideData.tile_height * slideData.uperpixel), 1, vectorSource, v["NORTH"][crop], 10, cssLabel);
        }
    }

    if(v["WEST"] !== undefined) {
        let numCrops = v["WEST"].length;
        for(let crop=0;crop<v["WEST"].length; crop++){
            let cssLabel = tileViewerPlotColors.fftRatioCleanColor;
            if (v['WEST'][numCrops - 1 - crop] <= ratioThresh* 1000) {
                cssLabel = tileViewerPlotColors.fftRatioStrongColor;
            }
            drawLine(x * (slideData.tile_width * slideData.uperpixel), 
            (y + 0.1 + (0.8/numCrops)* (crop+0.5))* (slideData.tile_height * slideData.uperpixel), 1, vectorSource, v["WEST"][numCrops - 1 - crop], 10, cssLabel);
        }
    }
}


export const plotFocusFourD = (slideMetaData, slideData, vectorSource) => {
    let data = slideMetaData.scan_meta_v2;
    let size = 2;
    let margin = size / 10;
    let sizeOfMarker = 0.3;
    for(let fftVals in data) {
        let x = parseInt(fftVals.split('y')[0].split('x')[1]);
        let y = parseInt(fftVals.split('y')[1].split('.' + slideData.img_type)[0]);
        plotFocusFourDUnit(data[fftVals], slideData, x, y, margin, sizeOfMarker, vectorSource);
    }
}

export const plotFocusFourDUnit = (v, slideData, x, y, margin, sizeOfMarker, vectorSource) => {
    y = slideData.y_fields - y - 1;

    // NORTH MARKERS
    let color = fullColorHex(0, v["northVal"], 0) + "80";
    let bounds = [
        [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y + 1) * (slideData.tile_height * slideData.uperpixel)],
        [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y + 1) * (slideData.tile_height * slideData.uperpixel)],
        [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y - (margin) + 1) * (slideData.tile_height * slideData.uperpixel)],
        [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y - (margin) + 1) * (slideData.tile_height * slideData.uperpixel)],
        [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y + 1) * (slideData.tile_height * slideData.uperpixel)]
    ];
    drawRectangle(bounds, vectorSource, true, 2, color);
    drawLine((x + 0.5) * (slideData.tile_width * slideData.uperpixel), (y + 1 - (margin / 2)) * (slideData.tile_height * slideData.uperpixel), 1, vectorSource, v["northVal"], 1, "#00ffea");

    // WEST MARKERS
    color = fullColorHex(0, v["westVal"], 0) + "80";
    bounds = [
        [(x) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
        [(x) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
        [(x + (margin)) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
        [(x + (margin)) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
        [(x) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)]
    ];
    drawRectangle(bounds, vectorSource, true, 2, color);
    drawLine((x + (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + 0.5) * (slideData.tile_height * slideData.uperpixel), 1, vectorSource, v["westVal"], 1, "#00ffea");

    // EAST MARKERS
    color = fullColorHex(0, v["eastVal"], 0) + "80";
    bounds = [
        [(x + 1) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
        [(x + 1) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
        [(x + 1 - (margin)) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
        [(x + 1 - (margin)) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
        [(x + 1) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)]
    ];
    drawRectangle(bounds, vectorSource, true, 2, color);
    drawLine((x + 1 - (margin / 2)) * (slideData.tile_width * slideData.uperpixel), (y + 0.5) * (slideData.tile_height * slideData.uperpixel), 1, vectorSource, v["eastVal"], 1, "#00ffea");

    // SOUTH MARKERS
    color = fullColorHex(0, v["southVal"], 0) + "80";
    bounds = [
        [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y) * (slideData.tile_height * slideData.uperpixel)],
        [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y) * (slideData.tile_height * slideData.uperpixel)],
        [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y + (margin)) * (slideData.tile_height * slideData.uperpixel)],
        [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y + (margin)) * (slideData.tile_height * slideData.uperpixel)],
        [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y) * (slideData.tile_height * slideData.uperpixel)]
    ];
    drawRectangle(bounds, vectorSource, true, 2, color);
    drawLine((x + 0.5) * (slideData.tile_width * slideData.uperpixel), (y + (margin / 2)) * (slideData.tile_height * slideData.uperpixel), 1, vectorSource, v["southVal"], 1, "#00ffea");
}

export const plotSparseFFTHeatmap = (slideMetaData, slideData, vectorSource) => {
    let data = slideMetaData.tilt_fft_vals;
    for (let key in data) {
        let Y = key.split(' ')[1].split(')')[0];
        let X = key.split(' ')[0].split('(')[1];
        let motorData = {}
        motorData.xPosition = X;
        motorData.yPosition = Y;
        let hex = fullColorHex(data[key], 0, 0);
        plotSquare(motorData, slideData, slideMetaData, vectorSource, hex, 1.5, 2);
        let imagePositions = mapMotorToCanvas(X, Y, slideData, slideMetaData);
        let x = imagePositions.x;
        let y = imagePositions.y;
        drawLine(x, y, 1, vectorSource, data[key], 1, "#00ffea");
    }
}


export const plotStitchInfo = (slideMetaData, slideData, vectorSource) => {
    let data = slideMetaData.stitch_annotations;
    let size = 3;
    let margin = size / 10;
    let sizeOfMarker = 0.3;
    for (let k in data) {
        if (k.includes("_")) {
            return;
        }
        let x = parseFloat(k.split('y')[0].split('x')[1]);
        let y = parseFloat(k.split('y')[1].split('.' + slideData.img_type)[0]);
        plotStitchTranslationNotFound(k, data[k], slideData, x, y, margin, sizeOfMarker - 0.1, vectorSource);
        plotStitchTranslationDirection(data[k], slideData, x, y, margin, sizeOfMarker, vectorSource);
    }
}

export const plotStitchTranslationNotFound = (tileName, v, slideData, x, y, margin, sizeOfMarker, vectorSource) => {
    y = slideData.y_fields - y - 1;
    let transparentGreen = "#00FF0080";
    
        if (v["mstDirection"] === "TOP" && v["topTranslationFound"] === false) {
            let bounds = [
                [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y + 1) * (slideData.tile_height * slideData.uperpixel)],
                [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y + 1) * (slideData.tile_height * slideData.uperpixel)],
                [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y - (margin) + 1) * (slideData.tile_height * slideData.uperpixel)],
                [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y - (margin) + 1) * (slideData.tile_height * slideData.uperpixel)],
                [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y + 1) * (slideData.tile_height * slideData.uperpixel)]
            ];
            drawRectangle(bounds, vectorSource, true, 2, transparentGreen);
        }
        if (v["mstDirection"] === "LEFT" && v["leftTranslationFound"] === false) {
            let bounds = [
                [(x) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
                [(x) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
                [(x + (margin)) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
                [(x + (margin)) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
                [(x) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)]
            ];
            drawRectangle(bounds, vectorSource, true, 2, transparentGreen);
        }
        if (v["mstDirection"] === "RIGHT" && v["rightTranslationFound"] === false) {
            let bounds = [
                [(x + 1) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
                [(x + 1) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
                [(x + 1 - (margin)) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
                [(x + 1 - (margin)) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
                [(x + 1) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)]
            ];
            drawRectangle(bounds, vectorSource, true, 2, transparentGreen);
        }
        if (v["mstDirection"] === "BOTTOM" && v["bottomTranslationFound"] === false) {
            let bounds = [
                [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y) * (slideData.tile_height * slideData.uperpixel)],
                [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y) * (slideData.tile_height * slideData.uperpixel)],
                [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y + (margin)) * (slideData.tile_height * slideData.uperpixel)],
                [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y + (margin)) * (slideData.tile_height * slideData.uperpixel)],
                [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y) * (slideData.tile_height * slideData.uperpixel)]
            ];
            drawRectangle(bounds, vectorSource, true, 2, transparentGreen);
        }
        let mstCount = v["mstCount"];
        drawLine((x + 0.5) * (slideData.tile_width * slideData.uperpixel), (y + 0.5) * (slideData.tile_height * slideData.uperpixel), 1, vectorSource, mstCount, 1, "#00ffea");
    }


export const plotStitchTranslationDirection = (v, slideData, x, y, margin, sizeOfMarker, vectorSource) => {
    y = slideData.y_fields - y - 1;
    let transparentBlue = "#59add480";
    if (v["mstDirection"] === "TOP" && v["topTranslationFound"] === true) {
        let bounds = [
            [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y + 1) * (slideData.tile_height * slideData.uperpixel)],
            [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y + 1) * (slideData.tile_height * slideData.uperpixel)],
            [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y - (margin) + 1) * (slideData.tile_height * slideData.uperpixel)],
            [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y - (margin) + 1) * (slideData.tile_height * slideData.uperpixel)],
            [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y + 1) * (slideData.tile_height * slideData.uperpixel)]
        ];
        drawRectangle(bounds, vectorSource, true, 2, transparentBlue);
    } else if (v["mstDirection"] === "LEFT" && v["leftTranslationFound"] === true) {
        let bounds = [
            [(x) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
            [(x) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
            [(x + (margin)) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
            [(x + (margin)) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
            [(x) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)]
        ];
        drawRectangle(bounds, vectorSource, true, 2, transparentBlue);
    } else if (v["mstDirection"] === "RIGHT" && v["rightTranslationFound"] === true) {
        let bounds = [
            [(x + 1) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
            [(x + 1) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
            [(x + 1 - (margin)) * (slideData.tile_width * slideData.uperpixel), (y + (1 - sizeOfMarker)) * (slideData.tile_height * slideData.uperpixel)],
            [(x + 1 - (margin)) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)],
            [(x + 1) * (slideData.tile_width * slideData.uperpixel), (y + sizeOfMarker) * (slideData.tile_height * slideData.uperpixel)]
        ];
        drawRectangle(bounds, vectorSource, true, 2, transparentBlue);
    } else if (v["mstDirection"] === "BOTTOM" && v["bottomTranslationFound"] === true) {
        let bounds = [
            [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y) * (slideData.tile_height * slideData.uperpixel)],
            [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y) * (slideData.tile_height * slideData.uperpixel)],
            [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y + (margin)) * (slideData.tile_height * slideData.uperpixel)],
            [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y + (margin)) * (slideData.tile_height * slideData.uperpixel)],
            [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y) * (slideData.tile_height * slideData.uperpixel)]
        ];
        drawRectangle(bounds, vectorSource, true, 2, transparentBlue);
    }

    
    //  else {
    //     let chosenColor;
    //     if (Math.round(x) !== x || Math.round(y) !== y) {
    //         return;
    //     } else {
    //         chosenColor = transparentBlue;
    //     }
    //     sizeOfMarker = 0;
    //     let bounds = [
    //         [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 0.5) * (slideData.tile_height * slideData.uperpixel)],
    //         [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 0.5) * (slideData.tile_height * slideData.uperpixel)],
    //         [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y + (margin / 2) + 0.5) * (slideData.tile_height * slideData.uperpixel)],
    //         [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y + (margin / 2) + 0.5) * (slideData.tile_height * slideData.uperpixel)],
    //         [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 0.5) * (slideData.tile_height * slideData.uperpixel)]
    //     ];
    //     drawRectangle(bounds, vectorSource, true, 2, chosenColor);
    //     margin = 1;
    //     sizeOfMarker = 0.4;
    //     bounds = [
    //         [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 0.5) * (slideData.tile_height * slideData.uperpixel)],
    //         [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 0.5) * (slideData.tile_height * slideData.uperpixel)],
    //         [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y + (margin / 2) + 0.5) * (slideData.tile_height * slideData.uperpixel)],
    //         [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y + (margin / 2) + 0.5) * (slideData.tile_height * slideData.uperpixel)],
    //         [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 0.5) * (slideData.tile_height * slideData.uperpixel)]
    //     ];
    //     drawRectangle(bounds, vectorSource, true, 2, chosenColor);
    // }
}

export const plotRepairInfo = (slideMetaData, slideData, vectorSource) => {

    let data = slideMetaData.repairs_meta;
    let size = 3;
    let margin = size / 10;
    let sizeOfMarker = 0.3;
    for (let k in data) {
        let v = data[k];
        let key = v.name;
        let x = parseFloat(key.split('y')[0].split('x')[1].replace('_', '.'));
        let y = parseFloat(key.split('y')[1].split('.' + slideData.img_type)[0].replace('_', '.'));
        plotRepairInfoUnit(v, slideData, x, y, margin, sizeOfMarker, vectorSource);
    }
}

export const plotRepairInfoUnit = (v, slideData, x, y, margin, sizeOfMarker, vectorSource) => {
    let transparentRed = "#FF000080";
    let transparentBlue = "#2200ff80";
    let transparentGreen = "#00ff1e80";
    let chosenColor;
    if (v.scanned === true) {
        chosenColor = transparentRed;
    } else if (v.score >= 0) {
        chosenColor = transparentBlue;
    } else {
        chosenColor = transparentGreen;
    }

    sizeOfMarker = 0;
    let bounds = [
        [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
        [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
        [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y + (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
        [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y + (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
        [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)]
    ];
    drawRectangle(bounds, vectorSource, true, 2, chosenColor);
    margin = 1;
    sizeOfMarker = 0.4;
    bounds = [
        [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
        [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
        [(x + (1 - sizeOfMarker)) * (slideData.tile_width * slideData.uperpixel), (y + (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
        [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y + (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)],
        [(x + sizeOfMarker) * (slideData.tile_width * slideData.uperpixel), (y - (margin / 2) + 1) * (slideData.tile_height * slideData.uperpixel)]
    ];
    drawRectangle(bounds, vectorSource, true, 2, chosenColor);
}

export const plotDropDistance = (slideMetaData, slideData, plannedDropDistanceVectorSource, actualDropDistanceVectorSource) => {
    let plannedDdData = slideMetaData.drop_distance_point;
    let actualDdData = slideMetaData.actual_drop_distance_point;
    let motorPos = plannedDdData[0];
    plotSquare(motorPos, slideData, slideMetaData, plannedDropDistanceVectorSource, tileViewerPlotColors.plannedFocusColor, 1, 2);

    if (actualDdData[0] !== undefined) {
        motorPos = actualDdData[0];
        plotSquare(motorPos, slideData, slideMetaData, actualDropDistanceVectorSource, tileViewerPlotColors.actualFocusColor, 1, 2);
    }
}

export const plotStitchDisagreements = (slideMetaData, slideData, vectorSource) => {
    let data = slideMetaData.stitch_disagreements;
    for (let key in data) {
        if (key.includes('old')) continue;
        try {
            let motorPos = slideMetaData.raw.motor_mappings[key];
            let Y = parseFloat(motorPos.split("_Y_")[1]);
            let X = parseFloat(motorPos.split("_Y_")[0].split("X_")[1]);
            let motorData = {}
            motorData.xPosition = X;
            motorData.yPosition = Y;
            let hex = data[key] === -1 ? fullColorHex(0, 255, 0) : fullColorHex(data[key], 0, 0);
            plotSquare(motorData, slideData, slideMetaData, vectorSource, hex, 1.5, 2);
        } catch {

        }
    }
}

export const plotStitchDeltas = (slideMetaData, slideData, vectorSource) => {
    let data = slideMetaData.stitch_deltas;
    for (let key in data) {
        if (key.includes('old')) continue;
        try {
            let motorPos = slideMetaData.raw.motor_mappings[key];
            let Y = parseFloat(motorPos.split("_Y_")[1]);
            let X = parseFloat(motorPos.split("_Y_")[0].split("X_")[1]);
            let motorData = {}
            motorData.xPosition = X;
            motorData.yPosition = Y;
            let hex = fullColorHex(data[key], 0, 0);
            plotSquare(motorData, slideData, slideMetaData, vectorSource, hex, 1.5, 2);
        } catch {

        }
    }
}

const plotSingleStreak = (streaks, streakIdx, slideMetaData, slideData, vectorSource) => {
    for(let k = 0; k < streaks[streakIdx]['pathUnits'].length; k++) {
        let motorX =  streaks[streakIdx]['pathUnits'][k]['distance']['xPosition'];
        let motorY =  streaks[streakIdx]['pathUnits'][k]['distance']['yPosition'];
        let pos = mapMotorToCanvas(motorX, motorY, slideData, slideMetaData);
        let x = pos.x;
        let y = pos.y;
        let color = tileViewerPlotColors.startOfStripColor;
        let draw = false;
        if (k === 0) {
            if(streaks[streakIdx]['direction'] === 1)
                color = tileViewerPlotColors.startOfStripColor;
            else
                color = tileViewerPlotColors.startOfSubpathColor;
            draw = true;
        } else if (k === streaks[streakIdx]['pathUnits'].length - 1) {
            // if(streaks[j]['direction'] === 1)
            color = tileViewerPlotColors.endOfStripColor;
            // else
                // color = tileViewerPlotColors.endOfSubpathColor;
            draw = true;
        }
        if (draw) {
            let xImageCoord = Math.floor(x / (slideData.tile_width * slideData.uperpixel));
            let yImageCoord = Math.floor(y / (slideData.tile_height * slideData.uperpixel));
            let popupText = "Streak ID: " + streaks[streakIdx]['id'] + "\n Direction: " + streaks[streakIdx]['direction'] + "\n Trace: " + streaks[streakIdx]['traceID'];
            popupText += "\nXID: " + streaks[streakIdx]['fixedXId'];
            popupText += "\nnumFields: " + streaks[streakIdx]['pathUnits'].length;
            popupText += "\ncollected Images: " + streaks[streakIdx]['numImagesTakenWithTrigger'];
            popupText += "\nStart: " + getPosString(streaks[streakIdx]['startPos']) + " End: " +  getPosString(streaks[streakIdx]['endPos']);
            popupText += "\nxImageCoord: " + Math.floor(xImageCoord) + " yImageCoord: " + Math.floor(yImageCoord);

            drawCircle(x, y, 0.5 * slideData.tile_height * slideData.uperpixel, vectorSource, "", 6, color, popupText);

            if (streaks[streakIdx]['traceID'] === 1) {
                drawCircle(x, y, 0.1 * slideData.tile_height * slideData.uperpixel, vectorSource, "", 6, color);
            }

            if(streaks[streakIdx]['islandId'] > 1000){
                drawLine(x + (-0.2 * slideData.tile_width * slideData.uperpixel), y + (0.4 * slideData.tile_height * slideData.uperpixel), 1, vectorSource, streaks[streakIdx]['islandId'], 1, "#ffff00");
            }else{
                drawLine(x + (0.4 * slideData.tile_width * slideData.uperpixel), y + (0.4 * slideData.tile_height * slideData.uperpixel), 1, vectorSource, streaks[streakIdx]['islandId'], 1, "#00ffea");
            }
        }
        if (streaks[streakIdx]['pathUnits'][k].takeImage) {
            let xImageCoord = Math.floor(x / (slideData.tile_width * slideData.uperpixel));
            let yImageCoord = Math.floor(y / (slideData.tile_height * slideData.uperpixel));
            let bounds = [
                [xImageCoord * (slideData.tile_width * slideData.uperpixel), yImageCoord * (slideData.tile_height * slideData.uperpixel)],
                [(xImageCoord + 1) * (slideData.tile_width * slideData.uperpixel), yImageCoord * (slideData.tile_height * slideData.uperpixel)],
                [(xImageCoord + 1) * (slideData.tile_width * slideData.uperpixel), (yImageCoord + 1) * (slideData.tile_height * slideData.uperpixel)],
                [xImageCoord * (slideData.tile_width * slideData.uperpixel), (yImageCoord + 1) * (slideData.tile_height * slideData.uperpixel)],
                [xImageCoord * (slideData.tile_width * slideData.uperpixel), yImageCoord * (slideData.tile_height * slideData.uperpixel)]         
            ];
            drawRectangle(bounds, vectorSource, false, 1, "#eaff00");
        }
        if (streaks[streakIdx]['pathUnits'][k].rescan) {    
            let xImageCoord = Math.floor(x / (slideData.tile_width * slideData.uperpixel));
            let yImageCoord = Math.floor(y / (slideData.tile_height * slideData.uperpixel));
            let bounds = [
                [(xImageCoord+0.25) * (slideData.tile_width * slideData.uperpixel), (yImageCoord+0.25) * (slideData.tile_height * slideData.uperpixel)],
                [(xImageCoord+0.75) * (slideData.tile_width * slideData.uperpixel), (yImageCoord+0.25) * (slideData.tile_height * slideData.uperpixel)],
                [(xImageCoord+0.75) * (slideData.tile_width * slideData.uperpixel), (yImageCoord+0.75) * (slideData.tile_height * slideData.uperpixel)],
                [(xImageCoord+0.25) * (slideData.tile_width * slideData.uperpixel), (yImageCoord+0.75) * (slideData.tile_height * slideData.uperpixel)],
                [(xImageCoord+0.5) * (slideData.tile_width * slideData.uperpixel), (yImageCoord+0.5) * (slideData.tile_height * slideData.uperpixel)]        
            ];
            if(streaks[streakIdx]['islandId'] > 1000){
                drawRectangle(bounds, vectorSource, false, 1, "#00FF00");
            }else{
                drawRectangle(bounds, vectorSource, false, 1, "#808080");
            }
        }
    }
}

// scan_ref change
export const getCountyKey = (islandKey, countyId) => {
    // islandKey is dict{"takeNumber": int, "islandId": int}
    // countyId is int
    return `t${islandKey["takeNumber"]}_i${islandKey["islandId"]}_c${countyId}`;
}

// scan_ref change
const plotSingleStreakv2 = (streaks, streakIdx, slideMetaData, slideData, vectorSource) => {
    for(let k = 0; k < streaks[streakIdx]['scanCells'].length; k++) {
        let motorX =  streaks[streakIdx]['scanCells'][k]['position']['xPosition'];
        let motorY =  streaks[streakIdx]['scanCells'][k]['position']['yPosition'];
        let pos = mapMotorToCanvas(motorX, motorY, slideData, slideMetaData);
        let x = pos.x;
//        let y = pos.y;
        let y = slideData.y_fields * (slideData.tile_height* slideData.uperpixel)-pos.y;
        let color = tileViewerPlotColors.startOfStripColor;
        let draw = false;
        if (k === 0) {
            if(streaks[streakIdx]['direction'] === 1)
                color = tileViewerPlotColors.startOfStripColor;
            else
                color = tileViewerPlotColors.startOfSubpathColor;
            draw = true;
        } else if (k === streaks[streakIdx]['scanCells'].length - 1) {
            color = tileViewerPlotColors.endOfStripColor;
            draw = true;
        }
        if (draw) {
            let xImageCoord = Math.floor(x / (slideData.tile_width * slideData.uperpixel));
            let yImageCoord = Math.floor(y / (slideData.tile_height * slideData.uperpixel));
            let popupText = "Streak ID: " + streaks[streakIdx]['id'] + "\n Direction: " + streaks[streakIdx]['direction'] + "\n Trace: " + streaks[streakIdx]['traceID'];
            popupText += "\nXID: " + streaks[streakIdx]['fixedXId'];
            popupText += "\nnumFields: " + streaks[streakIdx]['scanCells'].length;
            popupText += "\ncollected Images: " + streaks[streakIdx]['numImagesTakenWithTrigger'];
            popupText += "\nStart: " + getPosString(streaks[streakIdx]['startPos']) + " End: " +  getPosString(streaks[streakIdx]['endPos']);
            popupText += "\nxImageCoord: " + Math.floor(xImageCoord) + " yImageCoord: " + Math.floor(yImageCoord);

            drawCircle(x, y, 0.5 * slideData.tile_height * slideData.uperpixel, vectorSource, "", 6, color, popupText);

            if (streaks[streakIdx]['traceID'] === 1) {
                drawCircle(x, y, 0.1 * slideData.tile_height * slideData.uperpixel, vectorSource, "", 6, color);
            }

            if(streaks[streakIdx]['islandId'] > 1000){
                drawLine(x + (-0.2 * slideData.tile_width * slideData.uperpixel), y + (0.4 * slideData.tile_height * slideData.uperpixel), 1, vectorSource, streaks[streakIdx]['islandId'], 1, "#ffff00");
            }else{
                drawLine(x + (0.4 * slideData.tile_width * slideData.uperpixel), y + (0.4 * slideData.tile_height * slideData.uperpixel), 1, vectorSource, streaks[streakIdx]['islandId'], 1, "#00ffea");
            }
        }
        const islandKey = streaks[streakIdx]['countyKey']['islandKey']; // dict{"takeNumber": int, "islandId": int}
        const countyId = streaks[streakIdx]['countyKey']['countyId']; // int
        if (streaks[streakIdx]['scanCells'][k]['info'][getCountyKey(islandKey, countyId)].takeImage) {
            let xImageCoord = Math.floor(x / (slideData.tile_width * slideData.uperpixel));
            let yImageCoord = Math.floor(y / (slideData.tile_height * slideData.uperpixel));
            let bounds = [
                [xImageCoord * (slideData.tile_width * slideData.uperpixel), yImageCoord * (slideData.tile_height * slideData.uperpixel)],
                [(xImageCoord + 1) * (slideData.tile_width * slideData.uperpixel), yImageCoord * (slideData.tile_height * slideData.uperpixel)],
                [(xImageCoord + 1) * (slideData.tile_width * slideData.uperpixel), (yImageCoord + 1) * (slideData.tile_height * slideData.uperpixel)],
                [xImageCoord * (slideData.tile_width * slideData.uperpixel), (yImageCoord + 1) * (slideData.tile_height * slideData.uperpixel)],
                [xImageCoord * (slideData.tile_width * slideData.uperpixel), yImageCoord * (slideData.tile_height * slideData.uperpixel)]         
            ];
            drawRectangle(bounds, vectorSource, false, 1, "#eaff00");
        }
        if (streaks[streakIdx]['scanCells'][k].rescan) {    
            let xImageCoord = Math.floor(x / (slideData.tile_width * slideData.uperpixel));
            let yImageCoord = Math.floor(y / (slideData.tile_height * slideData.uperpixel));
            let bounds = [
                [(xImageCoord+0.25) * (slideData.tile_width * slideData.uperpixel), (yImageCoord+0.25) * (slideData.tile_height * slideData.uperpixel)],
                [(xImageCoord+0.75) * (slideData.tile_width * slideData.uperpixel), (yImageCoord+0.25) * (slideData.tile_height * slideData.uperpixel)],
                [(xImageCoord+0.75) * (slideData.tile_width * slideData.uperpixel), (yImageCoord+0.75) * (slideData.tile_height * slideData.uperpixel)],
                [(xImageCoord+0.25) * (slideData.tile_width * slideData.uperpixel), (yImageCoord+0.75) * (slideData.tile_height * slideData.uperpixel)],
                [(xImageCoord+0.5) * (slideData.tile_width * slideData.uperpixel), (yImageCoord+0.5) * (slideData.tile_height * slideData.uperpixel)]        
            ];
            if(streaks[streakIdx]['islandId'] > 1000){
                drawRectangle(bounds, vectorSource, false, 1, "#00FF00");
            }else{
                drawRectangle(bounds, vectorSource, false, 1, "#808080");
            }
        }
    }
}

export const plotStreaks = (slideMetaData, slideData, vectorSource) => {
    let streaksData = slideMetaData.xyzstreaks;
    if("streaks" in streaksData){
        streaksData = streaksData["streaks"];
    }
    for(let i = 0; i < streaksData.length; i++) {
        let streaks = streaksData[i]['second'];
        for(let j = 0; j < streaks.length; j++) {
            if(streaks[j]['pathUnits'] !== undefined) {
                plotSingleStreak(streaks, j, slideMetaData, slideData, vectorSource);
            } else if (streaks[j]['scanCells'] !== undefined) {  // scan_ref change
                plotSingleStreakv2(streaks, j, slideMetaData, slideData, vectorSource);
            }
        }
    }
}

export const plotImageGrid = (slideMetaData, slideData, vectorSource) => {
    let data = slideMetaData.scan_cells.scanCellMap;
    console.log("focus_profile_data", data);
    for(let key in data) {
        let motorX = data[key]['position']['xPosition'];
        let motorY = data[key]['position']['yPosition'];
        let pos = mapMotorToCanvas(motorX, motorY, slideData, slideMetaData);
        let x = pos.x;
//        let y = pos.y;
        let y = slideData.y_fields * (slideData.tile_height* slideData.uperpixel)-pos.y;
        let xImageCoord = Math.floor(x / (slideData.tile_width * slideData.uperpixel));
        let yImageCoord = Math.floor(y / (slideData.tile_height * slideData.uperpixel));

        if(data[key].selectedForScan) {
            let bounds = getBoundsForRectAroundCentre(slideData, xImageCoord, yImageCoord, 0.5);
            drawRectangle(bounds, vectorSource, false, 1, "#eaff00");

            drawCircle(x, y, 0.01 * slideData.tile_height * slideData.uperpixel, vectorSource, "", 1, "#7CFC00");

            let boundsSmall = getBoundsForRectAroundCentre(slideData, xImageCoord, yImageCoord, 0.05);
            drawRectangle(boundsSmall, vectorSource, false, 1, "#eaff00");
        }
        if(data[key].rescan) {
            let bounds = getBoundsForRectAroundCentre(slideData, xImageCoord, yImageCoord, 0.45);
            drawRectangle(bounds, vectorSource, false, 1, "#eaff00");
        }
    }
}

export const plotTiltFocusPoints = (slideMetaData, slideData, vectorSource) => {
    const focusUnits = slideMetaData.tilt_focus_pts;
    let highestPoint = getHighestFocusPoint(slideMetaData);

    console.log("highest tilt focus pt is ", highestPoint);

    console.log("focusUnits are :", focusUnits);
    for (const focusUnit of focusUnits) {
        let motorX =  focusUnit['focusedAt']['xPosition'];
        let motorY =  focusUnit['focusedAt']['yPosition'];
        let pos = mapMotorToCanvas(motorX, motorY, slideData, slideMetaData);
        let x = pos.x;
        let y = pos.y;
        let popupText = (
            <div>
                <Row>
                    <Col>{"Tilt Focus Point"}</Col>
                    <Col>{"X:" + focusUnit['focusedAt']["xPosition"]}</Col>
                    <Col>{"Y:" + focusUnit['focusedAt']["yPosition"]}</Col>
                    <Col>{"focusedUp:" + focusUnit['focusedUp']}</Col>
                    <Col>{"focusedDown:" + focusUnit['focusedDown']}</Col>
                </Row>
            </div>
        );
        drawCircle(x, y, 0.3 * slideData.tile_height * slideData.uperpixel, vectorSource, "", 16, tileViewerPlotColors.tiltFocusPointColor, popupText);        
        if (highestPoint !== null) {
            let zdiff = focusUnit['focusedDown'] - highestPoint; // zdiff is negative (means pt is down wrt highest pt)
            zdiff = Math.round((zdiff + Number.EPSILON) * 100) / 100;
            drawLine(x, y - 0.3*slideData.tile_height * slideData.uperpixel, 1, vectorSource, "Δz " + zdiff, 10, "#115511");
        }
    }
}

// 2dot8 change
// shows adjacentFU for each county
export const plotCountyTiltInfo = (slideMetaData, slideData, vectorSource) => {
    const tiltInfo = slideMetaData.county_order_tilt_info;
    let highestPoint = getHighestFocusPoint(slideMetaData);
    console.debug("highestPoint is ", highestPoint);
    console.log("tiltInfo :", tiltInfo);
    let counter = 1;
    for (const countyTiltInfo of tiltInfo) {
        // show info about adjacentFU's tiled position for that county
        const adjacentFU = countyTiltInfo['focusUnit'];
        
        const countyBoundary = countyTiltInfo["county"]["countyBoundary"];
        let rectStartPoint = imagetoCanvasPosHelper(slideMetaData, slideData, slideData.x_fields - countyBoundary["x"], countyBoundary["y"]);
        let rectEndPoint = imagetoCanvasPosHelper(slideMetaData, slideData, 
            slideData.x_fields - countyBoundary["x"] - countyBoundary["width"], 
            countyBoundary["y"] + countyBoundary["height"]);
        const midX = (rectStartPoint.x + rectEndPoint.x) / 2;
        if (adjacentFU === null || adjacentFU === undefined) {
                let popupText = (
                    <div>
                        <Row>
                            {"Adjacent Focus Unit is null"}
                        </Row>
                    </div>
                );
            drawCircle(midX, rectStartPoint.y - 2*slideData.tile_height * slideData.uperpixel, 0.3 * slideData.tile_height * slideData.uperpixel, vectorSource, "", 10, "#555555", popupText);
            continue;
        }
        console.log(adjacentFU['tiledFocusedAt']);
        let motorPos = adjacentFU['focusedAt'];
        let hasMotorPos = (motorPos !== null && motorPos !== undefined);
        let motorX = motorPos['xPosition'];
        let motorY = motorPos['yPosition'];
        if (adjacentFU['tiledFocusedAt'] === null) {
            adjacentFU['tiledFocusedAt'] = {'first': "unknown", 'second': "unknown"};
        }

        let link1 = "app://" + slideData.loc_on_machine + "/scans/" + slideData.bucket_name + "/" + slideData.path + "debug/zprofile/";
        let popupText = (
            <div>
                <Row>
                    {"AdjacentFU ID DOWN " + adjacentFU['unifiedFocusId'] + 
                    "(" + adjacentFU['focusedDown'] + ")"}
                </Row>
                <Row>{"County Key: " + getCountyKey(countyTiltInfo['adjacentFUCountyKey']['islandKey'], countyTiltInfo['adjacentFUCountyKey']['countyId'])}</Row>
                <Row>
                    <Col span={11}>
                        <a href={link1}>{"ID " + adjacentFU['unifiedFocusId'] + " Location"}</a>
                    </Col>
                </Row>
                <Row>
                    {"Tiled position Col: " + adjacentFU['tiledFocusedAt']['first'] + " Row: " + adjacentFU['tiledFocusedAt']['second']}
                </Row>
            </div>
        );
        
        drawCircle(midX, rectStartPoint.y - 2*slideData.tile_height * slideData.uperpixel, 0.3 * slideData.tile_height * slideData.uperpixel, vectorSource, "", 10, "#555555", popupText);
        drawLine(midX, 
            rectStartPoint.y - slideData.tile_height * slideData.uperpixel, 1, vectorSource, "Scan Order:" + counter.toString(), 10, "#115511");
        // also draw at the actual point of focus
        if (hasMotorPos) {
            let pos = mapMotorToCanvas(motorX, motorY, slideData, slideMetaData);
            let x = pos.x;
            let y = pos.y;
            let popupText2 = (
                <div>
                    <Row>
                        {"Focus ID DOWN " + adjacentFU['unifiedFocusId'] + 
                        "(" +adjacentFU['focusedDown']+ ")"}
                    </Row>
                    <Row>
                        <Col span={11}>
                            <a href={link1}>{"ID " + adjacentFU['unifiedFocusId'] + " Location"}</a>
                        </Col>
                    </Row>
                </div>
            );
            drawCircle(x, y, 0.4 * slideData.tile_height * slideData.uperpixel, vectorSource, "", 10, "#11EE11", popupText2);

            if (highestPoint !== null) {
                let zdiff = adjacentFU['focusedDown'] - highestPoint; // zdiff is negative (means pt is down wrt highest pt)
                zdiff = Math.round((zdiff + Number.EPSILON) * 100) / 100;
                drawLine(x, y-0.4*slideData.tile_height * slideData.uperpixel, 1, vectorSource, "Δz " + zdiff, 10, "#115511");
            }
        }
        counter += 1;
    }
}

const getHighestFocusPoint = (slideMetaData) => {
    let islandData = slideMetaData.focus_profile;
    let highestPoint = null;

    const tiltFocusUnits = slideMetaData.tilt_focus_pts;

    // tilt focus units
    for (const focusUnit of tiltFocusUnits) {
        if (focusUnit !== null) {
            if (highestPoint === null || focusUnit['focusedDown'] > highestPoint) {
                highestPoint = focusUnit['focusedDown'];
            }
        }
    }

    // county focus units (both takes)
    for(let i = 0; i < islandData.length; i++) {

        let countyData = islandData[i]["counties"];
        for (let idx = 0; idx < countyData.length; idx++) {
            let focusList = countyData[idx]['focusList'];
            let dualFocusList = countyData[idx]['dualFocusList'];
            if (dualFocusList !== undefined) {
                focusList = focusList.concat(dualFocusList);
            }
            if(focusList !== undefined) {
                for(let j = 0; j < focusList.length; j++) {
                    if(focusList[j]['focusedAt'] !== null) {
                        if (highestPoint === null || focusList[j]['focusedDown'] > highestPoint) {
                            highestPoint = focusList[j]['focusedDown'];
                        }
                    }
                }
            }
        }
    }

    // adjacent focus units (for wsi 3.0 and version 2.8)
    try {
        const tiltInfo = slideMetaData.county_order_tilt_info;
        console.debug("tiltInfo :", tiltInfo);
        for (const countyTiltInfo of tiltInfo) {
            const adjacentFU = countyTiltInfo['focusUnit'];
            if (adjacentFU === null || adjacentFU === undefined) {
                continue;
            }
            let motorPos = adjacentFU['focusedAt'];
            if (motorPos !== undefined && motorPos !== null) {
                if (highestPoint === null || adjacentFU['focusedDown'] > highestPoint) {
                    highestPoint = adjacentFU['focusedDown'];
                }
            }
        }
    } catch (error) {
        console.error("error while using county tilt pts", error);
    }

    return highestPoint;
}

const plotFocusPointsv2 = (slideMetaData, slideData, vectorSource, takeNumber, colorHex) => {
    let islandData = slideMetaData.focus_profile;
    let highestPoint = getHighestFocusPoint(slideMetaData);
    console.debug("highestPoint is ", highestPoint);
    
    for(let i = 0; i < islandData.length; i++) {
        // only plot focus pts for given takeNumber
        if (islandData[i]["islandKey"]["takeNumber"] !== takeNumber) {
            continue;
        }

        let countyData = islandData[i]["counties"];
        for (let idx = 0; idx < countyData.length; idx++) {
            let focusList = countyData[idx]['focusList'];
            let dualFocusList = countyData[idx]['dualFocusList'];
            if (dualFocusList !== undefined) {
                focusList = focusList.concat(dualFocusList);
            }
            if(focusList !== undefined) {
                for(let j = 0; j < focusList.length; j++) {
                    if(focusList[j]['focusedAt'] !== null) {
                        let motorX =  focusList[j]['focusedAt']['xPosition'];
                        let motorY =  focusList[j]['focusedAt']['yPosition'];
                        let pos = mapMotorToCanvas(motorX, motorY, slideData, slideMetaData);
                        let x = pos.x;
//                        let y = pos.y;
                         let y = slideData.y_fields * (slideData.tile_height* slideData.uperpixel)-pos.y;
                        let zProfilePath1 = '';
                        let zProfilePath2 = '';
                        for (let l = 0; l < slideMetaData.focus_profile_dir_paths.length; l++) {
                            if (focusList[j]['idUp'] === slideMetaData.focus_profile_dir_paths[l][0]) {
                                zProfilePath1 = slideMetaData.focus_profile_dir_paths[l];
                            } else if (focusList[j]['idDown'] === slideMetaData.focus_profile_dir_paths[l][0]) {
                                zProfilePath2 = slideMetaData.focus_profile_dir_paths[l];
                            }
                        }
                        let link2 = "app://" + slideData.loc_on_machine + "/scans/" + slideData.bucket_name + "/" + slideData.path + "debug/zprofile/" + zProfilePath2 + "/";
                        let popupText = (
                            <div>
                                <Row>
                                    {"Focus ID DOWN " + focusList[j]['unifiedFocusId'] + 
                                    "(" +focusList[j]['focusedDown']+ ")"}
                                </Row>
                                <Row>
                                    <Col span={11}>
                                        <a href={link2}>{"ID " + focusList[j]['unifiedFocusId'] + " Location"}</a>
                                    </Col>
                                </Row>
                            </div>
                        );
                        drawCircle(x, y, 0.3 * slideData.tile_height * slideData.uperpixel, vectorSource, "", 16, colorHex, popupText);
                        if (highestPoint !== null) {
                            let zdiff = focusList[j]['focusedDown'] - highestPoint; // zdiff is negative (means pt is down wrt highest pt)
                            zdiff = Math.round((zdiff + Number.EPSILON) * 100) / 100;
                            drawLine(x, y - 0.3*slideData.tile_height * slideData.uperpixel, 1, vectorSource, "Δz " + zdiff, 10, "#115511");
                        }
                    }
                }
            }
        }
    }
}


export const plotFocusPoints = (slideMetaData, slideData, vectorSource) => {
    let data = slideMetaData.focus_profile;
    if (data.length > 0 && data[0]["counties"] === undefined) {
        for(let i = 0; i < data.length; i++) {
            let focusList = data[i]['focusList'];
            if(focusList !== undefined){
                for(let j = 0; j < focusList.length; j++) {
                    if(focusList[j]['focusedAt'] !== null) {
                        let motorX =  focusList[j]['focusedAt']['xPosition'];
                        let motorY =  focusList[j]['focusedAt']['yPosition'];
                        let pos = mapMotorToCanvas(motorX, motorY, slideData, slideMetaData);
                        let x = pos.x;
                        let y = pos.y;
                        let zProfilePath1 = '';
                        let zProfilePath2 = '';
                        for (let l = 0; l < slideMetaData.focus_profile_dir_paths.length; l++) {
                            if (focusList[j]['idUp'] === slideMetaData.focus_profile_dir_paths[l][0]) {
                                zProfilePath1 = slideMetaData.focus_profile_dir_paths[l];
                            } else if (focusList[j]['idDown'] === slideMetaData.focus_profile_dir_paths[l][0]) {
                                zProfilePath2 = slideMetaData.focus_profile_dir_paths[l];
                            }
                        }
                        let link1 = "app://" + slideData.loc_on_machine + "/scans/" + slideData.bucket_name + "/" + slideData.path + "debug/zprofile/" + zProfilePath1 + "/";
                        let link2 = "app://" + slideData.loc_on_machine + "/scans/" + slideData.bucket_name + "/" + slideData.path + "debug/zprofile/" + zProfilePath2 + "/";
                        let popupText = (
                            <div>
                                <Row>
                                    {"Focus ID UP " + focusList[j]['unifiedFocusId'] + "(" +focusList[j]['focusedUp']+ ")" + ", ID DOWN " + focusList[j]['unifiedFocusId'] + 
                                    "(" +focusList[j]['focusedDown']+ ")"}
                                </Row>
                                <Row>
                                <Col offset={1} span={11}>
                                        <a href={link1}>{"ID " + focusList[j]['unifiedFocusId'] + " Location"}</a>
                                    </Col>
                                    <Col span={11}>
                                        <a href={link2}>{"ID " + focusList[j]['unifiedFocusId'] + " Location"}</a>
                                    </Col>
                                </Row>
                            </div>
                        );
                        drawCircle(x, y, 0.3 * slideData.tile_height * slideData.uperpixel, vectorSource, "", 16, tileViewerPlotColors.doneFocusColor, popupText);
                    }
                }
            }
        }
    } else { // scan_ref
        plotFocusPointsv2(slideMetaData, slideData, vectorSource, 0, tileViewerPlotColors.doneFocusColor);
    }
}

export const plotFocusTriangles = (slideMetaData, slideData, vectorSource) => {
    let data = slideMetaData.triangles;
    let filteredData = slideMetaData.filter_triangles;
    for(let i = 0; i < data.length; i++) {
        if(!filteredData.includes(i)) {
            let trianglesList = data[i];
            let points = [];
            for(let j = 0; j < trianglesList.length; j++) {
                if(trianglesList[j]['focusedAt'] !== null) {
                    let motorX =  trianglesList[j]['focusedAt']['xPosition'];
                    let motorY =  trianglesList[j]['focusedAt']['yPosition'];
                    let pos = mapMotorToCanvas(motorX, motorY, slideData, slideMetaData);
                    let x = pos.x;
                    let y = pos.y;
                    points.push([x, y]);
                }
            }
            if(points.length === 3) {
                let bounds = [
                    [points[0][0], points[0][1]],
                    [points[1][0], points[1][1]],
                    [points[2][0], points[2][1]],
                    [points[0][0], points[0][1]]      
                ];                  
                drawTriangle(bounds, vectorSource, false, 2, tileViewerPlotColors.focusTrianglesColor);
            }
        }
    }
}

export const plotFocusFilterTriangles = (slideMetaData, slideData, vectorSource) => {
    let data = slideMetaData.triangles;
    let filteredData = slideMetaData.filter_triangles;
    for(let i = 0; i < data.length; i++) {
        if(filteredData.includes(i)) {
            let trianglesList = data[i];
            let points = [];
            for(let j = 0; j < trianglesList.length; j++) {
                if(trianglesList[j]['focusedAt'] !== null) {
                    let motorX =  trianglesList[j]['focusedAt']['xPosition'];
                    let motorY =  trianglesList[j]['focusedAt']['yPosition'];
                    let pos = mapMotorToCanvas(motorX, motorY, slideData, slideMetaData);
                    let x = pos.x;
                    let y = pos.y;
                    points.push([x, y]);
                }
            }
            if(points.length === 3) {
                let bounds = [
                    [points[0][0], points[0][1]],
                    [points[1][0], points[1][1]],
                    [points[2][0], points[2][1]],
                    [points[0][0], points[0][1]]      
                ];                  
                drawTriangle(bounds, vectorSource, false, 2, tileViewerPlotColors.focusFilterTrianglesColor);
            }
        }
    }
}

export const plotZHeatMaps = (slideMetaData, slideData, actualZVectorSource, goldenZVectorSource, setState) => {
    let data = slideMetaData.focus_profile;
    console.log(data)
    let bestdata = slideMetaData.best_focus_profiles;
    let minPlannedvsActualFUpDiff = 10000.0, maxPlannedvsActualFUpDiff = -10000.0;
    let minPlannedvsBestFUpDiff = 10000.0, maxPlannedvsBestFUpDiff = -10000.0;
    for(let i = 0; i < data.length; i++) {
        if(data[i]['combList'] !== undefined){
            for(let c = 0; c < data[i]['combList'].length; c++) {
                for(let f = 0; f < data[i]['combList'][c]['fieldList'].length; f++) {
                    if(data[i]['combList'][c]['fieldList'][f]['estimatedZ'] !== null && data[i]['combList'][c]['fieldList'][f]['scannedZ'] !== null) {
                        minPlannedvsActualFUpDiff = Math.min(Math.abs(data[i]['combList'][c]['fieldList'][f]['estimatedZ']['up'] - data[i]['combList'][c]['fieldList'][f]['scannedZ']['up']), minPlannedvsActualFUpDiff);
                        maxPlannedvsActualFUpDiff = Math.max(Math.abs(data[i]['combList'][c]['fieldList'][f]['estimatedZ']['up'] - data[i]['combList'][c]['fieldList'][f]['scannedZ']['up']), maxPlannedvsActualFUpDiff);
                        for(let d = 0; d < bestdata.length; d++) {
                            if(data[i]['combList'][c]['fieldList'][f]['estimatedZ'] !== null &&
                            bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ'] !== undefined &&
                            bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ'] !== null &&
                            bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ']['up'] !== null) {
                                minPlannedvsBestFUpDiff = Math.min(Math.abs(data[i]['combList'][c]['fieldList'][f]['estimatedZ']['up'] - bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ']['up']), minPlannedvsBestFUpDiff);
                                maxPlannedvsBestFUpDiff = Math.max(Math.abs(data[i]['combList'][c]['fieldList'][f]['estimatedZ']['up'] - bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ']['up']), maxPlannedvsBestFUpDiff); 
                            }
                        }
                    }
                }
            }
        }
    }
    setState({
        minPlannedvsActualFUpDiff,
        maxPlannedvsActualFUpDiff,
        minPlannedvsBestFUpDiff,
        maxPlannedvsBestFUpDiff,
    });
    console.log("minPlannedvsActualFUpDiff = " + minPlannedvsActualFUpDiff)
    console.log("maxPlannedvsActualFUpDiff = " + maxPlannedvsActualFUpDiff)
    console.log("minPlannedvsBestFUpDiff = " + minPlannedvsBestFUpDiff)
    console.log("maxPlannedvsBestFUpDiff = " + maxPlannedvsBestFUpDiff)
    for(let i = 0; i < data.length; i++) {
        if(data[i]['combList'] !== undefined){
            for(let c = 0; c < data[i]['combList'].length; c++) {
                for(let f = 0; f < data[i]['combList'][c]['fieldList'].length; f++) {
                    if(data[i]['combList'][c]['fieldList'][f].takeImage) {
                        let motorX = data[i]['combList'][c]['fieldList'][f]['distance']['xPosition'];
                        let motorY = data[i]['combList'][c]['fieldList'][f]['distance']['yPosition'];
                        let pos = mapMotorToCanvas(motorX, motorY, slideData, slideMetaData);
                        let x = pos.x;
                        let y = pos.y;

                        let plannedFUp, actualFUp, bestFUp;

                        if (data[i]['combList'][c]['fieldList'][f]['estimatedZ'] !== null) {
                            plannedFUp = data[i]['combList'][c]['fieldList'][f]['estimatedZ']['up'];
                        }

                        if (data[i]['combList'][c]['fieldList'][f]['scannedZ'] !== null) {
                            actualFUp = data[i]['combList'][c]['fieldList'][f]['scannedZ']['up'];
                        }

                        for(let d = 0; d < bestdata.length; d++){
                            if(bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ'] !== undefined &&
                            bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ'] !== null &&
                            bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ']['up'] !== null){
                                bestFUp = bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ']['up']; 
                            }
                        }

                        let plannedVsActualColorScore = 180 - Math.floor(180 * ((Math.abs(plannedFUp - actualFUp) - minPlannedvsActualFUpDiff) / (maxPlannedvsActualFUpDiff - minPlannedvsActualFUpDiff)));
                        let rgbPlannedVsActualColorScore = hsvToRgb(plannedVsActualColorScore / 360, 1, 1);
                        let plannedVsActualColor = fullColorHex(rgbPlannedVsActualColorScore.r, rgbPlannedVsActualColorScore.g, rgbPlannedVsActualColorScore.b);
                        
                        let plannedVsGoldenColorScore = 180 - Math.floor(180 * ((Math.abs(plannedFUp - bestFUp) - minPlannedvsBestFUpDiff) / (maxPlannedvsBestFUpDiff - minPlannedvsBestFUpDiff)));
                        let rgbPlannedVsGoldenColorScore = hsvToRgb(plannedVsGoldenColorScore / 360, 1, 1);
                        let plannedVsGoldenColor = fullColorHex(rgbPlannedVsGoldenColorScore.r, rgbPlannedVsGoldenColorScore.g, rgbPlannedVsGoldenColorScore.b);

                        if (isNaN(plannedVsActualColorScore)) {
                            plannedVsActualColor = fullColorHex(0, 0, 0) + "00";
                        }

                        if (isNaN(plannedVsGoldenColorScore)) {
                            plannedVsGoldenColor = fullColorHex(0, 0, 0) + "00";
                        }

                        // let overlayText = "Est vs Scan Actual Diff: " + Math.abs(plannedFUp - actualFUp).toFixed(2) + ", Norm: " + (180 * Math.abs(plannedFUp - actualFUp)).toFixed(2);
                        // overlayText += "Est vs Best Diff: " + Math.abs(plannedFUp - bestFUp).toFixed(2) + ", Norm: " + (180 * Math.abs(plannedFUp - bestFUp)).toFixed(2);
                        // overlayText += "\nEstimated: (" + data[i]['combList'][c]['fieldList'][f]['estimatedZ']['up'] + "," + data[i]['combList'][c]['fieldList'][f]['estimatedZ']['down'] + ")"; 
                        // overlayText += "\nUsed: (" + data[i]['combList'][c]['fieldList'][f]['scannedZ']['up'] + "," + data[i]['combList'][c]['fieldList'][f]['scannedZ']['down'] + ")"; 

                        // for(let d = 0; d < bestdata.length; d++){
                        //     if(bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ'] !== undefined){
                        //         overlayText += "\nBest: (" + bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ']['up'] + "," + bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ']['down'] + ")"; 
                        //     }
                        // }

                        let bestUpZ, bestDownZ;
                        for(let d = 0; d < bestdata.length; d++){
                            if(bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ'] !== undefined){
                                bestUpZ = bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ']['up'];
                                bestDownZ = bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ']['down'];
                            }
                        }

                        let popupText = (
                            <div>
                                <Row>
                                    Estimated vs Scanned
                                </Row>
                                <Divider />
                                <Row>
                                    <Col span={10}>
                                        Diff:
                                    </Col>
                                    <Col span={14}>
                                        {Math.abs(plannedFUp - actualFUp).toFixed(2)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={10}>
                                        Norm:
                                    </Col>
                                    <Col span={14}>
                                        {Math.floor(180 * ((Math.abs(plannedFUp - actualFUp) - minPlannedvsActualFUpDiff) / (maxPlannedvsActualFUpDiff - minPlannedvsActualFUpDiff))) + " (180)"}
                                    </Col>
                                </Row>
                                <Divider />
                                <Row>
                                    Estimated vs Best
                                </Row>
                                <Divider />
                                <Row>
                                    <Col span={10}>
                                        Diff:
                                    </Col>
                                    <Col span={14}>
                                        {Math.abs(plannedFUp - bestFUp).toFixed(2)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={10}>
                                        Norm:
                                    </Col>
                                    <Col span={14}>
                                        {Math.floor(180 * ((Math.abs(plannedFUp - bestFUp) - minPlannedvsBestFUpDiff) / (maxPlannedvsBestFUpDiff - minPlannedvsBestFUpDiff))) + " (180)"}
                                    </Col>
                                </Row>
                                <Divider />
                                <Row>
                                    Estimated Data
                                </Row>
                                <Divider />
                                <Row>
                                    <Col span={10}>
                                        Up:
                                    </Col>
                                    <Col span={14}>
                                        {data[i]['combList'][c]['fieldList'][f]['estimatedZ']['up']}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={10}>
                                        Down:
                                    </Col>
                                    <Col span={14}>
                                        {data[i]['combList'][c]['fieldList'][f]['estimatedZ']['down']}
                                    </Col>
                                </Row>
                                <Divider />
                                <Row>
                                    Scanned Data
                                </Row>
                                <Divider />
                                <Row>
                                    <Col span={10}>
                                        Up:
                                    </Col>
                                    <Col span={14}>
                                        {data[i]['combList'][c]['fieldList'][f]['scannedZ']['up']}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={10}>
                                        Down:
                                    </Col>
                                    <Col span={14}>
                                        {data[i]['combList'][c]['fieldList'][f]['scannedZ']['down']}
                                    </Col>
                                </Row>
                                <Divider />
                                <Row>
                                    Best Data
                                </Row>
                                <Divider />
                                <Row>
                                    <Col span={10}>
                                        Up:
                                    </Col>
                                    <Col span={14}>
                                        {bestUpZ}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={10}>
                                        Down:
                                    </Col>
                                    <Col span={14}>
                                        {bestDownZ}
                                    </Col>
                                </Row>
                                <Divider />
                            </div>
                        );

                        if (!isNaN(plannedVsActualColorScore)) {
                            drawCircleFill(x, y, 0.3 * slideData.tile_height * slideData.uperpixel, actualZVectorSource, "", true, 2, plannedVsActualColor, popupText);
                        }
                        
                        if (!isNaN(plannedVsGoldenColorScore)) {
                            drawCircleFill(x, y, 0.3 * slideData.tile_height * slideData.uperpixel, goldenZVectorSource, "", true, 2, plannedVsGoldenColor, popupText);
                        }
                    }
                }
            }
        }
    }
}

export const plotDownZHeatMaps = (slideMetaData, slideData, actualZVectorSource, goldenZVectorSource, setState) => {
    let data = slideMetaData.focus_profile;
    let bestdata = slideMetaData.best_focus_profiles;
    let minPlannedvsActualFDownDiff = 10000.0, maxPlannedvsActualFDownDiff = -10000.0;
    let minPlannedvsBestFDownDiff = 10000.0, maxPlannedvsBestFDownDiff = -10000.0;
    for(let i = 0; i < data.length; i++) {
        for(let c = 0; c < (data[i]['combList'] | []).length; c++) {
            for(let f = 0; f < data[i]['combList'][c]['fieldList'].length; f++) {
                if(data[i]['combList'][c]['fieldList'][f]['estimatedZ'] !== null && data[i]['combList'][c]['fieldList'][f]['scannedZ'] !== null) {
                    minPlannedvsActualFDownDiff = Math.min(Math.abs(data[i]['combList'][c]['fieldList'][f]['estimatedZ']['down'] - data[i]['combList'][c]['fieldList'][f]['scannedZ']['down']), minPlannedvsActualFDownDiff);
                    maxPlannedvsActualFDownDiff = Math.max(Math.abs(data[i]['combList'][c]['fieldList'][f]['estimatedZ']['down'] - data[i]['combList'][c]['fieldList'][f]['scannedZ']['down']), maxPlannedvsActualFDownDiff);
                    for(let d = 0; d < bestdata.length; d++) {
                        if(data[i]['combList'][c]['fieldList'][f]['estimatedZ'] !== null &&
                        bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ'] !== undefined &&
                        bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ'] !== null &&
                        bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ']['down'] !== null) {
                            minPlannedvsBestFDownDiff = Math.min(Math.abs(data[i]['combList'][c]['fieldList'][f]['estimatedZ']['down'] - bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ']['down']), minPlannedvsBestFDownDiff);
                            maxPlannedvsBestFDownDiff = Math.max(Math.abs(data[i]['combList'][c]['fieldList'][f]['estimatedZ']['down'] - bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ']['down']), maxPlannedvsBestFDownDiff); 
                        }
                    }
                }
            }
        }
    }
    setState({
        minPlannedvsActualFDownDiff: minPlannedvsActualFDownDiff,
        maxPlannedvsActualFDownDiff: maxPlannedvsActualFDownDiff,
        minPlannedvsBestFDownDiff: minPlannedvsBestFDownDiff,
        maxPlannedvsBestFDownDiff: maxPlannedvsBestFDownDiff,
    });
    console.log("minPlannedvsActualFDownDiff = " + minPlannedvsActualFDownDiff)
    console.log("maxPlannedvsActualFDownDiff = " + maxPlannedvsActualFDownDiff)
    console.log("minPlannedvsBestFDownDiff = " + minPlannedvsBestFDownDiff)
    console.log("maxPlannedvsBestFDownDiff = " + maxPlannedvsBestFDownDiff)
    for(let i = 0; i < data.length; i++) {
        for(let c = 0; c < (data[i]['combList'] | []).length; c++) {
            for(let f = 0; f < data[i]['combList'][c]['fieldList'].length; f++) {
                if(data[i]['combList'][c]['fieldList'][f].takeImage) {
                    let motorX = data[i]['combList'][c]['fieldList'][f]['distance']['xPosition'];
                    let motorY = data[i]['combList'][c]['fieldList'][f]['distance']['yPosition'];
                    let pos = mapMotorToCanvas(motorX, motorY, slideData, slideMetaData);
                    let x = pos.x;
                    let y = pos.y;

                    let plannedFDown, actualFDown, bestFDown;

                    if (data[i]['combList'][c]['fieldList'][f]['estimatedZ'] !== null) {
                        plannedFDown = data[i]['combList'][c]['fieldList'][f]['estimatedZ']['down'];
                    }

                    if (data[i]['combList'][c]['fieldList'][f]['scannedZ'] !== null) {
                        actualFDown = data[i]['combList'][c]['fieldList'][f]['scannedZ']['down'];
                    }

                    for(let d = 0; d < bestdata.length; d++){
                        if(bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ'] !== undefined &&
                        bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ'] !== null &&
                        bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ']['down'] !== null){
                            bestFDown = bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ']['down']; 
                        }
                    }

                    let plannedVsActualColorScore = 180 - Math.floor(180 * ((Math.abs(plannedFDown - actualFDown) - minPlannedvsActualFDownDiff) / (maxPlannedvsActualFDownDiff - minPlannedvsActualFDownDiff)));
                    let rgbPlannedVsActualColorScore = hsvToRgb(plannedVsActualColorScore / 360, 1, 1);
                    let plannedVsActualColor = fullColorHex(rgbPlannedVsActualColorScore.r, rgbPlannedVsActualColorScore.g, rgbPlannedVsActualColorScore.b);
                    
                    let plannedVsGoldenColorScore = 180 - Math.floor(180 * ((Math.abs(plannedFDown - bestFDown) - minPlannedvsBestFDownDiff) / (maxPlannedvsBestFDownDiff - minPlannedvsBestFDownDiff)));
                    let rgbPlannedVsGoldenColorScore = hsvToRgb(plannedVsGoldenColorScore / 360, 1, 1);
                    let plannedVsGoldenColor = fullColorHex(rgbPlannedVsGoldenColorScore.r, rgbPlannedVsGoldenColorScore.g, rgbPlannedVsGoldenColorScore.b);

                    if (isNaN(plannedVsActualColorScore)) {
                        plannedVsActualColor = fullColorHex(0, 0, 0) + "00";
                    }

                    if (isNaN(plannedVsGoldenColorScore)) {
                        plannedVsGoldenColor = fullColorHex(0, 0, 0) + "00";
                    }

                    // let overlayText = "Est vs Scan Actual Diff: " + Math.abs(plannedFUp - actualFUp).toFixed(2) + ", Norm: " + (180 * Math.abs(plannedFUp - actualFUp)).toFixed(2);
                    // overlayText += "Est vs Best Diff: " + Math.abs(plannedFUp - bestFUp).toFixed(2) + ", Norm: " + (180 * Math.abs(plannedFUp - bestFUp)).toFixed(2);
                    // overlayText += "\nEstimated: (" + data[i]['combList'][c]['fieldList'][f]['estimatedZ']['up'] + "," + data[i]['combList'][c]['fieldList'][f]['estimatedZ']['down'] + ")"; 
                    // overlayText += "\nUsed: (" + data[i]['combList'][c]['fieldList'][f]['scannedZ']['up'] + "," + data[i]['combList'][c]['fieldList'][f]['scannedZ']['down'] + ")"; 

                    // for(let d = 0; d < bestdata.length; d++){
                    //     if(bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ'] !== undefined){
                    //         overlayText += "\nBest: (" + bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ']['up'] + "," + bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ']['down'] + ")"; 
                    //     }
                    // }

                    let bestUpZ, bestDownZ;
                    for(let d = 0; d < bestdata.length; d++){
                        if(bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ'] !== undefined){
                            bestUpZ = bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ']['up'];
                            bestDownZ = bestdata[d][i]['combList'][c]['fieldList'][f]['estimatedZ']['down'];
                        }
                    }

                    let popupText = (
                        <div>
                            <Row>
                                Estimated vs Scanned
                            </Row>
                            <Divider />
                            <Row>
                                <Col span={10}>
                                    Diff:
                                </Col>
                                <Col span={14}>
                                    {Math.abs(plannedFDown - actualFDown).toFixed(2)}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={10}>
                                    Norm:
                                </Col>
                                <Col span={14}>
                                    {Math.floor(180 * ((Math.abs(plannedFDown - actualFDown) - minPlannedvsActualFDownDiff) / (maxPlannedvsActualFDownDiff - minPlannedvsActualFDownDiff))) + " (180)"}
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                Estimated vs Best
                            </Row>
                            <Divider />
                            <Row>
                                <Col span={10}>
                                    Diff:
                                </Col>
                                <Col span={14}>
                                    {Math.abs(plannedFDown - bestFDown).toFixed(2)}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={10}>
                                    Norm:
                                </Col>
                                <Col span={14}>
                                    {Math.floor(180 * ((Math.abs(plannedFDown - bestFDown) - minPlannedvsBestFDownDiff) / (maxPlannedvsBestFDownDiff - minPlannedvsBestFDownDiff))) + " (180)"}
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                Estimated Data
                            </Row>
                            <Divider />
                            <Row>
                                <Col span={10}>
                                    Up:
                                </Col>
                                <Col span={14}>
                                    {data[i]['combList'][c]['fieldList'][f]['estimatedZ']['up']}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={10}>
                                    Down:
                                </Col>
                                <Col span={14}>
                                    {data[i]['combList'][c]['fieldList'][f]['estimatedZ']['down']}
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                Scanned Data
                            </Row>
                            <Divider />
                            <Row>
                                <Col span={10}>
                                    Up:
                                </Col>
                                <Col span={14}>
                                    {data[i]['combList'][c]['fieldList'][f]['scannedZ']['up']}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={10}>
                                    Down:
                                </Col>
                                <Col span={14}>
                                    {data[i]['combList'][c]['fieldList'][f]['scannedZ']['down']}
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                Best Data
                            </Row>
                            <Divider />
                            <Row>
                                <Col span={10}>
                                    Up:
                                </Col>
                                <Col span={14}>
                                    {bestUpZ}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={10}>
                                    Down:
                                </Col>
                                <Col span={14}>
                                    {bestDownZ}
                                </Col>
                            </Row>
                            <Divider />
                        </div>
                    );

                    if (!isNaN(plannedVsActualColorScore)) {
                        drawCircleFill(x, y, 0.3 * slideData.tile_height * slideData.uperpixel, actualZVectorSource, "", true, 2, plannedVsActualColor, popupText);
                    }
                    
                    if (!isNaN(plannedVsGoldenColorScore)) {
                        drawCircleFill(x, y, 0.3 * slideData.tile_height * slideData.uperpixel, goldenZVectorSource, "", true, 2, plannedVsGoldenColor, popupText);
                    }
                }
            }
        }
    }
}

export const plotIslands = (slideMetaData, slideData, vectorSource) => {
    let data = slideMetaData.islands_meta;
    if (data.length > 0 && data[0]["counties"] === undefined) {
        for(let i = 0; i < data.length; i++) {
            console.log(data[i]);
            let rectStartPoint = imagetoCanvasPosHelper(slideMetaData, slideData, data[i]["islandBoundary"]["x"], data[i]["islandBoundary"]["y"]);
            let rectEndPoint = imagetoCanvasPosHelper(slideMetaData, slideData, 
                data[i]["islandBoundary"]["x"] + data[i]["islandBoundary"]["width"], 
                data[i]["islandBoundary"]["y"] + data[i]["islandBoundary"]["height"]);
            let bounds = [
                [rectStartPoint.x, rectStartPoint.y],
                [rectEndPoint.x, rectStartPoint.y],
                [rectEndPoint.x, rectEndPoint.y],
                [rectStartPoint.x, rectEndPoint.y],
                [rectStartPoint.x, rectStartPoint.y]
            ];
            drawRectangle(bounds, vectorSource, false, 4, "#00ffff");
        }
    } else { // scan_ref change
        plotCounties(data, slideMetaData, slideData, vectorSource, 0, "#00ffff");
    }
}

const plotCounties = (islandsData, slideMetaData, slideData, vectorSource, takeNumber, colorHex) => {
    for(let i = 0; i < islandsData.length; i++) {
        console.log(islandsData[i]);
        // only plot counties from given takeNimber
        if (islandsData[i]["islandKey"]["takeNumber"] === takeNumber) {
            for (let idx = 0; idx < islandsData[i]["counties"].length; idx++) {
                
                const countyBoundary = islandsData[i]["counties"][idx]["countyBoundary"];
                let rectStartPoint = imagetoCanvasPosHelper(slideMetaData, slideData, slideData.x_fields - countyBoundary["x"], countyBoundary["y"]);
                let rectEndPoint = imagetoCanvasPosHelper(slideMetaData, slideData, 
                    slideData.x_fields - countyBoundary["x"] - countyBoundary["width"], 
                    countyBoundary["y"] + countyBoundary["height"]);
                let bounds = [
                    [rectStartPoint.x, rectStartPoint.y],
                    [rectEndPoint.x, rectStartPoint.y],
                    [rectEndPoint.x, rectEndPoint.y],
                    [rectStartPoint.x, rectEndPoint.y],
                    [rectStartPoint.x, rectStartPoint.y]
                ];
                drawRectangle(bounds, vectorSource, false, 4, colorHex);
            }
        }
    }
}

export const plotRescanIslands = (slideMetaData, slideData, vectorSource) => {
    let data = slideMetaData.rescan_islands_meta;
    if (data !== undefined){
        for(let i = 0; i < data.length; i++) {
            console.log("islandBoundary", data[i]['islandBoundary']['x'])
            let rectStartPoint = imagetoCanvasPosHelper(slideMetaData, slideData, (data[i]["islandBoundary"])["x"], slideData.y_fields - (data[i]["islandBoundary"])["y"]);
            let rectEndPoint = imagetoCanvasPosHelper(slideMetaData, slideData, 
                data[i]["islandBoundary"]["x"] + data[i]["islandBoundary"]["width"], 
                slideData.y_fields - data[i]["islandBoundary"]["y"] - data[i]["islandBoundary"]["height"]);
            let bounds = [
                [rectStartPoint.x, rectStartPoint.y],
                [rectEndPoint.x, rectStartPoint.y],
                [rectEndPoint.x, rectEndPoint.y],
                [rectStartPoint.x, rectEndPoint.y],
                [rectStartPoint.x, rectStartPoint.y]
            ];
            drawRectangle(bounds, vectorSource, false, 4, "#0000ff");
        }
    }

    let data_rescan = slideMetaData.rescan_path_map;
    if (data_rescan["pathUnits"] !== undefined){
        for(let i=0; i<data_rescan["pathUnits"].length;i++){
            for(let j=0; j<data_rescan["pathUnits"][i].length;j++){

                let motorX =  data_rescan["pathUnits"][i][j]["distance"]["xPosition"];
                let motorY =  data_rescan["pathUnits"][i][j]["distance"]["yPosition"];
                let pos = mapMotorToCanvas(motorX, motorY, slideData, slideMetaData);
                let x = pos.x;
                let y = pos.y;
                
                // let x = data_rescan["pathUnits"][i][j]["tiledMapPosition"].first;
                // let y = data_rescan["pathUnits"][i][j]["tiledMapPosition"].second;
                let rescan = data_rescan["pathUnits"][i][j]["rescan"];

                if (rescan){
                    drawCircle(x, y, 0.3 * slideData.tile_height * slideData.uperpixel, vectorSource, "", 4, "#ff0000");
                }
            }
        }
    }

    // scan_ref change
    if(slideMetaData.islands_meta.length > 0 && slideMetaData.islands_meta[0]["counties"] !== undefined) {
        plotCounties(slideMetaData.islands_meta, slideMetaData, slideData, vectorSource, 1, "#3E54F1");
        plotFocusPointsv2(slideMetaData, slideData, vectorSource, 1, "#21F558");
    }
}


export const mapMotorToCanvas = (xMotorPosition, yMotorPosition, slideData, slideMeta) => {
    let xImagePos = ((parseInt(xMotorPosition) - slideData.x_min + (slideData.x_step / 2)) / slideData.x_step);
    let yImagePos = ((parseInt(yMotorPosition) - slideData.y_min + (slideData.y_step / 2)) / slideData.y_step);
    
    return imagetoCanvasPosHelper(slideMeta, slideData, xImagePos, yImagePos);
}

export const mapMotorToImageStart = (xMotorPosition, yMotorPosition, slideData, slideMeta) => {
    let xImagePos = ((parseInt(xMotorPosition) - slideData.x_min) / slideData.x_step);
    let yImagePos = (slideData.y_fields - (parseInt(yMotorPosition) - slideData.y_min) / slideData.y_step);
    
    return imagetoCanvasPosHelper(slideMeta, slideData, xImagePos, yImagePos);
}



export const mapMotorToImageEnd = (xMotorPosition, yMotorPosition, slideData, slideMeta) => {
    let xImagePos = ((parseInt(xMotorPosition) - slideData.x_min + slideData.x_step) / slideData.x_step);
    let yImagePos = (slideData.y_fields - (parseInt(yMotorPosition) - slideData.y_min + (slideData.y_step)) / slideData.y_step);
    
    return imagetoCanvasPosHelper(slideMeta, slideData, xImagePos, yImagePos);
}


export const mapMotorRectToCanvasRect = (startMotorX, startMotorY, endMotorX, endMotorY, slideData, slideMeta) => {
    let canvasStart = mapMotorToImageStart(startMotorX, startMotorY, slideData, slideMeta);
    let canvasEnd = mapMotorToImageEnd(endMotorX, endMotorY, slideData, slideMeta);
    return [
        [canvasStart.x, canvasStart.y],
        [canvasEnd.x, canvasStart.y],
        [canvasEnd.x, canvasEnd.y],
        [canvasStart.x, canvasEnd.y],
        [canvasStart.x, canvasStart.y]
    ];
}

export const imagetoCanvasPosHelper = (slideMeta, slideData, xImagePos, yImagePos) => {
    let x, y;
    if (slideMeta.device_settings['scanConfig']['flipXYDuringStitching']) {
        let temp = xImagePos;
        xImagePos = yImagePos;
        yImagePos = temp;
    }
//
//    if (slideMeta.device_settings['scanConfig']['flipXYDuringStitching']) {
//        let temp = xImagePos;
//        xImagePos = yImagePos;
//        yImagePos = temp;
//    }

    if (slideMeta.device_settings['scanConfig']['flipXYForTiling']){
        xImagePos = slideData.x_fields - xImagePos;
        yImagePos = slideData.y_fields - yImagePos;
    }

    x = (xImagePos) * (slideData.tile_width * slideData.uperpixel);
    y = (yImagePos) * (slideData.tile_height * slideData.uperpixel);
    
    return {x:x, y:y};
}

export const plotSquareForPosition2DArraylist = (data, slideData, slideMetaData, slidemap, projection, color, size, visible) => {
    let vectorSource = getNewVectorSource();
    let vectorLayer = getPlotVectorLayer(vectorSource, projection);
    slidemap.addLayer(vectorLayer);
    for(let i = 0; i < data.length; i++) {
        plotSquare(data[i], slideData, slideMetaData, vectorSource, color, size, 2);
    }
    vectorLayer.setVisible(visible);
    return vectorLayer;
}

export const plotRescanGroup = (data, slideData, slidemap, projection, slideMeta, color, size, visible) => {
    
    let vectorSource = getNewVectorSource();
    let vectorLayer = getPlotVectorLayer(vectorSource, projection);
    slidemap.addLayer(vectorLayer);

    for(let ci = 0; ci < data.length; ci++) {
        let comb = data[ci];
        plotComb(comb, slideData, vectorSource, tileViewerPlotColors.combBoundaryColor, slideMeta, ci);

        for(let fi = 0; fi < comb.fieldList.length; fi++) {
            if (!comb.fieldList[fi].blank) {
                plotSquare(comb.fieldList[fi].position, slideData, slideMeta, vectorSource, color, size, 2);
            }
        }
    }
    vectorLayer.setVisible(visible);
    return vectorLayer;
}

export const plotSquare = (data, slideData, slideMeta, vectorSource, color, size, strokeWidth) => {
    if(data !== null){
        let motorX = data.xPosition;
        let motorY = data.yPosition;
        let imagePositions = mapMotorToCanvas(motorX, motorY, slideData, slideMeta);
        let x = imagePositions.x;
        let y = imagePositions.y;
        let side = size * (slideData.tile_height * slideData.uperpixel) / 4;
        let bounds = getSquareBounds(x, y, side);
        drawRectangle(bounds, vectorSource, false, strokeWidth, color);
    }
}

export const getSquareBounds = (x, y, side) => {
    return [
        [x - (side / 2), y - (side / 2)],
        [x + (side / 2), y - (side / 2)],
        [x + (side / 2), y + (side / 2)],
        [x - (side / 2), y + (side / 2)],
        [x - (side / 2), y - (side / 2)]
    ]
}

export const drawCircle = (x, y, radius, vectorSource, title, strokeWidth, color="#7CFC00", label="") => {
    drawCircleFill(x, y, radius, vectorSource, title, false, strokeWidth, color, label);
}

export const drawCircleFill = (x, y, radius, vectorSource, title, fill, strokeWidth, color="#7CFC00", label="") => {
    let feature = new Feature({
        geometry: new Circle([x, y], radius),
        name: "circle",
        title: title
    });
    feature.set('color', color);
    feature.set('label', label);
    feature.set('fill', fill);
    feature.set('strokeWidth', strokeWidth);
    vectorSource.addFeature(feature);
}

export const drawLine = (x, y, length, vectorSource, title, strokeWidth, color="#7CFC00", label="") => {
    let feature = new Feature({
        geometry: new LineString([[x, y], [x + length, y]]),
        name: "line",
        title: title
    });
    feature.set('color', color);
    feature.set('label', label);
    feature.set('strokeWidth', strokeWidth);
    vectorSource.addFeature(feature);
}

export const drawRectangle = (bounds, vectorSource, fill, strokeWidth, color="#7CFC00", label="") => {
    let feature = new Feature({
        geometry: new Polygon([bounds]),
        name: "polygon",
        title: ""
    });
    feature.set('color', color);
    feature.set('label', label);
    feature.set('fill', fill);
    feature.set('strokeWidth', strokeWidth);
    vectorSource.addFeature(feature);
}

export const drawTriangle = (bounds, vectorSource, fill, strokeWidth, color="#7CFC00", label="") => {
    let feature = new Feature({
        geometry: new Polygon([bounds]),
        name: "polygon",
        title: ""
    });
    feature.set('color', color);
    feature.set('label', label);
    feature.set('fill', fill);
    feature.set('strokeWidth', strokeWidth);
    vectorSource.addFeature(feature);
}

function getBoundsForRectAroundCentre(slideData, xImageCoord, yImageCoord, offFromCentre) {
    return [
        [(xImageCoord + 0.5 - offFromCentre) * (slideData.tile_width * slideData.uperpixel), (yImageCoord + 0.5 - offFromCentre) * (slideData.tile_height * slideData.uperpixel)],
        [(xImageCoord + 0.5 + offFromCentre) * (slideData.tile_width * slideData.uperpixel), (yImageCoord + 0.5 - offFromCentre) * (slideData.tile_height * slideData.uperpixel)],
        [(xImageCoord + 0.5 + offFromCentre) * (slideData.tile_width * slideData.uperpixel), (yImageCoord + 0.5 + offFromCentre) * (slideData.tile_height * slideData.uperpixel)],
        [(xImageCoord + 0.5 - offFromCentre) * (slideData.tile_width * slideData.uperpixel), (yImageCoord + 0.5 + offFromCentre) * (slideData.tile_height * slideData.uperpixel)],
        [(xImageCoord + 0.5 - offFromCentre) * (slideData.tile_width * slideData.uperpixel), (yImageCoord + 0.5 - offFromCentre) * (slideData.tile_height * slideData.uperpixel)]
    ];
}

