import {LOAD_MORPHEUS_SETTING, UPDATE_MORPHEUS_SETTING} from "../actionTypes/morpheus.setting.constant";

export const morpheusSettingReducer = (state = {}, action) => {
	switch (action.type) {

		case LOAD_MORPHEUS_SETTING:
			return Object.assign({}, action.morpheusSetting);

		case UPDATE_MORPHEUS_SETTING:
			return Object.assign({}, state, action.morpheusSetting);

		default:
			return state;
	}
};
