import html2canvas from "html2canvas";
import {convertSettingsToFilter} from "../../../utils/utils";
import axios from "axios";
import {notifyInfo} from "./display.notification";
import {componentIds, FileType} from "../../../utils/const";
import {AxiosConfig} from "../../../helper/axios.config";
import {displayError} from "../../../helper/display.error";

export function takeScreenshot(elementId, extension, viewerSettings, handler) {
	document.getElementById(componentIds.morphleWatermark).style.visibility = "visible";
	document.getElementById(componentIds.rightBottomApps).style.visibility = "hidden";

	const img = document.getElementById(elementId);
	html2canvas(img).then(canvas => {
		document.getElementById(componentIds.morphleWatermark).style.visibility = "hidden";
		document.getElementById(componentIds.rightBottomApps).style.visibility = "visible";
		extension = extension.slice(1);
		// TODO: filter needs to be merged for split mode
		let filter = convertSettingsToFilter(viewerSettings);
		let downloadableCanvas = document.createElement('canvas');
		downloadableCanvas.width = canvas.width;
		downloadableCanvas.height = canvas.height;
		let context = downloadableCanvas.getContext('2d');
		downloadableCanvas.setAttribute('crossOrigin', 'anonymous');
		context.filter = filter;
		context.drawImage(canvas, 0, 0, canvas.width, canvas.height);
		downloadableCanvas.toBlob(handler, `image/${extension}`, 0.97);
	});
}

export function downloadScreenshot(elementId, name, extension, viewerSettings) {
	takeScreenshot(elementId, extension, viewerSettings, blob => {
		let anchor = document.createElement('a');
		anchor.href = URL.createObjectURL(blob);
		anchor.download = name + extension;
		anchor.style.display = 'none';
		anchor.click();
		anchor.remove();
	});
}

export const addScreenshotToReport = (elementId, slideData, extension, currCase, viewerSettings, loc, cb) => {
	takeScreenshot(elementId, extension, viewerSettings, blob => {
		let formData = new FormData();
		formData.append('file', blob, slideData.name + extension);
		formData.append('case', currCase.id);
		formData.append('slide', slideData.id);
		formData.append('meta', JSON.stringify({
			mapState: loc,
		}));
		formData.append('type', FileType.SCREENSHOT);
		axios.post('/api/files/', formData, AxiosConfig()).then(() => {
			notifyInfo("case-image", "Screenshot added to report");
			cb();
		}).catch((err) => displayError("Error adding screenshot to report", err));
	})
}
