import {BloodGridRBCActions} from "../actionTypes/bloodGridRBC.const";

export const sizeRangeChanged = (value) => ({
    type: BloodGridRBCActions.RBC_UPDATE_SIZE_RANGE,
    response: value,
})

export const meanColorRangeChanged = (value) => ({
    type: BloodGridRBCActions.RBC_UPDATE_MEAN_COLOR_RANGE,
    response: value,
})

export const convexityRangeChanged = (value) => ({
    type: BloodGridRBCActions.RBC_UPDATE_CONVEXITY_RANGE,
    response: value,
})

export const eccentricityRangeChanged = (value) => ({
    type: BloodGridRBCActions.RBC_UPDATE_ECCENTRICITY_RANGE,
    response: value,
})

export const extremeSizeRangeChanged = (value) => ({
    type: BloodGridRBCActions.RBC_UPDATE_EXTREME_SIZE_RANGE,
    response: value,
})

export const extremeConvexityRangeChanged = (value) => ({
    type: BloodGridRBCActions.RBC_UPDATE_EXTREME_CONVEXITY_RANGE,
    response: value,
})

export const extremeEccentricityRangeChanged = (value) => ({
    type: BloodGridRBCActions.RBC_UPDATE_EXTREME_ECCENTRICITY_RANGE,
    response: value,
})

export const extremeMeanColorRangeChanged = (value) => ({
    type: BloodGridRBCActions.RBC_UPDATE_EXTREME_MEAN_COLOR_RANGE,
    response: value,
})

export const rbcGridSelected = (value) => ({
    type: BloodGridRBCActions.RBC_GRID_SELECTED,
    response: value,
})

export const rbcChangeImage = (value) => ({
    type: BloodGridRBCActions.RBC_CHANGE_IMAGE,
    response: value,
})

export const updatePageRBCView = (value) => ({
    type: BloodGridRBCActions.UPDATE_PAGE,
    response: value,
})

export const updatePageSizeRBC = (value) => ({
    type: BloodGridRBCActions.UPDATE_PAGE_SIZE,
    response: value
})

export const updateAllGridsRBC = (value) => ({
    type: BloodGridRBCActions.UPDATE_ALL_GRIDS,
    response: value,
})

export const updateTotalCountRBC = (value) => ({
    type: BloodGridRBCActions.UPDATE_TOTAL_COUNT,
    response: value,
})

export const updateTotalPagesRBC = (value) => ({
    type: BloodGridRBCActions.UPDATE_TOTAL_PAGES,
    response: value,
})

export const updateNextPageSelected = (value) => ({
    type: BloodGridRBCActions.UPDATE_NEW_SELECTED_ON_NEW_PAGE,
    response: value,
})

export const updateCurrentGrids = (value) => ({
    type: BloodGridRBCActions.UPDATE_CURR_GRIDS,
    response: value,
})

export const updateSelectedGridOverallId = (value) => ({
    type: BloodGridRBCActions.SELECTED_GRID_OVERALL_ID,
    response: value,
})

export const updateFetchingGridRBC = (value) => ({
    type: BloodGridRBCActions.UPDATE_FETCHING_GRID,
    response: value
})
