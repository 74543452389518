import React, { Component } from "react";
import { connect } from "react-redux";
import '../asset/style/style.css';
import '../asset/style/custom_context.css';
import { makeMenuInvisible, updateScrollHeight } from "../action/context.menu.action";
import { Modal, message } from 'antd';
import { openInNewTabType } from '../utils/const';


class CustomContext extends Component {
    constructor(props) {
      super(props);
      this.contextRef = React.createRef();
      this.returnMenu = this.returnMenu.bind(this);
      
      this.state={
      };
      
    }
    
    componentDidMount() {
        var self=this;
        document.addEventListener('click', function(event) {
            if(event.target.getAttribute('index') != null) {
                if(self.contextRef.current.id=='customcontext') {
                    self.click(event.target.getAttribute('index'));
                }
                event.preventDefault();
                self.props.dispatch(makeMenuInvisible());
            }
        });

        document.addEventListener('contextmenu', function(event) {
            if(event.target.getAttribute('index') != null) {
                if(self.contextRef.current.id=='customcontext') {
                    self.click(event.target.getAttribute('index'));
                }
                event.preventDefault();
                self.props.dispatch(makeMenuInvisible());
            } else {
                if(self.props.popupProps.visible) {
                    event.preventDefault();
                }
                self.props.dispatch(makeMenuInvisible());
            }
        });
    }
    
     
     click(index) {
        if(this.props.items[index].callback) {
            if(this.props.record.type == openInNewTabType.SLIDE && !(this.props.record.status == 9 || this.props.record.status == 12)) {
                message.error("Scanning going on!!", 2.5);
            } else {
                this.props.items[index].callback(this.props.record.id, this.props.record.type, this.props.record.displayOrder, this.props.record.specimen_type, this.props.record.viewer_type);
            }
        } else {
            console.log("callback not registered for the menu item")
        }
      }

    handleCancel = () => {
        this.props.dispatch(makeMenuInvisible());
    }
    
    returnMenu(items) {
      var myStyle = {
        'position': 'absolute',
        'top': `${this.props.y/*+this.props.height*/}px`,
        'left':`${this.props.x+5}px`,
        'width': `300px`
      }
    
      var self=this;
      return <Modal visible={this.props.popupProps.visible} footer={null} onCancel={this.handleCancel} closable={false} style={myStyle} wrapClassName='custom-modal' mask={false}>
          <div /*style={myStyle} className='custom-context'*/ id='customcontext'  ref={this.contextRef}>
          {items.map((item, index, arr) =>{
            
            if(arr.length-1==index) {
              return <div key={index} className='custom-context-item' index={index}>{item.label}</div>
            } else {
              return <div key={index} className='custom-context-item dotted-bottom-border' index={index}>{item.label}</div>
            }
          })}
          </div>
        </Modal>;
    }
    
    render() {
      return  (<div id='cmenu'>
          {this.props.visible ? this.returnMenu(this.props.items): this.returnMenu([])}
      </div>
      )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {
        popupProps: state.contextMenuReducer.popupProps
    }
}

export default connect(mapStateToProps)(CustomContext);
