import React from  "react";
import { createBox } from 'ol/interaction/Draw'
import LineString from 'ol/geom/LineString';
import DrawTool from './draw_tool'
import * as keys from './draw_tool_keys'
import { FaDrawPolygon, FaRegCircle, FaVectorSquare, FaSlash, FaDyalog, FaPen, FaMagic ,FaPencilAlt} from 'react-icons/fa';
import { geometry } from "@turf/turf";

const getLineDrawingTool = (params) => {
    return <DrawTool
                key={keys.lineDrawingKey.name}
                toolKey={keys.lineDrawingKey} 
                toolParams={{
                    maxPoints: 2,
                }}
                getGeometricParams={
                    (geometry) => {
                        let coordinates = geometry.getCoordinates();
                        let perimeter = geometry.getLength();
                        return {
                            area: 0, 
                            perimeter, 
                            coordinates
                        }
                    }
                }
                {...params}
                content={
                    <FaSlash/>
                }/>;
}

const getRectangleDrawingTool = (params) => {
    return <DrawTool
                key={keys.rectangleDrawingKey.name}
                toolKey={keys.rectangleDrawingKey}
                toolParams={{
                    geometryFunction: createBox(), 
                }}
                getGeometricParams={
                    (geometry) => {
                        let coordinates = geometry.getCoordinates();
                        let area = geometry.getArea();
                        let perimeter = new LineString(geometry.getLinearRing(0).getCoordinates()).getLength();
                        return {
                            area, 
                            perimeter, 
                            coordinates
                        }
                    }
                }
                {...params}
                content={
                    <FaVectorSquare/>
                }/>;
}

const getCircleDrawingTool = (params) => {
    return <DrawTool
                key={keys.circleDrawingKey}
                toolKey={keys.circleDrawingKey}
                getGeometricParams={
                    (geometry) => {
                        let area = geometry.getRadius() * geometry.getRadius() * Math.PI;
                        let perimeter = 2 * geometry.getRadius() * Math.PI;
                        let coordinates = [geometry.getCenter(), geometry.getRadius()];
                        return {
                            area, 
                            perimeter, 
                            coordinates
                        }
                    }
                }
                {...params}
                content={
                    <FaRegCircle/>
                }/>;
}

const getFreelineDrawingTool = (params) => {
    return <DrawTool
                key={keys.freelineDrawingKey}
                toolKey={keys.freelineDrawingKey}
                toolParams={{
                    freehand: true
                }}
                getGeometricParams={
                    (geometry) => {
                        let coordinates = geometry.getCoordinates();
                        let perimeter = geometry.getLength();
                        return {
                            area: 0, 
                            perimeter, 
                            coordinates
                        }
                    }
                }
                {...params}
                content={
                    <FaPen/>
                }/>;
}

const getFreehandDrawingTool = (params) => {
    return <DrawTool
                key={keys.freehandDrawingKey}
                toolKey={keys.freehandDrawingKey}
                toolParams={{
                    freehand: true
                }}
                getGeometricParams={
                    (geometry) => {
                        let coordinates = geometry.getCoordinates();
                        let area = geometry.getArea();
                        let perimeter = new LineString(geometry.getLinearRing(0).getCoordinates()).getLength();
                        return {
                            area, 
                            perimeter, 
                            coordinates
                        }
                    }
                }
                {...params}
                content={
                    <FaDyalog/>
                }/>;
}

const getPolygonDrawingTool = (params) => {
    return <DrawTool
                key={keys.polygonDrawingKey}
                toolKey={keys.polygonDrawingKey}
                getGeometricParams={
                    (geometry) => {
                        let coordinates = geometry.getCoordinates();
                        let area = geometry.getArea();
                        let perimeter = new LineString(geometry.getLinearRing(0).getCoordinates()).getLength();
                        return {
                            area, 
                            perimeter, 
                            coordinates
                        }
                    }
                }
                {...params}
                content={
                    <FaDrawPolygon/>
                }/>;
}

const getMagicToolDrawingTool = (params) => {
    return <DrawTool
                key={keys.magicToolDrawingKey}
                toolKey={keys.magicToolDrawingKey}
                toolParams={{
                    maxPoints: 2,
                }}
                getGeometricParams={
                    (geometry) => {
                        let coordinates = geometry.getCoordinates();
                        let perimeter = geometry.getLength();
                        return {
                            area: 0, 
                            perimeter, 
                            coordinates
                        }
                    }
                }
                {...params}
                content={
                    <FaMagic/>
                }/>;
}

const getModifyTool = (params) => {
    return <DrawTool 
                key={keys.pointDrawingKey}
                toolKey={keys.pointDrawingKey}
                toolParams={{
                    maxPoints: 1,
                }}
                getGeometricParams = {
                    (geometry) => {
                        let coordinates = geometry.getCoordinates();
                        return {
                            area: 0,
                            perimeter: 0,
                            coordinates
                        }
                    }
                }
                {...params}
                content={
                    <FaPencilAlt/>
                }
            />
}

export const getDrawingTools = (params, keyList) => {
    
    if (keyList == undefined) {
        keyList = [];
        for (let drawTool in keys) {
            keyList.push(keys[drawTool].name);
        }
    }

    return keyList.map(key => {
        if ( key == null) {
            return;
        } else if (key == keys.lineDrawingKey.name) {
            return getLineDrawingTool(params);
        } else if (key == keys.freelineDrawingKey.name) {
            return getFreelineDrawingTool(params);
        } else if (key == keys.freehandDrawingKey.name) {
            return getFreehandDrawingTool(params);
        } else if (key == keys.circleDrawingKey.name) {
            return getCircleDrawingTool(params);
        } else if (key == keys.rectangleDrawingKey.name) {
            return getRectangleDrawingTool(params);
        } else if (key == keys.polygonDrawingKey.name) {
            return getPolygonDrawingTool(params);
        } else if (key == keys.magicToolDrawingKey.name) {
            return getMagicToolDrawingTool(params);
        } else if (key == keys.pointDrawingKey.name) {
            return getModifyTool(params);
        }
    })
}

export const getIcon = (annotation, verticalAlign) => {

    let tool = annotation.tool_type;
    if ( tool == null) {
        return;
    } else if (tool == keys.lineDrawingKey.name) {
        return <FaSlash style={verticalAlign ? {verticalAlign: 'middle'} : {}} />;
    } else if (tool == keys.freelineDrawingKey.name) {
        return <FaPen style={verticalAlign ? {verticalAlign: 'middle'} : {}} />;
    } else if (tool == keys.freehandDrawingKey.name) {
        return <FaDyalog style={verticalAlign ? {verticalAlign: 'middle'} : {}} />;
    } else if (tool == keys.circleDrawingKey.name) {
        return <FaRegCircle style={verticalAlign ? {verticalAlign: 'middle'} : {}} />;
    } else if (tool == keys.rectangleDrawingKey.name) {
        return <FaVectorSquare style={verticalAlign ? {verticalAlign: 'middle'} : {}} />;
    } else if (tool == keys.polygonDrawingKey.name) {
        return <FaDrawPolygon style={verticalAlign ? {verticalAlign: 'middle'} : {}} />;
    } else if (tool == keys.magicToolDrawingKey.name) {
        return <FaDrawPolygon style={verticalAlign ? {verticalAlign: 'middle'} : {}} />;
    } else if (tool == keys.pointDrawingKey.name) {
        return <FaPencilAlt style={verticalAlign ? {verticalAlign: 'middle'} : {}} />;
    }
}