
import { BloodGridActions } from "../actionTypes/bloodGrid.constant"
import { displaySizeCalibrationKey } from "../component/neoviewer/apps/app_keys"
import { bloodGridRBCReducer } from "./bloodGridRBC.reducer"

const InitialState = {
  class:'WBC',
  tag:'',
  selected: false,
  selected_anno:'',
  move: false,
  update_list: false,
  summary_data: [],
  page_size: '',
  page: 1,
}

export const bloodGridReducer = ( state = InitialState , action) => {
  switch(action.type){
        case BloodGridActions.BLOOD_FILTER_DEFAULT:
            return Object.assign({}, state, {
                class:'WBC',
                tag: '',
                // selected_anno:'',
                // selected: false,
            })

        case BloodGridActions.BLOOD_CLASS_CHANGED:
            console.log("action",action.response)
            return Object.assign({}, state, {
                class: action.response,
                tag: ''
            })
        
        case BloodGridActions.BLOOD_TAG_CHANGED:
            return Object.assign({}, state, {
                tag: action.response
            })

        case BloodGridActions.BLOOD_ANNO_SELECTED:
            return Object.assign({}, state, {
                selected: true,
                selected_anno: action.response
            })
        
        case BloodGridActions.BLOOD_ANNO_MOVE:
            return Object.assign({},state, {
                move: true,
            })

        case BloodGridActions.BLOOD_ANNO_STOP_MOVE:
            return Object.assign({}, state, {
                move: false,
            })
        
        case BloodGridActions.BLOOD_ANNO_UPDATE_SUMMARY:
            return Object.assign({}, state, {
                update_list: action.response
            })

        case BloodGridActions.UPDATE_WBC_PAGE_SIZE:
            return Object.assign({}, state, {
                page_size: action.response,
            })

        case BloodGridActions.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: action.response,
            })

        default :
            return state
  }
}