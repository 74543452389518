import React, { Component } from "react";
import { connect } from 'react-redux';
import { message } from 'antd';
import { AuthHeader } from "../../../helper/auth.token";
import axios from "axios";
import GridReference from "../../../custom_libs/heatmap_overlay";
import {Button, Divider, Grid, MenuItem, Select, Stack, TextField, Typography, FormControl} from "@mui/material";
import {morphleBaseTheme1} from "../themes";

class IndxApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:{},
            showGrid: false,
        }
        this.gridRef = null;
        this.activeMapId = this.props.activeMapId;
        this.mapState = this.props.mapsState[this.activeMapId];
        this.slideState = this.mapState.slideState;
        this.slidemap = (this.slideState||{}).slidemap
    }

    componentDidMount = () => {
        let url = `/api/get_indx_data/?id=${((this.slideState||{}).slide_data||{}).id}`
        axios.get(url).then(res => {
            this.setState({
                data: res.data['data'],
                gridColor: "#FFFFFF",
                gridSize: 256,
                gridWidth: 3,
            })
        })
    }

    componentWillUnmount() {
        if (this.gridRef) {
            this.slidemap.removeControl(this.gridRef);
        }
    }

    drawGrid = () => {
        if (this.gridRef) {
            this.slidemap.removeControl(this.gridRef);
        }

     var extent = this.slidemap.getView().getProjection().getExtent();
        var height = this.slideState.slide_data.stitched_y_max * this.slideState.slide_data.stitched_tile_height;
        var width = this.slideState.slide_data.stitched_x_max * this.slideState.slide_data.stitched_tile_width;
        var gridEveryU = this.state.gridSize;
        var gridCols = width / gridEveryU;
        var gridRows = height / gridEveryU;

        this.gridRef = new GridReference(
            {
                extent: extent,
                size: [gridCols, gridRows],
                target: document.querySelector(".options div"),
                property: "commune",
                color: '#' + this.state.gridColor,
                width: this.state.gridWidth,
                heatmapData: this.state.data,
            }
        );

        this.slidemap.addControl(this.gridRef);
    }

    gridSwitch = () => {
        console.log("state_data", this.state.data)
        if(Object.keys(this.state.data).length == 0){
            message.error("Data not available")
            return;
        }
        this.setState({
            showGrid: !this.state.showGrid,
        })
    }

    render() {
        if (this.state.showGrid) {
            this.drawGrid();
        } else if (this.gridRef) {
            this.slidemap.removeControl(this.gridRef);
        }
        let isEmpty = Object.keys(this.state.data).length === 0;
        console.log("gammastate", this.slideState, morphleBaseTheme1.palette.text.primary);
        return <Grid>
                    <Typography variant={"h3"} sx={{color: morphleBaseTheme1.palette.text.primary}}>Indx.AI</Typography>
                    <Divider/>
                    <Grid >
                            <Button variant={"contained"} color={"secondary"} onClick={this.gridSwitch} disabled={isEmpty} sx={{marginTop :'2.5vh', marginLeft:"20%"}}>
                                {this.state.showGrid?"Hide Heatmap":isEmpty?"Data Unavailable":"Show Heatmap"}
                            </Button>
                    </Grid>
                </Grid>
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
        activeMapId: state.gammaStateReducer.activeMapId,
        mapsState: state.mapsStateReducer,
    }
}

export default connect(mapStateToProps)(IndxApp);


