import axios from "axios";
import {AxiosConfig} from "./axios.config";

export const get = url =>
	axios.get(url, AxiosConfig()).then(response => response.data);

export const post = (url, data) =>
	axios.post(url, data, AxiosConfig()).then(response => response.data);

export const patch = (url, data) =>
	axios.patch(url, data, AxiosConfig()).then(response => response.data);
