import {store} from "../helper/store";
import {updateCollapseMenuItem} from "../action/morpheus.state.action";
import {updateServices} from "../action/morpheus.setting.action";

export const checkCollapseMenuPermission = (key, defaultPermission) => {
	let permission = (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).collapse_menu[key];
	if (permission === undefined) {
		permission = defaultPermission;
		store.dispatch(updateCollapseMenuItem(key, permission));
	}
	return permission;
}

export const checkServicePermission = service => {
	let {services} = store.getState().morpheusSettingReducer;
	service.permission = services[service.service];
	if (service.permission === undefined) {
		services[service.service] = service.defaultPermission || false;
		store.dispatch(updateServices(services));
	}
	return service.permission;
}
