import {
	ADD_ANNOTATION,
	DELETE_ANNOTATION,
	UPDATE_ANNOTATION_STATE,
	UPDATE_BLOOD_ANNOTATION_STATE,
	UPDATE_BLOOD_VIEWER_SETTINGS,
	UPDATE_GRID_STATE,
	UPDATE_IHC_STATE,
	UPDATE_MAP_STATE,
	UPDATE_MAPS_STATE,
	UPDATE_SLIDE_STATE,
	UPDATE_VIEWER_SETTINGS,
	UPDATE_MAP_IN_FOCUS,
} from "../actionTypes/maps.state.const";
import axios from "axios";
import {AuthHeader} from "../helper/auth.token";
import {message} from "antd";
import {
	brightnessKey,
	contrastKey,
	grayscaleKey,
	hueKey,
	invertKey,
	saturationKey
} from "../component/gammaviewer/utils/viewerSettingsKeys";
import {isResponseOk} from "../helper/response.status.check";
import {displayError} from "../helper/display.error";
import {AxiosConfig} from "../helper/axios.config";
import queryString from "query-string";
import querystring from "query-string";
import {filterAnnotationsInROI, isROIAnnotation} from "../component/gammaviewer/utils/annotations_app_utils";
import {get} from "../helper/request";

export const initSlides = (slides) => updateMapsState(
	Object.assign({}, slides.map(slide => ({
		slideId: slide.id,
		x: -1,
		y: -1,
		z: 0,
		r: -1,
		digitalZoomStatus: false,
		zStackLevel: 0,
		gridState: {
			grid: false,
			color: "#e91e63",
			size: 400,
			width: 3,
		},
		...slide,
	})))
);

export const updateMapsState = (state) => ({
	type: UPDATE_MAPS_STATE,
	state: state
});

export const updateMapState = (mapId, mapState) => ({
	type: UPDATE_MAP_STATE,
	mapId: mapId,
	mapState: mapState
});

export const initSlide = id => dispatch =>
	get(`/api/slide/${id}/`)
		.then(slide => dispatch(updateMapState(`map-${Date.now()}`, {slide})))
		.catch(error => displayError("Failed to initialise slide", error));


// Z Stack Actions
export const updateZStackLevel = (mapId, zStackLevel) => updateMapState(mapId, {
	zStackLevel: zStackLevel,
});


// Slide State Actions
export const updateSlideState = (mapId, slideState) => ({
	type: UPDATE_SLIDE_STATE,
	mapId: mapId,
	slideState: slideState,
});

export const initSlideData = (mapId, slideId) => dispatch => {
	axios.get(`/api/slide/${slideId}/`, {
		headers: {
			Authorization : AuthHeader()
		}
	}).then(response =>
		isResponseOk(response) ?
			dispatch(updateSlideState(mapId, {
				slide_data: response.data,
			})) :
			displayError("Slide Data Init Failed", response)
	).catch(error => displayError("Slide Data Init Failed", error));
}

export const updateSlideData = (mapId, slideId, slideData) => dispatch =>
	axios.patch(`/api/slide/${slideId}/`, slideData, {
		headers: {
			Authorization: AuthHeader()
		}
	}).then(response => dispatch(updateSlideState(mapId, {
		slide_data: response.data,
	}))).catch(error => displayError("Slide Data Update Failed", error));

export const fullStitchSlide = (mapId, slideId) => dispatch => {
	axios.post(`/api/stitch/full/`, queryString.stringify({slideId: slideId}), AxiosConfig())
		.then(() => dispatch(initSlideData(mapId, slideId)));
}

export const updateDigitalZoomStatus = (mapId, digitalZoomStatus) => updateMapState(mapId, {
	digitalZoomStatus: digitalZoomStatus,
});


// Grid App Actions
export const updateGridState = (mapId, gridState) => ({
	type: UPDATE_GRID_STATE,
	mapId: mapId,
	gridState: gridState,
});

export const updateGridStatus = (mapId, grid) => updateGridState(mapId, {grid});

export const updateGridColor = (mapId, color) => updateGridState(mapId, {color});

export const updateGridSize = (mapId, size) => updateGridState(mapId, {size});

export const updateGridWidth = (mapId, width) => updateGridState(mapId, {width});


/**
 * @deprecated: [Priyanshu] Use settings.reducer and actions
 */
// Viewer Settings App ACTIONS
export const updateViewerSettings = (mapId, viewerSettings) => dispatch => {
	axios.patch(`/api/viewersetting/${viewerSettings.id}/`, viewerSettings, {
		headers: {
			Authorization : AuthHeader()
		}
	}).then(response => {
		if (response.status === 200 || response.status === 301 || response.status === 302) {
			// not dispatching here to remove ui lag, as server response time effect ui
		} else if (response.status === 403 || response.status === 401 || response.status === 400)
			message.error('Settings Update Failed', 2.5);
	})
		.catch(err =>{
			message.error('Settings Update Failed', 2.5);
			console.log(err);
		});

	dispatch({
		type: UPDATE_VIEWER_SETTINGS,
		mapId: mapId,
		viewerSettings: viewerSettings,
	});
}

/**
 * @deprecated: [Priyanshu] Use settings.reducer and actions
 */
export const updateBrightness = (mapId, viewerSettingId, brightness) => updateViewerSettings(mapId, {
	id: viewerSettingId,
	[brightnessKey.id]: brightness,
});

/**
 * @deprecated: [Priyanshu] Use settings.reducer and actions
 */
export const updateContrast = (mapId, viewerSettingId, contrast) => updateViewerSettings(mapId, {
	id: viewerSettingId,
	[contrastKey.id]: contrast,
});

export const updateHue = (mapId, viewerSettingId, hue) => updateViewerSettings(mapId, {
	id: viewerSettingId,
	[hueKey.id]: hue,
});

/**
 * @deprecated: [Priyanshu] Use settings.reducer and actions
 */
export const updateInvert = (mapId, viewerSettingId, invert) => updateViewerSettings(mapId, {
	id: viewerSettingId,
	[invertKey.id]: invert,
});

/**
 * @deprecated: [Priyanshu] Use settings.reducer and actions
 */
export const updateGrayscale = (mapId, viewerSettingId, grayscale) => updateViewerSettings(mapId, {
	id: viewerSettingId,
	[grayscaleKey.id]: grayscale,
});

/**
 * @deprecated: [Priyanshu] Use settings.reducer and actions
 */
export const updateSaturation = (mapId, viewerSettingId, saturation) => updateViewerSettings(mapId, {
	id: viewerSettingId,
	[saturationKey.id]: saturation,
});


/**
 * @deprecated: [Priyanshu] Use settings.reducer and actions
 */
// Blood Viewer Settings App Action
export const updateBloodViewerSettings = (mapId, viewerSettings) => dispatch => {
	axios.patch(`/api/viewersetting/${viewerSettings.id}/`, viewerSettings, {
		headers: {
			Authorization : AuthHeader()
		}
	}).then(response => {
		if (response.status === 200 || response.status === 301 || response.status === 302) {
			// not dispatching here to remove ui lag, as server response time effect ui
		} else if (response.status === 403 || response.status === 401 || response.status === 400)
			message.error('Settings Update Failed', 2.5);
	})
		.catch(err =>{
			message.error('Settings Update Failed', 2.5);
			console.log(err);
		});

	dispatch({
		type: UPDATE_BLOOD_VIEWER_SETTINGS,
		mapId: mapId,
		bloodViewerSettings: viewerSettings,
	});
}

/**
 * @deprecated: [Priyanshu] Use settings.reducer and actions
 */
export const updateBloodBrightness = (mapId, viewerSettingId, brightness) => updateBloodViewerSettings(mapId, {
	id: viewerSettingId,
	[brightnessKey.id]: brightness,
});

/**
 * @deprecated: [Priyanshu] Use settings.reducer and actions
 */
export const updateBloodContrast = (mapId, viewerSettingId, contrast) => updateBloodViewerSettings(mapId, {
	id: viewerSettingId,
	[contrastKey.id]: contrast,
});


// ANNOTATION APP ACTIONS
export const updateAnnotationState = (mapId, annotationState) => ({
	type: UPDATE_ANNOTATION_STATE,
	mapId: mapId,
	annotationState: annotationState,
});

export const updateMarkedAnnotation = (mapId, annotation) => updateAnnotationState(mapId, {
	markedAnnotation: annotation,
});

// retrieveAnnotationsAppDataFromBackend
export const retrieveAnnotations = (mapId, slideId) => dispatch =>
	axios.get(`/api/annotation/?slide=${slideId}`, AxiosConfig())
		.then(response => dispatch(updateAnnotationState(mapId, {
			annotations: filterAnnotationsInROI(response.data),
		})))
		.catch(error => displayError("Annotation Retrieval Failed", error));

// [Priyanshu] - only add annotation in reducer, (frontend), if annotation of same id exist, replace it
export const _addAnnotation = (mapId, annotation) => ({
	type: ADD_ANNOTATION,  // reducer is made to facilitate extending the list
	mapId: mapId,
	annotation: annotation,
});

// addAnnotationToAnnotationApp  -> use getAnnotationFromPoints(color, area, perimeter, key, coord, center, creator,
// z, slide_id) before
export const addAnnotation = (mapId, annotation) => dispatch =>
	axios.post(
		`/api/annotation/`, annotation, AxiosConfig())
		.then(response => isROIAnnotation(annotation) ? dispatch(retrieveAnnotations(mapId, annotation.slide)) :
			dispatch(_addAnnotation(mapId, response.data)))
		.catch(error => displayError("Annotation Addition Failed", error));

export const updateAnnotation = (mapId, annotation) => dispatch =>
	axios.patch(
		`/api/annotation/${annotation.id}/`, annotation, AxiosConfig())
		.then(response => dispatch(_addAnnotation(mapId, response.data)))
		.catch(error => displayError("Failed to update annotation", error));

// [Priyanshu] - delete annotation from reducer, (frontend)
export const _deleteAnnotations = (mapId, annotationId) => ({
	type: DELETE_ANNOTATION,
	mapId: mapId,
	annotationId: annotationId,
});

export const deleteAnnotation = (mapId, annotation) => dispatch =>
	axios.delete(
		`/api/annotation/${annotation.id}/`, AxiosConfig())
		.then(_ => isROIAnnotation(annotation) ?
			dispatch(retrieveAnnotations(mapId, annotation.slide)) :
			dispatch(_deleteAnnotations(mapId, annotation.id)))
		.catch(error => displayError("Annotation Deletion Failed", error));

//BLOOD ANNOTATIONS APP

export const retrieveBloodAnnotations = (mapId, slideId) => dispatch => {
	let url = `/api/get_all_field_view_annotations_wbc_gamma/`;
	let val = {
        slide_id: slideId,
        tag: '',
    }
	axios.post(
		url, querystring.stringify(val),{ headers : { Authorization: AuthHeader() }})
		.then(response => isResponseOk(response)
			? dispatch(updateBloodAnnotationState(mapId, {
				annotations: response.data.data,
			}))
			: message.info("Failed to retrieve Blood annotations")
		)
		.catch(error => {
			message.info("Failed to retrieve Blood annotations1");
			console.log("ErrorBlood: ", error);
		});
}

const hasbloodROIAnnotation = (annos, title) => {
	for (let i=0; i< annos.length; i++){
		if (annos[i].title === title)
			return annos[i].id;
	}
	return -1;
}

const patchROIAnnotation =  (data, annotation, mapId) => dispatch => {
	annotation.title = "blood-roi";
	annotation.anno_drawer = 0;
	let resp = hasbloodROIAnnotation(data, annotation.title);
	if (resp === -1) {
		dispatch(addAnnotation(mapId, annotation));
	} else {
		annotation.id = resp;
		dispatch(updateAnnotation(mapId, annotation))
	}
	dispatch(retrieveBloodAnnotations(mapId, annotation.slide));

}

/**
 * @deprecated
 */
export const addBloodROIAnnotation = (mapId, annotation) => dispatch => {

	axios.get(`/api/annotation/?slide=${annotation.slide}`, AxiosConfig())
		.then(response => {
				isResponseOk(response)
					? dispatch(patchROIAnnotation(response.data, annotation, mapId))
					: message.info("Failed to fetch annotation");
			}
		).catch(() => {
		message.info("failed to fetch annotation")
	})
}

export const updateBloodAnnotationState = (mapId, bloodAnnotationState) => ({
	type: UPDATE_BLOOD_ANNOTATION_STATE,
	mapId: mapId,
	bloodAnnotationState: bloodAnnotationState,
});


// IHC APP ACTIONS
export const updateIHCState = (mapId, ihcState) => ({
	type: UPDATE_IHC_STATE,
	mapId: mapId,
	ihcState: ihcState,
});

export const updateSelectedIHCResult = (mapId, selectedIHCResult) => updateIHCState(mapId, {
	selectedIHCResult: selectedIHCResult,
});

export const updateMapInFocus = ({ mapId, mapInFocus }) => ({
	type: UPDATE_MAP_IN_FOCUS,
	mapId,
	mapInFocus,
})