export function getPreviewLocation (slot, tray, rack) {

    slot === undefined ? slot = 0 : slot = slot;
    tray === undefined ? tray = 0 : tray = tray;
    rack === undefined ? rack = 0 : rack = rack;

    return '/dev-ssd/scans_preview/S'+ (slot+1) + 'T' + (tray+1) + 'R' + (rack+1)
}

export function getBarcode (slot, tray, rack) {
    return getPreviewLocation(slot, tray, rack) + '/pre-processed/barcode.jpg'
}

export function getBarcodeCrop (slot, tray, rack) {
    return getPreviewLocation(slot, tray, rack) + '/pre-processed/barcode_crop.jpg'
}

export function getMaskForFocusLevelZero (slot, tray, rack) {
    return getPreviewLocation(slot, tray, rack) + '/pre-processed/mask_for_focus_level_0.jpg'
}

export function getMaskForFocusLevelOne (slot, tray, rack) {
    return getPreviewLocation(slot, tray, rack) + '/pre-processed/mask_for_focus_level_1.jpg'
}

export function getMaskForFocusLevelTwo (slot, tray, rack) {
    return getPreviewLocation(slot, tray, rack) + '/pre-processed/mask_for_focus_level_2.jpg'
}

export function getMaskForPath (slot, tray, rack) {
    return getPreviewLocation(slot, tray, rack) + '/pre-processed/mask_for_path.bmp'
}

export function getOverlayedMaskForFocusLevelZero (slot, tray, rack) {
    return getPreviewLocation(slot, tray, rack) + '/pre-processed/overlayed_mask_for_focus_level_0.jpg'
}

export function getOverlayedMaskForFocusLevelOne (slot, tray, rack) {
    return getPreviewLocation(slot, tray, rack) + '/pre-processed/overlayed_mask_for_focus_level_1.jpg'
}

export function getOverlayedMaskForFocusLevelTwo (slot, tray, rack) {
    return getPreviewLocation(slot, tray, rack) + '/pre-processed/overlayed_mask_for_focus_level_2.jpg'
}

export function getOverlayedMaskForPath (slot, tray, rack) {
    return getPreviewLocation(slot, tray, rack) + '/pre-processed/overlayed_mask_for_path.jpg'
}

export function getRaw (slot, tray, rack) {
    return getPreviewLocation(slot, tray, rack) + '/pre-processed/raw.jpg'
}

export function getRotatedThumbnail (slot, tray, rack) {
    return getPreviewLocation(slot, tray, rack) + '/pre-processed/rotated_thumbnail.jpg'
}

export function getStitchedSmall (slot, tray, rack) {
    return getPreviewLocation(slot, tray, rack) + '/pre-processed/stitched_small.jpg'
}

export function getStitchedSmallViewer (slot, tray, rack) {
    return getPreviewLocation(slot, tray, rack) + '/pre-processed/stitched_small_viewer.jpg'
}

export function getUncropped (slot, tray, rack) {
    return getPreviewLocation(slot, tray, rack) + '/pre-processed/uncropped.jpg'
}

