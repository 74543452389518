import React, {Component} from  "react";
import {Row, Col, Divider, Switch, Button, message} from 'antd';
import {connect} from 'react-redux';

import "../../../asset/style/neoviewer/preview.css"
import axios from "axios";
import GridReference from "../../../custom_libs/heatmap_overlay";
import {height} from "plotly.js/src/plots/layout_attributes";


class IndxApp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data:{},
            showGrid: false,
        }

        this.gridRef = null;
    }

    componentDidMount = () => {
        let url = `/api/get_indx_data/?id=${this.props.slideData.id}`
        axios.get(url).then(res => {
            console.log("data--", res.data['data'])
            this.setState({
                data: res.data['data'],
                gridColor: "#FFFFFF",
                gridSize: 256,
                gridWidth: 3,
            })
        })

    }

    componentWillUnmount() {
        if (this.gridRef) {
            this.props.slidemap.removeControl(this.gridRef);
        }
    }

    drawGrid = () => {
        if (this.gridRef) {
            this.props.slidemap.removeControl(this.gridRef);
        }

        var extent = this.props.slidemap.getView().getProjection().getExtent();
        var height = this.props.slideData.stitched_y_max * this.props.slideData.stitched_tile_height;
        var width = this.props.slideData.stitched_x_max * this.props.slideData.stitched_tile_width;
        var gridEveryU = this.state.gridSize;
        var gridCols = width / gridEveryU;
        var gridRows = height / gridEveryU;

        this.gridRef = new GridReference(
            {
                extent: extent,
                size: [gridCols, gridRows],
                target: document.querySelector(".options div"),
                property: "commune",
                color: '#' + this.state.gridColor,
                width: this.state.gridWidth,
                heatmapData: this.state.data,
            }
        );

        this.props.slidemap.addControl(this.gridRef);
    }

    gridSwitch = () => {
	console.log("state_data", this.state.data)
	if(Object.keys(this.state.data).length == 0){
	   message.error("Data not available")
	   return;
	}
        this.setState({
            showGrid: !this.state.showGrid,
        })
    }

    render(){
        if (this.state.showGrid) {
            this.drawGrid();
        } else if (this.gridRef) {
            this.props.slidemap.removeControl(this.gridRef);
        }
	let isEmpty = Object.keys(this.state.data).length === 0;
        return <div className="grid-overlay-app-parent app-parent overlayed-component" style={{height: '8vh', textAlign:'center'}}>
            <Button type={'primary'} onClick={this.gridSwitch} disabled={isEmpty} style={{marginTop :'1vh'}}>
                {this.state.showGrid?"Hide Heatmap":isEmpty?"Data Unavailable":"Show Heatmap"}
            </Button>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
        appState: state.viewerUrlReducer.app_state,
    }
}

export default connect(mapStateToProps)(IndxApp);
