import React, {Component} from "react";
import {IconButton, Stack, Tooltip} from "@mui/material";
import {PictureAsPdf} from '@mui/icons-material';
import {morphleBaseTheme1} from "../themes";
import {connect} from "react-redux";
import {cropCanvas, degreeToRadian, isWhitePixel} from "../../../utils/utils";
import {getImageShape} from "../utils/map_utils";
import jsPDF from "jspdf";
import axios from "axios";
import html2canvas from "html2canvas";
import {Loading} from "../components/components";
import {displayError} from "../../../helper/display.error";
import {AxiosConfig} from "../../../helper/axios.config";

class ExportSlideToPdf extends Component {

	constructor(props) {
		super(props);

		// Scanner specific parameter
		this.height = 25;
		this.width = 75;

		// Printing specific parameter
		this.shiftPixel = 20;

	}

	exportHandler = () => {
		let center = this.props.view.getCenter();
		let rotation = this.props.view.getRotation();
		let zoom = this.props.view.getZoom();

		//TODO: Spandan --> Bad Timeout Implementation: Fix this
		this.props.view.animate({
			center: getImageShape(this.props.slide).map(x => x / 2),
			rotation: degreeToRadian(90),
			zoom: 1,
			duration: 500,
		}, () =>
			setTimeout(() => this.exportToPdf(() => {
				this.props.view.animate({
					center, rotation, zoom,
					duration: 500,
				})
			}), 500));
	}


	generatePdfFromImage = (imageData, backgroundColor) => {

		// Default export is A4 paper, portrait, using millimeters for units.
		let slide = this.props.slide;
		const doc = new jsPDF({unit: 'mm', format: 'a4', orientation: 'portrait'});

		let fullImg = new Image()
		fullImg.src = imageData;

		// let barcodeImg = new Image();
		// barcodeImg.crossOrigin = 'anonymous';
		// barcodeImg.src = getBarcodeUrl(slide);

		Promise.all([
			fullImg.decode(),
			axios.get(`/api/slide/selected_region_points/${slide.id}/mm/`, AxiosConfig())
		]).then(([ret1, response]) => {

			let [x0, y0, x1, y1] = response.data;
			doc.setFillColor(backgroundColor);

			doc.rect(this.shiftPixel, this.shiftPixel, this.width, this.height, "F");
			doc.rect(x0 + this.shiftPixel, y0 + this.shiftPixel, x1 - x0, y1 - y0, "S");

			doc.addImage(
				imageData,
				"PNG",
				x0 + this.shiftPixel,
				y0 + this.shiftPixel,
				x1 - x0, //width
				y1 - y0, //height
			);

			// doc.addImage(
			// 	barcodeImg,
			// 	"JPG",
			// 	50 + this.shiftPixel,
			// 	this.shiftPixel,
			// 	25,
			// 	25,
			// );
			doc.save(`${slide.name}.pdf`);
		})
			.catch(error => displayError("Failed to generate pdf", error));

	};

	exportToPdf = (reset) => {
		const canvasEl = document.getElementById(`map-${this.props.activeMapId}`);
		let backgroundColor = canvasEl.style.backgroundColor;
		let filter = canvasEl.style.filter;
		canvasEl.style.backgroundColor = 'rgb(255, 255, 255)';
		let olLine = document.getElementsByClassName("ol-scale-line");
		olLine.length > 0 && (olLine[0].style.visibility = "hidden");

		html2canvas(canvasEl).then(canvas => {
			let context = canvas.getContext("2d");
			// X, Y, Height and Width of Destination Canvas
			let xDest = 0
			let yDest = 0
			let widthDest = canvas.width;
			let heightDest = canvas.height;

			//Get X Position of Slide in Canvas
			for (let i = 0; i < canvas.width; i++) {
				let pixelData = context.getImageData(i, canvas.height / 2, 1, 1).data;
				if (!isWhitePixel(pixelData)) {
					xDest = i;
					break;
				}
			}

			//Get Width of Slide in Canvas
			for (let i = canvas.width - 1; i > xDest; i--) {
				let pixelData = context.getImageData(i, canvas.height / 2, 1, 1).data;
				if (!isWhitePixel(pixelData)) {
					widthDest = i - xDest;
					break;
				}
			}

			//Get Y Position of Slide in Canvas
			for (let i = 0; i < canvas.height; i++) {
				let pixelData = context.getImageData(canvas.width / 2, i, 1, 1).data;
				if (!isWhitePixel(pixelData)) {
					yDest = i;
					break;
				}
			}

			//Get Height of Slide in Canvas
			for (let i = canvas.height - 1; i > yDest; i--) {
				let pixelData = context.getImageData(canvas.width / 2, i, 1, 1).data;
				if (!isWhitePixel(pixelData)) {
					heightDest = i - yDest;
					break;
				}
			}

			//Crop the Canvas to Get Onlt Slide Data
			let croppedCanvas = cropCanvas(canvas, xDest, yDest, widthDest, heightDest);
			let croppedContext = croppedCanvas.getContext("2d");
			croppedContext.filter = filter;

			//Create PDF from image
			this.generatePdfFromImage(croppedCanvas.toDataURL(), backgroundColor);
			canvasEl.style.backgroundColor = backgroundColor;
			olLine.length > 0 && (olLine[0].style.visibility = "visible");

			// Needs to be kept at the end of the Function
			reset();
		});
	}


	render() {
		if (!this.props.view) return <Loading/>;

		return <Stack direction={"row"} spacing={1} justifyContent="flex-start" sx={{alignItems: 'center'}}>
			<Tooltip title={"Screenshot"} placement={"bottom"}>
				<IconButton size="small" onClick={this.exportHandler}>
					<PictureAsPdf sx={{color: morphleBaseTheme1.palette.headBarIcon.primary}}/>
				</IconButton>
			</Tooltip>
		</Stack>
	}
}


const mapStateToProps = (state) => {
	let activeMapId = state.gammaStateReducer.activeMapId;
	let slideState = state.mapsStateReducer[activeMapId].slideState;
	let view = (slideState || {}).view;
	let slide = (slideState || {}).slide_data;
	return {activeMapId, slide, view};
}
export default connect(mapStateToProps)(ExportSlideToPdf)
