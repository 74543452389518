import { PoweroffOutlined } from '@ant-design/icons';
import { Badge, Col, Row, Steps, message, Dropdown, Menu, Button } from 'antd';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { globalUrlPrefix, specimenType } from "../../utils/const";
import { ScanStages } from './scanWorkflow/scanConstants';
import { AuthHeader } from "../../helper/auth.token";
import axios from 'axios';
import { SettingOutlined } from '@ant-design/icons';

const { Step } = Steps;

class ScannerTimeline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            percentCompleted: 10,
            isScanning: false
        }
        this.usbConnectionCodeDict = {
            DISCONNECTED : "DISCONNECTED",
            CONNECTED : "CONNECTED",
            INITIALISED : "INITIALISED", 
            ERRORED : "ERRORED", 
            DOES_NOT_EXIST: "DOES_NOT_EXIST",
            UNKNOWN: "UNKNOWN"
        }
        this.hardwareDict = {
            CAMERA : "Camera", 
            SERIAL : "Serial", 
            PREVIEW_CAMERA : "Preview Camera",
            UPS: "UPS",
            POWER: "Power"
        }
        this.powerStatusDict = {
            UNKNOWN : "UNKNOWN",
            DISCONNECTED : "DISCONNECTED",
            CONNECTED : "CONNECTED",
            ONLINE: "ONLINE",
            ONBATT: "ONBATT"
        }
        this.systemStateDict = {
            CRITICAL : "CRITICAL",
            WARNING : "WARNING",
            AVAILABLE : "AVAILABLE"
        }
        this.notificationTypeDict = {
            HDD : 'hdd', 
            SSD : 'ssd', 
            STOP : 'stop'
        }
    }

    componentDidUpdate = () => {
        this.deviceStatusChecks();
    }

    deviceStatusChecks = () => {
        let allUsbDevicesConnected = true;
        let scannerStatus = (((this.props.deviceStatus || {}).scanner_health || {}).scanner_response || {});
        let usbDependenciesStatus = (scannerStatus || {}).usbDependenciesStatus;
        let upsPowerStatus = ((scannerStatus || {}).upsPowerStatus || {});
        let powerStatus = ((scannerStatus || {}).powerStatus || {});
        let upsMandatory = ((((this.props.deviceStatus || {}).scanner_health || {}).scanner_response || {}).usbDependenciesStatus || {}).upsStatus !== this.usbConnectionCodeDict.DOES_NOT_EXIST;
        let systemState = ((scannerStatus || {}).systemState || {});
        let isCriticalDisk = systemState.ssdStatus == this.systemStateDict.CRITICAL || systemState.hddStatus == this.systemStateDict.CRITICAL;
        let isMorpheusReachable = ((this.props.deviceStatus || {}).scanner_health || {}).device_name != undefined;
        let isServerReachable = ((this.props.deviceStatus || {}).scanner_health || {}).server_up;
        isServerReachable = isServerReachable == undefined ? true : isServerReachable;
        let hardwareError = false;
        let stageEncoderFailure = scannerStatus.stageEncoderFailure;

        if (usbDependenciesStatus != undefined) {
            for (let key in usbDependenciesStatus) {
                if (usbDependenciesStatus[key] != this.usbConnectionCodeDict.INITIALISED && key != 'upsStatus') {
                    hardwareError = true;
                }
                if (usbDependenciesStatus[key] != this.usbConnectionCodeDict.INITIALISED && 
                    usbDependenciesStatus[key] != this.usbConnectionCodeDict.CONNECTED && key != 'upsStatus') {
                    allUsbDevicesConnected = false;
                }
            }
            
            if (upsMandatory) {
                if(upsPowerStatus.deviceStarted == false && upsPowerStatus.powerStatus == this.powerStatusDict.ONBATT) {
                    allUsbDevicesConnected = false;
                }
                
                if(upsPowerStatus.deviceStarted == false && usbDependenciesStatus.upsStatus != this.usbConnectionCodeDict.INITIALISED) {
                    allUsbDevicesConnected = false;
                }
            }
        }

        if (!isServerReachable) {
            console.log("hit");
            window.location.pathname = '/neo/scanners/1/';
        }
    }

    startScannerServer = () => {
        let id = this.props.device_id;
        this.setScannerServerBusy("Booting Scanner");
        let url = `/server/devices/` + id + '/start_scanner_server/';
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status !== 200) {
                    message.error("Failed to Start Scanner. Contact Admin!");
                    this.setScannerServerIdle();
                }
            })
            .catch(err => {
                this.setScannerServerIdle();
                console.log(err);
            })

    }

    stopScannerServer = () => {
        let id = this.props.device_id;
        // this.setScannerServerBusy("Shutting Down Scanner");
        let url = `/server/devices/` + id + '/stop_scanner_server/';
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status !== 200) {
                    message.error("Failed to Stop Scanner. Contact Admin!");
                }
                // this.setScannerServerIdle();
            })
            .catch(err => {
                // this.setScannerServerIdle();
                console.log(err);
            })

    }


    render() {
        const menu = (
            <Menu>
              <Menu.Item key="0">
                <Button onClick = {this.stopScannerServer}>
                    Stop Scanner <PoweroffOutlined />
                </Button>
              </Menu.Item>
            </Menu>
          );

        let isServerReachable = ((this.props.deviceStatus || {}).scanner_health || {}).server_up;
        let isMorpheusReachable = ((this.props.deviceStatus || {}).scanner_health || {}).device_name != undefined;
        
        let deviceType = (((this.props.deviceStatus || {}).scanner_health || {}).scanner_response|| {})['deviceType']
        let isHemoLens = deviceType && deviceType.includes("HEMOLENS");

        let process_status = ((this.props.deviceStatus || {}).scanner_health || {}).process_status;
        let badges = [];
        for(var p in process_status){
            if(process_status[p][1] == 0){
                badges.push(<Badge status="default" />)
            }else if(process_status[p][1] == 1){
                badges.push(<Badge status="processing" />)
            }else{
                badges.push(<Badge status="error" />)
            }
        }
        
        let displayName = ((this.props.deviceStatus || {}).scanner_health || {}).device_name + " - Control Panel";
        if (this.props.type == specimenType.BLOOD){
            displayName = ((this.props.deviceStatus || {}).scanner_health || {}).device_name + " - Blood WorkFlow Panel";
        } else if (isHemoLens)  {
            displayName = ((this.props.deviceStatus || {}).scanner_health || {}).device_name + " - BMA WorkFlow Panel";
        }

        return (
           
            <div>
                <Row className="rounded-container scanner-name-div">
                        <Col className="scanner-name arial-font" span={21}>
                            <Link to={"/" + globalUrlPrefix + "/scanners/" + this.props.device_id + "/"} className="device-name">
                                {displayName}
                            </Link>
                            {/* { (isServerReachable) ? 
                                <div style={{height: 'auto', width: '100px', float: 'right'}}>
                                    <PoweroffOutlined onClick={this.stopScannerServer} style={{color: 'red', fontSize: '50px'}}/>
                                </div>
                            : null }
                            { (isMorpheusReachable && !isServerReachable) ?
                                <div style={{height: 'auto', width: '100px', float: 'right'}}>
                                    <PoweroffOutlined onClick={this.startScannerServer} style={{color: 'green', fontSize: '50px'}}/>
                                </div>
                            : null } */}
                        </Col>
                        <Col span={2}>
                            <Dropdown overlay={menu} trigger={['click']}>
                                <a className="ant-dropdown-link scanner-name arial-font" onClick={e => e.preventDefault()}>
                                     <SettingOutlined />
                                </a>
                            </Dropdown>
                        </Col>
                        <Col span={1}>
                                {badges}
                        </Col>
                       
                </Row>
            {/* <div style={{padding: '0px 30px 0px 30px'}}>
                <Row>
                    <Col span={24}>
                        <Steps 
                            current={parseInt(this.props.currentProcessIndex)} 
                            percent={this.state.percentCompleted} 
                            size='small'
                            onChange={this.props.handleProgressChange}
                        >
                            <Step title="Load Slides" 
                                disabled={this.props.currentProcessIndex > 0 ? true : false}/>
                            <Step title="Set Scan Parameters" 
                                disabled={this.props.currentProcessIndex !== 1 ? true : false}/>
                            <Step title="Scan" 
                                disabled={this.props.currentProcessIndex !== 2 ? true : false}/>
                        </Steps>
                    </Col>
                    <br></br>
                </Row>
            </div> */}
            </div>
            
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        deviceStatus: state.deviceStatusReducer[ownProps.device_id],
    };
};

export default connect(mapStateToProps)(ScannerTimeline);
