import cookie from "react-cookies";
import axios from 'axios';
import {AuthHeader} from "../helper/auth.token";
import { TileViewerConstants } from "../actionTypes/tile.viewer.state.constant";

export const getNewState = () => {
    return {
        z: 0, 
        r: -1,
        x: -1, 
        y: -1,
        digitalZoomStatus: false,
        tile_viewer_app_state: {}, 
        apps_initialised: false,
        selectedShape: null,
        isDrawing: false,
}}

const InitialState = getNewState()

export const tileViewerUrlReducer = ( state = InitialState , action) => {
    let returnableState = {};
    let allAppStates = {};
    let currentAppState = {};
    let newAppState = {};
    let encodedUrl;
    switch(action.type){
        case TileViewerConstants.INIT_TILE_VIEWER_STATE:
            returnableState = Object.assign({}, state, action.urlState);
            return returnableState;
        case TileViewerConstants.UPDATE_TILE_VIEWER_STATE:
            returnableState = Object.assign({}, action.urlState);
            encodedUrl = tileViewerUrlEncoder(returnableState);
            window.history.replaceState(returnableState, 'viewer_url', encodedUrl);
            return returnableState;
        case TileViewerConstants.UPDATE_TILE_VIEWER_APP_CLOSED_STATE:
            allAppStates = state.tile_viewer_app_state;
            currentAppState = allAppStates[action.appName] || {};
            newAppState = Object.assign({}, allAppStates, {
                [action.appName]: Object.assign({}, currentAppState, {
                    closed: action.appClosedStatus}) });
            returnableState = Object.assign({}, state, {
                tile_viewer_app_state : newAppState
            });
            updateAppSettingsInDb(newAppState);
            return returnableState;
        case TileViewerConstants.UPDATE_TILE_VIEWER_APP_POSITION:
            allAppStates = state.tile_viewer_app_state;
            currentAppState = allAppStates[action.appName] || {};
            newAppState = Object.assign({}, allAppStates, {
                [action.appName]: Object.assign({}, currentAppState, {
                    position: action.appPosition}) });
            returnableState = Object.assign({}, state, {
                tile_viewer_app_state : newAppState
            });
            updateAppSettingsInDb(newAppState);
            return returnableState;
        case TileViewerConstants.UPDATE_TILE_VIEWER_APP_CLICK_TIME:
            allAppStates = state.tile_viewer_app_state;
            currentAppState = allAppStates[action.appName] || {};
            newAppState = Object.assign({}, allAppStates, {
                [action.appName]: Object.assign({}, currentAppState, {
                    clicked_at: new Date().getTime()}) });
            
            let appVsClickTime = [];

            for (let key in newAppState) {
                appVsClickTime.push({
                    key: key, 
                    val: newAppState[key].clicked_at === undefined ? 0 : newAppState[key].clicked_at
                })
            }

            appVsClickTime = appVsClickTime.sort(function (a, b) {
                return a.val - b.val;
            });

            appVsClickTime.map((item, index) => {
                newAppState[item.key].zIndex = 9 + 2 * index;
            })
            
            returnableState = Object.assign({}, state, {
                tile_viewer_app_state : newAppState
            });
            updateAppSettingsInDb(newAppState);
            return returnableState;
        case TileViewerConstants.UPDATE_SHAPE:
            returnableState = Object.assign({}, state, {
                selectedShape: action.value
            });
            return returnableState;
        case TileViewerConstants.UPDATE_IS_DRAWING:
            returnableState = Object.assign({}, state, {
                isDrawing: action.value
            });
            return returnableState;
        default :
            returnableState = state
            return returnableState;
    }
}


export const updateAppSettingsInDb = (app_state) => {
    let url = `/api/morpheus/settings/` + JSON.parse(localStorage.getItem('morpheus_setting')).id + `/`;
    axios.put(url, {
        app_state
    }, {headers: {Authorization : AuthHeader()}})
        .then(response => {
            if(response.status === 200 || response.status === 301 || response.status === 302) {
            }
            else if(response.status === 403 || response.status === 401 || response.status === 400){
                console.log("Position Update Save Failed", response);
            }
        })
        .catch(err =>{
            console.log("Position Update Save Failed, ", err);
        });
}

export const tileViewerUrlEncoder = (partsOfUrl) => {
    var encodedUrl = '?';
    if (partsOfUrl === undefined) {
        return encodedUrl;
    }
    if ('x' in partsOfUrl) {
        encodedUrl += '&x=' + partsOfUrl.x;
    }
    if ('y' in partsOfUrl) {
        encodedUrl += '&y=' + partsOfUrl.y;
    }
    if ('z' in partsOfUrl) {
        encodedUrl += '&z=' + partsOfUrl.z;
    }
    if ('r' in partsOfUrl) {
        encodedUrl += '&r=' + partsOfUrl.r;
    }
    if ('digitalZoomStatus' in partsOfUrl) {
        encodedUrl += partsOfUrl.digitalZoomStatus ? '&digitalZoomStatus=true' : '';
    }
    return encodedUrl;
}
