import React from "react";
import {Checkbox, Input, Slider, Stack} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Typography from "@mui/material/Typography";
import {StyledDivider} from "./StyledComponents";
import ReactLoading from "react-loading";
import {limitNumber} from "../../../utils/utils";

export const getEyeCheckbox = (checked, defaultChecked, onChange, icon, checkedIcon, key) =>
	<Checkbox
		key={key}
		edge={"start"}
		size={"small"}
		checked={checked}
		defaultChecked={defaultChecked}
		icon={icon ? icon : <VisibilityIcon sx={{color: '#8a8a8a'}} fontSize={'small'}/>}
		checkedIcon={checkedIcon ? checkedIcon : <VisibilityIcon color={'secondary'} fontSize={'small'}/>}
		onChange={onChange}
	/>

export const HorizontalDivider = props => <StyledDivider orientation={"horizontal"} flexItem {...props}/>;

export const VerticalDivider = props => <StyledDivider orientation={"vertical"} flexItem {...props}/>;

export const getInfoRow = (label, value) =>
	<Stack direction={"row"} alignItems={"center"} height={30}>
		<Typography sx={{fontWeight: 'bold', width: '45%'}}>{label} </Typography>
		<VerticalDivider sx={{mx: 0.5}}/>
		<Typography>{value}</Typography>
	</Stack>

export const Loading = props =>
	<ReactLoading type={"cylon"} color={"#fff"} height={50} width={50} {...props}/>

export const LoadingText = props =>
	<Stack direction={"column"} justifyContent={"center"} alignItems={"center"}>
		<Loading {...props} />
		<Typography sx={{color: props.color || '#fff'}}>Loading ...</Typography>
	</Stack>

export const SliderRow = ({label, icon, defaultValue, value, min, max, update}) => {
	return <Stack direction={"column"}>
		<Typography>{label}</Typography>
		<Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
			{icon}
			<Slider defaultValue={defaultValue} value={value} min={min} max={max} color={'secondary'}
					onChange={(event, value) => update(limitNumber(value, min, max))}/>
			<Input defaultValue={defaultValue} value={value} size={'small'} sx={{width: '50px'}}
				   onChange={event => update(limitNumber(event.target.value, min, max))}
				   inputProps={{min: min, max: max, type: 'number', 'aria-labelledby': 'input-slider',}}
			/>
		</Stack>
	</Stack>;
}
