import {AspectRatioTwoTone} from "@mui/icons-material";
import React from "react";
import {SettingComponentType} from "../../../utils/const";

export const ConfigurationKeys = [
	{
		type: SettingComponentType.SLIDER,
		name: "Scale",
		icon: <AspectRatioTwoTone/>,
		value: 100,
		min: 10,
		max: 200,
	},
]
