import React, { Component } from 'react';
import { ConsoleSqlOutlined, ScanOutlined } from '@ant-design/icons';
import { Spin, Row, Col, Input, Button, message, Divider } from 'antd';
import "../../asset/style/scanner/scanner.css"
import { RegionSelectionConstants } from "../../actionTypes/region_selection.constant"
import { connect } from "react-redux";
import { takeAllPreviews, setDoTilingOnly, unsetDoTilingOnly, setDoTilingAfterScan, unsetDoTilingAfterScan, selectionDone, selectionCancelled, selectionConfirmed, setScanSpeed, setScanName, initPreviewForScan, takePreview, setSpecimenType, setObjectiveType, setScanOutputFormat, setScanZLevels, setScanZStepSize, getDefaultConfig, updatePreviewStatus, dropDistanceConfirmed, onDeleteFocusPoint } from '../../action/preview_status.action';
import { getSpecimenChooser, getAdminPanel, getBrushPanel, getButtonsPanel, getCanvasComponent, getConfirmedPanel, getBlankSlidePanel, updateImages, getSpeedPanel, cleanUpExtra, getObjectiveChooser, getScanOutputFormatChanger, getZStackConfigPanel, drawCrossHairInitial } from "./slot_component_utils"
import axios from 'axios';
import cookie from "react-cookies";
import querystring from "query-string";

const { Search } = Input;

const initState = (id, previewStatus, initRegions) => ({
	regions: initRegions,
	selectedSlot: id,
	previewStatus: previewStatus,
	usingBrush: true,
	regionArea: 0,
	brushSize: 20,
	slide_name: null,
	reading_barcode: false,
	overrideBlank: false
})

class SlotComponent extends Component {

	constructor(props) {
		super(props);
		this.state = initState(this.props.id, undefined, this.props.preview.region == undefined ? undefined : [this.props.preview.region]);
		this.paint = false;
	}

	onCanvasUpdate = (regions) => {
		this.setState({
			regions: regions
		});
		let url = `/server/devices/${this.props.device_id}/get_area_mm_from_bounding_box/`;
		let reg = regions[0];
		if (reg !== undefined) {
			var x1 = parseInt((reg.x * this.props.preview.response.previewWidth) / 100.0);
			var x2 = parseInt(((reg.x + reg.width) * this.props.preview.response.previewWidth) / 100.0);
			var y1 = parseInt((reg.y * this.props.preview.response.previewHeight) / 100.0);
			var y2 = parseInt(((reg.y + reg.height) * this.props.preview.response.previewHeight) / 100.0);
			var data = {
				'slot_id': this.state.selectedSlot, 
				'top_x': x1, 
				'top_y': y1, 
				'bottom_x': x2, 
				'bottom_y': y2
			}
			axios.post(url, querystring.stringify(data)).then(res => {
				this.setState({
					regionArea: res.data.toFixed(0)
				});
			})
			.catch(err => {
					console.log(err);
			});
		}
	}

	componentDidMount = () => {
		let newStatus = (this.props.preview || {}).status;
		if (this.state.selectedSlot !== this.props.id) {
			if (newStatus === RegionSelectionConstants.SELECTION_DONE) {
				this.props.dispatch(selectionCancelled(this.props.id));
			}
			let newRegions = newStatus === RegionSelectionConstants.CONFIRMED ? [this.props.preview.region] : undefined;
			this.setState(Object.assign({}, this.state, initState(this.props.id, undefined, newRegions)));
		} else if (this.state.previewStatus !== newStatus && this.previewCanvas != null) {
			if (newStatus === RegionSelectionConstants.FETCHED_IMAGE || 
				newStatus === RegionSelectionConstants.SELECTION_DONE || 
				newStatus === RegionSelectionConstants.CONFIRMED) {
				let date = new Date();
				updateImages(this.previewCanvas, this.props.preview.response.uploadLocation, 
					this.thresholdCanvas, this.props.preview.response.defaultMaskLocation + "?d=" + date.getTime(), this.state.regions);
			}
			this.setState(Object.assign({}, this.state, {previewStatus : newStatus}));
		}
	}

	componentDidUpdate(prevProps) {
		this.updatePreviewBox();

		if (prevProps.preview.status != this.props.preview.status && this.props.preview.status == RegionSelectionConstants.START_DROP_DISTANCE_SELECTION) {
			if (this.props.preview.dropDistancePoint != undefined) {
                drawCrossHairInitial(this.props.preview.dropDistancePoint[0].x, this.props.preview.dropDistancePoint[0].y, this.dropDistanceCanvas,
                    {clearCanvas: true, color: 'red', size: 10, width: 1}, this.props.preview);
            }
		}

		if (prevProps.preview.status != this.props.preview.status && this.props.preview.status == RegionSelectionConstants.START_FOCUS_POINTS_SELECTION) {
			let pointDrawn = false;
			for (let island in this.props.preview.focusPoints) {
                for (let i = 0; i < this.props.preview.focusPoints[island].length; i++) {
                    let focusUnit = this.props.preview.focusPoints[island][i];
                    if (focusUnit.length > 0) {
                        drawCrossHairInitial(focusUnit[0].x, focusUnit[0].y, this.focusPointsCanvas,
							{clearCanvas: !pointDrawn, color: 'green', size: 5, width: 1}, this.props.preview);
						pointDrawn = true;
                    }
                }
            }
		}
	}

	componentDidMount() {
		this.updatePreviewBox();
	}

	updatePreviewBox = () => {
		let newStatus = (this.props.preview || {}).status;
		if (this.state.selectedSlot !== this.props.id) {
			if (newStatus === RegionSelectionConstants.SELECTION_DONE) {
				this.props.dispatch(selectionCancelled(this.props.id));
			}
			let newRegions = newStatus === RegionSelectionConstants.CONFIRMED ? [this.props.preview.region] : undefined;
			this.setState(Object.assign({}, this.state, initState(this.props.id, undefined, newRegions)));
		} 
		else if (this.state.previewStatus !== newStatus && this.previewCanvas != null) {
			if (newStatus === RegionSelectionConstants.FETCHED_IMAGE || 
				newStatus === RegionSelectionConstants.SELECTION_DONE || 
				newStatus === RegionSelectionConstants.CONFIRMED) {
				let date = new Date();
				updateImages(this.previewCanvas, this.props.preview.response.uploadLocation, 
					this.thresholdCanvas, this.props.preview.response.defaultMaskLocation + "?d=" + date.getTime(), this.state.regions);
			}
			this.setState(Object.assign({}, this.state, {previewStatus : newStatus}));
		}
	}

	confirmClicked = () => {
		if (this.props.preview.status === RegionSelectionConstants.FETCHED_IMAGE) {
			this.props.dispatch(selectionDone(this.props.id));
		} else if (this.props.preview.status === RegionSelectionConstants.SELECTION_DONE) {
			let imageURLData = this.thresholdCanvas.toDataURL("image/jpeg");
			let reg = this.state.regions[0];
			let x1 = parseInt((reg.x * this.props.preview.response.previewWidth) / 100.0);
			let x2 = parseInt(((reg.x + reg.width) * this.props.preview.response.previewWidth) / 100.0);
			let y1 = parseInt((reg.y * this.props.preview.response.previewHeight) / 100.0);
			let y2 = parseInt(((reg.y + reg.height) * this.props.preview.response.previewHeight) / 100.0);
			let actualReg = [x1, y1, x2, y2]
			this.props.dispatch(selectionConfirmed(this.props.device_id, this.props.id, reg, actualReg, 
				imageURLData, this.props.preview.slideName, this.props.startScanning, this.props.cassetteSize, this.props.preview.specimenType, 
				this.props.preview.objectiveType, this.props.preview, this.dropDistanceCanvas));
		} else if (this.props.preview.status === RegionSelectionConstants.START_DROP_DISTANCE_SELECTION) {
			this.props.dispatch(dropDistanceConfirmed(this.props.device_id, this.props.id, this.props.preview, this.focusPointsCanvas));
		} else if (this.props.preview.status === RegionSelectionConstants.START_FOCUS_POINTS_SELECTION) {
			this.props.dispatch(updatePreviewStatus(this.props.id, RegionSelectionConstants.CONFIRMED));
		}
	}

	onRetakePreview = () => {
		this.clearSelection();
		this.props.dispatch(getDefaultConfig(this.props.device_id, this.props.id));
		this.props.dispatch(takePreview(this.props.device_id, this.props.id));
	}

	clearSelection = () => {
		this.setState(Object.assign({}, this.state, {
			regions: undefined,
			slide_name: null,
			reading_barcode: false
		}));
		this.props.dispatch(selectionCancelled(this.props.id));
	}

	overrideBlankSlide = () => {
		this.clearSelection();
		this.setState(Object.assign({}, this.state, {
			overrideBlank: true
		}));
	}

	paintCell = (x, y) => {
		const thresholdContext = this.thresholdCanvas.getContext('2d');
		var cellWidth = (parseInt(this.state.brushSize / 2)) * 2;
		thresholdContext.clearRect(x - cellWidth / 2, y - cellWidth / 2, cellWidth, cellWidth);
		thresholdContext.fillStyle = this.state.usingBrush ? "#FFFFFF" : "#000000";
		thresholdContext.fillRect(x - cellWidth / 2, y - cellWidth / 2, cellWidth, cellWidth);
	}

	paintCanvas = (event) => {
		if (this.paint) {
			var rect = this.thresholdCanvas.getBoundingClientRect();
			var canvasx = rect.x;
			var canvasy = rect.y;
			var mousex = parseInt(((event.pageX - canvasx) / rect.width) * this.thresholdCanvas.width);
			var mousey = parseInt(((event.pageY - canvasy) / rect.height) * this.thresholdCanvas.height);
			this.paintCell(mousex, mousey);
			
			// Clear any painting outside the region box
			cleanUpExtra(this.thresholdCanvas, this.thresholdCanvas.getContext('2d'), this.state.regions[0]);
		}
	}

	startPainting = (event) => {
		this.paint = true;
		this.paintCanvas(event);
	}

	stopPainting = () => this.paint = false;

	selectFullRegion = () => {
		this.setState(Object.assign({}, this.state, {
			regions: [
				{
					data: { index: 0 },
					height: 100,
					isChanging: false,
					new: false,
					width: 100,
					x: 0,
					y: 0,
				}
			], 
			oldState: RegionSelectionConstants.SELECTION_DONE
		}));
		this.confirmClicked();
	}

	toggleTiling = (event) => {
		if (event.target.checked) {
			this.props.dispatch(setDoTilingOnly(this.props.id));
		} else {
			this.props.dispatch(unsetDoTilingOnly(this.props.id));
		}
	}

	toggleTilingAfterScan = (event) => {
		if (event.target.checked) {
			this.props.dispatch(setDoTilingAfterScan(this.props.id));
		} else {
			this.props.dispatch(unsetDoTilingAfterScan(this.props.id));
		}
	}

	readbarcode = () => {
		this.setState({
			reading_barcode: true
		});
		let previewPath = this.props.preview.response.barcodeLocation;
		let url = `/server/devices/${this.props.device_id}/read_barcode/?path=${previewPath}`;
		axios.get(url).then(res => {
			if (res.data === "") {
				message.warn("No Barcode found on Slide. Please write Slide Name.")
			}
			else{
				this.props.dispatch(setScanName(this.props.id, res.data))
			}
			this.setState({
				reading_barcode: false
			});	
		})
		.catch(err => {
				console.log(err);
		});
	}

	movedBrushSizeSlider = (value) => this.setState({ brushSize : value });

	toggleBrushEraser = () => this.setState((prevState) => ({ usingBrush : !prevState.usingBrush}));

	previewCanvasRef = previewCanvas => this.previewCanvas = previewCanvas;

	thresholdCanvasRef = thresholdCanvas => this.thresholdCanvas = thresholdCanvas;

	dropDistanceCanvasRef = dropDistanceCanvas => this.dropDistanceCanvas = dropDistanceCanvas;

	focusPointsCanvasRef = focusPointsCanvas => this.focusPointsCanvas = focusPointsCanvas;

	onScanSpeedChange = (event) => this.props.dispatch(setScanSpeed(this.props.id, event.target.value));

	onZLevelsChange = (event) => this.props.dispatch(setScanZLevels(this.props.id, event.target.value));

	onStepSizeChange = (event) => this.props.dispatch(setScanZStepSize(this.props.id, event.target.value));

	onSpecimenTypeChange = (event) => this.props.dispatch(setSpecimenType(this.props.id, event.target.value));

	onObjectiveTypeChange = (event) => this.props.dispatch(setObjectiveType(this.props.id, event.target.value));

	onScanOutputFormatChange = (event) => this.props.dispatch(setScanOutputFormat(this.props.id, event.target.value));

	onScanNameChange = (event) => {
		let slide_name = event.target.value;
		this.setState({
			slide_name: slide_name
		}, () => this.props.dispatch(setScanName(this.props.id, slide_name)));
	}

	getFocusPointsTable = () => {
		let tableRows = [];
		tableRows.push(<Divider />);
		tableRows.push(
			<div>
				<Row>
					<Col offset={2} span={5}>
						Island ID
					</Col>
					<Col span={5}>
						Point
					</Col>
				</Row>
				<Divider />
			</div>
		);
		for (let island in this.props.preview.focusPoints) {
			for (let i = 0; i < this.props.preview.focusPoints[island].length; i++) {
				let focusUnit = this.props.preview.focusPoints[island][i];
				if (focusUnit.length > 0) {
					tableRows.push(this.getFocusPointRow(island, i, focusUnit[0]));
				}
			}
		}
		tableRows.push(<Divider />);
		return tableRows;
	}

	getFocusPointRow = (islandID, index, point) => {
		return (
			<div>
				<Row>
					<Col offset={2} span={5}>
						{islandID}
					</Col>
					<Col span={5}>
						{"X: " + point.x + ", Y: " + point.y}
					</Col>
					<Col span={5}>
						<Button type={"link"} size="small" onClick={() => this.redrawnFocusPoints(islandID, index)}>
							Select
						</Button>
					</Col>
					<Col span={5}>
						<Button type={"link"} size="small" onClick={() => this.deleteFocusPoint(islandID, index)}>
							Delete
						</Button>
					</Col>
				</Row>
				<Divider />
			</div>
		);
	}

	redrawnFocusPoints = (islandID, index) => {
		let pointDrawn = false;

		for (let island in this.props.preview.focusPoints) {
			for (let i = 0; i < this.props.preview.focusPoints[island].length; i++) {
				let focusUnit = this.props.preview.focusPoints[island][i];
				if (focusUnit.length > 0) {
					if (island == islandID && i == index) {
						drawCrossHairInitial(focusUnit[0].x, focusUnit[0].y, this.focusPointsCanvas,
							{clearCanvas: !pointDrawn, color: '#7CFC00', size: 8, width: 2}, this.props.preview);
					} else {
						drawCrossHairInitial(focusUnit[0].x, focusUnit[0].y, this.focusPointsCanvas,
							{clearCanvas: !pointDrawn, color: 'green', size: 5, width: 1}, this.props.preview);
					}
					pointDrawn = true;
				}
			}
		}
	}

	deleteFocusPoint = (islandID, index) => {
		this.props.dispatch(onDeleteFocusPoint(this.props.device_id, this.props.id, this.props.preview, this.focusPointsCanvas, islandID, index));
	}

	render() {

		let status = (this.props.preview || {}).status;
		let brushComponent = null;
		let speedSetter = null;
		let zStackConfig = null;
		let allowPainting = false;
		let focusPointsTable = null;
		let buttonsPanel = null;
		let slotComp = null;
		let slideNameInput = null;
		let scanButton = null;
		let specimenChooser = null;
		let objectiveChooser = null;
		let scanOutputFormatChanger = null;
		let adminPanel = null;

		if (status === RegionSelectionConstants.SELECTION_DONE) {
			brushComponent = getBrushPanel(this.state.usingBrush, this.state.brushSize, this.toggleBrushEraser, this.movedBrushSizeSlider);
			speedSetter = getSpeedPanel(this.onScanSpeedChange, this.props.preview);
			zStackConfig = getZStackConfigPanel(this.onZLevelsChange, this.onStepSizeChange, this.props.preview);
			slideNameInput = <Row>
								<Col span={10} offset={7}>
									<span className="arial-font" style={{marginRight:10, fontSize:13}}>Slide Name </span>
									<Spin spinning={this.state.reading_barcode} tip="Trying to Read Barcode...">
										<Search value={this.props.preview.slideName} placeholder={this.props.preview.response.slideName} enterButton="Read Barcode" onChange={this.onScanNameChange} onSearch={this.readbarcode}/>
									</Spin>
								</Col>
							</Row>
			allowPainting = true;
		}

		if (status === RegionSelectionConstants.FETCHED_IMAGE || status === RegionSelectionConstants.SELECTION_DONE || 
			status === RegionSelectionConstants.START_DROP_DISTANCE_SELECTION || status === RegionSelectionConstants.START_FOCUS_POINTS_SELECTION) {
			buttonsPanel = [
				getButtonsPanel(status, this.confirmClicked, this.onRetakePreview, this.clearSelection, this.selectFullRegion, this.state.regions, this.props.cassetteSize), 
				<Row key={1}>
					<Col span={6} offset = {9} style={{fontSize:14}}>
						Selected Area : {this.state.regionArea} mm2
					</Col>
				</Row>
			]
		}

		if (status === RegionSelectionConstants.START_FOCUS_POINTS_SELECTION) {
			focusPointsTable = this.getFocusPointsTable();
		}

		if (status === RegionSelectionConstants.CONFIRMED) {
			buttonsPanel = getConfirmedPanel(this.clearSelection);
		}

		if(!this.state.overrideBlank && ((this.props.preview || {}).response || {}).islandsFailure && status === RegionSelectionConstants.CONFIRMED){
			buttonsPanel = getBlankSlidePanel(this.overrideBlankSlide);
		}

		if (this.props.cassetteSize === 1 && status === RegionSelectionConstants.SELECTION_DONE) {
			scanButton =  <Row className="start-scan-button-div">
							<Button className="start-scan-button" type="danger" size={'large'} onClick={this.confirmClicked}><ScanOutlined /> Start Scanning</Button>
						</Row>;
		}

		let is_superuser = cookie.loadAll().superuser === "true";

		if ( is_superuser && status === RegionSelectionConstants.SELECTION_DONE) {
			adminPanel = getAdminPanel(this.props.preview, this.toggleTiling, this.toggleTilingAfterScan);
		}

		if (status === RegionSelectionConstants.SELECTION_DONE) {
			specimenChooser = getSpecimenChooser(this.onSpecimenTypeChange, this.props.preview, is_superuser);
			if (this.props.isObjectiveSwitcherPresent && this.props.allowObjectiveChange) {
				objectiveChooser = getObjectiveChooser(this.onObjectiveTypeChange, this.props.preview)
			}
			if (this.props.allowScanOutputFormatChange) {
				scanOutputFormatChanger = getScanOutputFormatChanger(this.onScanOutputFormatChange, this.props.preview);
			}
		}

		if (this.props.showSummary) {
			slotComp = this.props.summaryDiv;
		} else if (this.props.id === -1 || status === RegionSelectionConstants.NOT_STARTED) { 
		} else if (status !== RegionSelectionConstants.ERRORED_OUT) {
			slotComp = [
				<Row key={this.props.id} className="slot-header arial-font preview-for-region-selection">
					{status !== RegionSelectionConstants.CONFIRMED ? <Row style={{ fontSize: 18 }}>
						{status !== RegionSelectionConstants.SELECTION_DONE ?
							"Please select the area to scan." :
							"Adjust scan area using the Brush tool."}
					</Row> : undefined}
					{!this.state.overrideBlank && ((this.props.preview || {}).response || {}).islandsFailure && status == RegionSelectionConstants.CONFIRMED ? "Looks Like a blank Slide. Still Continue with region Selection ?":
					<Row>
						{getCanvasComponent(status, this.props.id, this.previewCanvasRef, this.thresholdCanvasRef, this.state.regions, 
							this.onCanvasUpdate, allowPainting, this.startPainting, this.paintCanvas, this.stopPainting, 
							this.dropDistanceCanvas, this.dropDistanceCanvasRef, this.focusPointsCanvas, this.focusPointsCanvasRef, 
							this.props.preview, this.props.id, this.props.device_id, this.showBrushOrEraserSize)}
						<Col span={8}>
							{((this.props.preview || {}).response || {}).barcodeLocation == undefined ? <div></div> :
							<img 
								className="barcode"
								src={"/dev-ssd/" + ((this.props.preview || {}).response || {}).barcodeLocation + "?d=" + new Date().getTime()}></img>}
						</Col>
					</Row>}
					{brushComponent}
					{speedSetter}
					{zStackConfig}
					{slideNameInput}
					{specimenChooser}
					{objectiveChooser}
					{scanOutputFormatChanger}
					<div style={{maxHeight: 300, overflowY: 'auto'}}>
						{focusPointsTable}
					</div>
					{buttonsPanel}
					{adminPanel}
					{scanButton}
				</Row>
			]
		} else {
			slotComp = <Row>Error</Row>;
		}

		return <Spin spinning={this.props.preview.status === RegionSelectionConstants.GETTING_IMAGE}>
			{slotComp}
		</Spin>;
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		preview: state.previewStatusReducer[ownProps.id],
	};
};

export default connect(mapStateToProps)(SlotComponent);
