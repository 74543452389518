import React, {Component} from "react";
import {CircularProgress, Grid, Link, Stack, Typography} from "@mui/material";
import axios from "axios";
import {AxiosConfig} from "../helper/axios.config";
import {displayError} from "../helper/display.error";
import {SignalWifi3BarTwoTone, SignalWifiStatusbarNullTwoTone} from "@mui/icons-material";
import {isTrue, openInNewTab} from "../utils/utils";
import {isDeviceIPRemote} from "../utils/device_utils";

class Devices extends Component {

	constructor(props) {
		super(props);

		this.state = {
			devices: [],
		}

		this.fetchDevices().then(this.pingDevices);
	}

	fetchDevices = () => axios.get('/api/device/', AxiosConfig())
		.then(response => this.setState({devices: response.data}))
		.catch(error => displayError("Failed to fetch devices", error));

	pingDevices = () => this.state.devices.forEach(device => this.pingDevice(device));

	pingDevice = device => isDeviceIPRemote(device.ip) ?
		axios.get(`/api/ping/?url=${device.ip}`, AxiosConfig())
			.then(response => this.setDevicePingState(device, isTrue(response.data)))
			.catch(error => displayError("Failed to ping device", error)) :
		this.setDevicePingState(device, true);


	setDevicePingState = (device, ping) => {
		device.ping = ping;
		this.setState({});
	}

	getPingComponent = ping => ping ?
		<SignalWifi3BarTwoTone sx={{color: 'green'}}/> :
		ping === false ?
			<SignalWifiStatusbarNullTwoTone sx={{color: 'red'}}/> :
			<CircularProgress size={20}/>


	getDeviceComponent = (device) =>
		<Stack direction={"column"} bgcolor={"#d4d4d4"} margin={1} padding={1}>
			<Typography variant={"overline"} fontSize={22} fontWeight={"fontWeightMedium"} align={"center"}>
				{device.name}
			</Typography>
			<Stack direction={"row"} justifyContent={"space-between"} margin={1} padding={1}>
				<Link onClick={() => openInNewTab(device.ip)}>{device.ip}</Link>
				{this.getPingComponent(device.ping)}
			</Stack>
		</Stack>

	render() {
		return <Grid container>
			<Grid item xs={12}>
				<Typography align={"center"} variant={"h3"}>Devices</Typography>
			</Grid>
			{this.state.devices.map(device =>
				<Grid item minWidth={300}>{this.getDeviceComponent(device)}</Grid>
			)}
		</Grid>
	}
}

export default Devices;
