import {
	ADD_ANNOTATION,
	DELETE_ANNOTATION,
	UPDATE_ANNOTATION_STATE,
	UPDATE_BLOOD_ANNOTATION_STATE,
	UPDATE_BLOOD_VIEWER_SETTINGS,
	UPDATE_GRID_STATE,
	UPDATE_IHC_STATE,
	UPDATE_MAP_STATE,
	UPDATE_MAPS_STATE,
	UPDATE_SLIDE_STATE,
	UPDATE_VIEWER_SETTINGS,
	UPDATE_MAP_IN_FOCUS,
} from "../actionTypes/maps.state.const";

const initialState = {
}

export const mapsStateReducer = (state = initialState, action) => {
	let annotationState;
	switch (action.type) {
		case UPDATE_MAP_STATE:
			return Object.assign({}, state, {
				[action.mapId] : {
					...state[action.mapId],
					...action.mapState,
				}
			});

		case UPDATE_MAPS_STATE:
			return Object.assign({}, action.state);

		case UPDATE_SLIDE_STATE:
			return Object.assign({}, state, {
				[action.mapId]: {
					...state[action.mapId],
					slideState: {
						...state[action.mapId].slideState,
						...action.slideState,
					}
				}
			});

		case UPDATE_GRID_STATE:
			return Object.assign({}, state, {
				[action.mapId]: {
					...state[action.mapId],
					gridState: {
						...state[action.mapId].gridState,
						...action.gridState,
					}
				}
			});

		case UPDATE_VIEWER_SETTINGS:
			state[action.mapId].slideState.slide_data.viewer_settings = {
				...state[action.mapId].slideState.slide_data.viewer_settings,
				...action.viewerSettings,
			};
			return Object.assign({}, state);

		case UPDATE_BLOOD_VIEWER_SETTINGS:
			state[action.mapId].slideState.slide_data.blood_viewer_settings = {
				...state[action.mapId].slideState.slide_data.blood_viewer_settings,
				...action.bloodViewerSettings,
			};
			return Object.assign({}, state);

		case UPDATE_ANNOTATION_STATE:
			return Object.assign({}, state, {
				[action.mapId]: {
					...state[action.mapId],
					annotationState: {
						...state[action.mapId].annotationState,
						...action.annotationState,
					}
				}
			});

		case ADD_ANNOTATION:
			annotationState = state[action.mapId].annotationState;
			return Object.assign({}, state, {
				[action.mapId]: {
					...state[action.mapId],
					annotationState: {
						...annotationState,
						// sort in decrement order by id, same way it's coming from backend
						annotations: [action.annotation, ...annotationState.annotations.filter(
							annotation => annotation.id !== action.annotation.id)]
							.sort((annotation1, annotation2) => annotation2.id - annotation1.id),
					}
				}
			});

		case DELETE_ANNOTATION:
			annotationState = state[action.mapId].annotationState;
			return Object.assign({}, state, {
				[action.mapId]: {
					...state[action.mapId],
					annotationState: {
						...annotationState,
						annotations: [...annotationState.annotations.filter(
							annotation => annotation.id !== action.annotationId)],
					},
				},
			});

		//Blood Annotations Layer Handler

		case UPDATE_BLOOD_ANNOTATION_STATE:
			return Object.assign({}, state, {
				[action.mapId]: {
					...state[action.mapId],
					bloodAnnotationState: {
						...state[action.mapId].bloodAnnotationState,
						...action.bloodAnnotationState,
					}
				}
			});

		case UPDATE_IHC_STATE:
			return Object.assign({}, state, {
				[action.mapId]: {
					...state[action.mapId],
					ihcState: {
						...state[action.mapId].ihcState,
						...action.ihcState,
					}
				}
			});

		case UPDATE_MAP_IN_FOCUS:
			return Object.assign({}, state, {
				[action.mapId]: {
					...state[action.mapId],
					mapInFocus: action.mapInFocus
				}
			})

		default:
			return state;
	}
}
