import cookie from "react-cookies";
import axios from 'axios';
import {AuthHeader} from "../helper/auth.token"
import { AdminStateConstants } from '../actionTypes/admin.state.constant';
import { cameraModeTypes } from "../utils/const";
import { cameraAppKey, previewAppLiveModeKey } from '../component/manualmode/initial_setup_app_keys_live_view';
import { objectiveType, previewCameraType, focusMode } from "../utils/const";

export const getNewState = () => {
    return {
        app_state: {}, 
        metric_state: {},
        apps_initialised: false, 
        camera_mode: cameraModeTypes.LIVE_CAMERA,
        motion_app_action: false,
        camera_app_action: false,
        preview_app_action: false,
        reload_settings_action: false,
        qc_settings_app_action: false,
        images_action: false,
        images: [],
        quarter_size: 450,
        step_size: 0.1,
        steps: 50,
        numberOfSlots: 0,
        deviceIP: '',
        slotID: -1,
        liveModePreviewVectorLayer: null,
        doDropDistanceAF: true,
        doAutoFocus: false,
        takeZStack: true,
        showZStackImages: false,
        lastImageTimstampLiveMode: -1,
        zStackLevel: 0,
        focusMode: focusMode.DO_NOTHING,
		live_mode_action: false,
		main_light: true,
		areaVisited: [],
		currPoint: [],
		loadingMessage: '',
		objectiveType: objectiveType.FORTY_X,
		rectHeight: 2048,
		rectWidth: 2448,
		uperPixel: 0.2496,
		previewImageExtent: [],
		previewCamera: previewCameraType.NORMAL,
		useStreakFocus: true,
	}}

const InitialState = getNewState()

export const adminUrlReducer = ( state = InitialState , action) => {
    let returnableState = {};
    let allAppStates = {};
    let currentAppState = {};
    let newAppState = {};
    let keys = [];
    switch(action.type){
        case AdminStateConstants.INIT_ADMIN_STATE:
            returnableState = Object.assign({}, state, action.urlState);
            return returnableState;
        case AdminStateConstants.UPDATE_ADMIN_STATE:
            returnableState = Object.assign({}, action.urlState);
            return returnableState;
        case AdminStateConstants.UPDATE_ADMIN_APP_CLOSED_STATE:
            allAppStates = state.app_state;
            currentAppState = allAppStates[action.appName] || {};
            newAppState = Object.assign({}, allAppStates, {
                [action.appName]: Object.assign({}, currentAppState, {
                    closed: action.appClosedStatus}) });
            returnableState = Object.assign({}, state, {
                app_state : newAppState
            });
            updateAppSettingsInDb(newAppState);
            return returnableState;
        case AdminStateConstants.CLOSE_ALL_APPS_ADMIN:
            allAppStates = state.app_state;
            keys = Object.keys(allAppStates);
            newAppState = Object.assign({}, allAppStates);
            for(let i = 0; i < keys.length; i++) {
                currentAppState = allAppStates[keys[i]] || {};
                newAppState = Object.assign({}, newAppState, {
                    [keys[i]]: Object.assign({}, currentAppState, {
                        closed: true}) });
            }
            returnableState = Object.assign({}, state, {
                app_state : newAppState
            });
            updateAppSettingsInDb(newAppState);
            return returnableState;
        case AdminStateConstants.ARRANGE_APPS_LIVE_MODE:
            allAppStates = state.app_state;
            keys = Object.keys(allAppStates);
            newAppState = Object.assign({}, allAppStates);
            for(let i = 0; i < keys.length; i++) {
                if(keys[i] == cameraAppKey.id) {
                    currentAppState = allAppStates[keys[i]] || {};
                    newAppState = Object.assign({}, newAppState, {
                        [keys[i]]: Object.assign({}, currentAppState, {
                            closed: false, position: {x: 0, y: 0}}) });
                } else if(keys[i] == previewAppLiveModeKey.id) {
                    currentAppState = allAppStates[keys[i]] || {};
                    newAppState = Object.assign({}, newAppState, {
                        [keys[i]]: Object.assign({}, currentAppState, {
                            closed: false, position: {x: 77.064, y: 10.499}}) });
                } else {
                    currentAppState = allAppStates[keys[i]] || {};
                    newAppState = Object.assign({}, newAppState, {
                        [keys[i]]: Object.assign({}, currentAppState, {
                            closed: true}) });
                }
            }
            returnableState = Object.assign({}, state, {
                app_state : newAppState
            });
            updateAppSettingsInDb(newAppState);
            return returnableState;
        case AdminStateConstants.UPDATE_ADMIN_APP_POSITION:
            allAppStates = state.app_state;
            currentAppState = allAppStates[action.appName] || {};
            newAppState = Object.assign({}, allAppStates, {
                [action.appName]: Object.assign({}, currentAppState, {
                    position: action.appPosition}) });
            returnableState = Object.assign({}, state, {
                app_state : newAppState
            });
            updateAppSettingsInDb(newAppState);
            return returnableState;
        case AdminStateConstants.UPDATE_ADMIN_APP_CLICK_TIME:
            allAppStates = state.app_state;
            currentAppState = allAppStates[action.appName] || {};
            newAppState = Object.assign({}, allAppStates, {
                [action.appName]: Object.assign({}, currentAppState, {
                    clicked_at: new Date().getTime()}) });
            
            let appVsClickTime = [];

            for (let key in newAppState) {
                appVsClickTime.push({
                    key: key, 
                    val: newAppState[key].clicked_at == undefined ? 0 : newAppState[key].clicked_at
                })
            }

            appVsClickTime = appVsClickTime.sort(function (a, b) {
                return a.val - b.val;
            });

            appVsClickTime.map((item, index) => {
                newAppState[item.key].zIndex = 9 + 2 * index;
            })
            
            returnableState = Object.assign({}, state, {
                app_state : newAppState
            });
            updateAppSettingsInDb(newAppState);
            return returnableState;
        case AdminStateConstants.IMAGES_ACTION_ONGOING:
            returnableState = Object.assign({}, state);
            returnableState.images_action = action.actionStatus;
            return returnableState;
        case AdminStateConstants.UPDATE_SAVED_IMAGES:
            returnableState = Object.assign({}, state);
            returnableState.images = action.images;
            return returnableState;
        case AdminStateConstants.UPDATE_DEVICE_IP:
            returnableState = Object.assign({}, state);
            returnableState.deviceIP = action.deviceIP;
            return returnableState;
        case AdminStateConstants.UPDATE_NUMBER_OF_SLOTS:
            returnableState = Object.assign({}, state);
            returnableState.numberOfSlots = action.numberOfSlots;
            return returnableState;
        case AdminStateConstants.UPDATE_LIVE_MODE_PREVIEW_VECTOR_LAYER:
            returnableState = Object.assign({}, state);
            returnableState.liveModePreviewVectorLayer = action.value;
            return returnableState;
        case AdminStateConstants.UPDATE_DO_DROP_DISTANCE_AF:
            returnableState = Object.assign({}, state);
            returnableState.doDropDistanceAF = action.value;
            return returnableState;
        case AdminStateConstants.UPDATE_DO_AUTO_FOCUS:
            returnableState = Object.assign({}, state);
            returnableState.doAutoFocus = action.value;
            return returnableState;
        case AdminStateConstants.UPDATE_TAKE_Z_STACK:
            returnableState = Object.assign({}, state);
            returnableState.takeZStack = action.value;
            return returnableState;
        case AdminStateConstants.UPDATE_SHOW_Z_STACK_IMAGES:
            returnableState = Object.assign({}, state);
            returnableState.showZStackImages = action.value;
            return returnableState;
        case AdminStateConstants.UPDATE_LAST_IMAGE_TIMESTAMP_LIVE_MODE:
            returnableState = Object.assign({}, state);
            returnableState.lastImageTimstampLiveMode = action.value;
            return returnableState;
        case AdminStateConstants.UPDATE_Z_STACK_LEVEL:
            returnableState = Object.assign({}, state);
            returnableState.zStackLevel = action.value;
            return returnableState;
        case AdminStateConstants.UPDATE_FOCUS_MODE:
            returnableState = Object.assign({}, state);
            returnableState.focusMode = action.value;
            return returnableState;
        case AdminStateConstants.UPDATE_LIVE_MODE_ACTION:
            returnableState = Object.assign({}, state);
            returnableState.live_mode_action = action.value;
            returnableState.loadingMessage = action.message;
            return returnableState;
        case AdminStateConstants.UPDATE_MAIN_LIGHT:
            returnableState = Object.assign({}, state);
            returnableState.main_light = action.value;
            return returnableState;
        case AdminStateConstants.UPDATE_AREA_VISITED:
            returnableState = Object.assign({}, state);
            returnableState.areaVisited = action.value;
            return returnableState;
        case AdminStateConstants.UPDATE_CURR_POINT:
            returnableState = Object.assign({}, state);
            returnableState.currPoint = action.value;
            return returnableState;
        case AdminStateConstants.UPDATE_CAMERA_CONFIG:
            returnableState = Object.assign({}, state);
            returnableState.rectHeight = action.height;
            returnableState.rectWidth = action.width;
            returnableState.uperPixel = action.uperpixel;
            return returnableState;
        case AdminStateConstants.UPDATE_PREVIEW_IMAGE_EXTENT:
            returnableState = Object.assign({}, state);
            returnableState.previewImageExtent = action.value;
            return returnableState;
        case AdminStateConstants.UPDATE_SLOT_ID:
            returnableState = Object.assign({}, state);
            returnableState.slotID = action.value;
            return returnableState;
        case AdminStateConstants.UPDATE_METRIC_STATE:
            returnableState = Object.assign({}, state);
            returnableState.metric_state = action.metricState;
            updateMetricSettingsInDb(action.metricState);
            return returnableState;
        case AdminStateConstants.UPDATE_USE_FOUR_X_FOR_PREVIEW:
            returnableState = Object.assign({}, state);
            if(action.value) {
                returnableState.previewCamera = previewCameraType.FOUR_X;
            } else {
                returnableState.previewCamera = previewCameraType.NORMAL;
            }
            return returnableState;
        case AdminStateConstants.UPDATE_USE_STREAK_FOCUS:
            returnableState = Object.assign({}, state);
            returnableState.useStreakFocus = action.value;
            return returnableState;
        default :
            returnableState = state
            return returnableState;
    }
}


export const updateAppSettingsInDb = (app_state) => {
    let url = `/api/adminsetting/` + cookie.load('admin_setting').id + `/`;
    axios.put(url, {
        app_state
    }, {headers: {Authorization : AuthHeader()}})
        .then(response => {
            if(response.status === 200 || response.status === 301 || response.status === 302) {
            }
            else if(response.status === 403 || response.status === 401 || response.status === 400){
                console.log("Position Update Save Failed", response);
            }
        })
        .catch(err =>{
            console.log("Position Update Save Failed, ", err);
        });
}


export const updateMetricSettingsInDb = (metric_state) => {
    let url = `/api/adminsetting/` + cookie.load('admin_setting').id + `/`;
    axios.put(url, {
        metric_state
    }, {headers: {Authorization : AuthHeader()}})
        .then(response => {
            if(response.status === 200 || response.status === 301 || response.status === 302) {
            }
            else if(response.status === 403 || response.status === 401 || response.status === 400){
                console.log("Position Update Save Failed", response);
            }
        })
        .catch(err =>{
            console.log("Position Update Save Failed, ", err);
        });
}