import React, { Component } from 'react';
import { Row, Col, Input, Checkbox, Divider, Button, message, Slider, InputNumber } from 'antd';
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from 'axios';
import { AuthHeader } from '../../../helper/auth.token';
import { scanWorkflowLoadingMessages } from '../../../utils/const';
import { updateActionOngoing } from '../../../action/preview_status.action';

class CameraSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputFieldInFocus: false,
        }
    }

    componentDidMount = () => {
        
    }

    // setExposureValue = (value) => {
    //     this.props.dispatch(updateActionOngoing(this.props.id, true, scanWorkflowLoadingMessages.SET_EXPOSURE_VALUE));
    //     let partsOfUrl = "api~scan~set_exposure";
    //     let url = `/server/scano/` + this.props.device_id + `/` + partsOfUrl + "/?exposure=" + value + "&objectiveType=" + this.props.preview.objectiveType;
    //     axios.get(url, { headers: { Authorization: AuthHeader() } })
    //         .then(response => {
    //             if (response.status === 200) {
    //                 this.setState({
    //                     exposureInputValue: response.data['exposureTime']
    //                 });
    //                 this.props.getFortyXImageDDPoint();
    //                 message.success("Exposure set successfully!!", 2.5);
    //             }
    //             else {
    //                 console.log(response);
    //                 message.error("Not able to set exposure!!", 2.5);
    //                 this.props.dispatch(updateActionOngoing(this.props.id, false, scanWorkflowLoadingMessages.EMPTY_MESSAGE));
    //             }
    //         })
    //         .catch(err => {
    //             console.log(err);
    //             message.error("Not able to set exposure!!", 2.5);
    //             this.props.dispatch(updateActionOngoing(this.props.id, false, scanWorkflowLoadingMessages.EMPTY_MESSAGE));
    //         })
    // }

    // setGammaValue = (value) => {
    //     this.props.dispatch(updateActionOngoing(this.props.id, true, scanWorkflowLoadingMessages.SET_GAMMA_VALUE));
    //     let partsOfUrl = "api~scan~set_gamma";
    //     let url = `/server/scano/` + this.props.device_id + `/` + partsOfUrl + "/?gamma=" + value + "&objectiveType=" + this.props.preview.objectiveType;
    //     axios.get(url, { headers: { Authorization: AuthHeader() } })
    //         .then(response => {
    //             if (response.status === 200) {
    //                 this.setState({
    //                     gammaInputValue: response.data['gamma']
    //                 });
    //                 this.props.getFortyXImageDDPoint();
    //                 message.success("Gamma set successfully!!", 2.5);
    //             }
    //             else {
    //                 console.log(response);
    //                 message.error("Not able to set gamma!!", 2.5);
    //                 this.props.dispatch(updateActionOngoing(this.props.id, false, scanWorkflowLoadingMessages.EMPTY_MESSAGE));
    //             }
    //         })
    //         .catch(err => {
    //             console.log(err);
    //             message.error("Not able to set gamma!!", 2.5);
    //             this.props.dispatch(updateActionOngoing(this.props.id, false, scanWorkflowLoadingMessages.EMPTY_MESSAGE));
    //         })
    // }

    onFocusInputField = () => {
        this.setState({
            inputFieldInFocus: true,
        });
    }

    onBlurInputField = () => {
        this.setState({
            inputFieldInFocus: false,
        });
    }

    getCameraSliderRow = (haveRowStyle, heading, slider) => {
        return (
            <Row style={haveRowStyle ? { marginTop: 5 } : {}}>
                <Col span={3}>
                    <b>{heading}</b>
                </Col>
                <Col span={21}>
                    {slider}
                </Col>
            </Row>
        )
    }

    render() {

        let minExposureValue = this.props.livemodeConfig.minExposure != undefined ? this.props.livemodeConfig.minExposure : 5;
        let maxExposureValue = this.props.livemodeConfig.maxExposure != undefined ? this.props.livemodeConfig.maxExposure : 25;

        let minGammaValue = this.props.livemodeConfig.minGamma != undefined ? this.props.livemodeConfig.minGamma : 0.1;
        let maxGammaValue = this.props.livemodeConfig.maxGamma != undefined ? this.props.livemodeConfig.maxGamma : 1;

        return (
            <Row>
                <Col span={18}>
                    {this.getCameraSliderRow(false, 'Exposure',
                        <Row>
                            <Col span={18}>
                                <Slider value={this.props.exposureInputValue} min={minExposureValue} max={maxExposureValue} //onAfterChange={this.setExposureValue} 
                                    onChange={this.props.exposureValueChange} style={{padding: 0, margin: '8px 0px 0px 0px'}}  />
                            </Col>
                            <Col span={5} offset={1}>
                                <InputNumber min={minExposureValue} max={maxExposureValue} style={{ margin: '0 16px' }} 
                                    value={this.props.exposureInputValue} onChange={this.props.exposureValueChange}/>
                            </Col>
                        </Row>
                    )}
                    {this.getCameraSliderRow(true, 'Gamma',
                    <Row>
                        <Col span={18}>
                            <Slider value={this.props.gammaInputValue} min={minGammaValue} max={maxGammaValue} step={0.1} //onAfterChange={this.setGammaValue} 
                                onChange={this.props.gammaValueChange} style={{padding: 0, margin: '8px 0px 0px 0px'}} 
                            />
                        </Col>
                        <Col span={5} offset={1}>
                        <InputNumber min={minGammaValue} max={maxGammaValue} style={{ margin: '0 16px' }} 
                                        value={this.props.gammaInputValue} onChange={this.props.gammaValueChange}/>
                        </Col>
                    </Row>
                    )}
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
		preview: state.previewStatusReducer[ownProps.id],
    };
};

export default connect(mapStateToProps)(CameraSettings);
