import RemoveIcon from "@mui/icons-material/Remove";
import React from "react";
import RectangleOutlinedIcon from "@mui/icons-material/RectangleOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CreateIcon from "@mui/icons-material/Create";
import RouteIcon from "@mui/icons-material/Route";
import PentagonOutlinedIcon from "@mui/icons-material/PentagonOutlined";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import {createBox} from "ol/interaction/Draw";
import LineString from "ol/geom/LineString";

export const lineDrawingKey = {
    name: 'Line',
    db_key: 'line',
    shape: 'LineString',
    icon: <RemoveIcon sx={{transform: "rotate(-45deg) scale(1.4)"}}/>,
    params: {
        maxPoints: 2,
    },
    getGeometricParams: (geometry) => ({
        area: 0,
        perimeter: geometry.getLength(),
        coordinates: geometry.getCoordinates(),
    }),
    shortcutKeyCode: 'Digit1',
}

export const freelineDrawingKey = {
    name: 'Freeline',
    db_key: 'line',
    shape: 'LineString',
    icon: <CreateIcon/>,
    params: {
        freehand: true,
    },
    getGeometricParams: lineDrawingKey.getGeometricParams,
    shortcutKeyCode: 'Digit2',
}

export const circleDrawingKey = {
    name: 'Circle',
    db_key: 'circle',
    shape: 'Circle',
    icon: <CircleOutlinedIcon />,
    getGeometricParams: (geometry) => ({
        area: geometry.getRadius() * geometry.getRadius() * Math.PI,
        perimeter: 2 * geometry.getRadius() * Math.PI,
        coordinates: [geometry.getCenter(), geometry.getRadius()],
    }),
    shortcutKeyCode: 'Digit3',
}

export const freehandDrawingKey = {
    name: 'Freehand',
    db_key: 'polygon',
    shape: 'Polygon',
    icon: <RouteIcon />,
    params: {
        freehand: true,
    },
    getGeometricParams: (geometry) => ({
        area: geometry.getArea(),
        perimeter: new LineString(geometry.getLinearRing(0).getCoordinates()).getLength(),
        coordinates: geometry.getCoordinates(),
    }),
    shortcutKeyCode: 'Digit4',
}

export const rectangleDrawingKey = {
    name: 'Rectangle',
    db_key: 'polygon',
    shape: 'Circle',
    icon: <RectangleOutlinedIcon/>,
    params: {
        geometryFunction: createBox(),
    },
    getGeometricParams: freehandDrawingKey.getGeometricParams,
    shortcutKeyCode: 'Digit5',
}

export const polygonDrawingKey = {
    name: 'Polygon',
    db_key: 'polygon',
    shape: 'Polygon',
    icon: <PentagonOutlinedIcon/>,
    getGeometricParams: freehandDrawingKey.getGeometricParams,
    shortcutKeyCode: 'Digit6',
}

export const magicToolDrawingKey = {
    name: 'MagicTool',
    db_key: 'line',
    shape: 'LineString',
    icon: <AutoFixHighOutlinedIcon/>,
    params: {
        maxPoints: 2,
    },
    getGeometricParams: freelineDrawingKey.getGeometricParams,
    shortcutKeyCode: 'Digit7',
}

export const magicToolResultDrawingKey = {
	name: 'MagicToolResult',
	db_key: 'polygon',
	shape: 'Polygon',
	icon: <AutoFixHighOutlinedIcon/>,
	getGeometricParams: freehandDrawingKey.getGeometricParams,
}

export const getAllDrawingTools = () => [
    lineDrawingKey,
    freelineDrawingKey,
    circleDrawingKey,
    freehandDrawingKey,
    rectangleDrawingKey,
    polygonDrawingKey,
    magicToolDrawingKey,
]
