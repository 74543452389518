import {
	CLEAR_NOTIFICATION,
	PUSH_NOTIFICATION,
	UPDATE_GAMMA_STATE,
	UPDATE_LAST_MAP_COUNT,
} from "../actionTypes/gamma.state.const";
import axios from "axios";
import {AxiosConfig} from "../helper/axios.config";
import {displayError} from "../helper/display.error";

export const updateGammaState = (state) => ({
    type: UPDATE_GAMMA_STATE,
    state: state,
});

export const pushNotification = (notification) => ({
    type: PUSH_NOTIFICATION,
    notification: notification,
});

export const clearNotification = (id) => ({
    type: CLEAR_NOTIFICATION,
    id: id,
});

export const updateActiveMap = (mapId) => updateGammaState({
    activeMapId: mapId,
});

export const updateSync = (sync) => updateGammaState({
    sync: sync
});

export const updateActiveAnnoDrawer = (activeAnnoDrawer) => updateGammaState({
    activeAnnoDrawer: activeAnnoDrawer,
});

export const updateSelectedDrawTool = selectedDrawTool => updateGammaState({selectedDrawTool});

export const updateSelectedDrawColor = selectedDrawColor => updateGammaState({selectedDrawColor});

export const updateLabellingImage = labellingImage => updateGammaState({labellingImage});

export const updateLastMapCount = () => ({
    type: UPDATE_LAST_MAP_COUNT,
});

/**
 * @deprecated: [Priyanshu] use viewer.setting actions and reducer
 * */
export const loadAllViewerSettings = () => dispatch =>
	axios.get(`/api/viewersetting/`, AxiosConfig())
		.then(response =>
			dispatch(updateGammaState({
				viewerSettings: response.data,
			}))
		).catch(error => displayError("Failed to load all viewer settings", error));

/**
 * @deprecated: [Priyanshu] use viewer.setting actions and reducer
 * */
export const createNewViewerSetting = (viewerSetting) => dispatch =>
	axios.post(`/api/viewersetting/`, viewerSetting, AxiosConfig())
		.then(dispatch(loadAllViewerSettings())
		).catch(error => displayError("Failed to create new viewer setting", error));
