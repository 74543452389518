import React, {Component} from 'react';
import {Select} from 'antd';
import {connect} from 'react-redux'
import axios from 'axios';
import cookie from "react-cookies";
import {AuthHeader} from '../../../helper/auth.token'
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from "react-quill"; // ES6
import '../../../asset/style/quill.css'
import {Button, IconButton, Stack, Tooltip} from "@mui/material";
import {notifyInfo} from "../utils/display.notification";
import Typography from "@mui/material/Typography";
import querystring from "query-string";

const {Option} = Select;

class ReportApp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: this.props.slideData,
			edit: false,
			name: this.props.slideData.name,
			specimen_type: this.props.slideData.specimen_type,
			case_details: (this.props.slideData.case_details == null || this.props.slideData.case_details === "") ?
				"NIL" : this.props.slideData.case_details,
			scan_time: 0.0,
			slide_meta: {},
			slide_time: new Date(this.props.slideData.date_time),
			CAPContent: '',
			cap_map: [],
			selectedCAP: '',
			currCase: [],
			can_edit: true,
			editing_user: '',
		};
    }

    componentDidMount = () => {
        let url = "/api/fetch_cap_map/";
        axios.get(url, { headers: { Authorization: AuthHeader() } })
			.then(response => {
				let cap_map = response.data;
				this.setState({
					cap_map: cap_map
				});
			})
			.catch(err => {
				console.log(err);
			});
		let url_ = '/api/get_slide_case_details/?case_id=' + this.props.slideData.case_id;
		if (this.props.slideData.case_id !== "" && this.props.slideData.case_id !== null) {
			axios.get(url_, {headers: {Authorization: AuthHeader()}})
				.then(response => {
					console.log("currrcase", response.data)
					this.setState({
						currCase: response.data
					})
					this.getCAPReportEditStatus();
				})
		}
	}
    componentWillMount = () => {
        window.addEventListener("beforeunload", this.unload);
    }

    onChangeCAPContent = (value) => {
        this.setState({
            CAPContent: value
        })
    }

    handleCAPTemplateChange = (value) => {
        this.setState({
            selectedCAP: value
        })
        let template = ""
        let cap_map = this.state.cap_map
        for (let i = 0; i < cap_map.length; i++) {
			if (cap_map[i]["search_key"] === value) {
				console.log(this.state.currCase["report_type"])
				if (cap_map[i]["search_key"] === this.state.currCase.cap_report_type) {
					template = this.state.currCase.cap_report
				} else {
					template = this.state.cap_map[i]["template"]
					break;
				}
			}
        }
        this.setState({
            CAPContent: template
        })
    }

	unload = () => {
		let url = "/api/open_cap_edit/"
		let case_id = this.state.currCase.case_id
		let data = {
			'case_id': case_id,
			'user_id': parseInt(cookie.load('user_id')),
		};
		axios.post(url, querystring.stringify(data), {headers: {Authorization: AuthHeader()}})
			.then();
	}

    componentDidUpdate = (prevProps, prevState) => {
		if (prevState.CAPContent !== this.state.CAPContent) {
			this.saveCAPReport();
		}
    }

    componentWillUnmount = () => {
        this.releaseCAPEditAccess()
    }

    getCAPReportEditStatus = () => {
        let url = "/api/get_cap_edit_status/"
        let case_id = this.state.currCase.case_id
        let data = {
            'case_id': case_id,
            'user_id': parseInt(cookie.load('user_id')),
        };
        axios.post(url, querystring.stringify(data), { headers: { Authorization: AuthHeader() } })
            .then(response => {
                    if(response.data["can_edit"] === 0){
                        this.setState({
                            can_edit: false,
                            editing_user: response.data["editing_user"]
                        })
                    }
                }
            );
    }

    releaseCAPEditAccess = () => {
		let url = "/api/open_cap_edit/"
		let case_id = this.state.currCase.case_id
		let data = {
			'case_id': case_id,
			'user_id': parseInt(cookie.load('user_id')),
		};
		axios.post(url, querystring.stringify(data), {headers: {Authorization: AuthHeader()}})
			.then();
	}

    getOverwriteAccess = () => {
		let url = "/api/overwrite_cap_edit/"
		let case_id = this.state.currCase.case_id
		let data = {
			'case_id': case_id,
			'user_id': parseInt(cookie.load('user_id')),
		};
		axios.post(url, querystring.stringify(data), {headers: {Authorization: AuthHeader()}})
			.then(() => {
					this.getCAPReportEditStatus();
					this.setState({
						can_edit: true,
					})
				}
			);
	}

    saveCAPReport = () => {
        console.log("sent cap report save req")
        let report = this.state.CAPContent
        let report_type = this.state.selectedCAP
        let case_id = this.state.currCase.case_id
        let url_ = '/api/save_cap_report/'
        let data = {
            'report': report,
            'case_id': case_id,
            'report_type': report_type,
            'user_id': parseInt(cookie.load('user_id')),
        };
        axios.post(url_, querystring.stringify(data), { headers: { Authorization: AuthHeader() } })
            .then(response => {
                    if(parseInt(response.data["can_edit"])===0){
                        notifyInfo(1, `Couldnt edit while ${response.data["editing_user"]} is editing`)
                        // message.info(`Couldnt edit while ${response.data["editing_user"]} is editing`)
                        this.setState({
                            can_edit:false,
                            editing_user: response.data["editing_user"],
                        })
                    }
                }
            );
    }

    render() {
		if (this.state.selectedCAP === '' && !(this.state.currCase instanceof Array)) {

			if (this.state.currCase.cap_report_type !== '') {
				this.setState({
					selectedCAP: this.state.currCase.cap_report_type,
					CAPContent: this.state.currCase.cap_report
				})
			}
		}
		let cap_report_name_options = this.state.cap_map !== undefined ?
			this.state.cap_map.map(item => (
				<Option key={item.search_key} value={item.search_key}>
					{item.display_name}
				</Option>
			)) : undefined;

		var toolbarOptions = [
			['bold', 'italic', 'underline', 'strike'],        // toggled buttons
			['blockquote', 'code-block'],

			[{'header': 1}, {'header': 2}],               // custom button values
			[{'list': 'ordered'}, {'list': 'bullet'}],
			[{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
			[{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
			[{'direction': 'rtl'}],                         // text direction

			[{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
			[{'header': [1, 2, 3, 4, 5, 6, false]}],

			[{'color': []}, {'background': []}],          // dropdown with defaults from theme
			[{'font': []}],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ];
        let editOverrideButton;
        if(this.state.can_edit){
            editOverrideButton = <span style={{color: 'green'}}> You are editing the report.</span>
        }
        else{
            editOverrideButton = <Tooltip title={`Can't edit as ${this.state.editing_user} is editing. To override click here.`} placement="top-end" >
                    <Button type="primary" color={"warning"} sx={{marginLeft: '4.5vw'}} onClick={this.getOverwriteAccess}>
                        OVERRIDE ACCESS
                    </Button>
                </Tooltip>
        }

        return (
			<Stack direction={"column"} height={"100%"} spacing={3} padding={2}>
				<Stack direction={"row"} justifyContent={"space-between"}>
					<Typography variant={"h4"} color={"white"} align={"center"}> CAP Report </Typography>
					<IconButton type="primary" color={"error"} onClick={this.props.onCAPReportClose}>
						<CloseIcon sx={{color: "red"}}/>
					</IconButton>
				</Stack>
				<Stack direction={"row"} justifyContent={"space-between"}>
					<Select showSearch placeholder={this.state.selectedCAP} value={this.state.selectedCAP}
							style={{width: 300}} onChange={this.handleCAPTemplateChange}
							disabled={!this.state.can_edit}>
						{cap_report_name_options}
					</Select>
					{editOverrideButton}
				</Stack>
				<ReactQuill
					readOnly={!this.state.can_edit}
					modules={{
						toolbar: toolbarOptions
					}}
					theme={"snow"}
					style={{
						height: '100%', backgroundColor: "whitesmoke", color: "black",
					}}
					formats={[
						'header',
						'bold', 'italic', 'underline', 'strike', 'blockquote',
						'list', 'bullet', 'indent',
						'link', 'image',
						'code-block'
					]}
					value={this.state.CAPContent}
					onChange={this.onChangeCAPContent}
				>
				</ReactQuill>
			</Stack>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loginStatus: state.loginAlerts.loginStatus
    }
}

export default connect(mapStateToProps)(ReportApp)
