import React from 'react';
import { Row, Col, Radio, Slider, Button, Checkbox, Input, Divider } from 'antd';

import '../../asset/style/workflow/slot-component.css';

import {
    ArrowLeftOutlined,
    BorderOutlined,
    CheckOutlined,
    RedoOutlined,
    RightSquareOutlined,
    UndoOutlined,
} from '@ant-design/icons';
import { RegionSelectionConstants } from "../../actionTypes/region_selection.constant"
import ReactRegionSelect from 'react-region-select';
import { scanSpeed, specimenType, objectiveType, scanOutputFormat } from "./slots";
import { getOverlayedMaskForPath } from './preview_utils';
import { store } from "../../helper/store";
import { setDropDistancePoint, setFocusPoints, receivedPreviewStatus, onAddFocusPoint } from '../../action/preview_status.action';
import { setDeviceBusy, setDeviceIdle } from '../../action/device_status.action';
import { AuthHeader } from '../../helper/auth.token';
import axios from 'axios';
import { ScanApiConstant } from '../../actionTypes/scan.constant';
import { brushType } from "../../utils/const";

export const getBrushPanel = (brushTypeVal, brushSize, onBrushToolChange, onBrushSizeChange) => {
    return [
        <Row key={0} style={{ padding: 10 }}>
            <Radio.Group defaultValue={brushType.BRUSH} value={brushTypeVal} buttonStyle="solid" onChange={onBrushToolChange}>
                <Radio.Button value={brushType.REDRAW}>Redraw</Radio.Button>
                <Radio.Button value={brushType.BRUSH}>Brush</Radio.Button>
                <Radio.Button value={brushType.ERASER}>Eraser</Radio.Button>
            </Radio.Group>
        </Row>,
        <Row key={1}>
            <Col span={4} offset={10}>
                <Row style={{fontSize:13}}>Brush Size</Row> 
				<Slider defaultValue={brushSize} onChange={onBrushSizeChange} max={50} min={1}/>
            </Col>
        </Row>
    ]
}

export const getVerticalBrushPanel = (brushTypeVal, brushSize, onBrushToolChange, onBrushSizeChange) => {
    return [
        <Row key={0} className="side-panel-section">
            <Radio.Group size="small" defaultValue={brushType.BRUSH} value={brushTypeVal} buttonStyle="solid" onChange={onBrushToolChange}>
                <Radio.Button value={brushType.REDRAW} style={{width: 100, textAlign: 'center'}}>REDO</Radio.Button>
                <Radio.Button value={brushType.BRUSH} style={{width: 80, textAlign: 'center'}}>Brush</Radio.Button>
                <Radio.Button value={brushType.ERASER} style={{width: 80, textAlign: 'center'}}>Eraser</Radio.Button>
            </Radio.Group>
        </Row>,
        <Row key={1} className="side-panel-section">
            <Col>
                <Row className="arial-font" style={{fontSize:13}}>
                    <Col span={8} style={{marginTop: 10}}>
                        <b>Stroke Size</b>
                    </Col>
                    <Col span={16}>
                        <Slider
                            defaultValue={brushSize} 
                            onChange={onBrushSizeChange} 
                            max={50} 
                            min={1} 
                            className="brush-size-slider"
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    ]
}

export const getSpeedPanel = (onScanSpeedChange, preview) => {
    return <Row>
        <Col span={24}>
            <br></br>
            <Row>
                <Row className="arial-font" style={{fontSize:13}}><b>Select Tissue Artifacts Level</b></Row> 
                <Radio.Group defaultValue={scanSpeed.FAST} value={(preview.speed || '').toUpperCase()} buttonStyle="solid" onChange={onScanSpeedChange}
                    size="small"
                >
                <Radio.Button value={scanSpeed.FAST} style={{width: 87, textAlign: 'center'}}>Minor</Radio.Button>
                <Radio.Button value={scanSpeed.MEDIUM} style={{width: 87, textAlign: 'center'}}>Few</Radio.Button>
                <Radio.Button value={scanSpeed.SLOW} style={{width: 87, textAlign: 'center'}}>Major</Radio.Button>
                </Radio.Group>
            </Row>
        </Col>
    </Row>
}

export const getZStackConfigPanel = (onZStackLevelChange, onStepSizeChange, preview) => {
    if ((preview.speed || '').toUpperCase() == scanSpeed.ZSTACK) {
        return [
            <Row key={0} style={{ padding: 10 }}>
                {/* <Row style={{fontSize:13}}>Z Stack Params</Row>  */}
                <Row style={{fontSize:13}}>
                    <Col span={3} offset={7}>
                        Z Stack Levels
                    </Col>
                    <Col span={2}>
                        <Input value={preview.zStackLevels} placeholder={10} onChange={onZStackLevelChange} />
                    </Col>
                    <Col span={3}>
                        Step Size
                    </Col>
                    <Col span={2}>
                        <Input value={preview.stepSize} placeholder={0.5} onChange={onStepSizeChange} />
                    </Col>
                </Row>
            </Row>
        ]
    } else {
        return [];
    }
}

export const getButtonsPanel = (status, onConfirm, onRetakePreview, clearSelection, selectFullRegion, regions, cassetteSize) => {
    let confirmButton = null;
    let showConfirm = cassetteSize > 1 || status !== RegionSelectionConstants.SELECTION_DONE ? true : false;
    if (showConfirm) {
        confirmButton = [<Col span={2} key={0}>
                            <Row key={0} style={{ paddingTop: 10 }}>
                                <Button 
                                    disabled={regions == undefined}
                                    onClick={onConfirm}
                                    shape="circle">
                                    <CheckOutlined />
                                </Button>
                            </Row>
                            <Row key={1} style={{ fontSize: 13 }}>Click to Confirm</Row>
                        </Col>];
    }
    
    let colOffset = showConfirm ? 8 : 9;

    return (
        <Row key={0}>
            <Col span={2} offset={colOffset}>
                <Row key={0} style={{ paddingTop: 10 }}>
                    <Button shape="circle" 
                        onClick={onRetakePreview} 
                        disabled={status === RegionSelectionConstants.GETTING_IMAGE}>
                        <UndoOutlined />
                    </Button>
                </Row>
                <Row key={1} style={{ fontSize: 13 }}>Retake Preview</Row>
            </Col>
            {confirmButton}
            <Col span={2}>
                <Row key={0} style={{ paddingTop: 10 }}>
                    <Button
                        shape="circle"
                        disabled={status !== RegionSelectionConstants.SELECTION_DONE}
                        onClick={clearSelection} >
                        <ArrowLeftOutlined/>
                    </Button>
                </Row>
                <Row key={1} style={{ fontSize: 13 }}>Clear Selection</Row>
            </Col>
            <Col span={2}>
                <Row key={0} style={{ paddingTop: 10 }}>
                    <Button
                        shape="circle"
                        disabled={status === RegionSelectionConstants.SELECTION_DONE}
                        onClick={selectFullRegion}>
                        <BorderOutlined />
                    </Button>
                </Row>
                <Row key={1} style={{ fontSize: 13 }}>Full Scan</Row>
            </Col>
        </Row>
    );
}

export const getConfirmedPanel = (clearSelection) => {
    return [
        <Row key={0} style={{ fontSize: 18 }}>
            Selection Confirmed.
        </Row>,
        <Row key={1}>
            <Col>
                <Row key={0} style={{ paddingTop: 10 }}>
                    <Button
                        type="danger"
                        shape="circle"
                        onClick={clearSelection}>
                            <RedoOutlined />
                        </Button>
                </Row>
                <Row key={1} style={{ fontSize: 13 }}>Click to Redo</Row>
            </Col>
        </Row>
    ];
}

export const getBlankSlidePanel = (clearSelection) => {
    return [
        <Row key={1}>
            <Col>
                <Row key={0} style={{ paddingTop: 10 }}>
                    <Button
                        type="primary"
                        shape="circle"
                        onClick={clearSelection} />
                        <RightSquareOutlined />
                </Row>
                <Row key={1} style={{ fontSize: 13 }}>Move to Selection</Row>
            </Col>
        </Row>
    ];
}

export const getAdminPanel = (preview, toggleTiling, toggleTilingAfterScan) => {
    return <Row>
        <Col span={24}>
            <br></br>
            <Row style={{fontSize:13}}><Checkbox checked={preview.doTilingOnly} onChange={toggleTiling}>Do Tiling Only</Checkbox></Row>
            <Row style={{fontSize:13}}><Checkbox checked={preview.doTilingAfterScan} onChange={toggleTilingAfterScan}>Do Tiling After Scan</Checkbox></Row>
        </Col>
    </Row>
}

const specimenTypeOptions = (specimenTypesToShow) => {
    if(specimenTypesToShow){
        let retObj = []
        if(specimenTypesToShow.includes("HNE_TISSUE"))
            retObj.push(<Radio.Button value={specimenType.HNE_TISSUE} style={{width: 65, textAlign: 'center'}}>HNE</Radio.Button>)
        if(specimenTypesToShow.includes("PAP"))
            retObj.push(<Radio.Button value={specimenType.PAP}style={{width: 65, textAlign: 'center'}}>PAP</Radio.Button>)
        if(specimenTypesToShow.includes("IHC"))
            retObj.push(<Radio.Button value={specimenType.IHC}style={{width: 65, textAlign: 'center'}}>IHC</Radio.Button>)
        if(specimenTypesToShow.includes("BM"))
            retObj.push(<Radio.Button value={specimenType.BM}style={{width: 65, textAlign: 'center'}}>BM</Radio.Button>)
        if(specimenTypesToShow.includes("BLOOD"))
        retObj.push(<Radio.Button value={specimenType.BLOOD}style={{width: 65, textAlign: 'center'}}>BLOOD</Radio.Button>)
        return retObj;
    }
    return null;
}

export const getSpecimenChooser = (onSpecimenTypeChange, preview) => {
    return <Row>
        <Col span={24}>
            <br></br>
            <Row key={0} style={{ padding: 10 }}>
                <Row style={{fontSize:13}}>Set Specimen Type</Row> 
                <Radio.Group defaultValue={specimenType.HNE_TISSUE} value={(preview.specimenType || '').toUpperCase()} buttonStyle="solid" onChange={onSpecimenTypeChange}>
                    <Radio.Button value={specimenType.HNE_TISSUE}>HNE</Radio.Button>
                    <Radio.Button value={specimenType.BLOOD}>Blood</Radio.Button>
                    <Radio.Button value={specimenType.PAP}>Pap</Radio.Button>
                    <Radio.Button value={specimenType.IHC}>IHC</Radio.Button>
                    <Radio.Button value={specimenType.BM}>BM</Radio.Button>
                </Radio.Group>
            </Row>
        </Col>
    </Row>
}

export const getStainChooser = (onSpecimenTypeChange, preview, specimenTypesToShow) => {
    console.log("spectype", (preview.specimenType || '').toUpperCase());
    return <Row>
        <Col span={24}>
            <br></br>
            <Row>
                <Row className="arial-font" style={{fontSize:13}}><b>Set Stain Type</b></Row> 
                <Radio.Group defaultValue={specimenType.HNE_TISSUE} value={(preview.specimenType || '').toUpperCase()} 
                    buttonStyle="solid" 
                    onChange={onSpecimenTypeChange}
                    size="small"
                >
                    {specimenTypeOptions(specimenTypesToShow)}
                </Radio.Group>
            </Row>
        </Col>
    </Row>
}

export const getObjectiveChooser = (onObjectiveTypeChange, preview) => {
    return <Row>
        <Col span={24}>
            <br></br>
            <Row key={0} style={{ padding: 10 }}>
                <Row style={{fontSize:13}}>Set Objective Type</Row> 
                <Radio.Group defaultValue={objectiveType.FORTY_X} value={preview.objectiveType} buttonStyle="solid" onChange={onObjectiveTypeChange}>
                    <Radio.Button value={objectiveType.FORTY_X}>40X</Radio.Button>
                    {/* <Radio.Button value={objectiveType.TWENTY_X}>20X</Radio.Button> */}
                </Radio.Group>
            </Row>
        </Col>
    </Row>
}

export const getScanOutputFormatChanger = (onScanOutputFormatChange, preview) => {
    return <Row>
        <Col span={24}>
            <br></br>
            <Row key={0} style={{ padding: 10 }}>
                <Row style={{fontSize:13}}>Choose Scan Format</Row> 
                <Radio.Group defaultValue={scanOutputFormat.JPG} value={preview.scanOutputFormat} buttonStyle="solid" onChange={onScanOutputFormatChange}>
                    <Radio.Button value={scanOutputFormat.JPG}>JPG</Radio.Button>
                    <Radio.Button value={scanOutputFormat.PNG}>PNG</Radio.Button>
                </Radio.Group>
            </Row>
        </Col>
    </Row>
}

export const getCanvasComponent = (status, id, previewCanvasRef, thresholdCanvasRef, regions, onCanvasChange, allowPainting, 
    startPainting, paintCanvas, stopPainting, dropDistanceCanvas, dropDistanceCanvasRef, focusPointsCanvas, focusPointsCanvasRef, 
    preview, slot, deviceId) => {

    const regionStyle = {
        background: 'rgba(255, 0, 0, 0)',
        zIndex: 1
    };

    let date = new Date();

    if (!Array.isArray(regions)) {
        regions = undefined;
    }

    return <Col style={{ alignItems: "center" }} span={14} offset={2}>
        {status === RegionSelectionConstants.FETCHED_IMAGE ?
            <ReactRegionSelect
                style={{ zIndex: 1 }}
                maxRegions={1}
                regions={regions == undefined ? undefined : regions}
                regionStyle={regionStyle}
                constraint
                onChange={onCanvasChange}>
                <div id="scan-preview" className="preview-canvas-wrapper">
                    <canvas ref={previewCanvasRef} className="region-selection-canvas" />
                    <canvas ref={thresholdCanvasRef} className="region-selection-canvas" />
                </div>
            </ReactRegionSelect> : status ===  RegionSelectionConstants.CONFIRMED ?
            <div>
                <img src={getOverlayedMaskForPath(id) + '?id=' + date.getTime()} 
                    style={{height: '192px', width: '576px'}}/>
            </div> :
            status === RegionSelectionConstants.START_DROP_DISTANCE_SELECTION ?
            <div style={{ zIndex: 1, marginBottom: "8px" }} className="preview-canvas-wrapper">
                <canvas ref={previewCanvasRef} className="region-selection-canvas" />
                <canvas id={"thresholdCanvas" + id}
                    ref={thresholdCanvasRef}
                    className="region-selection-canvas" />
                <canvas id={"dropDistanceCanvas" + id}
                    onMouseDown={(e) => drawCrossHair(e, dropDistanceCanvas, {clearCanvas: true, color: 'red', size: 10, width: 1}, preview, slot)}
                    ref={dropDistanceCanvasRef}
                    className="region-selection-canvas" />
            </div> :
            status === RegionSelectionConstants.START_FOCUS_POINTS_SELECTION ?
            <div style={{ zIndex: 1, marginBottom: "8px" }} className="preview-canvas-wrapper">
                <canvas ref={previewCanvasRef} className="region-selection-canvas" />
                <canvas id={"thresholdCanvas" + id}
                    ref={thresholdCanvasRef}
                    className="region-selection-canvas" />
                <canvas id={"focusPointsCanvas" + id}
                    onClick = {(e) => store.dispatch(onAddFocusPoint(e, deviceId, slot, preview, focusPointsCanvas))}
                    ref={focusPointsCanvasRef}
                    className="region-selection-canvas" />
            </div> :
            <div style={{ zIndex: 1, marginBottom: "8px" }} className="preview-canvas-wrapper">
                <canvas ref={previewCanvasRef} className="region-selection-canvas" />
                <canvas id={"thresholdCanvas" + id}
                    onMouseDown={allowPainting ? startPainting : undefined}
                    onMouseMove={allowPainting ? paintCanvas : undefined}
                    onMouseUp={allowPainting ? stopPainting : undefined}
                    ref={thresholdCanvasRef}
                    className="region-selection-canvas" />
            </div>
        }
    </Col>
}

export const getCanvasComponentNew = (status, previewCanvasRef, thresholdCanvasRef, brushEraserPrevCanvasRef, regions, onCanvasChange, allowPainting, 
    startPainting, paintCanvas, stopPainting, dropDistanceCanvas, dropDistanceCanvasRef, focusPointsCanvas, focusPointsCanvasRef, 
    preview, slot, deviceId, brushTypeVal, showBrushOrEraserSize) => {

    const regionStyle = {
        background: 'rgba(255, 0, 0, 0)',
        zIndex: 1
    };

    let date = new Date();

    if (!Array.isArray(regions)) {
        regions = undefined;
    }

    return <Col>
        {brushTypeVal === brushType.REDRAW ?
            <ReactRegionSelect
                style={{ zIndex: 1, marginLeft: "20px" }}
                className="react-region-select-custom--style"
                maxRegions={1}
                regions={regions == undefined ? undefined : regions}
                regionStyle={regionStyle}
                constraint
                onChange={onCanvasChange}>
                <div id="scan-preview" className="preview-canvas-wrapper-new">
                    <canvas ref={previewCanvasRef} className="region-selection-canvas-new" />
                    <canvas id={"thresholdCanvas" + slot} ref={thresholdCanvasRef} className="region-selection-canvas-new" />
                </div>
            </ReactRegionSelect> : status ===  RegionSelectionConstants.CONFIRMED ?
            <div>
                <img src={getOverlayedMaskForPath(slot) + '?id=' + date.getTime()} 
                    style={{height: '192px', width: '576px'}}/>
            </div> :
            status === RegionSelectionConstants.START_DROP_DISTANCE_SELECTION ?
            <div style={{ zIndex: 1 }} className="preview-canvas-wrapper-new">
                <canvas ref={previewCanvasRef} className="region-selection-canvas-new" />
                <canvas id={"thresholdCanvas" + slot}
                    ref={thresholdCanvasRef}
                    className="region-selection-canvas-new" />
                <canvas id={"dropDistanceCanvas" + slot}
                    onMouseDown={(e) => drawCrossHair(e, dropDistanceCanvas, {clearCanvas: true, color: 'red', size: 10, width: 1}, preview, slot)}
                    ref={dropDistanceCanvasRef}
                    className="region-selection-canvas-new" />
            </div> :
            status === RegionSelectionConstants.START_FOCUS_POINTS_SELECTION ?
            <div style={{ zIndex: 1 }} className="preview-canvas-wrapper-new">
                <canvas ref={previewCanvasRef} className="region-selection-canvas-new" />
                <canvas id={"thresholdCanvas" + slot}
                    ref={thresholdCanvasRef}
                    className="region-selection-canvas-new" />
                <canvas id={"focusPointsCanvas" + slot}
                    onClick = {(e) => store.dispatch(onAddFocusPoint(e, deviceId, slot, preview, focusPointsCanvas))}
                    ref={focusPointsCanvasRef}
                    className="region-selection-canvas-new" />
            </div> :
            <div style={{ zIndex: 1 }} className="preview-canvas-wrapper-new">
                <canvas ref={previewCanvasRef} className="region-selection-canvas-new" />
                <canvas id={"thresholdCanvas" + slot}
                    ref={thresholdCanvasRef}
                    className="region-selection-canvas-new" />
                <canvas id={"brushEraserPrevCanvas" + slot} 
                    onMouseDown={allowPainting ? startPainting : undefined}
                    onMouseMove={allowPainting ? paintCanvas : showBrushOrEraserSize}
                    onMouseUp={allowPainting ? stopPainting : undefined}
                    ref={brushEraserPrevCanvasRef}
                    className="brush-eraser-prev-canvas-new" />
            </div>
        }
    </Col>
}

const createThresholdedCanvas = (thresholdCanvas, thresholdedPath, regions) => {
    const thresholdContext = thresholdCanvas.getContext('2d');
    const thresholdImage = new Image();
    thresholdImage.src = "/dev-ssd/" + thresholdedPath;
    thresholdImage.onload = () => {
        thresholdContext.filter = "brightness(100%)"
        thresholdContext.clearRect(0, 0, thresholdCanvas.width, thresholdCanvas.height);
        thresholdContext.drawImage(thresholdImage, 0, 0, thresholdCanvas.width, thresholdCanvas.height);
        cleanUpExtra(thresholdCanvas, thresholdContext, regions);
    }
    thresholdContext.globalAlpha = 0.5;
}

export const cleanUpExtra = (thresholdCanvas, thresholdContext, reg) => {
    
    // Get Region Coordinates in Absolute Coordinates from Percentages
    let x1 = parseInt((reg.x * thresholdCanvas.width) / 100.0);
    let x2 = parseInt(((reg.x + reg.width) * thresholdCanvas.width) / 100.0);
    let y1 = parseInt((reg.y * thresholdCanvas.height) / 100.0);
    let y2 = parseInt(((reg.y + reg.height) * thresholdCanvas.height) / 100.0);
    
    // Clear surrounding boxes

    thresholdContext.clearRect(0, 0, x1, thresholdCanvas.height);
    thresholdContext.clearRect(x1, 0, thresholdCanvas.width - x1, y1);
    thresholdContext.clearRect(x2, y1, thresholdCanvas.width - x2, thresholdCanvas.height - y1);
    thresholdContext.clearRect(x1, y2, x2 - x1, thresholdCanvas.height - y2);
    
    // Fill surrounding with black
    thresholdContext.fillStyle = "#000000";
    thresholdContext.fillRect(0, 0, x1, thresholdCanvas.height);
    thresholdContext.fillRect(x1, 0, thresholdCanvas.width - x1, y1);
    thresholdContext.fillRect(x2, y1, thresholdCanvas.width - x2, thresholdCanvas.height - y1);
    thresholdContext.fillRect(x1, y2, x2 - x1, thresholdCanvas.height - y2);
    
    // Create boundary
    thresholdContext.beginPath();
    let strokeSize = 2;
    thresholdContext.lineWidth = 2 * strokeSize;
    thresholdContext.strokeStyle = "green";
    thresholdContext.rect(Math.max(0, x1 - strokeSize), Math.max(0, y1 - strokeSize), x2-x1 + 2 * strokeSize, y2-y1 + 2 * strokeSize);
    thresholdContext.stroke();
}

export const updateImages = (previewCanvas, previewPath, thresholdCanvas, thresholdedPath, regions) => {

    const previewContext = previewCanvas.getContext('2d');
    previewContext.clearRect(0, 0, previewCanvas.width, previewCanvas.height);
    const previewImage = new Image();
    previewImage.src = "/dev-ssd/" + previewPath;
    
    previewImage.onload = () => {
        let newImageDivHeight = Math.floor((previewImage.height / previewImage.width) * 30);
        let imageDiv = document.getElementById("scan-preview");
        // if (imageDiv != null) {
        //     imageDiv.style.height = newImageDivHeight + "vw";
        //     previewCanvas.style.height = newImageDivHeight + "vw";
        //     thresholdCanvas.style.height = newImageDivHeight + "vw";
        // }
        //previewContext.drawImage(previewImage, 0, 0, previewCanvas.width, previewCanvas.height);
        previewCanvas.style.backgroundImage = `url(${"/dev-ssd/"+previewPath})`
        previewCanvas.style.filter = "brightness(100%)";

    }

    if (regions != undefined && regions.length > 0) {
        let reg = regions[0];
        if ((reg || {}).x != undefined) {
            createThresholdedCanvas(thresholdCanvas, thresholdedPath, reg);
        }
    }
}

export const drawCrossHair = (event, canvas, opts, preview, slot) => {

    let rect = canvas.getBoundingClientRect();
    let canvasx = rect.x;
    let canvasy = rect.y;
    let mousex = parseInt(((event.pageX - canvasx) / rect.width) * canvas.width);
    let mousey = parseInt(((event.pageY - canvasy) / rect.height) * canvas.height);
    let x = mousex;
    let y = mousey;

    if (x===0 && y===0){
        return;
    }

    x = x-window.scrollX;
    y = y-window.scrollY;

    let canvasContext = canvas.getContext("2d");
    
    if (opts.clearCanvas === true) {
        canvasContext.clearRect(0, 0, canvas.width, canvas.height);
    }
    
    if (opts.color === undefined) {
        canvasContext.fillStyle = "#ff0000";
    } else {
        canvasContext.fillStyle = opts.color;
    }

    for (let i=0; i<=10; i++){
        canvasContext.fillRect(x-5+i, y, 2, 2);
        canvasContext.fillRect(x, y-5+i, 2, 2);
    }

    let realX = Math.trunc(x * (preview.response.previewWidth / canvas.width));
    let realY = Math.trunc(y * (preview.response.previewHeight / canvas.height));

    let dropDistancePoints = [...preview.dropDistancePoint];
    if (dropDistancePoints.length == 3) {
        dropDistancePoints.unshift({x: realX, y: realY});
    } else if (dropDistancePoints.length == 4) {
        dropDistancePoints[0] = {x: realX, y: realY};
    }
    store.dispatch(setDropDistancePoint(slot, dropDistancePoints));
}

export const drawCrossHairInitial = (realX, realY, canvas, opts, preview) => {
    let x = realX / (preview.response.previewWidth / (canvas).width);
    let y = realY / (preview.response.previewHeight / (canvas).height);

    if (x===0 && y===0){
        return;
    }

    x = x-window.scrollX;
    y = y-window.scrollY;

    let canvasContext = canvas.getContext("2d");
    
    if (opts.clearCanvas === true) {
        canvasContext.clearRect(0, 0, canvas.width, canvas.height);
    }
    
    if (opts.color === undefined) {
        canvasContext.fillStyle = "#ff0000";
    } else {
        canvasContext.fillStyle = opts.color;
    }
    
    for (let i=0; i<=opts.size; i++){
        canvasContext.fillRect(x-(opts.size / 2)+i, y, opts.width, opts.width);
        canvasContext.fillRect(x, y-(opts.size / 2)+i, opts.width, opts.width);
    }
}