import {fieldStyleFunction, markAnnotation, onSelectAnnotationOnMap} from "./annotations_app_utils";
import {
	circleDrawingKey,
	freehandDrawingKey,
	freelineDrawingKey,
	lineDrawingKey,
	magicToolDrawingKey,
	polygonDrawingKey,
	rectangleDrawingKey
} from "../drawer/draw_tool_keys";
import {onIHCResultSelect} from "./ihc_app_utils";
import {retrieveBloodAnnotations,} from "../../../action/maps.state.action";
import {annotationsApp, deepBioApp, ihcApp, labellingApp} from "../app_maker";


export const annotationLayer = {
	id: "annotations",
	name: "Annotations Layer",
	drawer: 0,
	defaultPermission: true,
	selectInteraction: true,
	tools: [lineDrawingKey, freelineDrawingKey, circleDrawingKey, freehandDrawingKey, rectangleDrawingKey,
		polygonDrawingKey, magicToolDrawingKey],
    onFeatureSelect: function (mapId, feature) {
		onSelectAnnotationOnMap(mapId, annotationsApp, feature.getId())
    },
}

export const deepBioLayer = {
    id: "deep_bio",
    name: "DeepBio Layer",
    drawer: 1,
    defaultPermission: true,
    loadDataOnRender: true,
    selectInteraction: false,
    tools: [circleDrawingKey, rectangleDrawingKey, polygonDrawingKey],
    onFeatureSelect: function (mapId, feature) {
		onSelectAnnotationOnMap(mapId, deepBioApp, feature.getId())
    },
}

export const deepBioResultLayer = {
    id: "deep_bio",
    name: "DeepBio Analysis",
    defaultPermission: true,
    selectInteraction: false,
};

export const ihcLayer = {
    id: "ihc",
    name: "IHC Layer",
    drawer: 2,
    defaultPermission: true,
    selectInteraction: false,
    tools: [circleDrawingKey, rectangleDrawingKey, polygonDrawingKey],
    onFeatureSelect: function (mapId, feature) {
		onSelectAnnotationOnMap(mapId, ihcApp, feature.getId())
    },
}

export const ihcResultLayer = {
    id: "ihc",
    name: "IHC Analysis",
    defaultPermission: true,
    onFeatureSelect: onIHCResultSelect,
    onFeatureHover: () => undefined,
}

export const labellingLayer = {
    id: "labelling",
    name: "Labelling Layer",
    drawer: 3,
    defaultPermission: false,
    selectInteraction: false,
    tools: [freehandDrawingKey],
    onFeatureSelect: function (mapId, feature) {
		onSelectAnnotationOnMap(mapId, labellingApp, feature.getId())
    },
}

export const fieldLayer = {
    id: "field",
    name: "Field Layer",
    drawer: 4,
    defaultPermission: true,
    styleFunction: fieldStyleFunction,
    onFeatureSelect: function (mapId, feature) {
        markAnnotation(mapId, feature.getProperties().annotation)
    },
};

export const getBloodAnnotationLayer = () => {
    return {
        id: "blood_annotations",
        name: "Blood Annotations",
        map_name: "blood_annotation_layer",
        anno_drawer: 5,
        type: 'blood',
        loadDataOnRender: true,
        dataLoad: (mapId, slideId) => retrieveBloodAnnotations(mapId, slideId),
        onSelectAnnotationOnMap: (annotationId) => onSelectAnnotationOnMap("blood_annotations", annotationId),
        selectInteraction: true,
    }
}

export const getAllSlideLayers = () => ([
    annotationLayer,
    deepBioLayer,
    deepBioResultLayer,
    ihcLayer,
    ihcResultLayer,
    fieldLayer,
    labellingLayer,
    getBloodAnnotationLayer(),
]);
