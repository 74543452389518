import {SearchAlertCaseConstant} from "../actionTypes/search.case.constant";
import {urlEncoder} from "../action/cases.action";

export const getNewState = () => {
    return {
        isFetching : true,
        search : '',
        case_assigned : true,
        unread : false,
        read : false,
        validation_id : '',
        page: 1,
        numPages: 1,
        count: 1,
        ordering : '-id',
        type: 'assigned',
}}

const InitialState = getNewState();

export const searchCaseReducer = ( state = InitialState , action) => {
    var returnableState = {}
    switch(action.type){
        case SearchAlertCaseConstant.CASE_SEARCH_INITIATED:
            returnableState = {
                ...state,
                isFetching : true,
            }
            return returnableState;
        case SearchAlertCaseConstant.CASE_SEARCH_SUCCESS:
            returnableState = {
                ...state,
                isFetching : false,
            }
            return returnableState;
        case SearchAlertCaseConstant.CASE_SEARCH_FAILED:
            returnableState = {
                ...state, 
                err: action.error, 
                isFetching : false,
            }
            return returnableState;
        case SearchAlertCaseConstant.CASE_UPDATE_URL_STATE:
            returnableState = Object.assign({}, action.urlState);
            updateUrl(returnableState);
            return returnableState;
        case SearchAlertCaseConstant.CASE_UPDATE_SEARCHES:
            returnableState = {
                ...state, 
            }
            returnableState.searches = action.searches;
            updateUrl(returnableState);
            return returnableState;
        case SearchAlertCaseConstant.CASE_CHANGE_PAGE:
            returnableState = {
                ...state
            }
            returnableState.page = action.page
        default :
            returnableState = state
            return returnableState;
    }
}

export const updateUrl = (state) => {
    var encodedUrl = urlEncoder(state);
    window.history.replaceState(state, 'filter_params', encodedUrl);
}