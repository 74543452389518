import React, {Component} from 'react';
import {connect} from "react-redux";
import {debounce} from 'lodash';
import {Button, Col, message, Row} from 'antd';
import {
	loadMorpheusSettings,
	updateAppClosedStatus,
	updateDigitalZoomStatus,
	updateMapParams,
	updateMorphleID,
	updateOnStitchedLayer,
	updateUrlStateInReducer,
	updateZStackLevel
} from "../../action/morpheus.state.action"
import {getSlideAndInitialiseMapState, recreateMapLayers} from './map_utils'
import AppBoard from './appboard'
import {AnnotationsConstants, globalUrlPrefix, mapLayerTypes, MorphleLogo, slideViewerType} from "../../utils/const";
import "../../asset/style/neoviewer/slidemap.css"
import cookie from 'react-cookies';
import {getErrorComponent} from '../dashboard/slidelist_utils';
import {AuthHeader} from "../../helper/auth.token";
import axios from "axios";
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import Circle from 'ol/geom/Circle';
import VectorSource from 'ol/source/Vector';
import {Fill, Stroke, Style} from 'ol/style.js';
import * as keys from '../neoviewer/apps/app_keys'
import {checkIfMobile, convertSettingsToFilter, degreeToRadian} from '../../utils/utils';
import {KeyboardPan} from 'ol/interaction';
import {Vector as VectorLayer} from 'ol/layer.js';
import {bloodAnnoSelected, bloodAnnoStopMove, reset, updatePage} from "../../action/bloodGrid.action";
import {fieldGridSelected, updateAllGrids, updatePageFieldView} from '../../action/fieldView.action';
import {updateAllGridsWBC, updatePageWBCView, wbcGridSelected} from '../../action/wbcview.action';
import {click} from 'ol/events/condition.js';
import Select from 'ol/interaction/Select.js';
import {updateMapState} from '../../action/maps.state.action';

import querystring from "query-string";
import {isZoomForTiled} from "../gammaviewer/utils/map_utils";


const style = new Style({
  stroke: new Stroke({
    color: '#7CFC00',
    width: 2,
  }),
  fill: new Fill({
    color: 'rgba(0, 0, 0, 0.0)'
  }),
})


const styleFunction = (feature) => {
  if(feature.getProperties().color != undefined && feature.getProperties().color!=""){
    style.getStroke().setColor(feature.getProperties().color);
    style.getStroke().setWidth(feature.getProperties().width);
  }
  return [style,]
}

class BloodSlideMap extends Component {
  
  constructor(props) {
    super(props);

    this.screenSizeString = window.screen.width + 'x' + window.screen.height;

    this.state = {
      slide: -1,
      isFetching: true,
      isErrored: false,
      loadedMap: false, 
      mapId: this.props.map_id,
      stackMode: 0,
      modalVisible: false,
      backgroundColor: "#FFFFFF",
      shiftScrollCount: 0,
      mapRenderComplete: false,
      view_mode:1,
      all_on_map: [],
      all_on_map_field:[],
    };

    this.vector = new VectorLayer({
      source: new VectorSource({}),
      name: mapLayerTypes.BLOOD_ANNOTATION_LAYER_NAME,
    });

    this.vector.set('annotationLayer', true);

    this.selectHover = new Select({
      condition: click, 
      layers: [this.vector], 
      wrapX: false,
      hitTolerance: 5,
      style: new Style({
          // stroke: new Stroke({
          //   color: '#ae2699',
          //   // color: 'rgba(0, 0, 0, 0.0)',
          //   width: 1,
          // }),
          fill: new Fill({
              color: 'rgba(0, 0, 0, 0.0)'
          })
      })
    });

    this.lastCachingCall = -1;
    this.upperZLevelsCached = 0;
    this.lowerZLevelsCached = 0;

    this.previewMapRef = null;
    this.props.dispatch(loadMorpheusSettings());
    this.props.dispatch(updateUrlStateInReducer(this.props.urlState));
    this.imageDownloadTimer = null;

    this.cacheZStackLayers = debounce(this.cacheZStackLayers.bind(this), 1000);
    this.initMapState = true;

    if (cookie.loadAll().isMobile === 'true') {
      window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    }
  }

  addSelectInteraction = () => {
    this.state.slidemap.addInteraction(this.selectHover);
    this.selectHover.on("select", e => {
        console.log("e_selected",e.selected)
        // this.drawAnnotations(this.state.all_on_map)
        if(this.props.field_view == true){
          if(e.selected.length==0){
            return;
          }
          let feature = e.selected[0];
          let anno_id = feature.get('id')
          let slide_id = this.props.slide_id
          if(this.props.mode=="FIELD"){
            let page_size = this.props.fieldGridData.page_size
            let url = `/api/get_selected_field_anno_page/?anno_id=${anno_id}&slide_id=${slide_id}&page_size=${page_size}`
            axios.get(url).then(res => {
              console.log("page", res.data['page'])
              let annos = this.state.all_on_map_field
              for(let i=0;i<annos.length;i++){
                if(annos[i].id == feature.get('id')){
                  this.props.dispatch(updatePageFieldView(res.data["page"]))
                  this.props.dispatch(fieldGridSelected(annos[i]))
                  // this.props.dispatch(resetZStack());
                  // this.props.dispatch(updateZStackWrapper(annos[i], this.props.fieldGridData.zStack))
                  this.drawAnnotationsFieldView(this.state.all_on_map_field);
                }
              }
            })
          }
          else if(this.props.mode == "WBC"){
            let page_size = this.props.fieldGridData.page_size
            let url = `/api/get_selected_field_anno_page_wbc/?anno_id=${anno_id}&slide_id=${slide_id}&page_size=${page_size}&tag=${this.props.wbcGridData.tag}`
            axios.get(url).then(res => {
              console.log("page", res.data['page'])
              let annos = this.state.all_on_map_field
              for(let i=0;i<annos.length;i++){
                if(annos[i].id == feature.get('id')){
                  this.props.dispatch(updatePageWBCView(res.data["page"]))
                  this.props.dispatch(wbcGridSelected(annos[i]))
                  // this.props.dispatch(updateZStackWrapperWBC(annos[i], this.props.wbcGridData.zStack))
                  this.drawAnnotationsFieldView(this.state.all_on_map_field);
                  // this.props.dispatch(resetZStack());
                  break;
                }
              }
            })
          }
          
        }
        else{
          if(e.selected.length==0 || this.props.bloodGridData.class != "WBC"){
            return;
          }
          let feature = e.selected[0];
          let anno_id = feature.get('id')
          let slide_id = this.props.slide_id
          let tag = this.props.bloodGridData.tag
          let page_size = this.props.bloodGridData.page_size
          let url = `/api/get_selected_anno_page/?anno_id=${anno_id}&slide_id=${slide_id}&tag=${tag}&page_size=${page_size}`
          axios.get(url).then(res => {
            console.log("page", res.data['page'])
            let annos = this.state.all_on_map
            for(let i=0;i<annos.length;i++){
              if(annos[i].oot_id == feature.get('id')){
                this.props.dispatch(reset());
                this.props.dispatch(bloodAnnoSelected(annos[i]));
                this.props.dispatch(updatePage(res.data["page"]))
                break;
              }
            }
          })
        }   
    });
}

  drawRBCAnno = (annotation) => {
    console.log("draw--", annotation)
    let vector = this.vector
    let allFeatures = [];
    if (annotation.new_bounds !== null) {
        let bounds = JSON.parse(annotation.new_bounds);
        console.log("draw-1..", bounds)
        let x = bounds[0]
        console.log("draw-1.", x)
         bounds.push(x)
        console.log("draw-1.5", bounds)
        let feature;
        // if(annotation.shape==AnnotationsConstants.POLYGON){
        //     feature = new Feature({
        //       geometry: new Polygon(JSON.parse(annotation.center), 10),
        //       id: annotation.id,
        //       name: annotation.shape,
        //       title: annotation.title,
        //   });
        // }
        // else if(annotation.shape==AnnotationsConstants.CIRCLE){
          // let center = bounds[0];
          // let radius = bounds[1];
          feature = new Feature({
              geometry: new Circle(JSON.parse(annotation.center), 15),
              id: annotation.id,
              name: annotation.shape,
              title: "",
          });
        // }
        
      //   }
        // feature.setId(annotation.oot_id);
        // feature.set('creator', annotation.anno_drawer);
        if(annotation.id == ((this.props.fieldGridData||{}).selected_grid||{}).id){
          feature.set('width',4);
          feature.set('color', '#ae2699');
        } else{
          feature.set('width',2);
          feature.set('color', annotation.color);
        }
      feature.setStyle(styleFunction);
      allFeatures.push(feature);
    }
    vector.setSource(new VectorSource({
      features: allFeatures,
      wrapX: false
    }))
    console.log("draw---", vector.getSource().getFeatures())
    vector.setStyle(styleFunction);
    this.state.slidemap && this.state.slidemap.getView().animate(
        {rotation: degreeToRadian((this.state.slide_data || {}).viewer_rotation)}, {center: JSON.parse(annotation.center)});
  }

  drawAnnotationsFieldView = (annotations) => {
    console.log("annos-1", annotations);
      let vector = this.vector
      let new_annotations = annotations
      // for(let i=0;i<new_annotations.length;i++){
      //   if(new_annotations[i].id == this.props.fieldGridData.selected_grid.id){
      //     new_annotations.splice(i,1)
      //   }
      // }
      // new_annotations.push(this.props.fieldGridData.selected_grid.id)
      this.setState({
        all_on_map_field: new_annotations,
      })
      let showAnno = true;
      let allFeatures = [];
      // let annotations=[annotation,]
      console.log("annotation", annotations)

      annotations = annotations.map((annotation, index) => {
        let feature;
        if (annotation.new_bounds !== null) {
          let bounds = JSON.parse(annotation.new_bounds);
          // let feature;
          if(annotation.shape==AnnotationsConstants.POLYGON){
              feature = new Feature({
                geometry: new Polygon(bounds),
                id: annotation.id,
                name: annotation.shape,
                title: annotation.title,
            });
          }
          else if(annotation.shape==AnnotationsConstants.CIRCLE){
            let center = bounds[0];
            let radius = bounds[1];
            feature = new Feature({
                geometry: new Circle(center, radius),
                id: annotation.id,
                name: annotation.shape,
                title: "",
            });
          }
          if(annotation.id == ((this.props.fieldGridData||{}).selected_grid||{}).id){
            feature.set('width',4);
            feature.set('color', '#ae2699');
          } else {
            feature.set('width',2);
            feature.set('color', annotation.color);
          }
          allFeatures.push(feature);
        }
        return feature;
      });
      console.log("allFeatures", allFeatures)
      vector.setSource(new VectorSource({
          features:allFeatures,
          wrapX: false
      }))
      console.log("annos", vector.getSource().getFeatures());
      vector.setStyle(styleFunction);
  }

  drawAnnotations = (annotations) => {
    let vector = this.vector
    let new_annotations = annotations
    for(let i=0;i<new_annotations.length;i++){
      if(new_annotations[i].id == this.props.bloodGridData.selected_anno.id){
        new_annotations.splice(i,1)
      }
    }
    new_annotations.push(this.props.bloodGridData.selected_anno)
    this.setState({
      all_on_map: new_annotations,
    })
    let showAnno = true;
    let allFeatures = [];
    // let annotations=[annotation,]
    annotations = annotations.map((annotation, index) => {
      let feature;
      if (annotation.new_bounds !== null) {
        console.log("annotation", annotation)
          if (annotation.shape === AnnotationsConstants.POLYGON) {
              feature = new Feature({
                  geometry: new Polygon(JSON.parse(annotation.new_bounds)),
                  id: annotation.oot_id,
                  name: annotation.shape,
                  title: annotation.title,
              });
          }
          feature.setId(annotation.oot_id);
          feature.set('creator', annotation.anno_drawer);
          allFeatures.push(feature);
      }
          return feature;
    });
    console.log("allFeatures", allFeatures)
    // vector.setSource(new VectorSource({
    //     features:allFeatures,
    //     wrapX: false
    // }))

    vector.setStyle(styleFunction);
}

  addPreviewMapControl = (previewMapRef) => this.previewMapRef = previewMapRef;
          
  updateUrlState = () => {
    if(this.state.view.getZoom()<0){
      this.state.view.setMaxZoom(this.state.ZValues[0]);
      this.state.view.setZoom(this.state.ZValues[0]);
    }
    let tiledLayer = isZoomForTiled(this.state.view.getZoom(), this.state.viewLevelsInfo);
    if (!tiledLayer) {
      this.props.dispatch(updateZStackLevel(0));
    }
    this.props.dispatch(updateOnStitchedLayer(!tiledLayer));
    let zoomRatio = (((this.props.urlState || {}).app_state || {})[keys.displaySizeCalibrationKey.id] || {})[this.screenSizeString] || 1;
    if(zoomRatio > 1 && Number.isInteger(this.state.view.getZoom()) && this.state.view != undefined && (this.props.urlState || {}).presentCode == undefined) {
      if(this.state.view.getZoom() <= this.state.view.getMaxZoom() - 1) {
        this.state.view.setZoom(this.state.ZValues[this.state.view.getZoom()]);
      } else if(this.state.view.getZoom() <= this.state.view.getMaxZoom()) {
        this.state.view.setZoom(this.state.ZValues[this.state.view.getZoom() - 1]);
      }
    }


    this.props.dispatch(updateMapState(this.props.map_id, {
      slideState: this.state,
    }));

    this.props.dispatch(updateMapParams(
      this.props.urlState,
      this.state.view.getCenter()[0],
      this.state.view.getCenter()[1],
      this.state.view.getZoom(),
      this.state.view.getRotation(),
    ));
  };

  updateLayers = () => {
    this.setState({
      mapRenderComplete: false,
    });

    let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels) / 2 : (this.props.urlState.numZLevels);
    let minZLevel = this.props.urlState.takeBidirectionalZStack ?  (-1 * ((this.props.urlState.numZLevels) / 2)) : 0;
    
    minZLevel = Math.floor(minZLevel);
    maxZLevel = Math.floor(maxZLevel);

    recreateMapLayers(this.state.slidemap, this.state.viewLevelsInfo, this.state.slide_data, this.state.imageInfo, this.state.projection, 
      this.state.resolutions, this.state.txtyInfo, this.state.tileSize, this.updateUrlState, (obj) => {
        obj.addPreviewMapControl = this.addPreviewMapControl;
        obj.goHome = this.goHome;
        this.setState(obj)}, this.props.urlState.zStackLevel, minZLevel, maxZLevel, this.props.urlState.takeZStack);
  }

  onMapRenderingComplete = () => {
    this.setState({
      mapRenderComplete: true,
    });
  }

  cacheZStackLayers = () => {
    console.log("Caching started")
    this.lastCachingCall = Date.now();
    let lastCachingCall = this.lastCachingCall;
    this.upperZLevelsCached = 0;
    this.lowerZLevelsCached = 0;
    let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels - 1) / 2 : (this.props.urlState.numZLevels - 1);
    let timerInterval  = 1000;
    let timer = 0;
    for(let i = 1; i <= maxZLevel + 1; i++) {
      let layers = this.state.slidemap.getLayers().getArray();
      setTimeout(() => this.cacheLayer(layers, i, lastCachingCall), timer);
      timer += timerInterval;
    }
  }

  cacheLayer = (layers, zLevel, lastCachingCall) => {
    if (lastCachingCall == this.lastCachingCall) {
      for(let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        if (layer.values_.posS == (-1 * zLevel) || layer.values_.posS == zLevel) {
          layer.setVisible(true);
        }
      }
      setTimeout(() => this.layerVisibilityOff(layers), 10);
      this.upperZLevelsCached = this.upperZLevelsCached + 1;
      this.lowerZLevelsCached = this.lowerZLevelsCached - 1;
    }
  }

  layerVisibilityOff = (layers) => {
    for(let i = 0; i < layers.length; i++) {
      let layer = layers[i];
      if(layer.values_.posS == this.props.urlState.zStackLevel || layer.values_.name == mapLayerTypes.ANNOTATION_LAYER_NAME || layer.values_.name == mapLayerTypes.STITCHED_LAYER_NAME || layer.values_.name == mapLayerTypes.BLOOD_ANNOTATION_LAYER_NAME) {
        layer.setVisible(true);
      } else {
        layer.setVisible(false);
      }
    }
  }

  cacheZoomLevels = (upper, layers) => {
    let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels - 1) / 2 : (this.props.urlState.numZLevels - 1);
    if (upper && this.upperZLevelsCached < maxZLevel) {
      for(let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        if (layer.values_.posS == (this.upperZLevelsCached + 1) || layer.values_.posS == (this.upperZLevelsCached + 2)) {
          layer.setVisible(true);
        }
      }
      setTimeout(() => this.layerVisibilityOff(layers), 10);
      this.upperZLevelsCached = this.upperZLevelsCached + 2;
    } else if(!upper && this.lowerZLevelsCached > (-1 * maxZLevel)) {
      for(let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        if (layer.values_.posS == (this.lowerZLevelsCached - 1) || layer.values_.posS == (this.lowerZLevelsCached - 2)) {
          layer.setVisible(true);
        }
      }
      setTimeout(() => this.layerVisibilityOff(layers), 10);
      this.upperZLevelsCached = this.lowerZLevelsCached - 2;
    }
  }

  scrollActionOnMap = (e) => {
    if (e.type !== "wheel") {
      return true;
    } else {
      let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels - 1) / 2 : (this.props.urlState.numZLevels - 1);
      let minZLevel = this.props.urlState.takeBidirectionalZStack ?  (-1 * ((this.props.urlState.numZLevels - 1) / 2)) : 0;

      if (this.props.urlState.takeZStack && e.originalEvent.shiftKey) {
        if(this.props.urlState.onStitchedLayer) {
          message.error("Z Stack only allowed on higher zoom levels.", 2.5);
        } else {
          if (e.originalEvent.deltaY > 0 && this.props.urlState.zStackLevel > minZLevel) {
            this.props.dispatch(updateZStackLevel(this.props.urlState.zStackLevel - 1));
            this.setState({
              shiftScrollCount: this.state.shiftScrollCount + 1,
            });
            this.cacheZoomLevels(true, this.state.slidemap.getLayers().getArray());
          } else if (e.originalEvent.deltaY < 0 && this.props.urlState.zStackLevel < maxZLevel) {
            this.props.dispatch(updateZStackLevel(this.props.urlState.zStackLevel + 1));
            this.setState({
              shiftScrollCount: this.state.shiftScrollCount + 1,
            });
            this.cacheZoomLevels(false, this.state.slidemap.getLayers().getArray());
          }
        }
        return false;
      } else {
        return true;
      }
    }
  }

  getExtentAllAnnotations = (final_extent) => {
    let slide_id = this.props.slide_id;
    let extent = final_extent
    let val = {
      id : slide_id,
      extent : extent,
      tag : this.props.bloodGridData.tag
    }
    let url =  `/api/get_extent_all_annotations/`
    axios.post(url, querystring.stringify(val), { headers: { Authorization: AuthHeader() } })
      .then(res=>{
        this.drawAnnotations(res.data['data'])
        console.log("new_anno", res.data['data'])
      })
  }

  componentDidMount = () => {
      // getSlideAndInitialiseMapState(this.props.slide_id, (obj) => {
      //   obj.goHome = this.goHome;
      //   obj.addPreviewMapControl = this.addPreviewMapControl;
      //   this.setState(obj)
      // }, this.props.urlState, this.updateUrlState);
      if(this.props.field_view==true){
        this.getAllFieldViewAnnotations();
      }
  }

  getAllFieldViewAnnotations = () => {
    console.log("ww-all123456")
    let slide_id = this.props.slide_id;
    if(this.props.mode == "FIELD"){
      let url =  `/api/get_all_field_view_annotations/?id=${slide_id}`
      axios.get(url)
        .then(res=>{
          this.drawAnnotationsFieldView(res.data['data'])
          this.props.dispatch(updateAllGrids(res.data['data']))
          console.log("new_anno", res.data['data'])
        })
    }else if(this.props.mode=="WBC"){
      //here make a request to wbc  
      let url;
      if(this.props.confirmed){
        url =  `/api/get_all_field_view_annotations_wbc/`
      }
      else{
        url = `/api/get_all_wbc_confused/`
      }
      let val = {
        slide_id: this.props.slide_id,
        tag: this.props.wbcGridData.tag,
      }
      axios.post(url, querystring.stringify(val), { headers: { Authorization: AuthHeader() }})
        .then(res=>{
          this.drawAnnotationsFieldView(res.data['data'])
          this.props.dispatch(updateAllGridsWBC(res.data['data']))
          console.log("ww-all123", res.data['data'])
        }).catch(err => {
          console.log("new_anno_error")
        })
    }
   
  }
  componentDidUpdate = (prevProps, prevState) => {

    if(prevProps.pltGridData.selected_grid != this.props.pltGridData.selected_grid && (this.props.pltGridData||{}).selected_grid!=undefined){
      this.drawRBCAnno(this.props.pltGridData.selected_grid)
    }

    if(prevProps.rbcGridData.selected_grid != this.props.rbcGridData.selected_grid && this.props.rbcGridData.selected_grid!=undefined){
      console.log("draw-")
      this.drawRBCAnno(this.props.rbcGridData.selected_grid)
    }

    if((prevProps.wbcGridData||{}).tag != (this.props.wbcGridData||{}).tag){
      this.getAllFieldViewAnnotations();
    }

    if(prevProps.confirmed != this.props.confirmed){
      this.getAllFieldViewAnnotations();
    }

    if((prevProps.wbcGridData||{}).update_summary == false && (this.props.wbcGridData||{}).update_summary == true){
      this.getAllFieldViewAnnotations();
    }

    if(this.state.slidemap!=undefined && prevState.slidemap == undefined){
      let selected = this.props.fieldGridData.selected_grid
      if (selected) {
        let center = selected.center
        // console.log("center", center)

        this.state.slidemap.getView().animate(
            {rotation: degreeToRadian((this.state.slide_data || {}).viewer_rotation)}, {center: [center[0], center[1]]}, {duration: 500});
      }
    }

    if (prevProps.fieldGridData.selected_grid &&
        prevProps.fieldGridData.selected_grid.id !== this.props.fieldGridData.selected_grid.id) {
      let selected = this.props.fieldGridData.selected_grid
      this.drawAnnotationsFieldView(this.state.all_on_map_field);
      if (selected && this.state.slidemap != undefined) {
        let center = selected.center
        // console.log("center", center)
        this.state.slidemap.getView().animate(
            {rotation: degreeToRadian((this.state.slide_data || {}).viewer_rotation)}, {center: [center[0], center[1]]}, {duration: 500});
      }

      // let center = JSON.parse(selected.center)
      // console.log("center", center)
      // this.state.slidemap.getView().animate(
      //   {rotation: 0}, {center: [center[0], center[1]]});
    }

    if(prevProps.mode != this.props.mode){
      this.vector.setSource(new VectorSource({
        features:[],
        wrapX: false
    }))
    }

    if((prevProps.field_view==false && this.props.field_view==true)||(prevProps.mode != this.props.mode)){
      this.getAllFieldViewAnnotations();
    }

    if(prevState.mapRenderComplete != this.state.mapRenderComplete ) {
      if(this.state.mapRenderComplete) {
        console.log("Map rendered complete")
        if (!this.props.urlState.onStitchedLayer) {
          this.cacheZStackLayers();
        } else {
          this.lastCachingCall = Date.now();
        }
      }
    }

    if(this.props.bloodGridData.selected==true){
      console.log("bloodgrid",this.props.bloodGridData)
      if(this.props.bloodGridData.move==true) {
        // if(prevProps.bloodGridData.selected_anno.id!= this.props.bloodGridData.selected_anno.id){
        // console.log("props",prevProps.bloodGridData.selected_anno.id, this.props.bloodGridData.selected_anno.id)
        // this.state.slidemap.addLayer(this.vector);
        console.log("xxx")
        let center = JSON.parse(this.props.bloodGridData.selected_anno.center)
        // console.log("anno", this.props.bloodGridData.selected_anno)
        // console.log("centre",(bbox[0]+bbox[2])/2,(bbox[1]+bbox[3])/2)
        // console.log("z",parseInt(this.state.slide_data.z_levels.split(',').pop()))
        this.state.slidemap.getView().animate(
            {rotation: degreeToRadian((this.state.slide_data || {}).viewer_rotation)}, {center: [center[0], center[1]]}, {duration: 0});
        message.info("Going to selected position", 2)

        //
        if (this.props.bloodGridData.class == "WBC") {
          let extent = this.state.slidemap.getView().calculateExtent(this.state.slidemap.getSize())//ignoring zoom for now
          let height_offset = Math.abs(extent[3] - extent[1]) / 2
          let width_offset = Math.abs(extent[2] - extent[0]) / 2
          let final_extent = [center[0] - width_offset, center[1] - height_offset, center[0] + width_offset, center[1] + height_offset]
          this.getExtentAllAnnotations(final_extent);
        } else {
          this.drawAnnotations([this.props.bloodGridData.selected_anno]);
          }
          this.props.dispatch(bloodAnnoStopMove());

          
        // }
      }
      // else{
      //   this.state.slidemap.addLayer(this.vector);
      //   console.log("xxx")
      //   this.drawAnnotations();
      //   this.state.slidemap.getView().animate({zoom: 5}, 
      //     {rotation: 0}, {center: [2000,3000]});
      //     message.info("Going to screenshot position");
      // }
    }

    if(prevProps.view_mode != this.props.view_mode || prevProps.mode!=this.props.mode){
      this.setState({
        loadedMap: false,
        mapRenderComplete: false
      })
    }

    if(prevProps.urlState.zStackLevel != this.props.urlState.zStackLevel) {
      let layers = this.state.slidemap.getLayers().getArray();
      for(let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        layer.setVisible(true);
      }
      for(let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        if(layer.values_.posS == this.props.urlState.zStackLevel || layer.values_.name == mapLayerTypes.ANNOTATION_LAYER_NAME || layer.values_.name == mapLayerTypes.STITCHED_LAYER_NAME || layer.values_.name == mapLayerTypes.BLOOD_ANNOTATION_LAYER_NAME
          ) {
          layer.setVisible(true);
        } else {
          layer.setVisible(false);
        }
      }
    }

    if ((prevProps.urlState.apps_initialised != this.props.urlState.apps_initialised && !this.state.loadedMap) ||
        prevProps.view_mode != this.props.view_mode || prevProps.mode != this.props.mode || this.initMapState) {
      this.initMapState = false;
      getSlideAndInitialiseMapState(this.props.slide_id, (obj) => {
        obj.addPreviewMapControl = this.addPreviewMapControl;
        obj.goHome = this.goHome;
        this.setState(obj)
      }, this.props.urlState, this.updateUrlState, this.updateLayers, this.onMapRenderingComplete, this.scrollActionOnMap);
    }

    if (!this.state.loadedMap && !this.state.isFetching && !this.state.isErrored) {
      this.state.slidemap.setTarget("map-" + this.props.map_id);
      if (this.previewMapRef != undefined) {
        console.log("Preview Map Was Undefined!!!");
        this.state.slidemap.addControl(this.previewMapRef);
        this.props.dispatch(updateDigitalZoomStatus(this.props.urlState, true))
        this.state.slidemap.getInteractions().forEach(interaction => {
            if(interaction instanceof KeyboardPan){
              this.state.slidemap.removeInteraction(interaction)
            }
        });
      }
        this.setState({
          loadedMap: true
        })

        let zIndex = (this.state.slide_data.x_fields * 1000000) + this.state.slide_data.y_fields + 1000000000;
        this.vector.setZIndex(zIndex);
        this.state.slidemap.addLayer(this.vector);  
        this.addSelectInteraction();
        this.props.dispatch(updateMorphleID(this.state.slide_data.morphle_id, this.state.slide_data.id));

        // if (this.props.urlState.takeZStack) {
        //   document.getElementById("map-" + this.props.map_id).addEventListener('wheel', this.scrollActionOnMap, false);
        // } else {
        //   this.state.slidemap.addInteraction(new MouseWheelZoom());
        // }

        // let self = this;
        // let url = getPrefixedUrl(this.state.slide_data.bucket_name + "/" + this.state.slide_data.path + 'meta/slide_meta.json', this.state.slide_data);
        // axios.get(url)
        //   .then(slideMetaRes => {
        //     let stackimage ={};
        //     if(slideMetaRes.data.takeZStack) {
        //         this.state.slidemap.addEventListener('singleclick', (e) => {
        //             if(self.props.urlState.selectedShape == null) {
        //               let tileCoord = getTileNumber(e.coordinate, self.state.slide_data, self.state.imageLog, self.state.imageShape);
        //               if(tileCoord[0] != -1) {
        //                   stackimage.size = getTiledImageSizeInMicrons(self.state.slide_data);
        //                   stackimage.tileCoord = tileCoord;
        //                   self.setState({
        //                       stackimage,
        //                   });
        //                   self.props.dispatch(updateTileCoordinate(tileCoord));
        //                   let quadrant = getQuadrant(e.coordinate, tileCoord, self.state.slide_data, self.state.imageShape, self.state.imageLog, self.state.view.getZoom(), self.state.maxZoom);
        //                   self.props.dispatch(updateQuadrant(quadrant));

        //                   let bounds = self.getBounds(tileCoord, quadrant);


        //                   let style = new Style({
        //                     stroke: new Stroke({
        //                         color: '#50ace9',
        //                     }),
        //                     fill: new Fill({
        //                         color: 'rgba(0, 0, 0, 0.0)'
        //                     })
        //                   });

        //                   let feature = new Feature({
        //                       geometry: new Polygon(bounds),
        //                   });

        //                   feature.setStyle(style);
        //                   // feature.set('color', 'black');
        //                   self.state.vectorLayer.setSource(new VectorSource({
        //                       features: [feature],
        //                       wrapX: false
        //                   }));

        //                   let time = 100;
        //                   let initialStart = slideMetaRes.data.takeBidirectionalZStack ? -2 : 1;
        //                   let initialEnd = slideMetaRes.data.takeBidirectionalZStack ? 2 : 4;
        //                   let finalStart = slideMetaRes.data.takeBidirectionalZStack ? (-1 * ((slideMetaRes.data.numZLevels - 1) / 2)) : 5;
        //                   let finalEnd = slideMetaRes.data.takeBidirectionalZStack ? ((slideMetaRes.data.numZLevels - 1) / 2) : slideMetaRes.data.numZLevels - 1;
        //                   for(let i = initialStart; i <= initialEnd; i++) {
        //                     let urlSuffix = `${self.state.slide_data.bucket_name}/${self.state.slide_data.path}stack`;
        //                     let url = getPrefixedUrl(urlSuffix, self.state.slide_data);
        //                     url = `${url}/x${self.props.urlState.tileCoord[0]}y${self.props.urlState.tileCoord[1]}/${i}.${self.state.slide_data.img_type}`;
        //                     self.imageDownloadTimer = setTimeout(() => self.downloadImage(url), time);
        //                     time += 100;
        //                   }
        //                   time += 500;
        //                   for(let i = finalStart; i <= finalEnd; i++) {
        //                     if(i < -2 && i > 2) {
        //                       let urlSuffix = `${self.state.slide_data.bucket_name}/${self.state.slide_data.path}stack`;
        //                       let url = getPrefixedUrl(urlSuffix, self.state.slide_data);
        //                       url = `${url}/x${self.props.urlState.tileCoord[0]}y${self.props.urlState.tileCoord[1]}/${i}.${self.state.slide_data.img_type}`;
        //                       self.imageDownloadTimer = setTimeout(() => self.downloadImage(url), time);
        //                       time += 100;
        //                     }
        //                   }
        //               }
        //             }
        //         }, false)
        //     }
        //   })
        //   .catch(err => {
        //     console.log("Failed request", err);
        //   });
      if (this.props.urlState.x === -1 ) this.goHome();

      
    }
  }

  downloadImage = (url) => {
    let image = new Image();
    image.src = url;
  }

  getBounds = (tileCoord, quadrant) => {
    let imageName = "x" + tileCoord[0] + "y" + tileCoord[1] + ".jpg";
    let maxZoom = parseInt(this.state.slide_data.z_levels.split(",")[this.state.slide_data.z_levels.split(",").length - 1]);
    let divisions = Math.pow(2, this.props.urlState.z == maxZoom ? 2: this.props.urlState.z == maxZoom - 1 ? 1 : 0);
    let widthThreshold = (2049 * this.state.slide_data.uperpixel) / divisions;
    let heightThreshold = (2449 * this.state.slide_data.uperpixel) / divisions;
    let xOffset = this.props.urlState.z >= maxZoom - 1 ? quadrant[0] : 0;
    let yOffset = this.props.urlState.z >= maxZoom - 1 ? quadrant[1] : 0;

    if (this.state.imageLog["map"]["stitching_info"]["map"][imageName] != undefined) {
      let imagePos = this.state.imageLog["map"]["stitching_info"]["map"][imageName]["map"]["Absolute Position"]["map"];
      let pointA = [(xOffset * widthThreshold) + (imagePos["X"] * this.state.slide_data.uperpixel), (-1 * yOffset * heightThreshold) + this.state.imageShape[1] - ((imagePos["Y"] * this.state.slide_data.uperpixel) + heightThreshold)];
      let pointB = [(xOffset * widthThreshold) + (imagePos["X"] * this.state.slide_data.uperpixel) + widthThreshold, (-1 * yOffset * heightThreshold) + this.state.imageShape[1] - ((imagePos["Y"] * this.state.slide_data.uperpixel) + heightThreshold)];
      let pointC = [(xOffset * widthThreshold) + (imagePos["X"] * this.state.slide_data.uperpixel) + widthThreshold, (-1 * yOffset * heightThreshold) + this.state.imageShape[1] - (imagePos["Y"] * this.state.slide_data.uperpixel)];
      let pointD = [(xOffset * widthThreshold) + (imagePos["X"] * this.state.slide_data.uperpixel), (-1 * yOffset * heightThreshold) + this.state.imageShape[1] - (imagePos["Y"] * this.state.slide_data.uperpixel)];
      return [[pointA, pointB, pointC, pointD, pointA]];
    } else {
      return [[]];
    }
  }

  openZoomAdjustmentApp = (e) => {
    this.props.dispatch(updateAppClosedStatus(keys.displaySizeCalibrationKey.id, false, this.props.urlState));
    e.stopPropagation();
  }

  stopViewing = (e) => {
    if(this.props.loginAlerts.is_staff || this.props.loginAlerts.superuser) {
      window.location.href = "/" + globalUrlPrefix + "/dashboard/";
    } else {
      window.location.href = "/" + globalUrlPrefix + "/cases/";
    }
  }

  toggleModal = () => {
      let modalVisible = !this.state.modalVisible;
      this.setState({
          modalVisible,
      });
  }

  updateTileCoordinates = (tileCoordinates) => {
    let stackimage = Object.assign({}, this.state.stackimage);
    stackimage.tileCoord = tileCoordinates;
    this.setState({
      stackimage,
    });
  }

  goHome = () => {
    if (this.state.layer != undefined) {
      this.state.slidemap.getView().fit(this.state.extent);
    }
  }

  render = () => {
	  let isMObile = checkIfMobile();
	  let viewer;
	  let filter = convertSettingsToFilter(this.props.viewerSettings[((this.props.mapsState[this.props.map_id].slideState || {})
		  .slide_data || {}).settings]);
	  if (this.props.view_mode == 3) {
		  viewer = <Col tabIndex="0" id={"map-" + this.props.map_id} className={"slide-map-container-full"}
						style={{
							backgroundColor: this.state.backgroundColor,
							filter,
						}}>
			  <div id="myposition"></div>
			  {(this.props.urlState || {}).presentCode != undefined ? null :
				  <img className="morphle-watermark" id="morphle-watermark" src={MorphleLogo}></img>}
			  {/* {(((this.props.urlState || {}).app_state || {})[keys.displaySizeCalibrationKey.id] || {})[this.screenSizeString] == undefined
        && !(cookie.load('morpheus_setting') || {}).is_audience ? 
        <div className="adjustment-error">
            <Button type='danger' onClick={this.openZoomAdjustmentApp}>
              <Icon type="exclamation" style={{color:'white'}} /> Different screen size detected. Click to adjust the size.
            </Button>
        </div>
        : null
      } */}
      {this.state.shiftScrollCount <= 10 && !isMObile && this.props.urlState.takeZStack ?
        <div className="main-background-color loading-message-slidemap">
          <b style={{ color: 'white' }}>Use SHIFT + Mouse Scroll Wheel to navigate between Z-Stack Layers</b>
        </div>
        : null
      }
    </Col>
    }
    else {
      viewer = <Col tabIndex="0" id={"map-" + this.props.map_id}
                    className={(this.props.mode == "FIELD" || !(this.props.differential)) ? "slide-map-container" : this.props.styleSelector}
                    style={{
						backgroundColor: this.state.backgroundColor,
						filter,
					}}>
        <div id="myposition"></div>
        {(this.props.urlState || {}).presentCode != undefined ? null :
            <img className="morphle-watermark" id="morphle-watermark" src={MorphleLogo}></img>}
      </Col>
    }

    if (this.state.isErrored) {
      console.log(this.state.errMessage);
    }
    return (
      this.state.isFetching || this.state.isErrored ? <div>Loading</div> :
        isNaN(this.props.urlState.x) || isNaN(this.props.urlState.y) || isNaN(this.props.urlState.z) || isNaN(this.props.urlState.r) ?
        getErrorComponent() :
        <div>
          <div className={(this.props.urlState || {}).presentCode != undefined ? "no-pointer-activity" : ""}>
            <Row className="slide-map-row"> 
                <Col className="slide-map-col">
                  <Row>
                      {(this.props.urlState || {}).presentCode != undefined ?
                        <div>
                          <div className="border-divider border-divider-top"></div>
                          <div className="border-divider border-divider-left"></div>
                          <div className="border-divider border-divider-right"></div>
                          <div className="border-divider border-divider-bottom"></div>
                        </div>
                        : null
                      }
                      <AppBoard slideState={this.state} updateTileCoordinates={this.updateTileCoordinates} viewerType={slideViewerType.NORMAL_SLIDE_VIEWER} />
                      {(this.props.urlState || {}).presentCode != undefined ?
                      <Button className="stop-presentation" type='danger' onClick={this.stopViewing}>
                        Exit Presentation
                      </Button> :
                      null
                      }
                      {viewer}
                  </Row>
                   
                </Col>
            </Row>
          </div>
        </div>
    )
  }
}

const mapStateToProps = state => {
  return {
	  urlState: state.viewerUrlReducer,
	  loginAlerts: state.loginAlerts,
	  bloodGridData: state.bloodGridReducer,
	  fieldGridData: state.fieldViewReducer,
	  wbcGridData: state.wbcViewReducer,
	  rbcGridData: state.bloodGridRBCReducer,
	  pltGridData: state.bloodGridPLTReducer,
	  mapsState: state.mapsStateReducer,
	  viewerSettings: state.viewerSettingsReducer,
  };
};

export default connect(mapStateToProps)(BloodSlideMap);
