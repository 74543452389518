import {bloodAnnoGridActions} from "../actionTypes/brainAnno.constant"
import { CaseActions } from "../actionTypes/cases.constant"

const InititialState = {
    selected_annotations:[], 
    loading_state: false,
    count_saved_annotations: 0,
    total_area_saved_annotations: 0,
    slideState: '',
    cluster_id: 0,
    get_whole_cluster: false,
    metastasis_area: 0,
    full_tissue_area: 0,
}

export const brainAnnoGridReducer = (state=InititialState, action) => {
    switch(action.type){
        case bloodAnnoGridActions.ADD_ANNOTATION:
            return Object.assign({}, state, {
                selected_annotations: action.response
            })
        
        case bloodAnnoGridActions.REMOVE_ANNOTATIONS:
            return Object.assign({}, state, {
                selected_annotations: []
            })

        case bloodAnnoGridActions.UPDATE_LOADING_STATE:
            return Object.assign({}, state, {
                loading_state: action.response
            })
        
        case bloodAnnoGridActions.UPDATE_COUNT_SAVED:
            return Object.assign({}, state, {
                count_saved_annotations: action.response
            })
        
        case bloodAnnoGridActions.UPDATE_TOTAL_AREA:
            return Object.assign({}, state, {
                total_area_saved_annotations: action.response
            })

        case bloodAnnoGridActions.UPDATE_SLIDE_STATE:
            // console.log("val",action.response)
            return Object.assign({}, state, {
                slideState: action.response
            })

        case bloodAnnoGridActions.UPDATE_CLUSTER_ID:
            return Object.assign({}, state, {
                cluster_id: action.response
            })

        case bloodAnnoGridActions.GET_WHOLE_CLUSTER:
            return Object.assign({}, state, {
                get_whole_cluster: action.response
            })

        case bloodAnnoGridActions.UPDATE_METASTASIS_AREA:
            return Object.assign({}, state, {
                metastasis_area: action.response
            })

        case bloodAnnoGridActions.UPDATE_FULL_TISSUE_AREA:
            return Object.assign({}, state, {
                full_tissue_area: action.response
            })
        default :
            return state
    }
}