import { LivemodeConstants } from '../actionTypes/livemode.constant';
import { updateLiveModeAction, updateShowZStackImages, updateDoDropDistanceAF, updateLastImageTimestampLiveMode, updateZStackLevel, updateVisitedArea, updateSlotID, updateCurrPoint, updateTakeZStack, updateDoAutoFocus } from './admin.state.action';
import { updateLiveView, updateLiveViewUrl } from './liveview.action';
import { liveModeLoadingMessages, previewCameraType, objectiveTypeLiveMode } from '../utils/const';
import { message } from 'antd';
import { AuthHeader } from '../helper/auth.token';
import { Style, Stroke, Fill } from 'ol/style';
import { Feature } from 'ol';
import Polygon from 'ol/geom/Polygon';
import GeoJSON from 'ol/format/GeoJSON';
import VectorSource from 'ol/source/Vector';
import { union } from "@turf/turf";
import axios from 'axios';

export const updateInputFieldInFocus = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_INPUT_FIELD_IN_FOCUS,
        value: value
    })
}

export const updateZStackLevels = (value) => dispatch => {
    dispatch({
        type: LivemodeConstants.UPDATE_Z_STACK_LEVELS,
        value: value
    })
}

export const updateStepSizeZStack = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_STEP_SIZE_Z_STACK,
        value: value
    })
}

export const updateMovementStepSize = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_MOVEMENT_STEP_SIZE,
        value: value
    })
}

export const updateImageFetching = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_IMAGE_FETCHING,
        value: value
    })
}

export const updateDeviceId = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_DEVICE_ID,
        value: value
    })
}

export const updateExposure = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_EXPOSURE,
        value: value
    })
}

export const updateGamma = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_GAMMA,
        value: value
    })
}

export const updateAccessToLiveMode = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_ACCESS_TO_LIVE_MODE,
        value: value
    })
}

export const updateUseLiveModeInViewMode = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_USE_LIVE_MODE_IN_VIEW_MODE,
        value: value
    })
}

export const updateLatestLiveModeDataTimestamp = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_LATEST_LIVE_MODE_DATA_TIMESTAMP,
        value: value
    })
}

export const updateGeneratedAccessCode = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_GENERATED_ACCESS_CODE,
        value: value
    })
}

export const updateAccessRevoked = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_ACCESS_REVOKED,
        value: value
    })
}

export const updateTakePreviewInLiveMode = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_TAKE_PREVIEW_IN_LIVE_MODE,
        value: value
    })
}

export const updateAtLeastOneImageFetched = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_ATLEAST_ONE_IMAGE_FETCHED,
        value: value
    })
}

export const toggleShowChat = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.TOGGLE_SHOW_CHAT,
        value: value
    })
}

export const updateChatMessages = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_CHAT_MESSAGES,
        value: value
    })
}

export const updateCurrentMapPosition = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_CURRENT_MAP_POSITION,
        value: value
    })
}

export const updateTileCenters = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_TILE_CENTERS,
        value: value
    })
}

export const updateSlideData = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_SLIDE_DATA,
        value: value
    })
}

export const updateCurrentPreview = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_CURRENT_PREVIEW,
        value: value
    })
}

export const updateScheduledPreviews = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_SCHEDULED_PREVIEWS,
        value: value
    })
}

export const updatePreviewsDone = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_PREVIEWS_DONE,
        value: value
    })
}

export const updateUseCoverSlipObjective = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_USE_COVERSLIP_OBJECTIVE,
        value: value
    })
}

export const updateLastBound = (value) => dispatch => {

    dispatch({
        type: LivemodeConstants.UPDATE_LAST_BOUND,
        value: value
    })
}

export const updateGlobalCoordinates = (value) => dispatch => {
    dispatch({
        type: LivemodeConstants.UPDATE_GLOBAL_COORDS,
        value: value,
    })
}

export const updateVisitedTiles = (value) => dispatch => {
    dispatch({
        type: LivemodeConstants.UPDATE_VISITED_TILES,
        value: value,
    })
}

export const syncVisitedTilesTrigger = (value) => dispatch => {
    console.log("sync-2")
    dispatch({
        type: LivemodeConstants.SYNC_VISITED_TILES_TRIGER,
        value: value,
    })
}


export const getLiveImageCurrentZ = (url, slot) => dispatch => {
    dispatch(updateLiveModeAction(true, liveModeLoadingMessages.LOADING_IMAGE));
    dispatch(updateShowZStackImages(false));
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if(response.status == 200) {
                dispatch(updateAtLeastOneImageFetched(true));
                dispatch(updateLiveView(response.data));
                // dispatch(updateLiveViewUrl("data:image/png;base64," + response.data));
                dispatch(updateLiveViewUrl(`/dev-ssd/last_preview/z_stack/slot_${slot}/0.jpg?time=` + Date.now()));
                dispatch(updateLiveModeAction(false, liveModeLoadingMessages.LOADING_IMAGE));
            } else {
                console.log(response);
                // message.error("Access Revoked!!", 2.5);
                dispatch(updateAccessRevoked(true));
                dispatch(updateAccessToLiveMode(false));
            }
        })
        .catch(err => {
            console.log("Failed Getting Image");
            dispatch(updateLiveModeAction(false, liveModeLoadingMessages.LOADING_IMAGE));
        });
}

export const getLiveImageMoveAndFocus = (url, errorMessage, slotID, takeZStack, updateArea, areaVisited, liveModePreviewVectorLayer) => dispatch => {
    dispatch(updateImageFetching(true));
    let startTime = Date.now();
    dispatch(updateLiveModeAction(true, errorMessage));
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(resp => {
            if (resp.status === 200) {
                if (slotID >= 0) {
                    if (resp.data != 'Request Skipped') {
                        console.log("Time taken to return image after keyboard movement: " + (Date.now() - startTime));
                        dispatch(updateAtLeastOneImageFetched(true));
                        dispatch(updateDoDropDistanceAF(false));
                        dispatch(setImageUrlForFocusMode(takeZStack));
                        // dispatch(updateLiveViewUrl("data:image/png;base64," + resp.data));
                        dispatch(updateLiveViewUrl(`/dev-ssd/last_preview/z_stack/slot_${slotID}/0.jpg?time=` + Date.now()));
                        dispatch(updateLiveView(resp.data));
                        dispatch(updateImageFetching(false));
                        // dispatch(updateCurrentMapPosition(newCurrentMapPosition));
                        if (updateArea) {
                            dispatch(syncVisitedTilesTrigger(true))
                            // dispatch(markArea(bounds, areaVisited, liveModePreviewVectorLayer));
                        }
                    }
                }
            }
            else {
                console.log(resp);
                dispatch(updateLiveModeAction(false, errorMessage));
                dispatch(updateImageFetching(false));
            }
        })
        .catch(err => {
            console.log(err);
            message.error("Soft Limits Hit!!", 2.5);
            dispatch(updateLiveModeAction(false, errorMessage));
            dispatch(updateImageFetching(false));
        })
}

export const getLiveImageMoveAndFocusDropDistance = (url, errorMessage, slotID, takeZStack, bounds, updateArea, areaVisited, liveModePreviewVectorLayer, newCurrentMapPosition) => dispatch => {
    dispatch(updateImageFetching(true));
    dispatch(updateLiveModeAction(true, liveModeLoadingMessages.DROP_DISTANCE_AF));
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(resp => {
            if (resp.status === 200) {
                if (slotID >= 0) {
                    dispatch(getLiveImageMoveAndFocus(url.replace("move_and_focus_drop_distance", "move_and_focus"), errorMessage, slotID, takeZStack, bounds, updateArea, areaVisited, liveModePreviewVectorLayer, newCurrentMapPosition));
                }
            }
            else {
                console.log(resp);
                dispatch(updateLiveModeAction(false, liveModeLoadingMessages.DROP_DISTANCE_AF));
                dispatch(updateImageFetching(false));
            }
        })
        .catch(err => {
            console.log(err);
            message.error("Soft Limits Hit!!", 2.5);
            dispatch(updateLiveModeAction(false, liveModeLoadingMessages.DROP_DISTANCE_AF));
            dispatch(updateImageFetching(false));
        })
}

export const getLiveImageMoveToPixelAndFocus = (evt, url, singleImageUrl, errorMessage, takeZStack, doDropDistanceAF, previewImageExtent, areaVisited, liveModePreviewVectorLayer, slotID, deviceId, previewCamera, noOfSlots, selectedlat, selectedlong, nearestTileY, xDistance, yDistance, nearestTileCenter, lastBound, newCurrentMapPosition) => dispatch => {

    dispatch(updateImageFetching(true));

    // let selectedlong = previewImageExtent[3] - evt.coordinate[1];
    // let selectedlat = evt.coordinate[0];

    // let selectedlong = evt.coordinate[1] - startTile[1];
    // let selectedlat = startTile[0] - evt.coordinate[0];

    // console.log(selectedlat)
    // console.log(selectedlong)
    // console.log(previewImageExtent)
    let startTime = Date.now();
    if (!(evt.coordinate[1] < 0 || evt.coordinate[1] > previewImageExtent[3] || evt.coordinate[0] < 0 || evt.coordinate[0] > previewImageExtent[2])) {
        dispatch(updateLiveModeAction(true, errorMessage));
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    if (doDropDistanceAF) { 
                        dispatch(getLiveImageMoveAndFocus(singleImageUrl, errorMessage, slotID, takeZStack, [], false, 
                            areaVisited, liveModePreviewVectorLayer, newCurrentMapPosition));
                    } else {
                        dispatch(updateLiveViewUrl(`/dev-ssd/last_preview/z_stack/slot_${slotID}/0.jpg?time=` + Date.now()));
                        dispatch(updateLiveView(response.data));
                        dispatch(updateImageFetching(false));
                        dispatch(setImageUrlForFocusMode(takeZStack));
                        dispatch(updateAtLeastOneImageFetched(true));
                        dispatch(updateCurrentMapPosition(newCurrentMapPosition));
                    }
                    console.log("Time taken to return image after click: " + (Date.now() - startTime));
                    dispatch(updateDoDropDistanceAF(false));
                    dispatch(syncVisitedTilesTrigger(true))
                    dispatch(markAreaMoveToFocus(Math.trunc(selectedlat), Math.trunc(selectedlong), previewImageExtent, 
                        areaVisited, liveModePreviewVectorLayer, slotID, deviceId, previewCamera, noOfSlots, nearestTileY,
                        xDistance, yDistance, nearestTileCenter, lastBound));
                    // dispatch(updateLiveViewUrl("data:image/png;base64," + response.data));
                }
                else {
                    console.log(response);
                    // message.error("Access Revoked!!", 2.5);
                    dispatch(updateAccessRevoked(true));
                    dispatch(updateAccessToLiveMode(false));
                    dispatch(updateLiveModeAction(false, errorMessage));
                    dispatch(updateImageFetching(false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to move to the specified position!!", 2.5);
                dispatch(updateLiveModeAction(false, errorMessage));
                dispatch(updateImageFetching(false));
            });
    } else {
        dispatch(updateImageFetching(false));
        message.error("Soft limits Hit!!", 2.5);
    }
}

export const markAreaMoveToFocus = (x, y, previewImageExtent, areaVisited, liveModePreviewVectorLayer, slotID, deviceId, previewCamera, noOfSlots, nearestTileY, xDistance, yDistance, nearestTileCenter, lastBound) => dispatch => {
    let partsOfUrl = "api~stage~get_selected_area_coordinates";
    let url = `/server/scano/` + deviceId + `/` + partsOfUrl + "?slot_id=" +
        slotID + "&x=" + x + "&y=" + y + "&nearestTileY=" + nearestTileY;
        dispatch(syncVisitedTilesTrigger(true))
        // console.log(xDistance)
        // console.log(yDistance)
        // console.log(nearestTileCenter)

    // axios.get(url, { headers: { Authorization: AuthHeader() } })
    //     .then(response => {
    //         let bounds = [];
    //         if (previewCamera == previewCameraType.FOUR_X) {
    //             if (noOfSlots > 1) {
    //                 // bounds = [
    //                 //     [response.data["point 0"][0], previewImageExtent[3] - response.data["point 0"][1]],
    //                 //     [response.data["point 0"][0], previewImageExtent[3] - response.data["point 1"][1]],
    //                 //     [response.data["point 2"][0], previewImageExtent[3] - response.data["point 1"][1]],
    //                 //     [response.data["point 2"][0], previewImageExtent[3] - response.data["point 0"][1]],
    //                 //     [response.data["point 0"][0], previewImageExtent[3] - response.data["point 0"][1]],
    //                 // ];
    //                 // bounds = [
    //                 //     [response.data["point 0"][0], previewImageExtent[3] - response.data["point 0"][1]],
    //                 //     [response.data["point 0"][0], previewImageExtent[3] - response.data["point 2"][1]],
    //                 //     [response.data["point 1"][0], previewImageExtent[3] - response.data["point 2"][1]],
    //                 //     [response.data["point 1"][0], previewImageExtent[3] - response.data["point 0"][1]],
    //                 //     [response.data["point 0"][0], previewImageExtent[3] - response.data["point 0"][1]],
    //                 // ];
    //                 bounds = [
    //                     [xDistance + nearestTileCenter[0] - response.data["point 0"][0], response.data["point 0"][1] + nearestTileCenter[1] - yDistance],
    //                     [xDistance + nearestTileCenter[0] - response.data["point 0"][0], response.data["point 2"][1] + nearestTileCenter[1] - yDistance],
    //                     [xDistance + nearestTileCenter[0] - response.data["point 1"][0], response.data["point 2"][1] + nearestTileCenter[1] - yDistance],
    //                     [xDistance + nearestTileCenter[0] - response.data["point 1"][0], response.data["point 0"][1] + nearestTileCenter[1] - yDistance],
    //                     [xDistance + nearestTileCenter[0] - response.data["point 0"][0], response.data["point 0"][1] + nearestTileCenter[1] - yDistance],
    //                 ];
    //             } else {
    //                 // bounds = [
    //                 //     [response.data["point 0"][0], previewImageExtent[3] - response.data["point 0"][1]],
    //                 //     [response.data["point 0"][0], previewImageExtent[3] - response.data["point 2"][1]],
    //                 //     [response.data["point 1"][0], previewImageExtent[3] - response.data["point 2"][1]],
    //                 //     [response.data["point 1"][0], previewImageExtent[3] - response.data["point 0"][1]],
    //                 //     [response.data["point 0"][0], previewImageExtent[3] - response.data["point 0"][1]],
    //                 // ];
    //                 bounds = [
    //                     [response.data["point 0"][0] - xDistance + nearestTileCenter[0], yDistance + nearestTileCenter[1] - response.data["point 0"][1]],
    //                     [response.data["point 0"][0] - xDistance + nearestTileCenter[0], yDistance + nearestTileCenter[1] - response.data["point 2"][1]],
    //                     [response.data["point 1"][0] - xDistance + nearestTileCenter[0], yDistance + nearestTileCenter[1] - response.data["point 2"][1]],
    //                     [response.data["point 1"][0] - xDistance + nearestTileCenter[0], yDistance + nearestTileCenter[1] - response.data["point 0"][1]],
    //                     [response.data["point 0"][0] - xDistance + nearestTileCenter[0], yDistance + nearestTileCenter[1] - response.data["point 0"][1]],
    //                 ];
    //             }
    //         } else {
    //             bounds = [
    //                 [response.data["point 0"][0], previewImageExtent[3] - response.data["point 0"][1]],
    //                 [response.data["point 0"][0], previewImageExtent[3] - response.data["point 2"][1]],
    //                 [response.data["point 1"][0], previewImageExtent[3] - response.data["point 2"][1]],
    //                 [response.data["point 1"][0], previewImageExtent[3] - response.data["point 0"][1]],
    //                 [response.data["point 0"][0], previewImageExtent[3] - response.data["point 0"][1]],
    //             ];
    //         }
    //         let newLastBound = Object.assign({}, lastBound);
    //         newLastBound[slotID] = bounds;
    //         dispatch(updateLastBound(newLastBound));
    //         dispatch(markArea(bounds, areaVisited, liveModePreviewVectorLayer));
    //     })
    //     .catch(err => {
    //         console.log(err);
    //     });
}

export const markArea = (bounds, areaVisited, liveModePreviewVectorLayer) => dispatch => {
    console.log("marking_area--1", areaVisited)
    dispatch(syncVisitedTilesTrigger(true))
    // let recentPointStyle = new Style({
    //     stroke: new Stroke({
    //         color: '#1c568f',
    //     }),
    //     fill: new Fill({
    //         color: 'rgba(28, 86, 143, 0.5)'
    //     })
    // });

    // let pointStyle = new Style({
    //     fill: new Fill({
    //         color: 'rgba(124, 252, 0, 0.5)'
    //     })
    // });

    // let feature = new Feature({
    //     geometry: new Polygon([bounds]),
    // });

    // feature.setStyle(recentPointStyle);

    // let allFeatures = [];

    // let previousBounds = [...areaVisited];

    // if (previousBounds.length > 0) {

    //     let currBound = previousBounds[0];

    //     let mergedFeature = new Feature({
    //         geometry: new Polygon([currBound]),
    //     });
    //     let format = new GeoJSON();

    //     for (let i = 1; i < previousBounds.length; i++) {
    //         currBound = previousBounds[i];

    //         let currFeature = new Feature({
    //             geometry: new Polygon([currBound]),
    //         });
    //         mergedFeature = format.readFeature(union(format.writeFeatureObject(mergedFeature), format.writeFeatureObject(currFeature)));
    //     }

    //     mergedFeature.setStyle(pointStyle);

    //     allFeatures.push(mergedFeature);
    // }

    // allFeatures.push(feature);

    // if (!previousBounds.includes(bounds)) {
    //     previousBounds.push(bounds);
    // }

    // dispatch(updateVisitedArea(previousBounds));

    // liveModePreviewVectorLayer.setSource(new VectorSource({
    //     features: allFeatures,
    //     wrapX: false
    // }));
}

export const setImageUrlForFocusMode = (takeZStack) => dispatch => {
    let timestamp = Date.now();
    dispatch(updateLastImageTimestampLiveMode(timestamp));
    dispatch(updateZStackLevel(0));
    dispatch(updateShowZStackImages(takeZStack));
    dispatch(updateLiveModeAction(false, liveModeLoadingMessages.LOADING_IMAGE));
}

export const updateLiveModeStatus = (data, slot, liveModePreviewVectorLayer, useLiveModeInViewMode, showImage) => dispatch => {
    dispatch(updateSlotID(data.slotLoaded));
    if (data.objectiveType == objectiveTypeLiveMode.COVER_SLIP) {
        dispatch(updateUseCoverSlipObjective(true));
    } else {
        dispatch(updateUseCoverSlipObjective(false));
    }
    if (data.slotLoaded >= 0) {
        let activeObjectiveType = data.slotWiseStatus[slot].activeObjectiveType;
        if (activeObjectiveType == objectiveTypeLiveMode.COVER_SLIP) {
            dispatch(updateUseCoverSlipObjective(true));
        } else {
            dispatch(updateUseCoverSlipObjective(false));
        }
        dispatch(updateAtLeastOneImageFetched(data.slotWiseStatus[slot].atLeastOneImageTaken[activeObjectiveType]));
        dispatch(updateDoDropDistanceAF(data.slotWiseStatus[slot].doDropDistance[activeObjectiveType]));
        if (data.slotWiseStatus[slot].curPoint != null) {
            dispatch(updateCurrPoint([data.slotWiseStatus[slot].curPoint.x, data.slotWiseStatus[slot].curPoint.y]));
        }
        if(showImage || (useLiveModeInViewMode && data.slotWiseStatus[slot].atLeastOneImageTaken[activeObjectiveType])) {
            dispatch(updateLiveView(data.image));
            dispatch(updateLiveViewUrl(`/dev-ssd/last_preview/z_stack/slot_${slot}/0.jpg?time=` + Date.now()));
        }
        if (data.zstackMode) {
                dispatch(updateShowZStackImages(true));
                dispatch(updateTakeZStack(true));
                dispatch(updateDoAutoFocus(false));
        } else {
            dispatch(updateDoAutoFocus(true));
            dispatch(updateShowZStackImages(false));
            dispatch(updateTakeZStack(false));
       }

        dispatch(updateLatestLiveModeDataTimestamp(data.updateCode));
        if (data.slotWiseStatus[slot].curPoint != null) {
            let currBound = getBoundFromPoint(data.slotWiseStatus[slot].curPoint, data.visitedRectWidth, data.visitedRectHeight, data.slotWiseStatus[slot].previewHeight);
            let visitedPointsBound = [];
            for (let i = 0; i < data.slotWiseStatus[slot].visitedPoints.length; i++) {
                if (!(data.slotWiseStatus[slot].visitedPoints[i].x == data.slotWiseStatus[slot].curPoint.x && data.slotWiseStatus[slot].visitedPoints[i].y == data.slotWiseStatus[slot].curPoint.y)) {
                    visitedPointsBound.push(getBoundFromPoint(data.slotWiseStatus[slot].visitedPoints[i], data.visitedRectWidth, data.visitedRectHeight, 
                        data.slotWiseStatus[slot].previewHeight));
                }
            }
            if (liveModePreviewVectorLayer != null) {
                dispatch(syncVisitedTilesTrigger(true))
                // dispatch(markArea(currBound, visitedPointsBound, liveModePreviewVectorLayer));
            } else {
                let areaVisited = [currBound];
                for (let i = 0; i < visitedPointsBound.length; i++) {
                    areaVisited.push(visitedPointsBound[i]);
                }
                dispatch(updateVisitedArea(areaVisited));
            }
        }
    }
}

export const getBoundFromPoint = (point, width, height, previewHeight) => {
    return [
        [parseInt(point.x - (width / 2)), parseInt(previewHeight - (point.y - (height / 2)))], 
        [parseInt(point.x - (width / 2)), parseInt(previewHeight - (point.y + (height / 2)))],  
        [parseInt(point.x + (width / 2)), parseInt(previewHeight - (point.y + (height / 2)))], 
        [parseInt(point.x + (width / 2)), parseInt(previewHeight - (point.y - (height / 2)))], 
        [parseInt(point.x - (width / 2)), parseInt(previewHeight - (point.y - (height / 2)))]
    ];

}

export const getNearestTile = (x, y, tileCenters) => {
    // console.log("tileCenters", tileCenters)
    let minDistance = Number.MAX_VALUE;
    let nearestTile = "";
    for (let tileCenter in tileCenters) {
        if (tileCenters.hasOwnProperty(tileCenter)) {           
            let distance = Math.sqrt(Math.pow((x - tileCenters[tileCenter][0]), 2) + Math.pow((y - tileCenters[tileCenter][1]), 2));
            if (distance < minDistance) {
                minDistance = distance;
                nearestTile = tileCenter;
            }
        }
    }
    return nearestTile;
}
