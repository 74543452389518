import ServicesApp from "../component/gammaviewer/sidebarTop_apps/ServicesApp";
import {Box, Grid, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {StyledSwitch} from "../component/gammaviewer/components/StyledComponents";
import {RestartAltTwoTone} from "@mui/icons-material";

class ServicesView extends ServicesApp {

	getServiceComponent = service =>
		<Stack direction={"column"} bgcolor={"#d4d4d4"} margin={1} padding={1} paddingX={2}>
			<Typography variant={"overline"} fontSize={22} fontWeight={"fontWeightMedium"} align={"center"}>
				{service.name}
			</Typography>
			<Stack key={service.name} direction="row" justifyContent={"space-between"} alignItems={"center"}>
				<Typography fontStyle={"italic"}>{service.service}</Typography>
				<Stack direction={"row-reverse"} spacing={1} alignItems={"center"}>
					<Tooltip title={`Restart ${service.name} service`}>
						<IconButton onClick={() => this.restart(service)}>
							<RestartAltTwoTone color={"secondary"}/>
						</IconButton>
					</Tooltip>
					<Tooltip title={`${service.status ? 'Stop' : 'Start'} ${service.name} service`}>
						<StyledSwitch
							checked={service.status}
							onChange={() => this.toggle(service)}
							inputProps={{'aria-label': 'controlled'}}
						/>
					</Tooltip>
				</Stack>
			</Stack>
			<Box width={350} bgcolor={"#d4d4d4"}>
				<Typography variant={"caption"}>{service.description}</Typography>
			</Box>
		</Stack>

	render() {
		return <Grid container>
			<Grid item xs={12}>
				<Typography align={"center"} variant={"h3"}>Services</Typography>
			</Grid>
			{this.services.map(service =>
				<Grid item minWidth={300}>{this.getServiceComponent(service)}</Grid>
			)}
		</Grid>
	}
}

export default ServicesView;
