import '@ant-design/compatible/assets/index.css';
import {
    CloudUploadOutlined,
    DeleteFilled,
    EditOutlined,
    InfoCircleOutlined,
    PictureOutlined,
    StarFilled,
    StopOutlined,
    ToTopOutlined,
    UploadOutlined
} from '@ant-design/icons';
import {Form} from '@ant-design/compatible';
import axios from "axios";
import _ from 'lodash';
import React, {Component} from "react";
import cookie from "react-cookies";
import {connect} from "react-redux";
import {
    Button,
    Col,
    DatePicker,
    Descriptions,
    Divider,
    Empty,
    Input,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Table,
    Tag,
    Tooltip,
    Upload
} from 'antd';
import {changePage} from '../action/search.action';
import {assignUsersUpdateAlert, loadUsers, removeUserUpdateAlert} from "../action/cases.action"
import {deleteSlide, descUpdateAlert, slideUpdateAlert, startTiling, startUpload} from '../action/slides.action';
import '../asset/style/add_case.css';
import '../asset/style/custom_antd_table_style.css';
import SlideSuggestions from "../component/case/add.slide.modal";
import {AuthHeader} from "../helper/auth.token";
import {historyMP} from "../helper/history";
import SlideDetailsModal from '../component/viewer/viewer.slidedetails';
import {globalUrlPrefix, openInNewTabType, slideViewType} from '../utils/const';
import {
    decodeStringWithNewLine,
    getAssignCaseButtonComponent,
    getAssignCaseComponent,
    getDbEntryComponent,
    getDeleteComponent,
    getErrorComponent,
    getExportComponent,
    getLabelComponent,
    getLoadingComponent,
    getMorphleIDComponent,
    getObjectiveComponent,
    getPreviewComponent,
    getScreenshotCarousel,
    getStarredComponent,
    getTagsComponent,
    getTilingComponent
} from "../component/dashboard/slidelist_utils";
import {makeMenuVisible} from "../action/context.menu.action";
import {getFileExtension, linkViewer, linkViewerNewTab} from "../utils/utils";
import moment from "moment";
import ReactQuill from "react-quill"; // ES6
import querystring from "query-string";
import 'react-quill/dist/quill.snow.css';
import {Carousel} from "react-responsive-carousel";
import {
	getChronologicalSorting,
	getLexicographicalSorting,
	getNumericalSorting,
	getReverseChronologicalSorting,
	getReverseLexicographicalSorting
} from "../component/case/case_utils";
import {AxiosConfig} from "../helper/axios.config";

const { TextArea } = Input;
const { Option } = Select;

const EditableContext = React.createContext();

const EditableFormRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

class EditableCell extends Component {
    state = {
        editing: false,
    };

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    };

    save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    };

    renderCell = form => {
        this.form = form;
        const { children, dataIndex, record, title } = this.props;
        const { editing } = this.state;
        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    initialValue: record[dataIndex],
                })(<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
            </Form.Item>
        ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={this.toggleEdit}
                >
                    {children}
                </div>
            );
    };

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
                ) : (
                        children
                    )}
            </td>
        );
    }
}

const ButtonGroup = Button.Group;

class AllScansCaseView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetchingCase: true,
            isFetchingFiles: true,
            isFetchingFilesSource: true,
            isFetchingSlides: true,
            isFetchingPendingScans: true,
            isFetchingComments: true,
            hasCaseThrownError: true,
            hasSlidesThrownError: true,
            hasCommentsThrownError: true,
            hasFilesThrownError: true,
            hasFilesSourceThrownError: true,
            hasPendingScansThrownError: true,
            selectedRowKeys: [],
            caseScans: {},
            caseScansArray: [],
            previewVisible: false,
            showFile: false,
            previewFile: '',
            caseFileList: [],
            caseFileListSource: [],
            currCase: [],
            pendingScans: [],
            comments: [],
            addSlideModalVisible: false,
            currentHoverRowID: -1,
            screenshotVisible: false,
            screenshots: [],
            sortingMethod: '-date_time',
            CAPContent: '',
            cap_map: [],

            rearchiveDays: null,
            archiveDays: null,
            selectedCAP: '',
            infoEditable: false,
            slideDetailsModelVisible: false,
            selectedSlide: {},
            patient_name :'',
            case_id : '',
            dob: new moment().format('YYYY-MM-DD'),
            gender: 'M',
            priority: 'Medium',
            receiving_date: new moment().format('YYYY-MM-DD'),
            reporting_date: new moment().format('YYYY-MM-DD'),
            age_and_sex: '',
            consulting_doctor: '',
            can_edit: true,
            editing_user: '',
            carousel_index: 0,
            selected_users: [],
        }

        this.is_cloud = cookie.loadAll().deployment_mode === 'cloud';
        this.isMobile = cookie.loadAll().isMobile === 'true';
        this.iOS = cookie.loadAll().iOS === "true";
    }

    componentWillMount = () => {
        this.getReArchiveDays();
        window.addEventListener("beforeunload", this.unload);
    }

    componentDidMount = () => {
        this.getCase();
        this.props.dispatch(loadUsers());
        let url = "/api/fetch_cap_map/";
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                let cap_map = response.data;
                console.log("cap_map", cap_map)
                for (let i = 0; i < cap_map.length; i++) {
                    if (cap_map[i]["search_key"] === this.state.currCase.cap_report_type) {
                        console.log("xxx")
                        cap_map[i]["search_key"] = this.state.currCase.cap_report
                    }
                }
                this.setState({
                    cap_map: cap_map
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    unload = (e) => {
        let url = "/api/open_cap_edit/"
        let case_id = this.state.currCase.case_id
        let data = {
            'case_id': case_id,
            'user_id': parseInt(cookie.load('user_id')),
        };
        axios.post(url, querystring.stringify(data), { headers: { Authorization: AuthHeader() } })
            .then(response => {
                }
            );
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevState.CAPContent !== this.state.CAPContent){
            this.saveCAPReport();
        }
    }

    componentWillUnmount = () => {
        this.releaseCAPEditAccess()
    }

    getCAPReportEditStatus = () => {
        let url = "/api/get_cap_edit_status/"
        let case_id = this.state.currCase.case_id
        let data = {
            'case_id': case_id,
            'user_id': parseInt(cookie.load('user_id')),
        };
        axios.post(url, querystring.stringify(data), { headers: { Authorization: AuthHeader() } })
            .then(response => {
                    if(response.data["can_edit"] === 0){
                        this.setState({
                            can_edit: false,
                            editing_user: response.data["editing_user"]
                        })
                    }
                }
            );
    }

    releaseCAPEditAccess = () => {
        let url = "/api/open_cap_edit/"
        let case_id = this.state.currCase.case_id
        let data = {
            'case_id': case_id,
            'user_id': parseInt(cookie.load('user_id')),
        };
        axios.post(url, querystring.stringify(data), { headers: { Authorization: AuthHeader() } })
            .then(response => {
                }
            );
    }

    getOverwriteAccess = () => {
        let url = "/api/overwrite_cap_edit/"
        let case_id = this.state.currCase.case_id
        let data = {
            'case_id': case_id,
            'user_id': parseInt(cookie.load('user_id')),
        };
        axios.post(url, querystring.stringify(data), { headers: { Authorization: AuthHeader() } })
            .then(response => {
                    this.getCAPReportEditStatus();
                    this.setState({
                        can_edit:true,
                    })
                }
            );
    }

    getReArchiveDays = () => {
        let url = `/api/get_rearchive_days/`
        axios.get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
            .then(response => {
                console.log("resres",response)
                this.setState({
                    rearchiveDays: parseInt(response.data.rearchive_days),
                    archiveDays: parseInt(response.data.archive_days),
                })
            }).catch(err => {
            console.log(err)
        })
    }

    toggleInfoEdit = () => {
        let editable = this.state.infoEditable;
        this.setState({
            infoEditable: !editable,
        })
    }

    getCase = () => {
        this.setState({
            hasCaseThrownError: true,
            hasFilesThrownError: true,
            isFetchingCase: true,
            isFetchingFiles: true,
        });
        let url = `/api/case/` + this.props.case + `/`;
        axios
            .get(url, { headers: { Authorization: AuthHeader() }, 'Cache-Control': 'no-cache' })
            .then(response => {
                this.setState({
                    currCase: response.data,
                    isFetchingCase: false,
                    hasCaseThrownError: false,
                    patient_name :response.data.patient_name,
                    case_id : response.data['case_id'],
                    gender: response.data.age_and_sex.split('/')[1],
                    priority: response.data['priority'],
                    receiving_date: response.data['receiving_date'],
                    reporting_date: response.data['reporting_date'],
                    age_and_sex: response.data['age_and_sex'],
                    consulting_doctor: response.data['consulting_doctor'],
                    registered_location: response.data['registered_location']
                });

				let caseFileList = response.data.files.map(caseFile => {
					let newImage = {
						uid: caseFile.id,
						name: caseFile.file.split('/').pop(),
						status: 'done',
						url: caseFile.file,
					}
					return newImage;
				});

                this.getCaseSlides(response.data);

                this.get_comments();
                this.getCAPReportEditStatus();
                this.setState({
                    caseFileList: caseFileList,
                    isFetchingFiles: false,
                    hasFilesThrownError: false,
                })

            })
            .catch(err => {
                message.error("Cases could not be retrieved. Contact Admin.");
                this.setState({
                    currCase: [],
                    caseFileList: [],
                    isFetchingCase: false,
                    isFetchingFiles: false,
                    isFetchingSlides: false,
                    isFetchingPendingScans: false,
                    isFetchingFilesSource: false,
                    hasSlidesThrownError: true,
                    hasPendingScansThrownError: true,
                    hasCaseThrownError: true,
                    hasFilesThrownError: true,
                    hasFilesSourceThrownError: true,
                });
                console.log(err);
            });
    }

    getCaseSlides = (currCase) => {
        this.setState({
            hasSlidesThrownError: true,
            isFetchingSlides: true,
            isFetchingPendingScans: true,
            hasPendingScansThrownError: true,
        });
        let url = `/api/nonpagslides/?case_id=` + currCase.id;
        axios
            .get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
            .then(response => {
                let allSlidesUpdated = {};
                for (let i = 0; i < response.data.length; i++) {
                    allSlidesUpdated[i] = response.data[i];
                }

                this.setState({
                    caseScans: allSlidesUpdated,
                    caseScansArray: response.data,
                    selectedRowKeys: [],
                    previewVisible: false,
                    showFile: false,
                    previewFile: '',
                    isFetchingSlides: false,
                    hasSlidesThrownError: false,
                })
            })
            .catch(err => {
                message.error("Slides could not be retrieved. Contact Admin.");
                console.log(err);
                this.setState({
                    caseScans: {},
                    caseScansArray: [],
                    selectedRowKeys: [],
                    previewVisible: false,
                    showFile: false,
                    previewFile: '',
                    isFetchingSlides: false,
                    isFetchingPendingScans: false,
                })
            });
    }

    get_comments = () => {
        let url = '/api/microscopy_observations/?case_id=' + this.state.currCase.id;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                let data = response.data;
                for (let i = 0; i < data.length; i++) {
                    data[i].key = data[i].id;
                    let modifiedValue = decodeStringWithNewLine(data[i].observation);
                    data[i].observation_original = data[i].observation;
                    data[i].observation = modifiedValue;
                }
                this.setState({
                    comments: data,
                    isFetchingComments: false,
                    hasCommentsThrownError: false,
                });
            })
            .catch(err => {
                console.log("Failed request", err);
                this.setState({
                    comments: [],
                    isFetchingComments: false,
                });
            });
    }

    handleUpload = (morphle_id, id, allIds, uploadDebug, uploadDebugOnly) => {
        this.props.dispatch(startUpload(morphle_id, id, allIds, uploadDebug, uploadDebugOnly));
    }

    handleDelete = (slide, button) => {
        if (button) {
            let newCaseScans = {};
            let newCaseScansArray = [];
            for (let i = 0; i < this.state.caseScansArray.length; i++) {
                if (slide.id !== this.state.caseScansArray[i].id) {
                    newCaseScans[i] = this.state.caseScans[i];
                    newCaseScansArray.push(this.state.caseScansArray[i]);
                }
            }
            this.setState({
                caseScans: newCaseScans,
                caseScansArray: newCaseScansArray
            });
        }
        this.props.dispatch(deleteSlide(slide, this.props.urlState));
    }

    starSelectedRows = () => {
        this.state.selectedRowKeys.map((value, index) => {
            this.handleStarButton(this.state.caseScans[value], true);
        });
    }

    uploadSelectedRows = () => {
        this.state.selectedRowKeys.map((value, index) => {
            let slide = this.state.caseScans[value];
            this.props.dispatch(startUpload(slide.morphle_id, slide.id, this.props.allIds, false, false));
        });
    }

    tileSelectedRows = () => {
        this.state.selectedRowKeys.map((value, index) => {
            this.props.dispatch(startTiling(this.state.caseScans[value].morphle_id, this.props.allIds));
        });
    }

    unstarSelectedRows = () => {
        this.state.selectedRowKeys.map((value, index) => {
            this.handleStarButton(this.state.caseScans[value], false);
        });
    }

    deleteSelectedRows = (e) => {
        this.state.selectedRowKeys.map((value, index) => {
            if (this.state.caseScans[value] === undefined) return;
            this.handleDelete(this.state.caseScans[value], false);
        });
        let newCaseScans = {};
        let newCaseScansArray = [];
        for (let i = 0; i < this.state.caseScansArray.length; i++) {
            if (!this.state.selectedRowKeys.includes(i)) {
                newCaseScans[i] = this.state.caseScans[i];
                newCaseScansArray.push(this.state.caseScansArray[i]);
            }
        }
        this.setState({
            caseScans: newCaseScans,
            caseScansArray: newCaseScansArray
        });
        message.loading("Scheduling slides for Deletion");
    }

    handleStarButton = (slide, input) => {
        let newCaseScans = this.state.caseScans;
        let newCaseScansArray = this.state.caseScansArray;
        let index = newCaseScansArray.findIndex((item) => slide.id === item.id);
        newCaseScans[index].starred = input;
        newCaseScansArray[index].starred = input;
        this.setState({
            caseScans: newCaseScans,
            caseScansArray: newCaseScansArray
        });
        let val = {
            morphle_id: slide.morphle_id,
            path: slide.path,
            date: slide.date,
            specimen_type: slide.specimen_type,
            name: slide.name,
            starred: input,
            displayOrder: slide.displayOrder
        };
        this.props.dispatch(slideUpdateAlert(slide.id, val));
    };

    handleNameChange = (slide, newName) => {
        let newCaseScans = this.state.caseScans;
        let newCaseScansArray = this.state.caseScansArray;
        let index = newCaseScansArray.findIndex((item) => slide.id === item.id);
        newCaseScans[index].name = newName;
        newCaseScansArray[index].name = newName;
        this.setState({
            caseScans: newCaseScans,
            caseScansArray: newCaseScansArray
        });
        let val = {
            morphle_id: slide.morphle_id,
            path: slide.path,
            date: slide.date,
            specimen_type: slide.specimen_type,
            name: newName,
            starred: slide.starred,
            displayOrder: slide.displayOrder
        };
        this.props.dispatch(slideUpdateAlert(slide.id, val));
    };

    openDeleteModal = () => {

        var deletedIDs = "";

        this.state.selectedRowKeys.map((value, index) => {
            if (this.state.caseScans[value] === undefined) return;
            if (index !== this.state.selectedRowKeys.length - 1) deletedIDs += (this.state.caseScans[value] || {}).name + ", ";
        });

        deletedIDs += this.state.caseScans[this.state.selectedRowKeys[this.state.selectedRowKeys.length - 1]].name;

        const deleteRowsHandle = this.deleteSelectedRows;

        Modal.confirm({
            title: 'Following scans will be deleted. Confirm?',
            icon: <InfoCircleOutlined></InfoCircleOutlined>,
            content: (<Row>{deletedIDs}</Row>),
            onOk() { deleteRowsHandle() },
            onCancel() { }
        });
    }

    removeFromCase = () => {
        let slides = []
        this.state.selectedRowKeys.map((value, index) => {
            if (this.state.caseScans[value] === undefined) return;
            slides.push(this.state.caseScans[value].id)
        });
        let url = `/api/remove_slides_from_case/?slides=${slides}`
        axios.get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
            .then(response => {
                if (response.status === 200) {
                    let caseScans = this.state.caseScansArray;
                    slides.map((slideValue, slideIndex) => {
                        caseScans.map((caseValue, caseIndex) => {
                            if (slideValue === caseValue.id) {
                                caseScans.splice(caseIndex, 1)
                            }
                        })
                    })
                    this.setState({
                        caseScansArray: caseScans,
                        selectedRowKeys: [],
                    })
                    message.success("Successfully removes slides");
                } else {
                    message.error("Error removing slides. Please contact Admin");
                }
            })
            .catch(err => {
                message.error("Error removing slides. Please contact Admin")
                console.log("Error removing slide. Please contact Admin");
            })
    }

    retrieveSelectedRows = () => {
        let count = 0;
        this.state.selectedRowKeys.map((value, index) => {
            let slide = this.state.caseScans[value];
            if(slide.availability_status === 1){
                count +=1 ;
                this.handleRetrieveSlide(slide);
            }
        });

        if (count === 0){
            message.error("No scans to be retrieved");
        }
    }

    getRowActionsIfAtleastOneRowSelected = () => {
        if (this.state.caseScansArray.length > 0) {
            return this.state.selectedRowKeys.length > 0 ?
                <ButtonGroup key={1}>
                    <Button onClick={() => { this.setState({ selectedRowKeys: Object.keys(this.state.caseScans).map((value) => parseInt(value)) }) }}>
                        Select All Scans
                    </Button>
                    <Button key={0} onClick={() => { this.setState({ selectedRowKeys: [] }) }}>
                        Cancel Selection
                        </Button>
                    {cookie.loadAll().superuser === "true" ?
                        <Button key={1} onClick={this.tileSelectedRows}>
                            Start Tiling
                        </Button> : undefined}
                    {cookie.loadAll().deployment_mode === 'offline' ?
                        <Button key={-1} onClick={this.uploadSelectedRows}>
                            Upload
                            <CloudUploadOutlined style={{ fontSize: "13px" }} />
                        </Button> : undefined}
                    <Button key={2} onClick={this.starSelectedRows}>
                        Star
                            <StarFilled style={{ color: "#f5667b", fontSize: "13px" }} />
                    </Button>
                    <Button key={3} onClick={this.unstarSelectedRows}>
                        Unstar
                            <StarFilled style={{ fontSize: "13px" }} />
                    </Button>
                    {cookie.loadAll().is_staff === "true" ?
                        <Button key={4} onClick={this.openDeleteModal}>
                            Delete
                                <DeleteFilled style={{ fontSize: "13px" }} />
                        </Button> : undefined}
                    <Button key={5} onClick={this.removeFromCase}>
                        Remove From Case
                        <StopOutlined />
                    </Button>
                </ButtonGroup> :
                <>
                <Col span={16}>
                    <Button onClick={() => { this.setState({ selectedRowKeys: Object.keys(this.state.caseScans).map((value) => parseInt(value)) }) }}>
                        Select All Scans
                    </Button>
                </Col>
                <Col span={2}>
                    <Select
                        value={this.state.sortingMethod}
                        placeholder="Sort List"
                        onChange={this.handleSortingMethodChange}
                        style={{width:230}}
                    >
                        <Option value="name">
                            {" "}
                            A-Z
                        </Option>
                        <Option value="-name">
                            {" "}
                            Z-A
                        </Option>
                        <Option value="date_time">
                            Oldest First
                        </Option>
                        <Option value="-date_time">
                            Recent First
                        </Option>
                        <Option value="number">
                            By Slide Number
                        </Option>
                    </Select>
                </Col>
              </>
        } else {
            return null;
        }

    }

    getRowActions = () => {
        return <Row>
            <Col>
                <span key={0} style={{ marginRight: "10px" }}>
                    {this.state.selectedRowKeys.length > 0 ? "What do you want to do with the selected scans ? " : ""}
                </span>
                {this.getRowActionsIfAtleastOneRowSelected()}
            </Col>
        </Row>
    }

    updatePage = (pageNumber) => {
        historyMP.push('/' + globalUrlPrefix + '/dashboard?page=' + pageNumber);
        this.props.dispatch(changePage(pageNumber, this.props.urlState));
    }

	handlePreview = file => {
		let file_list = this.state.caseFileList
		let idx;
		for (let i = 0; i < file_list.length; i++) {
			if (file_list[i]['uid'] === file.uid) {
				idx = i;
				this.setState({
					carousel_index: i,
				})
				break;
			}
		}
		let fileExtension = getFileExtension(file.name);
		if (fileExtension == 'jpg' || fileExtension == 'jpeg' || fileExtension == 'png') {
			this.setState({
                showFile: true,
            });
        } else {
            this.setState({
                showFile: false,
            });
        }
        this.setState({
            previewFile: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handleScreenshotPreview = (slide) => {
        this.setState({ 
            screenshotVisible: true, 
            screenshots: slide.screenshots 
        });
    }

    handleScreenshotCancel = () => {
        this.setState({
            screenshotVisible: false
        })
    }

    handleSlideDetailsCase = (slide, input) => {
        this.setState({
            selectedSlide: slide,
            slideDetailsModelVisible: true,
        });
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    handleChangeCaseFile = ({ fileList, file }) => {
        let caseFileList = _.map(fileList, (caseFile) => {
            if (caseFile.response) {
				let newImage = {
					uid: caseFile.response.id,
					name: caseFile.response.file.split('/').pop(),
					status: 'done',
					url: caseFile.response.file,
				}
				return newImage;
			} else {
                return caseFile;
            }
        });
        this.setState({
            caseFileList: caseFileList
        });
    }

    handleChangeUploadTRF = (info) => {
        if (info.file.status === 'done') {
            let url = `/api/case/` + this.props.case + `/`;
            axios
                .get(url, { headers: { Authorization: AuthHeader() }, 'Cache-Control': 'no-cache' })
                .then(response => {
                    this.setState({
                        currCase: response.data,
                        isFetchingCase: false,
                        hasCaseThrownError: false,
                    });
                })
        }
    }

    onChangeCAPContent = (value) => {
        this.setState({
            CAPContent: value
        })
    }

	beforeUpload(file, fileList) {
		if (file.type === "application/pdf" || file.type.substring(0, 5) === "image") {
			return true;
		} else {
			message.error('Invalid file type selected!!');
			return false;
		}
	}

	removeFileFromCase = file =>
		axios.delete(`/api/files/${file.uid}/`, AxiosConfig())

	handleSortingMethodChange = (value) => {
		this.setState({
			sortingMethod: value,
		})
	}

	getTableSource = (allSlides, getIntegersfromString, sortingMethod) => {
		let allSlidesList = []
		let sortedAllSlides = []

        switch (sortingMethod) {
            case 'name':
                sortedAllSlides = getLexicographicalSorting(allSlides)
                break;
            case '-name':
                sortedAllSlides = getReverseLexicographicalSorting(allSlides)
                break;
            case 'number':
                sortedAllSlides = getNumericalSorting(allSlides, getIntegersfromString)
                break;
            case 'date_time':
                sortedAllSlides = getChronologicalSorting(allSlides)
                break;
            case '-date_time':
                sortedAllSlides = getReverseChronologicalSorting(allSlides)
                break;
            default:
                break;
        }

        sortedAllSlides.map((key) => {
            let slide = allSlides[key];
            if (slide.slide_details === undefined || slide.slide_details === "") {
                slide.slide_details = "-";
            }
            if (!slide.isDeleted) {
                allSlidesList.push({
                    key: parseInt(key),
                    id: slide.id,
                    slide_details: slide.slide_details,
                    unread: slide.unread,
                    type: openInNewTabType.SLIDE,
                    slideCard: this.getSlideCard(slide),
                })
            }
        });
        return allSlidesList;
    }

    handleRetrieveSlide = (slide) => {
        let url = `/api/retrieve_from_glacier/?slide_id=${slide.id}`
        axios.get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
            .then(response => {
                let val = {
                    morphle_id: slide.morphle_id,
                    path: slide.path,
                    date: slide.date,
                    specimen_type: slide.specimen_type,
                    name: slide.name,
                    starred: slide.starred,
                    displayOrder: slide.displayOrder,
                    availability_status: 2,
                };
                this.props.dispatch(slideUpdateAlert(slide.id, val));
            }).catch(err => {
            console.log(err)
        })
    }

    getSlideCard = (slide) => {
        return <div>
            <Row>
                <Col span={3} style={{ marginTop: 35 }}>
                    <Row>
                        <Col span={8}>
                            {getStarredComponent(slide, this.handleStarButton)}
                        </Col>
                        {/*TODO: @Spandan glacier component was commented out as it wasnt needed at the time*/}
                        {/*<Col span={8}>*/}
                        {/*    /!*{getUploadComponent(slide, this.props.allIds, this.handleUpload)}*!/*/}
                        {/*    {getGlacierComponent(slide, this.handleRetrieveSlide, this.state.archiveDays, this.state.rearchiveDays)}*/}
                        {/*</Col>*/}
                        {cookie.loadAll().superuser === "true" ?
                            <Col span={8}>
                                {getTilingComponent(slide, this.props.allIds, this.props.dispatch)}
                            </Col> :
                            null
                        }
                    </Row>
                </Col>
                <Col span={8} style={{ marginTop: 10 }}>
                    <Row>
                        {slide.unread ?
                            <b> {slide.section_id === "" ? slide.name : slide.name + "," + slide.section_id}</b> :
                            <span style={{ fontWeight: 400 }}> {slide.section_id === "" ? slide.name : slide.name + "," + slide.section_id}</span>
                        }
                    </Row>
                    {slide.case_id.length > 0 ?
                        <Row style={{ marginTop: 5, fontSize: 13 }}>
                            <span>{"CASE ID: " + slide.case_id}</span>
                        </Row> :
                        this.state.currentHoverRowID === slide.id && cookie.loadAll().is_staff === "true" ?
                            <Row style={{ marginTop: 5 }}>
                                {getAssignCaseButtonComponent(slide, this.handleAssignCase)}
                            </Row> :
                            null
                    }
                    <Row style={{ marginTop: 10 }}>
                        {getTagsComponent(slide, this.props.dispatch, this.props.urlState)}
                    </Row>
                </Col>
                <Col span={4}>
                    {getPreviewComponent(slide, this.is_cloud, this.isMobile)}
                </Col>
                <Col span={2}>
                    {getLabelComponent(slide, this.is_cloud, this.isMobile)}
                </Col>
                <Col span={slide.screenshots.length === 0 ? 7 : 6}>
                    {this.state.currentHoverRowID === slide.id && cookie.loadAll().is_staff === "true" ?
                        <div>
                            <Row style={{ marginTop: 10 }}>
                                <Col offset={cookie.loadAll().superuser === "true" ? 4 : cookie.loadAll().is_staff === "true" ? 16 : 20} span={4}>
                                    {getAssignCaseComponent(slide, this.handleAssignCase)}
                                </Col>
                                {cookie.loadAll().is_staff === "true" ?
                                    <Col span={4}>
                                        {getExportComponent(slide, this.props.allIds, this.props.dispatch)}
                                    </Col> :
                                    null
                                }
                                {cookie.loadAll().superuser === "true" ?
                                    <Col span={4}>
                                        {getDbEntryComponent(slide)}
                                    </Col> :
                                    null
                                }
                                {cookie.loadAll().superuser === "true" ?
                                    <Col span={4}>
                                        {getMorphleIDComponent(slide)}
                                    </Col> :
                                    null
                                }
                                {cookie.loadAll().superuser === "true" || (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_delete_slide_action ?
                                    <Col span={4}>
                                        {getDeleteComponent(slide, this.handleDelete)}
                                    </Col> :
                                    null
                                }
                            </Row>
                            <br />
                        </div> :
                        null
                    }
                    <Row style={this.state.currentHoverRowID === slide.id && cookie.loadAll().is_staff === "true" ? {} : { marginTop: 35 }}>
                        <Col offset={cookie.loadAll().superuser === "true" ? 0 : cookie.loadAll().is_staff === "true" ? 3 : 18} span={6}>
                            {slide.unread ?
                                <b>{slide.date}</b> :
                                <span>{slide.date}</span>
                            }
                        </Col>
                        {cookie.loadAll().superuser === "true" ?
                            <Col span={3}>
                                {getObjectiveComponent(slide, slideViewType.CASEVIEW)}
                            </Col> :
                            null
                        }
                        {cookie.loadAll().is_staff === "true" ?
                            <Col span={4}>
                                {slide.unread ?
                                    <b>{slide.scan_speed}</b> :
                                    <span>{slide.scan_speed}</span>
                                }
                            </Col> :
                            null
                        }
                        {cookie.loadAll().is_staff === "true" ?
                            <Col span={6}>
                                {slide.unread ?
                                    <b>{slide.scan_time}</b> :
                                    <span>{slide.scan_time}</span>
                                }
                            </Col> :
                            null
                        }
                    </Row>
                </Col>
                { slide.screenshots.length === 0 ? null :
                <Col span={1}>
                    <PictureOutlined onClick={(e) => {e.stopPropagation(); this.handleScreenshotPreview(slide)}} className="icon-hover" 
                        style={{marginTop: '25px', fontSize: '23px', marginLeft: '10px', padding: '10px'}} />
                </Col>}
            </Row>
        </div>
    }

    handleSave = row => {
        let newCaseScans = this.state.caseScans;
        let newCaseScansArray = this.state.caseScansArray;
        let index = newCaseScansArray.findIndex((item) => row.id === item.id);
        newCaseScans[index].slide_details = row.slide_details;
        newCaseScansArray[index].slide_details = row.slide_details;
        this.setState({
            caseScans: newCaseScans,
            caseScansArray: newCaseScansArray
        });
        this.sendDescToCloud(row.id, row.slide_details, newCaseScansArray[index].displayOrder);
    };

    sendDescToCloud = (slideId, desc, displayOrder) => {
        this.props.dispatch(descUpdateAlert(slideId, desc, displayOrder));
    };

    toAllSlidesPage = (e) => {
        this.setState({
            addSlideModalVisible: true,
        });
    }

    closeModal = () => {
        this.getCase();
        this.setState({
            addSlideModalVisible: false,
            slideDetailsModelVisible: false
        });
    }

    openTRF = (url) => {
        window.open(url, '_blank')
    }

    generateReport = () => {
        let url = `/api/get_pdf/?case_id=${this.state.currCase.case_id}&user_id=${cookie.loadAll().user_id}`;
        window.open(url);
    }

    handleCAPTemplateChange = (value) => {
        this.setState({
            selectedCAP: value
        })
        let template = ""
        let cap_map = this.state.cap_map
        console.log("capmap", this.state.currCase, this.state.cap_map)
        for (let i = 0; i < cap_map.length; i++) {
            if (cap_map[i]["search_key"] === value) {
                console.log(this.state.currCase["cap_report_type"])
                if (cap_map[i]["search_key"] === this.state.currCase.cap_report_type) {
                    template = this.state.currCase.cap_report
                }
                else {
                    template = this.state.cap_map[i]["template"]
                    break;
                }
            }
        }
        this.setState({
            CAPContent: template
        })
    }

    // CAPContent: '',
    //         cap_map: [],
    //         selectedCAP : '',
    saveCAPReport = () => {
        let report = this.state.CAPContent
        let report_type = this.state.selectedCAP
        let case_id = this.state.currCase.case_id
        let url_ = '/api/save_cap_report/'
        let data = {
            'report': report,
            'case_id': case_id,
            'report_type': report_type,
            'user_id': parseInt(cookie.load('user_id')),
        };
        let url = '/api/save_cap_report/';
        axios.post(url_, querystring.stringify(data), { headers: { Authorization: AuthHeader() } })
            .then(response => {
                    // message.success("Added Report to Case " + case_id)
                    if(parseInt(response.data["can_edit"]) === 0){
                        message.info(`Couldnt edit while ${response.data["editing_user"]} is editing`)
                        this.setState({
                            can_edit:false,
                            editing_user: response.data["editing_user"],
                        })
                    }
                }
            );
    }

    updateCase = () => {
        let val = {
            id: this.state.currCase.id,
            case_id: this.state.case_id.toUpperCase(),
            patient_name: this.state.patient_name,
            gender :this.state.gender,
            priority: this.state.priority,
            receiving_date: this.state.receiving_date,
            reporting_date: this.state.reporting_date,
            consulting_doctor: this.state.consulting_doctor,
            registered_location: this.state.registered_location,
        }
        let url = '/api/update_case/'
        axios.post(url, querystring.stringify(val), {headers: { Authorization: AuthHeader()}})
            .then(response => {
                message.success("Case Updated Successfully")
                this.getCase();
            })
            .catch(err => {
                message.error("Failed to Update Case. Contact Admin");
            })
        this.toggleInfoEdit();
    }

    onChangeGender = (value) => {
        console.log("change", value)
        this.setState({
            gender: value,
        });
    }

    onChangePriority = (value) => {
        this.setState({
            priority: value,
        })
    }

    onChangeReceivingDate = (date, dateString) => {
        this.setState({
            receiving_date: dateString
        });
        console.log("change", dateString, date)
    }

    onChangeReportingDate = (date, dateString) => {
        this.setState({
            reporting_date: dateString
        });
        console.log("change", dateString, date)
    }

    handleRegisteredLocationChange = (value) => {
        this.setState({
            registered_location: value,
        });
    }

    onChangeCaseId = (event) => {
        this.setState({
            case_id: event.target.value,
        })
        console.log("change", event.target.value)
    }

    onChangePatientName = (event) => {
        this.setState({
            patient_name : event.target.value,
        })
        console.log("change", event.target.value)
    }

    onChangeConsultingDoctor = (event) => {
        this.setState({
            consulting_doctor: event.target.value,
        })
    }

    getRegisteredLocationOptions = () => {
        return [
            <Option key={1} value={"Vasai (Main Centre)"}>Vasai (Main Centre)</Option>
        ];
    }

    handleUserChange = (value) => {
        this.setState({
            selected_users: value,
        })
    }

    filterOptionUsers = (inputValue, option) => {
        return option.props.children.toLowerCase().includes(inputValue.toLowerCase())
    }

    getUserOptions = () => {
        console.log("xx All users ",  this.props.allUsers);
        return Object.keys(this.props.allUsers).map((key) => {
            let value = this.props.allUsers[key];
            console.log("xx user ",  value);
            return <Option key={value.id} value={value.id}>{value.first_name + ' ' + value.last_name}</Option>
        });
    }

    handleChangeAssignUsers = (currCases, input, selectedRows=[]) => {

        this.props.dispatch(assignUsersUpdateAlert(currCases, [], input, selectedRows));
        message.success("User assigned successfully")
        this.getCase();
    };

    handleCancelAssignedUser = (event, currCase, user_id) => {
        event.stopPropagation();
        let val = {
            id: currCase.id,
            case_id: currCase.case_id,
            displayOrder: currCase.displayOrder,
            user_id: user_id
        };
        this.props.dispatch(removeUserUpdateAlert(currCase.id, val, this.props.urlState));
        this.getCase();
        // window.location.reload();
    }

    getAssignedNamesComponent = (caseInfo) => {
        return caseInfo.assigned_users.map((user, index) => {
            const full_name = user.first_name + ' ' + user.last_name;
            const isLongUserName = full_name.length > 20;
            let color = 'blue';
            const userNameElem = (
                <Tag key={user.id} color={color} onClick={(event) => event.stopPropagation()} style={{ cursor: 'pointer' }}>
                    {isLongUserName ? `${full_name.slice(0, 20)}...` : full_name}
                </Tag>
            );
            const finalUserTag = isLongUserName ? <Tooltip title={full_name} key={user.id}>{userNameElem}</Tooltip> : userNameElem;
            return <Popconfirm
                title={"Remove user " + full_name + " from this case?"}
                onConfirm={(event) => this.handleCancelAssignedUser(event, caseInfo, user.id)}
                okText="Yes"
                palcement="topRight"
                style={{ padding: 20 }}
                cancelText="No"
                onCancel={(event) => {
                    event.stopPropagation();
                }}>
                {finalUserTag}
            </Popconfirm>
        });
    }


    sumitUsersAndStatus = () => {
        // if (this.state.selected_users.length > 0) {
        //   console.log(this.props.allCases)
        //   this.state.selectedRowKeys.map((value, index) => {
        //     this.handleChangeAssignUsers(this.props.allCases[value], this.state.selected_users, this.state.selectedRowKeys);
        //   });
        // }

        if (this.state.selected_users.length > 0) {
            console.log(this.props.allCases)
            let all_cases = []

            this.handleChangeAssignUsers([this.state.currCase.id,], this.state.selected_users)
        }
        this.setState({
            selected_users: [],
        })
        // if (this.state.case_status !== -1) {
        //   this.state.selectedRowKeys.map((value, index) => {
        //     this.handleChangeStatus(this.props.allCases[value], this.state.case_status, this.state.selectedRowKeys);
        //   });
        // }

    }

    render() {

        const comments_columns = [
            {
                title: 'User',
                dataIndex: 'user_name',
                key: 'user_name',
                width: 100,
            },
            {
                title: 'Scan Name',
                dataIndex: 'slide_name',
                key: 'slide_name',
                width: 200,
            },
            {
                title: 'Observation',
                dataIndex: 'observation',
                key: 'observation',
            },
        ];

        let assigned_users_component=null
        if(this.state.currCase !== [] && this.state.currCase.assigned_users !== undefined){
            console.log(this.state.currCase)
            assigned_users_component = this.getAssignedNamesComponent(this.state.currCase)
        }

        if (this.state.selectedCAP === '' && !(this.state.currCase instanceof Array)) {
            console.log("x", (this.state.currCase))

            if (this.state.currCase.cap_report_type !== '') {
                this.setState({
                    selectedCAP: this.state.currCase.cap_report_type,
                    CAPContent: this.state.currCase.cap_report
                })
            }
        }
        let cap_report_name_options = (this.state.cap_map !== undefined || this.state.cap_map !== []) ?
            this.state.cap_map.map((item, index) => (
                <Option key={item.search_key} value={item.search_key}>
                    {item.display_name}
                </Option>
            )) : undefined;

        let overallFetchingState = (this.state.isFetchingCase && this.state.isFetchingComments && this.state.isFetchingFiles
            && this.state.isFetchingPendingScans && this.state.isFetchingSlides);

        let overallErrorState = (this.state.hasCaseThrownError && this.state.hasCommentsThrownError && this.state.hasFilesThrownError
            && this.state.hasPendingScansThrownError && this.state.hasSlidesThrownError);

        let tableSource = [];

        tableSource = this.getTableSource(this.state.caseScansArray, this.getIntegersfromString, this.state.sortingMethod);

        let finalColumns = [{
            dataIndex: 'slideCard',
            key: 'slideCard',
        }];

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys: selectedRowKeys
                })
            },
            selectedRowKeys: this.state.selectedRowKeys
        };

        const onRow = (record, rowIndex) => {
            return {
                onContextMenu: (event) => {
                    event.preventDefault();
                    this.props.dispatch(makeMenuVisible({ visible: true, x: event.clientX, y: event.clientY, record: record, height: this.props.height }));
                },
                onMouseEnter: (event) => {
                    event.preventDefault();
                    this.setState({
                        currentHoverRowID: record.id,
                    });
                },
                onMouseLeave: (event) => {
                    event.preventDefault();
                    this.setState({
                        currentHoverRowID: -1,
                    });
                }
            };
        }

        let rowActions = this.isMobile ? undefined :
            [
                <Divider key={3} />,
                <Row key={0} className="row-actions">
                    {this.getRowActions()}
                </Row>
            ];

        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };

        finalColumns = finalColumns.map(col => {
            if (col.dataIndex === 'tagsComponent') {
                return col;
            } else {
                return {
                    ...col,
                    onCell: record => ({
                        onClick: (event) => {
                            if (event.ctrlKey) {
                                linkViewerNewTab(record);
                            } else {
                                linkViewer(record);
                            }
                        },
                    }),
                };
            }
        });

        if (overallFetchingState || overallErrorState) {
            this.props.setTabsView(false);
        } else {
            this.props.setTabsView(true);
        }

        var toolbarOptions = [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ];

        let editorButton;

        if(cookie.loadAll().is_staff === "true"){
            if(!this.state.infoEditable){
                editorButton = <Button style={{display: 'inline-block',float: 'right', marginTop:'-10px'}} onClick={this.toggleInfoEdit}>
                    <EditOutlined />
                </Button>
            }else{
                editorButton = <Button style={{display: 'inline-block',float: 'right',  marginTop:'-10px'}} onClick={this.updateCase}>
                    SAVE
                </Button>
            }
        }

        let infoComponent;

        if(!this.state.infoEditable){
            infoComponent =  <div><div className="heading-style sub-heading-style">
                <b className="case-scans-heading-style">Details</b>
                {/* <Button style={{display: 'inline-block',float: 'right'}} onClick={this.toggleInfoEdit}>
                <EditOutlined />
            </Button> */}
                {editorButton}
            </div>
                <div>
                    <br />
                    <Row>
                        <Col offset={1}>
                            {this.state.isFetchingCase ? getLoadingComponent() :
                                this.state.hasCaseThrownError ? getErrorComponent("Not able to retrieve the case details.") :
                                    <div>
                                        <Descriptions size="small" column={3}>
                                            <Descriptions.Item label={<b>Case ID</b>}>{this.state.currCase.case_id}</Descriptions.Item>
                                            <Descriptions.Item label={<b>Patient's Name</b>}>{this.state.currCase.patient_name}</Descriptions.Item>
                                            <Descriptions.Item label={<b>DOB</b>}>{this.state.currCase.age_and_sex}</Descriptions.Item>
                                            <Descriptions.Item label={<b>Receiving Date</b>}>{this.state.currCase.receiving_date}</Descriptions.Item>
                                            <Descriptions.Item label={<b>Reporting Date</b>}>{this.state.currCase.reporting_date}</Descriptions.Item>
                                            <Descriptions.Item label={<b>Consulting Doctor</b>}>{this.state.currCase.consulting_doctor}</Descriptions.Item>
                                            <Descriptions.Item label={<b>Reg. Location</b>}>{this.state.currCase.registered_location}</Descriptions.Item>
                                            <Descriptions.Item label={<b>Priority</b>}>{this.state.currCase.priority}</Descriptions.Item>
                                            {this.state.currCase.trf_uploaded ?
                                                <Descriptions.Item label={null}>
                                                    <Tag color="green" style={{ cursor: 'pointer' }} onClick={() => this.openTRF("/case-files/" + this.state.currCase.id + "/case_trf/" + this.state.currCase.id + "_trf.pdf")}>
                                                        View TRF
                                                    </Tag>
                                                </Descriptions.Item>
                                                :
                                                <Descriptions.Item label={null}>
                                                    <Upload
                                                        action="/api/upload_trf/"
                                                        headers={{ Authorization: AuthHeader() }}
                                                        maxCount={1}
                                                        data={{ 'case_id': this.state.currCase.case_id }}
                                                        accept="image/*, .pdf"
                                                        beforeUpload={this.beforeUpload}
                                                        onChange={this.handleChangeUploadTRF}
                                                    >
                                                        <div>
                                                            <UploadOutlined />
                                                            <b> Upload TRF</b>
                                                        </div>
                                                    </Upload>
                                                </Descriptions.Item>
                                            }
                                            {/* <Descriptions.Item label={null}>
                                        <Tag color="green" style={{ cursor: 'pointer' }} onClick={this.generateReport}>
                                            Generate Report
                                        </Tag>
                                    </Descriptions.Item> */}

                                        </Descriptions>
                                    </div>
                            }
                        </Col>
                    </Row>
                    <br />
                </div></div>
        }

        else{
            infoComponent = <div>
                <div className="heading-style sub-heading-style">
                    <b className="case-scans-heading-style">Details</b>
                    {/* <Button style={{display: 'inline-block',float: 'right'}} onClick={this.toggleInfoEdit}>
                                        <EditOutlined />
                                    </Button> */}
                    {editorButton}
                </div>
                <div>
                    <br />
                    <Row>
                        <Col offset={1}>
                            {this.state.isFetchingCase ? getLoadingComponent() :
                                this.state.hasCaseThrownError ? getErrorComponent("Not able to retrieve the case details.") :
                                    <div>
                                        <Descriptions size="small" column={3}>
                                            <Descriptions.Item label={<b>Case ID</b>}>
                                                <Input onChange={this.onChangeCaseId} value={this.state.case_id}>
                                                </Input>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<b>Patient's Name</b>}>
                                                <Input onChange={this.onChangePatientName}
                                                       value={this.state.patient_name}></Input>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<b>Gender</b>}>
                                                <Select className="input-field-add-case"
                                                        value={this.state.gender}
                                                        placeholder="Select Status"
                                                        onChange={this.onChangeGender}
                                                >
                                                    <Option value="M">Male</Option>
                                                    <Option value="F">Female</Option>
                                                    <Option value="Others">Others</Option>
                                                </Select>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<b>Receiving Date</b>}>
                                                <DatePicker onChange={this.onChangeReceivingDate}
                                                            value={new moment(this.state.receiving_date)}
                                                            className="input-field-add-case"
                                                            allowClear={false}
                                                />
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<b>Reporting Date</b>}>
                                                <DatePicker
                                                    onChange = {this.onChangeReportingDate}
                                                    value={new moment(this.state.reporting_date
                                                    )}
                                                    className="input-field-add-case"
                                                    allowClear={false}
                                                />
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<b>Consulting Doctor</b>}>
                                                <Input onChange={this.onChangeConsultingDoctor}
                                                       value={this.state.consulting_doctor}></Input>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<b>Reg. Location</b>}>
                                                <Select className="input-field-add-case"
                                                        value={this.state.registered_location}
                                                        placeholder="Select Registered Location"
                                                        onChange={this.handleRegisteredLocationChange}
                                                >
                                                    {this.getRegisteredLocationOptions()}
                                                </Select>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<b>Priority</b>}>
                                                <Select className="input-field-add-case"
                                                        value={this.state.priority}
                                                        placeholder="Select Priority"
                                                        onChange={this.onChangePriority}
                                                >
                                                    <Option value="Medium">Medium</Option>
                                                    <Option value="High">High</Option>
                                                    <Option value="Low">Low</Option>
                                                </Select>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </div>
                            }
                        </Col>
                    </Row>
                    <br />
                </div>

            </div>
        }

        let editOverrideButton;
		if (this.state.can_edit) {
			editOverrideButton =
				<span style={{color: 'green', position: 'absolute', right: '0'}}> You are editing the report.</span>
		} else {
			editOverrideButton = <span style={{color: 'red', position: 'absolute', right: '0'}}>
                {`Can't edit as ${this.state.editing_user} is editing the report. To override access click     `}
				<Button onClick={this.getOverwriteAccess}>
                    OVERRIDE ACCESS
                </Button>
            </span>
		}

		let imageCarouselsDiv;
		imageCarouselsDiv = this.state.caseFileList.map(file =>
			<div style={{textAlign: 'center'}}>
				<img src={file.url}></img>
			</div>);

		let assign_comp = <div>
			<Row style={{marginTop: '10px'}}>
				<Col span={3}>
					<Select
						mode="multiple"
						value={this.state.selected_users}
						placeholder="Select Users"
						onChange={this.handleUserChange}
						filterOption={this.filterOptionUsers}
						style={{width: 200}}
					>
						{this.getUserOptions()}
					</Select>

                </Col>
                <Button style={{marginLeft:'10px'}} onClick={this.sumitUsersAndStatus} >
                    Submit
                </Button>
            </Row>
            <Row style={{marginTop:'10px'}}>
                {assigned_users_component}
            </Row>
        </div>;

        return (overallFetchingState) ?
            getLoadingComponent() :
            (overallErrorState) ?
                getErrorComponent() :
                <>
                    <div>

                        {infoComponent}

                        <div className="heading-style sub-heading-style">
                            <b className="case-scans-heading-style">Assign Users To Case</b>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            {assign_comp}
                        </div>

                        <br/>

                        <div className="heading-style sub-heading-style">
                            <b className="case-scans-heading-style">Case Scans</b>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            {cookie.loadAll().is_staff === "true" ?
                                <Button onClick={this.toAllSlidesPage}>
                                    Add Slides to Case
                                </Button> : null
                            }
                        </div>
                        <div>
                            <br />
                            <Row>
                                <Col>
                                    {this.state.isFetchingSlides ? getLoadingComponent() :
                                        this.state.hasSlidesThrownError ? getErrorComponent("Not able to retrieve slides of the case.") :
                                            <div>
                                                <Row key={0} className="fixed-controls-dashboard" id="fixed-controls-dashboard">
                                                    <Col span={24}>
                                                        {rowActions}
                                                        <Divider />
                                                    </Col>
                                                </Row>
                                                <Row key={1} className="scrollable-dashboard">
                                                    <Table
                                                        key={2}
                                                        loading={this.state.isFetching}
                                                        dataSource={tableSource}
                                                        columns={finalColumns}
                                                        pagination={false}
                                                        showHeader={false}
                                                        rowSelection={this.isMobile ? undefined : rowSelection}
                                                        onRow={onRow}
                                                        components={components}
                                                        scroll={{ y: '550px' }}
                                                        className="custom-table-height custom-table-selection"
                                                        rowClassName={(record, index) => (record.unread ? 'slide-table-rows custom-hover-table-box editable-row unread' : 'slide-table-rows custom-hover-table-box editable-row read')}
                                                    />
                                                </Row>
                                            </div>
                                    }
                                </Col>
                            </Row>
                            <br />
                        </div>
						{((JSON.parse(localStorage.getItem('morpheus_setting')) || {}).apps_installed || {}).cap_report ?
							<div>
								<div className="heading-style sub-heading-style">
									<b className="case-scans-heading-style">CAP Report</b>
								</div>
								<br/>
								<div>
									Biomarker Reporting Protocol &#160;
									<Select showSearch placeholder={this.state.selectedCAP} style={{width: 200}}
											onChange={this.handleCAPTemplateChange}
											disabled={!this.state.can_edit}>
										{cap_report_name_options}
									</Select>
									{editOverrideButton}
									<br/>
									<br/>
									<Row>
										<Col>
											<ReactQuill
												readOnly={!this.state.can_edit}
												modules={{
													// syntax: true,
                            toolbar: toolbarOptions
                        }}
                            theme="snow"
                            style={{ height: "400px" }}
                            formats={[
                            'header',
                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                            'list', 'bullet', 'indent',
                            'link', 'image',
                            'code-block'
                            ]}
                            value={this.state.CAPContent}
                            onChange={this.onChangeCAPContent}
                            >
                            </ReactQuill>

                            </Col>
                            </Row>
                            <br />
                            <br />
                            <br />
                            <br /></div>
                            </div>
                            : null}
                        <Divider style={{ backgroundColor: "#ffffff00" }} />
                        <SlideSuggestions modalVisible={this.state.addSlideModalVisible} currCase={this.state.currCase} closeModal={this.closeModal} />
                        <Modal visible={this.state.screenshotVisible} footer={null} onCancel={this.handleScreenshotCancel} width={1500}>
                            {getScreenshotCarousel(this.state.screenshots)}
                        </Modal>
                    </div>
                    <div>
                        <br />
                        <Row>
                            <Col>
                                {this.state.isFetchingFiles ? getLoadingComponent() :
                                    this.state.hasFilesThrownError ? getErrorComponent("Not able to contact server. Please contact admin.") :
                                        <div>
											<Upload
												listType="picture"
												action="/api/files/"
												headers={{Authorization: AuthHeader()}}
												fileList={this.state.caseFileList}
												data={{'case': this.state.currCase.id}}
												onPreview={this.handlePreview}
												onChange={this.handleChangeCaseFile}
												onRemove={this.removeFileFromCase}
												showUploadList={{
													showDownloadIcon: true,
													downloadIcon: <ToTopOutlined/>,
												}}
												className='upload-list-inline'
                                                accept="image/*, .pdf"
                                                beforeUpload={this.beforeUpload}
                                            >
                                                <div style={{ textAlign: 'center' }}>
                                                    <Button>
                                                        <UploadOutlined /> Upload Supporting Files
                                                    </Button>
                                                </div>
                                            </Upload>
                                            <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
                                                {this.state.showFile ?
                                                    // <img alt="example" style={{ width: '100%' }} src={this.state.previewFile} />
                                                    <Carousel selectedItem={this.state.carousel_index}
                                                              centerMode={true} showThumbs={false} useKeyboardArrows={true}
                                                              autoFocus={true} showIndicators={false}>
                                                        {imageCarouselsDiv}
                                                    </Carousel>
                                                    : <div>No preview available</div>
                                                }
                                            </Modal>
                                            {
                                                this.state.caseFileList.length > 0 ?
                                                    null :
                                                    <div>
                                                        <Empty description="No Files Found" />
                                                    </div>
                                            }
                                        </div>
                                }
                            </Col>
                        </Row>
                        <br />
                    </div>
                    <div>
                        <Button type="primary" style={{ cursor: 'pointer' }} onClick={this.generateReport}>
                            Generate Report
                        </Button>
                    </div>

                    <Divider style={{ backgroundColor: "#ffffff00" }} />
                    <SlideSuggestions modalVisible={this.state.addSlideModalVisible} currCase={this.state.currCase} closeModal={this.closeModal} />
                    <SlideDetailsModal modalVisible={this.state.slideDetailsModelVisible} selectedSlide={this.state.selectedSlide} closeModal={this.closeModal} />
                </>
    }
}

const mapStateToProps = (state) => {

    return {
        allSlides: state.slidesReducer,
        allIds: Object.keys(state.slidesReducer),
        numPages: state.dashboardReducer.numPages,
        urlState: state.searchReducer,
        height: state.contextMenuReducer.popupProps.height,
        allUsers: state.caseStatusReducer.users,
    }
}

export default connect(mapStateToProps)(AllScansCaseView);
