import React from 'react';
import {CloseOutlined} from '@ant-design/icons';
import {Button, Col, Input, message, Modal, Row, Select} from 'antd';
import {connect} from 'react-redux'
import axios from 'axios';
import {AuthHeader} from '../../helper/auth.token';
import cookie from "react-cookies";

import querystring from "query-string";


class SlideSuggestions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            inputValue: '',
            name_suggestions: [],
            selectedSlideList: [],
        }

        this.is_cloud = cookie.loadAll().deployment_mode === 'cloud';
        this.isMobile = cookie.loadAll().isMobile == 'true';
        this.iOS = cookie.loadAll().iOS === "true";
    }

    typeAheadSearch = (value) => {
        this.setState({
            inputValue: value,
        });
        if (value.length >= 1) {
            var prefix = value;
            let url = "/api/typeahead_slide/?prefix=" + prefix;
            axios
                .get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    var res_list = [];
                    if(this.state.selectedSlideList.length > 0) {
                        for(let j = 0; j < response.data.length; j++) {
                            if (this.state.selectedSlideList.findIndex((item) => response.data[j].id === item.id) === -1) {
                                res_list.push(response.data[j]);
                            }
                        }
                    } else {
                        res_list = response.data;
                    }
                    var i=0;
                    for (i=0; i<res_list.length; i++){
                        res_list[i].display_text = res_list[i].display_text.replace(/^_/, "").toUpperCase();
                    }
                    this.setState({
                        name_suggestions: res_list,
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            this.setState({
                name_suggestions: []
            });
        }
    }

    selectSlide = (values) => {
        if(values.length > 0 || values >= 0) {
            let val = {
                id: parseInt(values.split(',')[0]),
                name: values.split(',')[1],
            }
            let selectedSlideList = this.state.selectedSlideList;
            selectedSlideList.unshift(val);
            this.setState({
                selectedSlideList,
                inputValue: '',
                name_suggestions: [],
            });
        } else {
            this.setState({
                inputValue: '',
                name_suggestions: [],
                selectedSlideList: '',
            });
        }
    }

    onBlurAction = (values) => {
        this.setState({
            inputValue: '',
            name_suggestions: [],
        });
    }

    removeSlide = (slide) => {
        let selectedSlideList = this.state.selectedSlideList;
        let index = selectedSlideList.findIndex((item) => slide.id === item.id);
        selectedSlideList.splice(index, 1);
        this.setState({
            selectedSlideList,
            inputValue: '',
            name_suggestions: [],
        });
    }

    handleOk = () => {
        if(this.state.selectedSlideList.length > 0) {
            let slide_ids = [];
            for(let i = 0; i < this.state.selectedSlideList.length; i++) {
                slide_ids.push(this.state.selectedSlideList[i].id);
            }
            let url = '/api/check_for_slides_owner/';
            axios.post(url, querystring.stringify({ slides: slide_ids }), { headers: { Authorization: AuthHeader() } })
                .then(result => {
                    if(result.status == 200) {
                        let url = `/api/add_slides_to_case/?case_id=` + this.props.currCase.id + `&slides=` + slide_ids.join();
                        axios.get(url, { headers: { Authorization: AuthHeader() } })
                            .then(response => {
                                if (response.status === 200 || response.status === 301 || response.status === 302) {
                                    message.success("Slides added successfully.", 2.5);
                                } else if (response.status === 403 || response.status === 401 || response.status === 400) {
                                    message.error("Some error occured. Not able to assign a case.", 2.5);
                                    console.log(response);
                                }
                                this.props.closeModal();
                                this.setState({
                                    inputValue: '',
                                    name_suggestions: [],
                                    selectedSlideList: [],
                                });
                            })
                            .catch(err => {
                                this.props.closeModal();
                                message.error("Some error occured. Not able to assign a case.", 2.5);
                                console.log(err);
                                this.setState({
                                    inputValue: '',
                                    name_suggestions: [],
                                    selectedSlideList: [],
                                });
                            });
                    }
                })
                .catch(err => {
                    if(err.response.status == 406) {
                        message.error(err.response.data, 2.5);
                    } else {
                        message.error("Some error occured. Not able to assign a case.");
                    }
                    console.log(err);
                });
            
        } else {
            message.error("Please select atleast 1 slide to add.", 2.5);
            this.setState({
                inputValue: '',
                name_suggestions: [],
                selectedSlideList: [],
            });
        }
    }

    handleCancel = () => {
        this.props.closeModal();
        this.setState({
            inputValue: '',
            name_suggestions: [],
            selectedSlideList: [],
        });
    }

    render() {

        let selected = [];

        let numSuggested = this.state.name_suggestions.length;

        let tagSuggestionsBox = numSuggested > 0 ?
            
            <Select.OptGroup label={<div><b>{numSuggested}</b> result{numSuggested > 1 ? 's' : ''} found </div>}>
                {this.state.name_suggestions.map((item, index) => (
                    
                <Select.Option
                    key={index}
                    value={item.id + ',' + item.display_text}
                    label={item.display_text}
                    className='search-result-tag'
                    size='small'
                    type='tag'
                >
                    {item.display_text} <span style={{ float: "right", fontSize: 12 }}></span>
                </Select.Option>
                ))}
            </Select.OptGroup> : undefined;

        return (
            <Modal
                title={"Add Slide"}
                visible={this.props.modalVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okButtonProps={{disabled: !(this.state.selectedSlideList.length > 0)}}
            >
                <div>
                    {this.state.selectedSlideList.length > 0 ?
                        <div>
                            <Row>
                                <Col span={23} offset={1} push={8}>
                                    <div><b>{this.state.selectedSlideList.length}</b> SLIDE{this.state.selectedSlideList.length > 1 ? 'S' : ''} SELECTED </div>
                                </Col>
                            </Row>
                            <br/>
                        </div> : null
                    }
                    <div className={this.state.selectedSlideList.length > 3 ? "scroll-slide-list-modal" : ""}>
                        {this.state.selectedSlideList.map((slide, index) => {
                            return (
                                <div key={index}>
                                            <Row>
                                                <Col span={14} offset={3}>
                                                    <Input style={{color: "black", cursor: "default"}} 
                                                            value={slide.name} 
                                                            disabled 
                                                    />
                                                </Col>
                                                <Col span={6} offset={1} style={{paddingTop: 5}}>
                                                    <Button
                                                        type="danger"
                                                        onClick={() => this.removeSlide(slide)}
                                                        ghost
                                                        size="small"
                                                    >
                                                        <CloseOutlined />
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <br/>
                                        </div>
                            );
                        })}
                    </div>
                    <br/>
                    <Row>
                        <Col offset={1}>
                            <p>Please enter Slide name in the input box below.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col offset={1}>
                            <Select
                                showSearch
                                allowClear
                                autoFocus
                                placeholder="Enter slide name"
                                className="search-input"
                                showArrow={false}
                                filterOption={false}
                                notFoundContent={null}
                                onSearch={this.typeAheadSearch}
                                onSelect={this.selectSlide}
                                onBlur={this.onBlurAction}
                                optionLabelProp="label"
                                value={selected}
                            >
                                {tagSuggestionsBox}
                            </Select>
                        </Col>
                    </Row>
                </div>
            </Modal>
        );
    }
}


const mapStateToProps = (state) => {
    return{
        loginStatus :  state.loginAlerts.loginStatus,
        urlState: state.searchReducer,
        allSlides: state.slidesReducer
    }
}

export default connect(mapStateToProps)(SlideSuggestions);
