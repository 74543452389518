import React, { Component } from 'react';
import { connect } from "react-redux";
import queryString from 'query-string'
import axios from 'axios';
import {Link} from 'react-router-dom';
import cookie from "react-cookies";
import { AuthHeader } from '../helper/auth.token';
import { Spin, Row, Col , Button, Dropdown, Menu, Popover, Checkbox, Empty} from 'antd';
import { updatePageFieldView } from '../action/fieldView.action';
import { updatePageWBCView } from '../action/wbcview.action';
import {rbcChangeImage, rbcGridSelected, updatePageSizeRBC, updatePageRBCView, updateTotalCountRBC, updateTotalPagesRBC, updateCurrentGrids, updateSelectedGridOverallId, updateFetchingGridRBC} from '../action/bloodGridRBC.action'
import {updatePageSizePLT, pltGridSelected, updateSelectedGridOverallIdPLT, updatePagePLT, pltChangeImage} from '../action/bloodGridPLT.action'
import {
    EditOutlined,
    InfoCircleOutlined,
    RightOutlined,
    LeftOutlined,
} from '@ant-design/icons';
import '../asset/style/workflow/tab-pane.css'
import {bloodClass} from '../utils/const'

import querystring from "query-string";

export class FieldGridViewRbc extends Component {
    constructor(props){
        super(props);
        this.state = {
            grid_data : [],
            tag_list: [],
            page: 1,
            total_pages:'',
            slide_path:'',
            total_count:'',
            first_time: false,
            hover_grid_index: -1,
            dimensionChanged:false,
            imgdim: 0,
            listannos:[],
            spinning: false,
            first_time_redis: true,
            only_one_call: true,
        }
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.updateDimensions);
        this.setGridSize();
    }

    firstSelectRBC = () => {
        this.props.dispatch(rbcChangeImage(true));
        this.props.dispatch(rbcGridSelected(this.state.grid_data[0]));
        this.props.dispatch(updateSelectedGridOverallId((this.state.grid_data[0]||{}).overall_count))
        this.props.dispatch(updatePageRBCView(this.state.page))
        this.props.dispatch(updateFetchingGridRBC(false));
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.state.dimensionChanged){
            this.setGridSize()
            this.setState({dimensionChanged:false})
        }
        if(((document.getElementById('fieldimg_0')||{}).offsetWidth) !== undefined && (this.state.imgdim === undefined))
            this.setState({imgdim:(document.getElementById('fieldimg_0')||{}).offsetWidth})

        if(prevProps.view_mode  !==  this.props.view_mode){
            this.getGridData(this.state.page);
        }
        if(prevProps.page  !==  this.props.page){
            this.onChangePage(this.props.page)
        }

        if(this.state.only_one_call && 
            this.props.rbcGridData.size_range_low  !==  -1 &&
            this.props.rbcGridData.size_range_high !==  -1 &&
            this.props.rbcGridData.convexity_range_low !==  -1 &&
            this.props.rbcGridData.convexity_range_high !==  -1 &&
            this.props.rbcGridData.eccentricity_range_low !==  -1 &&
            this.props.rbcGridData.eccentricity_range_high !==  -1 &&
            this.props.rbcGridData.mean_color_range_low  !==  -1 &&
            this.props.rbcGridData.mean_color_range_high !==  -1 &&
            this.props.rbcGridData.extreme_size_range_low !==  -1 &&
            this.props.rbcGridData.extreme_size_range_high !==  -1 &&
            this.props.rbcGridData.extreme_convexity_range_low !==  -1 &&
            this.props.rbcGridData.extreme_convexity_range_high !==  -1 &&
            this.props.rbcGridData.extreme_eccentricity_range_low !==  -1 &&
            this.props.rbcGridData.extreme_eccentricity_range_high  !==  -1 &&
            this.props.rbcGridData.extreme_mean_color_range_low !==  -1 &&
            this.props.rbcGridData.extreme_mean_color_range_high !==  -1){
                this.loadRedis(this.pageSize)
                this.setState({
                    only_one_call: false,
                })
        }
        

        if(this.state.first_time&&(prevProps.rbcGridData.size_range_low !== this.props.rbcGridData.size_range_low ||
            prevProps.rbcGridData.size_range_high !== this.props.rbcGridData.size_range_high||
            prevProps.rbcGridData.convexity_range_low !== this.props.rbcGridData.convexity_range_low||
            prevProps.rbcGridData.convexity_range_high !== this.props.rbcGridData.convexity_range_high||
            prevProps.rbcGridData.eccentricity_range_low !== this.props.rbcGridData.eccentricity_range_low||
            prevProps.rbcGridData.eccentricity_range_high !== this.props.rbcGridData.eccentricity_range_high||
            prevProps.rbcGridData.mean_color_range_low !== this.props.rbcGridData.mean_color_range_low ||
            prevProps.rbcGridData.mean_color_range_high !== this.props.rbcGridData.mean_color_range_high||
            prevProps.rbcGridData.extreme_size_range_low !== this.props.rbcGridData.extreme_size_range_low||
            prevProps.rbcGridData.extreme_size_range_high !== this.props.rbcGridData.extreme_size_range_high||
            prevProps.rbcGridData.extreme_convexity_range_low !== this.props.rbcGridData.extreme_convexity_range_low||
            prevProps.rbcGridData.extreme_convexity_range_high !== this.props.rbcGridData.extreme_convexity_range_high||
            prevProps.rbcGridData.extreme_eccentricity_range_low !== this.props.rbcGridData.extreme_eccentricity_range_low||
            prevProps.rbcGridData.extreme_eccentricity_range_high !== this.props.rbcGridData.extreme_eccentricity_range_high||
            prevProps.rbcGridData.extreme_mean_color_range_low !== this.props.rbcGridData.extreme_mean_color_range_low ||
            prevProps.rbcGridData.extreme_mean_color_range_high !== this.props.rbcGridData.extreme_mean_color_range_high)){
                this.setState({
                    page: 1,
                })
                this.props.dispatch(updatePageRBCView(1))
                this.getGridData(1);
                this.loadRedis(this.pageSize);
                this.setState({
                    first_time: false,
                })
        }
    }
    delRedis = () => {
        let val = {}
        console.log("REDIS HERE")
        let url = `/api/delete_redis_keys_rbc/`
            axios.post(url, queryString.stringify(val), { headers: { Authorization: AuthHeader() }}).then(res => {
                console.log("REDIS DEL")
            })
    }

    pressShiftLeft = (event) => {
        if(event.keyCode === 37 && event.shiftKey) {
            this.decreasepage()
        }
    }
    pressShiftRight = (event) => {
        if(event.keyCode === 39 && event.shiftKey) {
            this.increasepage()
        }
    }

    updateDimensions = () => {
        this.setState({ dimensionChanged: true });
    };

    setGridSize = () => {
        let picker = Math.max(((document.documentElement.clientHeight)*12/100),((document.documentElement.clientWidth)*6/100))
        let noOfGrids = Math.floor(document.documentElement.clientHeight/picker)-1
        if(this.props.mode === bloodClass.PLT){
            this.pageSize = noOfGrids*8;
            this.props.dispatch(updatePageSizePLT(this.pageSize))
        }
        else if(this.props.mode === bloodClass.RBC){
            this.pageSize = noOfGrids*8;
            this.props.dispatch(updatePageSizeRBC(this.pageSize))
        }
        this.getGridData(this.state.page);
        this.setState({imgdim:(document.getElementById('fieldimg_0')||{}).offsetWidth})
        this.loadRedis(this.pageSize);
    }

    loadRedis = (pageSize) => {
        if(this.props.mode === bloodClass.RBC){
            let low=this.props.rbcGridData.size_range_low;
            let high=this.props.rbcGridData.size_range_high;
            if(this.props.rbcGridData.size_range_low === -1 && this.props.rbcGridData.size_range_high === -1){
                low = this.props.rbcGridData.extreme_size_range_low;
                high = this.props.rbcGridData.extreme_size_range_high;
            }
            let val = {
                slide_id: this.props.slide_id,
                page_size: pageSize,
                size_filter: ((this.props.rbcGridData.size_range_low === -1 && this.props.rbcGridData.size_range_high === -1) || (this.props.rbcGridData.size_range_low === this.props.rbcGridData.extreme_size_range_low && this.props.rbcGridData.size_range_high === this.props.rbcGridData.extreme_size_range_high))?false:true,
                size_range_low: low,
                size_range_high: high,
                convexity_filter: ((this.props.rbcGridData.convexity_range_low === -1 && this.props.rbcGridData.convexity_range_high === -1) || (this.props.rbcGridData.convexity_range_low === this.props.rbcGridData.extreme_convexity_range_low &&
                                    this.props.rbcGridData.convexity_range_high === this.props.rbcGridData.extreme_convexity_range_high))
                                    ?false:true,
                convexity_range_low: this.props.rbcGridData.convexity_range_low,
                convexity_range_high: this.props.rbcGridData.convexity_range_high,
                eccentricity_filter: ((this.props.rbcGridData.eccentricity_range_low === -1 && this.props.rbcGridData.eccentricity_range_high === -1) || (this.props.rbcGridData.eccentricity_range_low === this.props.rbcGridData.extreme_eccentricity_range_low && 
                                        this.props.rbcGridData.eccentricity_range_high === this.props.rbcGridData.extreme_eccentricity_range_high))
                                        ?false:true,
                eccentricity_range_low: this.props.rbcGridData.eccentricity_range_low,
                eccentricity_range_high: this.props.rbcGridData.eccentricity_range_high,
                mean_color_filter: ((this.props.rbcGridData.mean_color_range_low === -1 && this.props.rbcGridData.mean_color_range_high === -1) || (this.props.rbcGridData.mean_color_range_low === this.props.rbcGridData.extreme_mean_color_range_low &&
                                        this.props.rbcGridData.mean_color_range_high === this.props.rbcGridData.extreme_mean_color_range_high))
                                        ?false:true,
                mean_color_range_low: this.props.rbcGridData.mean_color_range_low,
                mean_color_range_high: this.props.rbcGridData.mean_color_range_high,
                first_time: this.state.first_time_redis,
                filter:this.props.orderby
            }

            let url = `/api/load_redis_rbc_size/`
            axios.post(url, queryString.stringify(val), { headers: { Authorization: AuthHeader() }})
                .then(res => {
                    this.setState({
                        first_time_redis:false
                    })
            })
        }
    }

    getGridData = (page) => {
        if(this.props.mode === bloodClass.RBC){
            this.props.dispatch(updateFetchingGridRBC(true))
            let page_size = this.props.view_mode !== 1?28:this.pageSize
            let val = {
                slide_id: this.props.slide_id,
                page:page,
                page_size: page_size,
                size_filter: ((this.props.rbcGridData.size_range_low === -1 && this.props.rbcGridData.size_range_high === -1) || (this.props.rbcGridData.size_range_low === this.props.rbcGridData.extreme_size_range_low && this.props.rbcGridData.size_range_high === this.props.rbcGridData.extreme_size_range_high))?false:true,
                size_range_low: this.props.rbcGridData.size_range_low,
                size_range_high: this.props.rbcGridData.size_range_high,
                convexity_filter: ((this.props.rbcGridData.convexity_range_low === -1 && this.props.rbcGridData.convexity_range_high === -1) || (this.props.rbcGridData.convexity_range_low === this.props.rbcGridData.extreme_convexity_range_low &&
                                    this.props.rbcGridData.convexity_range_high === this.props.rbcGridData.extreme_convexity_range_high))
                                    ?false:true,
                convexity_range_low: this.props.rbcGridData.convexity_range_low,
                convexity_range_high: this.props.rbcGridData.convexity_range_high,
                eccentricity_filter: ((this.props.rbcGridData.eccentricity_range_low === -1 && this.props.rbcGridData.eccentricity_range_high === -1) || (this.props.rbcGridData.eccentricity_range_low === this.props.rbcGridData.extreme_eccentricity_range_low && 
                                        this.props.rbcGridData.eccentricity_range_high === this.props.rbcGridData.extreme_eccentricity_range_high))
                                        ?false:true,
                eccentricity_range_low: this.props.rbcGridData.eccentricity_range_low,
                eccentricity_range_high: this.props.rbcGridData.eccentricity_range_high,
                mean_color_filter: ((this.props.rbcGridData.mean_color_range_low === -1 && this.props.rbcGridData.mean_color_range_high === -1) || (this.props.rbcGridData.mean_color_range_low === this.props.rbcGridData.extreme_mean_color_range_low &&
                                    this.props.rbcGridData.mean_color_range_high === this.props.rbcGridData.extreme_mean_color_range_high))
                                    ?false:true,
                mean_color_range_low: this.props.rbcGridData.mean_color_range_low,
                mean_color_range_high: this.props.rbcGridData.mean_color_range_high,
                filter:this.props.orderby
            }
            let url = `/api/retrieve_grid_view_rbc_size/`
            axios.post(url,queryString.stringify(val),{ headers: { Authorization: AuthHeader() }})
                .then(res => {
                    this.setState({
                        grid_data: res.data['data'],
                        total_pages: res.data['total_pages'],
                        total_count:res.data['count'],
                        parameter: "size",
                        increasing: true,
                    })
                    this.props.dispatch(updateTotalPagesRBC(res.data['total_pages']))
                    this.props.dispatch(updateTotalCountRBC(res.data['count']))
                    this.props.dispatch(updateCurrentGrids(res.data['data']))

                    let curr_grids = res.data['data']
                    let selected_grid_id = this.props.rbcGridData.selected_grid_overall_id
                    for(let i=0; i<curr_grids.length && selected_grid_id !== ''; i++){
                        if(curr_grids[i].overall_count === selected_grid_id){
                            this.props.dispatch(rbcGridSelected(curr_grids[i]));
                        }
                    }
                    if((res.data['data']||{}).length === 0){
                            this.props.dispatch(updateSelectedGridOverallId(""))
                            this.setState({
                                first_time:true,
                            })
                        
                    }
                    if(res.data['data'].length>0 && !this.state.first_time){
                        setTimeout(this.firstSelectRBC, 500)
                        this.props.dispatch(updateFetchingGridRBC(false));
                        this.setState({
                            first_time:true,
                        })
                    }
                    else{
                        this.props.dispatch(updateFetchingGridRBC(false));
                    }
                })
            }       
        }

        addHover = (idx) => {
            this.setState({
                hover_grid_index: idx,
            })
        }
        getAnnotationsInGridView = (currentAnnotations) => {
    
            let annotationsGridParent;
            
            let imgsPerCol = this.props.view_mode === 1?(this.props.mode === bloodClass.RBC||this.props.mode === bloodClass.PLT)?8:3:4;
            let colSpan = parseInt(24 / imgsPerCol);
            let currentImgCount = 0;
            let annotationRow = [];
            let annotationsGrid = [];
        
            let tags = this.state.tag_list;
            let menu_items = tags.map((tag, index)=> {
                return <Menu.Item key={index}>{tag}</Menu.Item>
            })
            
            if(currentAnnotations.length === 0){
                annotationsGridParent = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={this.props.mode === bloodClass.WBC?{marginTop: '40vh', width:'17.1vw'}:( this.props.mode === bloodClass.RBC || this.props.mode === bloodClass.PLT )?{marginTop: '40vh',width:'58vw', height:'46vh'}:{width:'11.5vw'}}/>
                
                return annotationsGridParent;
            }
    
    
            for (let index = 0; index < currentAnnotations.length; index+=1){
                let annotation = currentAnnotations[index];
                let annotationDiv;
    
                const menu = (
                    <Menu onClick={(e)=>this.handleMenuClick(e,currentAnnotations[index])}>
                        {menu_items}
                    </Menu>
                )
    
                let content;
                if(this.props.mode === bloodClass.WBC){
                    content = (
                        <div>
                            <span>{annotation.class.toUpperCase()}</span>
                        </div>
                    )
                }
                let checkbox_tag;
                if(this.props.mode === bloodClass.WBC  && this.props.wbcGridData.tag !== ''){
                    let selected = currentAnnotations[index].oot_id
                    checkbox_tag = <Checkbox className="custom" checked={this.state.listannos.indexOf(selected) !== -1} key={selected} style={{position:"absolute", backgroundColor:"rgba(0,0,0,0)", border:'0px', borderColor:'rgba(0,0,0,0)', marginTop:'0.5vh', marginLeft:'0.7vw'}} onChange={(e)=>this.selectMultiple(e,currentAnnotations[index])}></Checkbox>
                }else{
                    checkbox_tag = null;
                }
    
                let content_popover=null;
                if(this.props.mode === bloodClass.WBC && this.state.hover_grid_index === index){
                    content_popover = <Popover placement="top" content={content} style={{backgroundColor:"#03112c", border:'0px', borderColor:'rgba(0,0,0,0)'}} trigger="click">
                                            <Button ghost={true} style={{border:'0px',position:'absolute', marginLeft:'3.4vw',marginTop:'0.5vh', backgroundColor:'rgba(0,0,0,0)' }}>
                                                <InfoCircleOutlined style={{color:'black'}}/>
                                            </Button>
                                    </Popover>
                }
    
                let edit_tag=null;
                if(this.props.mode === bloodClass.WBC  && this.props.wbcGridData.tag !== '' && this.state.hover_grid_index === index){
                    edit_tag = <Dropdown overlay={menu} trigger="click" style={{backgroundColor:'rgba(0,0,0,0)'}}>
                <Button style={{ backgroundColor:'rgba(255,255,255,0.5)', position:'absolute', border:'0px solid black', marginTop:(this.state.imgdim-25), marginLeft:(this.state.imgdim-(2*(this.state.imgdim+3))),width:this.state.imgdim-2, fontWeight:"bold"}}>
                        <span style={{textAlign:"center", width: '3.25vw'}}>EDIT<EditOutlined style={{color: "black", }}/></span> </Button>
                                </Dropdown>
                }

                annotationDiv =
                        <div onMouseEnter={()=> {this.addHover(index)}} onMouseLeave={()=> {this.addHover(-1)}}> 
                            <Col key={currentImgCount} span={colSpan}>   
                                <Row style={this.props.mode === bloodClass.FIELD?{minHeight: '5vw', height:'10vh'}:{minHeight: '6vw', height: '12vh'}}>
                                    {this.props.differential?checkbox_tag:null}
                                    {this.props.differential?content_popover:null}
                                    <img
                                        id={`fieldimg_${index}`}
                                        className={`annotation-crop imageDim`}
                                        style = {annotation.id === (this.props.selected||{}).id?{borderColor:'#7CFC00', borderWidth:'4px', width:'5.2vw', filter: `brightness(${(this.props.cssfilter||{}).brightness}%) contrast(${(this.props.cssfilter||{}).contrast}%)`}:{width:'5.2vw', filter: `brightness(${(this.props.cssfilter||{}).brightness}%) contrast(${(this.props.cssfilter||{}).contrast}%)`}}
                                        src={annotation.thumbnail} 
                                        onClick={()=>{this.onGridClicked(annotation)}}
                                        onLoad = {()=> {document.getElementById('fieldimg_'+index).className = 'annotation-crop';}}
                                        onError = {()=> {document.getElementById('fieldimg_'+index).className = 'annotation-crop';}}
                                    />
                                    {this.props.differential?edit_tag:null}
                                </Row>
                            </Col>
                        </div>
        
                if (currentImgCount === imgsPerCol) {
                    annotationsGrid.push(<Row key={annotationsGrid.length}>{annotationRow}</Row>);
                    annotationRow = [];
                    currentImgCount = 0;
                }
        
                annotationRow.push(annotationDiv);
                currentImgCount ++;
            }
        
            annotationsGrid.push(<Row key={annotationsGrid.length}>{annotationRow}</Row>);
            annotationsGridParent = 
                <Row>
                    {annotationsGrid}
                </Row>
        
            return annotationsGridParent;
        }
        
        onChangePage = (p) => {
            if(this.props.mode === bloodClass.FIELD){
                this.props.dispatch(updatePageFieldView(p));
            }
            else if(this.props.mode === bloodClass.WBC){
                this.props.dispatch(updatePageWBCView(p));
            }
            else if(this.props.mode === bloodClass.RBC){
                this.props.dispatch(updatePageRBCView(p));
            }
            else if(this.props.mode === bloodClass.PLT){
                this.props.dispatch(updatePagePLT(p));
            }
            this.setState({
                page: p,
            })
            this.getGridData(p);
        }
    
    
        componentWillUnmount = () => {
            window.removeEventListener('resize', this.updateDimensions);
        }
        onGridClicked = (annotation) => {
            if(this.props.view_mode  !==  1 && this.props.view_mode  !==  2){
                return;
            }
            if(this.props.mode === bloodClass.PLT){
                this.props.dispatch(pltChangeImage(true));
                this.props.dispatch(pltGridSelected(annotation))
                this.props.dispatch(updateSelectedGridOverallIdPLT(annotation.overall_count))
            }
            else if(this.props.mode === bloodClass.RBC){
                this.props.dispatch(rbcChangeImage(true));
                this.props.dispatch(rbcGridSelected(annotation))
                this.props.dispatch(updateSelectedGridOverallId(annotation.overall_count))
            }
        }
     
        decreasepage = () => {
            let all_images = document.getElementsByTagName("img");
            for(let i=0; i< all_images.length;i++){
                if(all_images[i].className === 'annotation-crop imageBright' || all_images[i].className === 'annotation-crop'){
                    all_images[i].className = 'annotation-crop imageDim';
                }    
            }
            let curr = this.state.page;
            if(curr === 1){
                return;
            }
            this.onChangePage(curr-1);
        }
    
        increasepage = () => {
            let all_images = document.getElementsByTagName("img");
            for(let i=0; i< all_images.length;i++){
                if(all_images[i].className === 'annotation-crop imageBright' || all_images[i].className === 'annotation-crop'){
                    all_images[i].className = 'annotation-crop imageDim';
                }
            }
            let curr = this.state.page;
            if(curr === this.state.total_pages){
                return;
            }
            this.onChangePage(curr+1);
        }

        render() {
            let annotationsGridParent = this.getAnnotationsInGridView(this.state.grid_data)

                let selectCounter=null;
                if((this.props.mode === bloodClass.RBC || this.props.mode === bloodClass.PLT) && this.props.selected){
                    selectCounter = <div style={{position:'fixed', bottom:'1.4vh', marginLeft:'52vw', textAlign:'center', fontSize:'2vh', color:'white'}}>
                                            #{(this.props.selected||{}).overall_count}
                                        </div>
                }

                let gridNumber = null;
                if(!(this.props.differential)){
                    gridNumber = <React.Fragment>
                        <Button  style={{textAlign:'center', backgroundColor:'#03112c' ,borderColor:'rgba(0,0,0,0)', borderWidth:'0px'}} onClick={this.decreasepage} disabled={this.state.page === 1}><LeftOutlined style={{color:'cyan',fontSize:'1vw'}}/></Button>
                                Showing {((this.state.page-1)*this.pageSize)+1}-{Math.min((this.state.page*this.pageSize), this.state.total_count)} of {this.state.total_count}
                                <Button  style={{textAlign:'center', backgroundColor:'#03112c' ,borderColor:'rgba(0,0,0,0)', borderWidth:'0px'}} onClick={this.increasepage}><RightOutlined style={{color:'cyan',fontSize:'1vw'}}/></Button>
                    </React.Fragment>

                } else {
                    if(this.state.total_count>0){
                        gridNumber= <React.Fragment>
                        <Button  style={this.props.mode === bloodClass.FIELD?{position:'fixed', bottom:'1vh', marginLeft:'0.5vw', textAlign:'center', backgroundColor:'#03112c' ,borderColor:'rgba(0,0,0,0)', borderWidth:'0px'}
                                        :this.props.mode === bloodClass.WBC?{position:'fixed', bottom:'1vh', marginLeft:'-2vw', textAlign:'center', backgroundColor:'#03112c' ,borderColor:'rgba(0,0,0,0)', borderWidth:'0px'}
                                            :{position:'fixed', bottom:'1vh', marginLeft:'12.5vw', textAlign:'center', backgroundColor:'#03112c' ,borderColor:'rgba(0,0,0,0)', borderWidth:'0px'}} 
                                                onClick={this.decreasepage} disabled={this.state.page === 1}><LeftOutlined style={{color:'cyan',fontSize:'1vw'}}/></Button>
                        <div style={this.props.mode === bloodClass.FIELD?{position:'fixed', bottom:'1.4vh', marginLeft:'3.5vw', textAlign:'center'}
                                        :this.props.mode === bloodClass.WBC?{position:'fixed', bottom:'1.4vh', marginLeft:'2.5vw', textAlign:'center'}
                                            :{position:'fixed', bottom:'1.4vh', marginLeft:'22vw', textAlign:'center'}}>
                                                Showing {((this.state.page-1)*this.pageSize)+1}-{Math.min((this.state.page*this.pageSize), this.state.total_count)} of {this.state.total_count}
                                    </div>
                        <Button  style={this.props.mode === bloodClass.FIELD?{position:'fixed', bottom:'1vh', marginLeft:'13vw', textAlign:'center', backgroundColor:'#03112c' ,borderColor:'rgba(0,0,0,0)', borderWidth:'0px'}
                        :this.props.mode === bloodClass.WBC?{position:'fixed', bottom:'1vh', marginLeft:'13.5vw', textAlign:'center', backgroundColor:'#03112c' ,borderColor:'rgba(0,0,0,0)', borderWidth:'0px'}
                            :{position:'fixed', bottom:'1vh', marginLeft:'40vw', textAlign:'center', backgroundColor:'#03112c' ,borderColor:'rgba(0,0,0,0)', borderWidth:'0px'}} 
                                onClick={this.increasepage} disabled={this.state.page>=this.state.total_pages}><RightOutlined style={{color:'cyan',fontSize:'1vw'}}/></Button>
                        {selectCounter}
                        </React.Fragment>  
                    }
                }
                let tags = this.state.tag_list;

                return (
                    <Spin  spinning={this.state.spinning} style={{backgroundColor:"rgba(0,0,0,0)", }}>
                        <div style={{backgroundColor:'#03112c', marginTop:'0.5vh', height:"50vh" }}>
                                    {annotationsGridParent}
                                    <Row style={this.props.mode === bloodClass.FIELD?{position:'fixed', bottom:'1vh'}:this.props.mode === bloodClass.WBC?this.props.differential?{position:'fixed', bottom:'1vh', marginLeft:'2.5vw'}:{position:'fixed', bottom:'1vh'}:{position:'fixed', bottom:'1vh', marginLeft:'0.7vw'}}>
                                        <Col span={24} style={this.props.view_mode === 1?{  color:'white', fontSize:'1vw'}:{ marginTop:'-10px',marginLeft:'290px'}}>
                                            {gridNumber}
                                        </Col>
                                    </Row>
                        </div>
                    </Spin>
                )
            }
}

const mapStateToProps = (state) => {
    return {
        bloodGridData: state.bloodGridReducer,
        urlState: state.viewerUrlReducer,
        // bloodGridRBCData: state.bloodGridRBCReducer,
    };
};

export default connect(mapStateToProps)(FieldGridViewRbc);
