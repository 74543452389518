import React, {Component} from "react";
import {connect} from "react-redux";
import {labellingLayer} from "../utils/layers_info";
import {Button, Grid, Stack, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {updateActiveAnnoDrawer, updateLabellingImage} from "../../../action/gamma.state.action";
import axios from "axios";
import {AxiosConfig} from "../../../helper/axios.config";
import {notifyError} from "../utils/display.notification";
import {ArrowCircleLeftTwoTone, ArrowCircleRightTwoTone} from "@mui/icons-material";
import {deleteAnnotation} from "../../../action/maps.state.action";

class LabellingApp extends Component {

	emptyComponent = <Grid>
		No Images Available for Labelling
	</Grid>

	constructor(props) {
		super(props);

		this.drawer = labellingLayer.drawer;
		this.state = {};
	}

	componentWillUnmount() {
		this.props.dispatch(updateActiveAnnoDrawer(null));
	}

	componentDidMount() {
		this.props.dispatch(updateActiveAnnoDrawer(this.drawer));
		this.fetchLabellingImages().then();
	}

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
		if (prevState.selected !== this.state.selected || prevState.idx !== this.state.idx)
			this.props.dispatch(updateLabellingImage(this.state.imageGroups[this.state.selected][this.state.idx]));
	}

	fetchLabellingImages = () =>
		axios.get(`/api/labelling/images/?slideId=${this.props.mapState.slideId}`, AxiosConfig())
			.then(response => this.setState({
				imageGroups: response.data,
				selected: Object.keys(response.data)[0],
				idx: 0,
			}))
			.catch(error => notifyError('labelling_images',
				`Failed to fetch images for labelling\n${error}`));

	select = (event, selected) => this.setState({selected, idx: 0});

	next = () => this.setState({
		idx: Math.min(this.state.imageGroups[this.state.selected].length - 1, this.state.idx + 1),
	});

	previous = () => this.setState({
		idx: Math.max(0, this.state.idx - 1),
	});

	clear = () => this.annotations.forEach(annotation => this.props.dispatch(deleteAnnotation(0, annotation)));

	render() {
		if (!this.props.slideState || !this.state.imageGroups)
			return this.emptyComponent;

		this.annotations = this.props.annotations.filter(annotation =>
			annotation.anno_drawer === this.drawer && annotation.meta.labellingImage === this.props.labellingImage);

		let numImages = this.state.imageGroups[this.state.selected].length;

		return <Stack direction={"column"} height={"100%"} spacing={3}>
			<ToggleButtonGroup value={this.state.selected} exclusive onChange={this.select}>
				{Object.keys(this.state.imageGroups).map(key =>
					<ToggleButton sx={{color: '#ffe294'}} value={key}>{key}</ToggleButton>
				)}
			</ToggleButtonGroup>
			{numImages > 0 ?
				[<img src={this.props.labellingImage} alt={this.props.labellingImage}/>,
					<Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
						<ArrowCircleLeftTwoTone onClick={this.previous}/>
						{`${this.state.idx + 1} of ${numImages}`}
						<ArrowCircleRightTwoTone onClick={this.next}/>
					</Stack>] : this.emptyComponent
			}
			<Button variant={"contained"} color={"secondary"} onClick={() => this.clear()}>Clear</Button>
			{/*<AnnotationsExpandableList annotations={this.annotations} slideId={this.props.mapState.slideId}*/}
			{/*                           onAnnotationClick={(annotation) =>*/}
			{/*	                           locateAnnotationOnMap(this.props.slideState.slidemap, annotation)}*/}
			{/*                           colors={AnnotationsConstants.ANNO_COLORS} showDownload={true}/>*/}
		</Stack>
	}
}

const mapStateToProps = (state) => {
	let mapState = state.mapsStateReducer[state.gammaStateReducer.activeMapId];
	let slideState = mapState.slideState;
	let labellingImage = state.gammaStateReducer.labellingImage;
	let annotations = ((mapState || {}).annotationState || {}).annotations || [];
	return {mapState, labellingImage, slideState, annotations};
};

export default connect(mapStateToProps)(LabellingApp);
