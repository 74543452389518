import React from 'react';
import {Col, Input, message, Modal, Row, Spin} from 'antd';
import {connect} from 'react-redux'
import axios from 'axios';
import {AuthHeader} from '../../../helper/auth.token';
import '../../../asset/style/settings/manageusers/change_password.css';
import querystring from "query-string";

const { Password } = Input;

class ChangePassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
            selectedUserUsername: '',
            newPassword: '',
            confirmNewPassword: '',
            errorFlag: false,
            errorMessage: '',
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedUser.id != this.props.selectedUser.id) {
            this.setState({
                selectedUserUsername: this.props.selectedUser.username,
            })
        }
    }

    handleOk = () => {
        this.setState({
            isFetching: true,
        });
        if (this.state.newPassword.length < 8) {
            this.setState({
                errorFlag: true,
                errorMessage: 'Use 8 characters or more for your password.',
                confirmNewPassword: '',
                isFetching: false,
            });
            return;
        } else if (this.state.newPassword != this.state.confirmNewPassword) {
            this.setState({
                errorFlag: true,
                errorMessage: 'Passwords didn\'t match. Please try again.',
                confirmNewPassword: '',
                isFetching: false,
            });
            return;
        }
        let val = {
            user_id: this.props.selectedUser.id,
            password: this.state.newPassword,
        }
        let url = `/api/change_password/`;
        axios.post(url, querystring.stringify(val), { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200 || response.status === 301 || response.status === 302) {
                    message.success("Password changed successfully.", 2.5);
                } else if (response.status === 403 || response.status === 401 || response.status === 400) {
                    message.error("Some error occured. Not able to change password.", 2.5);
                    console.log(response);
                }
                this.handleCancel();
            })
            .catch(err => {
                message.error("Some error occured. Not able to change password.", 2.5);
                console.log(err);
                this.handleCancel();
            });
    }

    handleCancel = () => {
        this.props.closeModal();
        this.setState({
            selectedUserUsername: '',
            newPassword: '',
            confirmNewPassword: '',
            isFetching: false,
            errorFlag: false,
            errorMessage: '',
        });
    }

    passwordFieldChange = (e) => {
        this.setState({
            newPassword: e.target.value,
            errorFlag: false,
            errorMessage: '',
        });
    }

    confirmPasswordFieldChange = (e) => {
        this.setState({
            confirmNewPassword: e.target.value,
            errorFlag: false,
            errorMessage: '',
        });
    }

    render() {

        return (
            <Modal
                title="Change Password"
                visible={this.props.modalVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okButtonProps={{ disabled: !(!this.state.errorFlag && this.state.newPassword.length > 0 && this.state.confirmNewPassword.length > 0) }}
            >
                <Spin spinning={this.state.isFetching}>
                    <div>
                        {this.state.errorFlag ?
                            <div>
                                <Row>
                                    <Col offset={5}>
                                        <div className="error-message-general"> {this.state.errorMessage} </div>
                                    </Col>
                                </Row>
                                <br/> 
                            </div> :
                            null
                        }
                        <Row>
                            <Col span={10} className="row-heading">
                                <b>Username</b>
                            </Col>
                            <Col span={8}>
                                <Input placeholder="Username" value={this.state.selectedUserUsername} className="input-field"
                                    autoComplete="off" disabled style={{ color: 'black' }} />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={10} className="row-heading">
                                <b>New Password</b>
                            </Col>
                            <Col span={8}>
                                <Password placeholder="Password" value={this.state.newPassword} className="input-field"
                                    onChange={this.passwordFieldChange} autoComplete="new-password" />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={10} className="row-heading">
                                <b>Confirm New Password</b>
                            </Col>
                            <Col span={8}>
                                <Password placeholder="Confirm Password" value={this.state.confirmNewPassword} className="input-field"
                                    onChange={this.confirmPasswordFieldChange} autoComplete="new-password" />
                            </Col>
                        </Row>
                    </div>
                </Spin>
            </Modal>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        loginStatus: state.loginAlerts.loginStatus,
        urlState: state.searchReducer,
        allSlides: state.slidesReducer
    }
}

export default connect(mapStateToProps)(ChangePassword);
