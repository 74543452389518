export const BloodGridPLTActions = {
    PLT_UPDATE_SIZE_RANGE:"PLT_UPDATE_SIZE_RANGE",
    PLT_UPDATE_EXTREME_SIZE_RANGE:"PLT_UPDATE_EXTREME_SIZE_RANGE",
    PLT_GRID_SELECTED:"RBC_GRID_SELECTED",
    PLT_CHANGE_IMAGE:"RBC_CHANGE_IMAGE",
    UPDATE_PAGE:"UPDATE_PAGE",
    UPDATE_PAGE_SIZE: "UPDATE_PAGE_SIZE",
    UPDATE_ALL_GRIDS:"UPDATE_ALL_GRIDS",
    UPDATE_Z_STACK:"UPDATE_Z_STACK",
    RESET_Z_STACK:"RESET_Z_STACK",
    RESET: "RESET",
    UPDATE_TOTAL_PAGES:"UPDATE_TOTAL_PAGES",
    UPDATE_TOTAL_COUNT:"UPDATE_TOTAL_COUNT",
    UPDATE_NEW_SELECTED_ON_NEW_PAGE:"UPDATE_NEW_SELECTED_ON_NEW_PAGE",
    UPDATE_CURR_GRIDS: "UPDATE_CURR_GRIDS",
    SELECTED_GRID_OVERALL_ID: "SELECTED_GRID_OVERALL_ID",
    UPDATE_FETCHING_GRID:"UPDATE_FETCHING_GRID", 
} 