import React, {Component} from "react";
import axios from "axios";
import {Button, Divider, ImageList, ImageListItem, Paper, Popper, Stack} from "@mui/material";
import {DoubleLeftOutlined, DoubleRightOutlined} from "@ant-design/icons";
import Typography from "@mui/material/Typography";
import {connect} from "react-redux";
import {limitString, linkViewer, linkViewerNewTab, notNull, openEndPoint} from "../../../utils/utils";
import {AxiosConfig} from "../../../helper/axios.config";
import {displayError} from "../../../helper/display.error";
import {morphleBaseTheme1} from "../themes";
import {OpenInNewRounded} from "@mui/icons-material";
import {FileType} from "../../../utils/const";
import {animateToState} from "../utils/map_utils";
import {convertMapStateToUrlParams} from "../utils/linkMakerUtils";
import {checkAppPermission} from "../utils/gammaScanUtils";
import {capReport} from "../app_maker";
import ReportApp from "./cap_report";
import {LoadingText} from "../components/components";

class CaseInfo extends Component {

	constructor(props) {
		super(props);

		this.state = this.initState();
		this.ref = React.createRef();
		this.fetchCase();
	}

	initState = () => {
		return {};
	}

	fetchCase = () =>
		// fetch case
		this.props.slide.case &&
		axios.get(`/api/case/${this.props.slide.case}/`, AxiosConfig())
			.then(response => this.setState({case: response.data}))
			.catch(error => displayError("Failed to fetch case", error));

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
		if (prevProps.slide !== this.props.slide) {
			this.setState(this.initState());
			this.fetchCase();
		}
	}

	updateProperties = () => {
		this.currSlideIndex = this.state.case.slides.findIndex(slide => slide.id === this.props.slide.id);
		this.totalSlides = this.state.case.slides.length;
		this.unreadSlides = this.state.case.slides.filter(slide => slide.unread).length;
	}

	goToPrevSlide = () => this.currSlideIndex > 0 && linkViewer(this.state.case.slides[this.currSlideIndex - 1]);

	goToNextSlide = () => this.currSlideIndex < this.state.case.slides.length - 1 &&
		linkViewer(this.state.case.slides[this.currSlideIndex + 1]);

	goToCase = () => openEndPoint(`/case/${this.state.case.id}`, true);

	getSlideNumberComponent = () => <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
		<Button variant={"outlined"} color={"secondary"} size={"small"}
				onClick={this.goToPrevSlide}>
			<DoubleLeftOutlined/> Prev Slide
		</Button>
		<Stack sx={{width: 0.5}} direction={"column"} alignItems={"center"}>
			<Typography sx={{fontWeight: 20, color: "white"}}>
				{`Slide ${this.currSlideIndex + 1} of ${this.totalSlides}`}
			</Typography>
			<Typography sx={{fontWeight: 20, color: "white"}}>
				{`(${this.unreadSlides} unread)`}
			</Typography>
		</Stack>
		<Button variant={"outlined"} color={"secondary"} size={"small"}
				onClick={this.goToNextSlide}>
			Next Slide <DoubleRightOutlined/>
		</Button>
	</Stack>

	toggleCapReport = () => this.setState({
		showCapReport: !this.state.showCapReport
	});

	getCapReportApp = () => checkAppPermission(capReport) ?
		<>
			<Button color={"secondary"} variant={"outlined"} size={"small"} onClick={this.toggleCapReport}>
				Edit CAP Report
			</Button>
			<Popper placement="right" open={this.state.showCapReport} anchorEl={this.ref.current}>
				<Paper elevation={3} sx={{height: "71vh", width: "41vw", marginTop: -2}}>
					<ReportApp slideData={this.props.slide} onCAPReportClose={this.toggleCapReport}/>
				</Paper>
			</Popper>
		</> : null;


	getCaseComponent = () =>
		<Stack direction={"column"}>
			{[
				["Patient Name", this.state.case.patient_name],
				["DOB/SEX", this.state.case.age_and_sex],
				["SAMPLE ID", this.props.slide.name],
			].map(row =>
				<Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
					<Typography sx={{color: 'white', fontWeight: 700}}>{row[0]}</Typography>
					<Typography sx={{color: 'white', fontWeight: 500}}>{row[1]}</Typography>
				</Stack>
			)}
			<Stack direction={"row"} alignItems={"center"} justifyContent={"space-evenly"} marginTop={1}>
				<Button color={"secondary"} variant={"outlined"} size={"small"} onClick={this.goToCase}>
					Go to Case
				</Button>
				{this.getCapReportApp()}
			</Stack>

		</Stack>

	getRelatedScansComponent = () =>
		<Stack direction={"column"}>
			<Typography color={"secondary"} sx={{textAlign: 'center'}}>Scans related to this case</Typography>
			{this.state.case.slides.map(slide =>
				<Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
					<Typography width={150} sx={{color: 'white', fontWeight: 700}}>
						{limitString(slide.name, 25)}
					</Typography>
					{slide.id !== this.props.slide.id ?
						<Button sx={{color: morphleBaseTheme1.palette.text.primary, minWidth: 10, padding: 1}}
								edge="end" onClick={() => linkViewerNewTab(slide)}>
							<OpenInNewRounded fontSize={"small"}/>
						</Button> : null
					}
				</Stack>
			)}
		</Stack>

	getScreenshotComponent = () =>
		<Stack direction={"column"} sx={{minHeight: 80, height: "100%"}}>
			<ImageList>
				{this.state.case.files.filter(file => file.type === FileType.SCREENSHOT).map(file => (
					<ImageListItem key={file.id}>
						<img
							src={`${file.file}?w=120&h=80&fit=crop&auto=format`}
							srcSet={`${file.file}?w=120&h=80&fit=crop&auto=format&dpr=2 2x`}
							alt={file.id}
							onClick={() => file.slide === this.props.slide.id ?
								animateToState(this.props.mapState.slideState.slidemap.getView(), file.meta.mapState) :
								openEndPoint(`/${(JSON.parse(localStorage.getItem('morpheus_setting'))
										|| {}).viewer}/${file.slide}?${convertMapStateToUrlParams(
										{slideId: file.slide, ...file.meta.mapState})}`,
									true)}
							style={{cursor: 'pointer'}}
							loading="lazy"
						/>
					</ImageListItem>
				))}
			</ImageList>
			<Divider style={{margin: "10px"}}/>
		</Stack>


	render() {
		if (!notNull(this.props.slide.case))
			return <Typography>No case associated with this slide</Typography>;
		if (!this.state.case)
			return <LoadingText/>;
		this.updateProperties();
		return <Stack ref={this.ref} direction={"column"} spacing={2} height={"100%"}>
			{this.getSlideNumberComponent()}
			<Divider style={{margin: "10px"}}/>
			{this.getCaseComponent()}
			<Divider style={{margin: "10px"}}/>
			{this.getScreenshotComponent()}
			{this.getRelatedScansComponent()}
		</Stack>
	}

}

const mapStateToProps = (state) => {
	let gammaState = state.gammaStateReducer;
	let mapState = state.mapsStateReducer[gammaState.activeMapId];
	let slide = mapState.slideState.slide_data;
	return {gammaState, mapState, slide};
};

export default connect(mapStateToProps)(CaseInfo);
