import React, {Component} from "react";
import {connect} from "react-redux";
import {Grid3x3} from "@mui/icons-material";
import VectorSource from "ol/source/Vector";
import GridReference from "ol-ext/control/GridReference";
import {morphleBaseTheme1} from "../themes";
import {updateGridStatus} from "../../../action/maps.state.action";
import {Stack, Tooltip} from "@mui/material";
import {Stroke, Style, Text} from "ol/style";

class GridOverlay extends Component {

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.activeMapId !== this.props.activeMapId || prevProps.gridState !== this.props.gridState)
			this.drawGrid();
	}

	toggleGrid = () => this.props.dispatch(updateGridStatus(this.props.activeMapId, !this.props.gridState.grid));

	drawGrid = () => {

		if (this.props.gridState.gridRef)
			this.props.slideState.slidemap.removeControl(this.props.gridState.gridRef);

		if (!this.props.gridState.grid) return;

		let extent = this.props.slideState.slidemap.getView().getProjection().getExtent();
		let height = extent[3];
		let width = extent[2];
		let stepCord = 1;

		let gridEveryU = this.props.gridState.size;
		let gridCols = width / gridEveryU;
		let gridRows = height / gridEveryU;

		this.props.gridState.gridRef = new GridReference({
			style: new Style({
				stroke: new Stroke({
					color: this.props.gridState.color,
					width: this.props.gridState.width,
				}),
			}),
			extent,
			size: [gridCols, gridRows],
			source: new VectorSource({
				overlaps: true,
			}),
		});

		this.props.gridState.gridRef.getHIndex = index =>
			stepCord && index % stepCord === 0 ? index + 'h' : '';

		this.props.gridState.gridRef.getVIndex = index =>
		stepCord && index % stepCord === 0 ? index + 'v' : '';

		this.props.slideState.slidemap.addControl(this.props.gridState.gridRef);
	}

	render() {
		if (!this.props.slideState) return <div/>;

		return <Stack direction={"row"} justifyContent="space-between" alignItems='center'>
			<Tooltip title={"Grid"} placement={"bottom"}>
				{this.props.gridState.grid ?
					<Grid3x3 sx={{color: morphleBaseTheme1.palette.headBarIcon.secondary, cursor: 'pointer'}}
					         onClick={this.toggleGrid}
					/> :
					<Grid3x3 sx={{color: morphleBaseTheme1.palette.headBarIcon.primary, cursor: 'pointer'}}
					         onClick={this.toggleGrid}
					/>
				}
			</Tooltip>
		</Stack>
	}
}

const mapStateToProps = (state) => {
	let activeMapId = state.gammaStateReducer.activeMapId;
	let {slideState, gridState} = state.mapsStateReducer[activeMapId];
	return {activeMapId, slideState, gridState};
}

export default connect(mapStateToProps)(GridOverlay)
