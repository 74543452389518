import {get, patch} from "../helper/request";
import {LOAD_MORPHEUS_SETTING, UPDATE_MORPHEUS_SETTING} from "../actionTypes/morpheus.setting.constant";
import {displayError} from "../helper/display.error";

export const loadMorpheusSetting = () => dispatch => {
	get(`/api/morpheus/settings/${JSON.parse(localStorage.getItem('morpheus_setting')).id}/`)
		.then(morpheusSetting => dispatch({
			type: LOAD_MORPHEUS_SETTING,
			morpheusSetting,
		}))
		.catch(error => displayError("Error loading morpheus setting", error));
};

export const updateMorpheusSetting = morpheusSetting => dispatch => {
	patch(`/api/morpheus/settings/${JSON.parse(localStorage.getItem('morpheus_setting')).id}/`, morpheusSetting)
		.then(morpheusSetting => dispatch({
			type: UPDATE_MORPHEUS_SETTING,
			morpheusSetting,
		}))
		.catch(error => displayError("Error updating morpheus setting", error));
};

export const updateAppsInstalled = apps_installed =>
	updateMorpheusSetting({apps_installed});

export const updateServices = services =>
	updateMorpheusSetting({services});

export const toggleKeyboardShortcuts = keyboard_shortcuts_enable =>
	updateMorpheusSetting({keyboard_shortcuts_enable});

export const updateKeyboardShortcuts = keyboard_shortcuts =>
	updateMorpheusSetting({keyboard_shortcuts});

export const updateConfigurations = viewer_configurations =>
	updateMorpheusSetting({viewer_configurations});
