import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import cookie from "react-cookies";
import { AuthHeader } from "../../../helper/auth.token";
import { Row, Col, Button, Icon, Tooltip, message, Spin, Radio, Input, Checkbox, Switch, Divider } from 'antd';
import {
    DragOutlined,
    PlusCircleOutlined,
    ReloadOutlined,
} from '@ant-design/icons';
import FullScreen from 'react-request-fullscreen'
import {
    ongoingCameraAppAction, getSavedImages, startCameraAction, stopCameraAction, ongoingMotionAppAction,
    updateDoDropDistanceAF, updateDoAutoFocus, updateLiveModeAction, updateVisitedArea, updateAppClosedStatus, updateAppClickTime
} from '../../../action/admin.state.action';
import { updateFullscreenMode } from "../../../action/liveview.action";
import LiveView from '../../manualmode/live_view_manual_mode';
import { cameraModeTypes } from "../../../utils/const";
import LiveCameraPreview from "./main_camera_preview";
import { savedImagesAppKey } from "../initial_setup_app_keys_live_view";
import { liveModeLoadingMessages } from "../../../utils/const";

import "../../../asset/style/manualmode/camera_app.css";
import "../../../asset/style/manualmode/motion_app.css";
import "../../../asset/style/manualmode/camera_controls_app.css";

class CameraApp extends Component {

    constructor(props) {
        super(props);

        let url = window.location.href.split('?')[1];
        let slot_id = -1;
        if (url != undefined) {
            let partsOfUrl = url.split('&');
            partsOfUrl.map((part) => {
                let key = part.split('=')[0];
                let value = part.split('=')[1];
                if (key === 'slot_id') {
                    slot_id = parseFloat(value);
                }
            });
        }

        this.state = {
            showMarker: false,
            showDirections: false,
            fullScreenMode: false,
            cameraMode: cameraModeTypes.LIVE_CAMERA,
            resetCameraCount: 0,
            exposureInputValue: 1000,
            gammaInputValue: 1,
            relativeMotionInputValue: 1,
            slotID: slot_id,
        }

    }

    toggleMarker = () => {
        this.setState({
            showMarker: !this.state.showMarker,
        });
    }

    toggleDirections = () => {
        this.setState({
            showDirections: !this.state.showDirections,
        });
    }

    toggleFullScreen = (isFullScreen) => {
        this.setState({
            fullScreenMode: isFullScreen,
        });
        this.props.dispatch(updateFullscreenMode(isFullScreen));
    }

    changeMainLight = (checked, e) => {
        if (checked) {
            this.mainLightOn();
        } else {
            this.mainLightOff();
        }
    }

    mainLightOn = (e) => {
        this.props.dispatch(ongoingCameraAppAction(this.props.adminState, true));
        this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.MAIN_LIGHT_ON));
        let partsOfUrl = "api~stage~switch_on_main_light";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                }
                else {
                    console.log(response);
                    message.error("Not able to switch main light on!!", 2.5);
                }
                this.props.dispatch(ongoingCameraAppAction(this.props.adminState, false));
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.MAIN_LIGHT_ON));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to switch main light on!!", 2.5);
                this.props.dispatch(ongoingCameraAppAction(this.props.adminState, false));
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.MAIN_LIGHT_ON));
            })
    }

    mainLightOff = (e) => {
        this.props.dispatch(ongoingCameraAppAction(this.props.adminState, true));
        this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.MAIN_LIGHT_OFF));
        let partsOfUrl = "api~stage~switch_off_main_light";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                }
                else {
                    console.log(response);
                    message.error("Not able to switch main light off!!", 2.5);
                }
                this.props.dispatch(ongoingCameraAppAction(this.props.adminState, false));
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.MAIN_LIGHT_OFF));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to switch main light off!!", 2.5);
                this.props.dispatch(ongoingCameraAppAction(this.props.adminState, false));
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.MAIN_LIGHT_OFF));
            })
    }

    dropDistanceAF = (e) => {
        if (this.state.slotID >= 0) {
            if (this.props.adminState.main_light) {
                if (!this.props.adminState.doDropDistanceAF) {
                    this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.DROP_DISTANCE_AF));
                    let partsOfUrl = "api~diagnostics~autofocus";
                    let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + `?move=false&doDrop=true`;
                    axios.get(url, { headers: { Authorization: AuthHeader() } })
                        .then(response => {
                            if (response.status === 200) {
                            }
                            else {
                                console.log(response);
                                message.error("Not able to do Drop Distance Autofocus!!", 2.5);
                            }
                            this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.DROP_DISTANCE_AF));
                        })
                        .catch(err => {
                            console.log(err);
                            message.error("Not able to do Drop Distance Autofocus!!", 2.5);
                            this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.DROP_DISTANCE_AF));
                        })
                } else {
                    message.error("Please go to some position once by double clicking on the preview image first.", 2.5);
                }
            } else {
                message.error("Please switch on the main light.", 2.5);
            }
        } else {
            message.error("Please take the preview first.", 2.5);
        }
    }

    autoFocus = (e) => {
        if (this.state.slotID >= 0) {
            if (this.props.adminState.main_light) {
                if (!this.props.adminState.doDropDistanceAF) {
                    this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.AUTO_FOCUS));
                    let partsOfUrl = "api~diagnostics~autofocus";
                    let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + `?move=false&doDrop=false`;
                    axios.get(url, { headers: { Authorization: AuthHeader() } })
                        .then(response => {
                            if (response.status === 200) {
                            }
                            else {
                                console.log(response);
                                message.error("Not able to do autofocus!!", 2.5);
                            }
                            this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.AUTO_FOCUS));
                        })
                        .catch(err => {
                            console.log(err);
                            message.error("Not able to do autofocus!!", 2.5);
                            this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.AUTO_FOCUS));
                        })
                } else {
                    message.error("Please go to some position once by double clicking on the preview image first.", 2.5);
                }
            } else {
                message.error("Please switch on the main light.", 2.5);
            }
        } else {
            message.error("Please take the preview first.", 2.5);
        }
    }

    openSavedImagesApp = () => {
        this.props.dispatch(updateAppClosedStatus(savedImagesAppKey.id, false, this.props.adminState));
        this.props.dispatch(updateAppClickTime(savedImagesAppKey.id, this.props.adminState));
    }

    takePicture = () => {
        this.props.dispatch(ongoingCameraAppAction(this.props.adminState, true));
        this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.TAKING_IMAGES));
        let partsOfUrl = "api~camera~take_picture_pan_zoom";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?zoomFactor=' + this.props.liveView.zoom +
            '&xPosition=' + this.props.liveView.x + '&yPosition=' + this.props.liveView.y;
        if (this.state.cameraMode == cameraModeTypes.QUADRANT_MODE) {
            partsOfUrl = "api~camera~take-picture-intrafield";
            url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?quarter_size=' + this.props.adminState.quarter_size;
        }

        let successMessage = <Button onClick={this.openSavedImagesApp} type="link">
            <Row>
                <Col>
                    Image saved successfully.
                </Col>
            </Row>
            <Row>
                <Col>
                    Click here to view all the images.
                </Col>
            </Row>
        </Button>;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(getSavedImages());
                    message.success(successMessage, 5);
                }
                else {
                    console.log(response);
                    message.error("Not able to take picture!!", 2.5);
                }
                this.props.dispatch(ongoingCameraAppAction(this.props.adminState, false));
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.TAKING_IMAGES));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to take picture!!", 2.5);
                this.props.dispatch(ongoingCameraAppAction(this.props.adminState, false));
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.TAKING_IMAGES));
            })
    }

    startCamera = (e) => {
        this.props.dispatch(startCameraAction(this.props.currentState.deviceId, this.props.adminState));
    }

    stopCamera = (e) => {
        this.props.dispatch(stopCameraAction(this.props.currentState.deviceId, this.props.adminState));
    }

    updateBlankImage = (e) => {
        this.props.dispatch(ongoingCameraAppAction(this.props.adminState, true));
        let partsOfUrl = "api~camera~update_blank_image";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                }
                else {
                    console.log(response);
                    message.error("Not able to update blank image!!", 2.5);
                }
                this.props.dispatch(ongoingCameraAppAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to update blank image!!", 2.5);
                this.props.dispatch(ongoingCameraAppAction(this.props.adminState, false));
            })
    }

    requestOrExitFullScreen = () => {
        this.fullScreenRef.fullScreen();
    }

    changeCameraMode = (e) => {
        let value = e.target.value;
        this.setState({
            cameraMode: value,
        });
    }

    resetArea = () => {
        this.setState({
            resetCameraCount: this.state.resetCameraCount + 1,
        });
    }

    getRadioButtonComponent = () => {
        return <Radio.Group value={this.state.cameraMode} buttonStyle="solid" size="small" onChange={this.changeCameraMode}>
            <Radio.Button className={this.state.cameraMode == cameraModeTypes.LIVE_CAMERA ? "" : "vertical-radio-buttons top-most-radio-button"}
                value={cameraModeTypes.LIVE_CAMERA}><Icon type="desktop" />
            </Radio.Button>
            <Radio.Button className={this.state.cameraMode == cameraModeTypes.LIVE_CAMERA ? "" : "vertical-radio-buttons bottom-most-radio-button"}
                value={cameraModeTypes.QUADRANT_MODE}><Icon type="appstore" />
            </Radio.Button>
        </Radio.Group>
    }

    getTakePictureComponent = () => {
        return <Tooltip title="Take Picture" placement="topRight">
            <Button type={"primary"} size="small" onClick={this.takePicture}>
                <Icon type="camera" />
            </Button>
        </Tooltip>
    }

    getFullScreenComponent = () => {
        return <FullScreen ref={ref => { this.fullScreenRef = ref }} onFullScreenChange={this.toggleFullScreen}>
            <Tooltip title="Toggle Fullscreen" placement="topRight">
                <Button type={this.state.fullScreenMode ? "danger" : "primary"} size="small" onClick={this.requestOrExitFullScreen.bind(this)}>
                    {this.state.fullScreenMode ? <Icon type="fullscreen-exit" /> : <Icon type="fullscreen" />}
                </Button>
            </Tooltip>
        </FullScreen>
    }

    getCameraAppButton = (text, onClickMethod) => {
        return (
            <Button size="small" type="primary" onClick={onClickMethod}>
                {text}
            </Button>
        )
    }

    getCameraInputFieldRow = (haveRowStyle, showButton, heading, inputField, button) => {
        return (
            <Row style={haveRowStyle ? { marginTop: 5 } : {}}>
                <Col span={8}>
                    <b>{heading}</b>
                </Col>
                <Col span={10}>
                    {inputField}
                </Col>
                {showButton ?
                    <Col span={4}>
                        {button}
                    </Col>
                    : null
                }
            </Row>
        )
    }

    getCameraCheckboxRow = (haveRowStyle, heading, checkboxField) => {
        return (
            <Row style={haveRowStyle ? { marginTop: 10 } : {}}>
                <Col offset={1} span={20} style={{ fontSize: 12 }}>
                    <b>{heading}</b>
                </Col>
                <Col span={3}>
                    {checkboxField}
                </Col>
            </Row>
        )
    }

    getCameraAppInputField = (inputValue, onChangeInput) => {
        return <Input size="small" value={inputValue} onChange={onChangeInput} className="camera-app-input-field" />
    }

    getCameraAppCheckbox = (inputValue, onChangeInput) => {
        return <Checkbox checked={inputValue} onChange={onChangeInput} className="custom-checkbox-style"></Checkbox>
    }

    setExposureValue = (e) => {
        this.props.dispatch(ongoingCameraAppAction(this.props.adminState, true));
        this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.SET_EXPOSURE_VALUE));
        let url = "/server/devices/" + this.props.currentState.deviceId + "/camera?exposure=" + this.state.exposureInputValue;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    let jsonData = JSON.parse(response.data);
                    this.setState({
                        exposureInputValue: jsonData['exposureTime']
                    });
                    message.success("Exposure set successfully!!", 2.5);
                }
                else {
                    console.log(response);
                    message.error("Not able to set exposure!!", 2.5);
                }
                this.props.dispatch(ongoingCameraAppAction(this.props.adminState, false));
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.SET_EXPOSURE_VALUE));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set exposure!!", 2.5);
                this.props.dispatch(ongoingCameraAppAction(this.props.adminState, false));
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.SET_EXPOSURE_VALUE));
            })
    }

    setGammaValue = (e) => {
        this.props.dispatch(ongoingCameraAppAction(this.props.adminState, true));
        this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.SET_GAMMA_VALUE));
        let url = "/server/devices/" + this.props.currentState.deviceId + "/camera?gamma=" + this.state.gammaInputValue
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    let jsonData = JSON.parse(response.data);
                    this.setState({
                        gammaInputValue: jsonData['gamma']
                    });
                    message.success("Gamma set successfully!!", 2.5);
                }
                else {
                    console.log(response);
                    message.error("Not able to set gamma!!", 2.5);
                }
                this.props.dispatch(ongoingCameraAppAction(this.props.adminState, false));
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.SET_GAMMA_VALUE));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set gamma!!", 2.5);
                this.props.dispatch(ongoingCameraAppAction(this.props.adminState, false));
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.SET_GAMMA_VALUE));
            })
    }

    changeDoDropDistanceAF = (e) => {
        this.props.dispatch(updateDoDropDistanceAF(e.target.checked));
    }

    changeDoAF = (e) => {
        this.props.dispatch(updateDoAutoFocus(e.target.checked));
    }

    setWBValue = (e) => {
        this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.SET_WB));
        let url = "/server/devices/" + this.props.currentState.deviceId + "/camera?awb=true"
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    message.success("WB set successfully!!", 2.5);
                }
                else {
                    console.log(response);
                    message.error("Not able to set WB!!", 2.5);
                }
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.SET_WB));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set WB!!", 2.5);
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.SET_WB));
            })
    }

    exposureValueChange = (e) => {
        this.setState({
            exposureInputValue: e.target.value
        })
    }

    gammaValueChange = (e) => {
        this.setState({
            gammaInputValue: e.target.value
        })
    }

    render() {
        return (
            <Row className={this.state.cameraMode == cameraModeTypes.QUADRANT_MODE ? "app-parent overlayed-component camera-app-width-quadrant" :
                this.state.fullScreenMode ? "app-parent overlayed-component camera-app-width-fullscreen" :
                    cookie.loadAll().superuser === 'true' ? "app-parent overlayed-component camera-app-width" :
                        "app-parent overlayed-component camera-app-live-mode-width"}>
                <div>
                    <Spin spinning={this.props.adminState.camera_app_action}>
                        <Row>
                            <Col span={this.state.cameraMode == cameraModeTypes.LIVE_CAMERA ?
                                this.state.fullScreenMode ? 20 : 19 : 23}>
                                <LiveView id={this.props.currentState.deviceId} camera_app={true} fullScreenMode={this.state.fullScreenMode} liveMode={false}
                                    showMarker={this.state.showMarker} showDirections={this.state.showDirections} cameraMode={this.state.cameraMode} />
                            </Col>
                            <Col span={this.state.cameraMode == cameraModeTypes.LIVE_CAMERA ?
                                this.state.fullScreenMode ? 4 : 5 : 1}>
                                <br />
                                    <div>
                                        <Row>
                                            {this.state.cameraMode == cameraModeTypes.LIVE_CAMERA ?
                                                <div>
                                                    <Col span={this.state.fullScreenMode ? 10 : 8} offset={this.state.fullScreenMode ? 0 : 2}>
                                                        {this.getRadioButtonComponent()}
                                                    </Col>
                                                    <Col span={7}>
                                                        {this.getTakePictureComponent()}
                                                    </Col>
                                                    <Col span={7}>
                                                        {this.getFullScreenComponent()}
                                                    </Col>
                                                </div> :
                                                <div>
                                                    <Row style={{ marginTop: '25%' }}>
                                                        {this.getRadioButtonComponent()}
                                                    </Row>
                                                    <Row style={{ marginTop: '25%' }}>
                                                        {this.getTakePictureComponent()}
                                                    </Row>
                                                    <Row style={{ marginTop: '25%' }}>
                                                        {this.getFullScreenComponent()}
                                                    </Row>
                                                </div>
                                            }
                                        </Row>
                                        <br />
                                        <Row>
                                            {this.state.cameraMode == cameraModeTypes.LIVE_CAMERA ?
                                                <div>
                                                    <Col span={this.state.fullScreenMode ? 8 : 7} offset={this.state.fullScreenMode ? 2 : 3}>
                                                        <Tooltip title="Toggle Marker" placement="rightTop">
                                                            <Button type={this.state.showMarker ? "danger" : "primary"} size="small" onClick={this.toggleMarker}>
                                                                <Icon type="plus-circle" />
                                                            </Button>
                                                        </Tooltip>
                                                    </Col>
                                                    <Col span={7}>
                                                        <Tooltip title="Toggle Directions" placement="rightTop">
                                                            <Button type={this.state.showDirections ? "danger" : "primary"} size="small" onClick={this.toggleDirections}>
                                                                <Icon type="drag" />
                                                            </Button>
                                                        </Tooltip>
                                                    </Col>
                                                    <Col span={7}>
                                                        <Tooltip title="Reset" placement="rightTop">
                                                            <Button type={"primary"} size="small" onClick={this.resetArea}>
                                                                <Icon type="reload" />
                                                            </Button>
                                                        </Tooltip>
                                                    </Col>
                                                </div> :
                                                null
                                            }
                                        </Row>
                                    </div>
                                {this.state.cameraMode == cameraModeTypes.LIVE_CAMERA ?
                                    <div>
                                        <div>
                                            <br />
                                            <Row>
                                                <Col offset={this.state.fullScreenMode ? 5 : 6} span={12}>
                                                    {this.getCameraAppButton('Start Camera', this.startCamera)}
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: 10 }}>
                                                <Col offset={this.state.fullScreenMode ? 5 : 6} span={11}>
                                                    {this.getCameraAppButton('Stop Camera', this.stopCamera)}
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: 10 }}>
                                                <Col offset={this.state.fullScreenMode ? 3 : 4} span={11}>
                                                    {this.getCameraAppButton('Update Blank Image', this.updateBlankImage)}
                                                </Col>
                                            </Row>
                                        </div>
                                        <br />
                                        <Row>
                                            <Col offset={this.state.fullScreenMode ? 0 : 1}>
                                                <LiveCameraPreview currentState={this.props.currentState} resetCameraCount={this.state.resetCameraCount}
                                                    liveMode={false} />
                                            </Col>
                                        </Row>
                                        <div>
                                            <Divider />
                                            <Row>
                                                <Col offset={6}>
                                                    <b>Camera Settings</b>
                                                </Col>
                                            </Row>
                                            <Divider />
                                            {this.getCameraInputFieldRow(false, true, 'Exposure: ',
                                                this.getCameraAppInputField(this.state.exposureInputValue, this.exposureValueChange),
                                                this.getCameraAppButton('Set', this.setExposureValue)
                                            )}
                                            {this.getCameraInputFieldRow(true, true, 'Gamma: ',
                                                this.getCameraAppInputField(this.state.gammaInputValue, this.gammaValueChange),
                                                this.getCameraAppButton('Set', this.setGammaValue)
                                            )}
                                            {this.getCameraInputFieldRow(true, true, 'WB: ', 'Auto Only',
                                                this.getCameraAppButton('Set', this.setWBValue)
                                            )}
                                            <br />
                                        </div>
                                    </div> :
                                    null
                                }
                            </Col>
                        </Row>
                    </Spin>
                </div>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        adminState: state.adminUrlReducer,
        liveView: state.liveViewReducer,
    }
}

export default connect(mapStateToProps)(CameraApp);
